import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {HipSurvey} from '../../shared/hip/hip-survey';
import {ProductCatalogAdapter} from '../../shared/product-catalog/product-catalog-adapter';
import {ProductCatalog} from '../../shared/product-catalog/product-catalog-listing';
import {CatalogType} from '../../shared/product-catalog/product-catalog-utils';
import {SalesReceipt} from '../../shared/product-catalog/product-sales-receipt';
import {PurchaseConfirmation} from '../../shared/product-catalog/purchase-confirmation';
import {CourseAndEventRegistration} from '../course-and-event-registration';
import {CustomerLotteryApplications} from '../customer-lottery-application-listing';
import {CustomerDashboard} from '../customer-sales-dashboard';
import {CustomerUpdateForRoute} from '../customers/customer-create-update/customer-create-update';
import {CustomerEmailSubmission} from '../customers/customer-email-sumbit';
import {CustomerEventListing} from '../customers/customer-events-listing';
import {EventRegistration} from '../events/event-registration';
import {EventRegistrationLogin} from '../events/event-registration-login';
import {LicenseListing} from '../license-listing';
import {LotteryApplications} from '../lottery-applications/lottery-application-list';
import {LotteryApplicationsPaymentConfirmation} from '../lottery-applications/lottery-applications-confirmation';
import {LotteryReceipt} from '../lottery-applications/lottery-receipt';
import {LotteryHunts} from '../lottery-hunt-listing/lottery-hunt-listing';
import {CourseRegistration} from '../online-course/course-registration';
import {CourseRegistrationLogin} from '../online-course/course-registration-login';
import {OnlineCourseListing} from '../online-course/online-courses-listing';
import {PreferencePoints} from '../preference-points-listing';
import {ValidateTags} from '../validate-tags';
import {YourAccount} from '../your-account';
import {AutoRenewManagement} from '../customers/auto-renew/auto-renew-management';
import {customerRoutes as routes} from './config';
import {OnlineCourseWrapper} from '../online-course/online-course-wrapper';
import {MailingAddressConfirmation} from '../../shared/mailing-address/mailing-address-confirmation';

export const CustomerRoutes = () => {
  return (
    <Switch>
      <Route path={routes.dashboard} exact>
        <CustomerDashboard />
      </Route>
      <Route
        exact
        path={routes.licensesAndPermits}
        render={({location}) => {
          const catalogState = location.state
            ? location.state.catalogState
            : null;
          return (
            <ProductCatalogAdapter
              catalogState={catalogState}
              render={(catalogState) => (
                <ProductCatalog
                  catalogType={CatalogType.customer}
                  prevCatalogState={catalogState}
                />
              )}
            />
          );
        }}
      />
      <Route
        exact
        path={routes.licensesAndPermitsSetDisplay}
        render={({location}) => {
          const catalogState = location.state
            ? location.state.catalogState
            : null;
          return (
            <ProductCatalogAdapter
              catalogState={catalogState}
              render={(catalogState) => (
                <ProductCatalog
                  catalogType={CatalogType.customer}
                  prevCatalogState={catalogState}
                />
              )}
            />
          );
        }}
      />
      <Route path={routes.customerlicensesAndPermits} exact>
        <LicenseListing isAdmin={false} />
      </Route>
      <Route path={routes.customerUpdate} exact>
        <CustomerUpdateForRoute />
      </Route>
      <Route path={routes.purchaseConfirmation} exact>
        <PurchaseConfirmation />
      </Route>
      <Route path={routes.salesReceipt} exact>
        <SalesReceipt />
      </Route>
      <Route path={routes.mailingAddressConfirmation} exact>
        <MailingAddressConfirmation />
      </Route>
      <Route path={routes.hipSurvey} exact>
        <HipSurvey />
      </Route>
      <Route path={routes.validateTags} exact>
        <ValidateTags />
      </Route>
      <Route path={routes.lotteryApplications} exact>
        <LotteryApplications />
      </Route>
      <Route path={routes.lotteryApplicationsPaymentConfirmation} exact>
        <LotteryApplicationsPaymentConfirmation />
      </Route>
      <Route path={routes.lotteryReceipt} exact>
        <LotteryReceipt />
      </Route>
      <Route path={routes.lotteryHunts}>
        <LotteryHunts />
      </Route>
      <Route path={routes.courseAndEventRegistration} exact>
        <CourseAndEventRegistration />
      </Route>
      <Route path={routes.yourAccount} exact>
        <YourAccount />
      </Route>
      <Route path={routes.eventRegistrationLogin} exact>
        <EventRegistrationLogin />
      </Route>
      <Route path={routes.eventRegistration} exact>
        <EventRegistration />
      </Route>
      <Route path={routes.customerEmailSubmission} exact>
        <CustomerEmailSubmission />
      </Route>
      <Route path={routes.customerEventListing} exact>
        <CustomerEventListing />
      </Route>
      <Route path={routes.customerLotteryApplications} exact>
        <CustomerLotteryApplications />
      </Route>
      <Route path={routes.preferencePoints} exact>
        <PreferencePoints />
      </Route>
      <Route path={routes.myOnlineCourses} exact>
        <OnlineCourseListing />
      </Route>
      <Route path={routes.courseRegistration} exact>
        <OnlineCourseWrapper>
          <CourseRegistration />
        </OnlineCourseWrapper>
      </Route>
      <Route path={routes.courseRegistrationLogin} exact>
        <OnlineCourseWrapper>
          <CourseRegistrationLogin />
        </OnlineCourseWrapper>
      </Route>
      <Route path={routes.autoRenewManagement.customerProducts} exact>
        <AutoRenewManagement />
      </Route>
      <Route path={routes.autoRenewManagement.paymentMethod} exact>
        <AutoRenewManagement />
      </Route>
      <Route>
        <Redirect to={routes.dashboard} />
      </Route>
    </Switch>
  );
};
