import css from '@emotion/css/macro';
import React, {useState, useMemo, useEffect} from 'react';
import {useAsync, useMedia} from 'react-use';
import {Table, Divider, Grid, Button, Image, Icon} from 'semantic-ui-react';
import {ConfirmationCartProductRows} from './purchase-confirmation';
import {StyledPageContainer} from '../../styled-page-container';
import {Form} from '../../forms';
import {Media, MediaSizes} from '../../styles/breakpoints';
import {
  catalogRoutes,
  updateEmailAddress,
  getSelectedDonation,
  ProductCatalogState,
  useProductCatalogState,
  getCheckoutInfo,
} from './product-catalog-utils';
import {Redirect, useHistory} from 'react-router-dom';
import {CatalogType} from './product-catalog-utils';
import {Theme} from '../../theme';
import {
  checkbox,
  fieldConfig,
  getDefaults,
  masked,
  RawFieldConfig,
} from '../../forms/schema-utils';
import lawffLogo from '../../assets/images/disabled-veterans-logo.png';
import lwaaLogo from '../../assets/images/lwaa-website-logo.png';
import {
  CreateTransactionReturnDto,
  CustomerInformationDto,
  TransactionCustomerService,
  SmsTaggingService,
  TextToTagEnrollmentStatus,
  TenderType,
  VendorTransactionService,
  ProductShoppingCartItemDto,
  CustomerMailingAddressDto,
  IspCustomersService,
  ResidencyCode,
  OrganDonorRegistryService,
} from '../../api/generated';
import moment from 'moment';
import {StyledButton} from '../../styled-components/styled-buttons';
import {faFilePdf} from '@fortawesome/pro-light-svg-icons';
import {ExportButton} from '../../components/export-button';
import {notifications} from '../../utils/notification-service';
import {customerRoutes} from '../../internet-sales-portal/routes/config';
import {ProductTypeCodeType} from '../../api/generated/enums';
import {Typography} from '../../styled-components/typography';
import {cstNow} from '../../utils/date-time-helpers';
import _ from 'lodash';
import {adminRoutes} from '../../admin/routes/config';
import {buildIdRoute} from '../../utils/route-helpers';
import {UpdateEmailOnReceiptPage} from '../purchase-receipt-email-update';
import {RolpRedirectInfo} from './product-sales-receipt-rolp-container';
import {CustomerMailingAddressInformation} from '../mailing-address/mailing-address-info';
import {routes} from '../../routes/config';
import {OrganDonorInfo} from '../organ-donor/organ-donor-info';

const useFields = (catalogType, customerId) => {
  const fetchFields = useAsync(async () => {
    const {result} = await getCheckoutInfo[catalogType]({
      customerId: customerId,
    });

    return {
      fieldConfig: fieldConfig<FieldConfigDto>({
        phoneNumber: masked({
          fieldLabel: 'Unique Mobile Number',
          defaultValue: result?.customerInformation.textToTagSmsNumber,
          inputProps: {
            options: {
              phone: true,
              phoneRegionCode: 'US',
              delimiter: '-',
            },
          },
        }),
        legalConfirmation: checkbox({
          fieldRequired: true,
          inputProps: {
            label:
              'Please confirm that the number entered here is the mobile number of the person who will be utilizing the "Text to Tag" feature.',
          },
        }),
      }),
      customerInformation: result?.customerInformation,
      shoppingCart: result?.shoppingCart,
    };
  });

  return fetchFields;
};

const InlineViewButton = ({href}) => {
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Button content={'View License(s) in Browser'} fluid={mobileMax} />
    </a>
  );
};

const transactionReceiptDetails: {
  transactionId: number;
  tenderType: any | null;
  customerMailingAddress: CustomerMailingAddressDto | undefined;
} = {transactionId: 0, tenderType: null, customerMailingAddress: undefined};

export const SalesReceipt = () => {
  const [queryIdList, setQueryIdList] = useState('');

  const history = useHistory();
  const {
    catalogState,
    transactionState,
  }: {
    catalogState: ProductCatalogState | undefined;
    transactionState: CreateTransactionReturnDto | undefined;
  } = history.location.state ?? {
    catalogState: undefined,
    transactionState: undefined,
  };

  const {
    context: {state},
  } = useProductCatalogState({
    prevCatalogState: catalogState,
  });

  const {catalogType, mailingAddress} = state;

  const fields = useFields(catalogType, catalogState?.customer?.id);

  transactionReceiptDetails.transactionId = transactionState?.id ?? 0;
  transactionReceiptDetails.tenderType = catalogState?.tenderType;
  transactionReceiptDetails.customerMailingAddress =
    mailingAddress || undefined;

  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  const isAdmin = state.catalogType === CatalogType.admin;
  const isVendor =
    state.catalogType === CatalogType.vendor ||
    state.catalogType === CatalogType.hqVendor;

  const fetchReceiptInformation = useAsync(async () => {
    const {result} =
      isAdmin || isVendor
        ? await VendorTransactionService.getCheckoutInfo({
            customerId: catalogState?.customer?.id,
          })
        : await TransactionCustomerService.getCheckoutInfo();
    return result;
  });

  const shoppingCart = fetchReceiptInformation.value?.shoppingCart?.products;
  const rolpProducts = fetchReceiptInformation.value?.shoppingCart?.products?.filter(
    (x) => x.product?.isRolpRequired
  );
  const isRolpRequired = !!rolpProducts?.length && rolpProducts.length > 0;

  let creditCardFee = state.creditCardFee;

  useMemo(
    () => ({
      creditCardFee: state.creditCardFee,
    }),
    [state.creditCardFee]
  );

  const total =
    (fetchReceiptInformation.value?.shoppingCart?.total ?? 0) +
    (creditCardFee ?? 0);

  const containsTag = shoppingCart?.find((item) => item.product?.hasTags);

  const hasPurchasedOnlineCourse = shoppingCart?.some(
    (x) => x.product?.productTypeCodeType === ProductTypeCodeType.COURSE
  );

  const cartHasReplacedTransactionCustomerProduct = shoppingCart?.some(
    (cartItem) => cartItem.replacedTransactionCustomerProductId !== null
  );

  useEffect(() => {
    const transactionCustomerProductIdsToExport = _.flatMap(
      shoppingCart,
      (cartItem) => {
        return cartItem.replacedTransactionCustomerProductId;
      }
    );

    let idList = '';
    transactionCustomerProductIdsToExport.forEach((id) => {
      idList += `tcpIds=${id}&`;
    });
    setQueryIdList(idList);
  }, [shoppingCart]);

  const {value: customer} = useAsync(async () => {
    const {result} = await IspCustomersService.getById();
    return result;
  }, []);

  const updateCustomerEmail = async (values) => {
    if (catalogType) {
      return await updateEmailAddress[catalogType]({
        emailAddress: values.emailAddress,
        customerId: catalogState?.customer?.id,
      });
    }
  };

  const resendEmailReceipt = async () => {
    return await TransactionCustomerService.resendTransactionReceiptEmail({
      body: {
        transactionId: transactionReceiptDetails.transactionId,
        customerMailingAddress: mailingAddress || undefined,
      },
    });
  };

  const {value: isOrganDonorRegistryEnabled} = useAsync(async () => {
    const {result} = await OrganDonorRegistryService.isEnabled();
    return result;
  }, []);

  if (!catalogState || !transactionState) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <StyledPageContainer
      title="Sales Receipt"
      subtitle="Thank you for supporting Louisiana Department of Wildlife & Fisheries"
    >
      <main css={styles}>
        {state ? (
          <>
            <section className="receipt-section">
              <div className="purchase-details-group">
                <section className="info-message-section">
                  <p>
                    {`Items purchased for ${catalogState.customer?.firstName} ${
                      catalogState.customer?.lastName
                    } (${moment(transactionState.createdTimestamp).format(
                      'MM/DD/YYYY'
                    )}) Confirmation #${
                      transactionState.transactionNumber
                    } LDWF #${catalogState.customer?.sportsmanId}`}
                  </p>
                </section>
                <section className="confirmation-cart-section">
                  <ConfirmationCart
                    state={state}
                    shoppingCart={shoppingCart}
                    total={total ?? 0}
                    isReceipt={true}
                  />
                  {transactionState.tendorType &&
                    state.catalogType === CatalogType.hqVendor && (
                      <>
                        <Typography variant="labelLarge">{`Payment Method - `}</Typography>
                        {transactionState.tendorType === TenderType.Check ? (
                          <Typography variant="body1">
                            {`${transactionState.tendorType} #${transactionState.checkNumber}`}
                          </Typography>
                        ) : (
                          <Typography variant="body1">{`${transactionState.tendorType}`}</Typography>
                        )}
                      </>
                    )}
                </section>

                <section className="actions-section">
                  {hasPurchasedOnlineCourse && (
                    <StyledButton
                      primary
                      onClick={() =>
                        history.push(customerRoutes.myOnlineCourses)
                      }
                    >
                      <div className="primary-button-text">
                        View Purchased Courses
                      </div>
                    </StyledButton>
                  )}
                  {cartHasReplacedTransactionCustomerProduct ? (
                    <>
                      <ExportButton
                        action={
                          TransactionCustomerService.exportCustomerLicensePdfFile
                        }
                        fileName={'CustomerLicenses'}
                        request={
                          shoppingCart?.map(
                            (cartItem) =>
                              cartItem?.replacedTransactionCustomerProductId
                          ) ?? []
                        }
                        label={'Download License(s)'}
                        icon={faFilePdf}
                        fluid={mobileMax}
                      />
                      <InlineViewButton
                        href={`/api/customer-portal/transaction/duplicate-license/pdf/customer-licenses?${queryIdList}`}
                      />
                    </>
                  ) : (
                    <>
                      <ExportButton
                        action={
                          TransactionCustomerService.exportTransactionCustomerLicensePdfFile
                        }
                        fileName={'CustomerLicenses'}
                        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                        request={{
                          transactionId:
                            transactionReceiptDetails.transactionId,
                          viewInNewTab: false,
                        }}
                        label={'Download License(s)'}
                        icon={faFilePdf}
                        fluid={mobileMax}
                      />
                      <InlineViewButton
                        href={`/api/customer-portal/transaction/license/pdf/customer-licenses?transactionId=${transactionState.id}`}
                      />
                    </>
                  )}

                  <ExportButton
                    action={
                      TransactionCustomerService.exportTransactionReceiptPdfFile
                    }
                    fileName={`TransactionReceipt-${cstNow().format(
                      'MM/DD/YY-hh:m'
                    )}-${state.customer?.lastName}`}
                    request={transactionReceiptDetails}
                    label={' Print Receipt'}
                    icon={faFilePdf}
                    fluid={mobileMax}
                  />
                </section>
              </div>
              {customer?.residencyCode === ResidencyCode.RESIDENT &&
                isOrganDonorRegistryEnabled && (
                  <section className="organ-donor-section">
                    <OrganDonorInfo />
                    <StyledButton
                      as="a"
                      className="organ-donor-apply-button"
                      href={routes.resources.organDonorApplication}
                      primary
                      target="_blank"
                      referrerpolicy="no-referrer"
                      rel="noreferrer noopener"
                    >
                      <Icon name="heart" />
                      Become a Donor
                    </StyledButton>
                  </section>
                )}
            </section>
            <Grid.Row className="enroll-to-sms-tagging">
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <UpdateEmailOnReceiptPage
                  customerEmail={catalogState.customer?.emailAddress}
                  updateEmail={updateCustomerEmail}
                  resendEmail={resendEmailReceipt}
                />
              </Grid.Column>
            </Grid.Row>

            {catalogState.catalogType === CatalogType.customer &&
              isRolpRequired && (
                <Grid.Row className="enroll-to-sms-tagging">
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <RolpRedirectInfo />
                  </Grid.Column>
                </Grid.Row>
              )}

            {containsTag && (
              <Grid.Row className="enroll-to-sms-tagging">
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <EnrollInSMSTagging
                    fields={fields.value?.fieldConfig}
                    customer={catalogState.customer}
                  />
                </Grid.Column>
              </Grid.Row>
            )}

            {!isAdmin && !isVendor && <SupportAgencies />}

            <section className="navigation-section">
              <StyledButton
                primary={!hasPurchasedOnlineCourse}
                type="button"
                fluid={mobileMax}
                onClick={() => {
                  if (
                    catalogState.catalogType === CatalogType.admin &&
                    catalogState.customer
                  ) {
                    history.push(
                      buildIdRoute(
                        adminRoutes.customers.dashboard,
                        catalogState.customer.id
                      )
                    );
                  } else if (catalogState.catalogType) {
                    history.push(
                      catalogRoutes.loggedInDashboardRoutes[
                        catalogState.catalogType
                      ],
                      {
                        catalogState: catalogState,
                      }
                    );
                  }
                }}
              >
                Back to Dashboard
              </StyledButton>
              {mobileMax && <Divider hidden />}
            </section>
          </>
        ) : null}
      </main>
    </StyledPageContainer>
  );
};

type FieldConfigDto = {
  phoneNumber: string;
  legalConfirmation: boolean;
};

const EnrollInSMSTagging: React.FC<{
  fields: RawFieldConfig<FieldConfigDto> | undefined;
  customer: CustomerInformationDto | null;
}> = ({fields, customer}) => {
  const initialValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const [disableEnrollButton, setDisableEnrollButton] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setDisableEnrollButton(true);
    setEnableLoading(true);
    const enteredNumber = values.phoneNumber;
    const enteredNumberWithCountryCode = `+1${enteredNumber}`;
    //Appends a +1 to the number to appease phoneNumberValidation -- U.S. Canada numbers only
    values.phoneNumber = enteredNumberWithCountryCode;
    values.enabled = true;
    values.customerId = customer?.id;

    const response = await SmsTaggingService.enrollToSmsTagging(values);

    //Since a response populates the errored / submitted field with the information submitted, we don't want to have the "+1" included
    //instead, we want the original value submitted
    values.phoneNumber = enteredNumber;

    if (response.hasErrors) {
      setEnableLoading(false);
      setDisableEnrollButton(false);
      return response;
    }
    setEnableLoading(false);

    notifications.success('Successfully enrolled in "Text to Tag".');
  };

  return customer?.textToTagStatus === TextToTagEnrollmentStatus.Enrolled ? (
    <>
      <div className="box-description">
        <h3>Enroll to 'Text to Tag'</h3>
        <label>
          Text to Tag allows the hunter to use an e-tag and removes the need to
          apply a traditional tag to the harvest. Enter your mobile phone number
          below and click enroll to sign up for this feature.
        </label>
        <label>
          Note: The hunter must have the phone with them to use this feature
          when reporting their harvest. Phone numbers MUST BE UNIQUE and cannot
          be shared.
        </label>
      </div>
      <Divider />
      <Form
        initialValues={initialValues}
        className="inline"
        onSubmit={onSubmit}
        disableLoading
        render={() => (
          <>
            {fields && (
              <Form.Row>
                <Form.InputMasked fieldConfig={fields.phoneNumber} />
                <div className="field-submission-container">
                  <Form.Button
                    type="submit"
                    className="field-submission-button"
                    content="Update"
                    disabled={disableEnrollButton}
                    loading={enableLoading}
                  />
                </div>
              </Form.Row>
            )}
          </>
        )}
      />
    </>
  ) : (
    <>
      <div className="box-description">
        <h3>Enroll in 'Text to Tag'</h3>
        <label>
          Validating your tag before you move the animal means NO PAPER TAG
          needs to be applied. Text to Tag makes validating at the harvest site
          easy! Enter your mobile phone number below and click enroll to sign up
          for this feature.
        </label>
        <br />
        <br />
        <label>
          <strong>
            Note: The hunter must have the phone with them to use this feature
            when reporting their harvest. Phone numbers MUST BE UNIQUE and
            cannot be shared.
          </strong>
        </label>
      </div>
      <Divider />
      <Form
        initialValues={initialValues}
        className="inline"
        onSubmit={onSubmit}
        disableLoading
        render={() => (
          <>
            {fields && (
              <>
                <Form.Row>
                  <Form.InputMasked fieldConfig={fields.phoneNumber} />
                  <div className="field-submission-container">
                    <Form.Button
                      type="submit"
                      className="field-submission-button"
                      content="Enroll"
                      disabled={disableEnrollButton}
                      loading={enableLoading}
                    />
                  </div>
                </Form.Row>
              </>
            )}
          </>
        )}
      />
    </>
  );
};

const ConfirmationCart: React.FC<{
  state: ProductCatalogState;
  shoppingCart: ProductShoppingCartItemDto[] | undefined;
  total: number;
  isReceipt?: boolean;
}> = ({state, shoppingCart, total, isReceipt}) => {
  const {catalogType, convenienceFee, customer, mailingAddress} = state;
  const fetchDonationAmounts = useAsync(async () => {
    if (catalogType && customer) {
      const {result} = await getSelectedDonation[catalogType]({
        isReceiptView: true,
        customerId: customer.id,
      });
      return result;
    }
  });

  const selectedDonations = fetchDonationAmounts.value;

  return (
    <>
      <Table className="confirmation-cart" basic="very" unstackable>
        <Table.Body>
          <ConfirmationCartProductRows
            state={state}
            shoppingCart={shoppingCart}
            isReceipt={isReceipt}
          />
          {selectedDonations &&
            selectedDonations.currentDonations
              ?.filter((donation) => donation.donationAmount > 0)
              .map((donation) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <div className="item-description-container">
                        <span className="product-name">
                          {donation.donationName}
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="right" colSpan="2" collapsing>
                      <span className="product-price">
                        {`$${donation.donationAmount.toFixed(2)}`}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          {catalogType === CatalogType.customer && total > 0 && (
            <Table.Row className="extra-fee-row">
              <Table.Cell className="extra-fee-title">
                <span>Convenience Fee</span>
              </Table.Cell>

              <Table.Cell textAlign="right" colSpan="2" collapsing>
                <span className="extra-fee-price">{`$${convenienceFee?.toFixed(
                  2
                )}`}</span>
              </Table.Cell>
            </Table.Row>
          )}

          {state.tenderType === TenderType.CreditCard && (
            <Table.Row className="extra-fee-row">
              <Table.Cell className="extra-fee-title">
                <span>Credit Card Fee</span>
              </Table.Cell>

              <Table.Cell textAlign="right" colSpan="2" collapsing>
                <span className="extra-fee-price">{`$${state.creditCardFee?.toFixed(
                  2
                )}`}</span>
              </Table.Cell>
            </Table.Row>
          )}

          <Table.Row className="total-cost-row" verticalAlign="top">
            <Table.Cell className="total-cost-title-cell">
              <span className="total-cost-title">Total Amount</span>
            </Table.Cell>

            <Table.Cell textAlign="right" collapsing>
              <span className="total-cost-price">{`$${total.toFixed(2)}`}</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table basic="very" unstackable className="mailing-confirmation-section">
        <CustomerMailingAddressInformation
          shoppingCart={shoppingCart}
          mailingAddress={mailingAddress}
          showAddressUpdateText={true}
          customerId={customer?.id}
        />
      </Table>
    </>
  );
};

const SupportAgencies: React.FC = () => {
  const lawffLink: string = 'https://www.lawff.org/';
  const lwaaLink: string = 'https://supportlwaa.org/';

  return (
    <section className="support-agencies-section">
      <section className="support-agency-first-container">
        <section className="support-agency-image">
          <a href={lawffLink} target='_blank" attribute'>
            <Image src={lawffLogo} />
          </a>
        </section>
        <section className="support-agency-description">
          Help Support our Sportsman’s Paradise! Our work is only possible
          through the generous donations from individuals and corporations.
          Donations are accepted both as undedicated contributions or for
          specific programs, all of which are tax deductible. <br />
          <a href={lawffLink} target='_blank" attribute'>
            Click Here.
          </a>
        </section>
      </section>
      <section className="support-agency-second-container">
        <section className="support-agency-image">
          <a href={lwaaLink} target='_blank" attribute'>
            <Image src={lwaaLogo} />
          </a>
        </section>
        <section className="support-agency-description">
          The LWAA is a 501C charity organization that focuses on donating to
          charities, individuals, and fellow agents and their families during
          times of need. Visit our website to buy merchandise such as hats,
          shirts, and more. Monies go directly to support the efforts of the
          Louisiana Wildlife Agent's Association. <br />
          <a href={lwaaLink} target='_blank" attribute'>
            Click Here.
          </a>
        </section>
      </section>
    </section>
  );
};

const styles = css`
  display: flex;
  flex-direction: column;

  .receipt-section {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
  }

  .info-message-section {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 20px;
    background: ${Theme.palette.green00};

    ${Media('DesktopMin')} {
      width: max-content;
    }

    p {
      color: ${Theme.palette.grey900};
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .organ-donor-section {
    flex: 1 1;
    border-radius: 5px;
    border: solid 1px #d7d6d4;
    padding: 1rem 2.25rem;
    align-self: start;
  }

  .support-agencies-section {
    overflow: hidden;
    margin: 40px 0 10px 0;
    padding: 10px 0 10px 0;
  }

  .support-agency-first-container {
    float: left;
    margin-right: 10px;
    width: 50%;
  }

  .support-agency-second-container {
    overflow: hidden;
    section {
      min-height: 50px;
    }
  }

  .support-agency-image {
    float: left;
    margin-left: 10px;
    width: 50%;
    max-width: 260px;
  }

  .support-agency-description {
    overflow: hidden;
    margin: 0 0 10px 0;
    padding: 0 10px 10px 10px;
  }

  .confirmation-cart-section {
    margin-bottom: 20px;
    max-width: 800px;

    .item-description-container {
      .product-name {
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
      }

      .product-details {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
      }

      .trips-container {
        small {
          color: ${Theme.palette.blue800};
        }
      }
    }

    .product-price {
      color: ${Theme.palette.grey700};
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }

    .extra-fee-row {
      .extra-fee-title {
        color: ${Theme.palette.blue800};
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }

      .extra-fee-price {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
      }
    }

    .total-cost-row {
      border: none !important;

      .total-cost-title-cell {
        padding-bottom: 0;

        .total-cost-title {
          display: block;
          color: ${Theme.palette.blue800};
          font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
            Helvetica, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .total-cost-price {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .mailing-confirmation-section {
    margin-top: -10px;
  }

  .actions-section {
    .ui.button {
      margin: 0 12px 0 0;
      font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial, Helvetica,
        sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: ${Theme.palette.grey700};
    }
  }

  .box-description {
    padding-left: 1.25rem;
  }

  .field-submission-container {
    display: flex;
    align-items: center;
  }

  .field-submission-button {
    background-color: #e0e1e2;
    margin: auto;
    margin-top: 26px;
  }

  .enroll-to-sms-tagging {
    background-color: #b8dbe2;
    margin-top: 3rem;
    border-radius: 5px;
    padding: 1rem;
  }

  .navigation-section {
    padding-top: 45px;
  }

  .primary-button-text {
    color: ${Theme.palette.white1};
  }

  ${Media('WideMin')} {
    .actions-section {
      .ui.button {
        margin: 0 5px 12px 0;
      }
    }

    .support-agencies-section {
      float: none;
      margin-right: 0;
      width: auto;
    }

    .support-agency-first-container,
    .support-agency-image {
      float: none;
      width: auto;
    }

    .support-agency-description {
      overflow: hidden;
      margin: 10px 0 10px 0;
      padding: 10px;
    }
  }

  ${Media('MobileMax')} {
    .support-agency-first-container,
    .support-agency-image {
      float: none;
      margin: 0 auto;
      width: auto;
    }

    .field-submission-button {
      width: 90%;
    }
  }
`;
