import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {ApiResult} from './generated';
import {notify} from '../hooks/use-subscription';
import {LiteralKeyedObject} from '../types';
import {logger} from '../utils/logger';
import {notifications} from '../utils/notification-service';
import {
  ClearAllTableFilters,
  ClearAllTableQuickSearches,
} from '../hooks/use-local-storage';
import Cookies from 'js-cookie';
import {loginCookieKey} from '../cookie-provider';

type HandledResponseCodes = '400' | '401' | '403' | '500';

type Errorhandlers = LiteralKeyedObject<
  HandledResponseCodes,
  (respose: AxiosResponse<any>) => Promise<any> | void
>;

const log = logger('axios');

export const requestInterceptors = compose(preventIEGetCaching);

export const responseInterceptors = (x) => x;

let requestCount = 0;
function preventIEGetCaching(config: AxiosRequestConfig) {
  if (/get/gi.test(config.method || '')) {
    if (config.params) {
      config.params['_ts'] = `${+new Date()}_${++requestCount}`;
    } else {
      config.params = {
        _ts: `${+new Date()}_${++requestCount}`,
      };
    }
  }

  return config;
}

export const defaultErrorhandlers: Errorhandlers = {
  '400': (response) => {
    log.info('Bad Request. Show Errors');
    return Promise.resolve(response);
  },
  '401': (response) => {
    notifications.error('Your session has expired. Please log in again');
    notify('refresh-session', undefined);
    const error: ApiResult<any> = {
      result: null,
      hasErrors: true,
      validationFailures: [
        {
          propertyName: 'Authentication',
          errorMessage: 'Your session has expired. Please log in again',
        },
      ],
    };

    Cookies.remove(loginCookieKey);
    ClearAllTableFilters();
    ClearAllTableQuickSearches();

    response.data = error;

    return Promise.resolve(response);
  },
  '403': (response) => {
    notifications.error('You are not authorized to perform this action');
  },
  '500': (response) => {
    notifications.error(
      'Something went wrong. Our team has been notified and will investigate.'
    );

    log.error('Error 500', response.data);

    const error: ApiResult<any> = {
      result: null,
      hasErrors: true,
      validationFailures: [
        {
          propertyName: '',
          errorMessage: 'Something went wrong.',
        },
      ],
    };

    response.data = error;

    return Promise.resolve(response);
  },
};

let errorHandlers = {
  ...defaultErrorhandlers,
};

export const setErrorHandlers = (handlers: Partial<Errorhandlers>) => {
  Object.keys(handlers).forEach((key) => {
    errorHandlers[key] = handlers[key];
  });
};

export async function handleResponseError(error: AxiosError) {
  if (error.response) {
    const response: AxiosResponse = error.response;
    const handler = errorHandlers[response.status];
    if (handler) {
      const result = await handler(error.response);
      if (result) {
        return result;
      }
    }
  }
  return Promise.reject(error);
}

type composeFn<T> = (x: T) => T | Promise<T>;

function compose<T>(...fns: composeFn<T>[]) {
  return async (x: T | Promise<T>): Promise<T> =>
    fns.reduce((v, f) => (async () => f(await v))(), x);
}
