import {faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAsync, useLocation} from 'react-use';
import {
  Button,
  Divider,
  Grid,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import {BoatRenewalDto, BoatRenewalService} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {initialValues} from '../../components/change-password-form';
import {ExportButton} from '../../components/export-button';
import {fieldConfig, input} from '../../forms/schema-utils';
import {routes} from '../../routes/config';
import {cstNow} from '../../utils/date-time-helpers';
import {Form} from '../../forms';
import css from '@emotion/css';
import {notifications} from '../../utils/notification-service';
import {Media} from '../../styles/breakpoints';

const transactionReceiptDetails: {transactionId: number | undefined} = {
  transactionId: 0,
};

type UpdateEmailFieldConfigDto = {
  emailAddress: string;
};

const useUpdateEmailFields = (email: string | undefined) =>
  fieldConfig<UpdateEmailFieldConfigDto>({
    emailAddress: input({
      fieldLabel: 'Email Address',
      inputProps: {
        defaultValue: email,
      },
      fieldRequired: true,
    }),
  });

export const BoatRenewalPaymentReceipt: React.FC<{}> = () => {
  const location = useLocation();
  const registrationInfo = location?.state?.state
    .registration as BoatRenewalDto;

  const history = useHistory();

  const fetchBoatTransaction = useAsync(async () => {
    const {result} = await BoatRenewalService.getBoatRenewalTransactionById({
      boatRenewalId: Number(registrationInfo.id),
    });
    return result;
  }, [registrationInfo.id]);
  const boatTransaction = fetchBoatTransaction.value;

  transactionReceiptDetails.transactionId = boatTransaction?.transactionId;

  const fields = useUpdateEmailFields(registrationInfo.emailAddress);

  const resendEmailReceipt = async (values) => {
    const response = await BoatRenewalService.resendBoatRenewalTransactionReceiptEmail(
      {
        body: {
          boatRenewalDetails: boatTransaction!,
          emailAddress: values.emailAddress,
        },
      }
    );

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Email receipt sent');
  };

  const convenienceFee = boatTransaction?.convenienceFee ?? 0;
  const total = (registrationInfo?.amount ?? 0) + convenienceFee;
  const formattedLongBoatNumber = registrationInfo?.longBoatNumber?.replace(
    /^LA(\d{4})([a-zA-Z]{2})$/,
    'LA $1-$2'
  );

  return (
    <BasicPage title="Payment Receipt">
      <Segment css={styles}>
        <Message color="green">
          Payment Successful on {boatTransaction?.renewalDate}.
        </Message>
        <Grid.Row>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <h3>Payment Details</h3>
            <p>Please print this receipt for your records</p>
          </Grid.Column>
        </Grid.Row>
        <Table basic compact unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <div>
                  <b>Registration Number:</b> {formattedLongBoatNumber}
                </div>
              </Table.Cell>
              <Table.Cell textAlign="right">
                ${boatTransaction?.amount.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div>
                  <b>Convenience Fee:</b>
                </div>
              </Table.Cell>
              <Table.Cell textAlign="right">
                ${convenienceFee.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div>
                  <b>Total:</b>
                </div>
              </Table.Cell>
              <Table.Cell textAlign="right">${total.toFixed(2)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Grid.Row className="update-email">
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <div className="box-description">
              <h3>Resend Email</h3>
              <label>
                Below is the email address that the receipt for this transaction
                was sent to. If this is incorrect, you may enter the correct
                email here to have the receipt resent.
              </label>
            </div>
            <Divider hidden />
            <Form
              initialValues={initialValues}
              className="inline"
              //Prevents Form component from throwing error when removed. Does not accept null
              onSubmit={resendEmailReceipt}
              render={() => (
                <>
                  <Form.Row>
                    <Form.Input fieldConfig={fields.emailAddress} />
                    <div className="update-email-button">
                      <Form.Button type="submit" primary>
                        Update Email Address
                      </Form.Button>
                    </div>
                  </Form.Row>
                </>
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <ExportButton
          action={BoatRenewalService.transactionReceiptPrint}
          fileName={`TransactionReceipt-${cstNow().format('MM/DD/YY-hh:m')}`}
          request={transactionReceiptDetails}
          label={' Print Receipt'}
          icon={faFilePdf}
        />
        <Button
          onClick={() => {
            history.replace(routes.salesLanding);
          }}
          content={'Back to Home'}
        />
        <Button
          onClick={() => {
            history.replace(routes.boatRenewal.boatRenewalLookup);
          }}
          content={'Back to Search'}
        />
      </Segment>
    </BasicPage>
  );
};

const styles = css`
  .update-email {
    background-color: #b8dbe2;
    margin-top: 1rem;
    border-radius: 5px;
  }

  .update-email-button {
    margin-right: 1rem;
    margin-top: 1.75rem;
  }

  .box-description {
    padding: 1.25rem;
  }

  ${Media('MobileMax')} {
    .update-email-button {
      margin: 1.25rem;
      padding-bottom: 1rem;
    }
  }
`;
