import {useAsync} from 'react-use';
import {Overwrite} from 'utility-types';
import {
  CustomerOptionsService,
  IspCreateCustomerCommand,
} from '../../../api/generated';
import {
  CountryCode,
  EthnicCode,
  GenderCode,
  StateCode,
  SuffixCode,
} from '../../../api/generated/enums';
import {
  getEnumDropdownOptions,
  getOptionDtoDropdownOptions,
} from '../../../api/generated/utils';
import {
  AddressProps,
  customerGetAddressDto,
} from '../../../forms/address-fields';
import {
  checkbox,
  datepicker,
  dropdown,
  fieldConfig,
  input,
  masked,
} from '../../../forms/schema-utils';
import {populateDropdownWithNumberRange} from '../../../utils/dropdown-helpers';

export const PersonalInformationFieldConfigDtoKeys = {
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  suffixCode: 'suffixCode',
  dateOfBirth: 'dateOfBirth',
  socialSecurityNumberStatusCode: 'socialSecurityNumberStatusCode',
  socialSecurityNumber: 'socialSecurityNumber',
  driversLicenseNumber: 'driversLicenseNumber',
  passportNumber: 'passportNumber',
  driversLicenseStateCode: 'driversLicenseStateCode',
  passportCountryCode: 'passportCountryCode',
};
type PersonalInformationFieldConfigDtoPick = keyof typeof PersonalInformationFieldConfigDtoKeys;
export type PersonalInformationFieldConfigDto = Pick<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  PersonalInformationFieldConfigDtoPick
>;

export const ContactInformationFieldConfigDtoKeys = {
  phoneNumberSecondary: 'phoneNumberSecondary',
  phoneNumberPrimary: 'phoneNumberPrimary',
  emailAddress: 'emailAddress',
  physicalAddress: 'physicalAddress',
  mailingAddress: 'mailingAddress',
};

type ContactInformationFieldConfigDtoPick = keyof typeof ContactInformationFieldConfigDtoKeys;
export type ContactInformationFieldConfigDto = Pick<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  ContactInformationFieldConfigDtoPick
>;

export const CharacteristicsFieldConfigDtoKeys = {
  hairColorCodeId: 'hairColorCodeId',
  eyeColorCodeId: 'eyeColorCodeId',
  weight: 'weight',
  ethnicCode: 'ethnicCode',
  heightInches: 'heightInches',
  heightFeet: 'heightFeet',
  genderCode: 'genderCode',
};

type CharacteristicsFieldConfigDtoPick = keyof typeof CharacteristicsFieldConfigDtoKeys;
export type CharacteristicsFieldConfigDto = Pick<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  CharacteristicsFieldConfigDtoPick
>;

export const usePersonalInformationFields = () => {
  return fieldConfig<PersonalInformationFieldConfigDto>({
    firstName: input({
      fieldLabel: 'First Name',
      fieldRequired: true,
    }),
    middleName: input({
      fieldLabel: 'Middle Name',
    }),
    lastName: input({
      fieldLabel: 'Last Name',
      fieldRequired: true,
    }),
    suffixCode: dropdown({
      fieldLabel: 'Suffix',
      inputProps: {
        options: getEnumDropdownOptions(SuffixCode),
        placeholder: 'Select a Suffix...',
        selection: true,
        clearable: true,
        search: true,
      },
    }),
    dateOfBirth: datepicker({
      fieldLabel: 'Birth Date',
      fieldRequired: true,
    }),
    socialSecurityNumberStatusCode: checkbox({
      defaultValue: false,
      inputProps: {
        label: 'I do not have a Social Security Number',
      },
    }),
    socialSecurityNumber: masked({
      fieldLabel: 'SSN',
      fieldRequired: true,
      inputProps: {
        autoComplete: 'off',
        options: {
          blocks: [3, 2, 4],
          delimiter: '-',
        },
      },
    }),
    driversLicenseNumber: input({
      fieldLabel: 'DL or ID Card Number',
      inputProps: {},
    }),
    driversLicenseStateCode: dropdown({
      fieldLabel: 'DL or ID Card State',
      inputProps: {
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
        clearable: true,
        search: true,
      },
    }),
    passportNumber: input({
      fieldLabel: 'Alternate ID #',
      inputProps: {
        placeholder: 'Non-US Citizens Only',
      },
    }),
    passportCountryCode: dropdown({
      fieldLabel: 'Country of Origin',
      fieldRequired: true,
      inputProps: {
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
        clearable: true,
        search: true,
      },
    }),
  });
};

export const useContactInformationFields = (
  routeLocation: 'isp' | 'vendor' | 'admin'
) => {
  return useAsync(async () => {
    return fieldConfig<ContactInformationFieldConfigDto>({
      phoneNumberPrimary: masked({
        fieldLabel: 'Primary Number',
        fieldRequired: true,
        inputProps: {
          options: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          },
        },
      }),
      phoneNumberSecondary: masked({
        fieldLabel: 'Secondary Number',
        inputProps: {
          options: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          },
        },
      }),
      emailAddress: input({
        fieldLabel: 'Email',
        fieldRequired: routeLocation === 'isp',
      }),
      physicalAddress: await customerGetAddressDto(),
      mailingAddress: await customerGetAddressDto(),
    });
  }, [routeLocation]);
};

export const useCharacteristicsFields = () => {
  return useAsync(async () => {
    const {result} = await CustomerOptionsService.getCustomerOptions();

    return fieldConfig<CharacteristicsFieldConfigDto>({
      genderCode: dropdown({
        fieldLabel: 'Gender',
        fieldRequired: true,
        inputProps: {
          options: getEnumDropdownOptions(GenderCode).filter(
            (x) => x.value !== GenderCode.UNKNOWN
          ),
          placeholder: 'Select a Gender...',
          selection: true,
          search: true,
        },
      }),
      hairColorCodeId: dropdown({
        fieldLabel: 'Hair Color',
        inputProps: {
          options: getOptionDtoDropdownOptions(result?.hairColorCodes),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select a Hair Color...',
        },
      }),
      eyeColorCodeId: dropdown({
        fieldLabel: 'Eye Color',
        inputProps: {
          options: getOptionDtoDropdownOptions(result?.eyeColorCodes),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select an Eye Color...',
        },
      }),
      weight: input({
        fieldLabel: 'Weight',
        inputProps: {
          type: 'number',
        },
      }),
      ethnicCode: dropdown({
        fieldLabel: 'Ethnicity',
        inputProps: {
          search: true,
          options: getEnumDropdownOptions(EthnicCode),
          placeholder: 'Select an Ethnicity...',
          selection: true,
          clearable: true,
        },
      }),
      heightFeet: dropdown({
        fieldLabel: 'Height (ft)',
        inputProps: {
          options: populateDropdownWithNumberRange(3, 7),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select Feet...',
        },
      }),
      heightInches: dropdown({
        fieldLabel: 'Height (in)',
        inputProps: {
          options: populateDropdownWithNumberRange(0, 11),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select Inches...',
        },
      }),
    });
  });
};
