import css from '@emotion/css';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useAsync, useLocation, useMedia} from 'react-use';
import {Button} from 'semantic-ui-react';
import {
  CommercialLicenseListItemDto,
  CommercialLicenseRenewalService,
  TransactionCustomerService,
} from '../../api/generated';
import {Flex} from '../../components/flex';
import {routes} from '../../routes/config';
import {StyledPageContainer} from '../../styled-page-container';
import {MediaSizes} from '../../styles/breakpoints';
import {cstNow} from '../../utils/date-time-helpers';

export const CommercialLicenseListing = () => {
  const location = useLocation();
  const history = useHistory();
  const locationState = location.state.state;
  const licenseNumber = locationState.licenseNumber;
  const searchedDateTime = cstNow().toISOString();

  const [cartItems, setCartItems] = useState<CommercialLicenseListItemDto[]>(
    []
  );

  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  function toggleCartItem(item) {
    let cartItemArr = [...cartItems];
    const matchingItem = cartItemArr?.filter(
      (license) => license.id === item.id
    );

    if (!matchingItem || matchingItem.length === 0) {
      cartItemArr?.push(item);
    } else if (matchingItem && matchingItem[0] !== null) {
      cartItemArr = cartItemArr.filter((cartItem) => cartItem.id !== item.id);
    }
    setCartItems(cartItemArr);
  }

  function isInCart(id: number) {
    return cartItems.filter((cartItem) => cartItem.id === id).length > 0;
  }

  const fetchCustomerCommercialLicenses = useAsync(async () => {
    const customerCommercialLicenses = await CommercialLicenseRenewalService.getAllCommercialLicenses(
      {
        body: {licenseNumber: licenseNumber},
      }
    );

    if (customerCommercialLicenses.hasErrors) {
      return;
    }
    return customerCommercialLicenses.result;
  }, [licenseNumber]);

  const fetchConvenienceFee = useAsync(async () => {
    const {result} = await TransactionCustomerService.getConvenienceFee();
    return result;
  }, []);

  const convenienceFee = fetchConvenienceFee.value ?? 0;

  const commercialLicenses = fetchCustomerCommercialLicenses.value;

  return (
    <StyledPageContainer title="Select License(s) to Renew" css={styles}>
      <Flex.Row className="page-container">
        <Flex.Col className="licenses-container">
          {commercialLicenses?.map((item) => {
            return (
              <Flex.Col className="commercial-license-row">
                <Flex.Row className="information-container">
                  <Flex.Col className="button-container">
                    <Button
                      className="add-remove-button"
                      content={isInCart(item.id) ? 'Remove' : 'Add'}
                      primary
                      onClick={() => {
                        toggleCartItem(item);
                      }}
                    />
                  </Flex.Col>
                  <Flex.Col className="information-row">
                    {item.description}
                    <br />
                    <i>
                      <b className="license-listing-subtext">
                        LIC#{item.licenseNumber}
                      </b>
                    </i>
                  </Flex.Col>{' '}
                  <Flex.Col className="information-row cost-row">
                    ${item.fees.toFixed(2)}
                  </Flex.Col>
                </Flex.Row>
              </Flex.Col>
            );
          })}
        </Flex.Col>
        {isMobile && <div className="break" />}
        <Flex.Col className="cart-container">
          <h3>Shopping Cart</h3>
          {cartItems.length === 0 && (
            <p className="empty-cart-message">Your cart is empty</p>
          )}
          {cartItems.map((item) => {
            return (
              <Flex.Row>
                <Flex.Col className="cart-description-column">
                  {item.description}
                  <br />
                  <p className="cart-item-subtext">LIC# {item.licenseNumber}</p>
                </Flex.Col>{' '}
                <Flex.Col className="cart-cost-column">
                  ${item.fees.toFixed(2)}
                </Flex.Col>
              </Flex.Row>
            );
          })}
          {cartItems.length > 0 && (
            <div className="total-row">
              <b>Convenience Fee: {convenienceFee.toFixed(2)}</b>
              <br />
              <b className="total-col">
                {' '}
                Total:{' $'}
                {(
                  cartItems
                    .map((item) => item.fees)
                    .reduce((prev, next) => prev + next) + convenienceFee
                ).toFixed(2)}
              </b>
            </div>
          )}
          <div className="button-container">
            <Button
              primary
              disabled={cartItems.length === 0}
              content="Continue to Payment"
              onClick={() =>
                history.replace(
                  routes.commercialLicenseRenewal
                    .commercialLicensePaymentConfirmation,
                  {
                    cartItems: cartItems,
                    searchedDateTime: searchedDateTime,
                  }
                )
              }
            />
            <Button
              secondary
              content="Cancel"
              onClick={() => history.goBack()}
            />
          </div>
        </Flex.Col>
      </Flex.Row>
    </StyledPageContainer>
  );
};

const styles = css`
  .page-container {
    display: flex;
    flex-wrap: wrap;

    .break {
      flex-basis: 100%;
      width: 0;
    }

    .cart-container {
      @media only screen and (min-width: 767px) {
        width: 25%;
      }

      .empty-cart-message {
        color: grey;
        font-style: italic;
      }

      .total-row {
        text-align: right;

        .total-col {
          font-size: 18px;
        }
      }

      .cart-description-column {
        width: 75%;
        display: inline-block;

        .cart-item-subtext {
          font-size: 12px;
          color: grey;
          margin-bottom: 5px;
        }
      }

      .cart-cost-column {
        margin-left: 2em;
      }

      .button-container {
        margin-top: 15px;
      }
    }

    .licenses-container {
      width: 75%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      .commercial-license-row {
        margin-top: 10px;

        .information-container {
          width: 100%;

          .information-row {
            display: inline-block;
            width: 50%;
            @media only screen and (max-width: 767px) {
              width: 100%;
            }
            margin-left: 2em;
            margin-top: 10px;
          }

          .cost-row {
            margin-left: 5em;
            width: 50%;
          }

          .button-container {
            width: 15%;
            @media only screen and (max-width: 767px) {
              width: 100px;
            }
            .add-remove-button {
              margin-top: 10%;
            }
          }

          .license-listing-subtext {
            font-size: 12px;
            color: grey;
          }
        }
      }
    }
  }
`;
