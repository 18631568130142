import {faCheck, faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Button, Divider, Grid, Message, Modal} from 'semantic-ui-react';
import {
  AdminCustomerAutoRenewalService,
  AutoRenewService,
} from '../../../../api/generated';
import {DateFormat, DateTimeFormat} from '../../../../components/date';
import {Form} from '../../../../forms';
import {notifications} from '../../../../utils/notification-service';
import {
  productListItemStyles,
  additionalInformationTextStyles,
  noProductsMessageStyles,
} from '../styles';
import {ProductRenewalPrice} from './product-renewal-price';

export const EnrolledProductListing: React.FC<{
  isAdmin?: boolean;
  enrolledProducts: any;
  fetchProducts: any;
  customerId?: number;
  isTryAgainButtonDisabled?: boolean;
  convenienceFee: number | undefined;
}> = ({
  isAdmin,
  enrolledProducts,
  fetchProducts,
  customerId,
  isTryAgainButtonDisabled,
  convenienceFee,
}) => {
  const [
    customerAutoRenewProduct,
    setCustomerAutoRenewProduct,
  ] = useState<any>();

  const [
    isUnenrollmentModalOpen,
    setIsUnenrollmentModalOpen,
  ] = useState<boolean>(false);

  const [isRetryingAutoRenew, setIsRetryingAutoRenew] = useState<boolean>(
    false
  );

  const onUnenrollClick = async () => {
    const result = isAdmin
      ? await AdminCustomerAutoRenewalService.unenrollProduct({
          customerId: customerId,
          customerAutoRenewProductId: customerAutoRenewProduct?.id,
        })
      : await AutoRenewService.unenrollProduct({
          customerAutoRenewProductId: customerAutoRenewProduct?.id,
        });

    if (result.hasErrors) {
      notifications.error(
        `There was an issue disabling auto-renew for ${customerAutoRenewProduct.name}`
      );
      return;
    }

    setIsUnenrollmentModalOpen(false);
    notifications.success(
      `Successfully disabled auto-renew for ${customerAutoRenewProduct.name}`
    );
    fetchProducts.retry();
  };

  const failedProductCount = enrolledProducts?.reduce(function (n, product) {
    return n + (product.renewalWasSuccessful === false);
  }, 0);

  return (
    <>
      {!isAdmin && failedProductCount > 0 && (
        <>
          <Button
            disabled={isTryAgainButtonDisabled || isRetryingAutoRenew}
            loading={isRetryingAutoRenew}
            onClick={async () => {
              setIsRetryingAutoRenew(true);
              const result = await AutoRenewService.retryAutoRenewProcess();

              if (result.result === false) {
                notifications.error(
                  'Auto-Renew Failed. See products below for more info.'
                );
              } else {
                notifications.success('Auto-Renew was successful!');
              }

              setIsRetryingAutoRenew(false);
              fetchProducts.retry();
            }}
            content={'Try All Again'}
          />
        </>
      )}
      <Form.Container>
        <Grid columns={2}>
          {enrolledProducts && enrolledProducts?.length > 0 ? (
            enrolledProducts?.map((product, index) => (
              <>
                <Grid.Row>
                  <Grid.Column verticalAlign="middle">
                    <b>
                      <p
                        style={productListItemStyles}
                      >{`${product.name} (${product.productCode})`}</p>
                    </b>

                    <p style={additionalInformationTextStyles}>
                      {'Expires after: '}
                      <DateFormat date={product.expirationDate} />
                      <br />
                      {'Renews on: '}
                      <DateFormat date={product.nextRenewDate} />
                    </p>
                    <ProductRenewalPrice
                      price={product.currentPrice}
                      convenienceFee={convenienceFee}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Button
                      negative
                      disabled={isRetryingAutoRenew}
                      content="Disable Auto-Renew"
                      onClick={() => {
                        setCustomerAutoRenewProduct(product);
                        setIsUnenrollmentModalOpen(true);
                      }}
                    />
                  </Grid.Column>
                  {product.lastAttemptedRenewalTimestamp &&
                    product.renewalWasSuccessful === false && (
                      <Message error>
                        {isAdmin ? (
                          <>
                            Auto-Renew failed for this product at{' '}
                            <DateTimeFormat
                              datetime={product.lastAttemptedRenewalTimestamp}
                            />
                            . Ensure that the payment method on the account is
                            valid and accurate if one of the products has a fee.
                            <br />
                            <br />
                            <RenewalErrorListing product={product} />
                            <br />
                          </>
                        ) : (
                          <>
                            Auto-Renew failed for this product at{' '}
                            <DateTimeFormat
                              datetime={product.lastAttemptedRenewalTimestamp}
                            />
                            . Ensure that the payment method on the account is
                            valid and accurate if one of the products has a fee.
                            Click "Try All Again" to re-execute auto-renew for
                            any failed products. You must have an auto-renew
                            payment method on record if one of the products has
                            a fee in order to try again. If this problem
                            persists, call support for further assistance.
                            <br />
                            <br />
                            <RenewalErrorListing product={product} />
                            <br />
                          </>
                        )}
                      </Message>
                    )}
                  {product.successfulRenewalTimestamp &&
                    product.renewalWasSuccessful === true && (
                      <Message success>
                        <FontAwesomeIcon icon={faCheck} /> Last successfully
                        renewed{' '}
                        <DateTimeFormat
                          datetime={product.successfulRenewalTimestamp}
                        />{' '}
                      </Message>
                    )}
                </Grid.Row>
                {index !== enrolledProducts?.length - 1 && <Divider />}
              </>
            ))
          ) : (
            <p style={noProductsMessageStyles}>NO PRODUCTS TO DISPLAY</p>
          )}
        </Grid>
      </Form.Container>
      <Modal
        onClose={() => setIsUnenrollmentModalOpen(false)}
        onOpen={() => setIsUnenrollmentModalOpen(true)}
        open={isUnenrollmentModalOpen}
        size="small"
      >
        <Modal.Header>Disable Auto-Renew Confirmation</Modal.Header>
        <Modal.Content>
          <p>
            Disable auto-renew for <b>{customerAutoRenewProduct?.name}</b>?
          </p>
          <p>
            Doing this will prevent the product from automatically renewing
            after its expiration. In order to continue to own the product after
            expiration, you will need to purchase it again through the product
            catalog or re-enable auto-renew.
          </p>
          <Divider hidden />
          <p>You may enable auto-renew for this product again at any time. </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            type="button"
            content={'Disable'}
            onClick={onUnenrollClick}
          />
          <Button
            secondary
            type="button"
            onClick={() => setIsUnenrollmentModalOpen(false)}
            content={'Cancel'}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const RenewalErrorListing: React.FC<{
  product: any;
}> = ({product}) => {
  return (
    <b>
      {(product.additionalInformation &&
        product.additionalInformation !== '' &&
        product.additionalInformation.split('//').map((error) => {
          if (error === '') {
            return null;
          }
          return (
            <>
              <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
              <br />
            </>
          );
        })) ??
        'PROCESS FAILURE: Failed for unknown reason(s)'}
    </b>
  );
};
