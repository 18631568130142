import React, {useState} from 'react';
import {Divider, Icon, Message, Modal, Table} from 'semantic-ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {Media} from '../../styles/breakpoints';
import css from '@emotion/css/macro';
import {
  ShoppingCartDto,
  LotteryShoppingCartItemDto,
  LotteryShoppingCartService,
  ProductShoppingCartItemDto,
} from '../../api/generated';
import {notifications} from '../../utils/notification-service';
import {routes} from '../../routes/config';
import {useHistory} from 'react-router-dom';
import {DateFormat} from '../../components/date';
import {StyledButton} from '../../styled-components/styled-buttons';
import {Typography} from '../../styled-components/typography';
import {Theme} from '../../theme';
import {Flex} from '../../components/flex';

export const ShoppingCart: React.FC<{
  cartContents: ShoppingCartDto | undefined;
  cartRefresh: boolean;
  refreshCart: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({cartContents, cartRefresh, refreshCart}) => {
  const [
    lotteryItemToDelete,
    setLotteryItemToDelete,
  ] = useState<LotteryShoppingCartItemDto | null>(null);

  const [
    donationToDelete,
    setDonationToDelete,
  ] = useState<ProductShoppingCartItemDto | null>(null);

  const [isCheckingExpiration, setIsCheckingExpiration] = useState<boolean>();

  const history = useHistory();

  const removeCartItem = async (
    cartItemId: number,
    isDonation: boolean = false
  ) => {
    setIsCheckingExpiration(true);
    const expirationResponse = await LotteryShoppingCartService.isCartExpired({
      shoppingCartId: cartContents?.id,
    });
    setIsCheckingExpiration(false);

    if (expirationResponse.result === true) {
      notifications.error('Your cart is expired');
      refreshCart(!cartRefresh);
      return;
    }

    const response = await LotteryShoppingCartService.removeItem({
      id: cartItemId,
    });

    if (!isDonation) {
      setLotteryItemToDelete(null);
    } else {
      setDonationToDelete(null);
    }

    if (response.hasErrors) {
      notifications.error(
        'Something went wrong removing that item. Try refreshing the page'
      );
    } else {
      notifications.success('Item removed');
    }

    refreshCart(!cartRefresh);
  };

  const continueToCheckout = async () => {
    setIsCheckingExpiration(true);
    const response = await LotteryShoppingCartService.isCartExpired({
      shoppingCartId: cartContents?.id,
    });
    setIsCheckingExpiration(false);

    if (response.result === true) {
      notifications.error('Your cart is expired');
      refreshCart(!cartRefresh);
    } else {
      history.push(routes.customer.lotteryApplicationsPaymentConfirmation);
    }
  };

  let cartTotal =
    !cartContents || cartContents.lotteries?.length === 0
      ? 0
      : cartContents.lotteries
          ?.filter((lottery) => !lottery.isApplicationFeeWaived)
          .reduce(function (prev, curr) {
            return prev + curr.itemTotal;
          }, 0) ?? 0;

  const cartHasCost =
    cartTotal > 0 ||
    (cartContents && (cartContents?.donations?.[0]?.donationAmount ?? 0) > 0);

  const DeleteButton = ({onClick}) => (
    <FontAwesomeIcon
      color="red"
      icon={faTrash}
      className="delete"
      title={'Remove item'}
      onClick={onClick}
    />
  );

  return (
    <Flex.Col css={styles}>
      <div className="cart-header-container">
        <Typography variant="heading2" color={Theme.palette.blue800}>
          Your Cart
        </Typography>
      </div>

      {!cartContents ||
      !cartContents.lotteries ||
      cartContents.lotteries.length === 0 ? (
        <Message info className="empty-cart-message-container" size="big">
          <Icon name="cart" size="big" />
          <Typography variant="body1" color={Theme.palette.blue700}>
            Your Cart is currently empty.
          </Typography>
        </Message>
      ) : (
        <Table className="shopping-cart-table" basic="very" unstackable>
          <Table.Body>
            {cartContents.lotteries.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <Table.Row>
                    <Table.Cell>
                      <ShoppingCartLotteryItemInfo lottery={item} />
                    </Table.Cell>
                    <Table.Cell>
                      <Typography
                        variant="heading4"
                        color={Theme.palette.grey700}
                      >
                        {item.itemTotal > 0 && !item.isApplicationFeeWaived
                          ? `$${item.itemTotal.toFixed(2)}`
                          : 'Free'}
                      </Typography>
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <DeleteButton
                        onClick={() => setLotteryItemToDelete(item)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              );
            })}
            <ConfirmLotteryItemRemovalModal />
            {cartContents &&
              cartContents.donations &&
              cartContents.donations.map((donationItem) => {
                return (
                  <React.Fragment key={donationItem.id}>
                    {(donationItem.donationAmount ?? 0) > 0 && (
                      <Table.Row>
                        <Table.Cell>
                          <Typography
                            variant="heading4"
                            color={Theme.palette.blue800}
                          >
                            {donationItem.product?.name}
                          </Typography>
                        </Table.Cell>
                        <Table.Cell>
                          <Typography
                            variant="heading4"
                            color={Theme.palette.grey700}
                          >
                            ${(donationItem.donationAmount ?? 0).toFixed(2)}
                          </Typography>
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <DeleteButton
                            onClick={() => setDonationToDelete(donationItem)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </React.Fragment>
                );
              })}
            <ConfirmDonationRemovalModal />
            {cartContents && cartContents.lotteries.length > 0 && (
              <>
                {((cartContents.fee !== 0 && cartTotal > 0) ||
                  (cartContents?.donations?.[0]?.donationAmount ?? 0) > 0) && (
                  <CostRow
                    costName={'Transaction Fee'}
                    cost={cartContents.fee}
                  />
                )}
                <TotalRow
                  cost={
                    //If there is nothing in the cart, we want a 0 total
                    //Donations, however, still warrant a convenience fee in the cart
                    cartHasCost
                      ? cartTotal +
                        cartContents.fee +
                        (cartContents?.donations?.[0]?.donationAmount ?? 0)
                      : 0
                  }
                />
                <Table.Row>
                  <Table.Cell textAlign="left" colSpan="3">
                    <StyledButton
                      primary
                      content={cartHasCost ? 'Checkout' : 'Continue'}
                      disabled={isCheckingExpiration}
                      loading={isCheckingExpiration}
                      onClick={continueToCheckout}
                    />
                    <StyledButton
                      buttonColor={Theme.palette.grey00}
                      buttonHoverColor={Theme.palette.grey100}
                      content={'Cancel'}
                      onClick={() => history.push(routes.customer.dashboard)}
                    />
                  </Table.Cell>
                </Table.Row>
              </>
            )}
          </Table.Body>
        </Table>
      )}
    </Flex.Col>
  );

  function ConfirmLotteryItemRemovalModal() {
    return (
      <Modal
        open={lotteryItemToDelete !== null}
        onClose={() => setLotteryItemToDelete(null)}
        size={'tiny'}
      >
        <Modal.Header>Confirm Removal</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Are you sure you want to remove this item from your cart?</p>
          </Modal.Description>
          <Divider />
          <Modal.Description>
            {lotteryItemToDelete && (
              <ShoppingCartLotteryItemInfo lottery={lotteryItemToDelete} />
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <StyledButton
            content="Cancel"
            onClick={() => setLotteryItemToDelete(null)}
          />
          {lotteryItemToDelete && (
            <StyledButton
              content="Remove"
              primary
              onClick={() => removeCartItem(lotteryItemToDelete.id)}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }

  function ConfirmDonationRemovalModal() {
    return (
      <Modal
        open={donationToDelete !== null}
        onClose={() => setDonationToDelete(null)}
        size={'tiny'}
      >
        <Modal.Header>Confirm Donation Removal</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Are you sure you want to remove this donation from your cart?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <StyledButton
            content="Cancel"
            onClick={() => setDonationToDelete(null)}
          />
          {donationToDelete && (
            <StyledButton
              content="Remove"
              primary
              onClick={() => removeCartItem(donationToDelete.id, true)}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
};

const ShoppingCartLotteryItemInfo: React.FC<{
  lottery: LotteryShoppingCartItemDto;
}> = ({lottery}) => {
  //If more than one in cart, format will be <Name><(xAmount)> e.g. Cart Item (x2)
  const cartItemDisplayName = `${lottery.lotteryName} ${
    lottery.quantity > 1 ? ` (x${lottery.quantity})` : ''
  }`;
  return (
    <>
      <div>
        <Typography variant="heading4" color={Theme.palette.blue800}>
          {cartItemDisplayName}
        </Typography>
      </div>
      {lottery.isLeftover || lottery.isAward ? (
        <Typography variant="small" color={Theme.palette.grey700}>
          {lottery.hunts?.map((hunt) => {
            return (
              <li key={hunt.id}>
                <Typography variant="small" color={Theme.palette.grey700}>
                  {hunt.name} (
                  <DateFormat date={hunt.startDate} />
                  {' - '}
                  <DateFormat date={hunt.endDate} />)
                  <br />
                </Typography>
              </li>
            );
          })}
        </Typography>
      ) : (
        <Typography variant="small" color={Theme.palette.grey700}>
          Draw Date: <DateFormat date={lottery.drawDate} />
        </Typography>
      )}
    </>
  );
};

const CostRow: React.FC<{
  costName: string;
  cost: number | undefined;
}> = ({costName, cost}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Typography variant="heading4" color={Theme.palette.blue800}>
          {costName}
        </Typography>
      </Table.Cell>
      <Table.Cell colSpan="3">
        <Typography variant="heading4" color={Theme.palette.grey700}>
          ${cost?.toFixed(2)}
        </Typography>
      </Table.Cell>
    </Table.Row>
  );
};

const TotalRow: React.FC<{
  cost: number | undefined;
}> = ({cost}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Typography variant="heading3" color={Theme.palette.blue800}>
          Total
        </Typography>
      </Table.Cell>
      <Table.Cell colSpan="3">
        <Typography variant="heading3" color={Theme.palette.grey700}>
          ${cost?.toFixed(2)}
        </Typography>
      </Table.Cell>
    </Table.Row>
  );
};

const styles = css`
  padding-right: 70px;
  align-items: flex-end;

  .cart-header-container {
    width: 400px;
  }

  .shopping-cart-table {
    max-width: 400px;
  }

  .delete {
    cursor: pointer;
  }

  .empty-cart-message-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cart.icon {
      margin-bottom: 19px;
    }
  }

  .ui.info.message {
    padding: 40px;
  }

  ${Media('WideMin')} {
    padding-right: 0;
    align-items: center;

    &.ui.segment {
      margin: 0 -1rem;
      border-radius: 0;
    }

    .cart-header-container {
      width: 80%;
    }
  }
`;
