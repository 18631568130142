import css from '@emotion/css/macro';
import React from 'react';
import {Header} from 'semantic-ui-react';
import {Theme} from '../theme';
import {Media, PageMaxWidth} from '../styles/breakpoints';

type StyledPageTitle = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  fluid?: boolean;
  url?: string;
};

export const StyledPageTitle: React.FC<StyledPageTitle> = ({
  title,
  subtitle,
  fluid,
}) => {
  return (
    <section className="page-title" css={styles}>
      <Header
        as="h1"
        className={`page-header ${fluid ? 'full-width' : 'padded'}`}
      >
        {title}
        {!!subtitle && (
          <Header.Subheader as="span">{subtitle}</Header.Subheader>
        )}
      </Header>
    </section>
  );
};

const styles = css`
  background: ${Theme.palette.grey00};
  border-bottom: 1px solid ${Theme.palette.grey100};
  padding: 48px 0 24px;
  margin: 0 0 44px;

  .page-header {
    margin: 0 auto;
    font-weight: normal;
    padding-left: 20px;

    &.padded {
      max-width: ${PageMaxWidth.Padded}px !important;
    }

    &.full-width {
      max-width: ${PageMaxWidth.FullWidth}px !important;
    }

    &.ui.header {
      font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial, Helvetica,
        sans-serif;
      font-weight: 600;
      color: ${Theme.palette.blue800};
      font-size: 26px;
      line-height: 36px;
    }

    .sub.header {
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      color: ${Theme.palette.grey700};
      font-size: 14px !important;
      line-height: 130%;
    }
  }

  ${Media('WideMin')} {
    padding: 20px 10px 15px;
    margin: 0 0 18px;

    .page-header {
      margin: 0 auto;
      font-weight: normal;

      &.padded {
        max-width: ${PageMaxWidth.Mobile}px !important;
      }

      &.full-width {
        max-width: ${PageMaxWidth.Mobile}px !important;
      }

      &.ui.header {
        font-size: 18px;
        line-height: 24px;
      }

      .sub.header {
        font-size: 12px !important;
        line-height: 14px;
      }
    }
  }
`;
