import React, {ReactNode} from 'react';
import {useAsync} from 'react-use';
import {CustomerOnlineCourseService} from '../../api/generated';
import {Redirect} from 'react-router';
import {Loader} from 'semantic-ui-react';
import {routes} from '../../routes/config';
import {notifications} from '../../utils/notification-service';

export const OnlineCourseWrapper: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const isOnlineCourseEnabled = useAsync(async () => {
    var response = await CustomerOnlineCourseService.isOnlineCourseEnabled();
    if (response.hasErrors) {
      notifications.error('Unable to fetch online courses');
    }

    return response.result;
  });

  if (isOnlineCourseEnabled.loading) {
    return <Loader inline="centered" active />;
  }
  if (!isOnlineCourseEnabled.value || isOnlineCourseEnabled.error) {
    return <Redirect to={routes.events} />;
  }
  return <>{children}</>;
};
