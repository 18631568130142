export const getMatchingEnumKey = (enumToSearch: object, key: string) =>
  Object.keys(enumToSearch).find((x) => enumToSearch[x] === key);

export const getLength = (obj: object) => {
  if (Object.values(obj).length === 0) {
    return 0;
  }
  if (!Object.values(obj).includes(0)) {
    return Object.keys(obj).length;
  }
  return Object.keys(obj).length / 2;
};
