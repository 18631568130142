import {faCaretRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  useAsync,
  useAsyncFn,
  useAsyncRetry,
  useInterval,
  useMedia,
} from 'react-use';
import {
  Checkbox,
  Divider,
  Dropdown,
  Message,
  Table,
  TableRow,
} from 'semantic-ui-react';
import {adminRoutes} from '../../admin/routes/config';
import {
  AccountService,
  AdminCustomerAutoRenewalService,
  AutoRenewService,
  BillingDetailsDto,
  CatalogProductDto,
  CreateTransactionForHqVendorCommand,
  CreateTransactionReturnDtoApiResult,
  CurrentDonationDto,
  CurrentDonationsDto,
  CustomerAutoRenewPaymentMethodDetailsDto,
  CustomerCatalogService,
  CustomerInformationDto,
  DonationTypeCode,
  LotteryCheckoutService,
  ProductRuleDto,
  ProductRuleTypeCode,
  ProductShoppingCartItemDto,
  TenderType,
  TransactionCustomerService,
  UserDto,
  VendorCatalogService,
  VendorTransactionService,
  VerifyProductTransactionDto,
} from '../../api/generated';
import {CountryCode, StateCode} from '../../api/generated/enums';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import disabledVeteransLogo from '../../assets/images/disabled-veterans-logo.png';
import huntersForTheHungryLogo from '../../assets/images/hunters-for-the-hungry-logo.png';
import {useUser} from '../../auth/use-auth';
import {DateFormat} from '../../components/date';
import {Env} from '../../config/env-vars';
import {Form} from '../../forms';
import {
  RawFieldConfig,
  checkbox,
  dropdown,
  fieldConfig,
  getDefaults,
  input,
  masked,
} from '../../forms/schema-utils';
import {routes} from '../../routes/config';
import {StyledPageContainer} from '../../styled-page-container';
import {MediaSizes} from '../../styles/breakpoints';
import {
  cstNow,
  forceCstOffsetAndStartOfDay,
  momentCst,
} from '../../utils/date-time-helpers';
import {useImportedScript} from '../../utils/import-script';
import {notifications} from '../../utils/notification-service';
import {CustomerMailingAddressInformation} from '../mailing-address/mailing-address-info';
import {
  DonationProductCode,
  Donations,
  donationsAndPaymentStyles,
} from '../payment/donation-selection';
import {
  CatalogType,
  ProductCatalogState,
  ProductConstantCode,
  addDonation,
  catalogRoutes,
  getSelectedDonation,
  getUpsoldProductsNotIncluded,
  isIncludedByInCart,
  isPrereqInCart,
  updateVendorCart,
  useProductCatalogState,
} from './product-catalog-utils';
import {EarlyRenewalConfirmation} from './purchase-confirmation-early-renewal-confirmation';

declare const PayWithConverge: any;

const autoRenewalUnavailableMessage = {color: 'lightgray', fontStyle: 'italic'};

type Error = {
  errorMessage?: string;
  propertyName?: string;
};

type ValidationFailures = {
  hasDonationError: boolean;
  hasTenderTypeError: boolean;
  hasEarlyRenewalConfirmationError: boolean;
  errors: Error[];
};

type CheckNumberDto = Pick<CreateTransactionForHqVendorCommand, 'checkNumber'>;
type CashDto = Pick<
  CreateTransactionForHqVendorCommand,
  'pivNumber' | 'depositDate'
>;

const getCheckField = () =>
  fieldConfig<CheckNumberDto>({
    checkNumber: masked({
      inputProps: {
        options: {
          blocks: [10],
          numericOnly: true,
        },
      },
    }),
  });

const getCashField = () =>
  fieldConfig<CashDto>({
    pivNumber: masked({
      fieldRequired: true,
    }),
    depositDate: masked({
      inputProps: {
        placeholder: 'MM/DD/YYYY',
        type: 'tel',
        options: {
          numericOnly: true,
          blocks: [2, 2, 4],
          delimiter: '/',
        },
      },
    }),
  });

interface BillingDetailsAndAutoRenewDto extends BillingDetailsDto {
  updateAutoRenewPaymentMethodInfo: boolean;
}

const useFields = (customer: CustomerInformationDto | null) =>
  fieldConfig<BillingDetailsAndAutoRenewDto>({
    firstName: input({
      fieldLabel: 'First Name',
      defaultValue: customer && customer.firstName,
      fieldRequired: true,
      inputProps: {
        maxLength: 20,
      },
    }),
    lastName: input({
      fieldLabel: 'Last Name',
      defaultValue: customer && customer.lastName,
      fieldRequired: true,
      inputProps: {
        maxLength: 20,
      },
    }),
    streetAddress: input({
      fieldLabel: 'Street Address',
      defaultValue:
        customer &&
        customer.physicalAddress &&
        customer.physicalAddress.street1,
      fieldRequired: true,
      inputProps: {
        maxLength: 50,
      },
    }),
    city: input({
      fieldLabel: 'City',
      defaultValue:
        customer && customer.physicalAddress && customer.physicalAddress.city,
      fieldRequired: true,
      inputProps: {
        maxLength: 30,
      },
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      defaultValue:
        customer &&
        customer.physicalAddress &&
        customer.physicalAddress.stateCode,
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      fieldRequired: true,
      defaultValue:
        customer &&
        customer.physicalAddress &&
        customer.physicalAddress.zipCode,
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
      },
    }),
    updateAutoRenewPaymentMethodInfo: checkbox({
      fieldLabel: 'Make this my new Auto-Renewal Payment Method',
      fieldRequired: true,
      defaultValue: false,
      inputProps: {
        toggle: true,
      },
    }),
  });

export const PurchaseConfirmation = () => {
  const user = useUser();

  const [
    selectedHuntersForHungryDonation,
    setSelectedHuntersForHungryDonation,
  ] = useState<CurrentDonationDto>();

  const [
    huntersForHungryDonationAmount,
    setHuntersForHungryDonationAmount,
  ] = useState<number>(0);

  const [
    selectedDisabledVetsDonation,
    setSelectedDisabledVetsDonations,
  ] = useState<CurrentDonationDto>();

  const [
    disabledVetsDonationAmount,
    setDisabledVetsDonationAmount,
  ] = useState<number>(0);

  const [isPageDisabled, setIsPageDisabled] = useState<boolean>(false);

  const [
    isEarlyRenewalPurchaseConfirmed,
    setIsEarlyRenewalPurchasedConfirmed,
  ] = useState<boolean>(false);

  const [
    validationFailures,
    setValidationFailures,
  ] = useState<ValidationFailures>({
    hasDonationError: false,
    hasTenderTypeError: false,
    hasEarlyRenewalConfirmationError: false,
    errors: [],
  });

  const updatePivNumber = (pivNumber: string) => {
    setState((draft) => {
      draft.pivNumber = pivNumber;
    });
  };

  const updateDepositDate = (depositDate: string) => {
    setState((draft) => {
      draft.depositDate = forceCstOffsetAndStartOfDay(depositDate);
    });
  };

  const history = useHistory();
  const {
    catalogState,
  }: {
    catalogState: ProductCatalogState;
  } = history.location.state;

  const {
    context: {state, setState},
  } = useProductCatalogState({
    prevCatalogState: catalogState,
  });

  const {catalogType, tenderType} = state;
  let {customer} = state;

  const fetchCustomer = useAsync(async () => {
    return catalogType === CatalogType.customer
      ? await LotteryCheckoutService.get()
      : null;
  });

  if (fetchCustomer?.value?.result?.customerInformation) {
    customer = fetchCustomer.value.result.customerInformation;
  }

  const fields = getCheckField();
  const pivFields = getCashField();

  const fetchPivNumber = useAsync(async () => {
    if (
      catalogType === CatalogType.hqVendor ||
      catalogType === CatalogType.admin
    ) {
      var response = await VendorTransactionService.getVendorPivNumber({
        body: {
          vendorId: user.vendorId ?? 0,
        },
      });
      if (response.hasErrors) {
        response.validationFailures?.forEach((err) => {
          notifications.error(err.errorMessage);
        });
        return response;
      }
      setState((draft) => {
        draft.pivNumber = response.result?.pivNumber || null;
        draft.depositDate = response.result?.depositDate
          ? forceCstOffsetAndStartOfDay(
              moment(response.result?.depositDate).format('MM/DD/YYYY')
            )
          : null;
      });
      return response;
    }
    return null;
  }, [catalogType, setState, user.vendorId]);

  const pivNumber = fetchPivNumber.value?.result?.pivNumber;
  const depositDate = fetchPivNumber.value?.result?.depositDate;

  const fetchSelectedDonations = useAsyncRetry(async () => {
    if (catalogType && customer) {
      const {result} = await getSelectedDonation[catalogType]({
        customerId: customer.id,
        isReceiptView: false,
      });
      return result;
    }
  }, [catalogType, customer]);

  const selectedDonations = fetchSelectedDonations.value;

  const updateTenderType = (newTenderType: TenderType) => {
    setState((draft) => {
      draft.tenderType = newTenderType;
    });
    if (state.customer?.id) {
      updateVendorCart(setState, state.customer?.id, newTenderType);
    }
  };

  const updateCheckNumber = (checkNumber: number) => {
    setState((draft) => {
      draft.checkNumber = checkNumber;
    });
  };

  const tenderTypeOptions = {
    [TenderType.Cash]: TenderType.Cash,
    [TenderType.Check]: TenderType.Check,
    [TenderType.CreditCard]: TenderType.CreditCard,
  };

  const isAdmin = catalogType === CatalogType.admin;
  const isVendor =
    catalogType === CatalogType.vendor || catalogType === CatalogType.hqVendor;

  const fetchCustomerShoppingCart = useAsyncRetry(async () => {
    const cart =
      isAdmin || isVendor
        ? await VendorCatalogService.getShoppingCart({
            customerId: customer?.id,
          })
        : await CustomerCatalogService.getShoppingCart();
    if (cart.hasErrors) {
      return;
    }
    return cart.result;
  }, [isAdmin, isVendor, customer]);

  const shoppingCart = fetchCustomerShoppingCart.value?.products;

  const {creditCardFee} = state;
  const total =
    (fetchCustomerShoppingCart.value?.total ?? 0) + (creditCardFee ?? 0);

  const fetchIsAutoRenewEnabled = useAsync(async () => {
    const {result} = await AutoRenewService.getIsEnabled();
    return result;
  }, []);

  const isAutoRenewEnabled = fetchIsAutoRenewEnabled.value ?? false;

  const fetchPaymentMethod = useAsync(async () => {
    const {result} = isAdmin
      ? await AdminCustomerAutoRenewalService.getPaymentMethod({
          customerId: customer?.id,
        })
      : await AutoRenewService.getPaymentMethod();
    return result;
  });

  const fetchConvenienceFee = useAsync(async () => {
    const {result} = await TransactionCustomerService.getConvenienceFee();
    return result;
  }, []);

  const convenienceFee = fetchConvenienceFee.value;

  const savedPaymentMethod = fetchPaymentMethod.value;

  const [
    shoppingCartHasEarlyRenewalProduct,
    setShoppingCartHasEarlyRenewalProduct,
  ] = useState<boolean>(false);

  useEffect(() => {
    const selectedDisabledVetsDonation = fetchSelectedDonations.value?.currentDonations?.find(
      (donation) =>
        donation.donationProductCode ===
        DonationProductCode.DisabledVeteransDonation
    );

    const selectedHuntersForHungryDonation = fetchSelectedDonations.value?.currentDonations?.find(
      (donation) =>
        catalogType === CatalogType.admin
          ? donation.donationProductCode ===
            DonationProductCode.HuntersForTheHungryDonationHq
          : donation.donationProductCode ===
            DonationProductCode.HuntersForTheHungryDonation
    );

    if (shoppingCart) {
      const cartHasEarlyRenewalProduct =
        shoppingCart?.filter((cartItem) => cartItem.isEarlyRenewal).length > 0;

      setShoppingCartHasEarlyRenewalProduct(cartHasEarlyRenewalProduct);
    }

    setSelectedHuntersForHungryDonation(selectedHuntersForHungryDonation);
    setSelectedDisabledVetsDonations(selectedDisabledVetsDonation);
  }, [
    huntersForHungryDonationAmount,
    disabledVetsDonationAmount,
    fetchSelectedDonations,
    catalogType,
    shoppingCart,
  ]);

  return (
    <StyledPageContainer
      title="Checkout"
      subtitle="Review your details and enter your payment method"
    >
      <main css={donationsAndPaymentStyles}>
        <section className="customer-information-section">
          <CustomerInformation customer={customer} />
        </section>
        {state && (
          <section className="confirmation-cart-section">
            <ConfirmationCart
              selectedDonations={selectedDonations}
              state={state}
              shoppingCart={shoppingCart}
              total={total}
              savedPaymentMethod={savedPaymentMethod}
              isAutoRenewEnabled={isAutoRenewEnabled}
            />
          </section>
        )}
        {isAutoRenewEnabled && (
          <section className="auto-renew-message-section">
            <Message>
              <h3>Important Auto-Renew Information</h3>
              <p>
                Automatic renewal is available for most hunting and fishing
                licenses. Auto-renew allows you to store payment information in
                the system and elect to have your license(s) renewed
                automatically every year.
              </p>
              <p>
                You can select which annual licenses you’d like to auto-renew.
                Add-on licenses are only eligible for auto-renew once
                prerequisite licenses have been selected.
              </p>
              <p>
                'LDWF will automatically renew your licenses at the purchase
                price plus a ${convenienceFee?.toFixed(2)} convenience fee. You
                can view your renewal dates, manage your payment method, and
                turn auto-renewal on or off at any time through your account.'
              </p>
              <p>
                You will receive reminders to the email on file 30 days and 7
                days prior to the scheduled renewal.
              </p>
            </Message>
          </section>
        )}
        {catalogType && customer && (
          <section className="donations-section">
            <div>
              <Donations
                donationAmount={
                  selectedHuntersForHungryDonation?.donationAmount
                }
                setDonationAmount={(value) => {
                  setHuntersForHungryDonationAmount(value);
                  fetchSelectedDonations.retry();
                }}
                addProductCartDonation={addDonation[catalogType]}
                customerId={customer.id}
                donationType={DonationTypeCode.Checkout}
                setState={setState}
                catalogType={state.catalogType}
                tenderType={state.tenderType}
                retryCartFetch={fetchCustomerShoppingCart}
                donationText={
                  'Would you like to donate to Hunters for the Hungry?'
                }
                donationProductCode={
                  catalogType === CatalogType.admin
                    ? DonationProductCode.HuntersForTheHungryDonationHq
                    : DonationProductCode.HuntersForTheHungryDonation
                }
                donationLogo={huntersForTheHungryLogo}
                isExternallyDisabled={isPageDisabled}
              />
              <Donations
                donationAmount={selectedDisabledVetsDonation?.donationAmount}
                setDonationAmount={(value) => {
                  setDisabledVetsDonationAmount(value);
                  fetchSelectedDonations.retry();
                }}
                addProductCartDonation={addDonation[catalogType]}
                customerId={customer.id}
                donationType={DonationTypeCode.Catalog}
                setState={setState}
                catalogType={state.catalogType}
                tenderType={state.tenderType}
                retryCartFetch={fetchCustomerShoppingCart}
                donationText={
                  'Would you like to help purchase a Hunting and Fishing License for a disabled veteran by donating to the Disabled Veterans License Fund? '
                }
                donationProductCode={
                  DonationProductCode.DisabledVeteransDonation
                }
                donationLogo={disabledVeteransLogo}
                isExternallyDisabled={isPageDisabled}
              />
            </div>
          </section>
        )}
        {shoppingCartHasEarlyRenewalProduct && (
          <EarlyRenewalConfirmation
            isEarlyRenewalPurchaseConfirmed={isEarlyRenewalPurchaseConfirmed}
            setIsEarlyRenewalPurchasedConfirmed={
              setIsEarlyRenewalPurchasedConfirmed
            }
          />
        )}
        {(catalogType === CatalogType.hqVendor ||
          catalogType === CatalogType.admin) &&
          total > 0 && (
            <section className="payment-method-section">
              <Form
                onSubmit={() => {}}
                render={() => (
                  <>
                    <div>
                      <h1>
                        <label>Payment Method</label>
                        <label className="required-indicator">*</label>
                      </h1>

                      <Dropdown
                        fluid
                        search
                        selection
                        options={getEnumDropdownOptions(tenderTypeOptions)}
                        onChange={(e, {value}) => {
                          updateTenderType(TenderType[value as string]);
                        }}
                      />
                    </div>
                    {tenderType === TenderType.Check && (
                      <>
                        <Divider hidden />
                        <div>
                          <h1>
                            <label>Check Number</label>
                            <label className="required-indicator">*</label>
                          </h1>

                          <Form.InputMasked
                            fieldConfig={fields.checkNumber}
                            onChange={(e) => {
                              updateCheckNumber(+e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {tenderType === TenderType.Cash && (
                      <>
                        <Divider hidden />
                        <div>
                          <h1>
                            <label>PIV Number</label>
                            <label className="required-indicator">*</label>
                          </h1>

                          <Form.InputMasked
                            fieldConfig={pivFields.pivNumber}
                            defaultValue={state.pivNumber}
                            readOnly={!!pivNumber}
                            onChange={(e) => {
                              updatePivNumber(e.target.value);
                            }}
                          />

                          <h1>
                            <label>Deposit Date</label>
                            <label className="required-indicator">*</label>
                          </h1>
                          <Form.InputMasked
                            fieldConfig={pivFields.depositDate}
                            defaultValue={
                              depositDate
                                ? moment(depositDate).format('MM/DD/YYYY')
                                : null
                            }
                            readOnly={!!depositDate}
                            onChange={(e) => {
                              updateDepositDate(e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              />
            </section>
          )}
        <section className="credit-card-info-section">
          <CreditCardInformation
            state={state}
            huntersForHungryDonation={selectedHuntersForHungryDonation}
            disabledVeteransDonation={selectedDisabledVetsDonation}
            validationFailures={validationFailures}
            setValidationFailures={setValidationFailures}
            total={total}
            savedPaymentMethod={savedPaymentMethod}
            setIsPageDisabled={setIsPageDisabled}
            isEarlyRenewalPurchaseConfirmed={isEarlyRenewalPurchaseConfirmed}
            shoppingCartHasEarlyRenewalProduct={
              shoppingCartHasEarlyRenewalProduct
            }
          />
        </section>
      </main>
    </StyledPageContainer>
  );
};

const modifyCart = async (
  history: any,
  state: ProductCatalogState,
  user: UserDto
) => {
  const {licensesAndPermitsRoutes} = catalogRoutes;

  const pathname = history.location.pathname.includes('admin')
    ? adminRoutes.vendors.issueDuplicates
    : routes.vendor.issueDuplicates;

  if (
    (state.catalogType === CatalogType.vendor ||
      state.catalogType === CatalogType.hqVendor ||
      state.catalogType === CatalogType.admin) &&
    history.location.state.from?.includes('issue-duplicates')
  ) {
    history.replace(pathname, {
      isHqVendor:
        state.catalogType === CatalogType.hqVendor ||
        state.catalogType === CatalogType.admin,
      isManager: user.claims?.includes('Vendor - Manager'),
      customer: state.customer,
      vendorId: user.vendorId,
    });
  } else if (state.catalogType) {
    let catalogState = _.cloneDeep(state);
    catalogState.tenderType = null;

    history.push(licensesAndPermitsRoutes[state.catalogType], {
      catalogState: catalogState,
      customer: state.customer,
      isHqVendor:
        state.catalogType === CatalogType.hqVendor ||
        state.catalogType === CatalogType.admin,
    });
  }
};

type ConfirmationCartProps = {
  state: ProductCatalogState;
  selectedDonations: CurrentDonationsDto | undefined;
  shoppingCart: any;
  total: number;
  savedPaymentMethod: CustomerAutoRenewPaymentMethodDetailsDto | undefined;
  isAutoRenewEnabled: boolean;
};

const ConfirmationCart: React.FC<ConfirmationCartProps> = ({
  state,
  selectedDonations,
  shoppingCart,
  total,
  savedPaymentMethod,
  isAutoRenewEnabled,
}) => {
  const history = useHistory();
  const user = useUser();
  const {
    catalogType,
    convenienceFee,
    creditCardFee,
    tenderType,
    mailingAddress,
    customer,
  } = state;
  const [productIdsInAutoRenew, setProductIdsInAutoRenew] = useState<number[]>(
    []
  );
  const [
    allProductInAutoRenewIsFree,
    setAllProductInAutoRenewIsFree,
  ] = useState<boolean>(true);
  const isVendor =
    state.catalogType === CatalogType.vendor ||
    state.catalogType === CatalogType.hqVendor;

  const isAdmin = state.catalogType === CatalogType.admin;
  const paymentMethodMessage = isAdmin
    ? 'This customer does not have a payment method set up for auto-renew. Notify them that before auto-renewal will work, they will need to log in to their customer portal and add a payment method.'
    : 'You do not have a payment method saved for auto-renew. If you choose to opt any products below into auto-renew, the card you use to complete the transaction will be saved as the payment method to be used for renewals. This can be removed or updated at a later time from your dashboard.';

  return (
    <>
      <h1>Your Cart</h1>
      {!isVendor &&
        productIdsInAutoRenew &&
        productIdsInAutoRenew.length > 0 &&
        !savedPaymentMethod &&
        !allProductInAutoRenewIsFree && (
          <Message warning>{paymentMethodMessage}</Message>
        )}
      <Table className="confirmation-cart" basic="very" unstackable>
        <Table.Header>
          <Table.Row>
            {isAutoRenewEnabled && (
              <>
                <Table.Cell></Table.Cell>
                {!isVendor && (
                  <Table.Cell textAlign={'center'} width={'5'}>
                    Enable Auto-Renewal?
                  </Table.Cell>
                )}
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <ConfirmationCartProductRows
            state={state}
            isVendor={isVendor}
            shoppingCart={shoppingCart}
            isAutoRenewEnabled={isAutoRenewEnabled}
            productIdsInAutoRenew={productIdsInAutoRenew}
            setProductIdsInAutoRenew={setProductIdsInAutoRenew}
            setAllProductInAutoRenewIsFree={setAllProductInAutoRenewIsFree}
          />
          {selectedDonations &&
            selectedDonations.currentDonations
              ?.filter((donation) => donation.donationAmount > 0)
              .map((donation) => {
                return (
                  <TableRow>
                    <Table.Cell>
                      <div className="item-description-container">
                        <span className="product-name">
                          {donation.donationName}
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign="right" colSpan="2" collapsing>
                      <span className="product-price">
                        {`$${donation.donationAmount.toFixed(2)}`}
                      </span>
                    </Table.Cell>
                  </TableRow>
                );
              })}
          {(catalogType === CatalogType.customer && total > 0 && (
            <Table.Row className="extra-fee-row">
              <Table.Cell>
                <span className="extra-fee-title">Convenience Fee</span>
              </Table.Cell>

              <Table.Cell textAlign="right" collapsing colSpan="4">
                <span className="extra-fee-price">{`$${convenienceFee?.toFixed(
                  2
                )}`}</span>
              </Table.Cell>
            </Table.Row>
          )) ||
            ((catalogType === CatalogType.hqVendor ||
              catalogType === CatalogType.admin) &&
              tenderType === TenderType.CreditCard && (
                <Table.Row className="extra-fee-row">
                  <Table.Cell>
                    <span className="extra-fee-title">Credit Card Fee</span>
                  </Table.Cell>

                  <Table.Cell textAlign="right" colSpan="4" collapsing>
                    <span className="extra-fee-price">{`$${creditCardFee?.toFixed(
                      2
                    )}`}</span>
                  </Table.Cell>
                </Table.Row>
              ))}
          <Table.Row className="total-cost-row" verticalAlign="top">
            <Table.Cell className="cell-with-action">
              <span className="total-cost-title">Total Amount</span>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing colSpan="4">
              <span className="total-cost-price">{`$${total?.toFixed(
                2
              )}`}</span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table basic="very" unstackable className="edit-button">
        <Table.Body>
          <Table.Row>
            <Form.Button
              className="edit-cart-button"
              type="button"
              onClick={() => modifyCart(history, state, user)}
            >
              <span className="edit-cart-button-text">Edit</span>
            </Form.Button>
          </Table.Row>
          <div className="customer-mailing-address-info">
            <CustomerMailingAddressInformation
              shoppingCart={shoppingCart}
              mailingAddress={mailingAddress}
              showAddressUpdateText={false}
              customerId={customer?.id}
            />
          </div>
        </Table.Body>
      </Table>
    </>
  );
};

export const ConfirmationCartProductRows: React.FC<{
  state: ProductCatalogState;
  isVendor?: boolean;
  shoppingCart: ProductShoppingCartItemDto[] | undefined;
  isReceipt?: boolean;
  isAutoRenewEnabled?: boolean;
  productIdsInAutoRenew?: number[];
  setProductIdsInAutoRenew?: React.Dispatch<React.SetStateAction<number[]>>;
  setAllProductInAutoRenewIsFree?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({
  state,
  isVendor,
  shoppingCart,
  isReceipt,
  isAutoRenewEnabled,
  productIdsInAutoRenew,
  setProductIdsInAutoRenew,
  setAllProductInAutoRenewIsFree,
}) => {
  const isUpsold = (product: CatalogProductDto) => {
    let isUpsold = false;
    let upsoldProducts = getUpsoldProductsNotIncluded(state, shoppingCart);
    upsoldProducts?.forEach((x) => {
      if (x.product?.code === product.code) {
        isUpsold = true;
      }
    });
    return isUpsold;
  };

  const getImageCodesWithQuantity = (item: ProductShoppingCartItemDto) => {
    const imageCodesWithQuantity: Record<string, number> = {};

    if (
      item &&
      item.productImageIds &&
      item.product &&
      item.product.productImages
    ) {
      item.productImageIds.forEach((id) => {
        let image;
        if (item.product && item.product.productImages) {
          image = item.product.productImages.find((img) => img.id === id);
        }

        if (image) {
          imageCodesWithQuantity[image.imageCode] =
            (imageCodesWithQuantity[image.imageCode] ?? 0) + 1;
        }
      });
    }

    return imageCodesWithQuantity;
  };

  useEffect(() => {
    const feeOfAutoRenewEnabledProducts =
      shoppingCart
        ?.filter(
          (value) =>
            value.productId && productIdsInAutoRenew?.includes(value.productId)
        )
        ?.reduce((acc, val) => acc + (val.product?.productFeeTotal ?? 0), 0) ||
      0;
    if (setAllProductInAutoRenewIsFree !== undefined) {
      feeOfAutoRenewEnabledProducts > 0
        ? setAllProductInAutoRenewIsFree(false)
        : setAllProductInAutoRenewIsFree(true);
    }
  }, [shoppingCart, productIdsInAutoRenew, setAllProductInAutoRenewIsFree]);

  return (
    <>
      {shoppingCart &&
        shoppingCart
          .filter(
            (itemInCart) =>
              itemInCart.product &&
              (!itemInCart.product.isIncludable ||
                isUpsold(itemInCart.product) ||
                !isPrereqInCart(itemInCart.product, shoppingCart) ||
                (isPrereqInCart(itemInCart.product, shoppingCart) &&
                  !isIncludedByInCart(itemInCart.product, shoppingCart)))
          )
          .map((itemInCart) => {
            const product = itemInCart.product;

            let includedProducts: CatalogProductDto[] = [];
            const includedProductRules: ProductRuleDto[] =
              product?.productRules?.filter(
                (rule) =>
                  rule.productRuleTypeCode === ProductRuleTypeCode.Includes
              ) ?? [];

            const isReplacementProduct =
              itemInCart.product?.code === ProductConstantCode.duplicate;

            const isDuplicateLifetimeProduct =
              itemInCart.product?.code ===
              ProductConstantCode.lifetimeDuplicate;

            if (includedProductRules.length > 0) {
              shoppingCart
                .filter((x) => x.product?.isIncludable)
                .forEach((itemInCart) => {
                  includedProductRules.forEach((rule) => {
                    if (
                      itemInCart.product &&
                      itemInCart.product.code === rule.appliedProductCode
                    ) {
                      includedProducts.push(itemInCart.product);
                    }
                  });
                });
            }

            const replacedTransactionCustomerProduct =
              itemInCart.replacedTransactionCustomerProduct;

            const isReplacementOrLifetimeDuplicateCartItem =
              isReplacementProduct || isDuplicateLifetimeProduct;

            return (
              <Table.Row key={product?.id}>
                <Table.Cell>
                  <div className="item-description-container">
                    <span className="product-name">
                      {product?.name}
                      {itemInCart.quantity > 1
                        ? ` (X${itemInCart.quantity})`
                        : ''}
                    </span>
                    {includedProducts.length > 0 &&
                      includedProducts.map((includedProduct) => {
                        return (
                          <div key={includedProduct.id}>
                            <FontAwesomeIcon size="sm" icon={faCaretRight} />
                            <span className="product-details">
                              {includedProduct.name}
                            </span>
                          </div>
                        );
                      })}
                    {itemInCart.isEarlyRenewal && (
                      <span className="product-details">
                        {` - Early Renewal`}
                      </span>
                    )}
                    {isReplacementOrLifetimeDuplicateCartItem && (
                      <div
                        key={replacedTransactionCustomerProduct?.id}
                        className="included-product"
                      >
                        <FontAwesomeIcon size="sm" icon={faCaretRight} />
                        <span className="product-details">
                          {
                            replacedTransactionCustomerProduct?.replacedProductName
                          }
                        </span>
                        {isDuplicateLifetimeProduct && (
                          <span className="product-details">
                            {` - Duplicate`}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {(itemInCart?.startDates?.length ?? 0) > 0 ||
                  (itemInCart?.serialNumbers?.length ?? 0) > 0 ? (
                    _.times(itemInCart.quantity, (index) => {
                      if (itemInCart.product?.isStartDateSelectable) {
                        return (
                          <div className="trips-container">
                            {itemInCart.startDates?.[index] ? (
                              <>
                                <small>{`Trip ${index + 1}:  `}</small>
                                <small className="muted">
                                  <DateFormat
                                    date={itemInCart.startDates[index]}
                                  />
                                  {' - '}
                                  <DateFormat
                                    date={momentCst(
                                      itemInCart.startDates[index]
                                    )
                                      .add(
                                        (product?.numberOfDaysAllowed ?? 0) - 1,
                                        'days'
                                      )
                                      .toDate()}
                                  />
                                </small>
                              </>
                            ) : (
                              <small className="error-text">
                                {`Trip ${index + 1} date is required`}
                              </small>
                            )}
                          </div>
                        );
                      } else if (itemInCart.product?.isSerialized) {
                        return (
                          <div className="trips-container">
                            {itemInCart.serialNumbers?.[index] ? (
                              <>
                                <small>{`Serial Number ${index + 1}:  `}</small>
                                <small className="muted">
                                  {itemInCart.serialNumbers[index]}
                                </small>
                              </>
                            ) : (
                              <small className="error-text">
                                {`Trip ${index + 1} date is required`}
                              </small>
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <small>
                            There was an issue getting the information.
                          </small>
                        );
                      }
                    })
                  ) : isReplacementOrLifetimeDuplicateCartItem ? (
                    <small className="muted">
                      {`Valid through `}
                      <DateFormat
                        date={
                          replacedTransactionCustomerProduct?.replacedProductLicenseValidStartDate ??
                          ''
                        }
                      />
                      {' - '}
                      <DateFormat
                        date={
                          replacedTransactionCustomerProduct?.replacedProductLicenseValidEndDate ??
                          ''
                        }
                      />
                    </small>
                  ) : (
                    <>
                      {itemInCart.product?.productImages?.length !== 0 &&
                        Object.entries(
                          getImageCodesWithQuantity(itemInCart)
                        ).map(([imageCode, quantity]) =>
                          itemInCart.product?.productImages?.length === 1 ? (
                            <></>
                          ) : (
                            <div className="trips-container" key={imageCode}>
                              <small className="muted">{imageCode}</small>
                              {quantity > 1 && (
                                <small className="muted">{` (X${quantity})`}</small>
                              )}
                            </div>
                          )
                        )}
                      <small className="muted">
                        {`Valid through `}
                        <DateFormat
                          date={
                            product?.licenseValidEndDate ??
                            momentCst(cstNow()).add(1, 'years').calendar()
                          }
                        />
                      </small>
                    </>
                  )}
                </Table.Cell>

                <RowItem
                  state={state}
                  isReceipt={isReceipt}
                  cartItem={itemInCart}
                  isVendor={isVendor}
                  isAutoRenewEnabled={isAutoRenewEnabled}
                  productIdsInAutoRenew={productIdsInAutoRenew}
                  setProductIdsInAutoRenew={setProductIdsInAutoRenew}
                />

                <Table.Cell textAlign="right" colSpan="2" collapsing>
                  <span className="product-price">
                    {itemInCart.donationAmount ? (
                      <span className="product-price">
                        {`$${itemInCart.donationAmount.toFixed(2)}`}
                      </span>
                    ) : (
                      <span className="product-price">
                        {`$${itemInCart.itemTotal.toFixed(2)}`}
                      </span>
                    )}
                  </span>
                </Table.Cell>
              </Table.Row>
            );
          })}
    </>
  );
};

export const RowItem: React.FC<{
  state: ProductCatalogState;
  isReceipt?: boolean;
  isVendor?: boolean;
  cartItem: ProductShoppingCartItemDto;
  isAutoRenewEnabled?: boolean;
  productIdsInAutoRenew?: number[];
  setProductIdsInAutoRenew?: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({
  state,
  isReceipt,
  isVendor,
  cartItem,
  isAutoRenewEnabled,
  productIdsInAutoRenew,
  setProductIdsInAutoRenew,
}) => {
  const [isChecked, setIsChecked] = useState(cartItem.isAutoRenewEnabled);
  const {customer} = state;
  const isAdmin = state.catalogType === CatalogType.admin;

  useEffect(() => {
    if (!productIdsInAutoRenew || !setProductIdsInAutoRenew) {
      return;
    }

    const listOfIds = _.clone(productIdsInAutoRenew) ?? [];
    if (
      cartItem.productId &&
      (!listOfIds ||
        (listOfIds.indexOf(cartItem.productId) === -1 &&
          !isReceipt &&
          isChecked))
    ) {
      listOfIds.push(cartItem.productId);
      setProductIdsInAutoRenew(listOfIds);
    }
  }, [
    cartItem.productId,
    isReceipt,
    productIdsInAutoRenew,
    setProductIdsInAutoRenew,
    isChecked,
  ]);

  return (
    <>
      {!isReceipt && isAutoRenewEnabled && !isVendor && (
        <Table.Cell textAlign="center" colSpan="2" collapsing>
          {cartItem.product?.isEligibleForAutoRenew ? (
            <div className="auto-renew-container">
              OFF
              <Checkbox
                className="bold-toggle cart-item-ar-toggle"
                checked={isChecked}
                toggle
                onChange={async (e, data) => {
                  const targetValue = data.checked ?? false;
                  const result = isAdmin
                    ? await AdminCustomerAutoRenewalService.toggleAutoRenewal({
                        shoppingCartItemId: cartItem.id,
                        isEnabled: targetValue,
                        customerId: customer?.id,
                      })
                    : await CustomerCatalogService.toggleAutoRenewal({
                        shoppingCartItemId: cartItem.id,
                        isEnabled: targetValue,
                      });

                  if (result.hasErrors) {
                    notifications.error(
                      `There was an issue ${
                        targetValue ? 'enabling' : 'disabling'
                      } auto-renewal for this item. Please contact support for further assistance.`
                    );

                    setIsChecked(!targetValue);
                    return;
                  }

                  const ids = _.clone(productIdsInAutoRenew) ?? [];

                  if (
                    targetValue &&
                    ids.indexOf(cartItem.productId!) === -1 &&
                    setProductIdsInAutoRenew
                  ) {
                    ids.push(cartItem.productId!);
                    setProductIdsInAutoRenew(ids);
                  }

                  setIsChecked(targetValue);
                }}
              />
              ON
            </div>
          ) : (
            <>{!isVendor && <p style={autoRenewalUnavailableMessage}>N/A</p>}</>
          )}
        </Table.Cell>
      )}
    </>
  );
};

type CreditCardInformationProps = {
  state: ProductCatalogState;
  huntersForHungryDonation: CurrentDonationDto | undefined;
  disabledVeteransDonation: CurrentDonationDto | undefined;
  validationFailures: ValidationFailures;
  setValidationFailures: (
    validationFailures: (
      validationFailures: ValidationFailures
    ) => ValidationFailures
  ) => void;
  total: number;
  savedPaymentMethod: CustomerAutoRenewPaymentMethodDetailsDto | undefined;
  setIsPageDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isEarlyRenewalPurchaseConfirmed: boolean;
  shoppingCartHasEarlyRenewalProduct: boolean;
};

const subscriptionDefaults = {submitting: true, pristine: true};
const CreditCardInformation: React.FC<CreditCardInformationProps> = ({
  state,
  huntersForHungryDonation,
  disabledVeteransDonation,
  validationFailures,
  setValidationFailures,
  total,
  savedPaymentMethod,
  setIsPageDisabled,
  isEarlyRenewalPurchaseConfirmed,
  shoppingCartHasEarlyRenewalProduct,
}) => {
  const history = useHistory();
  const user = useUser();
  const {salesReceiptRoutes} = catalogRoutes;
  const {
    catalogType,
    customer,
    tenderType,
    mailingAddress,
    hipSurvey,
    checkNumber,
    pivNumber,
    depositDate,
  } = state;
  const fields = useFields(customer);
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  useImportedScript(Env.elavonConvergeLightboxUrl);

  const [keepSessionAlive, setKeepSessionAlive] = useState(true);
  const delay = 15000;

  useInterval(
    () => {
      AccountService.keepSessionAlive();
    },
    keepSessionAlive ? delay : null
  );

  const [elavonTransactionId, setElavonTransactionId] = useState();
  const [retryButtonVisible, setRetryButtonVisible] = useState<boolean>(false);
  const [isTokenRequestLoading, setIsTokenRequestLoading] = useState<boolean>(
    false
  );

  const [verifyTransactionRequest, setVerifyTransactionRequest] = useState(
    {} as any
  );

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const [verifyTransactionState, verifyTransaction] = useAsyncFn(
    async (trx, updateAutoRenewPaymentMethodInfo) => {
      setIsPageDisabled(true);
      setElavonTransactionId(trx.ssl_txn_id);
      const transactionId = elavonTransactionId ?? trx.ssl_txn_id;

      const requestBody = {
        transactionId: transactionId,
        cardToken: trx.ssl_token,
        customerMailingAddress: mailingAddress,
        hipSurvey: {
          ...hipSurvey,
          id: 0,
          customerId: 0,
          transactionId: 0,
          licenseYear: 0,
          transactionCustomerId: 0,
        },
        updateAutoRenewalInformation: updateAutoRenewPaymentMethodInfo,
      } as VerifyProductTransactionDto;

      const verifyTransactionResponse = await TransactionCustomerService.verifyTransaction(
        {
          body: requestBody,
        }
      );

      setIsPageDisabled(false);
      if (verifyTransactionResponse.hasErrors) {
        setRetryButtonVisible(true);
        notifications.error(
          'Failed to verify purchase. Please contact support for further assistance.'
        );
      } else {
        notifications.success('Your purchase was successful');
        if (catalogType) {
          history.replace(salesReceiptRoutes[catalogType], {
            catalogState: state,
            transactionState: verifyTransactionResponse.result,
          });
        }
      }
      return;
    },
    [
      catalogType,
      elavonTransactionId,
      mailingAddress,
      hipSurvey,
      history,
      salesReceiptRoutes,
      state,
      setIsPageDisabled,
    ]
  );

  const onSubmit = async (values) => {
    values.zipCode = values?.zipCode?.replace('-', '');
    if (
      (catalogType === CatalogType.hqVendor ||
        catalogType === CatalogType.admin) &&
      tenderType === null &&
      total > 0
    ) {
      setValidationFailures((validationFailures) => {
        validationFailures.hasTenderTypeError = true;
        return {
          ...validationFailures,
        };
      });
    }

    if (
      shoppingCartHasEarlyRenewalProduct &&
      !isEarlyRenewalPurchaseConfirmed
    ) {
      notifications.error(
        'Please confirm that you wish to renew product(s) early'
      );
      setValidationFailures((validationFailures) => {
        validationFailures.hasEarlyRenewalConfirmationError = true;
        return {
          ...validationFailures,
        };
      });

      return;
    }

    if (!huntersForHungryDonation || !disabledVeteransDonation) {
      notifications.error('Please select an option for both donations');
      setValidationFailures((validationFailures) => {
        validationFailures.hasDonationError = true;
        return {
          ...validationFailures,
        };
      });

      return;
    } else {
      let response: CreateTransactionReturnDtoApiResult = {
        validationFailures: [],
        createdEntities: [],
        hasErrors: true,
        result: {
          id: 0,
          productSalesTotal: 0,
          transactionNumber: '',
          createdTimestamp: new Date(0),
          tendorType: '',
          checkNumber: '',
        },
      };

      if (catalogType === CatalogType.customer && total === 0 && customer) {
        response = await TransactionCustomerService.createFreeTransaction({
          body: {
            customerId: customer?.id,
            customerMailingAddress: mailingAddress || undefined,
            hipSurvey: {
              ...hipSurvey,
              id: 0,
              customerId: 0,
              transactionId: 0,
              licenseYear: 0,
              transactionCustomerId: 0,
            },
            isFreeTransaction: true,
            isAutoRenewal: false,
          },
        });
      } else if (catalogType === CatalogType.customer) {
        setIsTokenRequestLoading(true);
        setIsPageDisabled(true);
        const getTransactionTokenResponse = await TransactionCustomerService.getTransactionToken(
          {
            body: {
              billingDetails: values,
              donationAmount:
                huntersForHungryDonation?.donationAmount ??
                0 + disabledVeteransDonation?.donationAmount ??
                0,
              updateAutoRenewalPaymentInfo:
                values.updateAutoRenewPaymentMethodInfo,
            },
          }
        );
        setIsTokenRequestLoading(false);
        setIsPageDisabled(false);

        if (getTransactionTokenResponse.hasErrors) {
          notifications.error(
            'Failed to initiate payment step. Please contact support for further assistance.'
          );
          setValidationFailures((validationFailures) => {
            return {
              ...validationFailures,
              errors: [
                ...(getTransactionTokenResponse.validationFailures ?? []),
              ],
            };
          });
          return;
        }

        const paymentFields = {
          ssl_txn_auth_token: getTransactionTokenResponse.result?.token,
        };

        setKeepSessionAlive(true);

        const callback = {
          onError: function (error) {
            setKeepSessionAlive(false);
            notifications.error(
              `${error}. Please contact support if you require further assistance.`
            );
          },
          onCancelled: function () {
            notifications.info('Transaction Cancelled');
            setKeepSessionAlive(false);
          },
          onDeclined: async function (response: {
            errorName: any;
            ssl_txn_id: any;
          }) {
            if (response.errorName && response.errorName !== '') {
              notifications.error(
                `${response.errorName}. Please contact support if you require further assistance.`
              );
            } else {
              notifications.error(
                'Transaction Declined. Please contact support if you require further assistance.'
              );
            }
            const saveTransactionFailure = await TransactionCustomerService.saveTransactionFailure(
              {
                transactionId: response.ssl_txn_id,
              }
            );

            if (saveTransactionFailure.hasErrors) {
              notifications.error(
                'Failed to log declined transaction. Please contact support for further assistance.'
              );
            }
          },
          onApproval: async function (response: {
            ssl_txn_id: string;
            ssl_token: string;
          }) {
            const verifyTransactionRequestbody = {
              response: response,
              updateAutoRenewPaymentMethodInfo:
                values.updateAutoRenewPaymentMethodInfo,
            };
            setKeepSessionAlive(false);
            setVerifyTransactionRequest(verifyTransactionRequestbody as any);
            verifyTransaction(
              verifyTransactionRequestbody.response,
              verifyTransactionRequestbody.updateAutoRenewPaymentMethodInfo
            );
          },
        };
        PayWithConverge.open(paymentFields, callback);
        return;
      } else if (
        (catalogType === CatalogType.hqVendor ||
          catalogType === CatalogType.admin) &&
        customer
      ) {
        response = await VendorTransactionService.hqVendorCreateTransaction({
          body: {
            customerId: customer.id,
            tenderType: tenderType as TenderType,
            checkNumber:
              checkNumber === null || checkNumber === 0
                ? ''
                : checkNumber.toString(),
            pivNumber: pivNumber === null ? '' : pivNumber,
            depositDate: depositDate === null ? (null as any) : depositDate,
            customerMailingAddress: mailingAddress || undefined,
            hipSurvey: {
              ...hipSurvey,
              id: 0,
              customerId: 0,
              transactionId: 0,
              licenseYear: 0,
              transactionCustomerId: 0,
            },
          },
        });

        if (response.hasErrors) {
          notifications.error(
            'There was an error saving the transaction record. Please contact support for further assistance.'
          );
        }
      } else if (catalogType === CatalogType.vendor && customer) {
        response = await VendorTransactionService.generalVendorCreateTransaction(
          {
            body: {
              customerId: customer.id,
              customerMailingAddress: mailingAddress || undefined,
            },
          }
        );
      }

      if (response.hasErrors) {
        notifications.error(
          'Failed to save transaction. Please contact support for further assistance.'
        );
        setValidationFailures((validationFailures) => {
          validationFailures.hasDonationError = false;
          validationFailures.hasTenderTypeError = false;

          return {
            ...validationFailures,
            errors: [...(response.validationFailures ?? [])],
          };
        });

        return;
      }
      notifications.success('Your purchase was successful');

      if (catalogType) {
        history.push(salesReceiptRoutes[catalogType], {
          catalogState: state,
          transactionState: response.result,
        });
      }
    }
  };

  let validationFailureMessages = _.uniq(
    validationFailures.errors.map((error) => error.errorMessage)
  );

  return (
    <>
      {(catalogType === CatalogType.customer && (
        <>
          <h1>Billing Information</h1>
          <p>All fields are required</p>
          {validationFailures.errors.length > 0 && (
            <section className="error-section">
              <Message error list={validationFailureMessages} />
            </section>
          )}

          <Form
            initialValues={defaultValues}
            subscription={subscriptionDefaults}
            onSubmit={onSubmit}
            render={() => (
              <>
                <FormFields
                  fields={fields}
                  savedPaymentMethod={savedPaymentMethod}
                  isCustomerCatalog={catalogType === CatalogType.customer}
                />
                {retryButtonVisible && (
                  <Message color="red">
                    Error: Your payment was successful, but we encountered an
                    issue while saving your transaction. Please click 'Retry'
                    below to have the system try again. If the issue persists,
                    please contact support.
                  </Message>
                )}
                <div className="payment-button">
                  {!retryButtonVisible && (
                    <Form.Button
                      type="submit"
                      primary
                      fluid={mobileMax}
                      className="mobile-button-margin"
                      disabled={verifyTransactionState.loading}
                      content={
                        verifyTransactionState.loading
                          ? 'Processing Payment...'
                          : isTokenRequestLoading
                          ? 'Loading...'
                          : 'Continue'
                      }
                    />
                  )}
                  {retryButtonVisible && (
                    <Form.Button
                      type="button"
                      onClick={() =>
                        verifyTransaction(
                          verifyTransactionRequest.response,
                          verifyTransactionRequest.updateAutoRenewalInformation
                        )
                      }
                      primary
                      fluid={mobileMax}
                      className="mobile-button-margin"
                      disabled={verifyTransactionState.loading}
                      content={
                        verifyTransactionState.loading ? 'Retrying...' : 'Retry'
                      }
                    />
                  )}

                  <Form.Button
                    type="button"
                    disabled={
                      verifyTransactionState.loading || isTokenRequestLoading
                    }
                    onClick={() => modifyCart(history, state, user)}
                    className="mobile-button-margin"
                    fluid={mobileMax}
                    content={'Cancel'}
                  />
                </div>
              </>
            )}
          />
        </>
      )) ||
        ((catalogType === CatalogType.vendor ||
          catalogType === CatalogType.hqVendor ||
          catalogType === CatalogType.admin) && (
          <Form
            initialValues={defaultValues}
            onSubmit={onSubmit}
            render={() => (
              <div className="payment-button">
                <Form.Button
                  className="mobile-button-margin"
                  type="submit"
                  primary
                  title={`$${total?.toFixed(2)}`}
                  fluid={mobileMax}
                  content={'Continue'}
                />

                <Form.Button
                  className="mobile-button-margin"
                  type="button"
                  onClick={() => modifyCart(history, state, user)}
                  fluid={mobileMax}
                  content={'Cancel'}
                />
              </div>
            )}
          />
        ))}
    </>
  );
};

const FormFields: React.FC<{
  fields: RawFieldConfig<BillingDetailsAndAutoRenewDto>;
  savedPaymentMethod: CustomerAutoRenewPaymentMethodDetailsDto | undefined;
  isCustomerCatalog: boolean;
}> = ({fields, savedPaymentMethod, isCustomerCatalog}) => {
  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.firstName} />
        <Form.Input fieldConfig={fields.lastName} />
      </Form.Row>
      <Form.Row proportions={[1]}>
        <Form.Input fieldConfig={fields.streetAddress} />
      </Form.Row>
      <Form.Row proportions={[2, 1, 1]}>
        <Form.Input fieldConfig={fields.city} />
        <Form.Dropdown fieldConfig={fields.stateCode} />
        <Form.InputMasked fieldConfig={fields.zipCode} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Dropdown fieldConfig={fields.countryCode} />
      </Form.Row>
      {!!savedPaymentMethod && isCustomerCatalog && (
        <Form.Row proportions={[1]}>
          <Form.Checkbox
            className="bold-toggle"
            fieldConfig={fields.updateAutoRenewPaymentMethodInfo}
          />
        </Form.Row>
      )}
    </>
  );
};

const CustomerInformation: React.FC<{
  customer: CustomerInformationDto | null;
}> = ({customer}) => {
  return customer ? (
    <>
      <h1>Customer Information</h1>
      <div className="customer-info-details">
        <section>
          <h2>LDWF#</h2>
          <p>{customer.sportsmanId}</p>
        </section>

        <section>
          <h2>Name</h2>
          <p>{`${customer.firstName} ${customer.lastName}`}</p>
        </section>

        <section>
          <h2>DOB</h2>
          <p>
            <DateFormat date={customer.dateOfBirth} />
          </p>
        </section>
      </div>
    </>
  ) : null;
};
