import {useEffect} from 'react';
import {useAsync} from 'react-use';
import {ChatBotService} from '../api/generated';
import {Env} from '../config/env-vars';

export const ZendeskChatBotWidget = () => {
  const isChatBotEnabled = useAsync(async () => {
    const {result} = await ChatBotService.isChatBotEnabled();
    return result;
  });

  useEffect(() => {
    const shouldLoadChatbot = isChatBotEnabled.value;
    const chatBotScriptId = 'ze-snippet';
    const existingScriptElement = document.getElementById(chatBotScriptId);

    if (existingScriptElement) {
      if (shouldLoadChatbot) {
        return;
      }
      document.head.removeChild(existingScriptElement);
      return;
    }

    const script = document.createElement('script');
    const zendeskChatBotKey = Env.zendeskChatBotKey;
    script.id = chatBotScriptId;
    script.async = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskChatBotKey}`;

    if (shouldLoadChatbot) {
      document.head.appendChild(script);
    }
  }, [isChatBotEnabled.value]);

  return null;
};
