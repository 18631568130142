import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {saveAs} from 'file-saver';
import React from 'react';
import {useAsyncFn} from 'react-use';
import {ButtonSizes, StyledButton} from '../styled-components/styled-buttons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

type ExportButtonProps<TRequest, TResponse> = {
  fileName: string;
  request: Partial<TRequest>;
  action: (request: {body: TRequest}, params: any) => Promise<TResponse>;
  label?: string;
  icon?: IconProp;
  size?: ButtonSizes;
  disabled?: boolean;
  fluid?: boolean;
  floated?: string;
  primary?: boolean;
  fitContent?: boolean;
  className?: string;
};

export function ExportButton<TRequest, TResponse>({
  fileName,
  request,
  action,
  label,
  icon,
  size,
  disabled,
  fluid,
  floated,
  primary,
  fitContent,
  className,
}: ExportButtonProps<TRequest, TResponse>) {
  const [useGetExportState, getExport] = useAsyncFn(
    async (request) => {
      const blob = await action(
        {
          body: request,
        },
        {
          responseType: 'blob',
        }
      );
      saveAs(blob, fileName);
    },
    [fileName, action]
  );
  return (
    <StyledButton
      primary={primary}
      floated={floated}
      fluid={fluid}
      disabled={disabled || useGetExportState.loading}
      onClick={() => getExport(request)}
      loading={useGetExportState.loading}
      size={size}
      fitContent={fitContent}
      className={className}
    >
      <FontAwesomeIcon icon={icon as IconProp} fixedWidth />
      {label ?? 'Export'}
    </StyledButton>
  );
}
