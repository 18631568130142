import React from 'react';
import {useUser} from '../auth/use-auth';
import {BasicPage} from '../basic-page';

export const YourAccount = () => {
  const user = useUser();
  return (
    <BasicPage title="Temporary Page (for DEV/QA use only)">
      <h3>YOUR ACCOUNT</h3>
      <p>
        Logged in as {user.firstName} {user.lastName}
      </p>
    </BasicPage>
  );
};
