import React, {useState} from 'react';
import {Button, Divider, Grid, Modal} from 'semantic-ui-react';
import {
  AdminCustomerAutoRenewalService,
  AutoRenewService,
} from '../../../../api/generated';
import {DateFormat} from '../../../../components/date';
import {Form} from '../../../../forms';
import {notifications} from '../../../../utils/notification-service';
import {
  productListItemStyles,
  additionalInformationTextStyles,
  noProductsMessageStyles,
} from '../styles';
import {ProductRenewalPrice} from './product-renewal-price';

export const EligibleProductListing: React.FC<{
  isAdmin?: boolean;
  eligibleProducts: any;
  fetchProducts: any;
  customerId?: number;
  hasNoPaymentMethod: boolean;
  convenienceFee: number | undefined;
}> = ({
  isAdmin,
  eligibleProducts,
  fetchProducts,
  customerId,
  hasNoPaymentMethod,
  convenienceFee,
}) => {
  const [
    transactionCustomerProduct,
    setTransactionCustomerProduct,
  ] = useState<any>();
  const [isEnrollmentModalOpen, setIsEnrollmentModalOpen] = useState<boolean>(
    false
  );

  const onEnrollClick = async () => {
    const result = isAdmin
      ? await AdminCustomerAutoRenewalService.enrollProduct({
          customerId: customerId,
          transactionCustomerProductId: transactionCustomerProduct.id,
        })
      : await AutoRenewService.enrollProduct({
          transactionCustomerProductId: transactionCustomerProduct.id,
        });

    if (result.hasErrors) {
      notifications.error(
        `There was an issue enabling auto-renew for ${transactionCustomerProduct.productName}`
      );
      return;
    }

    setIsEnrollmentModalOpen(false);
    notifications.success(
      `Successfully enabled auto-renew for ${transactionCustomerProduct.productName}`
    );
    fetchProducts.retry();
  };

  return (
    <>
      <Form.Container>
        <Grid columns={2}>
          {eligibleProducts && eligibleProducts?.length > 0 ? (
            eligibleProducts?.map((product, index) => (
              <>
                <Grid.Row>
                  <Grid.Column verticalAlign="middle">
                    <b>
                      <p
                        style={productListItemStyles}
                      >{`${product.productName} (${product.productCode}) `}</p>
                    </b>
                    <p style={additionalInformationTextStyles}>
                      {'Expires after: '}
                      <DateFormat date={product.expirationDate} />
                      <br />
                      {'Renewal Date: '}
                      <DateFormat date={product.nextRenewDate} />
                    </p>
                    <ProductRenewalPrice
                      price={product.currentPrice}
                      convenienceFee={convenienceFee}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Button
                      primary
                      content={'Enable Auto-Renew'}
                      disabled={
                        hasNoPaymentMethod && product.currentPrice !== 0
                      }
                      onClick={() => {
                        setTransactionCustomerProduct(product);
                        setIsEnrollmentModalOpen(true);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                {index !== eligibleProducts?.length - 1 && <Divider />}
              </>
            ))
          ) : (
            <p style={noProductsMessageStyles}>NO PRODUCTS TO DISPLAY</p>
          )}
        </Grid>
      </Form.Container>
      <Modal
        onClose={() => setIsEnrollmentModalOpen(false)}
        onOpen={() => setIsEnrollmentModalOpen(true)}
        open={isEnrollmentModalOpen}
        size="small"
      >
        <Modal.Header>Enable Auto-Renew Confirmation</Modal.Header>
        <Modal.Content>
          <p>
            Enable auto-renew for{' '}
            <b>{transactionCustomerProduct?.productName}</b>?
          </p>
          <p>
            Doing this will renew this product automatically at the time of
            expiration using the payment method saved on your account.
          </p>
          <Divider hidden />
          <p>You may disable auto-renew for this product at any time. </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            type="button"
            content={'Enable'}
            onClick={onEnrollClick}
          />
          <Button
            secondary
            type="button"
            onClick={() => setIsEnrollmentModalOpen(false)}
            content={'Cancel'}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
