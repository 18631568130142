import css from '@emotion/css/macro';
import {cx} from 'emotion';
import React, { useEffect } from 'react';
import {Container} from 'semantic-ui-react';
import {StyledPageTitle} from './components/styled-page-title';
import {Media, PageMaxWidth} from './styles/breakpoints';

type Props =
  | {
      hideTitle?: undefined;
      title: string | React.ReactNode;
      subtitle?: string | React.ReactNode;
      fluid?: boolean;
      className?: string;
    }
  | {
      hideTitle: boolean;
      title?: undefined;
      subtitle?: string | React.ReactNode;
      fluid?: boolean;
      className?: string;
    };

export const StyledPageContainer: React.FC<Props> = React.forwardRef(
  ({title, subtitle, fluid, children, className, hideTitle}, ref: any) => {

    useEffect(() =>{
      window.scrollTo(0,0);
    },[]);
    
    return(
      <Container
        className={cx(className, 'styled-page-container')}
        ref={ref}
        css={styles}
        fluid={true}
      >
        {!hideTitle && title ? (
          <StyledPageTitle title={title} subtitle={subtitle} fluid={fluid} />
        ) : (
          <div className="spacer"></div>
        )}
        <section className={`content ${fluid ? 'full-width' : 'padded'}`}>
          {children}
        </section>
      </Container>
    );
  }
);

const styles = css`
  &.ui.container {
    background-color: #fff;
  }

  > .content {
    margin: 0 auto;
    padding: 0 0 60px;
    padding-left: 20px;

    &.full-width {
      max-width: ${PageMaxWidth.FullWidth}px !important;
    }

    &.padded {
      max-width: ${PageMaxWidth.Padded}px !important;
    }
  }

  .page-title {
    margin-bottom: 30px;
  }

  ${Media('WideMin')} {
    &.ui.container {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .page-title {
      margin-bottom: 20px;
      padding-left: 20px;
    }

    > .content {
      padding: 0.1rem 1rem 0;
    }

    > .content {
      &.full-width {
        max-width: ${PageMaxWidth.Mobile}px !important;
      }

      &.padded {
        max-width: ${PageMaxWidth.Mobile}px !important;
      }
    }
  }

  .spacer {
    height: 30px;
  }
`;
