import css from '@emotion/css/macro';
import {faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import {useDropzone} from 'react-dropzone';
import {FormApi} from 'final-form';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {useAsync, useLocation, useMedia} from 'react-use';
import {AsyncState} from 'react-use/lib/useAsync';
import {useUser} from '../auth/use-auth';
import {AsyncStateContainer} from '../components/async-state-container';
import {DateTimeFormat} from '../components/date';
import {Form} from '../forms';
import {StyledButton} from '../styled-components/styled-buttons';
import {StyledConfirmButton} from '../styled-components/styled-confirm-button';
import {Typography} from '../styled-components/typography';
import {StyledPageContainer} from '../styled-page-container';
import {notifications} from '../utils/notification-service';
import {Accordion, Card, Grid, Segment} from 'semantic-ui-react';
import {OnChange} from 'react-final-form-listeners';
import {
  TransactionTagListItemDto,
  OptionsService,
  TurkeyMaturityCode,
  HarvestOptionsDto,
  CustomerHarvestsService,
  HarvestSpecies,
  LandTypeCode,
  ValidationMethod,
  AntlerCode,
} from '../api/generated';
import {
  getEnumDropdownOptions,
  getOptionDtoDropdownOptions,
} from '../api/generated/utils';
import {
  datepicker,
  dropdown,
  fieldConfig,
  getDefaults,
  input,
  RawFieldConfig,
} from '../forms/schema-utils';
import {Theme} from '../theme';
import {MediaSizes} from '../styles/breakpoints';
import {cstNow, momentCst} from '../utils/date-time-helpers';
import {
  getAvailableDates,
  getYears,
  isDateFormatValid,
} from '../utils/date-helpers';
import {ExportButton} from '../components/export-button';
import {Flex} from '../components/flex';
import {FormSpy, useForm} from 'react-final-form';
import {TagSpeciesCodes} from '../api/generated/enums';

const LandTypeCodeOptions = {
  'PUBLIC LAND': LandTypeCode.PublicLand,
  'PRIVATE LAND': LandTypeCode.PrivateLand,
};

const GenderCode = {
  BUCK: 'Male',
  DOE: 'Female',
};

type HarvestOmitKeys =
  | 'confirmationNumber'
  | 'transactionTagId'
  | 'harvestSpecies'
  | 'harvestImageUri'
  | 'id';

interface CreateHarvestCommand {
  id: number;
  transactionTagId: number;
  confirmationNumber: string;
  dateKilled: Date;
  countyCodeId: number;
  landTypeCode: LandTypeCode;
  genderCode: any;
  deerAreaId: number;
  turkeyMaturityCode: TurkeyMaturityCode;
  antlerCode: AntlerCode;
  harvestImageUri: string;
  harvestSpecies: HarvestSpecies;
  harvestImage: any;
}

type FieldConfigDto = Omit<CreateHarvestCommand, HarvestOmitKeys>;

type FieldConfigOptions = {
  fieldConfig: RawFieldConfig<FieldConfigDto>;
  tagOptions: HarvestOptionsDto;
};

type TagState = TransactionTagListItemDto | null;

const years = getYears(120, 120);
const availableDates = getAvailableDates(120, 120);

const useFields = (): AsyncState<FieldConfigOptions> => {
  const fetchTagOptions = useAsync(async () => {
    const {result} = await OptionsService.getHarvestOptions();

    return {
      fieldConfig: fieldConfig<FieldConfigDto>({
        dateKilled: datepicker({
          fieldLabel: 'Date of Harvest',
          fieldRequired: true,
          defaultValue: cstNow(),
          inputProps: {
            isOutsideRange: availableDates,
            years: years,
          },
        }),
        countyCodeId: dropdown({
          fieldLabel: 'Parish of Harvest',
          fieldRequired: true,
          inputProps: {
            options: getOptionDtoDropdownOptions(result?.counties),
            placeholder: 'Select a Parish...',
            selection: true,
            search: true,
          },
        }),
        genderCode: dropdown({
          fieldLabel: 'Gender',
          fieldRequired: true,
          inputProps: {
            options: getEnumDropdownOptions(GenderCode),
            placeholder: 'Select a Gender...',
            selection: true,
            search: true,
          },
        }),
        landTypeCode: dropdown({
          fieldLabel: 'Land Type',
          fieldRequired: true,
          inputProps: {
            options: getEnumDropdownOptions(LandTypeCodeOptions),
            placeholder: 'Select a Land Type...',
            selection: true,
            search: true,
          },
        }),
        antlerCode: dropdown({
          fieldLabel: 'Antler Type',
          fieldRequired: true,
          inputProps: {
            options: getEnumDropdownOptions(AntlerCode),
            placeholder: 'Select an Antler Type...',
            selection: true,
          },
        }),
        turkeyMaturityCode: dropdown({
          fieldLabel: 'Turkey Maturity',
          fieldRequired: true,
          inputProps: {
            options: getEnumDropdownOptions(TurkeyMaturityCode),
            placeholder: 'Select a Maturity...',
            selection: true,
            search: true,
          },
        }),
        deerAreaId: dropdown({
          fieldLabel: 'Deer Area',
          fieldRequired: true,
          inputProps: {
            placeholder: 'Select a Deer Area...',
            selection: true,
            search: true,
          },
        }),
        harvestImage: input({
          fieldLabel: 'Harvest Image',
          fieldRequired: false,
        }),
      }),
      tagOptions: result ?? {},
    };
  }, []);

  return fetchTagOptions;
};

const isTagSelected = (index: number, activeTagId: number | undefined) => {
  return activeTagId === index;
};

export const ValidateTags = () => {
  const user = useUser();

  const {state: locationState} = useLocation();
  const state = locationState?.state;

  let defaultTagState: TagState = null;

  const isTablet = useMedia(`(${MediaSizes.TabletMax})`);

  if (state && state.tag) {
    defaultTagState = state.tag;
  }

  const [file, setFile] = useState<any>(null);
  const [files, setFiles] = useState<any>();
  const [refresh, setRefresh] = useState<number | null>(null);
  const [tags, setTags] = useState<TransactionTagListItemDto[] | null>(null);
  const [activeTag, setActiveTag] = useState<TagState>(defaultTagState);

  const fieldConfigOptions = useFields();

  const {getRootProps, getInputProps} = useDropzone({
    maxSize: 9097152,
    multiple: false,
    accept: 'image/jpeg, image/png',
    onDropAccepted: (files) => {
      setFile(files[0]);
      setFiles(
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((fileRejection) =>
        fileRejection.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            notifications.error('File size is too large.');
          } else {
            notifications.error(`${error.code}: ${error.message}`);
          }
        })
      );
    },
  });

  const handleClose = () => {
    setActiveTag(null);
  };

  const initialValues = useMemo(() => {
    if (fieldConfigOptions.value) {
      return getDefaults(fieldConfigOptions.value.fieldConfig);
    }
  }, [fieldConfigOptions.value]);

  const fetchTags = useAsync(async () => {
    if (refresh === null) {
    }
    const response = await CustomerHarvestsService.getAllForCustomer({
      customerId: user.customerId ?? 0,
    } as any);

    const sortedTags = _.sortBy(response.result, ['wasHarvested', 'species']);
    setTags(sortedTags);

    return response.result;
  }, [refresh, user.customerId]);

  const onSubmit = async (values: FieldConfigDto) => {
    const harvestSpecies =
      activeTag?.speciesCode === TagSpeciesCodes.TURKEY
        ? HarvestSpecies.Turkey
        : HarvestSpecies.Deer;

    const dateKillString = momentCst(values.dateKilled).format('MM/DD/YYYY');

    if (!isDateFormatValid(dateKillString)) {
      const response = {
        hasErrors: true,
        validationFailures: [
          {
            propertyName: 'dateKilled',
            errorMessage: "'Date of Harvest' not in correct format, MM/DD/YYYY",
          },
        ],
      };

      return response;
    }

    const response = await CustomerHarvestsService.post({
      harvestSpecies,
      transactionTagId: activeTag?.id,
      ...values,
      antlerCode: values.antlerCode,
      image: file,
      dateKilled: momentCst(values.dateKilled).format().toString(),
      validationMethod: ValidationMethod.CustomerPortal,
    });

    if (response.hasErrors) {
      return response;
    }

    setFile(null);
    setRefresh(+new Date());
    notifications.success('Tag Validated');
  };

  const fetchState = {
    loading: fieldConfigOptions.loading || fetchTags.loading,
    error: fieldConfigOptions.error || fetchTags.error,
    hasAllValues: fieldConfigOptions.value && fetchTags.value,
  };

  return (
    <AsyncStateContainer {...fetchState}>
      {fetchState.hasAllValues && (
        <StyledPageContainer
          title="Validate Tags"
          subtitle="Report your harvested animal(s) by filling in tag details."
          css={styles}
        >
          <Flex.Row justifyContent="space-between" vAlign="top">
            <Typography variant="heading3">Available Tags</Typography>
            <ExportButton
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              request={{customerId: user.customerId}}
              disabled={(tags && tags.length === 0) ?? true}
              action={CustomerHarvestsService.exportCustomerTagsPdfFile}
              fileName={'TagsDownload.pdf'}
              icon={faFilePdf}
              label="Download Tags"
            />
          </Flex.Row>
          <br />
          <Accordion styled fluid>
            <Form
              disableLoading
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({
                values,
                form,
              }: {
                values: FieldConfigDto;
                form: FormApi<FieldConfigDto>;
              }) => {
                return (
                  tags &&
                  tags.map((tag) => {
                    const isSelectedTag = isTagSelected(tag.id, activeTag?.id);
                    return (
                      <>
                        <AccordianTitleRow
                          tag={tag}
                          form={form}
                          activeTagId={activeTag?.id}
                          setFile={setFile}
                          setFiles={setFiles}
                          setActiveTagId={setActiveTag}
                        />
                        <Accordion.Content
                          active={isSelectedTag && !tag.wasHarvested}
                        >
                          {isSelectedTag &&
                            fieldConfigOptions.value &&
                            ((tag.speciesCode === TagSpeciesCodes.TURKEY && (
                              <>
                                <TurkeyFormFields
                                  fields={fieldConfigOptions.value.fieldConfig}
                                  files={files}
                                  getInputProps={getInputProps}
                                  getRootProps={getRootProps}
                                />
                              </>
                            )) ||
                              ((tag.speciesCode ===
                                TagSpeciesCodes['ANTLERED DEER'] ||
                                tag.speciesCode ===
                                  TagSpeciesCodes['ANTLERLESS DEER'] ||
                                tag.speciesCode ===
                                  TagSpeciesCodes['DEER - EITHER SEX']) && (
                                <>
                                  <DeerFormFields
                                    tag={tag}
                                    values={values}
                                    files={files}
                                    getInputProps={getInputProps}
                                    getRootProps={getRootProps}
                                    fieldConfigOptions={
                                      fieldConfigOptions.value
                                    }
                                  />
                                </>
                              )))}
                          <Grid columns={2} doubling>
                            <Grid.Row>
                              <Grid.Column
                                textAlign="right"
                                computer={1}
                                tablet={3}
                                mobile={5}
                              >
                                <StyledConfirmButton
                                  primary
                                  text="Save"
                                  fluid={isTablet}
                                  onConfirm={() => {
                                    form.submit();
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column computer={1} mobile={3}>
                                <StyledButton
                                  fluid={isTablet}
                                  onClick={handleClose}
                                  content={'Cancel'}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Accordion.Content>
                      </>
                    );
                  })
                );
              }}
            />
          </Accordion>
        </StyledPageContainer>
      )}
    </AsyncStateContainer>
  );
};

const AccordianTitleRow: React.FC<{
  tag: TransactionTagListItemDto;
  form: FormApi<FieldConfigDto>;
  activeTagId: number | undefined;
  setFile: any;
  setFiles: any;
  setActiveTagId: (tag: TransactionTagListItemDto | null) => void;
}> = ({tag, form, activeTagId, setFile, setFiles, setActiveTagId}) => {
  const handleOpen = () => {
    if (tag.wasHarvested) {
      setActiveTagId(null);
    } else {
      setActiveTagId(tag);
    }
    setFile(null);
    setFiles(null);
    form.reset();
  };

  //Prevents the user from having to click the "Save" button twice
  form.blur('genderCode');
  form.blur('landTypeCode');
  form.blur('countyCodeId');
  form.blur('deerAreaId');
  form.blur('turkeyMaturityCode');
  form.blur('harvestImage');

  const isSelected = isTagSelected(tag.id, activeTagId);
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <Accordion.Title
      className="accordion-title"
      active={isSelected}
      onClick={isSelected ? () => {} : handleOpen}
    >
      <Grid columns={2}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column computer={8} tablet={12} mobile={12}>
            <Typography variant="heading2">{tag.species}</Typography>
            <div>
              <Typography variant="subheading1">{`Tag #${tag.tagNumber}`}</Typography>
            </div>
          </Grid.Column>

          <Grid.Column textAlign="right" computer={8} tablet={4} mobile={16}>
            {!tag.wasHarvested ? (
              !isSelected && <StyledButton floated="right" content={'Report'} />
            ) : (
              <>
                <HarvestedTagInformation tag={tag} isMobile={isMobile} />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Accordion.Title>
  );
};

export const HarvestedTagInformation: React.FC<{
  tag: TransactionTagListItemDto;
  isMobile: boolean;
}> = ({tag, isMobile}) => {
  return (
    <Grid columns={2} Padded>
      <Grid.Row verticalAlign="middle" reversed="computer">
        <Grid.Column textAlign="left" computer={13} mobile={16}>
          <Segment compact padded className="tag-info" floated="right">
            <Card.Content>
              <div>
                <Typography variant="subheading1">
                  {`Reported `}
                  <DateTimeFormat datetime={tag.harvestCreatedTimestamp} />
                </Typography>
              </div>
              <div>
                <Typography variant="body1">
                  Confirmation #{tag.confirmationNumber}
                </Typography>
              </div>
            </Card.Content>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const DeerFormFields: React.FC<{
  files: any;
  values: any;
  getInputProps: any;
  getRootProps: any;
  fieldConfigOptions: FieldConfigOptions;
  tag: TransactionTagListItemDto;
}> = ({values, fieldConfigOptions, tag}) => {
  const {fieldConfig, tagOptions} = fieldConfigOptions;
  const {change} = useForm();

  const isPrivateLand = () => {
    return (
      values.landTypeCode === LandTypeCode.PrivateLand ||
      values.landTypeCode === LandTypeCode.DMap
    );
  };

  const isDmap = () => {
    return values.landTypeCode === LandTypeCode.DMap;
  };

  const isParishSelected = () => {
    return values.countyCodeId;
  };

  const clearLandOptions = () => {
    if (
      !_.isEqual(values.genderCode, tag.genderCode) ||
      !_.isEqual(values.antlerCode, tag.antlerCode)
    ) {
      change('deerAreaId', null);
      change('landTypeCode', null);
    }
  };

  var isDoeEitherSex = false;

  switch (tag.speciesCode) {
    case TagSpeciesCodes['ANTLERED DEER']:
      change('genderCode', GenderCode.BUCK);
      change('antlerCode', AntlerCode.Antlered);
      break;
    case TagSpeciesCodes['ANTLERLESS DEER']:
      change('antlerCode', AntlerCode.Antlerless);
      break;
    case TagSpeciesCodes['DEER - EITHER SEX']:
      if (values.genderCode === GenderCode.DOE) {
        change('antlerCode', AntlerCode.Antlerless);
        isDoeEitherSex = true;
      }
  }

  const landTypeOptions = useMemo(() => {
    if (
      values.genderCode === GenderCode.BUCK &&
      tag.speciesCode !== TagSpeciesCodes['ANTLERLESS DEER'] &&
      values.antlerCode === AntlerCode.Antlered
    ) {
      return {
        'PUBLIC LAND': LandTypeCode.PublicLand,
        'PRIVATE LAND': LandTypeCode.PrivateLand,
        DMAP: LandTypeCode.DMap,
      };
    } else {
      return {
        'PUBLIC LAND': LandTypeCode.PublicLand,
        'PRIVATE LAND': LandTypeCode.PrivateLand,
      };
    }
  }, [values.genderCode, tag.speciesCode, values.antlerCode]);

  const deerAreaOptions = useMemo(() => {
    if (values.landTypeCode === LandTypeCode.PublicLand) {
      change('deerAreaId', null);
    }

    const getDeerAreaOptions = (countyCodeId: number) => {
      const countyDeerAreas =
        tagOptions.countyCodeDeerAreas?.filter(
          (countyDeerArea) => countyDeerArea.countyCodeId === countyCodeId
        ) ?? [];

      return tagOptions.deerAreas?.filter((deerArea) => {
        return countyDeerAreas.some((countyDeerArea) => {
          return deerArea.value === countyDeerArea.deerAreaId;
        });
      });
    };

    return getDeerAreaOptions(values.countyCodeId);
  }, [
    values.countyCodeId,
    tagOptions.countyCodeDeerAreas,
    tagOptions.deerAreas,
    values.landTypeCode,
    change,
  ]);

  const hasDeerAreaOptions = (deerAreaOptions?.length ?? 0) > 0;
  const isAntleredDeer = tag.speciesCode === TagSpeciesCodes['ANTLERED DEER'];
  const isAntlerlessDeer =
    tag.speciesCode === TagSpeciesCodes['ANTLERLESS DEER'];

  return (
    fieldConfig &&
    tagOptions && (
      <>
        <Form.DatePicker fieldConfig={fieldConfig.dateKilled} />
        <Form.Dropdown
          fieldConfig={fieldConfig.genderCode}
          disabled={isAntleredDeer}
        />
        <OnChange name={'genderCode'}>
          {async () => {
            clearLandOptions();
          }}
        </OnChange>
        {!isAntleredDeer && !isAntlerlessDeer && !isDoeEitherSex && (
          <>
            <Form.Dropdown fieldConfig={fieldConfig.antlerCode} />
            <OnChange name={'antlerCode'}>
              {async () => {
                clearLandOptions();
              }}
            </OnChange>
          </>
        )}
        <Form.Dropdown fieldConfig={fieldConfig.countyCodeId} />
        <FormSpy>
          {({values}) => {
            return (
              <Form.Dropdown
                fieldConfig={fieldConfig.landTypeCode}
                options={getEnumDropdownOptions(landTypeOptions)}
                disabled={!values?.genderCode}
              />
            );
          }}
        </FormSpy>
        {hasDeerAreaOptions && isPrivateLand() && isParishSelected() && (
          <Form.Dropdown
            fieldConfig={fieldConfig.deerAreaId}
            options={getOptionDtoDropdownOptions(deerAreaOptions)}
          />
        )}
        {hasDeerAreaOptions && isDmap() && isParishSelected() && (
          <b>
            <Form.Info message="We see you have chosen to use this tag as a DMAP. Please remember that you must still record this data on the data sheet." />
          </b>
        )}
      </>
    )
  );
};

export const TurkeyFormFields: React.FC<{
  fields: RawFieldConfig<FieldConfigDto>;
  getInputProps: any;
  getRootProps: any;
  files: any;
}> = ({fields, files, getInputProps, getRootProps}) => {
  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.DatePicker fieldConfig={fields.dateKilled} />
        <Form.Dropdown fieldConfig={fields.turkeyMaturityCode} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Dropdown fieldConfig={fields.countyCodeId} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Dropdown fieldConfig={fields.landTypeCode} />
      </Form.Row>
    </>
  );
};

const styles = css`
  .tag-info {
    padding: 0.2em 0.5em !important;
    margin: 1em 0em !important;
    box-shadow: none !important;
    border: none !important;
    background-color: ${Theme.palette.green00};
    line-height: 13px;
    width: 100%;

    .content {
      padding: 0em !important;
    }
  }

  .tag-actions {
    display: block !important;
  }

  .ui.grid > .row {
    padding: 1em 0.5em !important;
  }

  .accordion-title {
    padding: 1em 0em !important;
    cursor: auto !important;
  }

  .thumbsContainer {
    margin-top: 16px;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    height: 100px;
    width: 100px;
    box-sizing: border-box;
  }

  .thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
  }

  .img {
    display: block;
    width: auto;
    height: 100%;
  }

  .harvestImage {
    display: block;
    width: auto;
    height: 300px;
  }

  .baseStyle {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    backgroundcolor: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`;
