import {useState} from 'react';
import {useLocalStorage} from 'react-use';

export const savedSearchFilterKey = 'SavedSearchFilters';

export type SavedSearchFilters = {
  tableFilters: SavedSearchFilter[];
};

export type SavedSearchFilter = {
  tableName: string;
  filterValues: any;
};

type LocalStorageTableFiltersResult = {
  savedFilterByTable?: SavedSearchFilter | null;
  saveSearchFilterByTable: (filtersToSave: SavedSearchFilter) => void;
  removeAllSavedFilters: () => void;
};

const savedTableFiltersInitialValue: SavedSearchFilters = {tableFilters: []};

export default function useLocallyStoredTableFilters(
  tableName: string
): LocalStorageTableFiltersResult {
  const [savedTableFilters, setSavedTableFilters] = useLocalStorage(
    savedSearchFilterKey,
    savedTableFiltersInitialValue
  );

  const [
    savedFilterByTable,
    setSavedFilterByTable,
  ] = useState<SavedSearchFilter | null>(() => {
    if (!savedTableFilters) {
      return null;
    }

    const savedFilters = savedTableFilters as SavedSearchFilters;

    const savedFilterByTable = savedFilters.tableFilters.filter(
      (tableFilter) => tableFilter.tableName === tableName
    )[0]; //Only expect one table in list

    if (!savedFilterByTable) {
      return null;
    }

    savedFilterByTable.filterValues = isJsonString(
      savedFilterByTable.filterValues
    )
      ? JSON.parse(savedFilterByTable.filterValues)
      : savedFilterByTable.filterValues;

    return savedFilterByTable;
  });

  const saveSearchFilterByTable = (filtersToSave: SavedSearchFilter) => {
    setSavedFilterByTable(filtersToSave);

    const savedFilters = savedTableFilters as SavedSearchFilters;

    const prevSavedTableIndex = savedFilters.tableFilters.findIndex(
      (savedTable) => savedTable.tableName === tableName
    );

    if (prevSavedTableIndex === -1) {
      savedFilters.tableFilters.push(filtersToSave);
    } else {
      savedFilters.tableFilters[prevSavedTableIndex] = filtersToSave;
    }

    setSavedTableFilters(savedFilters);
  };

  const removeAllSavedFilters = () => {
    ClearAllTableFilters();
    ClearAllTableQuickSearches();
  };

  return {savedFilterByTable, saveSearchFilterByTable, removeAllSavedFilters};
}

export const ClearAllTableFilters = () => {
  localStorage.removeItem(savedSearchFilterKey);
};

export const ClearAllTableQuickSearches = () => {
  Object.keys(localStorage).forEach(function (key) {
    if (key.includes('-quick-search')) {
      localStorage.removeItem(key);
    }
  });
};

const isJsonString = (string: string) => {
  try {
    JSON.parse(string);
    return true;
  } catch (e) {
    return false;
  }
};
