import _ from 'lodash';
import {
  CustomerDetailDtoApiResult,
  ResidencyCode,
} from '../../../api/generated';
import {SocialSecurityNumberStatus} from '../../../api/generated/enums';
import {splitZipCodeFields} from '../../../utils/address-helpers';
import {forceCstOffsetAndStartOfDay} from '../../../utils/date-time-helpers';
import {notifications} from '../../../utils/notification-service';
import {
  isDateFormatValid,
  isValidDate,
  isValidDateOfBirth,
} from '../../../utils/date-helpers';

const residencyCodeOptions = {
  Yes: ResidencyCode.RESIDENT,
  No: ResidencyCode.NONRESIDENT,
};

const SsnStatus = {
  true: SocialSecurityNumberStatus.EXEMPT,
  false: SocialSecurityNumberStatus['NON-EXEMPT'],
};

export const customerUpdateFormValueMapper = (values: any) => {
  const valuesToSubmit = _.cloneDeep(values);

  valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
    ? SsnStatus.true
    : SsnStatus.false;

  valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

  const dateOfBirthToSubmit = valuesToSubmit.dateOfBirth;
  valuesToSubmit.dateOfBirth = forceCstOffsetAndStartOfDay(dateOfBirthToSubmit);

  splitZipCodeFields(valuesToSubmit);

  return valuesToSubmit;
};

export const getValidationFailureResponse = (
  propertyName: string,
  errorMessage: string,
  action: string
) => {
  const response = ({
    hasErrors: true,
    validationFailures: [
      {
        propertyName: propertyName,
        errorMessage: errorMessage,
      },
    ],
    createdEntities: [],
    result: null,
  } as unknown) as CustomerDetailDtoApiResult;

  notifications.error(`Customer ${action} Failed`);

  return response;
};

export const validateDateOfBirth = (dateOfBirth: string, action: string) => {
  const propertyName = 'dateOfBirth';

  if (!!dateOfBirth) {
    dateOfBirth = forceCstOffsetAndStartOfDay(dateOfBirth);
  }

  if (!isValidDate(dateOfBirth)) {
    const response = getValidationFailureResponse(
      propertyName,
      "'Date of Birth' is not a valid date",
      action
    );
    return response;
  }

  if (!isValidDateOfBirth(dateOfBirth)) {
    const response = getValidationFailureResponse(
      propertyName,
      "'Date of Birth' cannot be in the future",
      action
    );
    return response;
  }

  if (!isDateFormatValid(dateOfBirth)) {
    const response = getValidationFailureResponse(
      propertyName,
      "'Date of Birth' not in the correct format, MM/DD/YYYY",
      action
    );
    return response;
  }

  return dateOfBirth;
};
