import css from '@emotion/css/macro';
import React, {useState} from 'react';
import {useMedia} from 'react-use';
import {Dropdown, Pagination} from 'semantic-ui-react';
import {useCachedState} from './use-cached-state';
import {Flex} from '../components/flex';
import {Media, MediaSizes} from '../styles/breakpoints';
import {ceil} from 'lodash';

type InitialPaginationState = {
  initialPage?: number;
  initialPageSize?: number;
};

export type PaginationResult = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  render: (totalPages: number, totalRows: number) => React.ReactElement;
};

type ItemsPerPageProps = {
  key: number;
  text: string;
  value: number;
};

const defaultPageState = {
  initialPage: 1,
  initialPageSize: 25,
};

const defaultCardPageState = {
  initialPage: 1,
  initialPageSize: 12,
};

export const pageSizeMathFuction = ({itemsPerPage}: {itemsPerPage: number}) => {
  return [
    {key: 1, text: `${itemsPerPage}`, value: itemsPerPage},
    {key: 2, text: `${itemsPerPage * 2}`, value: itemsPerPage * 2},
    {key: 3, text: `${itemsPerPage * 4}`, value: itemsPerPage * 4},
  ] as ItemsPerPageProps[];
};

export const pageSizeOptions = [
  {key: 1, text: '10', value: 10},
  {key: 2, text: '25', value: 25},
  {key: 3, text: '50', value: 50},
];

export const cardPageSizeOptions = [
  {key: 1, text: '12', value: 12},
  {key: 2, text: '24', value: 24},
  {key: 3, text: '48', value: 48},
];

export const usePagination = (
  initialState?: InitialPaginationState,
  useCardView?: boolean
): PaginationResult => {
  const useCardPagination = useCardView;

  const defaultState = initialState
    ? {
        ...defaultPageState,
        ...initialState,
      }
    : defaultPageState;

  const defaultCardState = useCardView
    ? {
        ...defaultCardPageState,
        ...initialState,
      }
    : defaultCardPageState;

  const [pageNumber, setPageNumber] = useState(defaultState.initialPage);

  const [tablePageSize, setTablePageSize] = useCachedState(
    defaultState.initialPageSize,
    'lines-per-page-table'
  );

  const [pageSizeCard, setPageSizeCard] = useCachedState(
    defaultCardState.initialPageSize,
    'lines-per-page-card'
  );

  const pageSize = useCardPagination ? pageSizeCard : tablePageSize;

  const useSmallPagination = useMedia(`(${MediaSizes.MobileMax})`);
  const useMediumPagination = useMedia(`(${MediaSizes.TabletMax})`);

  let paginationProps = {};

  const render = (totalPages, totalRows) => {
    totalPages = ceil(totalPages);
    const maxPage = Math.min(pageNumber, totalPages);
    const pageStart = Math.max((maxPage - 1) * pageSize + 1, 1);
    const pageStop = Math.min(maxPage * pageSize, totalRows);

    if (useMediumPagination) {
      paginationProps = {
        ...paginationProps,
        boundaryRange: 0,
        ellipsisItem: null,
      };
    }

    if (useSmallPagination) {
      paginationProps = {
        ...paginationProps,
        fluid: true,
        widths: totalPages > 3 ? 5 : totalPages + 2,
      };
    }

    return (
      <div css={styles} className="pagination">
        <Flex.Row align="center">
          <Flex.Fill>
            {totalPages > 0 && (
              <Pagination
                activePage={maxPage}
                totalPages={totalPages}
                onPageChange={(e, {activePage}) =>
                  setPageNumber(Number(activePage))
                }
                firstItem={null}
                lastItem={null}
                {...paginationProps}
              />
            )}
          </Flex.Fill>
          <Flex.Box>
            {totalRows > 0 && (
              <span className="lines-per-page">
                <span className="current-page">{`Showing ${pageStart} - ${pageStop} of ${totalRows}`}</span>
                <Dropdown
                  onChange={
                    useCardPagination
                      ? (e, {value}) => setPageSizeCard(Number(value))
                      : (e, {value}) => setTablePageSize(Number(value))
                  }
                  options={
                    useCardView
                      ? pageSizeMathFuction({
                          itemsPerPage: defaultCardState.initialPageSize,
                        })
                      : pageSizeOptions
                  }
                  selection
                  upward
                  compact
                  value={useCardPagination ? pageSizeCard : tablePageSize}
                />
              </span>
            )}
          </Flex.Box>
        </Flex.Row>
      </div>
    );
  };

  return {
    pageNumber,
    setPageNumber,
    pageSize: pageSize,
    render,
  };
};

const styles = css`
  .lines-per-page {
    display: inline-flex;
    align-items: center;

    ${Media('MobileMax')} {
      display: flex;

      .current-page {
        flex: 1 1 0%;
      }
    }

    .ui.dropdown {
      margin-left: 20px;
    }
  }
`;
