import React, {ReactNode, useEffect, useState} from 'react';
import {Card, Divider, Grid, Message, Modal, Segment} from 'semantic-ui-react';
import {useAsync, useLocation} from 'react-use';
import {css} from '@emotion/core';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {cx} from 'emotion';
import moment from 'moment';

// Components
import {AsyncStateContainer} from '../../components/async-state-container';
import {StyledPageContainer} from '../../styled-page-container';
import {StyledButton} from '../../styled-components/styled-buttons';
import {Typography} from '../../styled-components/typography';

// Network Services
import {
  AccountService,
  CanLoggedInUserRegisterForEventQueryResponse,
  CustomerEventsService,
  IspCustomersService,
  IspEventDetailDto,
} from '../../api/generated';

// Helpers
import {cstNow, momentCst} from '../../utils/date-time-helpers';
import {buildPath} from '../../routes/config';
import {useNotification} from '../../hooks/use-notifications';
import {Producer, useProduce} from '../../hooks/use-produce';

// Constants
import {customerRoutes as routes} from '../../internet-sales-portal/routes/config';
import {Theme} from '../../theme';
import {StyledConfirmButton} from '../../styled-components/styled-confirm-button';
import {Flex} from '../../components/flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint, faStarExclamation} from '@fortawesome/pro-regular-svg-icons';
import {Tooltip} from '../../components/tooltip';
import {
  EmailInstructorModal,
  EmailInstructorModalStateProps,
} from './email-instructor-modal';
import {EventRegistrationSubmitAction} from './event-registration-login';
import {ClassEventTypeNames} from '../../utils/constants/class-event-type-names';

type ReturnRoute = string | null;

const setupEventDetailState = (
  event: IspEventDetailDto | undefined,
  eventInstructorInformation:
    | CanLoggedInUserRegisterForEventQueryResponse
    | undefined,
  setEventDetailState: (mutationFn: Producer<EventDetailStateProps>) => void
) => {
  if (!event) {
    return;
  }
  const userIsLoggedIn = eventInstructorInformation?.isUserLoggedIn;
  const eventHasWaitlist = event.hasWaitList;

  let eventIsAtCapacity = false;
  if (event.capacity !== undefined && event.capacity !== null) {
    eventIsAtCapacity = event.capacity <= event.numberOfStudentsInClass;
  }

  const eventHasCustomersOnWaitlist =
    eventInstructorInformation?.canUserRegisterForEvent &&
    !eventInstructorInformation.canUserRegisterForEvent
      ?.eventDoesNotHaveCustomersOnWaitlist;

  if (event.isCancelled) {
    setEventDetailState((draft) => {
      draft.disableRegisterButton = true;
      draft.submitAction = undefined;
      draft.messageOverActionButtons = {
        messageText: 'EVENT HAS BEEN CANCELLED',
        messageColor: 'red',
      };
    });
    return;
  }

  if (
    momentCst(event.displayEndDate).format('yyyyMMDD') <
    cstNow().format('yyyyMMDD')
  ) {
    setEventDetailState((draft) => {
      draft.disableRegisterButton = true;
      draft.submitAction = undefined;
      draft.messageOverActionButtons = {
        messageText: 'Registration for this event has ended.',
        messageColor: 'red',
      };
    });
    return;
  }

  if (
    momentCst(event.displayStartDate).format('yyyyMMDD') >
    cstNow().format('yyyyMMDD')
  ) {
    setEventDetailState((draft) => {
      draft.disableRegisterButton = true;
      draft.submitAction = undefined;
      draft.messageOverActionButtons = {
        messageText: 'Registration for this event has not started.',
        messageColor: 'blue',
      };
    });
    return;
  }

  const customerIsRegisteredForEvent =
    eventInstructorInformation?.canUserRegisterForEvent &&
    !eventInstructorInformation.canUserRegisterForEvent
      .customerIsNotRegisteredForEvent;
  const customerIsWaitlistedForEvent =
    eventInstructorInformation?.canUserRegisterForEvent &&
    !eventInstructorInformation.canUserWaitlistForEvent
      ?.customerIsNotWaitlistedForEvent;

  if (userIsLoggedIn) {
    if (customerIsRegisteredForEvent) {
      setEventDetailState((draft) => {
        draft.disableRegisterButton = true;
        draft.submitAction = 'unregister';
        draft.messageOverActionButtons = {
          messageText: 'You have registered for this event',
          messageColor: 'green',
        };
      });
      return;
    }
    if (customerIsWaitlistedForEvent) {
      setEventDetailState((draft) => {
        draft.disableRegisterButton = true;
        draft.submitAction = 'remove-from-waitlist';
        draft.messageOverActionButtons = {
          messageText: 'You have waitlisted for this event.',
          messageColor: 'green',
        };
      });
      return;
    }
  }

  const eventStatusCodeIsActive =
    event.eventStatusCode?.description?.toUpperCase() === 'ACTIVE';

  if (!eventStatusCodeIsActive) {
    setEventDetailState((draft) => {
      draft.disableRegisterButton = true;
      draft.submitAction =
        eventHasCustomersOnWaitlist || eventIsAtCapacity
          ? 'waitlist'
          : 'register';
      draft.messageOverActionButtons = {
        messageText: `Event is currently not accepting ${
          eventHasCustomersOnWaitlist || eventIsAtCapacity
            ? 'waitlists'
            : 'registrations'
        }. Please check back later.`,
        messageColor: 'red',
      };
    });
    return;
  }

  if (eventHasCustomersOnWaitlist && eventHasWaitlist) {
    setEventDetailState((draft) => {
      draft.disableRegisterButton = false;
      draft.submitAction = 'waitlist';
      draft.messageOverActionButtons = {
        messageText: 'Event is full. You can apply to the waitlist.',
        messageColor: 'blue',
      };
    });
    return;
  }

  if (eventIsAtCapacity) {
    if (eventHasWaitlist) {
      setEventDetailState((draft) => {
        draft.disableRegisterButton = false;
        draft.submitAction = 'waitlist';
        draft.messageOverActionButtons = {
          messageText: 'Event is full. You can apply to the waitlist.',
          messageColor: 'blue',
        };
      });
      return;
    } else {
      setEventDetailState((draft) => {
        draft.disableRegisterButton = true;
        draft.submitAction = undefined;
        draft.messageOverActionButtons = {
          messageText:
            'Event is full. Please check back later to see if more seats become available.',
          messageColor: 'red',
        };
      });
      return;
    }
  }

  setEventDetailState((draft) => {
    draft.disableRegisterButton = false;
    draft.submitAction = 'register';
    draft.messageOverActionButtons = undefined;
  });
};

type EventDetailStateProps = {
  disableRegisterButton?: boolean;
  submitAction?: EventRegistrationSubmitAction;
  messageOverActionButtons?: {
    messageText: string;
    messageColor: 'red' | 'blue' | 'green';
  };
};

export const EventDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const match = useRouteMatch<{id: string}>();
  const eventId = Number(match.params.id);
  const [
    eventDetailState,
    setEventDetailState,
  ] = useProduce<EventDetailStateProps>({});

  const fetchEvent = useAsync(async () => {
    const fetchEvent = await CustomerEventsService.getById({
      id: eventId,
    });

    const canUserRegister = await CustomerEventsService.canLoggedInUserRegisterForEvent(
      {
        id: eventId,
      }
    );

    setupEventDetailState(
      fetchEvent.result,
      canUserRegister.result,
      setEventDetailState
    );

    return {
      event: fetchEvent.result,
      eventInstructorInformation: canUserRegister.result,
    };
  }, [eventId, setEventDetailState]);

  const event = fetchEvent.value?.event;
  const eventInstructorInformation =
    fetchEvent.value?.eventInstructorInformation;
  const customerIsRegisteredForEvent =
    eventInstructorInformation?.canUserRegisterForEvent &&
    !eventInstructorInformation.canUserRegisterForEvent
      .customerIsNotRegisteredForEvent;

  return (
    <AsyncStateContainer {...fetchEvent}>
      {event && eventInstructorInformation && (
        <StyledPageContainer
          title="Event Details"
          subtitle=""
          css={eventDetailStyles}
        >
          {event.isCancelled &&
            eventDetailState.messageOverActionButtons !== undefined && (
              <MessageToShowUser
                color={eventDetailState.messageOverActionButtons.messageColor}
                messageText={
                  eventDetailState.messageOverActionButtons.messageText
                }
              />
            )}
          {!event.isCancelled &&
            eventDetailState.messageOverActionButtons !== undefined && (
              <MessageToShowUser
                color={eventDetailState.messageOverActionButtons.messageColor}
                messageText={
                  eventDetailState.messageOverActionButtons.messageText
                }
                actions={
                  customerIsRegisteredForEvent && (
                    <Tooltip label="Print">
                      <StyledButton
                        className="print-button"
                        size="sm"
                        padded
                        primary
                        onClick={() => window.print()}
                      >
                        <FontAwesomeIcon icon={faPrint} />
                      </StyledButton>
                    </Tooltip>
                  )
                }
              />
            )}
          <Typography variant="heading2">{`${event.classEventTypeName} Information`}</Typography>
          <EventInformation
            event={event}
            eventInstructorInformation={eventInstructorInformation}
            eventDetailState={eventDetailState}
          />
        </StyledPageContainer>
      )}
    </AsyncStateContainer>
  );
};

export type EventInfoType = {
  event: IspEventDetailDto;
  eventInstructorInformation: CanLoggedInUserRegisterForEventQueryResponse;
  eventDetailState: EventDetailStateProps;
};

export type EventInfoFieldType = {
  header?: string;
  text?: any;
  noPadding?: boolean;
};

const EventInformation: React.FC<EventInfoType> = ({
  event,
  eventDetailState,
}) => {
  let seatsRemaining: string | number | undefined;

  if (event.capacity === undefined || event.capacity === null) {
    seatsRemaining = 'UNLIMITED';
  } else if (event.capacity - event.numberOfStudentsInClass > 0) {
    seatsRemaining = event.capacity - event.numberOfStudentsInClass;
  } else {
    seatsRemaining = 'FULL';
  }

  var primaryInstructor = event.eventInstructors?.find(
    (x) => x.isPrimary === true
  );

  const {state: locationState} = useLocation();
  const state = locationState?.state;
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const eventId = Number(match.params.id);
  const notification = useNotification();
  const [
    emailInstructorModalState,
    setEmailInstructorModalState,
  ] = useProduce<EmailInstructorModalStateProps>({
    eventId: eventId,
    isOpen: false,
    instructorId: 0,
  });
  const [
    openEventRegistrationConfirmationModal,
    setOpenEventRegistrationConfirmationModal,
  ] = useState<boolean>(false);
  const [
    isEventRegistrationConfirmationModalLoading,
    setIsEventRegistrationConfirmationModalLoading,
  ] = useState<boolean>(false);

  let returnRoute: ReturnRoute = null;

  if (state && state.returnRoute) {
    returnRoute = state.returnRoute;
  }

  const eventRegistrationHandler = () => {
    if (eventDetailState.submitAction) {
      history.push(
        buildPath(routes.eventRegistrationLogin, {
          id: Number(eventId),
          submitAction: eventDetailState.submitAction,
        })
      );
    } else {
      notification.error(
        'Unable to register or apply to waitlist. Please try again.'
      );
    }

    setOpenEventRegistrationConfirmationModal(false);
  };

  const eventRegistrationModalHandler = async () => {
    setIsEventRegistrationConfirmationModalLoading(true);

    const isHunterEdFieldDay =
      event.classEventTypeName === ClassEventTypeNames.HunterEdFieldDay;

    const response = await AccountService.getLoggedInUserDetails();

    var customerId = response.result?.customerId;
    if (customerId) {
      if (isHunterEdFieldDay) {
        const fetchCustomer = await IspCustomersService.getById();
        if (fetchCustomer.hasErrors) {
          eventRegistrationHandler();
        } else {
          const isSixteenOrOlder =
            moment().diff(fetchCustomer.result?.dateOfBirth, 'years', false) >=
            16;

          isSixteenOrOlder
            ? setOpenEventRegistrationConfirmationModal(true)
            : eventRegistrationHandler();
        }
      } else {
        eventRegistrationHandler();
      }
    } else {
      eventRegistrationHandler();
    }

    setIsEventRegistrationConfirmationModalLoading(false);
  };

  return (
    <>
      <Grid stackable doubling columns="equal">
        <Grid.Row>
          <Grid.Column>
            <EventInformationField
              header="Seats Remaining"
              text={`${seatsRemaining}`}
            />
            <EventInformationField
              header="About"
              text={event.classEventType?.publicDescription}
            />
            <EventInformationField
              header="Additional Information"
              text={event.description}
            />
            <EventInformationField header="Instructor">
              <div>{primaryInstructor?.fullName}</div>
              <div>
                {primaryInstructor && primaryInstructor.hasEmail && (
                  <>
                    <span
                      className="link-css"
                      onClick={() => {
                        setEmailInstructorModalState((draft) => {
                          draft.eventId = event.id;
                          draft.instructorId =
                            primaryInstructor?.instructorId ?? 0;
                          draft.isOpen = true;
                        });
                      }}
                    >
                      Click here
                    </span>{' '}
                    to email this instructor
                  </>
                )}
              </div>
            </EventInformationField>
            <EmailInstructorModal
              modalState={emailInstructorModalState}
              setModalState={setEmailInstructorModalState}
            />
            <Divider />
            <Typography variant="heading2">
              Event Location Information
            </Typography>
            <EventInformationField header="Where" text={event.location} />
            <EventInformationField text={event.address?.fullAddress} />
            {event.eventDateTimes?.[0] && (
              <>
                <EventInformationField header="When" text={' '} />
                {event.eventDateTimes.map((x) => {
                  return (
                    <EventInformationField
                      text={`${momentCst(x.eventDate).format(
                        'MMMM Do YYYY'
                      )}: ${momentCst(x.startTime).format(
                        'h:mm A'
                      )} - ${momentCst(x.endTime).format('h:mm A')}`}
                    />
                  );
                })}
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Modal
        css={eventDetailStyles}
        open={openEventRegistrationConfirmationModal}
        onClose={() => {
          setOpenEventRegistrationConfirmationModal(false);
        }}
        size="small"
      >
        <Modal.Content className="event-registration-confirmation-modal-content">
          <Flex.Col justifyContent="space-around">
            <Flex.Row justifyContent="space-around">
              <Flex.Col>
                <Typography variant="heading3" icon>
                  <FontAwesomeIcon icon={faStarExclamation} />
                  <Message warning>
                    You are 16 or older, thus, you do not need to take a Hunter
                    Ed Field Day to receive a Hunter Ed Certification. You will
                    only need to complete a Hunter Ed Course (Online or
                    In-Person).
                  </Message>{' '}
                </Typography>
                <Flex.Row
                  justifyContent="flex-start"
                  className="event-registration-confirmation-modal-confirmation-container"
                >
                  <Typography variant="labelLarge">
                    Are you sure you want to register for{' '}
                    {event.classEventTypeName}?
                  </Typography>
                </Flex.Row>
              </Flex.Col>
            </Flex.Row>
          </Flex.Col>
        </Modal.Content>
        <Flex.Row
          justifyContent="flex-end"
          className="event-registration-confirmation-modal-button-container"
        >
          <StyledButton
            onClick={() => {
              setOpenEventRegistrationConfirmationModal(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton primary padded onClick={eventRegistrationHandler}>
            Register
          </StyledButton>
        </Flex.Row>
      </Modal>
      <div className="button-container">
        <StyledButton
          onClick={() => {
            returnRoute ? history.push(returnRoute) : history.goBack();
          }}
        >
          Back
        </StyledButton>
        {eventDetailState.submitAction &&
        eventDetailState.submitAction !== 'unregister' &&
        eventDetailState.submitAction !== 'remove-from-waitlist' ? (
          <StyledButton
            padded
            primary
            disabled={
              eventDetailState.disableRegisterButton ||
              isEventRegistrationConfirmationModalLoading
            }
            onClick={() => {
              eventRegistrationModalHandler();
            }}
          >
            {eventDetailState.submitAction === 'register'
              ? 'Register'
              : 'Apply to Waitlist'}
          </StyledButton>
        ) : (
          <StyledConfirmButton
            primary
            onConfirm={() => {
              if (eventDetailState.submitAction) {
                history.push(
                  buildPath(routes.eventRegistrationLogin, {
                    id: Number(eventId),
                    submitAction: eventDetailState.submitAction,
                  })
                );
              } else {
                notification.error(
                  'Unable to cancel registration or remove from waitlist. Please try again.'
                );
              }
            }}
          >
            {eventDetailState.submitAction === 'unregister'
              ? 'Cancel Registration'
              : 'Remove From Waitlist'}
          </StyledConfirmButton>
        )}
      </div>
    </>
  );
};

const EventInformationField: React.FC<EventInfoFieldType> = ({
  header,
  text,
  children,
}) => {
  const isNullOrUndefined = (text) => {
    return (
      text === null ||
      text === undefined ||
      text === 'null' ||
      text === 'undefined'
    );
  };

  return isNullOrUndefined(text) && isNullOrUndefined(children) ? (
    <></>
  ) : (
    <Grid.Row>
      <Grid.Column>
        <Typography variant="heading3">
          {`${isNullOrUndefined(header) ? '' : header} `}
        </Typography>
      </Grid.Column>
      <Grid.Column>
        {text ? (
          <Typography variant="body1">{`${
            isNullOrUndefined(text) ? 'N/A' : text
          }`}</Typography>
        ) : (
          <Typography variant="body1">
            {isNullOrUndefined(children) ? 'N/A' : children}
          </Typography>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

const MessageToShowUser: React.FC<{
  color: 'red' | 'blue' | 'green';
  messageText: string;
  actions?: ReactNode;
}> = ({color, messageText, actions}) => {
  return (
    <div css={styles}>
      <Flex.Row
        vAlign="center"
        className="user-message-row"
        justifyContent="space-between"
      >
        <Segment
          compact
          padded
          className={cx(
            'registration-message-container',
            `${color}-container`,
            actions ? 'right-margin' : ''
          )}
        >
          <Card.Content>
            <div>
              <Typography variant="heading3">{`${messageText}`}</Typography>
            </div>
          </Card.Content>
        </Segment>
        {actions}
      </Flex.Row>
    </div>
  );
};

const eventDetailStyles = css`
  .link-css {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  .event-registration-confirmation-modal-content {
    min-height: 10em !important;
    min-height: 20em;
  }

  .event-registration-confirmation-modal-button-container {
    margin: 1em;
  }

  .event-registration-confirmation-modal-confirmation-container {
    margin: 0em 1em;
  }
`;

const styles = css`
  .cancelled-card {
    padding: 0.2em 0.5em !important;
    margin: 1em 0em !important;
    box-shadow: none !important;
    border: 1px solid !important;
    color: #e0b4b4;
    background-color: #fff6f6;
    line-height: 13px;
    width: 100%;
    text-align: center;

    .content {
      padding: 0em !important;
    }
  }

  .right-margin {
    margin-right: 6px !important;
  }

  .print-button {
    margin-left: 6px;
  }

  .user-message-row {
    width: 100%;
  }

  .registration-message-container {
    padding: 0.2em 0.5em !important;
    margin: 1em 0em !important;
    box-shadow: none !important;
    border: 1px solid !important;
    line-height: 13px;
    text-align: center;

    .content {
      padding: 0em !important;
    }
  }

  .green-container {
    color: ${Theme.palette.green200} !important;
    background-color: ${Theme.palette.green00} !important;
  }

  .blue-container {
    color: ${Theme.palette.blue200} !important;
    background-color: ${Theme.palette.blue00} !important;
  }

  .red-container {
    color: #e0b4b4 !important;
    background-color: #fff6f6 !important;
  }
`;
