import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {Divider, Message, Modal} from 'semantic-ui-react';
import {OnChange} from 'react-final-form-listeners';
import {Form} from '../../../forms';
import {checkbox, fieldConfig, masked} from '../../../forms/schema-utils';
import {notifications} from '../../../utils/notification-service';
import {IspCustomersService} from '../../../api/generated';
import _ from 'lodash';

type SmsPreferencesFields = {
  phoneNumberPrimary: any;
  receiveSmsUpdates: any;
  legalConfirmation: any;
};

const useFields = () => {
  return {
    fields: fieldConfig<SmsPreferencesFields>({
      phoneNumberPrimary: masked({
        fieldLabel: 'Primary Phone Number',
        inputProps: {
          options: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          },
        },
      }),
      receiveSmsUpdates: checkbox({
        inputProps: {
          label: 'Enable SMS notifications?',
        },
      }),
      legalConfirmation: checkbox({
        inputProps: {
          label:
            'Please confirm that the number entered here is the mobile number you want to receive SMS notifications',
        },
      }),
    }),
  };
};

type Error = {
  errorMessage?: string;
  propertyName?: string;
};

type ValidationFailures = {
  errors: Error[];
};

export const SmsPreferencesModal = ({customerId, setOpen}) => {
  const fields = useFields();
  const closeModal = () => {
    setOpen(false);
  };
  const [
    validationFailures,
    setValidationFailures,
  ] = useState<ValidationFailures>({
    errors: [],
  });

  let validationFailureMessages = _.uniq(
    validationFailures.errors.map((error) => error.errorMessage)
  );

  const fetchCustomer = useAsync(async () => {
    const {result} = await IspCustomersService.getById();
    return result;
  }, []);

  const initialValues = fetchCustomer.value;
  if (initialValues != null && initialValues.phoneNumberPrimary == null) {
    initialValues.phoneNumberPrimary = '';
  }

  const [showOtherSmsFields, setShowOtherSmsFields] = useState<boolean>(
    initialValues?.receiveSmsUpdates || false
  );

  const onSubmit = async (values: any) => {
    const response = await IspCustomersService.updateSmsPreferences({
      body: {
        enableSmsNotifications: values.receiveSmsUpdates,
        phoneNumberPrimary: values.phoneNumberPrimary,
        legalConfirmation: values.legalConfirmation,
      },
    });

    if (response.hasErrors) {
      setValidationFailures((validationFailures) => {
        return {
          ...validationFailures,
          errors: [...(response.validationFailures ?? [])],
        };
      });
      return;
    }

    notifications.success(
      values.receiveSmsUpdates
        ? initialValues?.receiveSmsUpdates
          ? 'SMS notifications updated'
          : 'SMS notifications enabled'
        : 'SMS notifications disabled'
    );

    closeModal();
  };

  return (
    <>
      <Modal.Header>Edit SMS Notification Preferences</Modal.Header>
      <Modal.Content>
        {validationFailures.errors.length > 0 && (
          <Message error list={validationFailureMessages} />
        )}
        <Form
          initialValues={initialValues}
          className="inline"
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Row>
                <Form.Checkbox fieldConfig={fields.fields.receiveSmsUpdates} />
                <OnChange name={'receiveSmsUpdates'}>
                  {async (value: boolean) => {
                    setShowOtherSmsFields(value);
                    if (!value) {
                      setValidationFailures({errors: []});
                    }
                  }}
                </OnChange>
              </Form.Row>
              {showOtherSmsFields && (
                <>
                  <Form.Row>
                    <Form.InputMasked
                      fieldConfig={fields.fields.phoneNumberPrimary}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Form.Checkbox
                      fieldConfig={fields.fields.legalConfirmation}
                    />
                  </Form.Row>
                </>
              )}
              <Divider />
              <Modal.Actions>
                <Form.Button type="submit" primary>
                  Save
                </Form.Button>
                <Form.Button secondary type="button" onClick={closeModal}>
                  Cancel
                </Form.Button>
              </Modal.Actions>
            </>
          )}
        />
      </Modal.Content>
    </>
  );
};
