import React from 'react';
import css from '@emotion/css/macro';
import {Divider} from 'semantic-ui-react';
import {Flex} from '../components/flex';

export const StyledDivider = () => (
  <div css={styles}>
    <Divider clearing />
  </div>
);

export const VerticalDivider = () => (
  <div css={styles}>
    <Flex.Box className="vertical-divider" />
  </div>
);

const styles = css`
  .ui.divider:not(.vertical):not(.horizontal) {
    border-top: none !important;
  }

  .ui.divider {
    margin: 8px 0 !important;
  }

  .vertical-divider {
    height: 100%;
    border-right: 1px solid rgba(34, 36, 38, 0.15);
    margin: 0px 8px;
  }
`;
