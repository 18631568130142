import React, {useState} from 'react';
import {Divider, Grid, Message, Segment, Table} from 'semantic-ui-react';
import {
  BillingDetailsDto,
  BoatRenewalDto,
  BoatRenewalService,
  TransactionCustomerService,
} from '../../api/generated';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {BasicPage} from '../../basic-page';
import {dropdown, fieldConfig, input, masked} from '../../forms/schema-utils';
import {CountryCode, StateCode} from '../../api/generated/enums';
import {useAsync, useAsyncFn, useLocation, useMedia} from 'react-use';
import {MediaSizes} from '../../styles/breakpoints';
import {Link, useHistory} from 'react-router-dom';
import {Form} from '../../forms';
import _ from 'lodash';
import {useImportedScript} from '../../utils/import-script';
import {notifications} from '../../utils/notification-service';
import {buildPath, routes} from '../../routes/config';
import {Env} from '../../config/env-vars';
import {StyledButton} from '../../styled-components/styled-buttons';
import {donationsAndPaymentStyles} from '../../shared/payment/donation-selection';

declare const PayWithConverge: any;

type Error = {
  errorMessage?: string;
  propertyName?: string;
};

type ValidationFailures = {
  errors: Error[];
};

const useFields = () =>
  fieldConfig<BillingDetailsDto>({
    firstName: input({
      fieldLabel: 'First Name',
      fieldRequired: true,
      inputProps: {
        maxLength: 20,
      },
    }),
    lastName: input({
      fieldLabel: 'Last Name',
      fieldRequired: true,
      inputProps: {
        maxLength: 20,
      },
    }),
    streetAddress: input({
      fieldLabel: 'Street Address',
      fieldRequired: true,
      inputProps: {
        maxLength: 50,
      },
    }),
    city: input({
      fieldLabel: 'City',
      fieldRequired: true,
      inputProps: {
        maxLength: 30,
      },
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      fieldRequired: true,
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
      },
    }),
  });

export const BoatRegistrationPaymentConfirmation = () => {
  const [
    validationFailures,
    setValidationFailures,
  ] = useState<ValidationFailures>({
    errors: [],
  });
  const location = useLocation();
  const registrationInfo = location?.state?.state
    .registration as BoatRenewalDto;

  let validationFailureMessages = _.uniq(
    validationFailures.errors.map((error) => error.errorMessage)
  );

  const fetchConvenienceFee = useAsync(async () => {
    const {result} = await TransactionCustomerService.getConvenienceFee();
    return result;
  }, []);

  const convenienceFee = fetchConvenienceFee.value ?? 0;
  const total = (registrationInfo?.amount ?? 0) + convenienceFee;

  return (
    <BasicPage title="Payment Confirmation">
      <Segment css={donationsAndPaymentStyles}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              {validationFailures.errors.length > 0 && (
                <Message error list={validationFailureMessages} />
              )}
              {registrationInfo && (
                <>
                  <BoatRenewalRows
                    boatRenewal={registrationInfo}
                    convenienceFee={convenienceFee}
                    total={total}
                  />
                  <Divider hidden />
                  <Grid.Row>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                      <h3>Submit Your Payment</h3>
                      <p>
                        Please enter your billing address and credit card
                        information below.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column
                      computer={12}
                      tablet={16}
                      mobile={16}
                      className={'credit-card-info-section'}
                    >
                      <CreditCardInformation
                        boatRenewal={registrationInfo}
                        setValidationFailures={setValidationFailures}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </BasicPage>
  );
};

const BoatRenewalRows: React.FC<{
  boatRenewal: BoatRenewalDto;
  total: number;
  convenienceFee: number;
}> = ({boatRenewal, total, convenienceFee}) => {
  return (
    <Table basic compact unstackable>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <div>
              <b>Registration #:</b> {boatRenewal?.longBoatNumber}
            </div>
          </Table.Cell>
          <Table.Cell textAlign="right">
            ${boatRenewal?.amount.toFixed(2)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <div>
              <b>Convenience Fee:</b>
            </div>
          </Table.Cell>
          <Table.Cell textAlign="right">
            ${convenienceFee.toFixed(2)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <div>
              <b>Total:</b>
            </div>
          </Table.Cell>
          <Table.Cell textAlign="right">${total.toFixed(2)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const CreditCardInformation: React.FC<{
  boatRenewal: BoatRenewalDto;
  setValidationFailures: (
    validationFailures: (
      validationFailures: ValidationFailures
    ) => ValidationFailures
  ) => void;
}> = ({boatRenewal, setValidationFailures}) => {
  const history = useHistory();
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  const [elavonTransactionId, setElavonTransactionId] = useState<string>();
  const [boatRenewalDto] = useState<BoatRenewalDto>(boatRenewal);

  const [retryButtonVisible, setRetryButtonVisible] = useState<boolean>(false);

  const [
    isContinueButtonDisabled,
    setIsContinueButtonDisabled,
  ] = useState<boolean>(false);

  const [
    isContinueButtonLoading,
    setIsContinueButtonLoading,
  ] = useState<boolean>(false);

  useImportedScript(Env.elavonConvergeLightboxUrl);

  const [verifyTransactionState, verifyTransaction] = useAsyncFn(
    async (boatRenewalDto, elavonTransactionId) => {
      setIsContinueButtonLoading(true);
      const verifyTransactionResponse = await BoatRenewalService.verifyTransaction(
        {
          body: boatRenewalDto,
          transactionId: elavonTransactionId,
        }
      );

      if (verifyTransactionResponse.hasErrors) {
        notifications.error(
          'Failed to verify purchase. Please contact support for further assistance.'
        );

        setRetryButtonVisible(true);
        setIsContinueButtonLoading(false);
        setValidationFailures((validationFailures) => {
          return {
            ...validationFailures,
            errors: [...(verifyTransactionResponse.validationFailures ?? [])],
          };
        });
      } else {
        history.replace(
          buildPath(routes.boatRenewal.boatRegistrationReceipt, {
            boatRenewalId: Number(boatRenewalDto.id),
          }),
          {
            registration: boatRenewalDto,
          }
        );
      }
    }
  );

  const onSubmit = async (values: any) => {
    setIsContinueButtonDisabled(true);
    setIsContinueButtonLoading(true);
    values.zipCode = values?.zipCode?.replace('-', '');
    const getTransactionTokenResponse = await BoatRenewalService.getTransactionToken(
      {
        body: {
          billingDetails: values,
          boatRenewalId: boatRenewalDto?.id,
        },
      }
    );

    if (getTransactionTokenResponse.hasErrors) {
      notifications.error(
        'Failed to initiate payment step. Please contact support for further assistance.'
      );
      setValidationFailures((validationFailures) => {
        return {
          ...validationFailures,
          errors: [...(getTransactionTokenResponse.validationFailures ?? [])],
        };
      });
      setIsContinueButtonLoading(false);
      setIsContinueButtonDisabled(false);
      return;
    }

    const paymentFields = {
      ssl_txn_auth_token: getTransactionTokenResponse.result?.token,
    };

    const callback = {
      onError: function (error: React.ReactNode) {
        notifications.error(
          `${error}. Please contact support if you require further assistance.`
        );
        setIsContinueButtonLoading(false);
        setIsContinueButtonDisabled(false);
      },
      onCancelled: function () {
        notifications.info('Transaction Canceled');
        setIsContinueButtonLoading(false);
        setIsContinueButtonDisabled(false);
      },
      onDeclined: async function (response: {errorName: any; ssl_txn_id: any}) {
        if (response.errorName && response.errorName !== '') {
          notifications.error(
            `${response.errorName}. Please contact support if you require further assistance.`
          );
        } else {
          notifications.error(
            'Transaction Declined. Please contact support if you require further assistance.'
          );
        }

        const saveTransactionFailure = await BoatRenewalService.saveTransactionFailure(
          {
            transactionId: response.ssl_txn_id,
          }
        );

        if (saveTransactionFailure.hasErrors) {
          notifications.error(
            'Failed to log declined transaction. Please contact support for further assistance.'
          );
        }
        setIsContinueButtonLoading(false);
        setIsContinueButtonDisabled(false);
      },
      onApproval: async function (response: {ssl_txn_id: any}) {
        setElavonTransactionId(response.ssl_txn_id);
        await verifyTransaction(boatRenewalDto, response.ssl_txn_id);
        setIsContinueButtonLoading(false);
        setIsContinueButtonDisabled(false);
      },
    };

    PayWithConverge.open(paymentFields, callback);
    return true;
  };

  return (
    <>
      {
        <Table basic compact unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <strong>Billing Information</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Form
                  onSubmit={onSubmit}
                  render={() => (
                    <>
                      <FormFields />
                      {retryButtonVisible && (
                        <>
                          <Message color="red">
                            Error: Your payment was successful, but we
                            encountered an issue while saving your transaction.
                            Please click 'Retry' below to have the system try
                            again. If the issue persists, please contact
                            support.
                          </Message>
                        </>
                      )}
                      <div className="payment-button">
                        {!retryButtonVisible && (
                          <StyledButton
                            type="submit"
                            className="mobile-button-margin"
                            fluid={mobileMax}
                            primary
                            loading={isContinueButtonLoading}
                            disabled={isContinueButtonDisabled}
                            content={'Continue'}
                          />
                        )}
                        {retryButtonVisible && (
                          <StyledButton
                            onClick={() =>
                              verifyTransaction(
                                boatRenewalDto,
                                elavonTransactionId
                              )
                            }
                            loading={verifyTransactionState.loading}
                            disabled={verifyTransactionState.loading}
                            className="mobile-button-margin"
                            fluid={mobileMax}
                            primary
                            content={'Retry'}
                          />
                        )}
                        <StyledButton
                          secondary
                          as={Link}
                          fluid={mobileMax}
                          onClick={() => {
                            history.replace(
                              routes.boatRenewal.boatRegistrationConfirmation,
                              {
                                registration: boatRenewalDto,
                              }
                            );
                          }}
                          content={'Back to Search'}
                          disabled={verifyTransactionState.loading}
                        />
                      </div>
                    </>
                  )}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
    </>
  );
};

const FormFields: React.FC<{}> = () => {
  const fields = useFields();
  return (
    <Form.Section title="">
      <Form.Row>
        <Form.Input fieldConfig={fields.firstName} />
        <Form.Input fieldConfig={fields.lastName} />
      </Form.Row>
      <Form.Row>
        <Form.Input fieldConfig={fields.streetAddress} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.city} />
        <Form.Dropdown fieldConfig={fields.stateCode} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.InputMasked fieldConfig={fields.zipCode} />
        <Form.Dropdown fieldConfig={fields.countryCode} />
      </Form.Row>
    </Form.Section>
  );
};
