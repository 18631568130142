import css from '@emotion/css/macro';
import React from 'react';
import {HtmlInputrops, StrictInputProps} from 'semantic-ui-react';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

type TimePickerShape = {};

export type TimePickerAdapterProps = StrictInputProps &
  HtmlInputrops &
  TimePickerShape;

export default function TimePickerAdapter({input, meta, ...rest}) {
  let {...additionalProps} = rest;
  var newTime = input.value;
  if (input.value.length > 5) {
    newTime = input.value.substring(11, 19);
  }

  return (
    <div css={timepickerStyles}>
      <TimePicker
        onChange={(value) => {
          input.onChange(value);
        }}
        disableClock
        id={input.id}
        value={newTime}
        {...additionalProps}
      />
    </div>
  );
}

const timepickerStyles = css`
  .react-time-picker {
    display: inline-flex !important;
    position: relative !important;
    width: 60% !important;
    border-color: #c1c0be !important;
  }
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    border-color: #c1c0be !important;
  }
  .react-time-picker--disabled {
    background-color: #f0f0f0 !important;
    color: #6d6d6d !important;
  }
  .react-time-picker__wrapper {
    height: 38px;
    display: flex !important;
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    border: thin solid gray !important;
    border-radius: 0.26666667rem !important;
    border-color: #c1c0be !important;
  }
  .react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2) !important;
    flex-grow: 1 !important;
    padding: 0 2px !important;
    box-sizing: content-box !important;
    display: inline-flex !important;
  }
  .react-time-picker__inputGroup__divider {
    margin-top: 5px !important;
    white-space: pre !important;
  }
  .react-time-picker__inputGroup__input {
    min-width: 0.54em !important;
    height: 100% !important;
    position: relative !important;
    padding: 0 1px !important;
    border: 0 !important;
    background: none !important;
    font: inherit !important;
    box-sizing: content-box !important;
    -moz-appearance: textfield !important;
  }
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1) !important;
  }
  .react-time-picker__inputGroup__leadingZero {
    margin-top: 7px !important;
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em !important;
    padding-left: calc(1px + 0.54em) !important;
  }
  .react-time-picker__inputGroup__amPm {
    font: inherit !important;
    -moz-appearance: menulist !important;
    width: auto !important;
  }
  .react-time-picker__button {
    border: 0 !important;
    background: transparent !important;
    padding: 4px 6px !important;
  }
  .react-time-picker__button:enabled {
    cursor: pointer !important;
  }
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7 !important;
  }
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d !important;
  }
  .react-time-picker__button svg {
    display: inherit !important;
  }
  .react-time-picker__clock {
    width: 200px !important;
    height: 200px !important;
    max-width: 100vw !important;
    padding: 25px !important;
    background-color: white !important;
    border: thin solid #a0a096 !important;
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    z-index: 1 !important;
  }
  .react-time-picker__clock--closed {
    display: none !important;
  }
`;
