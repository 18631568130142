import _ from 'lodash';
import React, {useState} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {useAsync, useAsyncRetry, useMedia} from 'react-use';
import {Button, Divider, Grid, Message} from 'semantic-ui-react';
import {faPencil} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  AdminCustomerAutoRenewalService,
  AutoRenewService,
  CreateEditCustomerAutoRenewPaymentMethodDto,
  TransactionCustomerService,
} from '../../../../api/generated';
import {StateCode} from '../../../../api/generated/enums';
import {getEnumDropdownOptions} from '../../../../api/generated/utils';
import {BasicPage} from '../../../../basic-page';
import {AsyncStateContainer} from '../../../../components/async-state-container';
import {Env} from '../../../../config/env-vars';
import {Form} from '../../../../forms';
import {
  dropdown,
  fieldConfig,
  input,
  masked,
  RawFieldConfig,
} from '../../../../forms/schema-utils';
import {MediaSizes} from '../../../../styles/breakpoints';
import {useImportedScript} from '../../../../utils/import-script';
import {notifications} from '../../../../utils/notification-service';
import {PaymentMethodDeleteConfirmationModal} from './payment-method-delete-confirmation-modal';
import {autoRenewPaymentMethodStyles} from './styles';

declare const PayWithConverge: any;

type FieldConfigDto = Omit<
  CreateEditCustomerAutoRenewPaymentMethodDto,
  'elavonToken' | 'creditCardExpiration' | 'creditCardNumber' | 'creditCardType'
>;

export const PaymentMethod: React.FC<{
  isAdmin?: boolean;
  customerId?: number;
}> = ({isAdmin, customerId}) => {
  useImportedScript(Env.elavonConvergeLightboxUrl);

  const [showChangePayment, setShowChangePayment] = useState<boolean>(false);
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  const fetchPaymentMethod = useAsyncRetry(async () => {
    const {result} = isAdmin
      ? await AdminCustomerAutoRenewalService.getPaymentMethod({
          customerId: customerId,
        })
      : await AutoRenewService.getPaymentMethod();
    return result;
  });

  const useAddressCreateUpdateFields = () => {
    const fetchFields = useAsync(async () => {
      return fieldConfig<FieldConfigDto>({
        address: input({
          fieldLabel: 'Address',
          fieldRequired: true,
          inputProps: {
            placeholder: '',
            maxLength: 30,
          },
        }),
        city: input({
          fieldLabel: 'City',
          fieldRequired: true,
          inputProps: {
            maxLength: 30,
          },
        }),
        stateCode: dropdown({
          fieldLabel: 'State',
          fieldRequired: true,
          inputProps: {
            search: true,
            options: getEnumDropdownOptions(StateCode),
            placeholder: 'Select a State...',
            selection: true,
          },
        }),
        zipCode: masked({
          fieldLabel: 'Zip / Postal Code',
          fieldRequired: true,
          inputProps: {
            options: {
              blocks: [5, 4],
              delimiter: '-',
              delimiterLazyShow: true,
              numericOnly: true,
            },
          },
        }),
      });
    }, []);

    return fetchFields;
  };

  const addressCreateUpdateFields = useAddressCreateUpdateFields();

  let addressForm;
  let addressFormState;

  const AddressFields: React.FC<{
    createUpdateFields: RawFieldConfig<any>;
  }> = ({createUpdateFields}) => {
    addressForm = useForm();
    addressFormState = useFormState();

    const [isElavonModalLoading, setIsElavonModalLoading] = useState<boolean>(
      false
    );

    const addressValues = addressFormState.values;
    const continueButtonDisabled =
      !addressValues.address ||
      !addressValues.city ||
      !addressValues.stateCode ||
      !addressValues.zipCode;

    const onCreateEditSubmit = async () => {
      setIsElavonModalLoading(true);

      let addressFormStateCopy = _.cloneDeep(addressFormState);
      addressFormStateCopy.zipCode = addressFormStateCopy.values.zipCode.replace(
        '-',
        ''
      );

      const addressValues = addressFormStateCopy.values;
      const getTransactionTokenResponse = await TransactionCustomerService.getTokenizationTransactionToken();

      const callback = {
        onError: function (error) {
          notifications.error(error);
          notifications.error(
            'There was an error processing your card. Please contact support for further assistance'
          );
          setIsElavonModalLoading(false);
          return;
        },
        onCancelled: function () {
          notifications.info('Card information entry canceled');
          setIsElavonModalLoading(false);
          return;
        },
        //Not sure if declined is practical here, but just in case
        onDeclined: async function (response: {
          errorName: any;
          ssl_txn_id: any;
        }) {
          notifications.error(
            'Card was declined while saving. Please contact support if you require further assistance'
          );
          setIsElavonModalLoading(false);
          return;
        },
        onApproval: async function (response: {
          ssl_card_number: any;
          ssl_token: any;
          ssl_exp_date: any;
          ssl_card_short_description: any;
        }) {
          const addPaymentMethodResponse = await AutoRenewService.addPaymentMethod(
            {
              body: {
                creditCardType: response.ssl_card_short_description,
                elavonToken: response.ssl_token,
                creditCardNumber: response.ssl_card_number,
                creditCardExpiration: response.ssl_exp_date,
                address: addressValues.address,
                city: addressValues.city,
                stateCode: addressValues.stateCode,
                zipCode: addressValues.zipCode,
              },
            }
          );

          if (addPaymentMethodResponse.hasErrors) {
            notifications.error(
              'Payment method creation failed. Please contact support for further assistance.'
            );
            return response;
          }

          addressForm.reset({});
          fetchPaymentMethod.retry();
          setShowChangePayment(false);

          notifications.success('Payment method added successfully');
          setIsElavonModalLoading(false);
          return;
        },
      };

      const paymentFields = {
        ssl_txn_auth_token: getTransactionTokenResponse.result?.token,
      };

      PayWithConverge.open(paymentFields, callback);
    };

    return (
      <Form.Section title={'Billing Address'}>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={createUpdateFields.address} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={createUpdateFields.city} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown fieldConfig={createUpdateFields.stateCode} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.InputMasked fieldConfig={createUpdateFields.zipCode} />
        </Form.Row>
        <Button
          disabled={continueButtonDisabled || isElavonModalLoading}
          primary
          content="Enter Card Info"
          loading={isElavonModalLoading}
          onClick={onCreateEditSubmit}
        />
        <Button
          content="Cancel"
          disabled={isElavonModalLoading}
          onClick={() => setShowChangePayment(false)}
        />
      </Form.Section>
    );
  };

  const paymentMethodPresentMessage = isAdmin
    ? 'Below is the payment method on file for this customer. This payment method will be used when automatically renewing products. You may not make changes to an existing payment method, nor add a new one, but you may remove it.'
    : 'Below is the payment method on file. This payment method will be used when automatically renewing products. You may make changes to the information below or remove the payment method from your account.';

  const paymentMethodNotPresentMessage = isAdmin
    ? 'This customer does not have a payment method setup on their account.'
    : 'No payment method found on your account.';

  if (fetchPaymentMethod.error) {
    return (
      <BasicPage title="">
        <AsyncStateContainer {...fetchPaymentMethod}>
          <Form.Container>
            <h2>Auto-Renew Payment Method</h2>
            <Divider />
            <Message error>
              There was an issue retrieving auto-renew payment information. If
              this issue persists please contact support.
            </Message>
          </Form.Container>
        </AsyncStateContainer>
      </BasicPage>
    );
  }

  const paymentMethod = fetchPaymentMethod.value;

  return (
    <BasicPage title="" css={autoRenewPaymentMethodStyles}>
      <AsyncStateContainer {...fetchPaymentMethod}>
        <Form.Container>
          <h2>Auto-Renew Payment Method</h2>
          {paymentMethod ? (
            <Message info>{paymentMethodPresentMessage}</Message>
          ) : (
            <Message warning>{paymentMethodNotPresentMessage}</Message>
          )}
          {paymentMethod && (
            <>
              <Divider />
              <Grid>
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <p>
                      <b>Card Type</b>
                      <br /> {paymentMethod?.creditCardType}
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <b>Card Last 4</b> <br /> {paymentMethod?.creditCardLast4}
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <b>Credit Card Expiration</b>
                      <br />
                      {paymentMethod?.creditCardExpiration}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <p>
                      <b>Address</b>
                      <br /> {paymentMethod?.address}
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <b>City</b>
                      <br /> {paymentMethod?.city}
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <b>State</b>
                      <br /> {paymentMethod?.stateCode}
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <b>ZipCode</b>
                      <br /> {paymentMethod?.zipCode}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider hidden />
              <PaymentMethodDeleteConfirmationModal
                isAdmin={isAdmin}
                fetchPaymentMethod={fetchPaymentMethod}
                customerId={customerId}
              />
            </>
          )}
          {(!showChangePayment || !!paymentMethod) && !isAdmin && (
            <Button
              className="mobile-button-margin"
              primary
              fluid={mobileMax}
              onClick={() => setShowChangePayment(true)}
            >
              <FontAwesomeIcon icon={faPencil} />{' '}
              {paymentMethod ? 'Change Payment Method' : 'Add Payment Method'}
            </Button>
          )}
        </Form.Container>
        {!isAdmin && showChangePayment && (
          <Form.Container>
            <h2>
              {paymentMethod ? 'Change Payment Method' : 'New Payment Method'}
            </h2>
            {paymentMethod && (
              <Message warning>
                This will overwrite any existing payment method on this account.
              </Message>
            )}
            <Form
              className="inline"
              onSubmit={() => {}}
              render={() => (
                <>
                  {addressCreateUpdateFields.value && (
                    <AddressFields
                      createUpdateFields={addressCreateUpdateFields.value}
                    />
                  )}
                </>
              )}
            />
          </Form.Container>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};
