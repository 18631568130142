import React from 'react';
import {useUser} from './use-auth';
import {Claims} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {Message} from 'semantic-ui-react';

type ClaimParam = Claims | Claims[];

type RequireAuthorizationProps = {
  claims?: ClaimParam;
  hasClaimOverride?: boolean;
};

type RequireAuthorizationComponentProps = RequireAuthorizationProps & {
  showErrorPage?: boolean;
  onMissing?: () => React.ReactNode;
};

//TODO: Create const file of the claims to replace the use of strings
export const useRequireAuthorization = (props: RequireAuthorizationProps) => {
  const user = useUser();
  let hasClaim: boolean = false;

  if (props.hasClaimOverride !== undefined && props.hasClaimOverride !== null) {
    hasClaim = props.hasClaimOverride;
  } else if (props.claims !== undefined && props.claims !== null) {
    const claims = Array.isArray(props.claims) ? props.claims : [props.claims];
    hasClaim = claims.some((claim) => user.claims?.includes(claim));
  }

  return hasClaim;
};

export const RequireAuthorization: React.FC<RequireAuthorizationComponentProps> = ({
  claims,
  hasClaimOverride,
  showErrorPage = true,
  onMissing,
  children,
}) => {
  const isAuthorized = useRequireAuthorization({claims, hasClaimOverride});
  return (
    <>
      {isAuthorized ? (
        children
      ) : showErrorPage ? (
        <ErrorPage403 />
      ) : onMissing ? (
        onMissing()
      ) : (
        <></>
      )}
    </>
  );
};

const ErrorPage403: React.FC<{}> = () => {
  return (
    <>
      <BasicPage title="">
        <Message negative>
          <Message.Header>
            403 Error: You are not authorized to view this page.
          </Message.Header>
          <p>
            If this should not be the case, contact an administrator or log out
            and log back in to try again.
          </p>
        </Message>
      </BasicPage>
    </>
  );
};
