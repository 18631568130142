import React from 'react';
  
import {Form} from '../forms';
import {fieldConfig, input} from '../forms/schema-utils';
import {ChangeUserPasswordCommand} from '../api/generated';

export type ChangePasswordDto = Omit<ChangeUserPasswordCommand, 'userId'>;

const fields = fieldConfig<ChangePasswordDto>({
  oldPassword: input({
    fieldLabel: 'Old Password ',
    fieldRequired: true,
    inputProps: {
      type: 'password',
      placeholder: 'Old Password',
    },
  }),
  password: input({
    fieldLabel: 'New Password ',
    fieldRequired: true,
    inputProps: {
      type: 'password',
      placeholder: 'New Password',
    },
  }),
  confirmPassword: input({
    fieldLabel: 'Confirm New Password ',
    fieldRequired: true,
    inputProps: {
      type: 'password',
      placeholder: 'Confirm New Password',
    },
  }),
});
  
export const initialValues = {
  oldPassword: '',
  password: '',
  confirmPassword: '',
};

type ChangePasswordFormProps = {
  title: string | React.ReactNode;
  oldPasswordRequired: boolean;
};

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  title,
  oldPasswordRequired,
}) => (
  <Form.Section title={title}>
    {oldPasswordRequired && <Form.Input fieldConfig={fields.oldPassword} />}
    <Form.Input fieldConfig={fields.password} />
    <Form.Input fieldConfig={fields.confirmPassword} />
  </Form.Section>
);
  