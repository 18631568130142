import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useAsync, useMedia} from 'react-use';
import css from '@emotion/css/macro';
import {faAddressCard, faShip} from '@fortawesome/pro-solid-svg-icons';
import {CommercialLicenseRenewalService} from '../api/generated';
import wildlife from '../assets/images/wildlife-blue.svg';
import {BasicPage} from '../basic-page';
import {customerRoutes} from '../internet-sales-portal/routes/config';
import {routes} from '../routes/config';
import {ListButton, StyledButton} from '../styled-components/styled-buttons';
import {
  DashboardCard,
  DashboardCardActions,
  DashboardCardDescription,
  DashboardCardHeader,
  StyledDashboard,
} from '../styled-components/styled-dashboard';
import {Typography} from '../styled-components/typography';
import {Media, MediaSizes} from '../styles/breakpoints';
import {Theme} from '../theme';
import {useTheme} from '../theme/use-theme';
import {GetTagsModal} from './sales-landing-modals/get-tags-modal';
import {TextToTagModal} from './sales-landing-modals/text-to-tag-modal';

export const SalesLandingPage = () => {
  const [, setTheme] = useTheme();
  const history = useHistory();
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTheme(
      (draft) =>
        (draft.palette.mainContentBackgroundColor = Theme.palette.grey00)
    );
    const resetBackground = () => {
      setTheme(
        (draft) =>
          (draft.palette.mainContentBackgroundColor =
            Theme.palette.mainContentBackgroundColor)
      );
    };

    return resetBackground;
  });

  const fetchIsCommercialLicensingEnabled = useAsync(async () => {
    const {result} = await CommercialLicenseRenewalService.getIsEnabled();
    return result;
  }, []);
  const isCommercialLicensingEnabled = fetchIsCommercialLicensingEnabled.value;

  return (
    <>
      <BasicPage title="" css={styles}>
        <StyledDashboard flexWrap="wrap" className="dashboard-card">
          <DashboardCard
            className="wildlife-background"
            backgroundColor={Theme.palette.blue500}
          >
            <DashboardCardHeader>
              <Typography
                variant="heading2"
                as={Link}
                to={routes.customer.login}
                color={Theme.palette.white1}
              >
                Welcome to Your Dashboard
              </Typography>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <Typography variant="body1" color={Theme.palette.white1}>
                Login to see all of your account details in one screen.
              </Typography>
            </DashboardCardDescription>
            <DashboardCardActions>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                onClick={() => history.push(routes.customer.dashboard)}
              >
                Login/Create Account
              </StyledButton>
            </DashboardCardActions>
          </DashboardCard>

          <DashboardCard>
            <DashboardCardHeader>
              <Typography
                variant="heading2"
                as={Link}
                to={routes.customer.licensesAndPermits}
              >
                Licenses & Permits
              </Typography>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <Typography variant="body1">
                Recreational annual licenses purchased on or after June 1, 2022,
                will be valid for 365 days from the date of purchase.
              </Typography>
            </DashboardCardDescription>
            <DashboardCardActions>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                primary
                onClick={() => history.push(routes.customer.licensesAndPermits)}
              >
                Purchase
              </StyledButton>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                onClick={() =>
                  history.push(routes.customer.customerlicensesAndPermits)
                }
              >
                View Purchased
              </StyledButton>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                onClick={() => history.push(routes.customer.dashboard)}
              >
                HIP Certification
              </StyledButton>
            </DashboardCardActions>
          </DashboardCard>

          <DashboardCard>
            <DashboardCardHeader>
              <Typography
                variant="heading2"
                as={Link}
                to={routes.customer.validateTags}
              >
                Tags
              </Typography>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <p>
                <Typography variant="body1">
                  To Validate your Tag, sometimes called a Game Check, please
                  click the button below.
                </Typography>
              </p>
              <p>
                <Typography variant="small">
                  If you prefer to call in your tags, dial{' '}
                  <a className="phone-number-link" href="tel:+12252679998">
                    (225) 267-9998
                  </a>
                </Typography>
              </p>
            </DashboardCardDescription>
            <DashboardCardActions>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                primary
                onClick={() => history.push(routes.customer.validateTags)}
              >
                Validate Tags
              </StyledButton>
              <TextToTagModal
                trigger={
                  <StyledButton size={isMobile ? 'sm' : 'md'}>
                    E-tag/Text to Tag
                  </StyledButton>
                }
              />
              <GetTagsModal
                trigger={
                  <StyledButton size={isMobile ? 'sm' : 'md'}>
                    Get Tags
                  </StyledButton>
                }
              />
            </DashboardCardActions>
          </DashboardCard>

          <DashboardCard>
            <DashboardCardHeader>
              <Typography
                variant="heading2"
                as={Link}
                to={routes.customer.lotteryApplications}
              >
                Lotteries
              </Typography>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <Typography variant="body1">
                LDWF's wildlife management areas offer limited hunts available
                through lotteries. Hunts include several species including
                alligator, deer, dove, turkey, and waterfowl.
              </Typography>
            </DashboardCardDescription>
            <DashboardCardActions>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                primary
                onClick={() =>
                  history.push(routes.customer.lotteryApplications)
                }
              >
                Enter Lottery
              </StyledButton>
            </DashboardCardActions>
          </DashboardCard>

          <DashboardCard>
            <DashboardCardHeader>
              <Typography
                variant="heading2"
                as={Link}
                to={routes.customer.courseAndEventRegistration}
              >
                Events & Courses
              </Typography>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <Typography variant="body1">
                LDWF events offer courses in hunter education, boater education,
                bow education, and more.
              </Typography>
            </DashboardCardDescription>
            <DashboardCardActions>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                onClick={() => {
                  history.push(routes.events);
                }}
              >
                Find Event
              </StyledButton>
              <StyledButton
                size={isMobile ? 'sm' : 'md'}
                onClick={() => {
                  history.push(customerRoutes.customerEventListing);
                }}
              >
                View My Events
              </StyledButton>
            </DashboardCardActions>
          </DashboardCard>

          <DashboardCard>
            <DashboardCardHeader>
              <Typography variant="heading2">Renewals</Typography>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <ListButton
                buttonText="Boat Registration"
                iconColor={Theme.palette.blue400}
                icon={faShip}
                onClick={() => {
                  history.push(routes.boatRenewal.boatRenewalLookup);
                }}
              />
              {isCommercialLicensingEnabled && (
                <ListButton
                  buttonText="Commercial License"
                  iconColor={Theme.palette.blue400}
                  icon={faAddressCard}
                  onClick={() => {
                    history.push(
                      routes.commercialLicenseRenewal.commercialLicenseLookup
                    );
                  }}
                ></ListButton>
              )}
            </DashboardCardDescription>
          </DashboardCard>
        </StyledDashboard>
      </BasicPage>
    </>
  );
};

const styles = css`
  .wildlife-background {
    background-image: url('${wildlife}');
    background-repeat: no-repeat;
    background-position: 140px 45px;
  }

  .image-text {
    position: absolute;
    z-index: 100;
    align-self: center;
    text-align: center;
    padding-top: 5%;
    font-size: 1.5em;
    color: white;
    text-shadow: 2px 2px #2b2d2f;
    letter-spacing: 0.2em;
    line-height: 2em;
  }

  .card-image {
    object-fit: cover;
    height: 300px !important;
    width: 100% !important;
  }

  .card-group .card .card-content {
    background-color: #335c64;
    text-align: center;
  }

  .card-header {
    color: white !important;
  }

  .card-group .card:hover {
    filter: brightness(0.85);
    box-shadow: 5px 8px 10px #808080 !important;
  }

  .card-group .card-base {
    box-shadow: 5px 5px 10px #707070;
  }

  .phone-number-link {
    color: inherit;
    text-decoration: underline;
  }

  .ui.button {
    margin-bottom: 5px;

    ${Media('MobileMax')} {
      padding: 0.75em 1.3em;
    }
  }
`;
