import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Divider, Modal, ModalProps} from 'semantic-ui-react';
import {css} from '@emotion/core';
import {CustomerOptionsService} from '../../api/generated';
import {buildPath, routes} from '../../routes/config';
import {Typography} from '../../styled-components/typography';
import {customerRoutes} from '../routes/config';

type GetTagsModalProps = ModalProps;

export const GetTagsModal: React.FC<GetTagsModalProps> = ({
  onClose,
  ...props
}) => {
  const [tagsDisplayUrl, setTagsDisplayUrl] = useState<string | null>(null);

  const clickGetTags = async () => {
    const {result} = await CustomerOptionsService.getDisplayMenuByName({
      name: 'TAGS',
    });

    const url = buildPath(routes.customer.licensesAndPermitsSetDisplay, {
      id: result?.id ?? 0,
    });

    setTagsDisplayUrl(url);
  };

  useEffect(() => {
    clickGetTags();
  }, []);

  return (
    <Modal css={getTagsModalStyles} closeIcon {...props}>
      <Modal.Header icon>Get Tags</Modal.Header>
      <Modal.Content>
        <div>
          <Typography variant="heading3">
            If you already have your license and just need to claim your tags
            for this hunting season then{' '}
            <Link
              className="link"
              to={tagsDisplayUrl || customerRoutes.licensesAndPermits}
            >
              Click Here
            </Link>
            .
          </Typography>
        </div>
        <Divider />
        <div>
          <div className="get-tag-modal-content-row">
            <Typography variant="heading3">
              If you have already claimed your tags and need to reprint them{' '}
              <Link
                className="link"
                to={customerRoutes.customerlicensesAndPermits}
              >
                Click Here
              </Link>
              .
            </Typography>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

const getTagsModalStyles = css`
  .get-tag-modal-content-row {
    margin-top: 0.5em;
  }

  .link {
    text-decoration: underline !important;
  }
`;
