import {useForm} from 'react-final-form';
import {useCallback} from 'react';

export const splitZipCodeFields = (values) => {
  const splitZipCode = (address) => {
    if (address?.zipCode) {
      const splitZip = address.zipCode.split('-');
      address.zipCode = splitZip[0];
      if (splitZip.length === 2) {
        address.plus4 = splitZip[1];
      } else {
        delete address.plus4;
      }
    }
  };

  if (values.physicalAddress) {
    splitZipCode(values.physicalAddress);
  }
  if (values.mailingAddress) {
    splitZipCode(values.mailingAddress);
  }
  if (values.address) {
    splitZipCode(values.address);
  }
  if (values.instructorAddress) {
    splitZipCode(values.instructorAddress);
  }
};

export const useCopyAddress = (
  primaryAddressName: string,
  secondaryAddressName: string
) => {
  const form = useForm();
  return useCallback(() => {
    const registeredFields = form.getRegisteredFields();
    const secondaryAddressFields = form
      .getRegisteredFields()
      .filter((x) => x.startsWith(`${secondaryAddressName}.`));
    form.batch(() => {
      secondaryAddressFields.forEach((x) => {
        const matchingPrimaryAddressKey = registeredFields.find(
          (y) => y === `${primaryAddressName}.${x.split('.')[1]}`
        );
        if (matchingPrimaryAddressKey) {
          form.change(x, form.getFieldState(matchingPrimaryAddressKey)?.value);
        }
      });
    });

    form.blur(secondaryAddressFields[0]);
  }, [form, primaryAddressName, secondaryAddressName]);
};
