import css from '@emotion/css/macro';
import React from 'react';
import {useAsync} from 'react-use';
import {AccountService, AnyObject} from '../api/generated';
import {Form} from '../forms';
import {fieldConfig, input} from '../forms/schema-utils';
import {useQueryParams} from '../hooks/use-query-params';
import {
  Dimmer,
  Header,
  Loader,
  Grid,
  Container,
  Divider,
  Message,
} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';
import {routes} from '../routes/config';

const fields = fieldConfig<AnyObject>({
  password: input({
    fieldLabel: 'Password',
    inputProps: {
      type: 'password',
    },
  }),
  confirmPassword: input({
    fieldLabel: 'Confirm Password',
    inputProps: {
      type: 'password',
    },
  }),
});

const initialValues = {
  password: '',
  confirmPassword: '',
};

export const ConfirmAccount = () => {
  const {username, token, portal} = useQueryParams<{
    username: string;
    token: string;
    portal: string;
  }>();
  const confirmAccount = useAsync(async () => {
    const result = await AccountService.confirmAccount({username, token});
    return result;
  });
  const history = useHistory();

  const onSubmit = async (values) => {
    const response = await AccountService.resetPassword({
      body: {
        username,
        code: confirmAccount.value?.result?.code,
        ...values,
      },
    });

    if (response.hasErrors) {
      return response;
    }

    switch (portal) {
      case 'ADMIN':
        history.replace(routes.admin.root);
        break;
      case 'CUSTOMER':
        history.replace(routes.customer.root);
        break;
      case 'VENDOR':
        history.replace(routes.vendor.root);
        break;
      default:
        history.replace(routes.customer.root);
        break;
    }
  };

  if (confirmAccount.loading) {
    return (
      <Dimmer inverted active page>
        <Header as="h2" icon inverted>
          <Loader></Loader>
        </Header>
      </Dimmer>
    );
  }

  if (confirmAccount.value?.hasErrors) {
    return (
      <Message negative>
        {confirmAccount.value.validationFailures?.map((x) => (
          <p key={x.propertyName}>{x.errorMessage}</p>
        ))}
      </Message>
    );
  }

  return (
    <Grid css={styles} verticalAlign="middle">
      <Grid.Column>
        <Container text>
          <Form.Container>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Section
                    title={
                      <>
                        Department of Wildlife & Fisheries
                        <Header.Subheader>Set a password</Header.Subheader>
                      </>
                    }
                  >
                    <Form.Input fieldConfig={fields.password} />
                    <Form.Input fieldConfig={fields.confirmPassword} />
                  </Form.Section>

                  <Divider />
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Set Password
                    </Form.Button>
                  </div>
                </>
              )}
            ></Form>
          </Form.Container>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
`;
