import css from '@emotion/css/macro';
import React, {createContext, useContext} from 'react';
import {useAsyncRetry} from 'react-use';
import {AccountService, UserDto, VendorLoginCommand} from '../api/generated';
import logo from '../assets/ldwf-logo-3c-2018-small.png';
import {DelayedLoadingContainer} from '../components/delayed-loading-container';
import {Form} from '../forms';
import {fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {useProduce} from '../hooks/use-produce';
import {notify, useSubscription} from '../hooks/use-subscription';
import {ValidationError} from '../types/index';
import {routes} from '../routes/config';
import {
  Dimmer,
  Loader,
  Message,
  Grid,
  Container,
  Header,
  Image,
  Button,
} from 'semantic-ui-react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';

export type AuthState = {
  user: UserDto | null;
  errors: ValidationError[];
};

const INITIAL_STATE: AuthState = {
  user: null,
  errors: [],
};

export const AuthContext = createContext<AuthState>(INITIAL_STATE);

const fields = fieldConfig<VendorLoginCommand>({
  username: input({
    fieldLabel: 'Username',
  }),
  password: input({
    fieldLabel: 'Password',
    inputProps: {
      type: 'password',
    },
  }),
});

const defaultFormState = getDefaults(fields);

export const VendorLogin = ({fetchCurrentUserRetry}) => {
  const [state, setState] = useProduce<AuthState>(INITIAL_STATE);

  const history = useHistory();

  const fetchCurrentUser = useAsyncRetry(async () => {
    setState((draft) => {
      draft.errors = [];
    });

    const response = await AccountService.getLoggedInUserDetails();

    setState((draft) => {
      draft.user = response.result ?? null;
      draft.errors = response.validationFailures ?? [];
    });
  }, [setState]);

  useSubscription('refresh-session', () => {
    fetchCurrentUser.retry();
  });

  const onSubmit = async (values) => {
    const response = await AccountService.vendorLogin({
      body: values,
    });

    if (response.hasErrors) {
      if (
        response.validationFailures?.some(
          (x) => x.propertyName === 'ExpiredPassword'
        )
      ) {
        const id = response.validationFailures.find(
          (x) => x.propertyName === 'Id'
        );

        if (id && id.errorMessage) {
          history.push(
            routes.changeExpiredPassword +
              `?id=${encodeURIComponent(id.errorMessage)}&isVendor=1`
          );
        }
      }
      return response;
    }

    notify('refresh-session', undefined);
    fetchCurrentUserRetry();
  };

  if (fetchCurrentUser.loading) {
    return (
      <div css={styles}>
        <DelayedLoadingContainer delayInMs={1000}>
          <Dimmer active inverted>
            <Loader indeterminate />
          </Dimmer>
        </DelayedLoadingContainer>
      </div>
    );
  }

  return (
    <Grid css={styles} verticalAlign="middle">
      <Grid.Column>
        <Container text>
          {state.errors.length > 0 && (
            <Message negative>
              {state.errors.map((x) => (
                <p key={x.propertyName}>{x.errorMessage}</p>
              ))}
            </Message>
          )}
          <Form.Container>
            <Image src={logo} className="logo" />
            <Form
              initialValues={defaultFormState}
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Section
                    title={
                      <>
                        Vendor Login
                        <Header.Subheader>
                          Log in to your account
                        </Header.Subheader>
                      </>
                    }
                  >
                    <Form.Input fieldConfig={fields.username} />
                    <Form.Input fieldConfig={fields.password} />

                    <Form.Row proportions={[1, 1]}>
                      <Link
                        className="forgot-password"
                        to={routes.forgotPassword}
                      >
                        Forgot Password?
                      </Link>
                    </Form.Row>
                  </Form.Section>
                  <Message
                    className="info"
                    icon="warning circle"
                    header="First time logging in?"
                    content={
                      <>
                        <strong>
                          For your first sign in, your credentials will be as
                          follows:
                        </strong>
                        <br />
                        <strong>Username: </strong>
                        If you have used the old system, it will remain the
                        same.
                        <br />
                        <strong>Password: </strong>
                        {
                          'WLF<Your Username (all lowercase)><Last 4 digits of Vendor ID>!'
                        }
                        <br />
                        <br />
                        <strong>Example: </strong>
                        <br />
                        <strong>UN: </strong>
                        {'USERNAME'}
                        <br />
                        <strong>PW: </strong>
                        {'WLFusername1234!'}
                      </>
                    }
                  />
                  <Grid>
                    <Grid.Row columns={2} className="button-row">
                      <Grid.Column>
                        <div className="form-actions">
                          <Form.Button type="submit" primary>
                            Log In
                          </Form.Button>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          className="help-button"
                          as={Link}
                          to={routes.resources.vendorHelp.root}
                          secondary
                          content={'Help Resources'}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            ></Form>
          </Form.Container>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

export function useUser(): UserDto {
  const {user} = useContext(AuthContext);
  if (!user) {
    throw new Error(`useUser must be used within an authenticated app`);
  }
  return user;
}

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;

  .logo {
    margin: -100px auto 25px;
  }

  .forgot-password {
    margin-left: auto;
  }

  .button-row {
    width: 100%;
  }

  .help-button {
    margin-top: 10%;
    float: right;
  }
`;
