import {invert, map} from 'lodash';
import {AnyObject} from '../../types';
import {logger} from '../../utils/logger';
import {OptionItemDto, StringOptionItemDto} from '.';
import {DropdownItemProps} from 'semantic-ui-react';

const log = logger('code-gen utils');

export type DropdownOption = {
  text: string;
  value: any;
};

export const getEnumDropdownOptions = (() => {
  const enumMapCache = new WeakMap();

  return (enumObject: AnyObject) => {
    if (!enumObject) {
      throw new Error('invalid enum cache object');
    }
    if (enumMapCache.has(enumObject)) {
      return enumMapCache.get(enumObject) as DropdownOption[];
    }

    log.info('enum cache miss', enumObject);
    const enumOptions = map(enumObject, (value, key) => ({
      text: key,
      value: value,
      key: key,
    }));

    enumMapCache.set(enumObject, enumOptions);
    return enumOptions as DropdownOption[];
  };
})();

export const getOptionDtoDropdownOptions = (
  optionDtos: (OptionItemDto | StringOptionItemDto)[] | undefined
) => {
  if (!optionDtos) {
    return [];
  }

  return optionDtos.map<DropdownItemProps>((x) => {
    return {value: x.value, text: x.label};
  });
};

export const getOptionDtoDropdownOptionsValueAsLabel = (
  optionDtos: (OptionItemDto | StringOptionItemDto)[] | undefined
) => {
  if (!optionDtos) {
    return [];
  }

  return optionDtos.map<DropdownItemProps>((x) => {
    return {value: x.label, text: x.label};
  });
};

export function getKeyOfEnum<T extends object>(_enum: T, key: keyof T) {
  return invert(_enum)[_enum[key] as any];
}

export function getKeyOfEnumValue<T extends object>(
  _enum: T,
  value: T[keyof T]
) {
  return Object.keys(_enum).find((key) => _enum[key] === value);
}