import { useEffect } from "react";
import { useForm } from "react-final-form";

export const fieldCleanupMutator = ([fieldName]:string[], state:any):void => {
  const newSubmitErrors = {...state.formState.submitErrors};
  delete newSubmitErrors[fieldName];
  state.formState.submitErrors = newSubmitErrors;
};

// pass this if the field has a state to clear the state on unmount
 export type UseFieldCleanupState<T> = {
  setStateFn: React.Dispatch<
    React.SetStateAction<T>
  >;
  initialState: T;
};

export const useFieldCleanup = <T>(fieldName:string, fieldState?: UseFieldCleanupState<T>):void => {
  const {change, mutators} = useForm();

  if (typeof mutators?.fieldCleanupMutator !== 'function') {
    throw new Error(
      'fieldCleanupMutator function must be registered to form mutator in order to use the useField cleanup hook.'
    );
  }

  useEffect(() => {
    return () => {
      if(fieldState){
        fieldState.setStateFn(fieldState.initialState)
      }
      change(fieldName, undefined);
      mutators.fieldCleanupMutator(fieldName);
    };
  }, [fieldState, fieldName, change, mutators]);
};