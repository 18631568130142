import {css} from '@emotion/core';
import {cx} from 'emotion';
import React from 'react';
import {Header, HeaderProps} from 'semantic-ui-react';
import {TypographyType} from '../theme/constants/fonts';
import {Media} from '../styles/breakpoints';
import {useTheme} from '../theme/use-theme';

type OmittedHeaderProps = Omit<HeaderProps, 'color'>;

type TypographyProps = OmittedHeaderProps & {
  variant: keyof TypographyType;
  color?: string;
  className?: string;
  lineHeight?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  onClick?: (e?: any) => void;
};

export const Typography: React.FC<TypographyProps> = ({
  variant,
  color,
  className,
  lineHeight,
  fontFamily,
  fontSize,
  fontWeight,
  onClick,
  ...rest
}) => {
  const styles = useStyles(
    variant,
    color,
    lineHeight,
    fontFamily,
    fontSize,
    fontWeight
  );

  return (
    <span css={styles} onClick={onClick}>
      <Header
        as="span"
        className={cx(className, 'styled-header', onClick && 'clickable')}
        {...rest}
      />
    </span>
  );
};

const useStyles = (
  variant: keyof TypographyType,
  color?: string,
  lineHeight?: string,
  fontFamily?: string,
  fontSize?: string,
  fontWeight?: string
) => {
  const [theme] = useTheme();
  const {mobile, desktop} = theme.fonts;

  return css`
    .clickable {
      cursor: pointer;
    }

    .styled-header {
      font-weight: ${fontWeight ?? desktop[variant].weight} !important;
      font-size: ${fontSize ?? desktop[variant].size} !important;
      line-height: ${lineHeight ?? desktop[variant].lineHeight} !important;
      color: ${color ?? desktop[variant].color} !important;
      font-family: ${fontFamily ?? desktop[variant].fontFamily} !important;

      ${Media('MobileMax')} {
        font-weight: ${fontWeight ?? mobile[variant].weight} !important;
        font-size: ${fontSize ?? mobile[variant].size} !important;
        line-height: ${lineHeight ?? mobile[variant].lineHeight} !important;
        color: ${color ?? mobile[variant].color} !important;
        font-family: ${fontFamily ?? mobile[variant].fontFamily} !important;
      }
    }
  `;
};
