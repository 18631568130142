import css from '@emotion/css';
import {cx} from 'emotion';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useAsyncFn, useMedia} from 'react-use';
import {
  CommercialLicenseAccountInformationDto,
  CommercialLicenseRenewalLookupCommand,
  CommercialLicenseRenewalService,
  TransactionCommercialDto,
} from '../../api/generated';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import {fieldConfig, masked, RawFieldConfig} from '../../forms/schema-utils';
import {routes} from '../../routes/config';
import {StyledButton} from '../../styled-components/styled-buttons';
import {StyledPageContainer} from '../../styled-page-container';
import {Media, MediaSizes} from '../../styles/breakpoints';
import {getHomeRoute} from '../../utils/auth-helpers';
import {Divider, Message, Modal} from 'semantic-ui-react';
import {Typography} from '../../styled-components/typography';

const fields = fieldConfig<CommercialLicenseRenewalLookupCommand>({
  licenseNumber: masked({
    fieldLabel: 'License Number',
    fieldRequired: true,
  }),
  socialSecurityNumberLast4: masked({
    fieldLabel: 'Last 4 of SSN / Tax ID Number',
    fieldRequired: true,
    inputProps: {
      options: {
        blocks: [4],
      },
    },
  }),
});

export const CommercialLicenseLookup = () => {
  const history = useHistory();
  const homeRoute = getHomeRoute();
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  const [
    accountInformation,
    setAccountInformation,
  ] = useState<CommercialLicenseAccountInformationDto | null>(null);

  const [
    accountConfirmationModalOpen,
    setAccountConfirmationModalOpen,
  ] = useState<boolean>(false);

  const [
    renewalTransactionInformation,
    setRenewalTransactionInformation,
  ] = useState<TransactionCommercialDto | null>(null);

  const [
    customerHasPendingRenewals,
    setCustomerHasPendingRenewals,
  ] = useState<boolean>(false);

  const linkToPaymentReceipt = (
    transactionInfo: TransactionCommercialDto | null
  ) => {
    history.replace(
      routes.commercialLicenseRenewal.commercialLicensePaymentReceipt,
      {
        transactionInformation: transactionInfo,
      }
    );
  };

  const [, submitLicenseLookup] = useAsyncFn(
    async (values: CommercialLicenseRenewalLookupCommand) => {
      const commercialLicense = await CommercialLicenseRenewalService.lookupCommercialLicense(
        {body: values}
      );

      if (commercialLicense.hasErrors) {
        return commercialLicense;
      }

      if (!commercialLicense.result) {
        return null;
      }

      if (commercialLicense.result.isPaid) {
        const commercialLicenseTransaction = await CommercialLicenseRenewalService.getCommercialLicenseRenewalTransactionById(
          {
            commercialLicenseNumber: values.licenseNumber,
          }
        );

        if (commercialLicenseTransaction.hasErrors) {
          return commercialLicenseTransaction;
        }

        setRenewalTransactionInformation(commercialLicenseTransaction.result!);

        const pendingRenewals = await CommercialLicenseRenewalService.getAllCommercialLicenses(
          {
            body: {licenseNumber: values.licenseNumber},
          }
        );

        if (pendingRenewals.hasErrors) {
          return pendingRenewals;
        }

        if (!pendingRenewals.result) {
          return null;
        }

        if (pendingRenewals.result.length) {
          setAccountInformation(commercialLicense.result);
          setAccountConfirmationModalOpen(true);
          setCustomerHasPendingRenewals(true);
        } else {
          linkToPaymentReceipt(commercialLicenseTransaction.result!);
        }
      } else {
        setAccountInformation(commercialLicense.result);
        setAccountConfirmationModalOpen(true);
      }
    }
  );

  return (
    <StyledPageContainer fluid={isMobile} title="Renew Commercial Licenses">
      <Flex.Row css={styles} justifyContent="space-between" flexWrap="wrap">
        <Flex.Col>
          <Form
            className={cx(isMobile ? 'ui form mini' : '')}
            onSubmit={submitLicenseLookup}
            render={() => (
              <>
                <FormFields isMobile={isMobile} fields={fields} />
                <Flex.Row className="form-actions">
                  <StyledButton
                    size={isMobile ? 'sm' : 'md'}
                    padded={!isMobile}
                    type="submit"
                    primary
                    content="Search"
                  />
                  <StyledButton
                    padded={!isMobile}
                    size={isMobile ? 'sm' : 'md'}
                    onClick={() =>
                      history.push(homeRoute ?? routes.salesLanding)
                    }
                    content="Cancel"
                  />
                </Flex.Row>
              </>
            )}
          />
        </Flex.Col>
        <Flex.Col className="commercial-license-renewal-directions">
          <Typography variant="heading1">
            Search for your commercial license{' '}
          </Typography>
          <Divider fitted />
          <Typography variant="heading3" fontWeight="normal">
            Please provide the full license number (include any applicable
            leading 0's) of the license that you would like to renew and the
            last 4 digits of your Social Security Number/Tax ID Number so we can
            find your info.
          </Typography>
        </Flex.Col>
        <Modal
          open={accountConfirmationModalOpen}
          onClose={() => setAccountConfirmationModalOpen(false)}
          size="tiny"
        >
          <Modal.Header>Confirm Your Information</Modal.Header>
          <Modal.Content>
            {customerHasPendingRenewals && (
              <Message color="yellow" css={styles}>
                License Number {accountInformation?.licenseNumber} has already
                been renewed on {accountInformation?.renewalDate}. If you'd like
                to reprint the receipt for License Number{' '}
                {accountInformation?.licenseNumber}{' '}
                <span
                  className="link"
                  onClick={() => {
                    linkToPaymentReceipt(renewalTransactionInformation);
                  }}
                >
                  CLICK HERE
                </span>
                , otherwise click "Continue" to view other licenses up for
                renewal.
              </Message>
            )}
            <p>
              <strong>Full Name:</strong>{' '}
              {accountInformation?.fullName ?? 'N/A'}
            </p>
            <p>
              <strong>Company Name:</strong>{' '}
              {accountInformation?.companyName ?? 'N/A'}
            </p>
            <p>
              <strong>License Name:</strong>{' '}
              {accountInformation?.description ?? 'N/A'}
            </p>
            <p>
              <strong>License Number:</strong>{' '}
              {accountInformation?.licenseNumber ?? 'N/A'}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <StyledButton
              primary
              type="button"
              content="Continue"
              onClick={() =>
                history.push(
                  routes.commercialLicenseRenewal.commercialLicenseListing,
                  {
                    licenseNumber: accountInformation?.licenseNumber,
                  }
                )
              }
            />
            <StyledButton
              secondary
              type="button"
              onClick={() => setAccountConfirmationModalOpen(false)}
              content="Back to search"
            />
          </Modal.Actions>
        </Modal>
      </Flex.Row>
    </StyledPageContainer>
  );
};

export const FormFields: React.FC<{
  fields: RawFieldConfig<CommercialLicenseRenewalLookupCommand>;
  isMobile: boolean;
}> = ({fields}) => {
  return (
    <Form.Section title="Commercial License Lookup">
      <Form.Row proportions={[1]}>
        <Form.InputMasked fieldConfig={fields.licenseNumber} />
      </Form.Row>
      <Form.Row proportions={[1]}>
        <Form.InputMasked fieldConfig={fields.socialSecurityNumberLast4} />
      </Form.Row>
    </Form.Section>
  );
};

const styles = css`
  .form-field {
    padding: 0px !important;
  }

  .already-paid-message {
    width: 75%;
  }

  .link {
    display: inline-block;
    text-decoration: underline !important;
  }

  .link:hover {
    cursor: pointer;
  }

  ${Media('DesktopMin')} {
    .commercial-license-renewal-directions {
      max-width: 465px;
      margin-right: 214px;
    }
  }

  ${Media('MobileMax')} {
    .commercial-license-renewal-directions {
      max-width: 465px;
    }
  }
`;
