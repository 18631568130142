import React, {useEffect, useMemo, useState} from 'react';
import {useAsync, useMedia} from 'react-use';
import {Divider, Grid, Segment, Table} from 'semantic-ui-react';
import {
  CreateTransactionReturnDto,
  CustomerInformationDto,
  LotteryCheckoutService,
  LotteryReceiptService,
  ShoppingCartDto,
  TransactionCustomerService,
} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {AsyncStateContainer} from '../../components/async-state-container';
import {DateFormat} from '../../components/date';
import {Media, MediaSizes} from '../../styles/breakpoints';
import {css} from '@emotion/core';
import {
  fieldConfig,
  getDefaults,
  masked,
  RawFieldConfig,
} from '../../forms/schema-utils';
import {Form} from '../../forms';
import {Link, useHistory} from 'react-router-dom';
import {routes} from '../../routes/config';
import {notifications} from '../../utils/notification-service';
import {cstNow} from '../../utils/date-time-helpers';
import {ExportButton} from '../../components/export-button';
import {faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import {UpdateEmailOnReceiptPage} from '../../shared/purchase-receipt-email-update';

type FieldConfigDto = {
  phoneNumber: string;
};

const useFields = () => {
  const fetchFields = useAsync(async () => {
    const {result} = await LotteryReceiptService.get();
    return {
      fieldConfig: fieldConfig<FieldConfigDto>({
        phoneNumber: masked({
          fieldLabel: 'Phone Number',
          defaultValue: result?.customerInformation?.phoneNumberPrimary,
          inputProps: {
            options: {
              phone: true,
              phoneRegionCode: 'US',
              delimiter: '-',
            },
          },
        }),
      }),
      customerInformation: result?.customerInformation,
      shoppingCart: result?.shoppingCart,
    };
  });

  return fetchFields;
};

const transactionReceiptDetails: {
  transactionId: number | undefined;
  tenderType: any | null;
} = {transactionId: 0, tenderType: null};

export const LotteryReceipt = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fields = useFields();
  const receiptInformation = useMemo(() => {
    if (fields.value) {
      return {
        customerInformation: fields?.value?.customerInformation,
        shoppingCart: fields?.value?.shoppingCart,
      };
    } else return undefined;
  }, [fields]);

  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  const history = useHistory();
  const {
    transactionState,
  }: {
    transactionState: CreateTransactionReturnDto;
  } = history.location.state;

  const updateEmail = async (values) => {
    return await TransactionCustomerService.updateEmailAddress({
      emailAddress: values.emailAddress,
    });
  };

  const resendEmailReceipt = async () => {
    return await TransactionCustomerService.resendLotteryTransactionReceiptEmail(
      {
        body: {
          shoppingCart: receiptInformation?.shoppingCart!,
        },
      }
    );
  };

  transactionReceiptDetails.transactionId = transactionState.id;

  return (
    <AsyncStateContainer {...fields}>
      <BasicPage title="Receipt">
        <Segment css={styles}>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={12} tablet={16} mobile={16}>
                <h3>Purchased Applications</h3>
                <p>
                  THANK YOU FOR SUPPORTING LOUISIANA DEPARTMENT OF WILDLIFE &
                  FISHERIES
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={12} tablet={16} mobile={16}>
                {receiptInformation && receiptInformation.shoppingCart && (
                  <ShoppingCart
                    shoppingCart={receiptInformation.shoppingCart}
                  />
                )}
                <Grid.Row className="subscribe-to-sms">
                  {receiptInformation && (
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      {fields.value && (
                        <SubscribeToSMS
                          fields={fields.value.fieldConfig}
                          customer={receiptInformation?.customerInformation}
                        />
                      )}
                    </Grid.Column>
                  )}
                </Grid.Row>

                <Grid.Row className="subscribe-to-sms">
                  {receiptInformation && (
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <UpdateEmailOnReceiptPage
                        customerEmail={
                          receiptInformation?.customerInformation?.emailAddress
                        }
                        updateEmail={updateEmail}
                        resendEmail={resendEmailReceipt}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid.Column>
              {receiptInformation && receiptInformation?.customerInformation && (
                <Grid.Column computer={4} tablet={16} mobile={16}>
                  <CustomerInformation
                    customer={receiptInformation?.customerInformation}
                  />
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row className="actions-section">
              <div className="export-button">
                <ExportButton
                  action={
                    TransactionCustomerService.exportTransactionReceiptPdfFile
                  }
                  fileName={`TransactionReceipt-${cstNow().format(
                    'MM/DD/YY-hh:m'
                  )}-${receiptInformation?.customerInformation?.lastName}`}
                  disabled={transactionState.id == null}
                  request={transactionReceiptDetails}
                  label={' Print Receipt'}
                  icon={faFilePdf}
                  fluid={mobileMax}
                />
              </div>
              <Form.Button
                secondary
                as={Link}
                to={routes.customer.dashboard}
                content="Back to Dashboard"
                fluid={mobileMax}
              />
              <Form.Button
                secondary
                as={Link}
                to={routes.customer.lotteryApplications}
                content="Back to Lottery Listing"
                fluid={mobileMax}
              />
            </Grid.Row>
          </Grid>
        </Segment>
      </BasicPage>
    </AsyncStateContainer>
  );
};

export const SubscribeToSMS: React.FC<{
  fields?: RawFieldConfig<FieldConfigDto>;
  customer?: CustomerInformationDto;
}> = ({fields, customer}) => {
  const initialValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const [disableSubscribeButton, setDisableSubscribeButton] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setDisableSubscribeButton(true);
    setEnableLoading(true);
    const enteredNumber = values.phoneNumber;
    const enteredNumberWithCountryCode = `+1${enteredNumber}`;
    //Appends a +1 to the number to appease phoneNumberValidation -- U.S. Canada numbers only
    values.phoneNumber = enteredNumberWithCountryCode;
    const response = await LotteryReceiptService.subscribeToSmsUpdates({
      body: values,
    });

    //Since a response populates the errored / submitted field with the information submitted, we don't want to have the "+1" included
    //instead, we want the original value submitted
    values.phoneNumber = enteredNumber;

    if (response.hasErrors) {
      return response;
    }
    setEnableLoading(false);

    notifications.success('Successfully subscribed to SMS Updates.');
  };

  return customer?.receiveSmsUpdates ? (
    <>
      <div className="box-description">
        <h3>Subscribe to SMS Updates</h3>
        <label>
          You are already signed up to recieve SMS Notifications and will be
          alerted via text message when the status of your application changes.
          You can update your phone number below.
        </label>
      </div>
      <Divider hidden />
      <Form
        initialValues={initialValues}
        className="inline"
        onSubmit={onSubmit}
        disableLoading
        render={() => (
          <>
            {fields && (
              <Form.Row>
                <Form.InputMasked fieldConfig={fields.phoneNumber} />
                <div className="field-submission-container">
                  <Form.Button
                    type="submit"
                    className="field-submission-button"
                    content="Update"
                    disabled={disableSubscribeButton}
                    loading={enableLoading}
                  />
                </div>
              </Form.Row>
            )}
          </>
        )}
      />
    </>
  ) : (
    <>
      <div className="box-description">
        <h3>Subscribe to SMS Updates</h3>
        <label>
          Enter your mobile phone number and click “Subscribe” to be alerted via
          text message when the status of your application changes, you are
          selected in additional drawings, leftovers become available, or your
          lottery has been cancelled.
        </label>
      </div>
      <Divider hidden />
      <Form
        initialValues={initialValues}
        className="inline"
        onSubmit={onSubmit}
        disableLoading
        render={() => (
          <>
            {fields && (
              <>
                <Form.Row>
                  <Form.InputMasked fieldConfig={fields.phoneNumber} />
                  <div className="field-submission-container">
                    <Form.Button
                      type="submit"
                      content="Subscribe"
                      primary
                      className="field-submission-button"
                    />
                  </div>
                </Form.Row>
              </>
            )}
          </>
        )}
      />
    </>
  );
};

export const ShoppingCart: React.FC<{
  shoppingCart: ShoppingCartDto | null;
}> = ({shoppingCart}) => {
  const fetchSelectedDonations = useAsync(async () => {
    const {result} = await LotteryCheckoutService.getSelectedDonation({
      isReceiptView: true,
    });
    return result;
  });

  const cartItemsHaveCost =
    shoppingCart != null && shoppingCart?.total - shoppingCart?.fee > 0;

  const selectedDonations = fetchSelectedDonations.value;

  return (
    <Table basic compact unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <strong>Applications & Hunts</strong>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <ShoppingCartProductRows shoppingCart={shoppingCart} />
        {selectedDonations &&
          selectedDonations.currentDonations
            ?.filter((donation) => donation.donationAmount > 0)
            .map((donation) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <div className="item-description-container">
                      <span className="product-name">
                        {donation.donationName}
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="right" colSpan="2" collapsing>
                    <span className="product-price">
                      {`$${donation.donationAmount.toFixed(2)}`}
                    </span>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        {shoppingCart && (
          <>
            {shoppingCart.fee > 0 && (
              <Table.Row>
                <Table.Cell>
                  <strong>Transaction Fee</strong>
                </Table.Cell>
                <Table.Cell textAlign="right" colSpan="2" collapsing>
                  <strong>{`$${shoppingCart.fee.toFixed(2)}`}</strong>
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell textAlign="right" colSpan="3">
                <h3>{`Total: $${(cartItemsHaveCost
                  ? shoppingCart.total
                  : 0
                ).toFixed(2)}`}</h3>
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Table.Body>
    </Table>
  );
};

export const ShoppingCartProductRows: React.FC<{
  shoppingCart: ShoppingCartDto | null;
}> = ({shoppingCart}) => {
  return (
    <>
      {shoppingCart &&
        shoppingCart.lotteries?.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>
                <div>{item.lotteryName}</div>
                {item.groupCode && (
                  <div className="cart-item-subtext">
                    <strong>Group Code: </strong>
                    <label>{item.groupCode}</label>
                  </div>
                )}
                {item.isLeftover || item.isAward ? (
                  <div className="cart-item-subtext">
                    {item.hunts?.map((hunt) => {
                      return (
                        <li key={hunt.id}>
                          <strong>{hunt.name}</strong> (
                          <DateFormat date={hunt.startDate} /> -
                          <DateFormat date={hunt.endDate} />)
                          <br />
                        </li>
                      );
                    })}
                  </div>
                ) : (
                  <div className="cart-item-subtext">
                    <strong>Draw Date: </strong>
                    <DateFormat date={item.drawDate} />
                  </div>
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {item.itemTotal > 0 && !item.isApplicationFeeWaived
                  ? `$${item.itemTotal.toFixed(2)}`
                  : 'Free'}
              </Table.Cell>
            </Table.Row>
          );
        })}
    </>
  );
};

const CustomerInformation: React.FC<{
  customer: CustomerInformationDto | null;
}> = ({customer}) => {
  const labelWidth = 5;
  const valueWidth = 11;

  return (
    <>
      {customer && (
        <Table className="customer-info-table" basic compact unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <strong>Customer Information</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>LDWF#</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <p>{customer.sportsmanId}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>Name</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <p>{`${customer.firstName} ${customer.lastName}`}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>DOB</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <DateFormat date={customer.dateOfBirth} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>Address</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.street1 ? (
                        <p>{customer.physicalAddress.street1}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>City</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.city ? (
                        <p>{customer.physicalAddress.city}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>State</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.stateCode ? (
                        <p>{customer.physicalAddress.stateCode}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>Zip</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.zipCode ? (
                        <p>{customer.physicalAddress.zipCode}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};

const styles = css`
  ${Media('MobileMax')} {
    &.ui.segment {
      margin: 0 -1rem;
      border-radius: 0;
    }

    .ui.form.donation-options {
      .form-row {
        .image {
          margin-top: 2em !important;
        }

        .field {
          margin: 0em 0.5em 1.5em !important;
          height: 1em !important;
        }
      }
    }

    .customer-info-table {
      margin-top: 15px !important;
    }

    .field-submission-button {
      width: 90%;
    }

    .actions-section {
      text-align: center;
      .ui.button {
        margin: 1rem auto;
        display: block;
      }
    }

    .export-button {
      margin-right: 2rem;
      width: 95%;
    }
  }

  ${Media('TabletMax')} {
    .customer-info-table {
      margin-top: 15px !important;
    }
  }

  .box-description {
    padding-left: 1.25rem;
  }

  .customer-info-table {
    tr td:first-of-type {
      padding-left: 0.3em !important;
    }

    tr td:last-of-type {
      padding-right: 0.3em !important;
    }
    tbody tr td {
      padding: 0em !important;

      .ui.grid {
        padding-top: 0.3em;
        padding-bottom: 0.3em;

        .row {
          padding-top: 0em;
          padding-bottom: 0em;
        }
      }
    }
  }

  .field-submission-container {
    display: flex;
    align-items: center;
  }

  .field-submission-button {
    background-color: #e0e1e2;
    margin: auto;
    margin-top: 26px;
  }

  .subscribe-to-sms {
    background-color: #b8dbe2;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 5px;
  }

  .ui.form.donation-options {
    .form-row {
      .field {
        margin: 0em 0.5em 1em;
        height: 3em;
      }

      .ui.radio.checkbox label {
        padding-left: 1.5em;
      }
    }
  }

  .expiry-slash {
    width: 2em !important;
  }

  .actions-section {
    .ui.button {
      margin: 1rem;
    }
  }
`;
