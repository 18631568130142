import palette from './palette';

export const families = {
  hindMadurai: 'Hind Madurai',
  roboto: 'Roboto',
  helveticaNeue: 'Helvetica Neue',
  arial: 'Arial',
  helvetica: 'Helvetica',
  sansSerif: 'sans-serif',
};

export type FontType = {
  size: string;
  weight: string;
  lineHeight: string;
  color: string;
  fontFamily: string;
};

export type TypographyType = {
  heading1: FontType;
  heading2: FontType;
  heading3: FontType;
  heading4: FontType;
  heading5: FontType;
  subheading1: FontType;
  dropdownHeading: FontType;
  body1: FontType;
  body2: FontType;
  small: FontType;
  labelLarge: FontType;
  labelMedium: FontType;
  labelSmall: FontType;
};

export const desktop: TypographyType = {
  heading1: {
    size: '26px',
    weight: '600',
    lineHeight: '36px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading2: {
    size: '20px',
    weight: '600',
    lineHeight: '28px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading3: {
    size: '16px',
    weight: '600',
    lineHeight: '22px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading4: {
    size: '13px',
    weight: '600',
    lineHeight: '18px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading5: {
    size: '12px',
    weight: '600',
    lineHeight: '19.04px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  dropdownHeading: {
    size: '14px',
    weight: '600',
    lineHeight: '19px',
    color: palette.blue600,
    fontFamily: families.hindMadurai,
  },
  subheading1: {
    size: '14px',
    weight: '600',
    lineHeight: '15.68px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  body1: {
    size: '14px',
    weight: 'normal',
    lineHeight: '130%',
    color: palette.grey700,
    fontFamily: families.roboto,
  },
  body2: {
    size: '13px',
    weight: '400',
    lineHeight: '18px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  small: {
    size: '12px',
    weight: 'normal',
    lineHeight: '124%',
    color: palette.grey700,
    fontFamily: families.roboto,
  },
  labelLarge: {
    size: '14px',
    weight: '500',
    lineHeight: '16.41px',
    color: palette.grey800,
    fontFamily: families.roboto,
  },
  labelMedium: {
    color: palette.grey700,
    fontFamily: families.roboto,
    weight: '500',
    size: '12px',
    lineHeight: '124%',
  },
  labelSmall: {
    color: palette.grey600,
    fontFamily: families.roboto,
    weight: '500',
    size: '11px',
    lineHeight: '12.89px',
  },
};

export const mobile: TypographyType = {
  heading1: {
    size: '18px',
    weight: '600',
    lineHeight: '36px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading2: {
    size: '16px',
    weight: '600',
    lineHeight: '18px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading3: {
    size: '14px',
    weight: '600',
    lineHeight: '19.32px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading4: {
    size: '12px',
    weight: '600',
    lineHeight: '13.44px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  heading5: {
    size: '12px',
    weight: '600',
    lineHeight: '19.04px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  dropdownHeading: {
    size: '14px',
    weight: '600',
    lineHeight: '19px',
    color: palette.blue600,
    fontFamily: families.hindMadurai,
  },
  subheading1: {
    size: '12px',
    weight: '600',
    lineHeight: '16px',
    color: palette.blue800,
    fontFamily: families.hindMadurai,
  },
  body1: {
    size: '12px',
    weight: 'normal',
    lineHeight: '14.4px',
    color: palette.grey700,
    fontFamily: families.roboto,
  },
  body2: {
    size: '11px',
    weight: 'normal',
    lineHeight: '130%',
    color: palette.grey700,
    fontFamily: families.roboto,
  },
  small: {
    size: '10px',
    weight: 'normal',
    lineHeight: '124%',
    color: palette.grey700,
    fontFamily: families.roboto,
  },
  labelLarge: {
    size: '14px',
    weight: '500',
    lineHeight: '16.41px',
    color: palette.grey800,
    fontFamily: families.roboto,
  },
  labelMedium: {
    color: palette.grey700,
    fontFamily: families.roboto,
    weight: '500',
    size: '12px',
    lineHeight: '124%',
  },
  labelSmall: {
    color: palette.grey600,
    fontFamily: families.roboto,
    weight: '500',
    size: '11px',
    lineHeight: '12.89px',
  },
};

const fonts = {
  families,
  desktop,
  mobile,
};

export default fonts;
