import React from 'react';
import {Table, Grid} from 'semantic-ui-react';
import {CustomerInformationDto} from '../../../api/generated';
import {DateFormat} from '../../../components/date';

const labelWidth = 5;
const valueWidth = 11;

export interface CustomerInformationProps {
  customer: CustomerInformationDto | null;
}

export const CustomerInformation = ({customer}: CustomerInformationProps) => {
  return (
    <>
      {customer && (
        <Table className="customer-info-table" basic compact unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <strong>Customer Information</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>LDWF#</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <p>{customer.sportsmanId}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>Name</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <p>{`${customer.firstName} ${customer.lastName}`}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>DOB</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <DateFormat date={customer.dateOfBirth} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>Address</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.street1 ? (
                        <p>{customer.physicalAddress.street1}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>City</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.city ? (
                        <p>{customer.physicalAddress.city}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>State</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.stateCode ? (
                        <p>{customer.physicalAddress.stateCode}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth}>
                      <h5>Zip</h5>
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      {customer.physicalAddress?.zipCode ? (
                        <p>{customer.physicalAddress.zipCode}</p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
