import css from '@emotion/css/macro';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Divider, Header, List, Message, Transition} from 'semantic-ui-react';
import {
  BooleanApiResult,
  CustomerInformationDto,
  IssueDuplicateProductDto,
  ShoppingCartProductDto,
  ValidationError,
  VendorCustomerService,
  VendorTypeService,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DateFormat} from '../components/date';
import {Flex} from '../components/flex';
import {buildPath, routes as vendorRoutes} from '../routes/config';
import {adminRoutes} from '../admin/routes/config';
import {
  addShoppingCartItem,
  CatalogType,
} from '../shared/product-catalog/product-catalog-utils';
import {StyledButton} from '../styled-components/styled-buttons';
import {Typography} from '../styled-components/typography';
import {Theme} from '../theme';
import {notifications} from '../utils/notification-service';
import {CustomerResidencyValidation} from '../internet-sales-portal/customers/customer-residency-validation';

export type DuplicateProduct = {
  id: number;
  code?: string;
  name?: string;
};

export type DuplicateProducts = {
  productIds: DuplicateProduct[];
  lifetimeProductIds: DuplicateProduct[];
};

export const IssueDuplicates = () => {
  const location = useLocation();
  const history = useHistory();

  const [
    replacementProductCartItems,
    setReplacementProductCartItems,
  ] = useState<ShoppingCartProductDto[]>([]);

  const customer: CustomerInformationDto | null = location.state
    ? location.state.customer
    : null;

  const vendorId: number | null = location.state
    ? location.state.vendorId
    : null;

  const fetchCustomerTransactions = useAsync(async () => {
    if (customer) {
      const {result} = await VendorCustomerService.getAllCustomerTransactions({
        customerId: customer.id,
      });

      return result;
    }
    return null;
  });

  const replacementProduct =
    fetchCustomerTransactions.value?.replacementProduct;

  const duplicateLifetimeProduct =
    fetchCustomerTransactions.value?.duplicateLifetimeProduct;

  const hasRevocations = fetchCustomerTransactions.value?.hasRevocation;

  let transactions = useMemo(
    () =>
      fetchCustomerTransactions
        ? fetchCustomerTransactions.value?.pastTransactions
        : [],
    [fetchCustomerTransactions]
  );

  transactions = transactions?.filter((x) => (x.products?.length ?? 0) > 0);

  const fetchIsHqVendor = useAsync(async () => {
    const {result}: Partial<BooleanApiResult> = vendorId
      ? await VendorTypeService.get({vendorId: vendorId})
      : {result: false};
    return {
      isHqVendor: result,
    };
  }, [vendorId]);

  const isHqVendor = fetchIsHqVendor.value?.isHqVendor;
  const isAdmin = location.pathname.includes('admin');

  const catalogType = isHqVendor
    ? isAdmin
      ? CatalogType.admin
      : CatalogType.hqVendor
    : CatalogType.vendor;

  const navigateToCatalog = () => {
    if (catalogType && customer && replacementProductCartItems) {
      replacementProductCartItems.forEach((cartItem, index) => {
        new Promise<void>(async (resolve, reject) => {
          const {result, validationFailures} = await addShoppingCartItem[
            catalogType
          ]({
            body: cartItem,
            customerId: customer.id,
          });

          if (result) {
            resolve();
          } else {
            reject(validationFailures);
          }
        })
          .then(async () => {
            if (index === replacementProductCartItems.length - 1) {
              const pathname = isAdmin
                ? adminRoutes.vendors.purchaseConfirmation
                : vendorRoutes.vendor.purchaseConfirmation;

              history.replace(pathname, {
                catalogType: catalogType,
                customer: customer,
                from: isAdmin
                  ? adminRoutes.vendors.issueDuplicates
                  : vendorRoutes.vendor.issueDuplicates,
              });
            }
          })
          .catch((validationFailures: ValidationError[]) => {
            notifications.errors(validationFailures);
          });
      });
    }
  };

  const isBeingDuplicated = (transactionCustomerProductId: number) => {
    return _.find(
      replacementProductCartItems,
      (x) =>
        x.replacedTransactionCustomerProductId === transactionCustomerProductId
    );
  };

  const addDuplicateProduct = async (product: IssueDuplicateProductDto) => {
    if (replacementProduct && duplicateLifetimeProduct) {
      let replacementProductToAdd = replacementProduct;

      if (product.isLifetime) {
        replacementProductToAdd = duplicateLifetimeProduct;
      }

      const productToAdd: ShoppingCartProductDto = {
        ...replacementProductToAdd,
        startDates: [],
        serialNumbers: [],
        productImageIds: [],
        quantity: 1,
        replacedTransactionCustomerProductId:
          product.transactionCustomerProductId,
      };

      setReplacementProductCartItems([
        ...replacementProductCartItems,
        productToAdd,
      ]);
    }
  };

  const removeDuplicateProductFromCart = (
    product: IssueDuplicateProductDto
  ) => {
    if (replacementProduct && duplicateLifetimeProduct) {
      const filteredCartItems = replacementProductCartItems.filter(
        (item) =>
          item.replacedTransactionCustomerProductId !==
          product.transactionCustomerProductId
      );

      setReplacementProductCartItems([...filteredCartItems]);
    }
  };

  const duplicatableProducts: IssueDuplicateProductDto[] = useMemo(() => {
    if (transactions) {
      let products = transactions.reduce((products, transaction) => {
        return [...products, ...(transaction.products ?? [])];
      }, [] as IssueDuplicateProductDto[]);
      return _.orderBy(products, ['createdTimestamp'], ['desc']);
    } else return [];
  }, [transactions]);

  return (
    <BasicPage title="Issue Duplicates" css={styles}>
      {customer && (
        <>
          <CustomerResidencyValidation
            customerId={customer.id}
            catalogType={catalogType ?? CatalogType.customer}
          />
          <Flex.Col>
            <Typography variant="heading3">
              {`${customer.firstName} ${customer.lastName}`}
            </Typography>
            <Typography variant="subheading1">
              {`LDWF# ${customer.sportsmanId}`}
            </Typography>
          </Flex.Col>
        </>
      )}
      <Divider hidden />
      <Typography variant="subheading1">
        Duplicate Licenses can be printed for $2 per license. Duplicate Lifetime
        Licenses can be printed for $7.50 per lifetime license.
      </Typography>
      <AsyncStateContainer loading={fetchCustomerTransactions.loading}>
        <Divider />
        <Flex.Row justifyContent="space-between">
          <Flex.Col>
            <Flex.Row justifyContent="space-between">
              <Flex.Col>
                <Typography variant="heading4">Purchased</Typography>
              </Flex.Col>
              <Flex.Col className="product-col valid-header">
                <Typography variant="heading4">
                  Valid Start - Valid End
                </Typography>
              </Flex.Col>
              <Flex.Col className="product-col">
                <Typography variant="heading4">Vendor Type</Typography>
              </Flex.Col>
              <Flex.Col className="product-col">
                <Typography variant="heading4">License</Typography>
              </Flex.Col>
            </Flex.Row>
          </Flex.Col>
        </Flex.Row>
        <Divider />
        {hasRevocations ? (
          <>
            <Message negative>
              <Message.Header>Restricted</Message.Header>
              <p>
                Our records indicate that this customer currently has a
                revocation which inhibits them from duplicating licenses at this
                time.
              </p>
            </Message>
          </>
        ) : duplicatableProducts.length > 0 ? (
          duplicatableProducts.map((product) => (
            <Transition.Group
              key={product.transactionCustomerProductId}
              as={List}
              duration={200}
              divided
              size="huge"
              verticalAlign="middle"
            >
              <Flex.Row justifyContent="space-between">
                <Flex.Col>
                  <Flex.Row justifyContent="space-between">
                    <Flex.Col>
                      <Transition.Group animation="fade right" duration={300}>
                        {isBeingDuplicated(
                          product.transactionCustomerProductId
                        ) && <Header as="h3" icon="check" size="medium" />}
                      </Transition.Group>
                    </Flex.Col>
                    <Flex.Col>
                      <Typography variant="small">
                        <DateFormat date={product.createdTimestamp} />
                      </Typography>
                    </Flex.Col>
                    <Flex.Col className="product-col">
                      <Typography variant="heading4">
                        <DateFormat
                          date={product.licenseValidStartDate ?? ''}
                        />
                        {` - `}
                        <DateFormat date={product.licenseValidEndDate ?? ''} />
                      </Typography>
                    </Flex.Col>
                    <Flex.Col className="product-col">
                      <Typography variant="heading5">
                        {product.vendor}
                      </Typography>
                    </Flex.Col>
                    <Flex.Col className="product-col">
                      <Typography variant="heading3">{`(${product.code}) ${product.name}`}</Typography>
                    </Flex.Col>
                  </Flex.Row>
                </Flex.Col>
                <Flex.Col>
                  <Flex.Row>
                    <Flex.Col>
                      {isBeingDuplicated(
                        product.transactionCustomerProductId
                      ) && (
                        <StyledButton
                          className="duplicate-button"
                          buttonColor={Theme.palette.transparent}
                          size="sm"
                          onClick={async () => {
                            await removeDuplicateProductFromCart(product);
                          }}
                        >
                          Cancel
                        </StyledButton>
                      )}
                      {!isBeingDuplicated(
                        product.transactionCustomerProductId
                      ) && (
                        <StyledButton
                          className="duplicate-button"
                          size="md"
                          onClick={async () =>
                            await addDuplicateProduct(product)
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} title="Duplicate" />
                        </StyledButton>
                      )}
                    </Flex.Col>
                  </Flex.Row>
                </Flex.Col>
              </Flex.Row>
            </Transition.Group>
          ))
        ) : (
          <Typography variant="body1">
            There are no licenses available for duplication.
          </Typography>
        )}
        <Divider />
        <StyledButton
          onClick={() => {
            if (customer) {
              if (location.pathname.includes('admin')) {
                history.push(
                  buildPath(adminRoutes.customers.dashboard, {
                    id: customer.id,
                  })
                );
              } else {
                history.push(vendorRoutes.vendor.root, {
                  catalogState: {customer},
                });
              }
            }
          }}
        >
          Back to Dashboard
        </StyledButton>
        <StyledButton
          disabled={replacementProductCartItems.length === 0}
          onClick={navigateToCatalog}
          primary
        >
          Checkout
        </StyledButton>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const styles = css`
  .product-col {
    margin-left: 1rem;
  }

  .valid-header {
    width: 130px;
  }

  .duplicate-button {
    width: 85px !important;
  }

  .expired {
    color: red !important;
  }

  .license .column p {
    padding: 0.5em;
  }

  .license:hover {
    background-color: ${Theme.palette.grey00};
  }
`;
