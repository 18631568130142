import mitt from 'mitt';
import {useEffect} from 'react';

let emitter = mitt();

type SubscriptionEventMap = {
  'refresh-session':
    | undefined
    | {
        commentId?: undefined;
        customerId?: undefined;
        notificationId?: undefined;
        isVerifying?: boolean;
        didVerifyResidency?: boolean;
      };
  'edit-customer-comment': {
    commentId: number;
    customerId?: undefined;
    notificationId?: undefined;
    isVerifying?: undefined;
    didVerifyResidency?: undefined;
  };
  'customer-details-redirect': {
    customerId: number;
    commentId?: undefined;
    notificationId?: undefined;
    isVerifying?: undefined;
    didVerifyResidency?: undefined;
  };
  'event-results-submitted': undefined;
  'customer-updated': undefined;
  'customer-attachments-updated': undefined;
  'vendor-updated': undefined;
  'edit-vendor-comment': {
    commentId: number;
    notificationId?: undefined;
    customerId?: undefined;
    isVerifying?: undefined;
    didVerifyResidency?: undefined;
  };
  'edit-vendor-notification': {
    notificationId: number;
    customerId?: undefined;
    commentId?: undefined;
    isVerifying?: undefined;
    didVerifyResidency?: undefined;
  };
  'product-updated': undefined;
  'event-updated': undefined;
  'corporation-updated': undefined;
  'distribution-account-updated': undefined;
  'setting-updated': undefined;
  'certification-code-updated': undefined;
  'class-event-type-updated': undefined;
  'lottery-fee-updated': undefined;
  'lottery-award-updated': undefined;
  'lottery-category-updated': undefined;
  'lottery-species-updated': undefined;
  'event-type-updated': undefined;
  'display-menu-updated': undefined;
  'revocation-type-updated': undefined;
  'instructor-type-updated': undefined;
  'role-updated': undefined;
  'location-updated': undefined;
  'user-updated': undefined;
  'lottery-email-notification-updated': undefined;
  'lottery-sms-notification-updated': undefined;
  'hunt-location-updated': undefined;
  'customer-certification-generated': undefined;
};

export type SubscriptionEvents = keyof SubscriptionEventMap;

export function useSubscription(
  eventName: SubscriptionEvents,
  cb: (event: SubscriptionEventMap[SubscriptionEvents]) => void
): void {
  useEffect(() => {
    emitter.on(eventName as any, cb as any);
    return () => emitter.off(eventName as any, cb as any);
  });
}

export function notify(
  eventName: SubscriptionEvents,
  event: SubscriptionEventMap[SubscriptionEvents]
) {
  emitter.emit(eventName, event);
}
