import {Global} from '@emotion/core';
import css from '@emotion/css/macro';
import {darken, lighten, textInputs, transparentize} from 'polished';
import React from 'react';
import {Media} from './breakpoints';
import {Theme} from '../theme';

const globalStyles = css`
  .ui {
    &.form {
      .DateInput__disabled {
        & > input::placeholder {
          color: #000;
        }
      }

      ${textInputs()} {
        border-color: ${Theme.palette.grey200};

        &:focus {
          outline: -webkit-focus-ring-color auto 3px;
        }

        &[readonly] {
          background: #f1f1f1;
          color: #6b6b6b;
          &:focus {
            outline: none;
            background: #f1f1f1;
            color: #6b6b6b;
          }
        }
      }

      .field.error {
        ${textInputs()} {
          background: #fff;
          border-color: #e0b4b4;
          color: #9f3a38;
          box-shadow: none;
        }
      }

      .selection.dropdown {
        min-width: 8em;
      }

      .dropdown,
      .selection.dropdown {
        border-color: ${Theme.palette.grey200};

        .menu {
          border-width: 2px;
          border-color: ${Theme.palette.grey200};
          margin: 0px -2px;
          min-width: calc(100% + 4px);
          width: calc(100% + 4px);
        }

        &:not(.button) > .default.text,
        &.default:not(.button) > .text {
          color: ${Theme.palette.grey1};
        }
      }

      .success.message,
      .warning.message,
      .error.message {
        display: block;
      }

      .error.error input:-webkit-autofill {
        box-shadow: inherit !important;
        border-color: #cbcfd1 !important;
      }

      .upper-case input {
        text-transform: uppercase;
      }
    }

    &.input {
      &.labeled:not([class*='corner labeled']) .label {
        line-height: 1.3rem;
        border-width: 2px;
        border-color: ${Theme.palette.grey200};
        padding-top: calc(0.78571429em - 2px);
        padding-bottom: calc(0.78571429em - 2px);
        background-color: #fff;

        &:first-of-type + input {
          border-left: 0;
        }
      }

      &.action {
        > .button {
          border: solid 2px ${Theme.palette.grey200} !important;
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }
        }

        &:not([class*='left action']) {
          > input {
            border-right-color: ${Theme.palette.grey200} !important;
          }

          > .button:last-child {
            border-left: none !important;
          }
        }
      }

      &.input-file-upload {
        .file-upload {
          padding: 0 !important;
          background: #fff;

          .ui.button {
            margin: 0;
            border-radius: 0;
          }
        }
      }
    }

    &.table {
      border-top: 0.2em solid ${Theme.palette.black1};
      font-size: 1rem;

      &[class*='very basic']:not(.sortable):not(.striped) td:first-of-type {
        padding-left: 0.5em;
      }

      .error-text {
        color: ${Theme.palette.errorText} !important;
      }

      .muted-text {
        color: ${Theme.palette.mutedText} !important;
      }

      .warning-text {
        color: ${Theme.palette.warningText} !important;
      }

      &.compact th {
        padding: 0.528571em 0.78571429em;
      }

      &.table tr th {
        border-left: none;
      }

      &.celled tr th {
        border-left: 1px solid rgba(222, 222, 222, 0.43);
      }

      thead th {
        border-radius: 0 !important;
        font-weight: 500;
      }

      &.selectable tbody tr {
        cursor: pointer;

        &.active,
        &.active:hover,
        &:hover {
          background: #f6f7f9 !important;

          .ui.label {
            /* border: solid 1px; */
          }
        }
      }

      tbody tr,
      thead tr {
        .hidden-row-action {
          opacity: 0;
        }

        &:hover .hidden-row-action {
          opacity: 1;
        }

        td:first-of-type,
        th:first-of-type {
          padding-left: 30px;
        }

        td:last-child,
        th:last-child {
          padding-right: 30px;
        }
      }
    }

    &.button {
      font-weight: normal;

      &.primary {
        background-color: ${Theme.palette.primaryColor};

        &:hover,
        &:focus {
          background-color: ${lighten(
            0.075,
            Theme.palette.primaryAccent
          )} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.secondary {
        background-color: #f1eadf;
        color: ${Theme.palette.textLabel};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover,
        &:focus {
          color: ${Theme.palette.textLabel};
          background-color: ${darken(0.075, '#F1EADF')} !important;

          &.active {
            color: ${Theme.palette.white1};
            background-color: ${Theme.palette.black1} !important;
          }
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.tertiary {
        background-color: #fff;
        color: ${Theme.palette.textLabel};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;

        &.negative,
        &.negative.clear {
          color: #db2828;

          &:hover,
          &:focus {
            background: #f9dbdc !important;
            color: #db2828;
          }
        }

        &:hover,
        &:focus {
          color: ${Theme.palette.textLabel};
          background-color: ${darken(0.075, '#e8e8e8')} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      &.basic {
        background-color: ${Theme.palette.white1} !important;
        color: ${Theme.palette.black1};

        &:not(.action-button) {
          box-shadow: 0px 0px 0px 1px ${Theme.palette.basicBoxShadow} !important;
        }
        &:hover {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          color: ${darken(0.04, Theme.palette.black1)} !important;
          background-color: ${darken(0.08, Theme.palette.white1)} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          color: ${darken(0.04, Theme.palette.black1)} !important;
          background-color: ${darken(0.02, Theme.palette.white1)} !important;
        }
        &:active {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.08, Theme.palette.basicBoxShadow)};
          color: ${darken(0.08, Theme.palette.black1)} !important;
          background-color: ${darken(0.05, Theme.palette.white1)} !important;
        }

        &.transparent {
          background-color: ${Theme.palette.transparent} !important;
          &:hover {
            background-color: ${Theme.palette.transparentHover} !important;
          }
          &:active {
            background-color: ${darken(
              0.05,
              Theme.palette.transparentHover
            )} !important;
          }
        }

        &.warning {
          background-color: ${Theme.palette.warningBackground} !important;
          box-shadow: 0px 0px 0px 1px ${Theme.palette.warningBoxShadow} !important;
          color: ${Theme.palette.warning};
          &:hover {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.2, Theme.palette.warningBoxShadow)};
            color: ${darken(0.2, Theme.palette.warning)} !important;
            background-color: ${darken(
              0.03,
              Theme.palette.warningBackground
            )} !important;
          }
          &:focus {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.2, Theme.palette.warningBoxShadow)};
            color: ${darken(0.2, Theme.palette.warning)} !important;
            background-color: ${darken(
              0.03,
              Theme.palette.warningBackground
            )} !important;
          }
          &:active {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.3, Theme.palette.warningBoxShadow)};
            color: ${darken(0.3, Theme.palette.warning)} !important;
            background-color: ${darken(
              0.05,
              Theme.palette.warningBackground
            )} !important;
          }
        }

        &.clear {
          background-color: transparent !important;
          box-shadow: none !important;

          &:hover,
          &:focus {
            background-color: rgba(27, 28, 29, 0.1) !important;
          }
        }
      }

      &.link {
        background: transparent none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        display: inline-block;
        padding: 5px 0px;
        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          color: ${darken(0.04, Theme.palette.black1)} !important;
          background-color: ${darken(0.02, Theme.palette.white1)} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }

      &.white {
        background: #fff !important;
        box-shadow: 0px 0px 0px 1px rgba(107, 112, 117, 0.35) inset,
          0px 0px 0px 0px rgba(50, 53, 56, 0.15) inset;
      }

      &.clear {
        background: transparent none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover {
          color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, Theme.palette.basicBoxShadow)};
          color: ${darken(0.04, Theme.palette.black1)} !important;
          background-color: ${transparentize(
            0.9,
            Theme.palette.black1
          )} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }

      &.no-focus:not(:active) {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    &.header {
      &.no-margin {
        margin: 0;
      }

      &.inverted.attached {
        background-color: ${Theme.palette.black1};
      }

      &.icon svg {
        display: block;
        line-height: 1;
        padding: 0em;
        font-size: 3em;
      }

      &.icon > svg {
        margin: 0em auto 0.5rem;
      }
    }

    &.buttons > .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) > .button,
    &.buttons .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) .button {
      box-shadow: 0px 0px 0px 1px transparent inset,
        0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    }

    &.menu {
      &.pagination {
        border: 1px solid rgba(175, 176, 177, 0.45);
        box-shadow: none;

        .item:before {
          background: rgba(175, 176, 177, 0.45);
        }
      }

      &.secondary.pointing .active.item {
        font-weight: normal;
        color: ${Theme.palette.black1};
        border-color: ${Theme.palette.black1};
      }
    }

    .modal {
      .close-icon {
        position: absolute;
        right: -1rem;
        top: -1rem;

        .ui.button {
          width: 2rem;
          height: 2rem;
          padding: 0;
          border: solid 2px #fff;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.88);
        }
      }

      .content .actions {
        margin: 15px -1.5rem -1.5rem;
        padding: 15px 10px;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.02);
      }

      & > .actions {
        text-align: left;
        padding: 1rem 1rem !important;
      }
    }

    &.segment {
      padding: 2em;
      display: block;

      ${Media('TabletMax')} {
        padding: 1em;
      }

      &.placeholder {
        min-height: 8rem;
        display: flex;
      }
    }

    a.hint {
      float: right;
      font-weight: normal;
      font-size: 1rem;
    }
  }

  .muted {
    color: ${Theme.palette.mutedText} !important;
  }

  .bold-toggle {
    transform: scale(1.1);
    background: #bfbfbf;
    border-radius: 15px;
  }

  [data-reach-skip-link]:focus {
    z-index: 10;
  }

  [data-reach-tab-list] {
    margin: 0 0 1em 0;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
    padding: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  }

  [data-reach-tab-panel] {
    outline: none;
  }

  [data-reach-tab] {
    display: inline-block;
    padding: 0.25em 0.5em;
    margin: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    align-self: flex-end;
    border: none;
    margin: 0 2.5px 0;
    padding: 0.557143em 1.14285714em;
    border-bottom-width: 2px;
    transition: color 0.1s ease;
    background-color: transparent;
    color: #0a2233;
    border-radius: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &[data-selected] {
      background: #185885;
      color: #fff;
    }

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  [data-reach-tab]:disabled {
    opacity: 0.25;
    cursor: default;
  }
`;

export const GlobalStyles = () => <Global styles={globalStyles} />;
