// Helpers
import {EventListItemDto, IspEventListItemDto} from '../../api/generated';
import {momentCst} from '../../utils/date-time-helpers';

export const eventDateTimeSorter = (a, b) => {
  return momentCst(a.eventDate).unix() - momentCst(b.eventDate).unix();
};

export const shortenString = (s: string) => {
  if (s === null) {
    return s;
  }

  const descriptionLength = 30;
  const shortDescriptionParts = (s || '')
    .slice(0, descriptionLength)
    .split(' ');

  if (s.length > descriptionLength) {
    shortDescriptionParts.pop();
  }

  let shortDescription = shortDescriptionParts.join(' ');

  if (s.length > descriptionLength) {
    shortDescription = shortDescription.trim() + '...';
  }

  return shortDescription;
};

export const getNumberOfSeatsRemainingMessage = (
  event: EventListItemDto
): string => {
  const remainingSeatCount = (event.capacity ?? 0) - (event.numberOfStudentsInClass ?? 0);

  const seats = `Seat${remainingSeatCount === 1 ? '' : 's'}`;

  return `${remainingSeatCount} ${seats} Remaining`;
};

export const getNumberOfSeatsRemainingMessageIsp = (
  event: IspEventListItemDto
): string => {
  const remainingSeatCount = (event.capacity ?? 0) - (event.numberOfStudentsInClass ?? 0);

  const seats = `Seat${remainingSeatCount === 1 ? '' : 's'}`;

  return `${remainingSeatCount} ${seats} Remaining`;
};

export const toPascalCase = (str: string, separator: string) => {
  const words = str.match(/([a-zA-Z]+)+/g);

  if (words === null) {
    return str;
  }

  words.forEach((word, index) => {
    word = word.toLowerCase();
    word = word.replace(word.charAt(0), word.charAt(0).toUpperCase());

    words[index] = word;
  });

  return words.join(separator);
};
