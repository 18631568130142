import React from 'react';
import {Form} from '../../forms';
import {Link, useHistory} from 'react-router-dom';
import {Divider} from 'semantic-ui-react';
import {
  IspCustomersService,
  UpdateLoggedInUsersEmailCommand,
} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {fieldConfig, input, RawFieldConfig} from '../../forms/schema-utils';
import {notify} from '../../hooks/use-subscription';
import {notifications} from '../../utils/notification-service';
import _ from 'lodash';
import {useLocation} from 'react-use';

type ReturnRoute = string | null;

export const CustomerEmailSubmission = () => {
  const history = useHistory();
  const fields = useFields();

  const {state: locationState} = useLocation();
  const state = locationState?.state;
  let returnRoute: ReturnRoute = null;

  if (state && state.returnRoute) {
    returnRoute = state.returnRoute;
  }

  const onSubmit = async (values) => {
    const valuesToSubmit = _.cloneDeep(values);

    const response = await IspCustomersService.updateLoggedInUsersEmail({
      body: valuesToSubmit,
    });

    if (response.hasErrors) {
      notifications.error('Email Update Failed');
      return response;
    }
    notify('refresh-session', undefined);

    notifications.success('Email updated');
    history.goBack();
  };

  return (
    <BasicPage title="It looks like you do not have an email...">
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              {fields && <FormFields fields={fields as any} />}
              <Divider />
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Update Email
                </Form.Button>
                <Form.Button secondary as={Link} to={returnRoute}>
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

type UpdateCustomerEmailCommandOmitKeys = 'customerId';
type FieldConfigDto = Omit<
  UpdateLoggedInUsersEmailCommand,
  UpdateCustomerEmailCommandOmitKeys
>;

const useFields = () => {
  return fieldConfig<FieldConfigDto>({
    emailAddress: input({
      fieldLabel: 'Email',
      fieldRequired: true,
    }),
  });
};

const FormFields: React.FC<{
  fields: RawFieldConfig<FieldConfigDto>;
}> = ({fields}) => {
  return (
    <Form.Section title="Add Email">
      <Form.Row proportions={[]}>
        <Form.Input fieldConfig={fields.emailAddress} />
      </Form.Row>
    </Form.Section>
  );
};
