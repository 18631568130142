import css from '@emotion/css';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Divider} from 'semantic-ui-react';
import {routes} from '../routes/config';
import {StyledPageContainer} from '../styled-page-container';

const trainingVideoPathName = {
  pathname: routes.resources.vendorHelp.trainingVideo,
};

const trainingDocumentPathName = {
  pathname: routes.resources.vendorHelp.trainingDocument,
};

const autoRenewDocumentPathName = {
  pathname: routes.resources.vendorHelp.autoRenewDocument,
};

const autoRenewVideoPathName = {
  pathname: routes.resources.vendorHelp.autoRenewVideo,
};

export const HelpPage = () => {
  const history = useHistory();

  return (
    <StyledPageContainer
      title={'Help Resources'}
      className="help-page"
      css={styles}
    >
      <div className="resource-link-container">
        <div className="resource-link">
          <h3>Instructional Videos</h3>
          <Link replace to={trainingVideoPathName} target="_blank">
            Vendor Portal Instructional Video
          </Link>
          <br />
          <Link to={autoRenewVideoPathName} target="_blank">
            Auto-Renew Instructional Video
          </Link>
        </div>
        <Divider />
        <div className="resource-link">
          <h3>Instructional PDFs</h3>
          <Link to={trainingDocumentPathName} target="_blank">
            Vendor Portal Documentation
          </Link>
          <br />
          <Link to={autoRenewDocumentPathName} target="_blank">
            Auto-Renew Documentation
          </Link>
        </div>
      </div>
      <Divider hidden />
      <Button onClick={() => history.goBack()} content={'Go Back'} />
    </StyledPageContainer>
  );
};

const styles = css`
  .content {
    height: 600px;
  }

  .resource-link {
    height: 100px;
  }
`;
