import css from '@emotion/css/macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import {lighten, transparentize} from 'polished';
import {Link} from 'react-router-dom';
import {useAsyncRetry} from 'react-use';
import {Required, Subtract} from 'utility-types';
import {useDebouncedState} from './use-debounced-state';
import {useDelayedExpiration} from './use-delay-expiration';
import {useProduce} from './use-produce';
import {ApiResult, DataTableColumn, DataTableRequest} from '../api/generated';
import {Alert} from '../components/alert';
import {CopyButton} from '../components/copy-button';
import Currency from '../components/currency';
import {DateFormat, DateTimeFormat} from '../components/date';
import {Flex} from '../components/flex';
import Percentage from '../components/percentage';
import {Tooltip} from '../components/tooltip';
import {Button} from '../forms/button';
import {usePagination} from '../hooks/use-pagination';
import {buildPath} from '../routes/config';
import {Media} from '../styles/breakpoints';
import {Theme} from '../theme';
import {notifications} from '../utils/notification-service';
import {flattenObject} from '../utils/object-helpers';
import {
  entries,
  find,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  remove,
} from 'lodash';
import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  AnyObject,
  DataTablePage,
  KeyOf,
  KeysOfType,
  RequireOnlyOne,
  ColumnDefinition,
} from '../types';

import {
  faSort,
  faSortDown,
  faSortUp,
  faFilter,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faPencil,
  IconDefinition,
  faFileInvoice,
  faPrint,
} from '@fortawesome/pro-regular-svg-icons';

import {
  Header,
  Icon,
  Segment,
  Table,
  TableCellProps,
  Input,
  TableRowProps,
  Loader,
  Dimmer,
  TableProps,
  Label,
  SemanticCOLORS,
} from 'semantic-ui-react';
import {Typography} from '../styled-components/typography';

type ColumnRenderProps<TDto> = RequireOnlyOne<
  {
    column?: KeysOfType<TDto, any>;
    render?: (item: TDto) => ReactNode;
  },
  'column' | 'render'
>;

type Cons<H, T> = T extends readonly any[]
  ? ((h: H, ...t: T) => void) extends (...r: infer R) => void
    ? R
    : never
  : never;

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

type Leaves<T, D extends number = 3> = [D] extends [never]
  ? never
  : T extends object
  ? {[K in keyof T]-?: Cons<K, Leaves<T[K], Prev[D]>>}[keyof T]
  : [];

export type ColumnConfig<TDto> = {
  header: string | React.ReactNode;
  cellProps?: TableCellProps;
  copy?: boolean | ((item: TDto) => string);
  sortable?: keyof TDto | Leaves<TDto>;
} & ColumnRenderProps<TDto>;

type ExtraColumnConfig<TDto> = {
  [index: number]: ColumnConfig<TDto>;
};

type RenderEditButton = {
  item: {id: number};
  route: string;
  descriptor: string;
  disabled?: boolean;
  additionalPathParameters?: AnyObject;
};

type RenderViewButton = {
  item: {id: number};
  route: string;
  state?: AnyObject;
  descriptor: string;
};

type RenderPrintButton = {
  item: {id: number};
  route: string;
  descriptor: string;
};

type ServiceCall = (params: any) => Promise<any>;

export type AdditionalParams<T extends ServiceCall> = Partial<
  Subtract<Required<NonNullable<Parameters<T>[0]>>['body'], DataTableRequest>
>;

export type DtoColumnSearch<TDto> = {
  propertyName: KeysOfType<TDto, any>;
  sortDirection: string;
  searchTerm: string;
} & DataTableColumn;

type RuntimeConfigBase<TDto> = {
  onStateChange?: (state: DataTableRequest) => void;
  additionalParams?: AnyObject;
  columnParams?: DtoColumnSearch<TDto>[];
  filter?: (dismiss: () => void) => React.ReactNode;
  filterBadge?: React.ReactNode;
  filterBadgeColor?: SemanticCOLORS;
  filterTitle?: string;
  renderSearch?: (
    onSearchChange: (newValue: string) => void,
    defaultComponent: React.ReactNode
  ) => React.ReactNode;
  footer?: () => React.ReactNode;
};

type RuntimeConfig<TDto> = RuntimeConfigBase<TDto> &
  (
    | {
        actions?: React.ReactNode;
        actionsFunction?: undefined;
      }
    | {
        actions?: undefined;
        actionsFunction?: (data?: DataTablePage<TDto>) => React.ReactNode;
      }
  );

type Sort<TDto> = {
  column: KeyOf<TDto> | Leaves<TDto>;
  direction: 'ASC' | 'DESC';
};

type SortState<TDto> = Sort<TDto> | Sort<TDto>[];

type AutoColumnConfig<TDto> =
  | {
      route: string;
      description: string;
      omitEditButton?: false;
      extraColumns?: ExtraColumnConfig<TDto>;
    }
  | {
      route?: undefined;
      description?: undefined;
      omitEditButton: true;
      extraColumns?: ExtraColumnConfig<TDto>;
    };

type TableColumns<TDto> =
  | {
      columns: ColumnConfig<TDto>[];
      autoColumns?: undefined;
    }
  | {
      columns?: undefined;
      autoColumns: AutoColumnConfig<TDto>;
    }
  | {
      columns?: undefined;
      autoColumns?: undefined;
    };

export type PagedDataTableConfig<TDto> = TableColumns<TDto> & {
  isDisabled?: boolean;
  defaultSort?: SortState<TDto>;
  filterOpen?: boolean;
  rowProps?: (item: TDto) => TableRowProps;
  tableProps?: TableProps;
  initialPageSize?: number;
  useCardView?: boolean;
  returnFetchRetry?: boolean;
  hideUnfilteredTable?: boolean;
  hideSearch?: boolean;
  customRender?: (
    data: DataTablePage<TDto>,
    config: PagedDataTableConfig<TDto>
  ) => React.ReactNode;
  quickSearchConfig?: {
    saveQuickSearch?: boolean;
    quickSearchKey?: string;
  };
};

type State<TDto> = {
  sort: SortState<TDto> | undefined;
  filterOpen: boolean;
};

type PagedDataTable = {
  fetchRetry: () => void;
  table: ReactNode;
};

export function usePagedDataTable<TDto>(
  pagedFetchAction: (request: any) => Promise<ApiResult<DataTablePage<TDto>>>,
  config: PagedDataTableConfig<TDto>,
  runtimeConfig?: RuntimeConfig<TDto>
): PagedDataTable;
export function usePagedDataTable<TDto>(
  pagedFetchAction: (request: any) => Promise<ApiResult<DataTablePage<TDto>>>,
  config: PagedDataTableConfig<TDto>,
  runtimeConfig?: RuntimeConfig<TDto>
): ReactNode {
  const getJoinedKeyName = (
    potentialArray: keyof TDto | Leaves<TDto> | undefined
  ) => {
    return (Array.isArray(potentialArray)
      ? (potentialArray as any)?.join('.')
      : potentialArray) as any;
  };

  const joinAllNestedColumnNames = (
    sort: Sort<TDto> | Sort<TDto>[] | undefined
  ) => {
    if (Array.isArray(sort)) {
      sort.forEach((x) => (x = {...x, column: getJoinedKeyName(x.column)}));
    } else if (sort) {
      sort = {...sort, column: getJoinedKeyName(sort.column)};
    }
    return sort;
  };

  const pagination = usePagination(
    config.initialPageSize
      ? {
          initialPageSize: config.initialPageSize,
        }
      : undefined,
    config.useCardView ?? undefined
  );

  const cachedResult = useRef<DataTablePage<TDto> | null | undefined>();
  const additionalParams = useAdditionalParams(runtimeConfig?.additionalParams);
  const onStateChange = runtimeConfig?.onStateChange;
  const columnParams = runtimeConfig?.columnParams;
  const quickSearchConfig = config.quickSearchConfig;

  const localStorageKey = quickSearchConfig?.quickSearchKey + '-quick-search';
  const saveQuickSearch = quickSearchConfig?.saveQuickSearch;

  const currentlySavedQuickSearchValue =
    localStorage.getItem(localStorageKey) ?? null;
  const [search, setSearch] = useDebouncedState('', 350);
  const isFilterSet = columnParams != null && columnParams?.length > 0;

  const [wasCleared, setWasCleared] = useState<boolean>(false);

  //If we passed in hide unfiltered table and there is not an active search, hide it
  const hideTable = search === '' && config.hideUnfilteredTable && !isFilterSet;

  const [state, setState] = useProduce<State<TDto>>({
    sort: joinAllNestedColumnNames(config.defaultSort),
    filterOpen: config.filterOpen || false,
  });

  const getFirstSortedColumn = useCallback(() => {
    if (state.sort) {
      return Array.isArray(state.sort) ? state.sort[0] : state.sort;
    } else {
      return {} as Sort<TDto>;
    }
  }, [state.sort]);

  const {isDisabled, returnFetchRetry} = config;
  const fetchData = useAsyncRetry(async () => {
    // Returns no data if disabled
    if (isDisabled) {
      return await new Promise<ApiResult<DataTablePage<TDto>>>(() => {});
    }

    let columns = columnParams || [];

    if (state.sort) {
      const sortDirectionPredicate = (x) => x.sortDirection !== '';
      columns
        .filter(sortDirectionPredicate)
        .forEach((x) => (x.sortDirection = ''));

      const sortColumnPredicate = {
        propertyName: getFirstSortedColumn().column,
      };
      const sortColumn =
        (find(columns, sortColumnPredicate) as DtoColumnSearch<TDto>) ||
        undefined;
      if (sortColumn) {
        sortColumn.sortDirection = getFirstSortedColumn().direction;
      } else {
        if (Array.isArray(state.sort)) {
          state.sort.forEach((x) => {
            columns.push({
              propertyName: x.column,
              sortDirection: x.direction,
              searchTerm: '',
            } as DtoColumnSearch<TDto>);
          });
        } else {
          columns.push({
            propertyName: state.sort.column,
            sortDirection: state.sort.direction,
            searchTerm: '',
          } as DtoColumnSearch<TDto>);
        }
      }

      const unsearchedUnsortedPredicate = (x) =>
        x.sortDirection === '' && x.searchTerm === '';

      remove(columns, unsearchedUnsortedPredicate);
    }

    const params: DataTableRequest = {
      skip: (pagination.pageNumber - 1) * pagination.pageSize,
      take: pagination.pageSize,
      tableEcho: 0,
      allSearch: search,
      columns,
      includeColumns: true,
      ...additionalParams,
    };

    if (!params.allSearch && currentlySavedQuickSearchValue) {
      params.allSearch = currentlySavedQuickSearchValue;
      setSearch(params.allSearch);
    }

    if (onStateChange) {
      if (saveQuickSearch && localStorageKey) {
        localStorage.setItem(
          localStorageKey,
          wasCleared ? '' : params.allSearch ?? ''
        );
      }

      onStateChange(params);
    }

    try {
      const {result, hasErrors} = await pagedFetchAction({
        body: params as Parameters<typeof pagedFetchAction>['0'],
      });

      if (hasErrors) {
        notifications.error('Failed to fetch data');
      } else {
        cachedResult.current = result;
        return result;
      }
    } catch (error) {
      notifications.error('Failed to fetch data');
    }
  }, [
    isDisabled,
    columnParams,
    state.sort,
    pagination.pageNumber,
    pagination.pageSize,
    search,
    additionalParams,
    currentlySavedQuickSearchValue,
    saveQuickSearch,
    localStorageKey,
    onStateChange,
    pagedFetchAction,
    getFirstSortedColumn,
    setSearch,
    wasCleared,
  ]);

  const showLoading = useDelayedExpiration({
    isActive: fetchData.loading,
    delayInMs: 350,
  });

  const normalizedData = normalizedAdvancedPagedResult<TDto>(
    cachedResult.current
  );

  const SearchComponent = (
    <>
      {!config.hideSearch && (
        <Input
          onChange={(e, {value}) => {
            if (!value) {
              clearSearchValueAndStopSearch();
              return;
            }
            setWasCleared(false);
            setSearch(value);
          }}
          icon="search"
          placeholder="Search"
          className="table-search"
          defaultValue={currentlySavedQuickSearchValue}
        />
      )}
      {runtimeConfig?.filter && (
        <>
          <FilterButton
            onClick={() =>
              setState((draft) => {
                draft.filterOpen = !draft.filterOpen;
              })
            }
            runtimeConfig={runtimeConfig}
            state={state}
          />
        </>
      )}
    </>
  );

  const FluidSearchComponent = (
    <Flex.Row>
      <Flex.Fill className="fluid-search-box">
        {!config.hideSearch && (
          <Input
            icon="search"
            onChange={(e, {value}) => {
              if (!value) {
                clearSearchValueAndStopSearch();
                return;
              }
              setWasCleared(false);
              setSearch(value);
            }}
            placeholder={
              hideTable
                ? 'Search for records or specify filters to show results'
                : 'Search'
            }
            className="table-search"
            fluid
            defaultValue={currentlySavedQuickSearchValue}
          />
        )}
      </Flex.Fill>
      {runtimeConfig?.filter && (
        <FilterButton
          onClick={() => {
            setState((draft) => {
              draft.filterOpen = !draft.filterOpen;
            });
          }}
          runtimeConfig={runtimeConfig}
          state={state}
        />
      )}
    </Flex.Row>
  );

  const FilterComponent = (
    <>
      {state.filterOpen && runtimeConfig?.filter && (
        <>
          <br />
          {runtimeConfig?.filter(() => {
            setState((draft) => {
              draft.filterOpen = !draft.filterOpen;
            });
          })}
        </>
      )}
    </>
  );

  const columns = config.autoColumns
    ? mapColumns<TDto>(config.autoColumns, normalizedData.columns || [])
    : config.columns;

  const getSortable = (column: ColumnConfig<TDto>) =>
    getJoinedKeyName(column.sortable);

  const isSorted = (column: ColumnConfig<TDto>) => {
    const firstSortedColumn = getFirstSortedColumn();
    const columnKey = getSortable(column);

    return firstSortedColumn.column === columnKey;
  };

  const clearSearchValueAndStopSearch = () => {
    localStorage.removeItem(localStorageKey);
    setWasCleared(true);
    setSearch('');
  };

  const table = (
    <>
      {config.customRender ? (
        <>
          <div css={styles}>
            <Flex.Row align="top" className="flex-row-button-container">
              <Flex.Fill>
                {runtimeConfig?.renderSearch
                  ? runtimeConfig.renderSearch(setSearch, FluidSearchComponent)
                  : FluidSearchComponent}
              </Flex.Fill>

              <Flex.Box>
                {runtimeConfig?.actionsFunction
                  ? runtimeConfig?.actionsFunction(normalizedData)
                  : runtimeConfig?.actions}
              </Flex.Box>
            </Flex.Row>
            <Flex.Row>
              <Flex.Fill>{FilterComponent}</Flex.Fill>
            </Flex.Row>
            {!hideTable ? (
              <>
                {config.customRender(normalizedData, config)}
                <Flex.Row className="flex-row-button-container">
                  <Flex.Fill>
                    {pagination.render(
                      normalizedData.totalFilteredRecords / pagination.pageSize, // FIXME: Handle remainders
                      normalizedData.totalFilteredRecords
                    )}
                  </Flex.Fill>
                </Flex.Row>
              </>
            ) : (
              <Flex.Fill className="no-results-found-row">
                <Segment placeholder className="no-results">
                  <Header icon>
                    <Icon name="search" size="tiny" />
                    <Typography variant="heading1">No results yet</Typography>
                    <Flex.Box>
                      <Typography variant="heading4">
                        Search or Filter to see results
                      </Typography>
                    </Flex.Box>
                  </Header>
                </Segment>
              </Flex.Fill>
            )}
          </div>
        </>
      ) : (
        <>
          <Segment css={styles}>
            <Flex.Row align="top">
              <Flex.Fill>
                {runtimeConfig?.renderSearch
                  ? runtimeConfig.renderSearch(setSearch, SearchComponent)
                  : SearchComponent}
              </Flex.Fill>

              <Flex.Box>
                {runtimeConfig?.actionsFunction
                  ? runtimeConfig?.actionsFunction(normalizedData)
                  : runtimeConfig?.actions}
              </Flex.Box>
            </Flex.Row>
            <Flex.Row>
              <Flex.Fill>{FilterComponent}</Flex.Fill>
            </Flex.Row>
            {!!isDisabled ? null : fetchData.error ? ( // When disabled, for now we're returning null. There may be a better option in the future.
              <Alert negative>{fetchData.error.message}</Alert>
            ) : (normalizedData.rows?.length ?? 0) === 0 &&
              !fetchData.loading ? (
              <Segment placeholder className="no-results">
                <Header icon>
                  <Icon name="search" size="tiny" />
                  No results found
                </Header>
              </Segment>
            ) : (normalizedData.rows?.length ?? 0) > 0 ? (
              <>
                <Dimmer.Dimmable className="table-container">
                  <Table
                    basic="very"
                    compact
                    className="paged-table"
                    unstackable
                    {...config.tableProps}
                  >
                    <Table.Header>
                      <Table.Row>
                        {columns &&
                          columns.map((column, columnIndex) => {
                            const key =
                              (column.column as string) ||
                              `column_${columnIndex}`;

                            let sortIcon: IconDefinition | undefined;

                            if (getSortable(column)) {
                              sortIcon = isSorted(column)
                                ? getFirstSortedColumn().direction === 'ASC'
                                  ? faSortUp
                                  : faSortDown
                                : faSort;
                            }

                            return (
                              <Table.HeaderCell
                                {...column.cellProps}
                                key={key}
                                className={cx(
                                  column.cellProps?.className,
                                  getSortable(column) && 'sortable'
                                )}
                                onClick={
                                  getSortable(column) &&
                                  (() => {
                                    const direction =
                                      isSorted(column) &&
                                      getFirstSortedColumn().direction ===
                                        'DESC'
                                        ? 'ASC'
                                        : 'DESC';

                                    setState((draft) => {
                                      draft.sort = {
                                        column: getSortable(column) as any,
                                        direction,
                                      };
                                    });
                                  })
                                }
                              >
                                {column.header}
                                {sortIcon && (
                                  <FontAwesomeIcon icon={sortIcon} />
                                )}
                              </Table.HeaderCell>
                            );
                          })}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {normalizedData.rows?.map((item, itemIndex) => {
                        const rowProps = config.rowProps?.(item) ?? {};
                        return (
                          <Table.Row key={`item_${itemIndex}`} {...rowProps}>
                            {columns &&
                              columns.map((column, columnIndex) => {
                                const key =
                                  (column.column as string) ||
                                  `column_${columnIndex}`;

                                let copyValue: string = '';

                                if (column.copy) {
                                  copyValue = isFunction(column.copy)
                                    ? column.copy(item)
                                    : !column.render
                                    ? ((item[
                                        column.column
                                      ] as unknown) as string)
                                    : '';
                                }

                                return (
                                  <Table.Cell {...column.cellProps} key={key}>
                                    <span
                                      className={cx(
                                        copyValue && 'has-copy-icon'
                                      )}
                                    >
                                      {column.render
                                        ? column.render(item)
                                        : item[column.column]}
                                      {copyValue && (
                                        <CopyButton
                                          value={copyValue}
                                          size="tiny"
                                          className="hidden-row-action"
                                          tabIndex="-1"
                                        />
                                      )}
                                    </span>
                                  </Table.Cell>
                                );
                              })}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                    <Table.Footer>{runtimeConfig?.footer?.()}</Table.Footer>
                  </Table>

                  <Dimmer active={showLoading} inverted />
                  {showLoading && <Loader active />}
                </Dimmer.Dimmable>
                {pagination.render(
                  normalizedData.totalFilteredRecords / pagination.pageSize, // FIXME: Handle remainders
                  normalizedData.totalFilteredRecords
                )}
              </>
            ) : (
              <Loader inline="centered" active={showLoading} />
            )}
          </Segment>
        </>
      )}
    </>
  );

  if (returnFetchRetry) {
    return {
      fetchRetry: fetchData.retry,
      table: table,
    };
  } else {
    return table;
  }
}

type FilterButton<TDto> = {
  onClick: () => void;
  runtimeConfig?: RuntimeConfig<TDto>;
  state: State<TDto>;
};

function FilterButton<TDto>(props: FilterButton<TDto>) {
  return (
    <Button
      type="button"
      className="filter"
      basic
      color="black"
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faFilter} />{' '}
      {props.state.filterOpen ? 'Hide ' : 'Show '}Filter
      {props.runtimeConfig?.filterBadge ? (
        <Label
          className="filter-badge"
          floating
          circular
          color={props.runtimeConfig?.filterBadgeColor ?? 'red'}
        >
          {props.runtimeConfig?.filterBadge}
        </Label>
      ) : (
        ''
      )}
    </Button>
  );
}

export const getActiveFiltersCount = (obj: any) =>
  Object.values(flattenObject(obj)).filter((x) => Boolean(x)).length;

const AdditionalParams = {};
function useAdditionalParams(newParams: AnyObject = AdditionalParams) {
  const [state, setState] = useState(newParams);
  useEffect(() => {
    if (!isEqual(state, newParams)) {
      setState(newParams);
    }
  }, [newParams, state]);
  return state;
}

export function PagedDataTableConfig<TDto>(
  pagedFetchAction: (params: any) => Promise<ApiResult<DataTablePage<TDto>>>,
  config: PagedDataTableConfig<TDto>
): PagedDataTableConfig<TDto> {
  return config;
}

export const RenderEditButton: React.FC<RenderEditButton> = (props) => {
  const {item, route, descriptor, disabled, additionalPathParameters} = props;
  const url = buildPath(route, {
    id: item.id,
    ...additionalPathParameters,
  });
  return (
    <Tooltip label={`Edit ${descriptor}`}>
      <Button
        disabled={disabled}
        className="clear"
        basic
        icon
        as={Link}
        to={url}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    </Tooltip>
  );
};

let to: {
  pathname: string;
  state: AnyObject | undefined;
};

export const RenderViewButton: React.FC<RenderViewButton> = (props) => {
  const {item, route, descriptor, state} = props;
  const url = buildPath(route, {
    id: item.id,
  });

  to = {
    pathname: url,
    state: state,
  };

  return (
    <Tooltip label={`View ${descriptor}`}>
      <Button
        className="clear"
        basic
        icon
        as={Link}
        to={to}
        aria-label={`View ${descriptor}`}
      >
        <FontAwesomeIcon icon={faFileInvoice} />
      </Button>
    </Tooltip>
  );
};

export type renderViewOrEditButtonProps = Pick<
  RenderEditButton | RenderViewButton,
  keyof RenderEditButton & keyof RenderViewButton
> & {
  itemsAreViewOnly: boolean;
};
export const renderViewOrEditButton: React.FC<renderViewOrEditButtonProps> = (
  props
) => {
  const {itemsAreViewOnly, ...rest} = props;

  return itemsAreViewOnly ? (
    <RenderViewButton {...rest} />
  ) : (
    <RenderEditButton {...rest} />
  );
};

export const renderPrintButton: React.FC<RenderPrintButton> = (props) => {
  const {item, route, descriptor} = props;
  const url = buildPath(route, {id: item.id});
  return (
    <Tooltip label={`Print ${descriptor}`}>
      <Button className="clear" basic icon as={Link} to={url}>
        <FontAwesomeIcon icon={faPrint} />
      </Button>
    </Tooltip>
  );
};

const DEFAULT_RESULT = {
  columns: [],
  rows: [],
  totalRecords: 0,
  totalFilteredRecords: 0,
};

function normalizedAdvancedPagedResult<T>(
  value: DataTablePage<T> | null | undefined
): DataTablePage<T> {
  return value || DEFAULT_RESULT;
}

const formatters = {
  Default: (input: string) => input,
  YesNoCell: (input: string) => (input ? 'Yes' : 'No'),
  FormattedPhoneNumberCell: (input: string) => {
    if (!input) {
      return null;
    } else if (input.length !== 12) {
      return input;
    }

    const areaCode = input.slice(2, 5);
    const part1 = input.slice(5, 8);
    const part2 = input.slice(8, 12);

    return `(${areaCode}) ${part1}-${part2}`;
  },
  DateCell: (input: string) => <DateTimeFormat datetime={input} />,
  DateOnlyCell: (input: string) => <DateFormat date={input} />,
  DollarCell: (input: number) => <Currency amount={input * 100} />,
  ParentheticalDollarCell: (input: number) => (
    <Currency amount={input * 100} currencySign="accounting" />
  ),
  NullableDollarCell: (input: number) =>
    !isNil(input) && <Currency amount={input * 100} />,
  NullableParentheticalDollarCell: (input: number) =>
    !isNil(input) && (
      <Currency amount={input * 100} currencySign="accounting" />
    ),
  PercentageCell: (input: number) => <Percentage value={input} />,
};

export function mapColumns<TDto>(
  config: AutoColumnConfig<TDto>,
  serverColumns: ColumnDefinition[]
) {
  const columns: ColumnConfig<TDto>[] = serverColumns.map((x) => {
    return {
      header: x.header,
      sortable: (x.sortable ? x.accessor : undefined) as any,
      column: x.componentAttribute ? undefined : x.accessor,
      render: !x.componentAttribute
        ? undefined
        : (item: TDto) => {
            const format = x.componentAttribute?.name || 'Default';
            return <>{formatters[format](item[x.accessor])}</>;
          },
    } as ColumnConfig<TDto>;
  });

  let result = [...columns];

  if (!config.omitEditButton) {
    const editButtonColumn = {
      header: '',
      render: (item) => (
        <RenderEditButton
          item={item}
          descriptor={config.description}
          route={config.route}
        />
      ),
      cellProps: {
        collapsing: true,
      },
    };
    result.splice(0, 0, editButtonColumn);
  }

  if (isEmpty(config.extraColumns)) {
    return result;
  }

  entries(config.extraColumns).forEach(([index, extraColumn]) => {
    result.splice(parseInt(index), 0, extraColumn);
  });

  return result;
}

const styles = css`
  .flex-row-button-container {
    width: 100%;
    padding-left: 26px;
    padding-right: 60px;
  }

  .actions-box {
    ${Media('MobileMax')} {
      margin-bottom: 5px;
    }
  }

  .flex-row-button-container {
    width: 100%;
    padding-left: 26px;
    padding-right: 60px;

    ${Media('MobileMax')} {
      padding-right: 0px;
    }
  }

  ${Media('MobileMax')} {
    &.ui.segment {
      margin: 0 -1rem;
      border-radius: 0;
    }
  }

  .table-search {
    input {
      border-width: 2px;
      border-color: #cbcfd1;
    }

    &.left.action {
      input {
        margin-left: -1px;
      }

      .ui.button {
        box-shadow: none !important;
        z-index: 0;
      }
    }

    ${Media('MobileMax')} {
      margin-right: 10px;
      margin-bottom: 5px;
    }

    min-width: 175px;
  }

  .filter {
    position: relative;
    margin-left: 1rem;

    ${Media('MobileMax')} {
      margin-left: 0px;
      margin-bottom: 5px;
    }
  }

  .filter-badge {
    z-index: 0 !important;
  }

  .table-container {
    overflow-x: auto;
    margin: 2em 0em;
  }

  .paged-table.ui.table {
    thead th {
      white-space: nowrap;
      padding: 0.528571em 0.78571429em !important;
      border-bottom: 1px solid rgba(34, 36, 38, 0.25);

      &:first-of-type {
        padding-left: 0.5em !important;
      }

      &.sortable {
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }

        svg {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .has-copy-icon {
    white-space: nowrap;
    position: relative;
  }

  .copy-button {
    position: relative;
    opacity: 0;
    margin-left: 0 !important;
    margin-right: -5px !important;
    background-color: ${transparentize(0.2, Theme.palette.white1)} !important;
    border: solid 1px ${transparentize(0.2, Theme.palette.white1)} !important;
    height: 36px;
    width: 36px;
    position: absolute;
    top: 50%;
    right: -38px;
    margin-top: -18px;

    &:hover {
      background-color: ${Theme.palette.white1} !important;
      border-color: inherit !important;
    }
  }

  td:hover .copy-button {
    opacity: 1;
  }

  .no-results .ui.icon.header .icon {
    line-height: 2.5rem;
    font-size: 2rem;
  }

  .ui.buttons {
    .ui.button.basic {
      box-shadow: 0px 0px 0px 1px #144b70 !important;
      color: #144b70 !important;

      &.active {
        background: #144b70 !important;
        color: #fff !important;
      }

      &:active,
      &:focus,
      &:hover {
        background: ${lighten(0.05, '#144b70')} !important;
        color: #fff !important;
      }
    }
  }

  .fluid-search-box {
    max-width: 560px;
  }

  .no-results-found-row {
    margin-top: 40px;
    margin-left: 25px;
    margin-right: 25px;
  }
  .no-results .ui.icon.header .icon {
    margin-top: 10px;
    line-height: 2.5rem;
    font-size: 2rem;
  }
`;
