import _, {some} from 'lodash';
import {useMemo} from 'react';
import {DropdownItemProps} from 'semantic-ui-react';
import {
  CatalogProductDto,
  CustomerCatalogService,
  CustomerInformationDto,
  CustomerMailingAddressDto,
  HipSurveyDto,
  IspCustomersService,
  PreviouslyPurchasedProductsDto,
  ProductAncillaryInfoDto,
  ProductRuleDto,
  ProductRuleTypeCode,
  ProductShoppingCartItemDto,
  RevocationListItemDto,
  ShoppingCartDto,
  ShoppingCartProductDto,
  TenderType,
  TransactionCustomerService,
  VendorCatalogService,
  VendorCustomerService,
  VendorTransactionService,
} from '../../api/generated';
import {Producer, useProduce} from '../../hooks/use-produce';
import {routes} from '../../routes/config';
import {notifications} from '../../utils/notification-service';
import {FulfillmentType} from '../../api/generated/enums';

export const ProductConstantCode = {
  hip: '0009',
  duplicate: '0999',
  lifetimeDuplicate: '0998',
};

export type ModalInfo = {
  isOpen: boolean;
  isUpsell: boolean;
  isQuantity: boolean;
  isAmount: boolean;
  isSerialized: boolean;
  needsMilitaryId: boolean;
  isHip: boolean;
  isNative: boolean;
  selectedProduct: ShoppingCartProductDto | null;
  upsoldRules: ProductRuleDto[] | null;
};

export enum CatalogType {
  vendor = 'Vendor',
  customer = 'Customer',
  hqVendor = 'HqVendor',
  admin = 'admin',
}

export const addShoppingCartItem = {
  [CatalogType.customer]: CustomerCatalogService.addShoppingCartItem,
  [CatalogType.hqVendor]: VendorCatalogService.addShoppingCartItem,
  [CatalogType.vendor]: VendorCatalogService.addShoppingCartItem,
  [CatalogType.admin]: VendorCatalogService.addShoppingCartItem,
};

const getShoppingCart = {
  [CatalogType.customer]: CustomerCatalogService.getShoppingCart,
  [CatalogType.hqVendor]: VendorCatalogService.getShoppingCart,
  [CatalogType.vendor]: VendorCatalogService.getShoppingCart,
  [CatalogType.admin]: VendorCatalogService.getShoppingCart,
};

const addProductAncillaryInfo = {
  [CatalogType.customer]: CustomerCatalogService.addProductAncillaryInfo,
  [CatalogType.hqVendor]: VendorCatalogService.addProductAncillaryInfo,
  [CatalogType.vendor]: VendorCatalogService.addProductAncillaryInfo,
  [CatalogType.admin]: VendorCatalogService.addProductAncillaryInfo,
};

export const clearCart = {
  [CatalogType.customer]: CustomerCatalogService.clearCart,
  [CatalogType.hqVendor]: VendorCatalogService.clearCart,
  [CatalogType.vendor]: VendorCatalogService.clearCart,
  [CatalogType.admin]: VendorCatalogService.clearCart,
};

export const addDonation = {
  [CatalogType.customer]: CustomerCatalogService.addDonation,
  [CatalogType.hqVendor]: VendorCatalogService.addDonation,
  [CatalogType.vendor]: VendorCatalogService.addDonation,
  [CatalogType.admin]: VendorCatalogService.addDonation,
};

export const getSelectedDonation = {
  [CatalogType.customer]: CustomerCatalogService.getSelectedDonation,
  [CatalogType.hqVendor]: VendorCatalogService.getSelectedDonation,
  [CatalogType.vendor]: VendorCatalogService.getSelectedDonation,
  [CatalogType.admin]: VendorCatalogService.getSelectedDonation,
};

export const hasMilitaryIdNumber = {
  [CatalogType.customer]: IspCustomersService.hasMilitaryIdNumber,
  [CatalogType.hqVendor]: VendorCustomerService.hasMilitaryIdNumber,
  [CatalogType.vendor]: VendorCustomerService.hasMilitaryIdNumber,
  [CatalogType.admin]: VendorCustomerService.hasMilitaryIdNumber,
};

export const addMilitaryIdNumber = {
  [CatalogType.customer]: IspCustomersService.addMilitaryIdNumber,
  [CatalogType.hqVendor]: VendorCustomerService.addMilitaryIdNumber,
  [CatalogType.vendor]: VendorCustomerService.addMilitaryIdNumber,
  [CatalogType.admin]: VendorCustomerService.addMilitaryIdNumber,
};

export const updateEmailAddress = {
  [CatalogType.customer]: TransactionCustomerService.updateEmailAddress,
  [CatalogType.hqVendor]: VendorCustomerService.updateEmailAddress,
  [CatalogType.vendor]: VendorCustomerService.updateEmailAddress,
  [CatalogType.admin]: VendorCustomerService.updateEmailAddress,
};

export const getCheckoutInfo = {
  [CatalogType.customer]: TransactionCustomerService.getCheckoutInfo,
  [CatalogType.hqVendor]: VendorTransactionService.getCheckoutInfo,
  [CatalogType.vendor]: VendorTransactionService.getCheckoutInfo,
  [CatalogType.admin]: VendorTransactionService.getCheckoutInfo,
};

const loggedInDashboardRoutes = {
  [CatalogType.customer]: routes.customer.dashboard,
  [CatalogType.hqVendor]: routes.vendor.root,
  [CatalogType.vendor]: routes.vendor.root,
  [CatalogType.admin]: routes.admin.customers.root,
};

const licensesAndPermitsRoutes = {
  [CatalogType.customer]: routes.customer.licensesAndPermits,
  [CatalogType.hqVendor]: routes.vendor.licensesAndPermits,
  [CatalogType.vendor]: routes.vendor.licensesAndPermits,
  [CatalogType.admin]: routes.admin.vendors.licensesAndPermits,
};

const purchaseConfirmationRoutes = {
  [CatalogType.customer]: routes.customer.purchaseConfirmation,
  [CatalogType.hqVendor]: routes.vendor.purchaseConfirmation,
  [CatalogType.vendor]: routes.vendor.purchaseConfirmation,
  [CatalogType.admin]: routes.admin.vendors.purchaseConfirmation,
};

const salesReceiptRoutes = {
  [CatalogType.customer]: routes.customer.salesReceipt,
  [CatalogType.hqVendor]: routes.vendor.salesReceipt,
  [CatalogType.vendor]: routes.vendor.salesReceipt,
  [CatalogType.admin]: routes.admin.vendors.salesReceipt,
};

const mailingAddressConfirmationRoutes = {
  [CatalogType.customer]: routes.customer.mailingAddressConfirmation,
  [CatalogType.hqVendor]: routes.vendor.mailingAddressConfirmation,
  [CatalogType.vendor]: routes.vendor.mailingAddressConfirmation,
  [CatalogType.admin]: routes.admin.vendors.mailingAddressConfirmation,
};

const hipSurveyRoutes = {
  [CatalogType.customer]: routes.customer.hipSurvey,
  [CatalogType.hqVendor]: routes.vendor.hipSurvey,
  [CatalogType.admin]: routes.admin.vendors.hipSurvey,
};

export const catalogRoutes = {
  loggedInDashboardRoutes,
  licensesAndPermitsRoutes,
  purchaseConfirmationRoutes,
  salesReceiptRoutes,
  mailingAddressConfirmationRoutes,
  hipSurveyRoutes,
};

type HipSurveyOmitKeys =
  | 'id'
  | 'licenseYear'
  | 'customerId'
  | 'transactionId'
  | 'transactionCustomerId';

type HipSurvey = Omit<HipSurveyDto, HipSurveyOmitKeys>;

export enum Species {
  isHuntingBirds = 'isHuntingBirds',
  ducksBagged = 'ducksBagged',
  geeseBagged = 'geeseBagged',
  dovesBagged = 'dovesBagged',
  woodcockHunted = 'woodcockHunted',
  cootsAndSnipeHunted = 'cootsAndSnipeHunted',
  railsAndGallinulesHunted = 'railsAndGallinulesHunted',
}

export const INITIAL_HIPSURVEY = {
  [Species.isHuntingBirds]: false,
  [Species.ducksBagged]: 1,
  [Species.geeseBagged]: 1,
  [Species.dovesBagged]: 1,
  [Species.woodcockHunted]: 1,
  [Species.cootsAndSnipeHunted]: 1,
  [Species.railsAndGallinulesHunted]: 1,
};

export type ProductCatalogState = {
  products: CatalogProductDto[];
  displayMenuOptions: DropdownItemProps[];
  ownedProducts: PreviouslyPurchasedProductsDto[];
  tenderType: TenderType | null;
  checkNumber: number | null;
  pivNumber: string | null;
  depositDate: string | null;
  convenienceFee: number | null;
  creditCardFee: number | null;
  stateOfficeCreditCardFeePercent: number | null;
  currentDisplayMenu: number | null;
  activeRevocations: RevocationListItemDto[] | null;
  customer: CustomerInformationDto | null;
  catalogType: CatalogType | null;
  mailingAddress: CustomerMailingAddressDto | null;
  hipSurvey: HipSurvey;
  earlyRenewalConfirmationModalOpen: boolean;
  imageQuantityModalOpen: boolean;
  modalInfo: ModalInfo;
  displayedUpsellProductCodes: string[];
};

export const INITIAL_STATE: ProductCatalogState = {
  products: [],
  displayMenuOptions: [],
  ownedProducts: [],
  tenderType: null,
  checkNumber: null,
  pivNumber: null,
  depositDate: null,
  convenienceFee: null,
  creditCardFee: null,
  stateOfficeCreditCardFeePercent: null,
  currentDisplayMenu: null,
  activeRevocations: null,
  customer: null,
  catalogType: null,
  mailingAddress: null,
  hipSurvey: INITIAL_HIPSURVEY,
  earlyRenewalConfirmationModalOpen: false,
  imageQuantityModalOpen: false,
  modalInfo: {
    isOpen: false,
    isUpsell: false,
    isQuantity: false,
    isAmount: false,
    isSerialized: false,
    needsMilitaryId: false,
    isHip: false,
    isNative: false,
    selectedProduct: null,
    upsoldRules: [],
  },
  displayedUpsellProductCodes: [],
};

type UseProductCatalogState = {
  prevCatalogState: ProductCatalogState | undefined;
};

export const useProductCatalogState = ({
  prevCatalogState,
}: UseProductCatalogState) => {
  const catalogState = prevCatalogState ? prevCatalogState : INITIAL_STATE;
  const [state, setState] = useProduce<ProductCatalogState>(catalogState);

  const productsToDisplay = useMemo(() => {
    return (state.products || []).filter((x) => {
      return x.displayMenuId === state.currentDisplayMenu && x.isPurchasable;
    });
  }, [state.currentDisplayMenu, state.products]);
  return {context: {state, setState}, productsToDisplay};
};

export const isProductPurchasable = (
  productCode: string,
  state: ProductCatalogState
) => {
  return (
    state.products.find((x) => x.code === productCode) ?? {
      isPurchasable: false,
    }
  ).isPurchasable;
};

type ProductRules = {
  allowedProductRules: ProductRuleDto[];
  includedProductRules: ProductRuleDto[];
  excludedProductRules: ProductRuleDto[];
  upsoldProductRules: ProductRuleDto[];
};

export const getRules = (
  product?: CatalogProductDto,
  state?: ProductCatalogState
): ProductRules => {
  const allowedProductRules =
    product?.productRules?.filter(
      (x) => x.productRuleTypeCode === ProductRuleTypeCode.Allows
    ) ?? [];
  const includedProductRules =
    product?.productRules?.filter(
      (x) => x.productRuleTypeCode === ProductRuleTypeCode.Includes
    ) ?? [];
  const excludedProductRules =
    product?.productRules?.filter(
      (x) => x.productRuleTypeCode === ProductRuleTypeCode.Excludes
    ) ?? [];
  const upsoldProductRules =
    product?.productRules?.filter((x) => {
      const isUpsold = x.productRuleTypeCode === ProductRuleTypeCode.Upsells;
      if (isUpsold && state) {
        return isProductPurchasable(x.appliedProductCode ?? '', state);
      }
      return isUpsold;
    }) ?? [];

  return {
    allowedProductRules,
    includedProductRules,
    excludedProductRules,
    upsoldProductRules,
  };
};

export const isMailingAddressConfirmationNeeded = (
  shoppingCart: ProductShoppingCartItemDto[] | undefined
): boolean => {
  if (shoppingCart === undefined) {
    return false;
  }

  return shoppingCart.some(
    (x) => x.product?.isMailingAddressConfirmationRequired
  );
};

export const isHardCardInCart = (
  shoppingCart: ProductShoppingCartItemDto[] | undefined
): boolean => {
  if (shoppingCart === undefined) {
    return false;
  }

  return shoppingCart.some((x) =>
    x.product?.fulfillmentTypes?.some((y) => y === FulfillmentType.HardCard)
  );
};

export const isStateDuckstampInCart = (
  shoppingCart: ProductShoppingCartItemDto[] | undefined
): boolean => {
  if (shoppingCart === undefined) {
    return false;
  }

  return shoppingCart.some((x) =>
    x.product?.fulfillmentTypes?.some(
      (y) => y === FulfillmentType.StateDuckstamp
    )
  );
};

export const isHipSurveyNeeded = (
  shoppingCart: ProductShoppingCartItemDto[] | undefined,
  ownedProducts: PreviouslyPurchasedProductsDto[],
  customer: CustomerInformationDto | null,
  hipProduct: CatalogProductDto | undefined
) => {
  if (isProductOwned(ownedProducts, ProductConstantCode.hip)) {
    return false;
  }

  if (shoppingCart === undefined) {
    return false;
  }

  const hipItem = shoppingCart.find(
    (x) => x.product?.code === ProductConstantCode.hip
  );

  if (hipItem) {
    return true;
  }

  return some(shoppingCart, (x) => {
    const rules = getRules(x.product);
    const hasHipRule = some(
      rules.includedProductRules,
      (y) => y.appliedProductCode === ProductConstantCode.hip
    );

    if (
      hasHipRule &&
      hipProduct &&
      customer &&
      customer?.age >= hipProduct.minimumAge &&
      customer?.age <= hipProduct.maximumAge
    ) {
      return true;
    }
  });
};

export const containsPrereqOrIncluder = (
  productCollection: CatalogProductDto[],
  productCode: string
) => {
  return productCollection.some((product) =>
    product.productRules
      ?.filter(
        (x) =>
          x.productRuleTypeCode === ProductRuleTypeCode.Allows ||
          x.productRuleTypeCode === ProductRuleTypeCode.Includes
      )
      .some((rule) => rule.appliedProductCode === productCode)
  );
};

export const isPrereqOrIncluderInCart = (
  product: CatalogProductDto,
  shoppingCart: ProductShoppingCartItemDto[]
) => {
  const products = shoppingCart.map((x) => x.product!);

  return containsPrereqOrIncluder(products, product.code!);
};

export const isPrereqInCart = (
  product: CatalogProductDto,
  shoppingCart: ProductShoppingCartItemDto[]
) => {
  const products = shoppingCart.map((x) => x.product);

  return products.some((currProduct) =>
    currProduct?.productRules
      ?.filter((x) => x.productRuleTypeCode === ProductRuleTypeCode.Allows)
      .some((rule) => rule.appliedProductCode === product.code)
  );
};

export const isIncludedByInCart = (
  product: CatalogProductDto,
  shoppingCart: ProductShoppingCartItemDto[]
) => {
  const products = shoppingCart.map((x) => x.product);

  return products.some((currProduct) =>
    currProduct?.productRules
      ?.filter((x) => x.productRuleTypeCode === ProductRuleTypeCode.Includes)
      .some((rule) => rule.appliedProductCode === product.code)
  );
};

export const getUpsoldProductsNotIncluded = (
  state: ProductCatalogState,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => {
  return shoppingCart?.filter((itemInCart) => {
    const upsoldByProducts = getParentProductsInCartByRule(
      ProductRuleTypeCode.Upsells,
      itemInCart.product,
      state,
      shoppingCart
    );

    const includedByProducts = getParentProductsInCartByRule(
      ProductRuleTypeCode.Includes,
      itemInCart.product,
      state,
      shoppingCart
    );

    return (
      upsoldByProducts &&
      !_.isEmpty(upsoldByProducts) &&
      includedByProducts &&
      _.isEmpty(includedByProducts)
    );
  });
};

export const getProductsByCodes = (
  productCodes: string[],
  productList: CatalogProductDto[]
) => {
  let products: CatalogProductDto[] = [];
  productCodes.forEach((code) => {
    productList.forEach((product) => {
      if (code === product.code) {
        products.push(product);
      }
    });
  });
  return products;
};

export const getPreviouslyOwnedProduct = (
  ownedProducts: PreviouslyPurchasedProductsDto[],
  productCode?: string
) => {
  return ownedProducts.filter(
    (ownedProduct) => ownedProduct.code === productCode
  )[0];
};

export const isItemInCart = (
  shoppingCart: ProductShoppingCartItemDto[],
  productCode: string
) => {
  if (!shoppingCart) {
    return false;
  }

  return (
    shoppingCart.filter(
      (itemInCart) => itemInCart.product?.code === productCode
    ).length > 0
  );
};

export const isProductOwned = (
  ownedProducts: PreviouslyPurchasedProductsDto[],
  productCode?: string
) => {
  return (
    productCode !== undefined &&
    productCode !== ProductConstantCode.duplicate &&
    productCode !== ProductConstantCode.lifetimeDuplicate &&
    ownedProducts.filter((ownedProduct) => ownedProduct.code === productCode)
      .length > 0
  );
};

export const productHasQuantity = (product: CatalogProductDto) =>
  product.maxPerSale > 1;

export const productHasDates = (product: CatalogProductDto) =>
  product.isStartDateSelectable;

export const productHasSerialNumbers = (product: CatalogProductDto) =>
  product.isSerialized;

export const hasMaxThisSeason = (
  ownedProducts: PreviouslyPurchasedProductsDto[],
  product: CatalogProductDto
) => {
  let hasMax = false;
  let amountThisSeason = 0;
  ownedProducts.forEach((x) => {
    if (x.code === product.code) {
      amountThisSeason++;
    }
  });
  if (amountThisSeason >= product.amountAllowedPerSeason) {
    hasMax = true;
  }
  return {hasMax, amountThisSeason};
};

type GetParentProductsInCartByRule = (
  ruleType: string,
  product: CatalogProductDto | undefined,
  state: ProductCatalogState,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => CatalogProductDto[] | undefined;

export const getParentProductsInCartByRule: GetParentProductsInCartByRule = (
  ruleType,
  childProduct,
  state,
  shoppingCart
) => {
  let appliedProducts: CatalogProductDto[] = [];
  let isPrereqSatisfied = false;

  state.products.forEach((parentProduct) => {
    const inCart = isItemInCart(shoppingCart ?? [], parentProduct.code!);
    const isOwned = isProductOwned(state.ownedProducts, parentProduct.code!);

    parentProduct?.productRules?.forEach((productRule) => {
      if (
        productRule.productRuleTypeCode === ruleType &&
        productRule.appliedProductCode === childProduct?.code
      ) {
        if (
          (!inCart &&
            !isOwned &&
            !isPrereqSatisfied &&
            ruleType === ProductRuleTypeCode.Allows) ||
          ((inCart || isOwned) && ruleType === ProductRuleTypeCode.Excludes) ||
          (inCart && ruleType === ProductRuleTypeCode.Upsells) ||
          (inCart && ruleType === ProductRuleTypeCode.Includes)
        ) {
          appliedProducts.push(parentProduct);
        } else if (
          (inCart || isOwned) &&
          ruleType === ProductRuleTypeCode.Allows
        ) {
          isPrereqSatisfied = true;
          appliedProducts = [];
        }
      }
    });
  });
  return appliedProducts;
};

export const removeProductFromCustomerBackendCart = async (
  productId: number,
  setState: (mutationFn: Producer<ProductCatalogState>) => void,
  duplicateProductId?: number | undefined
) => {
  const result = await CustomerCatalogService.removeItem({
    productId: productId,
    duplicateProductId: duplicateProductId,
  });

  if (!result.hasErrors) {
    updateCustomerCart(setState);
  }

  return result;
};

export const removeProductFromVendorBackendCart = async (
  productId: number,
  customerId: number,
  setState: (mutationFn: Producer<ProductCatalogState>) => void,
  replacedTransactionCustomerProductId?: number | undefined
) => {
  const result = await VendorCatalogService.removeItem({
    productId,
    customerId,
    replacedTransactionCustomerProductId,
  });

  if (!result.hasErrors) {
    updateVendorCart(setState, customerId);
  }

  return result;
};

type UpdateCustomerCart = (
  setState: (mutationFn: Producer<ProductCatalogState>) => void
) => void;

export const updateCustomerCart: UpdateCustomerCart = async (setState) => {
  const BackendShoppingCart = await CustomerCatalogService.getShoppingCart();
  setState((draft) => {
    draft.convenienceFee = BackendShoppingCart.result?.fee ?? 0;
  });
};

type UpdateVendorCart = (
  setState: (mutationFn: Producer<ProductCatalogState>) => void,
  customerId: number,
  tenderType?: TenderType | null
) => void;

export const updateVendorCart: UpdateVendorCart = async (
  setState,
  customerId,
  tenderType
) => {
  const hasCreditCardFee = tenderType === TenderType.CreditCard;
  const BackendShoppingCart = await VendorCatalogService.getShoppingCart({
    customerId,
    hasCreditCardFee,
  });

  setState((draft) => {
    draft.creditCardFee = BackendShoppingCart.result?.fee ?? 0;
  });
};

type addProduct = (
  selectedProduct: CatalogProductDto,
  state: ProductCatalogState,
  setState: (mutationFn: Producer<ProductCatalogState>) => void,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => Promise<boolean>;

export const addProduct: addProduct = async (
  selectedProduct,
  state,
  setState,
  shoppingCart
) => {
  const result = await selectProduct(
    selectedProduct,
    state,
    setState,
    true,
    shoppingCart
  );
  if (state.catalogType === CatalogType.customer) {
    updateCustomerCart(setState);
  } else if (
    (state.catalogType === CatalogType.vendor ||
      state.catalogType === CatalogType.hqVendor ||
      state.catalogType === CatalogType.admin) &&
    state.customer &&
    state.tenderType
  ) {
    updateVendorCart(setState, state.customer.id);
  }
  return result;
};

type removeProduct = (
  selectedProduct: CatalogProductDto,
  state: ProductCatalogState,
  setState: (mutationFn: Producer<ProductCatalogState>) => void,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => Promise<boolean>;

export const removeProduct: removeProduct = async (
  selectedProduct,
  state,
  setState,
  shoppingCart
) => {
  const result = await selectProduct(
    selectedProduct,
    state,
    setState,
    false,
    shoppingCart
  );

  if (state.catalogType === CatalogType.customer) {
    updateCustomerCart(setState);
  } else if (
    (state.catalogType === CatalogType.vendor ||
      state.catalogType === CatalogType.hqVendor ||
      state.catalogType === CatalogType.admin) &&
    state.customer
  ) {
    updateVendorCart(setState, state.customer.id);
  }

  return result;
};

const addAppliedProduct = async (
  selectedProduct: CatalogProductDto,
  state: ProductCatalogState,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => {
  await selectProduct(selectedProduct, state, () => {}, true, shoppingCart);
};

const removeAppliedProduct = async (
  selectedProduct: CatalogProductDto,
  state: ProductCatalogState,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => {
  await selectProduct(selectedProduct, state, () => {}, false, shoppingCart);
};

type SelectProduct = (
  selectedProduct: CatalogProductDto,
  state: ProductCatalogState,
  setState: (mutationFn: Producer<ProductCatalogState>) => void,
  isAddingToCart: boolean,
  shoppingCart: ProductShoppingCartItemDto[] | undefined
) => Promise<boolean>;

const selectProduct: SelectProduct = async (
  selectedProduct,
  state,
  setState,
  isAddingToCart,
  shoppingCart
) => {
  const productRules = getRules(selectedProduct, state);
  const productToAdd: ShoppingCartProductDto = {
    quantity: 1,
    startDates: [],
    serialNumbers: [],
    productImageIds: [],
    ...selectedProduct,
  };
  const hasStartDates = productHasDates(selectedProduct);
  const hasSerialNumbers = productHasSerialNumbers(selectedProduct);
  const hasProductImages =
    selectedProduct?.productImages &&
    selectedProduct?.productImages?.length > 0;
  const hasIncludes = !_.isEmpty(productRules.includedProductRules);
  const hasExcludes = !_.isEmpty(productRules.excludedProductRules);
  const hasUpsells = !_.isEmpty(productRules.upsoldProductRules);
  const hasAllows = !_.isEmpty(productRules.allowedProductRules);

  let parentProducts: CatalogProductDto[] = [selectedProduct];

  const isCircular = (appliedProductRules: ProductRuleDto[]) => {
    let isCircular = false;
    parentProducts.forEach((parentProduct) => {
      appliedProductRules.forEach((rule) => {
        if (parentProduct.code === rule.appliedProductCode) {
          isCircular = true;
        }
      });
    });
    return isCircular;
  };

  const removeAppliedProductsFromCart = async (
    productRules: ProductRuleDto[],
    shoppingCart: ProductShoppingCartItemDto[] | undefined
  ) => {
    await Promise.all(
      (shoppingCart ?? []).map(async (itemInCart) => {
        await Promise.all(
          productRules.map(async (productRule) => {
            if (itemInCart.product?.code === productRule.appliedProductCode) {
              const noOtherRuleAppliesSameEffect =
                (shoppingCart ?? [])
                  .flatMap((x) => x.product?.productRules)
                  .filter(
                    (x) =>
                      x &&
                      x.productRuleTypeCode ===
                        productRule.productRuleTypeCode &&
                      x.appliedProductCode === productRule.appliedProductCode
                  ).length <= 1;

              if (noOtherRuleAppliesSameEffect) {
                const allRules = getRules(itemInCart.product);

                for (let rule in allRules) {
                  if (
                    !_.isEmpty(productRules[rule]) &&
                    !isCircular(productRules[rule])
                  ) {
                    parentProducts.push(itemInCart.product!);
                    await removeAppliedProductsFromCart(
                      productRules[rule],
                      shoppingCart
                    );
                  }
                }
                await removeAppliedProduct(
                  itemInCart.product!,
                  state,
                  shoppingCart
                );
              }
            }
          })
        );
      })
    );
  };

  const addIncludedProducts = async (shoppingCart) => {
    if (!hasIncludes) {
      return;
    }
    if (state.products) {
      await Promise.all(
        state.products
          .filter((x) => x.isIncludable && x.isPurchasable)
          .map(async (product) => {
            const {hasMax} = hasMaxThisSeason(state.ownedProducts, product);
            await Promise.all(
              productRules.includedProductRules.map(
                async (includedProductRule) => {
                  if (state.catalogType === CatalogType.customer) {
                    const isAppliedProduct =
                      product.code === includedProductRule.appliedProductCode;

                    const isIncludeditemInCart = isItemInCart(
                      shoppingCart,
                      includedProductRule.appliedProductCode!
                    );
                    if (isAppliedProduct && !isIncludeditemInCart && !hasMax) {
                      const includedProduct: CatalogProductDto = {
                        ...product,
                      };
                      await addAppliedProduct(
                        includedProduct,
                        state,
                        shoppingCart
                      );
                      await updateCustomerCart(setState);
                    }
                  } else {
                    const isAppliedProduct =
                      product.code === includedProductRule.appliedProductCode;

                    const isIncludeditemInCart = isItemInCart(
                      shoppingCart,
                      includedProductRule.appliedProductCode!
                    );

                    if (
                      isAppliedProduct &&
                      !isIncludeditemInCart &&
                      !hasMax &&
                      state.customer
                    ) {
                      const includedProduct: CatalogProductDto = {
                        ...product,
                      };
                      await addAppliedProduct(
                        includedProduct,
                        state,
                        shoppingCart
                      );
                      await updateVendorCart(setState, state.customer.id);
                    }
                  }
                }
              )
            );
          })
      );
    }
  };

  const addSelection = async (shoppingCart) => {
    const addProductToBackendCart = new Promise<{
      backendItemId: number;
      shoppingCartDto?: ShoppingCartDto | null;
    }>(async (resolve, reject) => {
      if (
        hasStartDates &&
        productToAdd.startDates?.filter((x) => x).length !==
          productToAdd.quantity
      ) {
        resolve({backendItemId: -1, shoppingCartDto: null});
        return;
      }

      if (
        hasSerialNumbers &&
        productToAdd.serialNumbers?.filter((x) => x).length !==
          productToAdd.quantity
      ) {
        resolve({backendItemId: -1, shoppingCartDto: null});
        return;
      }

      if (
        hasProductImages &&
        productToAdd.productImageIds?.filter((x) => x).length !==
          productToAdd.quantity
      ) {
        resolve({backendItemId: -1, shoppingCartDto: null});
        return;
      }

      if (state.catalogType && state.customer) {
        const isDonation =
          productToAdd.productTypeCodeType === 'CATALOG DONATION';

        const {result} = isDonation
          ? await addDonation[state.catalogType]({
              body: {
                donationAmount: productToAdd.productFeeTotal ?? 0,
                customerId: state.customer.id,
                donationProductCode: productToAdd.code,
              },
            })
          : await addShoppingCartItem[state.catalogType]({
              body: productToAdd,
              customerId: state.customer.id,
            });

        if (result) {
          const BackendShoppingCart = await getShoppingCart[state.catalogType]({
            customerId: state.customer.id,
          });

          const backendCart =
            (isDonation
              ? BackendShoppingCart?.result?.donations
              : BackendShoppingCart?.result?.products) ?? [];

          let addedProduct = backendCart.find(
            (x) => x.productId === productToAdd.id
          );

          if (addedProduct) {
            resolve({
              backendItemId: addedProduct.id,
              shoppingCartDto: BackendShoppingCart.result,
            });
          } else {
            reject();
          }
        } else {
          resolve({backendItemId: -1, shoppingCartDto: null});
        }
      }
    });

    const result = await addProductToBackendCart.then(async (backendInfo) => {
      await addIncludedProducts(shoppingCart);
      if (hasExcludes) {
        await removeAppliedProductsFromCart(
          productRules.excludedProductRules,
          shoppingCart
        );
      }

      if (
        hasStartDates &&
        productToAdd.startDates?.filter((x) => x).length !==
          productToAdd.quantity
      ) {
        notifications.error(
          'There was a problem adding dates for this product'
        );

        return false;
      }
      if (
        hasProductImages &&
        productToAdd.productImageIds?.filter((x) => x).length !==
          productToAdd.quantity
      ) {
        notifications.error(
          'There was a problem adding cards for this product'
        );

        return false;
      }
      if (
        (hasStartDates || hasSerialNumbers || hasProductImages) &&
        state.customer &&
        state.catalogType
      ) {
        const productAncillaryInfo = {
          quantity: productToAdd.quantity,
          startDates: productToAdd.startDates,
          serialNumbers: productToAdd.serialNumbers,
          productImageIds: productToAdd.productImageIds,
          shoppingCartItemId: backendInfo.backendItemId,
        } as ProductAncillaryInfoDto;

        const result = await addProductAncillaryInfo[state.catalogType]({
          customerId: state.customer.id,
          body: productAncillaryInfo,
        });

        if (result.hasErrors) {
          //Validation failures are handled within the modal
          //This prevents toast notifications which would be redundant
          if ((result.validationFailures?.length ?? 0) === 0) {
            notifications.error(
              'There was a problem adding additional info for this product.'
            );
          }

          await removeAppliedProduct(selectedProduct, state, shoppingCart);
          return false;
        }
        const BackendShoppingCart = await getShoppingCart[state.catalogType]({
          customerId: state.customer.id,
        });

        backendInfo = {
          backendItemId: backendInfo.backendItemId,
          shoppingCartDto: BackendShoppingCart.result,
        };
      }

      setState((draft) => {
        draft.modalInfo.isQuantity = false;
        draft.modalInfo.needsMilitaryId = false;
        draft.modalInfo.isOpen = draft.modalInfo.isUpsell;

        if (backendInfo.shoppingCartDto) {
          draft.convenienceFee = backendInfo.shoppingCartDto.fee;
        }

        if (hasUpsells) {
          draft.modalInfo.isOpen = true;
          draft.modalInfo.isUpsell = true;
          draft.modalInfo.selectedProduct = productToAdd;
          draft.modalInfo.upsoldRules = productRules.upsoldProductRules;
        }
      });
      return true;
    });
    return result;
  };

  const removeSelection = async (shoppingCart) => {
    if (hasAllows) {
      await removeAppliedProductsFromCart(
        productRules.allowedProductRules,
        shoppingCart
      );
    }
    if (hasIncludes) {
      await removeAppliedProductsFromCart(
        productRules.includedProductRules,
        shoppingCart
      );
    }

    if (state.catalogType === CatalogType.customer) {
      await removeProductFromCustomerBackendCart(
        productToAdd.id,
        setState,
        productToAdd.replacedTransactionCustomerProductId
      );
    } else if (
      (state.catalogType === CatalogType.vendor ||
        state.catalogType === CatalogType.hqVendor ||
        state.catalogType === CatalogType.admin) &&
      state.customer
    ) {
      await removeProductFromVendorBackendCart(
        productToAdd.id,
        state.customer.id,
        setState,
        productToAdd.replacedTransactionCustomerProductId
      );
    }

    return true;
  };

  if (isAddingToCart) {
    return await addSelection(shoppingCart);
  } else {
    return await removeSelection(shoppingCart);
  }
};
