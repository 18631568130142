import {css} from '@emotion/core';
import {faSearchLocation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import React, {useCallback, useMemo, useState} from 'react';
import {FormSpy} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import {useAsync} from 'react-use';
import {List, Modal} from 'semantic-ui-react';
import {Overwrite} from 'utility-types';
import {
  AdminVerifyCustomerService,
  IspCreateCustomerCommand,
  ResidencyCode,
  VendorVerifyCustomerService,
  VerifyCustomerService,
} from '../../api/generated';
import {StateCode} from '../../api/generated/enums';
import {useUser} from '../../auth/use-auth';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import {AddressProps} from '../../forms/address-fields';
import {
  fieldConfig,
  input,
  radiogroup,
  RawFieldConfig,
} from '../../forms/schema-utils';
import {CatalogType} from '../../shared/product-catalog/product-catalog-utils';
import {StyledButton} from '../../styled-components/styled-buttons';
import {Typography} from '../../styled-components/typography';
import {Theme} from '../../theme';
import {
  getIsResidencyVerified,
  setIsResidencyVerified,
} from '../../utils/auth-helpers';
import {notifications} from '../../utils/notification-service';
import {customerRoutes} from '../routes/config';

type FieldConfigDto = Pick<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  'residencyCode' | 'driversLicenseNumber' | 'legalConfirmation'
>;

enum ResidentYesNoOptions {
  Yes = 'Yes',
  No = 'No',
}

enum ResidencyCodeOptions {
  Yes = ResidencyCode.RESIDENT,
  No = ResidencyCode.NONRESIDENT,
}

const useFields = () => {
  return useAsync(async () => {
    return fieldConfig<FieldConfigDto>({
      residencyCode: radiogroup({
        fieldRequired: true,
      }),
      driversLicenseNumber: input({
        fieldLabel: 'Louisiana DL or ID Number',
        fieldRequired: true,
      }),
      legalConfirmation: input({
        fieldLabel: '',
        fieldRequired: true,
        inputProps: {
          label:
            'I hereby declare that all information provided is true and correct',
        },
      }),
    });
  }, []);
};

const getCustomerInfo = {
  [CatalogType.customer]: VerifyCustomerService.getById,
  [CatalogType.hqVendor]: VendorVerifyCustomerService.getById,
  [CatalogType.vendor]: VendorVerifyCustomerService.getById,
  [CatalogType.admin]: AdminVerifyCustomerService.getById,
};

const verifyCustomer = {
  [CatalogType.customer]: VerifyCustomerService.update,
  [CatalogType.hqVendor]: VendorVerifyCustomerService.update,
  [CatalogType.vendor]: VendorVerifyCustomerService.update,
  [CatalogType.admin]: AdminVerifyCustomerService.update,
};

type CustomerResidencyValidationProps = {
  customerId?: number;
  catalogType: CatalogType;
  onConfirm?: () => void;
  isLotteryListing?: boolean;
};

export const CustomerResidencyValidation: React.FC<CustomerResidencyValidationProps> = ({
  customerId,
  catalogType,
  onConfirm,
  isLotteryListing,
  children,
}) => {
  const isResidencyVerified = getIsResidencyVerified();
  const [open, setOpen] = useState(!isResidencyVerified);

  const history = useHistory();
  const fields = useFields();
  const user = useUser();

  const fetchCustomer = useAsync(async () => {
    if (catalogType === CatalogType.customer) {
      const {result} = await getCustomerInfo[catalogType]();
      return result;
    } else {
      const {result} = await getCustomerInfo[catalogType]({
        id: customerId ?? 0,
      });
      return result;
    }
  }, [catalogType, customerId]);

  const handleVerification = useCallback(
    async (values) => {
      const valuesToSubmit = _.cloneDeep(values);
      valuesToSubmit.id = customerId ?? user.customerId;
      valuesToSubmit.residencyCode = ResidencyCodeOptions[values.residencyCode];

      const response = await verifyCustomer[catalogType]({
        body: valuesToSubmit,
      });

      if (response.hasErrors) {
        notifications.error('Customer Verification Failed');
        return response;
      }

      if (onConfirm) {
        onConfirm();
      }

      setIsResidencyVerified();
      setOpen(false);
    },
    [catalogType, customerId, onConfirm, user.customerId]
  );

  const handleCancel = () => {
    if (catalogType === CatalogType.customer) {
      //Handles infinite loop for one-time login links
      history.push(customerRoutes.dashboard);
    } else {
      history.goBack();
    }
  };

  const initialValues = useMemo(() => {
    if (fetchCustomer.value) {
      return {
        driversLicenseNumber: fetchCustomer.value.driversLicenseNumber,
        driversLicenseStateCode: fetchCustomer.value.driversLicenseStateCode,
        dateOfBirth: fetchCustomer.value.dateOfBirth,
      };
    } else return undefined;
  }, [fetchCustomer.value]);

  return (
    <>
      <Modal
        css={styles}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
        trigger={children}
        closeOnDimmerClick={false}
      >
        <Modal.Content className="verification-modal-content">
          <Flex.Col justifyContent="space-around">
            <Flex.Row justifyContent="space-around">
              <Flex.Col align="center">
                <Typography variant="heading1" icon>
                  <FontAwesomeIcon icon={faSearchLocation} />
                  Are you a Louisiana Resident?
                </Typography>

                <List
                  className="residency-requirement-list"
                  size="small"
                  bulleted
                >
                  <List.Item>
                    Do you have a valid Louisiana DL or ID? (Not required if
                    under 18)
                  </List.Item>
                  <List.Item>
                    You are NOT a resident of another state.
                  </List.Item>
                  <List.Item>
                    You have resided in Louisiana for 6 months or longer.
                  </List.Item>
                  {isLotteryListing && (
                    <List.Item>
                      Please note that the Alligator Lottery requires applicants
                      to reside in Louisiana for 12 months or longer due to
                      alligator licensing requirements.
                    </List.Item>
                  )}
                </List>
              </Flex.Col>
            </Flex.Row>
            <Flex.Row justifyContent="space-around">
              <Form
                initialValues={initialValues}
                onSubmit={handleVerification}
                render={() => (
                  <>
                    {fields.value && (
                      <>
                        <FormFields
                          initialValues={initialValues}
                          fields={fields.value}
                        />
                      </>
                    )}
                    <div className="form-actions">
                      <StyledButton
                        floated="right"
                        type="submit"
                        primary
                        padded
                      >
                        Next
                      </StyledButton>
                      <StyledButton
                        floated="right"
                        padded
                        onClick={handleCancel}
                      >
                        Cancel
                      </StyledButton>
                    </div>
                  </>
                )}
              />
            </Flex.Row>
          </Flex.Col>
        </Modal.Content>
      </Modal>
    </>
  );
};

type FormFieldProps = {
  fields: RawFieldConfig<FieldConfigDto>;
  initialValues:
    | {
        driversLicenseNumber?: string;
        driversLicenseStateCode?: string;
        dateOfBirth: Date;
      }
    | undefined;
};

const FormFields: React.FC<FormFieldProps> = ({fields, initialValues}) => {
  const hasDriversLicenseNumber =
    initialValues !== undefined && initialValues.driversLicenseNumber;

  const hasLouisianaStateCode =
    initialValues !== undefined &&
    initialValues.driversLicenseStateCode === StateCode.LOUISIANA;

  if (initialValues && hasDriversLicenseNumber && !hasLouisianaStateCode) {
    initialValues.driversLicenseNumber = '';
  }

  const age = initialValues
    ? moment().diff(initialValues.dateOfBirth, 'years', false)
    : 0;

  return (
    <>
      <Flex.Row justifyContent="space-around">
        <div>
          <Form.RadioGroup
            fieldConfig={fields.residencyCode}
            enum={ResidentYesNoOptions}
          />
        </div>
      </Flex.Row>
      <Flex.Row
        className="driver-license-input-row"
        justifyContent="space-around"
      >
        <FormSpy>
          {({values}: {values: FieldConfigDto}) => {
            const isResidencySelected =
              ResidentYesNoOptions[values.residencyCode ?? ''] ===
              ResidentYesNoOptions.Yes;

            const isEighteenOrOlder = age >= 18;

            return (
              <>
                {isResidencySelected &&
                  isEighteenOrOlder &&
                  (!hasDriversLicenseNumber ||
                    (hasDriversLicenseNumber && !hasLouisianaStateCode)) && (
                    <Form.Input fieldConfig={fields.driversLicenseNumber} />
                  )}
              </>
            );
          }}
        </FormSpy>
      </Flex.Row>
      <Flex.Row justifyContent="space-around">
        <div className="legal-confirmation-text">
          <Typography variant="body1">
            No person shall procure or attempt to procure a license by fraud,
            deceit, misrepresentation, or any false statement. Licenses are
            affidavits confirming the information is correct. False information
            is subject to investigation and may be grounds for criminal
            prosecution resulting in denial or revocation of licenses and/or
            permits.
          </Typography>
          <Typography variant="heading1">
            <Form.Checkbox fieldConfig={fields.legalConfirmation} />
          </Typography>
        </div>
      </Flex.Row>
    </>
  );
};

const styles = css`
  .verification-modal-content {
    min-height: 20em !important;
    min-height: 40em;
  }

  .driver-license-input-row {
    height: 5em;
  }

  .legal-confirmation-text {
    width: 70%;
    margin-bottom: 1em;
    margin-top: 1em;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .form-field {
      padding-top: 1rem !important;
    }
  }

  .residency-requirement-list {
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }

  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    border: 2px solid ${Theme.palette.blue800};
  }
`;
