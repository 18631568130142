import css from '@emotion/css/macro';
import React from 'react';
import {useAsync} from 'react-use';
import {MetadataService} from '../api/generated';
import palette from '../theme/constants/palette';

const DeploymentInfoBanner = () => {
  const fetchDeploymentInfo = useAsync(async () => {
    const {result} = await MetadataService.getDeploymentInformation();
    return result;
  });

  const deploymentInfo = fetchDeploymentInfo.value;

  return (
    <div css={styles}>
      {deploymentInfo && (
        <div className="deployment-info-footer">
          <div className="deployment-info-right">
            <span>{deploymentInfo?.commitHash}</span>
          </div>
          <div className="deployment-info-left">
            <span>v{deploymentInfo?.versionNumber}</span>
            {' | '}
            <span>Last updated {deploymentInfo?.deploymentDate}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = css`
  .deployment-info-footer {
    max-height: 20px;
    font-size: 10px;
    padding: 0 10px 0 10px;
    bottom: 0;
    position: fixed;
    width: 100%;
    background: ${palette.white2};
    box-shadow: 0 -1px 1px ${palette.grey100};

    .deployment-info-right {
      float: right;
    }

    .deployment-info-left {
      float: left;
    }
  }
`;

export default DeploymentInfoBanner;
