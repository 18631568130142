import React from 'react';
import css from '@emotion/css/macro';
import {Button, ButtonProps, Label, SemanticCOLORS} from 'semantic-ui-react';
import {Omit} from 'utility-types';
import {cx} from 'emotion';
import {Flex} from '../components/flex';
import {FontAwesomeCircleWithIcon, StyledIcon} from './icons';
import {Typography} from './typography';
import {Theme} from '../theme';
import {Media} from '../styles/breakpoints';
import {useTheme} from '../theme/use-theme';
import {IconDefinition, IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

enum ButtonTypes {
  'primary' = 'primary',
  'secondary' = 'secondary',
}

export type ButtonType = 'primary' | 'secondary';
export type ButtonSizes = keyof typeof Theme.buttons.buttonSizes;

type ButtonPropsOmitted = Omit<ButtonProps, 'size' | 'color'>;

export type StyledButton = ButtonPropsOmitted & {
  size?: ButtonSizes;
  fitContent?: boolean;
  padded?: boolean;
  primary?: boolean;
  secondary?: boolean;
  buttonColor?: string;
  buttonTextColor?: string;
  buttonHoverColor?: string;
  buttonHoverTextColor?: string;
  loading?: boolean;
};

const sizeDefaultValue: ButtonSizes = 'md';

export const StyledButton: React.FC<StyledButton> = (props) => {
  const styles = useStyledButtonStyles(props);

  const {
    size,
    fitContent,
    buttonColor,
    buttonTextColor,
    buttonHoverColor,
    buttonHoverTextColor,
    children,
    className,
    primary,
    padded,
    loading,
    ...rest
  } = props;

  return (
    <span css={styles}>
      <Button
        type="button"
        className={cx(className, 'styled-button', padded ? 'padded' : '')}
        {...rest}
      >
        {loading ? <LoadingIcon /> : children}
      </Button>
    </span>
  );
};

const useStyledButtonStyles = ({
  size = sizeDefaultValue,
  fitContent,
  buttonColor,
  buttonTextColor,
  buttonHoverColor,
  buttonHoverTextColor,
  primary,
  secondary,
}: StyledButton) => {
  let type: ButtonTypes = ButtonTypes.secondary;
  if (primary) {
    type = ButtonTypes.primary;
  } else if (secondary) {
    type = ButtonTypes.secondary;
  }

  const [theme] = useTheme();

  const {buttonSizes, buttonTypes} = theme.buttons;
  const {families} = theme.fonts;

  return css`
    .styled-button {
      background-color: ${buttonColor ?? buttonTypes[type].buttonColor};
      color: ${buttonTextColor ?? buttonTypes[type].buttonTextColor};
      max-height: ${fitContent ? 'fit-content' : buttonSizes[size].height};
      max-width: ${buttonSizes[size].width} !important;
      border-radius: ${buttonSizes[size].borderRadius};
      padding: ${buttonSizes[size].padding};
      font-size: ${buttonSizes[size].fontSize};
      font-weight: ${buttonSizes[size].fontWeight};
      line-height: ${buttonSizes[size].lineHeight};
      font-size: ${buttonSizes[size].fontSize};
      font-family: ${families.hindMadurai};

      &:hover {
        background-color: ${buttonHoverColor ??
        buttonTypes[type].buttonHoverColor};
        color: ${buttonHoverTextColor ??
        buttonTypes[type].buttonHoverTextColor};
      }
    }

    .padded {
      margin-right: 14px;
    }
  `;
};

export type ListButtonType = StyledIcon & {
  hidden?: boolean;
  className?: string;
  buttonText: string | React.ReactNode;
  badgeText?: string | React.ReactNode;
  badgeColor?: SemanticCOLORS;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => void;
};

export const ListButton: React.FC<ListButtonType> = (props) => {
  return (
    <div css={ListButtonStyles}>
      {!props.hidden && (
        <Button
          className={cx('list-button', props.className)}
          onClick={props.onClick}
          fluid
        >
          <Flex.Row align={'center'}>
            <Flex.Box>
              <div className="list-button-icon">
                <FontAwesomeCircleWithIcon styledIcon={props} iconSize="lg" />
              </div>
            </Flex.Box>
            <Flex.Box className="list-button-text">{props.buttonText}</Flex.Box>
          </Flex.Row>
          {props.badgeText && (
            <Label
              className="list-button-badge"
              floating
              circular
              color={props.badgeColor ?? 'red'}
              size="small"
            >
              <Typography
                variant="small"
                color={Theme.palette.white1}
                lineHeight="100%"
              >
                {props.badgeText}
              </Typography>
            </Label>
          )}
        </Button>
      )}
    </div>
  );
};

type IconAsButton = {
  icon: IconProp;
  onClick?: () => void;
  padded?: boolean;
  color?: string;
  loadingIndex?: number;
  className?: string;
};

const paddedIconStyle = {
  margin: '0px 6px 0px 6px',
  cursor: 'pointer',
};
const iconStyle = {cursor: 'pointer'};
export const FontAwesomeIconAsButton: React.FC<IconAsButton> = ({
  icon,
  onClick,
  padded,
  color,
}) => {
  return (
    <span
      className="icon-as-button"
      style={padded ? paddedIconStyle : iconStyle}
      onClick={onClick}
    >
      <FontAwesomeIcon color={color} icon={icon} />
    </span>
  );
};

export const LoadingIcon: React.FC<Omit<IconAsButton, 'icon'>> = (props) => {
  return (
    <span
      className={props.className}
      css={loaderStyles}
      style={props.padded ? paddedIconStyle : iconStyle}
    >
      <FontAwesomeIcon
        className="spinning-loader"
        color={props.color}
        icon={faSpinner}
      />
    </span>
  );
};

type IconWithLoading = {
  icon: IconDefinition;
  isLoading: boolean;
  className: string;
};

export const IconWithLoading: React.FC<IconWithLoading> = (props) => {
  return (
    <span className={props.className} css={props.isLoading && loaderStyles}>
      <FontAwesomeIcon
        className="spinning-loader"
        icon={props.isLoading ? faSpinner : props.icon}
      />
    </span>
  );
};

const loaderStyles = css`
  .spinning-loader {
    animation: spin 0.75s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ListButtonStyles = css`
  .list-button {
    position: relative;
    background-color: hsla(0, 100%, 100%, 0);
    border: 1px solid ${Theme.palette.blue100};
    border-radius: 3px;
    color: ${Theme.palette.blue500};
    margin-bottom: 8px !important;
    height: 59px;
    padding-left: 0px;

    &:hover {
      background-color: ${Theme.palette.blue300};
      color: white;
    }

    &:focus {
      background-color: ${Theme.palette.blue200};
      color: white;
    }
  }

  .list-button-text {
    font-size: ${Theme.fonts.desktop.subheading1.size};
    font-weight: ${Theme.fonts.desktop.subheading1.weight};
    line-height: ${Theme.fonts.desktop.subheading1.lineHeight};
    font-family: ${Theme.fonts.desktop.subheading1.fontFamily};
    line-height: ${Theme.fonts.desktop.subheading1.lineHeight};
    text-align: left;

    ${Media('MobileMax')} {
      font-size: ${Theme.fonts.mobile.subheading1.size};
      font-weight: ${Theme.fonts.mobile.subheading1.weight};
      line-height: ${Theme.fonts.mobile.subheading1.lineHeight};
      font-family: ${Theme.fonts.mobile.subheading1.fontFamily};
      line-height: ${Theme.fonts.mobile.subheading1.lineHeight};
    }
  }

  .list-button-icon {
    padding: 0px 15px;
  }

  .list-button-badge {
  }
`;
