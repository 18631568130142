import {useAsync} from 'react-use';
import {
  fieldConfig,
  input,
  dropdown,
  RawFieldConfig,
} from '../forms/schema-utils';
import {
  CreateVendorUserCommand,
  VendorOptionsService,
  SuffixCode,
} from '../api/generated';
import {
  getEnumDropdownOptions,
  getOptionDtoDropdownOptions,
} from '../api/generated/utils';
import React from 'react';
import {Form} from '../forms';

type VendorUserOmitKeys = 'id' | 'vendorId' | 'isAdminEdit';
type FieldConfigDto = Omit<CreateVendorUserCommand, VendorUserOmitKeys>;

export const useVendorFields = (isUpdate: boolean) => {
  const fetchFields = useAsync(async () => {
    const {result} = await VendorOptionsService.getVendorUserOptions();
    return {
      options: result,
      fields: fieldConfig<FieldConfigDto>({
        firstName: input({
          fieldLabel: 'First Name',
          fieldRequired: true,
        }),
        middleName: input({
          fieldLabel: 'Middle Name',
        }),
        lastName: input({
          fieldLabel: 'Last Name',
          fieldRequired: true,
        }),
        suffixCode: dropdown({
          fieldLabel: 'Suffix',
          inputProps: {
            options: getEnumDropdownOptions(SuffixCode),
            placeholder: 'Select a Suffix...',
            selection: true,
            clearable: true,
            search: true,
          },
        }),
        statusCodeId: dropdown({
          fieldLabel: 'Status',
          fieldRequired: true,
          inputProps: {
            options: getOptionDtoDropdownOptions(result?.statuses),
            placeholder: 'Select a Status Code...',
            selection: true,
            search: true,
          },
        }),
        userRole: dropdown({
          fieldLabel: 'Role',
          fieldRequired: true,
          inputProps: {
            options: getOptionDtoDropdownOptions(result?.roles),
            placeholder: 'Select a Role...',
            selection: true,
            search: true,
          },
        }),
        userName: input({
          fieldLabel: 'Username',
          fieldRequired: !isUpdate,
          inputProps: {
            readOnly: isUpdate,
          },
        }),
        password: input({
          fieldLabel: 'Password',
          fieldRequired: true,
          inputProps: {
            type: 'password',
          },
        }),
        confirmPassword: input({
          fieldLabel: 'Confirm Password',
          fieldRequired: true,
          inputProps: {
            type: 'password',
          },
        }),
        email: input({
          fieldLabel: 'Email',
        }),
      }),
    };
  }, [isUpdate]);

  return fetchFields;
};

export const VendorCreateFormFields: React.FC<{
  fields: RawFieldConfig<FieldConfigDto>;
}> = ({fields}) => {
  return (
    <>
      <Form.Section title={'User Details'}>
        <Form.Row>
          <Form.Input fieldConfig={fields.firstName} />
          <Form.Input fieldConfig={fields.middleName} />
          <Form.Input fieldConfig={fields.lastName} />
          <Form.Dropdown fieldConfig={fields.suffixCode} />
        </Form.Row>
        <Form.Row>
          <Form.Dropdown fieldConfig={fields.statusCodeId} />
          <Form.Dropdown fieldConfig={fields.userRole} />
        </Form.Row>
      </Form.Section>
      <Form.Section title="Login Information">
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.userName} />
          <Form.Input fieldConfig={fields.email} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.password} />
        </Form.Row>

        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.confirmPassword} />
        </Form.Row>
      </Form.Section>
    </>
  );
};

export const VendorUpdateFormFields: React.FC<{
  fields: RawFieldConfig<FieldConfigDto>;
}> = ({fields}) => {
  return (
    <>
      <Form.Section title={'User Details'}>
        <Form.Row>
          <Form.Input fieldConfig={fields.firstName} />
          <Form.Input fieldConfig={fields.middleName} />
          <Form.Input fieldConfig={fields.lastName} />
          <Form.Dropdown fieldConfig={fields.suffixCode} />
        </Form.Row>
        <Form.Row>
          <Form.Dropdown fieldConfig={fields.statusCodeId} />
          <Form.Dropdown fieldConfig={fields.userRole} />
        </Form.Row>
      </Form.Section>
      <Form.Section title="Login Information">
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.userName} />
          <Form.Input fieldConfig={fields.email} />
        </Form.Row>
      </Form.Section>
    </>
  );
};
