import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Container, Grid, List, Loader} from 'semantic-ui-react';
import {AccountService} from '../api/generated';
import {useLoginCookie} from '../hooks/use-login-cookie';
import {routes} from '../routes/config';
import {notifications} from '../utils/notification-service';

interface OneTimeLoginLandingPageProps {
  path: string;
}

export const OneTimeLoginLandingPage: React.FC<OneTimeLoginLandingPageProps> = ({
  path,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const match = useRouteMatch<{token: string}>();
  const cookies = useLoginCookie();

  useAsync(async () => {
    setIsLoading(true);

    const response = await AccountService.customerLoginWithToken({
      body: {secureToken: match.params.token},
    });

    setIsLoading(false);
    if (response.hasErrors) {
      notifications.error(
        'This link is no longer valid. Please use one of the options below to log in.'
      );
      history.push(routes.customer.dashboard);
      return response;
    }

    cookies.setCurrentLoginCookie();
    notifications.success('Successfully logged in.');
    history.push(path);
  });

  return (
    <Grid verticalAlign="middle" columns={1}>
      <Grid.Column>
        <List horizontal>
          <Grid.Row>
            <Container text>Logging you in...</Container>
          </Grid.Row>
          <Grid.Row>
            <Loader active={isLoading}></Loader>
          </Grid.Row>
        </List>
      </Grid.Column>
    </Grid>
  );
};
