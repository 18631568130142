import React from 'react';
import {Dropdown, StrictDropdownProps} from 'semantic-ui-react';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {AnyObject} from '../../types';

export type DropdownAdapterProps = Omit<StrictDropdownProps, 'search'> & {
  enum?: AnyObject;
  search?: StrictDropdownProps['search'] | boolean;
};

const exactMatchAtTopSearch = (options, query) => {
  return options
    .filter((opt) => opt.text.toLowerCase().includes(query.toLowerCase()))
    .sort((a, b) => {
      return a.text > b.text ? 1 : -1;
    })
    .sort((a, b) => (a.text.toLowerCase() !== query.toLowerCase() ? 1 : -1));
};

export const DropdownAdapter = ({input, meta, ...rest}) => {
  const {
    options,
    enum: enumObject,
    ...dropdownProps
  } = rest as DropdownAdapterProps;
  const _options = enumObject ? getEnumDropdownOptions(enumObject) : options;

  return (
    <Dropdown
      selectOnBlur={false}
      {...input}
      {...dropdownProps}
      search={dropdownProps.search ? exactMatchAtTopSearch : false}
      options={_options}
      onChange={(event, {value}) => input.onChange(value)}
    />
  );
};
