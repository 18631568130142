import React, {createContext, useContext} from 'react';
import {Producer, useProduce} from '../hooks/use-produce';
import {Theme} from '.';

export type ThemeState = typeof Theme;

export type ThemeContext = React.Dispatch<Producer<typeof Theme>>;
export const ThemeContext = createContext<[ThemeState, ThemeContext]>([
  Theme,
  () => {},
]);

export const ThemeProvider = (props: any) => {
  const [theme, setTheme] = useProduce(Theme);

  return (
    <>
      <ThemeContext.Provider value={[theme, setTheme]} {...props} />
    </>
  );
};

export const useTheme = (): [ThemeState, ThemeContext] => {
  const [theme, setTheme] = useContext(ThemeContext);

  if (!theme) {
    throw new Error(`useTheme must be used within the ThemeProvider`);
  }

  return [theme, setTheme];
};
