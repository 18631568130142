import {Mutator} from 'final-form';

export const removeUnknownKeyFromSubmitErrorsMutator: Mutator = (
  [errors]: [Record<string, any>],
  state
) => {
  const result: Record<string, any> = {};

  for (const key in errors) {
    if (typeof errors[key] === 'object' && errors[key] !== null) {
      // If the value is an object, merge its properties into the result
      Object.assign(result, errors[key]);
    } else {
      // Otherwise, just copy the value
      result[key] = errors[key];
    }
  }

  state.formState.submitErrors = result;
};
