export enum ClassEventTypeNames {
  BoaterEdClass = 'BOATER ED CLASS',
  KalkomeyOnlineBoaterEdClass = 'KALKOMEY ONLINE BOATER ED CLASS',
  HunterEdClass = 'HUNTER ED CLASS',
  HunterEdFieldDay = 'HUNTER ED FIELD DAY',
  HunterEdInstructorClass = 'HUNTER ED INSTRUCTOR CLASS',
  OnlineHunterEdClass = 'ONLINE HUNTER ED CLASS',
  BowhunterEdClass = 'BOWHUNTER ED CLASS',
  BowhunterEdInstructorClass = 'BOWHUNTER ED INSTRUCTOR CLASS',
  AquaticClass = 'AQUATIC CLASS',
  AquaticInstructorClass = 'AQUATIC INSTRUCTOR CLASS',
  BoatUsOnlineBoaterEdClass = 'BOAT US ONLINE BOATER ED CLASS',
}
