import css from '@emotion/css/macro';
import {SkipNavLink} from '@reach/skip-nav';
import React, {useMemo} from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';
import {Button, Dropdown, Image, Menu} from 'semantic-ui-react';
import logo from '../../assets/images/ldwf-logo.svg';
import {NonProductionWarning} from '../../components/non-production-warning';
import {routes} from '../../routes/config';
import {Env} from '../../config/env-vars';
import {cx} from 'emotion';
import {AccountService, MetadataService} from '../../api/generated';
import {useSubscription} from '../../hooks/use-subscription';
import {useAsync, useAsyncRetry} from 'react-use';
import {Media} from '../../styles/breakpoints';
import {ThemeState, useTheme} from '../../theme/use-theme';
import {Theme} from '../../theme';
import {logoutAsync} from '../../utils/auth-helpers';
import {Flex} from '../../components/flex';
import DeploymentInfoBanner from '../deployment-info-banner';

type ispVendorPageWrapperProps = {
  paddingBottom?: string;
};

const externalResourcePaths = {
  disclaimer: {pathname: routes.resources.disclaimer},
  louisianaConservationist: {
    pathname: routes.resources.louisianaConservationist,
  },
  contact: {pathname: routes.resources.contact},
  huntingInfo: {pathname: routes.resources.huntingSeasonInformation},
  fishingInfo: {pathname: routes.resources.fishingSeasonInformation},
  commercialFishingInfo: {
    pathname: routes.resources.commercialfishingInformation,
  },
};

export const IspVendorPageWrapper: React.FC<ispVendorPageWrapperProps> = ({
  paddingBottom,
  children,
}) => {
  const [theme] = useTheme();

  const IspVendorTheme = {
    ...theme,
  };

  const fetchCurrentUser = useAsyncRetry(async () => {
    const {result} = await AccountService.getLoggedInUserDetails();
    return result;
  });

  const fetchDeploymentInfo = useAsync(async () => {
    const {result} = await MetadataService.getDeploymentInformation();
    return result;
  });

  const deploymentInfo = fetchDeploymentInfo.value;

  const isVendor = fetchCurrentUser.value?.vendorId != null;
  const themeStyles = getStyles(IspVendorTheme, isVendor ? '0' : paddingBottom);

  return (
    <>
      <SkipNavLink>Main Content</SkipNavLink>
      <div className={cx(`${Env.name}-environment`)}>
        <div css={styles}>
          <div css={themeStyles}>
            <Header />
            <Body>{children}</Body>
            {!isVendor ? (
              <Footer deploymentInfo={deploymentInfo} />
            ) : (
              <DeploymentInfoBanner />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Header = () => {
  const history = useHistory();

  const fetchCurrentUser = useAsyncRetry(async () => {
    const {result} = await AccountService.getLoggedInUserDetails();
    return result;
  });

  useSubscription('refresh-session', () => {
    fetchCurrentUser.retry();
  });

  const isAuthenticated = !!fetchCurrentUser.value;

  let isVendor = fetchCurrentUser.value?.vendorId;
  let homeRoute = routes.customer.root;
  if (isVendor) {
    homeRoute = routes.vendor.root;
  } else if (fetchCurrentUser?.value?.customerId) {
    homeRoute = routes.customer.dashboard;
  }

  const handleLogout = async () => {
    await logoutAsync();
    if (isVendor) {
      history.push(routes.vendor.root);
    } else {
      history.push(routes.customer.root);
    }
  };

  const vendor = useMemo(() => {
    return fetchCurrentUser.value ? fetchCurrentUser.value.vendor : '';
  }, [fetchCurrentUser.value]);

  return (
    <>
      <NonProductionWarning></NonProductionWarning>
      <div className="header-container">
        <div className="image-and-header-text-container">
          <Image className="header-logo" src={logo} as={Link} to={homeRoute} />

          <div className="header-text-container">
            <span className="header-text">
              Department of Wildlife & Fisheries
            </span>
            <span className="sub-header-text">State of Louisiana</span>
          </div>
        </div>
        <Flex.Row vAlign="center">
          {vendor && (
            <>
              <div className="vendor-header-text-container">
                <Button
                  as={Link}
                  to={routes.resources.vendorHelp.root}
                  secondary
                  content={'Help Resources'}
                />
              </div>
              <div className="vendor-header-text-container">
                <span className="vendor-sub-header-support-number">
                  Vendor Support: (225) 267-9992
                </span>
              </div>
              <div className="vendor-header-text-container">
                <span className="vendor-sub-header-text">{vendor}</span>
              </div>
            </>
          )}
          <div className="top-nav-bar">
            {isAuthenticated && (
              <Menu className="user-menu">
                <Dropdown
                  item
                  icon="user circle"
                  labeled
                  button
                  text={`${fetchCurrentUser.value?.firstName} ${fetchCurrentUser.value?.lastName}`}
                  className="icon user-menu-item"
                  selectOnNavigation={false}
                  direction="left"
                >
                  <Dropdown.Menu>
                    {isVendor && (
                      <Dropdown.Item
                        as={NavLink}
                        to={routes.vendor.changePassword}
                      >
                        Change Password
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item as="button" onClick={handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            )}
          </div>
        </Flex.Row>
      </div>
    </>
  );
};

const Body = ({children}) => {
  return (
    <>
      <div className="main-content">{children}</div>
    </>
  );
};

const Footer = ({deploymentInfo}) => {
  return (
    <>
      <div className="help-banner">
        <span>
          Support:{' '}
          <a className="support-number" href="tel:+12252679996">
            (225) 267-9996
          </a>
        </span>
      </div>

      <footer className="footer-container">
        <section className="section-1">
          <Image
            className="footer-logo"
            src={logo}
            as={Link}
            to={routes.customer.root}
          />

          <article>
            <h6>
              Louisiana Department of
              <br />
              Wildlife & Fisheries
            </h6>

            <span>P.O. Box 98000</span>
            <span>Baton Rouge, Louisiana 70898</span>
            <span>800.256.2749</span>
            <span>225.765.2800</span>
          </article>
        </section>

        <section className="section-2">
          <Link to={routes.customer.licensesAndPermits}>
            Licenses & Permits
          </Link>

          <Link to={routes.customer.validateTags}>Validate Tags</Link>

          <Link to={routes.customer.lotteryApplications}>
            Lottery Applications
          </Link>

          <Link to={routes.boatRenewal.boatRenewalLookup}>
            Boat Registration Renewal
          </Link>

          <Link to={routes.events}>Course & Event Registration</Link>
        </section>

        <section className="section-3">
          <Link to={routes.customer.dashboard}>Your Account</Link>
          <Link to={routes.customer.customerlicensesAndPermits}>
            Reprint Licenses
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={externalResourcePaths.disclaimer}
          >
            Site Disclaimer
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={externalResourcePaths.louisianaConservationist}
          >
            Louisiana Conservationist Magazine
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={externalResourcePaths.contact}
          >
            Contact the LDWF
          </Link>
        </section>

        <section className="section-4">
          <h6>LICENSING INFORMATION</h6>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={externalResourcePaths.huntingInfo}
          >
            Hunting
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={externalResourcePaths.fishingInfo}
          >
            Fishing
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={externalResourcePaths.commercialFishingInfo}
          >
            Commercial Fishing
          </Link>
        </section>
        {deploymentInfo && (
          <section className="section-1">
            <article>
              <span>v{deploymentInfo.versionNumber}</span>
              <span>{deploymentInfo.commitHash}</span>
              <span>{deploymentInfo.deploymentDate}</span>
            </article>
          </section>
        )}
      </footer>
    </>
  );
};

const getStyles = (theme: ThemeState, paddingBottom?: string) => {
  const styles = css`
    /******** Body ********/
    .main-content {
      background-color: ${theme.palette.mainContentBackgroundColor};
      min-height: ${paddingBottom ? '50vh' : '80vh'};
      padding-bottom: ${paddingBottom ?? '100px'};
    }
  `;
  return styles;
};

const styles = css`
  &.production-environment {
    border-top: solid 4px ${Theme.palette.black1};
  }

  /******** Header ********/

  .header-container {
    height: 84px;
    display: flex;
    background: ${Theme.palette.green900};
    justify-content: space-between;
  }

  .header-logo {
    height: 70px;
    width: 70px;
    margin: 7px 20px 7px 70px;
  }

  .top-nav-bar {
    background: ${Theme.palette.green900};
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .user-menu {
      background: ${Theme.palette.green900};
      border-width: 0px 1px;
      border-color: ${Theme.palette.green800};
      height: 100%;
      padding-left: 20px;

      .ui.labeled.icon.buttons .button,
      .ui.labeled.icon.button {
        position: relative;
        padding-left: 20px !important;

        &:hover {
          color: ${Theme.palette.grey1};
        }
      }
    }

    .ui.menu .active.item {
      color: ${Theme.palette.white1};
    }

    .ui.menu .icon.item > .icon {
      color: ${Theme.palette.green100};
    }

    .ui.menu .dropdown.item .menu {
      background: ${Theme.palette.green900} !important;
      color: ${Theme.palette.white1};
    }

    .ui.menu .ui.dropdown .menu > .item {
      color: ${Theme.palette.white1} !important;

      &:hover {
        color: ${Theme.palette.grey100} !important;
      }
    }

    .user-menu-item {
      padding-left: 20px !important;
      background: ${Theme.palette.green900};
      font-size: 14px;
      color: ${Theme.palette.white1};
    }
  }

  .image-and-header-text-container {
    display: flex;
  }

  .header-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-text {
      color: ${Theme.palette.white1};
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }

    .sub-header-text {
      color: ${Theme.palette.green200};
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .vendor-header-text-container {
    margin-right: 1em;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    .vendor-sub-header-support-number {
      color: ${Theme.palette.grey100};
      margin-right: 2em;
    }

    .vendor-sub-header-text {
      color: ${Theme.palette.green200};
    }
  }

  /******** Footer ********/
  .help-banner {
    position: absolute;
    left: 0;
    right: 0;
    width: 400px;
    height: 3rem;
    padding: 0 3rem;
    border-radius: 10rem;
    margin: -1.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4b5b17;
    color: #fff;
  }

  .footer-container {
    background: ${Theme.palette.grey50};
    height: 340px;
    width: 100%;
    padding: 80px;
    display: flex;
    border-top: 2px solid ${Theme.palette.grey200};

    span {
      display: block;
      font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial, Helvetica,
        sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${Theme.palette.grey700};
    }

    a {
      display: block;
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${Theme.palette.grey700};

      &:hover {
        font-weight: bold;
      }
    }

    .section-1 {
      flex: 1;
      display: flex;
      justify-content: center;

      .footer-logo {
        width: 80px;
        height: 80px;
      }

      article {
        padding-left: 29px;

        h6 {
          margin: 0px;
          font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
            Helvetica, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 135%;
          color: ${Theme.palette.grey700};
        }
      }
    }

    .section-2 {
      flex: 1;
      padding-left: 200px;
    }

    .section-3 {
      flex: 1;
      padding-left: 146px;
    }

    .section-4 {
      flex: 1;
      padding-left: 146px;

      h6 {
        margin: 0px;
        padding-bottom: 11px;
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 158.69%;
      }
    }
  }

  ${Media('WideMin')} {
    /******** Header ********/
    .header-container {
      height: 56px;
      padding-left: 50px;

      .ui.dropdown > .text {
        display: none;
      }

      .top-nav-bar .user-menu {
        margin: 0;
      }

      .header-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header-text {
          color: ${Theme.palette.white1};
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        .sub-header-text {
          color: ${Theme.palette.green200};
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 13.44px;
        }
      }

      .header-logo {
        height: 42px;
        width: 42px;
        margin: 7px 10px 7px 15px;
      }
    }

    /******** Body ********/
    .main-content {
      padding-bottom: 50px;
    }

    /******** Footer ********/
    .help-banner {
      position: inherit;
      width: 100%;
      border-radius: 0rem;
    }

    .footer-container {
      flex-direction: column;
      height: auto;
      padding: 20px 15px;
      border: none;

      span {
        line-height: 17px;
      }

      .footer-logo {
        display: none;
      }

      [class*='section-'] {
        padding: 0px;
      }

      .section-1 {
        flex-direction: column;
        padding-bottom: 20px;

        article {
          padding: 0px;
        }
      }

      .section-3 {
        padding-bottom: 20px;
      }

      .section-4 {
        h6 {
          padding-bottom: 5px;
        }
      }
    }
  }

  ${Media('TabletMax')} {
    .header-container {
      padding-left: 5px;
    }
  }

  .support-number {
    color: inherit;
    text-decoration: underline;
  }
`;
