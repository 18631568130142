import Cookies from 'js-cookie';
import React, {createContext, useState} from 'react';

export const loginCookieKey = 'last-login';

export type LoginCookieState = {
  currentCookie: string | undefined;
  setCurrentCookie: React.Dispatch<React.SetStateAction<string | undefined>>;
  previousCookie: string | undefined;
  setPreviousCookie: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const INITIAL_VALUES = {
  currentCookie: Cookies.get(loginCookieKey),
  setCurrentCookie: () => {},
  previousCookie: undefined,
  setPreviousCookie: () => {},
};

export const LoginCookieContext = createContext<LoginCookieState>(
  INITIAL_VALUES
);

let contextProviderValue: LoginCookieState;

export const LoginCookieProvider = (props: any) => {
  const [previousCookie, setPreviousCookie] = useState<string | undefined>();
  const [currentCookie, setCurrentCookie] = useState<string | undefined>(
    Cookies.get(loginCookieKey)
  );

  contextProviderValue = {
    currentCookie: currentCookie,
    setCurrentCookie: setCurrentCookie,
    previousCookie: previousCookie,
    setPreviousCookie: setPreviousCookie,
  };

  return (
    <LoginCookieContext.Provider value={contextProviderValue} {...props}>
      {props.children}
    </LoginCookieContext.Provider>
  );
};
