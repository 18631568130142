import moment from 'moment';
import {EventDateTimeDto} from '../api/generated';
import {cstNow, momentCst} from './date-time-helpers';

export const getYears = (yearsInThePast: number, yearsInTheFuture: number) =>
  Array.from({length: yearsInTheFuture + yearsInThePast})
    .map((_, idx) => cstNow().year() + yearsInTheFuture - idx)
    .reverse();

export const getAvailableDates = (
  yearsInThePast: number,
  yearsInTheFuture: number
) => {
  return (date) =>
    momentCst(date).isAfter(cstNow().add(yearsInThePast, 'years')) ||
    momentCst(date).isBefore(cstNow().subtract(yearsInTheFuture, 'years'));
};

export const getFutureDatesIncludingToday = (date) =>
  momentCst(date).isBefore(cstNow(), 'day');

type StartToEndDateStringProps = {
  beginDate: Date;
  endDate?: Date;
};

export const startToEndDateString = (props: StartToEndDateStringProps) => {
  const beginDate = momentCst(props.beginDate);
  const endDate = momentCst(props.endDate);

  const beginDateMonthString = moment.months(beginDate.month());
  const endDateMonthString = moment.months(endDate.month());

  if (
    beginDate.date() === endDate.date() &&
    beginDate.month() === endDate.month() &&
    beginDate.year() === endDate.year()
  ) {
    return `${beginDateMonthString} ${beginDate.date()}, ${endDate.year()}`;
  } else if (
    beginDate.month() === endDate.month() &&
    beginDate.year() === endDate.year()
  ) {
    return `${beginDateMonthString} ${beginDate.date()} - ${endDate.date()}, ${endDate.year()}`;
  } else if (beginDate.year() === endDate.year()) {
    return `${beginDateMonthString} ${beginDate.date()} - ${endDateMonthString} ${endDate.date()}, ${endDate.year()}`;
  } else {
    return `${beginDateMonthString} ${beginDate.date()}, ${beginDate.year()} - ${endDateMonthString} ${endDate.date()}, ${endDate.year()}`;
  }
};

export const getMonthDayYearDateString = (date?: Date) => {
  if (!date) {
    return '';
  }
  return momentCst(date).format('MMMM D, YYYY');
};

export const dateSorter = (a: Date, b: Date) => {
  return momentCst(a).unix() - momentCst(b).unix();
};

export const sortEventsByFirstStartDate = (
  a: {eventDateTimes?: EventDateTimeDto[]},
  b: {eventDateTimes?: EventDateTimeDto[]},
  options?: {
    reversed?: boolean;
  }
) => {
  a.eventDateTimes = a.eventDateTimes ?? [];
  b.eventDateTimes = b.eventDateTimes ?? [];

  if (a.eventDateTimes.length <= 0 && b.eventDateTimes.length <= 0) {
    return 0;
  } else if (a.eventDateTimes.length <= 0) {
    return options?.reversed ? 1 : -1;
  } else if (b.eventDateTimes.length <= 0) {
    return options?.reversed ? -1 : 1;
  }

  const aEventDates = a.eventDateTimes.map((x) => x.eventDate);
  const bEventDates = b.eventDateTimes.map((x) => x.eventDate);

  const aFirstClassDate = aEventDates.sort(dateSorter);
  const bFirstClassDate = bEventDates.sort(dateSorter);

  return options?.reversed
    ? +momentCst(bFirstClassDate[0]).format('yyyyMMDD') -
        +momentCst(aFirstClassDate[0]).format('yyyyMMDD')
    : +momentCst(aFirstClassDate[0]).format('yyyyMMDD') -
        +momentCst(bFirstClassDate[0]).format('yyyyMMDD');
};

export const isDateFormatValid = (date: string): boolean => {
  //Strict checking for ISO format (for customer update)
  if (moment(date, moment.ISO_8601, true).isValid()) {
    return true;
  }

  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  const dateRegexMatchList = date.match(dateFormatRegex);

  return dateRegexMatchList !== null;
};

const now = cstNow();
export const firstDayOfMonth = new Date(now.year(), now.month(), 1);
export const lastDayOfMonth = new Date(now.year(), now.month() + 1, 0);

export const getFileSafeDate = (dateString) => {
  const date = new Date(dateString);
  if (date) {
    return date.toISOString().slice(0, 10);
  }
  return '';
};

export const isValidDate = (dateString: string) =>
  momentCst(dateString).isValid();

export const isValidDateOfBirth = (dateString: string) =>
  momentCst(dateString) <= now;
