import React, {useMemo} from 'react';
import {
  CustomerEventsService,
  EmailInstructorCommand,
} from '../../api/generated';
import {Modal} from 'semantic-ui-react';
import {useAsyncFn} from 'react-use';
import {Typography} from '../../styled-components/typography';
import {StyledButton} from '../../styled-components/styled-buttons';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import arrayMutators from 'final-form-arrays';
import {notifications} from '../../utils/notification-service';
import {
  fieldConfig,
  getDefaults,
  input,
  masked,
} from '../../forms/schema-utils';
import {HtmlTextInput} from '../../components/html-input';
import {Producer} from '../../hooks/use-produce';
import {css} from '@emotion/core';
import {Media} from '../../styles/breakpoints';

const subscriptions = {submitting: true, pristine: true};
const mutators = {
  ...arrayMutators,
};

type EmailInstructorFormProps = Omit<
  EmailInstructorCommand,
  'instructorId' | 'eventId'
>;

const useEmailInstructorFields = () => {
  return fieldConfig<EmailInstructorFormProps>({
    fromEmail: input({
      fieldLabel: 'Your Email',
      fieldRequired: true,
    }),
    fromName: input({
      fieldLabel: 'Your Name',
    }),
    fromPhoneNumber: masked({
      fieldLabel: 'Your Phone Number',
      inputProps: {
        options: {
          phone: true,
          phoneRegionCode: 'US',
          delimiter: '-',
        },
      },
    }),
    emailBody: input({
      fieldLabel: 'Body',
      fieldRequired: true,
    }),
  });
};

const modalStyles = {maxWidth: '800px', minHeight: '500px'};

export type EmailInstructorModalStateProps = {
  isOpen: boolean;
  instructorId: number;
  eventId: number;
};

type EmailInstructorModalProps = {
  modalState: EmailInstructorModalStateProps;
  setModalState: (mutationFn: Producer<EmailInstructorModalStateProps>) => void;
};

export const EmailInstructorModal: React.FC<EmailInstructorModalProps> = ({
  modalState,
  setModalState,
}) => {
  const fields = useEmailInstructorFields();

  const [, onSubmit] = useAsyncFn(
    async (values) => {
      values.instructorId = modalState.instructorId;
      values.eventId = modalState.eventId;

      let response = await CustomerEventsService.emailInstructorOnEvent({
        body: values,
      });

      if (response.hasErrors) {
        response.validationFailures?.forEach((failure) => {
          notifications.error(failure.errorMessage);
        });
        return false;
      }

      notifications.success('Email successfully sent.');
      setModalState((draft) => (draft.isOpen = false));
      return response;
    },
    [modalState.eventId, modalState.instructorId, setModalState]
  );

  const handleClose = () => {
    setModalState((draft) => {
      draft.isOpen = false;
    });
  };

  const initialValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  return (
    <div css={styles}>
      <Modal
        open={modalState.isOpen}
        onClose={handleClose}
        size="fullscreen"
        style={modalStyles}
      >
        <Modal.Header className="modal-header-no-border">
          <Typography variant="heading2">Email Instructor</Typography>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <Form
            className="ui form mini"
            initialValues={initialValues}
            mutators={mutators}
            subscription={subscriptions}
            onSubmit={onSubmit}
            render={({form: {change}}) => (
              <>
                <div className="form-fields-email-link-modal">
                  <Flex.Box className="input-line">
                    <Form.Input fieldConfig={fields.fromEmail} />
                  </Flex.Box>
                  <Flex.Box className="input-line">
                    <Form.Input fieldConfig={fields.fromName} />
                  </Flex.Box>
                  <Flex.Box className="input-line">
                    <Form.InputMasked fieldConfig={fields.fromPhoneNumber} />
                  </Flex.Box>
                  <HtmlTextInput
                    fieldConfig={fields.emailBody}
                    onChange={(content: string) => {
                      change('emailBody', content);
                    }}
                    height="225px"
                    enableMobileToolbar={true}
                  />
                </div>
                <div>
                  <Modal.Actions className="modal-actions">
                    <Flex.Row justifyContent="flex-end" vAlign="center">
                      <Flex.Box>
                        <StyledButton padded onClick={handleClose}>
                          Close
                        </StyledButton>
                        <StyledButton primary padded type="submit">
                          Send
                        </StyledButton>
                      </Flex.Box>
                    </Flex.Row>
                  </Modal.Actions>
                </div>
              </>
            )}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const styles = css`
  .modal-header-no-border {
    border-bottom: 0px !important;
    padding-bottom: 5px !important;
  }

  .modal-actions {
    background: none !important;
  }

  .modal-content {
    min-height: 400px !important;
  }

  .form-fields-email-link-modal {
    height: 370px;
    margin-bottom: 16px;
  }

  .input-line {
    width: 50%;
  }

  .html-editor {
    min-height: 150px;
  }

  ${Media('MobileMax')} {
    .html-editor {
      min-height: 200px !important;
    }

    .ql-toolbar {
      max-height: 120px !important;
    }
  }
`;
