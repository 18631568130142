import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React from 'react';

type ClickableIconType = FontAwesomeIconProps & {
  padded?: boolean;
};

const clickableStyles = {
  cursor: 'pointer',
};

const paddedClickableStyles = {
  marginLeft: '5px',
  cursor: 'pointer',
};

export const ClickableIcon: React.FC<ClickableIconType> = (props) => {
  return (
    <FontAwesomeIcon
      style={props.padded ? paddedClickableStyles : clickableStyles}
      {...props}
    />
  );
};
