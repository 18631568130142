import React from 'react';
import {additionalInformationTextStyles} from '../styles';

export const ProductRenewalPrice: React.FC<{
  price: number;
  convenienceFee: number | undefined;
}> = ({price, convenienceFee}) => {
  return (
    <>
      {price === 0 ? (
        <p style={additionalInformationTextStyles}>{'Product Fee: FREE'}</p>
      ) : (
        <p style={additionalInformationTextStyles}>
          {'Product Fee: '}${price.toFixed(2)}
        </p>
      )}
    </>
  );
};
