import React from 'react';
import {FieldRenderProps, useField} from 'react-final-form';
import {Form} from 'semantic-ui-react';

type FieldCustomValue = {
  fieldName: string;
  fieldLabel?: string;
  render: (
    field: FieldRenderProps<any, HTMLElement>
  ) => JSX.Element | false | undefined;
};

export const FieldCustomValue = ({
  fieldName,
  fieldLabel,
  render,
}: FieldCustomValue) => {
  const field = useField(fieldName);
  return (
    <div className="form-field">
      <Form.Field>
        {fieldLabel ? (
          <label>{fieldLabel}</label>
        ) : (
          <span className="no-label" />
        )}
        {render(field)}
      </Form.Field>
    </div>
  );
};
