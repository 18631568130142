import {useState} from 'react';
import {useDebounce} from 'react-use';

export function useDebouncedState<T>(defaultValue: T, delay) {
  const [val, setVal] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  useDebounce(
    () => {
      setDebouncedValue(val);
    },
    delay,
    [val]
  );

  return [debouncedValue, setVal] as [T, (newValue: T) => void];
}
