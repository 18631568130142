import palette from './constants/palette';
import fonts from './constants/fonts';
import {buttons} from './constants/buttons';

export const Theme = {
  palette: {
    ...palette,
    mainContentBackgroundColor: palette.white1,
    basicBoxShadow: 'rgba(34, 36, 38, 0.15)',
    textLabel: palette.black2,
    transparentHover: '#efefef',
    warning: '#712928',
    warningBackground: '#fff6f6',
    warningBoxShadow: '#9F3A38',
    errorText: '#9F3A38',
    mutedText: palette.grey1,
    warningText: '#7b341e',
    primaryColor: palette.green1,
    primaryAccent: palette.green1,
    navBarBorder: palette.brown1,
  },
  fonts,
  buttons,
};
