import {saveAs} from 'file-saver';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {
  useAsync,
  useAsyncFn,
  useAsyncRetry,
  useLocation,
  useMedia,
} from 'react-use';
import {Accordion, Dropdown, Grid, Label, Popup} from 'semantic-ui-react';
import css from '@emotion/css/macro';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
  faFileDownload,
  faFileInvoiceDollar,
} from '@fortawesome/pro-light-svg-icons';
import {faShippingFast} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {adminRoutes} from '../admin/routes/config';
import {
  CustomerOptionsService,
  CustomerPortalCustomersService,
  CustomersService,
  TransactionCustomerProductListItemDto,
  TransactionCustomerProductListItemDtoListApiResult,
  TransactionCustomerService,
  TransactionsForCustomerService,
} from '../api/generated';
import {TransactionStatus} from '../api/generated/enums';
import {DropdownOption} from '../api/generated/utils';
import {useRequireAuthorization} from '../auth/require-authorization';
import {useUser} from '../auth/use-auth';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DateFormat} from '../components/date';
import {Flex} from '../components/flex';
import {Tooltip} from '../components/tooltip';
import {buildPath} from '../routes/config';
import {UpdateMailingAddressModal} from '../shared/mailing-address/update-mailing-address-modal';
import {ProductConstantCode} from '../shared/product-catalog/product-catalog-utils';
import {StyledButton} from '../styled-components/styled-buttons';
import {Typography} from '../styled-components/typography';
import {StyledPageContainer} from '../styled-page-container';
import {Media, MediaSizes} from '../styles/breakpoints';
import {Theme} from '../theme';
import {cstNow} from '../utils/date-time-helpers';
import {notifications} from '../utils/notification-service';
import {formatTransactionStatus} from '../utils/transaction-status-helpers';
import {getFormattedMailingAddress} from '../shared/mailing-address/mailing-address-helpers';

type SortedProductsByYear = {
  [key: number]: TransactionCustomerProductListItemDto[];
};

enum ExportActions {
  LicenseHistoryReport = 'LisenseHistoryReport',
  SingleLicenseExport = 'SingleLicenseExport',
  TransactionExport = 'TransactionExport',
}

export const LicenseListing: React.FC<{
  isAdmin?: boolean;
}> = ({isAdmin}) => {
  const user = useUser();
  const match = useRouteMatch<{id: string}>();
  const customerId = Number(match.params.id);
  const activeLicenseSeason = 0;
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  const [selectedSeason, setSelectedSeason] = useState(activeLicenseSeason);
  const [seasonYearOptions, setSeasonYearOptions] = useState<number[]>([]);

  const fetchInformation = useAsyncRetry(async () => {
    const optionsResponse = await CustomerOptionsService.getProductOptions();
    var transactionsResponse = {} as TransactionCustomerProductListItemDtoListApiResult;

    if (isAdmin) {
      transactionsResponse = await TransactionsForCustomerService.getAllForCustomer(
        {
          customerId: customerId,
        }
      );
    } else if (user.customerId) {
      transactionsResponse = await TransactionCustomerService.getAllForCustomer(
        {
          customerId: user.customerId,
        }
      );
    }

    if (transactionsResponse.hasErrors) {
      notifications.error('Failed to fetch Licenses.');
      return;
    }

    if (optionsResponse.hasErrors) {
      notifications.error('Failed to fetch Options.');
      return;
    }

    const licensesResult = transactionsResponse.result;
    const currentLicenseYear = optionsResponse.result?.defaultLicenseYear ?? 0;

    const seasonYears = licensesResult?.map((x) => {
      if (
        !x.productIsLifetime ||
        (x.productIsLifetime &&
          x.transactionStatus !== TransactionStatus.ACTIVE)
      ) {
        return x.productLicenseYear ?? 0;
      }
      return currentLicenseYear;
    });
    const uniqueYears = _.uniq(seasonYears);
    const nonActiveYears = uniqueYears.filter(
      (year) => year !== currentLicenseYear
    );
    const orderedYears = nonActiveYears.sort((a, b) => {
      return b - a;
    });
    setSeasonYearOptions(orderedYears);

    return {licenses: licensesResult, options: optionsResponse.result};
  }, [customerId, isAdmin, user.customerId]);

  const fetchCustomer = useAsync(async () => {
    if (isAdmin) {
      const {result} = await CustomersService.getById({
        id: isAdmin ? Number(customerId) : Number(user.customerId),
      });

      return result;
    }
  }, [customerId, user.customerId, isAdmin]);

  const customer = fetchCustomer.value;

  const currentLicenseYear = useMemo(() => {
    return (
      fetchInformation.value?.options?.defaultLicenseYear ?? cstNow().year()
    );
  }, [fetchInformation.value]);

  const licenses = useMemo(() => {
    const products = fetchInformation?.value?.licenses ?? [];
    let sortedProductsByYear: SortedProductsByYear = [];

    products.forEach((product) => {
      //active licenses are grouped under activeLicenseSeason key (0)
      if (product.transactionStatus === TransactionStatus.ACTIVE) {
        if (sortedProductsByYear[activeLicenseSeason]) {
          sortedProductsByYear[activeLicenseSeason].push(product);
        } else {
          sortedProductsByYear = {
            ...sortedProductsByYear,
            [activeLicenseSeason]: [product],
          };
        }
      }

      //licenses are also grouped under their license year key
      if (
        product.productIsLifetime &&
        product.transactionStatus === TransactionStatus.ACTIVE &&
        sortedProductsByYear[currentLicenseYear]
      ) {
        sortedProductsByYear[currentLicenseYear].push(product);
      } else if (
        sortedProductsByYear[product.productLicenseYear ?? currentLicenseYear]
      ) {
        sortedProductsByYear[
          product.productLicenseYear ?? currentLicenseYear
        ].push(product);
      } else if (
        product.productIsLifetime &&
        product.transactionStatus === TransactionStatus.ACTIVE
      ) {
        sortedProductsByYear = {
          ...sortedProductsByYear,
          [currentLicenseYear]: [product],
        };
      } else {
        sortedProductsByYear = {
          ...sortedProductsByYear,
          [product.productLicenseYear ?? currentLicenseYear]: [product],
        };
      }
    });

    return sortedProductsByYear;
  }, [fetchInformation, currentLicenseYear]);

  const seasonDropdownOptions = useMemo(() => {
    const activeOption = {text: 'Show Active', value: activeLicenseSeason};
    const currentYearOption = {
      text: currentLicenseYear.toString(),
      value: currentLicenseYear,
    };
    const yearOptions = seasonYearOptions.map((year) => {
      return {text: `${year}`, value: year};
    });
    return [activeOption, currentYearOption, ...yearOptions];
  }, [currentLicenseYear, seasonYearOptions]);

  const licensePageContent = (
    <AsyncStateContainer {...fetchInformation}>
      {fetchInformation.value && (
        <>
          <Flex.Row justifyContent="flex-end">
            <ExportLicenseButton
              tooltipText="Download License History"
              label="License History Report"
              actionType={ExportActions.LicenseHistoryReport}
              id={isAdmin ? customerId : user.customerId ?? 0}
              fileName={'License History Report'}
            />
          </Flex.Row>
          <Grid>
            <Grid.Row>
              <Grid.Column verticalAlign="bottom" computer={16} mobile={16}>
                <Flex.Row justifyContent="space-between" vAlign="bottom">
                  <Flex.Col>
                    {isAdmin ? (
                      <Typography
                        variant="heading2"
                        color={Theme.palette.blue700}
                      >
                        Active Licenses & Permits
                      </Typography>
                    ) : (
                      <Typography
                        variant="heading2"
                        color={Theme.palette.blue700}
                      >
                        Your Active Licenses & Permits
                      </Typography>
                    )}
                  </Flex.Col>
                  {!isMobile && (
                    <Flex.Col>
                      {seasonDropdownOptions && (
                        <SeasonDropdown
                          defaultSeason={activeLicenseSeason}
                          setSelectedSeason={setSelectedSeason}
                          seasonDropdownOptions={seasonDropdownOptions}
                        />
                      )}
                    </Flex.Col>
                  )}
                </Flex.Row>
                {isMobile && (
                  <Flex.Row>
                    {seasonDropdownOptions && (
                      <SeasonDropdown
                        defaultSeason={activeLicenseSeason}
                        setSelectedSeason={setSelectedSeason}
                        seasonDropdownOptions={seasonDropdownOptions}
                      />
                    )}
                  </Flex.Row>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row mobile={16}>
              <Grid.Column>
                <Typography variant="subheading1">
                  All active licenses are listed below. If you want to filter
                  them based on license year, please use the License Year filter
                  above.
                </Typography>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row mobile={16}>
              <Grid.Column>
                <Accordion styled fluid>
                  {licenses &&
                    currentLicenseYear &&
                    licenses[selectedSeason]
                      ?.filter(
                        isAdmin
                          ? (product) => !product.productIsOnlineCourse
                          : (product) =>
                              !product.productIsOnlineCourse &&
                              product.productCode !==
                                ProductConstantCode.duplicate
                      )
                      .map((product) => {
                        if (
                          selectedSeason !==
                            fetchInformation.value?.options
                              ?.defaultLicenseYear &&
                          !isAdmin &&
                          (product.transactionStatus ===
                            TransactionStatus.VOID ||
                            product.transactionStatus ===
                              TransactionStatus['LDWF VOID'])
                        ) {
                          return;
                        }
                        return (
                          <AccordianTitleRow
                            product={product}
                            customerId={customer?.id}
                            isAdmin={isAdmin}
                            key={product.id}
                            refetchLicenseInformation={fetchInformation.retry}
                          />
                        );
                      })}
                </Accordion>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </AsyncStateContainer>
  );

  const breadcrumbs = [
    {title: 'Customers', url: adminRoutes.customers.root},
    {
      title: 'Dashboard',
      url: buildPath(adminRoutes.customers.dashboard, {
        id: Number(customerId),
      }),
    },
    {title: 'Licenses & Permits'},
  ];

  return (
    <>
      {isAdmin ? (
        <BasicPage title={breadcrumbs} css={styles}>
          {licensePageContent}
        </BasicPage>
      ) : (
        <StyledPageContainer
          title="Licenses & Permits"
          subtitle={'Reprint or download your products.'}
          css={styles}
        >
          {licensePageContent}
        </StyledPageContainer>
      )}
    </>
  );
};

type SeasonDropdownProps = {
  setSelectedSeason: (number) => void;
  defaultSeason: number;
  seasonDropdownOptions: DropdownOption[];
};

const SeasonDropdown: React.FC<SeasonDropdownProps> = ({
  setSelectedSeason,
  defaultSeason,
  seasonDropdownOptions,
}) => {
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <>
      <Flex.Row vAlign="center">
        {!isMobile && (
          <Flex.Col>
            <Typography className="season-dropdown-label" variant="labelLarge">
              License Year
            </Typography>
          </Flex.Col>
        )}
        <Flex.Col>
          <Dropdown
            className="season-dropdown"
            compact
            selection
            defaultValue={defaultSeason}
            options={seasonDropdownOptions}
            onChange={(_, data) => {
              setSelectedSeason(data.value);
            }}
          />
        </Flex.Col>
      </Flex.Row>
    </>
  );
};

const AccordianTitleRow: React.FC<{
  product: TransactionCustomerProductListItemDto;
  customerId?: number;
  isAdmin?: boolean;
  refetchLicenseInformation: () => void;
}> = ({product, customerId, isAdmin, refetchLicenseInformation}) => {
  const history = useHistory();
  const {pathname} = useLocation();

  const hasLicenseViewLicenseDetailsClaim = useRequireAuthorization({
    claims: ['License - View License Details'],
  });

  const hasLicenseUpdateLicenseStatusClaim = useRequireAuthorization({
    claims: ['License - Update License Status'],
  });

  const mailingAddressDto = product.productFulfillmentMailingAddress;
  const fullAddress =
    mailingAddressDto !== null && mailingAddressDto !== undefined
      ? getFormattedMailingAddress(mailingAddressDto)
      : '';

  return (
    <Accordion.Title className="accordion-title">
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column mobile={8} verticalAlign="top">
            <Typography
              variant="heading3"
              color={Theme.palette.blue700}
              onClick={() => {
                if (hasLicenseViewLicenseDetailsClaim) {
                  history.push(
                    buildPath(adminRoutes.customers.licenseDetail, {
                      id: Number(customerId),
                      transactionCustomerDetailId: product.id,
                    }),
                    {
                      from: pathname,
                      prevRoute: adminRoutes.customers.allLicenses,
                    }
                  );
                }
              }}
            >
              {product.replacedProductName ? (
                <>
                  <Label basic horizontal>
                    {product.productName}
                  </Label>
                  {`${product.replacedProductName}`}
                </>
              ) : (
                <>{product.productName}</>
              )}
              {product.isAutoRenew && (
                <Label basic horizontal>
                  AUTO-RENEW
                </Label>
              )}
              {product.isEarlyRenewal && (
                <Label basic horizontal>
                  EARLY RENEWAL
                </Label>
              )}
              {(product.transactionStatus === TransactionStatus.VOID ||
                product.transactionStatus === TransactionStatus['LDWF VOID']) &&
                isAdmin && (
                  <Label basic horizontal color="red">
                    {formatTransactionStatus(product.transactionStatus)}
                  </Label>
                )}
            </Typography>
            <div>
              <Typography variant="small">
                Valid from <DateFormat date={product.licenseValidStartDate} /> -{' '}
                <DateFormat date={product.licenseValidEndDate} />
              </Typography>
            </div>
            <div>
              <Typography variant="small">Transaction number: </Typography>
              {isAdmin ? (
                <Typography
                  variant="small"
                  as={Link}
                  to={buildPath(adminRoutes.transactions.detail, {
                    id: Number(product.transactionId),
                  })}
                >
                  {product.transactionNumber}
                </Typography>
              ) : (
                <Typography variant="small">
                  {product.transactionNumber}
                </Typography>
              )}
            </div>
            <div>
              <Typography variant="small">
                Transaction date:{' '}
                <DateFormat
                  date={product.transactionDate}
                  format={'MM/DD/YY hh:mm A'}
                />
              </Typography>
            </div>
            {(product.hasProcessedTransactionFulfillment ||
              product.hasUnProcessedTransactionFulfillment) && (
              <div>
                <Typography variant="small">
                  Mailing Address: {fullAddress}
                </Typography>
              </div>
            )}
          </Grid.Column>
          <Grid.Column mobile={8} textAlign="right" verticalAlign="top">
            <>
              <ExportLicenseButton
                tooltipText="Download Transaction"
                actionType={ExportActions.TransactionExport}
                icon={faFileInvoiceDollar}
                id={product.transactionId}
                isDisabled={
                  product.transactionStatus !== TransactionStatus.ACTIVE
                }
                fileName={'Transaction Export'}
              />
              <ExportLicenseButton
                tooltipText="Download License"
                actionType={ExportActions.SingleLicenseExport}
                icon={faFileDownload}
                id={
                  product.replacedTransactionCustomerProductId &&
                  product.replacedTransactionCustomerProductId !== 0
                    ? product.replacedTransactionCustomerProductId
                    : product.id
                }
                isDisabled={
                  product.transactionStatus !== TransactionStatus.ACTIVE
                }
                fileName={'Customer Licenses'}
              />
              {isAdmin ? (
                hasLicenseUpdateLicenseStatusClaim && (
                  <MailingAddressButton
                    product={product}
                    refetchLicenseInformation={refetchLicenseInformation}
                  />
                )
              ) : (
                <MailingAddressButton
                  product={product}
                  refetchLicenseInformation={refetchLicenseInformation}
                />
              )}
            </>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Accordion.Title>
  );
};

const ExportLicenseButton = ({
  id,
  icon,
  label,
  fileName,
  isDisabled,
  tooltipText,
  actionType,
}: {
  id: number;
  icon?: IconProp;
  label?: string;
  fileName?: string;
  isDisabled?: boolean;
  tooltipText?: string;
  actionType?: ExportActions;
}) => {
  const [useGetExportState, getExport] = useAsyncFn(async () => {
    let blob = undefined;

    switch (actionType) {
      case ExportActions.LicenseHistoryReport:
        blob = await CustomerPortalCustomersService.exportAllCustomerLicensePdfFile(
          {
            customerId: id,
          },
          {responseType: 'blob'}
        );
        break;
      case ExportActions.SingleLicenseExport:
        blob = await CustomerPortalCustomersService.exportTransactionCustomerLicensePdfFile(
          {
            body: {
              transactionCustomerProductIds: [id],
              viewInNewTab: true,
            },
          },
          {
            responseType: 'blob',
          }
        );
        break;
      case ExportActions.TransactionExport:
        blob = await TransactionCustomerService.exportTransactionCustomerLicensePdfFile(
          {
            body: {transactionId: id, viewInNewTab: false},
          },
          {responseType: 'blob'}
        );
        break;
    }

    saveAs(blob, fileName);
  });

  return (
    <Tooltip
      label={isDisabled ? 'Cannot Download Expired License' : tooltipText}
    >
      <StyledButton
        className="export-button"
        onClick={() => {
          if (!useGetExportState.loading) {
            getExport();
          }
        }}
        loading={useGetExportState.loading}
        disabled={isDisabled}
      >
        <FontAwesomeIcon icon={icon as IconProp} fixedWidth /> {label}
      </StyledButton>
    </Tooltip>
  );
};

const MailingAddressButton = ({
  product,
  refetchLicenseInformation,
}: {
  product: TransactionCustomerProductListItemDto;
  refetchLicenseInformation: () => void;
}) => {
  const [
    isMailingAddressModalOpen,
    setIsMailingAddressModalOpen,
  ] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsMailingAddressModalOpen(true);
  };

  const handleOnClose = () => {
    setIsMailingAddressModalOpen(false);
  };

  const updateMailingAddressProductDto = useMemo(() => {
    return {
      transactionId: product.transactionId,
      transactionCustomerProductId: product.id,
      productName: product.productName || '',
    };
  }, [product.transactionId, product.id, product.productName]);

  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <>
      {(product.hasUnProcessedTransactionFulfillment ||
        product.hasProcessedTransactionFulfillment) && (
        <>
          {isMobile ? (
            <>
              {product.hasUnProcessedTransactionFulfillment && (
                <StyledButton
                  primary
                  className="export-button"
                  onClick={handleOnClick}
                >
                  <FontAwesomeIcon icon={faShippingFast} />
                </StyledButton>
              )}
              {product.hasProcessedTransactionFulfillment && (
                <Popup
                  on="click"
                  trigger={
                    <StyledButton primary className="disabled-export-button">
                      <FontAwesomeIcon icon={faShippingFast} />
                    </StyledButton>
                  }
                  position="bottom right"
                  wide="very"
                >
                  Cannot Update Processed Transaction's Mailing Address
                </Popup>
              )}
            </>
          ) : (
            <Tooltip
              label={
                product.hasProcessedTransactionFulfillment
                  ? `Cannot Update Processed Transaction's Mailing Address`
                  : 'Update Mailing Address for Transaction'
              }
            >
              <StyledButton
                primary
                disabled={product.hasProcessedTransactionFulfillment}
                className="export-button"
                onClick={handleOnClick}
              >
                <FontAwesomeIcon icon={faShippingFast} />
              </StyledButton>
            </Tooltip>
          )}
          <UpdateMailingAddressModal
            initialValues={product.productFulfillmentMailingAddress || null}
            product={updateMailingAddressProductDto}
            isModalOpen={isMailingAddressModalOpen}
            closeModal={handleOnClose}
            refetchLicenseInformation={refetchLicenseInformation}
          />
        </>
      )}
    </>
  );
};

const styles = css`
  .ui.grid > .row {
    padding: 1em 0.5em !important;
  }

  .accordion-title {
    padding: 1em 0em !important;
    cursor: pointer !important;
  }

  .export-button {
    margin: 0 0 10px 10px;
  }

  .disabled-export-button {
    margin: 0 0 10px 10px;
    opacity: 0.45;
  }

  .season-dropdown-label {
    margin-right: 5px;
  }

  .season-dropdown {
    ${Media('MobileMax')} {
      margin-top: 15px;
    }
  }
`;
