import React from 'react';
import {Modal} from 'semantic-ui-react';
import {StyledButton} from '../../../styled-components/styled-buttons';
import {routes} from '../../../routes/config';
import {OrganDonorInfo} from '../../../shared/organ-donor/organ-donor-info';

export const OrganDonorModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={closeModal} size="tiny">
      <OrganDonorInfo
        headerWrapper={Modal.Header}
        contentWrapper={Modal.Content}
      />
      <Modal.Actions>
        <StyledButton onClick={closeModal} content="Cancel" />
        <StyledButton
          as="a"
          href={routes.resources.organDonorApplication}
          primary
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer noopener"
          content="Continue"
        />
      </Modal.Actions>
    </Modal>
  );
};
