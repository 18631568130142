import {css} from '@emotion/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {getEnumDropdownOptions} from '../api/generated/utils';
import {Flex} from '../components/flex';
import {Form} from '../forms';
import {CustomerVerification} from '../internet-sales-portal/customers/customer-verification';
import {IspVendorPageWrapper} from '../shared/wrappers/isp-vendor-page-wrapper';
import {StyledButton} from '../styled-components/styled-buttons';
import {StyledPageContainer} from '../styled-page-container';
import {ValidationError} from '../types/index';
import {notifications} from '../utils/notification-service';
import {
  AccountService,
  CustomerAuthWithAlternateIdDto,
  CustomerAuthWithCustomerNumberDto,
  CustomerAuthWithDriversLicenseDto,
  CustomerAuthWithSsnDto,
  UserDto,
} from '../api/generated';
import {dropdown, fieldConfig, input, masked} from '../forms/schema-utils';
import {
  Divider,
  Message,
  Dropdown,
  Input,
  Select,
  Modal,
} from 'semantic-ui-react';
import _ from 'lodash';
import {CountryCode, StateCode} from '../api/generated/enums';
import {
  logoutAsync,
  unVerifyCustomer,
  verifyCustomer,
} from '../utils/auth-helpers';
import {CustomerCreateComponent} from '../internet-sales-portal/customers/customer-create-update/customer-create-update-login-component';
import {CustomerUpdateComponent} from '../internet-sales-portal/customers/customer-create-update/customer-create-update-login-component';
import {Media} from '../styles/breakpoints';
import {forceCstOffsetAndStartOfDay} from '../utils/date-time-helpers';
import {notify} from '../hooks/use-subscription';
import {Typography} from '../styled-components/typography';
import {isDateFormatValid} from '../utils/date-helpers';
import {useLoginCookie} from '../hooks/use-login-cookie';

const ldwfLoginKey = 'LDWF #';
const driversLicenseLoginKey = "Driver's License/ID Card #";
const ssnLoginKey = 'Social Security #';
const alternateIdLoginKey = 'Alternate ID #';

const loginOptions = [
  {
    key: ssnLoginKey,
    text: ssnLoginKey,
    value: ssnLoginKey,
  },
  {
    key: ldwfLoginKey,
    text: ldwfLoginKey,
    value: ldwfLoginKey,
  },
  {
    key: driversLicenseLoginKey,
    text: driversLicenseLoginKey,
    value: driversLicenseLoginKey,
  },
  {
    key: alternateIdLoginKey,
    text: alternateIdLoginKey,
    value: alternateIdLoginKey,
  },
];

const dateOfBirthFieldForLogin = masked({
  fieldLabel: 'Date of Birth',
  inputProps: {
    placeholder: 'MM/DD/YYYY',
    type: 'tel',
    options: {
      numericOnly: true,
      blocks: [2, 2, 4],
      delimiter: '/',
    },
  },
});

const ldwfLoginFieldConfig = fieldConfig<CustomerAuthWithCustomerNumberDto>({
  sportsmanId: masked({
    fieldLabel: 'LDWF #',
    inputProps: {
      type: 'tel',
      options: {
        numericOnly: true,
      },
    },
  }),
  dateOfBirth: dateOfBirthFieldForLogin,
});
const LdwfLoginFields = () => (
  <>
    <Form.InputMasked fieldConfig={ldwfLoginFieldConfig.sportsmanId} />
    <Form.InputMasked fieldConfig={ldwfLoginFieldConfig.dateOfBirth} />
  </>
);

const driversLicenseLoginFieldConfig = fieldConfig<CustomerAuthWithDriversLicenseDto>(
  {
    number: input({
      fieldLabel: `Driver's License or ID Card #`,
    }),
    state: dropdown({
      fieldLabel: `Driver's License or ID State`,
      inputProps: {
        placeholder: 'Select a State...',
        options: getEnumDropdownOptions(StateCode),
        selection: true,
        search: true,
        defaultValue: StateCode.LOUISIANA,
      },
    }),
    dateOfBirth: dateOfBirthFieldForLogin,
  }
);
const DriversLicenseLoginFields = () => (
  <>
    <Form.Dropdown fieldConfig={driversLicenseLoginFieldConfig.state} />
    <Form.Input fieldConfig={driversLicenseLoginFieldConfig.number} />
    <Form.InputMasked
      fieldConfig={driversLicenseLoginFieldConfig.dateOfBirth}
    />
  </>
);

const ssnLoginFieldConfig = fieldConfig<CustomerAuthWithSsnDto>({
  ssnLast4: masked({
    fieldLabel: 'Last 4 digits of SSN',
    inputProps: {
      options: {
        blocks: [4],
        numericOnly: true,
      },
    },
  }),
  lastName: input({
    fieldLabel: 'Last Name',
  }),
  dateOfBirth: dateOfBirthFieldForLogin,
});
const SsnLoginFields = () => (
  <>
    <Form.InputMasked fieldConfig={ssnLoginFieldConfig.ssnLast4} />
    <Form.Input fieldConfig={ssnLoginFieldConfig.lastName} />
    <Form.InputMasked fieldConfig={ssnLoginFieldConfig.dateOfBirth} />
  </>
);

const alternateIdLoginFieldConfig = fieldConfig<CustomerAuthWithAlternateIdDto>(
  {
    id: input({
      fieldLabel: 'Alternate ID #',
    }),
    country: dropdown({
      fieldLabel: 'Country of Issue',
      inputProps: {
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        search: true,
        clearable: true,
        selection: true,
      },
    }),
    dateOfBirth: dateOfBirthFieldForLogin,
  }
);
const AlternateIdLoginFields = () => (
  <>
    <Form.Input fieldConfig={alternateIdLoginFieldConfig.id} />
    <Form.Dropdown fieldConfig={alternateIdLoginFieldConfig.country} />
    <Form.InputMasked fieldConfig={alternateIdLoginFieldConfig.dateOfBirth} />
  </>
);

type CustomerLogin = {
  user?: UserDto | null;
  isMobileUser?: boolean | undefined;
};

type Step = 'login' | 'verification' | 'create' | 'update';

export const CustomerLogin: React.FC<CustomerLogin> = ({
  user,
  isMobileUser,
}) => {
  const [loginType, setLoginType] = useState<any>(ssnLoginKey);
  const [loginErrors, setLoginErrors] = useState<ValidationError[]>();
  const [isStepper, setIsStepper] = useState(false);
  const [loginStep, setLoginStep] = useState<Step>(
    !user ? 'login' : 'verification'
  );
  const [customerId, setCustomerId] = useState<Number>(user?.customerId ?? 0);
  const cookies = useLoginCookie();

  useEffect(() => {
    if (isMobileUser) {
      setLoginStep('create');
    }
  }, [isMobileUser]);

  const getFormFields = () => {
    switch (loginType) {
      case ldwfLoginKey:
        return <LdwfLoginFields />;
      case driversLicenseLoginKey:
        return <DriversLicenseLoginFields />;
      case ssnLoginKey:
        return <SsnLoginFields />;
      case alternateIdLoginKey:
        return <AlternateIdLoginFields />;
      default:
        return <></>;
    }
  };

  const onSubmitVerification = useCallback(() => {
    verifyCustomer();
  }, []);

  const onSubmit = async (values) => {
    const valuesToSubmit = _.cloneDeep(values);

    const dateOfBirthToSubmit = valuesToSubmit.dateOfBirth;
    valuesToSubmit.dateOfBirth = forceCstOffsetAndStartOfDay(
      dateOfBirthToSubmit
    );

    if (!isDateFormatValid(dateOfBirthToSubmit)) {
      const response = {
        hasErrors: true,
        validationFailures: [
          {
            propertyName: 'dateOfBirth',
            errorMessage: "'Date of Birth' not in correct format, MM/DD/YYYY",
          },
        ],
      };
      return response;
    }

    let loginEndpoint;
    let requestBody;

    switch (loginType) {
      case ldwfLoginKey:
        loginEndpoint = AccountService.customerLoginWithCustomerNumber;
        requestBody = {
          sportsmanId: valuesToSubmit.sportsmanId,
          dateOfBirth: valuesToSubmit.dateOfBirth,
        } as CustomerAuthWithCustomerNumberDto;
        break;
      case driversLicenseLoginKey:
        loginEndpoint = AccountService.customerLoginWithDriversLicense;
        requestBody = {
          number: valuesToSubmit.number,
          state: valuesToSubmit.state,
          dateOfBirth: valuesToSubmit.dateOfBirth,
        } as CustomerAuthWithDriversLicenseDto;
        break;
      case ssnLoginKey:
        loginEndpoint = AccountService.customerLoginWithSsn;
        requestBody = {
          ssnLast4: valuesToSubmit.ssnLast4,
          lastName: valuesToSubmit.lastName,
          dateOfBirth: valuesToSubmit.dateOfBirth,
        } as CustomerAuthWithSsnDto;
        break;
      case alternateIdLoginKey:
        loginEndpoint = AccountService.customerLoginWithAlternateId;
        requestBody = {
          id: valuesToSubmit.id,
          country: valuesToSubmit.country,
          dateOfBirth: valuesToSubmit.dateOfBirth,
        } as CustomerAuthWithAlternateIdDto;
        break;
      default:
        notifications.error('Unknown login command.');
        return;
    }

    const response = await loginEndpoint({
      body: requestBody,
    });

    if (response.hasErrors) {
      setLoginErrors(
        response.validationFailures.reduce(
          (acc: ValidationError[], cur: ValidationError) => {
            if (cur.propertyName === '') {
              acc.push(cur);
            }
            return acc;
          },
          [] as ValidationError[]
        )
      );
      return response;
    }

    unVerifyCustomer();

    setCustomerId(response.result.customerId);
    setLoginStep('verification');
    cookies.setCurrentLoginCookie();
    notify('refresh-session', undefined);
  };

  const onCancelUpdate = async () => {
    await logoutAsync();
    setLoginStep('login');
  };

  const onCancelCreate = () => {
    setLoginStep('login');
  };

  const onEditVerification = ({isStepper}: {isStepper: boolean}) => {
    setIsStepper(isStepper);
    setLoginStep('update');
  };

  type FirstTimeCustomers = {};
  const FirstTimeCustomers: React.FC<FirstTimeCustomers> = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        <Typography variant="heading2">First Time Customers</Typography>
        <Divider fitted hidden />
        <Typography variant="body1">
          If you have never purchased a license in Louisiana, please create a
          new customer account to make purchases.
        </Typography>
        <Divider hidden />
        <StyledButton type="button" primary onClick={() => setIsOpen(true)}>
          Create Account
        </StyledButton>
        <AccountWarningModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={() => setLoginStep('create')}
        />
      </>
    );
  };

  type LegalInformation = {};
  const LegalInformation: React.FC<LegalInformation> = () => {
    return (
      <>
        <Flex.Row justifyContent="space-between" flexWrap="wrap">
          <Flex.Col className="legal-information">
            <Typography variant="heading3">License and Permit Sales</Typography>
            <Typography variant="body1">
              <p>
                Know the law! View a copy of the current regulations at
                <a
                  href="https://www.wlf.louisiana.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  https://www.wlf.louisiana.gov/
                </a>
              </p>
              <p>
                The licenses and permits for which you are eligible to purchase
                will be displayed once your customer record is identified.
                Select the items for purchase and enter the payment method. For
                your convenience, VISA, MasterCard and Discover are accepted for
                online payment.
              </p>
              <p>
                You must print your licenses and permits as required by law and
                carry them on your person when hunting and fishing.
              </p>
              <p>ALL SALES ARE FINAL!</p>
            </Typography>
          </Flex.Col>
          <Divider />
          <Flex.Col className="legal-information">
            <Typography variant="heading3">
              Unauthorized Use of this Website
            </Typography>
            <Typography variant="body1">
              <p>
                Retailers and/or proprietors shall not use this web site to
                process over-the-counter transactions on behalf of a customer.
                If you are interested in becoming an Authorized License Agent,
                please contact the LDWF at 1-800-256-2749.
              </p>
            </Typography>
          </Flex.Col>
        </Flex.Row>
      </>
    );
  };

  return (
    <div css={styles}>
      <IspVendorPageWrapper>
        {loginStep === 'login' && (
          <StyledPageContainer
            title="Login/Create Account"
            subtitle="Find your existing account or create a new one"
          >
            <Flex.Row justifyContent="space-between" flexWrap="wrap">
              <Flex.Col>
                <Form
                  onSubmit={onSubmit}
                  render={() => (
                    <Flex.Col>
                      <Typography variant="heading1">
                        Existing Customers
                      </Typography>
                      <LoginTypeSelector
                        setLoginType={setLoginType}
                        setLoginErrors={setLoginErrors}
                        loginType={loginType}
                      />
                      <div className="input-fields">
                        <Form.Section title="">
                          {(loginErrors || []).length > 0 && (
                            <Message negative>
                              {(loginErrors || []).map((x) => (
                                <p>{x.errorMessage}</p>
                              ))}
                            </Message>
                          )}
                          {getFormFields()}
                        </Form.Section>
                      </div>
                      <div>
                        <StyledButton type="submit" primary>
                          Search Accounts
                        </StyledButton>
                      </div>
                    </Flex.Col>
                  )}
                />
              </Flex.Col>
              <Flex.Col className="first-time-customers">
                <Divider hidden />
                <FirstTimeCustomers />
              </Flex.Col>
            </Flex.Row>
            <Divider />
            <Typography variant="body1" className="legal-confitmation-text">
              By finding or creating an account, you agree to the following
              conditions:
            </Typography>
            <Divider hidden />
            <Flex.Box>
              <LegalInformation />
            </Flex.Box>
          </StyledPageContainer>
        )}
        {loginStep === 'verification' && (
          <CustomerVerification
            customerId={customerId}
            onSubmit={onSubmitVerification}
            onEdit={onEditVerification}
          />
        )}
        {loginStep === 'update' && (
          <CustomerUpdateComponent
            stepper={isStepper}
            customerId={customerId}
            onCancel={onCancelUpdate}
          />
        )}
        {loginStep === 'create' && (
          <CustomerCreateComponent onCancel={onCancelCreate} />
        )}
      </IspVendorPageWrapper>
    </div>
  );
};

type AccountWarningModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const AccountWarningModal: React.FC<AccountWarningModalProps> = ({
  isOpen,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={() => onClose()} closeIcon>
      <Modal.Header>
        Have you ever owned an LDWF license in the past?
      </Modal.Header>
      <Modal.Content>
        <div>
          <Typography variant="heading1">Existing Customer</Typography>
        </div>
        <div>
          <div>
            <Typography variant="heading3">
              If you have owned an LDWF license, try using the account lookup to
              find your account. Click "Return to account lookup" to return to
              the account lookup page.
            </Typography>
          </div>
          <Divider hidden />
          <div>
            <Typography variant="heading3">
              <strong>
                Unable to find your account?{' '}
                <u>
                  <i>Do not create a new account</i>
                </u>
                .
              </strong>{' '}
              Please call Customer Support at 225-267-9996 so we can help you
              locate your account.
            </Typography>
          </div>
        </div>
        <Divider />
        <div>
          <Typography variant="heading1">First Time Customer</Typography>
        </div>
        <div>
          <Typography variant="heading3">
            If you have never owned an LDWF license, then you need to create an
            account. Click "Continue to create account" to create your account.
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions css={styles}>
        <StyledButton
          primary
          onClick={() => onClose()}
          className="account-warning-button"
        >
          Return to account lookup
        </StyledButton>
        <StyledButton
          onClick={() => onConfirm()}
          className="account-warning-button"
        >
          Continue to create account
        </StyledButton>
      </Modal.Actions>
    </Modal>
  );
};

type LoginTypeSelector = {
  setLoginType: any;
  setLoginErrors: any;
  loginType: any;
};

const LoginTypeSelector: React.FC<LoginTypeSelector> = ({
  setLoginType,
  setLoginErrors,
  loginType,
}) => {
  const form = useForm();

  return (
    <>
      <Typography variant="body1">
        Choose an identification option to find an existing account.
      </Typography>
      <Typography variant="body1">
        If you have had an LDWF license in the past, look up your account below.
      </Typography>
      <Dropdown
        className="login-selector"
        onChange={(e, {value}) => {
          setLoginType(value);
          setLoginErrors([]);
          form.reset();
        }}
        value={loginType}
        options={loginOptions}
        selection
        fluid
      />
    </>
  );
};

type StyledLoginTypeSelector = {
  setLoginType: any;
  setLoginErrors: any;
  loginType: any;
};

const StyledLoginTypeSelector: React.FC<StyledLoginTypeSelector> = ({
  setLoginType,
  setLoginErrors,
  loginType,
}) => {
  const form = useForm();

  return (
    <>
      <Typography variant="body1">
        Choose an identification option to find an existing account.
      </Typography>
      <Typography variant="body1">
        If you have had an LDWF license in the past, look up your account below.
      </Typography>
      <Input type="text" placeholder="Search" action>
        <input />
        <Select
          compact
          options={loginOptions}
          defaultValue={loginOptions[0].value}
          value={loginType}
          onChange={(e, {value}) => {
            setLoginType(value);
            setLoginErrors([]);
            form.reset();
          }}
        />
        <StyledButton type="submit">Search</StyledButton>
      </Input>
    </>
  );
};

const numberOfLoginOptions = 5;

const styles = css`
  ${Media('MobileMax')} {
    .login-selector .menu {
      //The 2px are required to get scroll bar to go away.
      min-height: calc(${numberOfLoginOptions} * 40px + 2px) !important;
    }
  }

  ${Media('DesktopMin')} {
    .legal-information {
      max-width: 528px;
      margin-right: 152px;
    }
  }

  ${Media('MobileMax')} {
    .legal-information {
      max-width: 528px;
    }
  }

  .account-warning-button {
    margin: 5px;
  }

  .login-selector {
    max-width: 400px;
    margin-top: 16px;
  }

  ${Media('DesktopMin')} {
    .first-time-customers {
      max-width: 465px;
      margin-right: 214px;
    }
  }

  ${Media('MobileMax')} {
    .first-time-customers {
      max-width: 465px;
    }
  }

  .legal-confitmation-text {
    padding-bottom: 2rem;
  }

  .existing-customer-form {
    border: 0px !important;
    box-shadow: 0px 0px 0px !important;
  }

  .existing-customer {
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .license-and-permit-container {
    padding: 2rem;
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .unauthorized-use-container {
    padding: 2rem;
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .input-fields {
    max-width: 400px;
  }

  .spacing-div {
    width: 180px;
    height: 1px;
  }

  .borderless-form-container {
    border: 0px !important;
    box-shadow: 0px 0px 0px !important;
  }
`;
