import React from 'react';

export const OrganDonorInfo = ({
  headerWrapper: HeaderWrapper = 'h2',
  contentWrapper: ContentWrapper = React.Fragment,
}: {
  headerWrapper?: React.FC | keyof React.ReactHTML;
  contentWrapper?: React.FC | keyof React.ReactHTML;
}) => {
  return (
    <>
      <HeaderWrapper>Become an Organ Donor!</HeaderWrapper>
      <ContentWrapper>
        <p>
          When you sign up to be an organ donor, you change lives for the
          better—plus, it's fast and easy!
        </p>
        <ul>
          <li>Free Online Registration</li>
          <li>95% of Americans support organ donation</li>
          <li>
            <b>One Donor can save up to 8 lives</b>
          </li>
        </ul>
      </ContentWrapper>
    </>
  );
};
