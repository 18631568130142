import {AccountService} from '../api/generated';
import {notify} from '../hooks/use-subscription';
import {analtyics} from '../analytics';
import {setUser as setAiUser} from '../analytics/appinsights';
import {setUser} from '../analytics/sentry';
import {
  ClearAllTableFilters,
  ClearAllTableQuickSearches,
} from '../hooks/use-local-storage';
import {notifications} from './notification-service';

export const isVerified = 'isVerified';
export const isResidencyVerified = 'didVerifyResidency';

export const isCustomerVerified = () => {
  return sessionStorage.getItem(isVerified) === 'true';
};

export const verifyCustomer = () => {
  notify('refresh-session', {isVerifying: true});
};

export const unVerifyCustomer = () => {
  sessionStorage.removeItem(isVerified);
  notify('refresh-session', undefined);
};

export const logoutAsync = async () => {
  await AccountService.logout();
  notifications.warning('You have been logged out.');
  notify('refresh-session', undefined);
  analtyics.identify('');
  setUser({});
  setAiUser({});
  sessionStorage.removeItem(isVerified);
  removeIsResidencyVerified();
  ClearAllTableFilters();
  ClearAllTableQuickSearches();
};

export const setIsResidencyVerified = () => {
  notify('refresh-session', {didVerifyResidency: true});
};

export const removeIsResidencyVerified = () => {
  sessionStorage.removeItem(isResidencyVerified);
};

export const getIsResidencyVerified = () => {
  return sessionStorage.getItem(isResidencyVerified) === 'true';
};

export const getHomeRoute = () => {
  return sessionStorage.getItem('homeRoute');
};

export const isEmailValid = (email?: string) => {
  if (!email) {
    return false;
  }
  const regex = new RegExp(`.+@.+\\..+`);
  return regex.test(email);
};
