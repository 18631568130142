import React from 'react';
import {RevocationListItemDto} from '../api/generated';

const revocationTypes = {
  citation: {typeName: 'CITATION', phoneNumber: '(225)-765-2887'},
  unpaidTaxes: {typeName: 'UNPAID TAXES', phoneNumber: '(225)-219-7448'},
  childSupport: {typeName: 'CHILD SUPPORT', phoneNumber: ' 1-800-256-4650'},
};

type RevocationBannerContentProps = {
  activeRevocations: RevocationListItemDto[] | undefined;
};

export const RevocationBannerContent: React.FC<RevocationBannerContentProps> = ({
  activeRevocations,
}) => {
  return (
    <>
      <p>
        Our records indicate that you currently have one or more revocations
        which inhibits you from purchasing licenses at this time.
      </p>

      <ul>
        {activeRevocations?.map((x, index) => {
          let contactNumber = '';
          switch (x.revocationType) {
            case revocationTypes.citation.typeName:
              contactNumber = revocationTypes.citation.phoneNumber;
              break;
            case revocationTypes.unpaidTaxes.typeName:
              contactNumber = revocationTypes.unpaidTaxes.phoneNumber;
              break;
            case revocationTypes.childSupport.typeName:
              contactNumber = revocationTypes.childSupport.phoneNumber;
              break;
          }
          return (
            <li>
              {`Revocation Type: ${x.revocationType} - Call ${
                contactNumber ?? 'LDWF Headquarters'
              } for
              more information`}
            </li>
          );
        })}
      </ul>
    </>
  );
};
