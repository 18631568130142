import {useEffect} from 'react';
import {useAsync} from 'react-use';
import {
  IspCustomersService,
  KlaviyoProfilePropertiesDto,
} from '../api/generated';
import {useUser} from '../auth/use-auth';
import {analtyics} from './';
import {setUser as setAiUser} from './appinsights';
import {setUser} from './sentry';

export default function AnalyticsUserTracker() {
  const user = useUser();

  useAsync(async () => {
    let result: KlaviyoProfilePropertiesDto | undefined;
    if (user.customerId != null) {
      const {
        result: response,
      } = await IspCustomersService.getKlaviyoProfilePropertiesByCustomerId();
      result = response;
    }

    const klaviyoSettings = await IspCustomersService.klaviyoSettings();

    if (
      result &&
      klaviyoSettings.result?.klaviyoEnabled &&
      result.emailAddress != null
    ) {
      //@ts-ignore
      klaviyo.identify({
        $email: result.emailAddress,
        $first_name: result.firstName,
        $last_name: result.lastName,
        $id: result.sportsmanId,
        $city: result.mailingAddress?.city,
        $region: result.mailingAddress?.stateCode,
        $country: result.mailingAddress?.county,
        $zip: result.mailingAddress?.zipCode,
        residencyCode: result.residencyCode,
        birthDate: result.dateOfBirth,
        age: getAge(result.dateOfBirth),
        activeProductCodes: result.activeProductCodes,
        productCodesEnrolledInAutoRenew: result.productCodesEnrolledInAutoRenew,
      });
    }
  }, [user]);

  useEffect(() => {
    analtyics.identify(`${user.id}`);
    setUser({id: `${user.id}`, email: user.email});
    setAiUser({id: `${user.id}`});
  }, [user.email, user.id]);

  return null;
}

function getAge(birthDate: Date) {
  var today = new Date();
  var newBirthDate = new Date(birthDate);
  var age = today.getFullYear() - newBirthDate.getFullYear();
  var m = today.getMonth() - newBirthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < newBirthDate.getDate())) {
    age--;
  }
  return age;
}
