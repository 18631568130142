import moment from 'moment-timezone';

export const applicationTimezone = 'America/Chicago';

// converts a date with any offset to CST
export const momentCst = (date, format?) =>
  format
    ? moment.tz(date, format, applicationTimezone)
    : moment.tz(date, applicationTimezone);

export const cstNow = () => moment().tz(applicationTimezone);

export const forceCstOffsetAndStartOfDay = (date: string | Date) => {
  return moment(date).tz(applicationTimezone, true).startOf('day').format();
};
