import Cookies from 'js-cookie';
import {useContext} from 'react';
import {LoginCookieContext, loginCookieKey} from '../cookie-provider';

export function useLoginCookie() {
  const {
    currentCookie,
    setCurrentCookie,
    previousCookie,
    setPreviousCookie,
  } = useContext(LoginCookieContext);

  function setCurrentLoginCookie() {
    const currentCookieValue = Cookies.get(loginCookieKey);
    if (currentCookieValue) {
      setPreviousCookie(currentCookieValue);
    } else {
      return;
    }

    Cookies.set(loginCookieKey, currentCookieValue);
    setCurrentCookie(currentCookieValue);
  }

  function checkAndUpdateCookie() {
    const currentCookieValue = Cookies.get(loginCookieKey);
    if (currentCookie !== currentCookieValue) {
      setPreviousCookie(currentCookie);
      setCurrentCookie(currentCookieValue);
    }
  }

  return {
    currentCookie: currentCookie,
    setCurrentLoginCookie: setCurrentLoginCookie,
    previousCookie: previousCookie,
    checkAndUpdateCookie: checkAndUpdateCookie,
  };
}
