import React from 'react';
import {useUser} from '../auth/use-auth';
import {BasicPage} from '../basic-page';

export const CourseAndEventRegistration = () => {
  const user = useUser();
  return (
    <BasicPage title="Temporary Page (for DEV/QA use only)">
      <h3>COURSE & EVENT REGISTRATION</h3>
      <p>
        Logged in as {user.firstName} {user.lastName}
      </p>
    </BasicPage>
  );
};
