import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  TransactionCustomerService,
  LotteryCheckoutService,
  LotteryShoppingCartService,
} from '../../../api/generated';
import {routes} from '../../../routes/config';
import {notifications} from '../../../utils/notification-service';
import {Modal} from 'semantic-ui-react';
import {StyledButton} from '../../../styled-components/styled-buttons';

export interface ContinueNoFeeTransactionProps {
  isEmailVerified: boolean;
  customerEmail: string;
  shoppingCartId?: number;
}

export const ContinueNoFeeTransaction = ({
  isEmailVerified,
  customerEmail,
  shoppingCartId,
}: ContinueNoFeeTransactionProps) => {
  const history = useHistory();

  const [continueModalOpen, setContinueModalOpen] = useState(false);

  const submitNoFeeTransaction = async () => {
    const getUpdateEmailResponse = await TransactionCustomerService.updateEmailAddress(
      {emailAddress: customerEmail}
    );

    if (getUpdateEmailResponse.hasErrors) {
      notifications.error(
        'Failed to update email. Please contact support for further assistance.'
      );
      return;
    }

    const response = await LotteryCheckoutService.verifyNoFeeTransaction();

    if (response.hasErrors) {
      notifications.error('Failed to verify Transaction');
      return response;
    }

    notifications.success('Transaction Complete');
    history.replace(routes.customer.lotteryReceipt, {
      transactionState: response.result,
    });
  };

  return (
    <Modal
      primary
      onClose={() => setContinueModalOpen(false)}
      onOpen={async () => {
        const response = await LotteryShoppingCartService.isCartExpired({
          shoppingCartId: shoppingCartId,
        });

        if (response.result === true) {
          notifications.error('Your cart is expired');
          history.push(routes.customer.lotteryApplications);
          return;
        }

        if (!isEmailVerified) {
          notifications.error('Please Verify Your Email Address');
          return;
        }
        setContinueModalOpen(true);
      }}
      open={continueModalOpen}
      size="large"
      trigger={<StyledButton primary content={'Complete'} />}
    >
      <Modal.Header>Confirm Application(s)</Modal.Header>
      <Modal.Content>
        There is no fee required for one or more applications in your cart,
        meaning that confirming this will automatically enter you into your
        selected lotteries. Are you sure you want to continue?
      </Modal.Content>
      <Modal.Actions>
        <StyledButton
          content="Cancel"
          onClick={() => {
            setContinueModalOpen(false);
          }}
        />
        <StyledButton
          content="Confirm"
          primary
          onClick={submitNoFeeTransaction}
        />
      </Modal.Actions>
    </Modal>
  );
};
