import _ from 'lodash';
import React from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {useAsyncFn} from 'react-use';
import {CustomerDetailDto, VendorCustomerService} from '../api/generated';
import {
  ResidencyCode,
  SocialSecurityNumberStatus,
} from '../api/generated/enums';
import {CustomerUpdate} from '../internet-sales-portal/customers/customer-create-update/customer-create-update-base';
import {splitZipCodeFields} from '../utils/address-helpers';
import {removeIsResidencyVerified, verifyCustomer} from '../utils/auth-helpers';
import {notifications} from '../utils/notification-service';
import {vendorRoutes} from './routes/config';

const SsnStatus = {
  true: SocialSecurityNumberStatus.EXEMPT,
  false: SocialSecurityNumberStatus['NON-EXEMPT'],
};

const residencyCodeOptions = {
  Yes: ResidencyCode.RESIDENT,
  No: ResidencyCode['NON-RESIDENT'],
};

export const VendorCustomerProfile = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  const [, onSubmit] = useAsyncFn(async (values) => {
    const valuesToSubmit = _.cloneDeep(values);

    splitZipCodeFields(valuesToSubmit);

    valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
      ? SsnStatus.true
      : SsnStatus.false;
    valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

    const response = await VendorCustomerService.update({
      body: {...valuesToSubmit},
    });

    if (response.hasErrors) {
      return response;
    }
    notifications.success('Customer Updated');
    removeIsResidencyVerified();

    verifyCustomer();
    history.push(vendorRoutes.root, {catalogState: {customer: valuesToSubmit}});
  });

  const [, onCancel] = useAsyncFn(
    async (customer: CustomerDetailDto | undefined) => {
      history.push(vendorRoutes.root, {catalogState: {customer: customer}});
    }
  );

  return (
    <CustomerUpdate customerId={id} onSubmit={onSubmit} onCancel={onCancel} />
  );
};
