import {css} from '@emotion/core';
import {faSearchLocation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import React, {useMemo} from 'react';
import {FormSpy} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import {useAsync, useAsyncFn, useLocation} from 'react-use';
import {List, Modal} from 'semantic-ui-react';
import {Overwrite} from 'utility-types';
import {
  BooleanApiResult,
  IspCreateCustomerCommand,
  IspCustomersService,
  VendorCustomerService,
} from '../../../api/generated';
import {StateCode} from '../../../api/generated/enums';
import {Flex} from '../../../components/flex';
import {Form} from '../../../forms';
import {AddressProps} from '../../../forms/address-fields';
import {
  FieldConfig,
  RawFieldConfig,
  fieldConfig,
  input,
  radiogroup,
} from '../../../forms/schema-utils';
import {
  fieldCleanupMutator,
  useFieldCleanup,
} from '../../../hooks/use-field-cleanup';
import {notify} from '../../../hooks/use-subscription';
import {StyledButton} from '../../../styled-components/styled-buttons';
import {Typography} from '../../../styled-components/typography';
import {Theme} from '../../../theme';
import {
  setIsResidencyVerified,
  verifyCustomer,
} from '../../../utils/auth-helpers';
import {notifications} from '../../../utils/notification-service';
import {vendorRoutes} from '../../../vendor-portal/routes/config';
import {customerRoutes as routes} from '../../routes/config';
import {customerUpdateFormValueMapper} from './customer-create-update-utils';

type FieldConfigDto = Pick<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  'residencyCode' | 'driversLicenseNumber' | 'legalConfirmation'
>;

enum ResidentYesNoOptions {
  Yes = 'Yes',
  No = 'No',
}

const mutators = {
  fieldCleanupMutator,
};

const useFields = () => {
  return useAsync(async () => {
    return fieldConfig<FieldConfigDto>({
      residencyCode: radiogroup({
        fieldRequired: true,
      }),
      driversLicenseNumber: input({
        fieldLabel: 'Louisiana DL or ID Number',
        fieldRequired: true,
      }),
      legalConfirmation: input({
        fieldLabel: '',
        fieldRequired: true,
        inputProps: {
          label:
            'I hereby declare that all information provided is true and correct',
        },
      }),
    });
  }, []);
};

type CustomerUpdateResidencyValidationProps = {
  formValues: any;
  isOpen?: boolean;
  closeModal?: () => void;
};

export const CustomerUpdateResidencyValidation: React.FC<CustomerUpdateResidencyValidationProps> = ({
  formValues,
  isOpen,
  closeModal,
}) => {
  const fields = useFields();
  const history = useHistory();
  const location = useLocation();
  const routeLocation = location.pathname?.includes('vendor')
    ? 'vendor'
    : location.pathname?.includes('admin')
    ? 'admin'
    : 'isp';
  const isVendor = routeLocation === 'vendor';

  const [onSubmitState, onSubmit] = useAsyncFn(
    async (values) => {
      //combining update form values with verification modal form values
      const initialFormValues = _.cloneDeep(formValues);

      initialFormValues.residencyCode = values.residencyCode;
      initialFormValues.legalConfirmation = values.legalConfirmation;

      if (initialFormValues.residencyCode === ResidentYesNoOptions.Yes) {
        initialFormValues.driversLicenseStateCode = StateCode.LOUISIANA;
        initialFormValues.driversLicenseNumber = values.driversLicenseNumber;
      }

      const valuesToSubmit = customerUpdateFormValueMapper(initialFormValues);

      let response: BooleanApiResult;
      if (isVendor) {
        response = await VendorCustomerService.updateWithResidencyVerification({
          body: valuesToSubmit,
        });
      } else {
        response = await IspCustomersService.updateWithResidencyVerification({
          body: valuesToSubmit,
        });
      }

      if (response.hasErrors) {
        notifications.error('Customer Update Failed');
        return response;
      }

      notifications.success('Customer Updated');
      setIsResidencyVerified();

      if (isVendor) {
        verifyCustomer();
        history.push(vendorRoutes.root, {
          catalogState: {customer: valuesToSubmit},
        });
      } else {
        notify('customer-updated', undefined);
        notify('refresh-session', undefined);
        history.push(routes.dashboard);
      }
    },
    [formValues, history, isVendor]
  );

  const initialValues = useMemo(() => {
    return {
      driversLicenseNumber:
        formValues.driversLicenseStateCode === StateCode.LOUISIANA
          ? formValues.driversLicenseNumber
          : null,
      driversLicenseStateCode: formValues.driversLicenseStateCode,
      dateOfBirth: formValues.dateOfBirth,
    };
  }, [formValues]);

  return (
    <>
      <Modal
        css={styles}
        onClose={closeModal}
        open={isOpen}
        size="small"
        closeOnDimmerClick={false}
      >
        <Modal.Content className="verification-modal-content">
          <Flex.Col justifyContent="space-around">
            <Flex.Row justifyContent="space-around">
              <Flex.Col align="center">
                <Typography variant="heading1" icon>
                  <FontAwesomeIcon icon={faSearchLocation} />
                  Are you a Louisiana Resident?
                </Typography>

                <List
                  className="residency-requirement-list"
                  size="small"
                  bulleted
                >
                  <List.Item>
                    Do you have a valid Louisiana DL or ID? (Not required if
                    under 18)
                  </List.Item>
                  <List.Item>
                    You are NOT a resident of another state.
                  </List.Item>
                  <List.Item>
                    You have resided in Louisiana for 6 months or longer.
                  </List.Item>
                </List>
              </Flex.Col>
            </Flex.Row>
            <Flex.Row justifyContent="space-around">
              <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                mutators={mutators}
                render={() => (
                  <>
                    {fields.value && (
                      <>
                        <FormFields
                          initialValues={initialValues}
                          fields={fields.value}
                        />
                      </>
                    )}
                    <div className="form-actions">
                      <StyledButton
                        floated="right"
                        type="submit"
                        primary
                        padded
                        loading={onSubmitState.loading}
                      >
                        Update
                      </StyledButton>
                      <StyledButton floated="right" padded onClick={closeModal}>
                        Cancel
                      </StyledButton>
                    </div>
                  </>
                )}
              />
            </Flex.Row>
          </Flex.Col>
        </Modal.Content>
      </Modal>
    </>
  );
};

type FormFieldProps = {
  fields: RawFieldConfig<FieldConfigDto>;
  initialValues: {
    driversLicenseNumber: string;
    driversLicenseStateCode: string;
    dateOfBirth: Date;
  };
};

const FormFields: React.FC<FormFieldProps> = ({fields, initialValues}) => {
  const hasDriversLicenseNumber = initialValues.driversLicenseNumber;

  const hasLouisianaStateCode =
    initialValues.driversLicenseStateCode === StateCode.LOUISIANA;

  if (hasDriversLicenseNumber && !hasLouisianaStateCode) {
    initialValues.driversLicenseNumber = '';
  }

  const age = moment().diff(initialValues.dateOfBirth, 'years', false);

  return (
    <>
      <Flex.Row justifyContent="space-around">
        <div>
          <Form.RadioGroup
            fieldConfig={fields.residencyCode}
            enum={ResidentYesNoOptions}
          />
        </div>
      </Flex.Row>
      <Flex.Row
        className="driver-license-input-row"
        justifyContent="space-around"
      >
        <FormSpy>
          {({values}: {values: FieldConfigDto}) => {
            const isResidencySelected =
              ResidentYesNoOptions[values.residencyCode ?? ''] ===
              ResidentYesNoOptions.Yes;
            const isEighteenOrOlder = age >= 18;

            return (
              <>
                {isResidencySelected &&
                  isEighteenOrOlder &&
                  (!hasDriversLicenseNumber ||
                    (hasDriversLicenseNumber && !hasLouisianaStateCode)) && (
                    <LouisianaDriversLicenseField
                      fieldConfig={fields.driversLicenseNumber}
                    />
                  )}
              </>
            );
          }}
        </FormSpy>
      </Flex.Row>
      <Flex.Row justifyContent="space-around">
        <div className="legal-confirmation-text">
          <Typography variant="body1">
            No person shall procure or attempt to procure a license by fraud,
            deceit, misrepresentation, or any false statement. Licenses are
            affidavits confirming the information is correct. False information
            is subject to investigation and may be grounds for criminal
            prosecution resulting in denial or revocation of licenses and/or
            permits.
          </Typography>
          <Typography variant="heading1">
            <Form.Checkbox fieldConfig={fields.legalConfirmation} />
          </Typography>
        </div>
      </Flex.Row>
    </>
  );
};

const LouisianaDriversLicenseField: React.FC<{
  fieldConfig?: FieldConfig;
}> = ({fieldConfig}) => {
  useFieldCleanup(fieldConfig!.fieldName);

  return <Form.Input fieldConfig={fieldConfig} />;
};

const styles = css`
  .verification-modal-content {
    min-height: 20em !important;
    min-height: 40em;
  }
  .driver-license-input-row {
    height: 5em;
  }

  .legal-confirmation-text {
    width: 70%;
    margin-bottom: 1em;
    margin-top: 1em;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .form-field {
      padding-top: 1rem !important;
    }
  }

  .residency-requirement-list {
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }

  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    border: 2px solid ${Theme.palette.blue800};
  }
`;
