import React, {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import {buildStringRouteWithState} from '../../utils/route-helpers';
import {customerRoutes} from '../routes/config';
import {CourseRegistrationLocationState} from './course-registration';

export const CourseRegistrationLogin = () => {
  const history = useHistory();
  const match = useRouteMatch<{string: string}>();
  const courseCode = String(match.params.string);
  const locationState: CourseRegistrationLocationState | undefined =
    history.location.state;

  useEffect(() => {
    setTimeout(() => {
      history.push(
        ...buildStringRouteWithState<
          CourseRegistrationLocationState | undefined
        >(customerRoutes.courseRegistration, courseCode, locationState)
      );
    }, 250);
  });

  return (
    <>
      <Loader inline="centered" active />
    </>
  );
};
