import css from '@emotion/css/macro';
import React, {useEffect, useState} from 'react';
import {Image, Input, Button} from 'semantic-ui-react';
import _ from 'lodash';
import {notifications} from '../../utils/notification-service';
import {
  DonationTypeCode,
  LotteryShoppingCartService,
} from '../../api/generated';
import {Media} from '../../styles/breakpoints';
import {darken} from 'polished';
import {Theme} from '../../theme';
import {StyledButton} from '../../styled-components/styled-buttons';
import {
  CatalogType,
  ProductCatalogState,
  updateCustomerCart,
  updateVendorCart,
} from '../product-catalog/product-catalog-utils';
import {Producer} from '../../hooks/use-produce';
import {TenderType} from '../../api/generated';
import {routes} from '../../routes/config';
import {useHistory} from 'react-router-dom';

export enum DonationProductCode {
  HuntersForTheHungryDonation = '0160',
  HuntersForTheHungryDonationHq = '0169',
  LdwfHeadquartersDonation = '0168',
  DisabledVeteransDonation = '0130',
}

const STANDARD_DONATION_AMOUNTS = ['0', '2', '5', '10', '15', '25', '50'];

export const Donations: React.FC<{
  donationAmount: number | undefined;
  setDonationAmount: React.Dispatch<React.SetStateAction<number>>;
  addProductCartDonation?: (x: any) => any;
  customerId?: number;
  donationType?: DonationTypeCode;
  setState?: (mutationFn: Producer<ProductCatalogState>) => void;
  catalogType?: CatalogType | null;
  tenderType?: TenderType | null;
  retryCartFetch?: any;
  donationProductCode: string;
  donationText: string;
  donationLogo: string;
  timedShoppingCartId?: number;
  isExternallyDisabled?: boolean;
}> = ({
  donationAmount,
  setDonationAmount,
  addProductCartDonation = null,
  customerId = null,
  setState,
  catalogType,
  tenderType,
  retryCartFetch,
  donationProductCode,
  donationText,
  donationLogo,
  timedShoppingCartId,
  isExternallyDisabled,
}) => {
  const [currentSelection, setCurrentSelection] = useState<
    string | undefined
  >();
  const [otherValue, setOtherValue] = useState<number>();
  const [loading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (donationAmount === undefined) {
      setCurrentSelection(donationAmount);
    } else {
      const donationAmountString = donationAmount?.toString() ?? '';
      setCurrentSelection(
        STANDARD_DONATION_AMOUNTS.includes(donationAmountString)
          ? donationAmountString
          : 'other'
      );
      setOtherValue(!donationAmount || donationAmount < 0 ? 1 : donationAmount);
    }
  }, [donationAmount]);

  const handleSelection = async (amount?: number, isOtherValue?: boolean) => {
    if (timedShoppingCartId) {
      const isCartExpired = await LotteryShoppingCartService.isCartExpired({
        shoppingCartId: timedShoppingCartId,
      });

      if (isCartExpired.result === true) {
        notifications.error('Your cart is expired');
        history.replace(routes.customer.lotteryApplications);
        return;
      }
    }

    if (_.isNumber(amount)) {
      setCurrentSelection(isOtherValue ? 'other' : amount.toString());

      setIsLoading(true);
      const result = addProductCartDonation
        ? await addProductCartDonation({
            body: {
              donationAmount: amount,
              customerId: customerId,
              donationProductCode: donationProductCode,
            },
          })
        : await LotteryShoppingCartService.addDonation({
            body: {
              donationAmount: amount,
              donationProductCode: donationProductCode,
            },
          });
      setIsLoading(false);

      if (!result.hasErrors) {
        notifications.success('Donation updated');
        setDonationAmount(amount);

        if (retryCartFetch) {
          retryCartFetch.retry();
        }

        //We set the other amount equal to the current amount so that the field / button is as responsive as possible
        setOtherValue(amount);

        if (catalogType === CatalogType.customer && setState) {
          updateCustomerCart(setState);
        } else if (
          (catalogType === CatalogType.vendor ||
            catalogType === CatalogType.hqVendor ||
            catalogType === CatalogType.admin) &&
          setState
        ) {
          updateVendorCart(setState, customerId ?? 0, tenderType);
        }
      } else {
        notifications.error('Failed to update donation');
        return;
      }
    } else {
      setCurrentSelection('other');
    }
  };

  const handleOtherEntry = (e: any, {value}: any) => {
    const parsedInput = parseFloat(value);
    setOtherValue(parsedInput);
  };

  const isDisabled = (value: number) => {
    return (
      isExternallyDisabled ||
      loading ||
      (currentSelection !== 'other' && currentSelection === value.toString())
    );
  };

  return (
    <>
      <div className="donations-header-container-mobile">
        <h1 className="donations-header-mobile">Donations</h1>
        <Image className="hunters-for-hungry-logo-mobile" src={donationLogo} />
      </div>

      <div className="non-mobile-logo-container">
        <Image className="hunters-for-hungry-logo-mobile" src={donationLogo} />
      </div>

      <section className="question-section">
        <p id="donation-question" title="A selection is required">
          {donationText}
        </p>
        <label
          htmlFor="donation-question"
          className="required-indicator"
          title="A selection is required"
        >
          *
        </label>
      </section>

      <section className="donation-options">
        <Button
          disabled={isDisabled(0)}
          loading={loading && currentSelection === '0'}
          className={currentSelection === `${0}` ? 'selected' : ''}
          onClick={() => handleSelection(0)}
          content={'Not Now'}
        />
        <Button
          disabled={isDisabled(2)}
          loading={loading && currentSelection === '2'}
          className={currentSelection === `${2}` ? 'selected' : ''}
          onClick={() => handleSelection(2)}
          content={'$2'}
        />
        <Button
          disabled={isDisabled(5)}
          loading={loading && currentSelection === '5'}
          className={currentSelection === `${5}` ? 'selected' : ''}
          onClick={() => handleSelection(5)}
          content={'$5'}
        />
        <Button
          disabled={isDisabled(10)}
          loading={loading && currentSelection === '10'}
          className={currentSelection === `${10}` ? 'selected' : ''}
          onClick={() => handleSelection(10)}
          content={'$10'}
        />
        <Button
          disabled={isDisabled(15)}
          loading={loading && currentSelection === '15'}
          className={currentSelection === `${15}` ? 'selected' : ''}
          onClick={() => handleSelection(15)}
          content={'$15'}
        />
        <Button
          disabled={isDisabled(25)}
          loading={loading && currentSelection === '25'}
          className={currentSelection === `${25}` ? 'selected' : ''}
          onClick={() => handleSelection(25)}
          content={'$25'}
        />
        <Button
          disabled={isDisabled(50)}
          loading={loading && currentSelection === '50'}
          className={currentSelection === `${50}` ? 'selected' : ''}
          onClick={() => handleSelection(50)}
          content={'$50'}
        />
        <Button
          disabled={loading || isExternallyDisabled}
          className={currentSelection === `other` ? 'selected' : ''}
          onClick={() => handleSelection()}
          content={'Other'}
        />
      </section>

      {currentSelection === 'other' && (
        <section className="other-input-section">
          <label className="ui form" htmlFor="other-input">
            Other
          </label>
          <Input
            id="other-input"
            onChange={handleOtherEntry}
            disabled={isExternallyDisabled}
            //accounts for initial -1 value -- set default to $1
            defaultValue={
              !donationAmount || donationAmount < 0 ? 1 : donationAmount
            }
            type="number"
          />{' '}
          <StyledButton
            primary
            disabled={
              donationAmount === otherValue ||
              otherValue == null ||
              isNaN(otherValue) ||
              (donationAmount === 0 && otherValue === 0)
            }
            loading={loading && currentSelection === 'other'}
            onClick={() => handleSelection(otherValue, true)}
            content={
              donationAmount && donationAmount > 0
                ? 'Update Donation'
                : 'Add Donation'
            }
          />
        </section>
      )}
    </>
  );
};

export const donationsAndPaymentStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, auto);

  .cart-item-ar-toggle {
    margin-bottom: -5px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .auto-renew-container {
    font-size: 13px;
  }

  .customer-mailing-address-info {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .edit-button {
    margin-top: -15px;
  }

  ${Media('TabletMax')} {
    .customer-info-table {
      margin-top: 15px !important;
    }
  }

  ${Media('MobileMax')} {
    &.ui.segment {
      margin: 0 -1rem;
      border-radius: 0;
    }

    .ui.form.donation-options {
      .form-row {
        .image {
          margin-top: 2em !important;
        }

        .field {
          margin: 0em 0.5em 1.5em !important;
          height: 1em !important;
        }
      }
    }

    .customer-info-table {
      margin-top: 15px !important;
    }
  }

  .auto-renew-message-section {
    border-bottom: none !important;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
    margin-top: 15px;
    font-size: 14px;
  }

  .donations-section,
  .lottery-donations-section {
    border-bottom: none !important;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, auto);
    border-bottom: 1px solid ${Theme.palette.grey100};
    margin-bottom: 10px;

    .donations-header-wide {
      display: block;
      margin-right: 20px;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
    }

    .hunters-for-hungry-logo-wide {
      display: block;
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 6;

      justify-self: center;
      align-self: center;
      width: 100px;
      height: 75px;
    }

    .donations-header-container-mobile {
      display: none;
    }

    .error-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .question-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3;

      padding: 12px 0 20px 0;

      p {
        margin: 0;
        color: ${Theme.palette.grey600};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: inline;
      }
    }

    .donation-options {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 4;
      grid-row-end: 4;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding: 0 0 20px 0;

      button {
        padding: 0;
        margin: 0;
        background: rgba(236, 235, 234, 0.5);
        border: 1px solid ${Theme.palette.grey100};
        box-sizing: border-box;
        border-radius: 0px;
        width: 99px;
        height: 62px;
        color: ${Theme.palette.grey800};

        &.disabled {
          opacity: 100% !important;
        }

        &.selected {
          background: ${Theme.palette.green00};
          border: 1px solid ${Theme.palette.green400};
          border-radius: 0px 0px 3px 0px;
          outline: none;
        }

        :hover {
          cursor: pointer;
          background: ${darken(0.1, Theme.palette.grey50)};
        }

        :active {
          background: ${Theme.palette.green00};
          border: 1px solid ${Theme.palette.green400};
        }

        :focus {
          background: ${Theme.palette.green00};
          border: 1px solid ${Theme.palette.green400};
          border-radius: 0px 0px 3px 0px;
          outline: none;
        }
      }
    }

    .other-input-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 5;
      grid-row-end: 5;

      padding: 0;

      margin-bottom: 10px;

      label {
        display: block;
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
      }

      .ui.input {
        width: 145px;
        height: 40px;
        border-radius: 3px;
        border: 1px solid ${Theme.palette.grey200};
      }
    }

    .ui.form.donation-options {
      .form-row {
        .field {
          margin: 0em 0.5em 1em;
          height: 3em;
        }

        .ui.radio.checkbox label {
          padding-left: 1.5em;
        }
      }
    }

    .expiry-slash {
      width: 2em !important;
    }

    .payment-button {
      margin-left: 16px !important;
    }
  }

  .lottery-donations-section {
    margin: 0.5em;
  }

  .early-renewal-confirmation-section {
    border-bottom: none !important;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;

    background-color: #fcf8e3;
    border-radius: 5px;
    margin-bottom: 25px;

    .box-description {
      padding: 1rem;
    }
  }

  section {
    padding: 0 50px 5px 0;
  }

  h1 {
    margin: 0;
    color: ${Theme.palette.blue800};
    font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial, Helvetica,
      sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  .error-section {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .required-indicator {
    color: red;
  }

  .confirmation-cart-section {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    border-bottom: 1px solid ${Theme.palette.grey100};

    .item-description-container {
      .product-name {
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
      }

      .product-details {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
      }

      .trips-container {
        small {
          color: ${Theme.palette.blue800};
        }
      }
    }

    .product-price {
      color: ${Theme.palette.grey700};
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }

    .extra-fee-row {
      .extra-fee-title {
        color: ${Theme.palette.blue800};
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }

      .extra-fee-price {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
      }
    }

    .total-cost-row {
      border: none !important;

      .cell-with-action {
        padding-bottom: 0;

        .total-cost-title {
          display: block;
          color: ${Theme.palette.blue800};
          font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
            Helvetica, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }

        .edit-cart-button {
          background: #eeeeee;
          margin: 25px 0 0 0;
          width: 65px;
          height: 39px;
        }

        .edit-cart-button-text {
          color: ${Theme.palette.grey600};
          font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
            Helvetica, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
        }
      }

      .total-cost-price {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .customer-information-section {
    padding: 0 0 0 50px;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;

    h1 {
      margin-bottom: 8px;
    }

    .customer-info-details {
      display: grid;

      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, auto);

      section {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-template-rows: 1, auto;
        padding: 0;
        margin: 0;
        height: 23px;

        h2 {
          color: ${Theme.palette.grey700};
          margin: none;
          font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
        }

        p {
          color: ${Theme.palette.grey700};
          font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }

  .other-input-section {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;

    padding: 0;

    label {
      display: block;
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    .ui.input {
      width: 145px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid ${Theme.palette.grey200};
    }
  }

  .payment-method-section {
    display: grid;
    margin-bottom: 20px;

    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 5;

    grid-template-columns: 1fr 1fr;
  }

  .credit-card-info-section {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 6;
    grid-row-end: 6;
    margin-bottom: 20px;

    h1 {
      margin: 0 0 6px 0;
      font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial, Helvetica,
        sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: ${Theme.palette.blue800};
    }

    p {
      margin: 0 0 35px 0;
      padding: 0;
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }

    .form-actions-row {
      padding-top: 22px;

      .submit-button {
        flex: wrap;
        height: 39px;
        background: ${Theme.palette.blue500};
        padding: 10px 20px;
        border-radius: 3px;
        margin: 0;

        color: ${Theme.palette.white1};
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;

        &:hover {
          background: ${darken(0.1, Theme.palette.blue500)};
        }
      }

      .cancel-button {
        width: 82px;
        height: 39px;
        background: ${Theme.palette.grey50};
        padding: 10px 20px;
        border-radius: 3px;
        margin: 0 0 0 20px;

        color: ${Theme.palette.grey700};
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;

        &:hover {
          background: ${darken(0.1, Theme.palette.grey50)};
        }
      }
    }
  }

  ${Media('WideMin')} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    padding: 0 5px;

    section {
      padding: 0 0 5px 0;
    }

    .error-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
    }

    .auto-renew-message-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 4;
      grid-row-end: 4;
    }

    .customer-information-section {
      padding: 0 0 50px 0;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .confirmation-cart-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3;
    }

    .donations-section,
    .lottery-donations-section {
      border-bottom: none !important;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 5;
      grid-row-end: 5;

      .donations-header-wide {
        display: none;
      }

      .hunters-for-hungry-logo-wide {
        display: none;
      }

      .donations-header-container-mobile {
        display: block;

        .donations-header-mobile {
          display: inline;
          margin-right: 20px;
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 1;
          grid-row-end: 1;
        }

        .hunters-for-hungry-logo-mobile {
          display: inline;
          grid-column-start: 2;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 6;

          justify-self: center;
          align-self: center;
          width: 100px;
          height: 75px;
        }
      }

      .non-mobile-logo-container {
        display: none;
      }

      .donation-options {
        button {
          padding: 0;
          margin: 0;
          background: rgba(236, 235, 234, 0.5);
          border: 1px solid ${Theme.palette.grey100};
          box-sizing: border-box;
          border-radius: 0px;
          width: 79px;
          height: 42px;
          color: ${Theme.palette.grey800};

          &.selected {
            background: ${Theme.palette.green00};
            border: 1px solid ${Theme.palette.green400};
            border-radius: 0px 0px 3px 0px;
            outline: none;
          }

          :hover {
            cursor: pointer;
            background: ${darken(0.1, Theme.palette.grey50)};
          }

          :active {
            background: ${Theme.palette.green00};
            border: 1px solid ${Theme.palette.green400};
          }

          :focus {
            background: ${Theme.palette.green00};
            border: 1px solid ${Theme.palette.green400};
            border-radius: 0px 0px 3px 0px;
            outline: none;
          }
        }
      }
    }

    .early-renewal-confirmation-section {
      border-bottom: none !important;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 6;
      grid-row-end: 6;
    }

    .mobile-button-margin {
      margin-bottom: 10px;
    }

    .payment-method-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 7;
      grid-row-end: 7;
    }

    .credit-card-info-section {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 8;
      grid-row-end: 8;
    }
  }
`;
