export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object')
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key;
}

export const isNullOrUndefined = (obj) => {
  return obj === undefined || obj === null;
};

export function getKeys(obj) {
  return Object.keys(obj);
}
