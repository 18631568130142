import {css} from '@emotion/core';
import {faBan, faPrint} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Divider, Label, Message, Modal} from 'semantic-ui-react';
import {
  CustomerLookupDto,
  PastTransactionDto,
  TransactionCustomerService,
  VendorTransactionService,
  VoidTransactionForVendorCommand,
} from '../api/generated';
import {VoidTransactionReasons} from '../api/generated/enums';
import {getEnumDropdownOptions} from '../api/generated/utils';
import {BasicPage} from '../basic-page';
import {DateFormat, DateTimeFormat} from '../components/date';
import {ExportButton} from '../components/export-button';
import {Flex} from '../components/flex';
import {Form} from '../forms';
import {dropdown, fieldConfig, RawFieldConfig} from '../forms/schema-utils';
import {routes} from '../routes/config';
import {StyledButton} from '../styled-components/styled-buttons';
import {Typography} from '../styled-components/typography';
import {notifications} from '../utils/notification-service';

type VoidTransactionDto = Omit<
  VoidTransactionForVendorCommand,
  'transactionId'
>;

const getVoidTransactionFields = () =>
  fieldConfig<VoidTransactionDto>({
    voidTransactionReason: dropdown({
      fieldLabel: 'Reason',
      fieldRequired: true,
      inputProps: {
        options: getEnumDropdownOptions(VoidTransactionReasons),
        placeholder: 'Select a Reason...',
        selection: true,
        search: true,
      },
    }),
  });

export const TransactionDetails = () => {
  const history = useHistory();
  const location = useLocation();

  const customer: CustomerLookupDto | null = location.state
    ? location.state.customer
    : null;

  const transaction: PastTransactionDto = location.state
    ? location.state.transaction
    : null;

  return (
    <>
      {transaction && (
        <BasicPage title={`Transaction #${transaction.transactionNumber}`}>
          <Flex.Row>
            <Flex.Col>
              <Typography variant="body1">
                <Flex.Col>
                  <Flex.Row justifyContent="space-between">
                    <Typography
                      variant="body1"
                      fontWeight="500"
                    >{`Status `}</Typography>
                    <Flex.Box>{transaction.status}</Flex.Box>
                  </Flex.Row>
                  <Flex.Row justifyContent="space-between">
                    <Typography
                      variant="body1"
                      fontWeight="500"
                    >{`LDWF# `}</Typography>
                    <Flex.Box>{transaction.sportsmanId}</Flex.Box>
                  </Flex.Row>
                  <Flex.Row justifyContent="space-between">
                    <Typography
                      variant="body1"
                      fontWeight="500"
                    >{`DL/ID# `}</Typography>
                    <Flex.Box>{transaction.driversLicenseNumber}</Flex.Box>
                  </Flex.Row>
                  <Flex.Row justifyContent="space-between">
                    <Typography
                      variant="body1"
                      fontWeight="500"
                    >{`DOB `}</Typography>
                    <Flex.Box>
                      <DateFormat date={transaction.dateOfBirth} />
                    </Flex.Box>
                  </Flex.Row>
                </Flex.Col>
              </Typography>
              <Typography variant="heading3">
                {transaction.customerName}
              </Typography>
              <Typography variant="body2">
                {transaction.customerPhysicalAddress}
              </Typography>
              <Typography variant="body2">
                {`Purchased `}
                <DateTimeFormat datetime={transaction.createdTimestamp} />
              </Typography>
            </Flex.Col>
          </Flex.Row>
          <Divider />
          <Flex.Col>
            <Flex.Row justifyContent="space-between">
              <Flex.Col>
                <Typography variant="heading2">Products</Typography>
              </Flex.Col>
              <Flex.Col>
                <Flex.Row>
                  {transaction.canReprint && !transaction.isVoid && (
                    <ExportButton
                      label="Reprint"
                      action={
                        TransactionCustomerService.exportTransactionCustomerLicensePdfFile
                      }
                      fileName={'CustomerTransaction'}
                      // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                      request={{
                        transactionId: transaction.id,
                        viewInNewTab: false,
                      }}
                      icon={faPrint}
                    />
                  )}
                  {transaction.canVoid && !transaction.isVoid && (
                    <VoidModal transaction={transaction} />
                  )}
                </Flex.Row>
              </Flex.Col>
            </Flex.Row>
            {transaction.products?.map((x) => (
              <>
                <Divider />
                <Flex.Col>
                  <Flex.Row>
                    {x.replacedProductName && x.replacedProductCode ? (
                      <Flex.Col grow={5} fill>
                        <Flex.Row>
                          <Label basic horizontal>
                            {x.name}
                          </Label>
                          {`(${x.replacedProductCode}) - ${x.replacedProductName}`}
                        </Flex.Row>
                      </Flex.Col>
                    ) : (
                      <Flex.Col grow={5} fill>
                        <div>
                          {`(${x.code}) - ${x.name}`}{' '}
                          {x.wasAutoRenewed && (
                            <Label basic horizontal className="product-labels">
                              AUTO-RENEW
                            </Label>
                          )}
                          {x.wasEarlyRenewal && (
                            <Label basic horizontal className="product-labels">
                              EARLY RENEWAL
                            </Label>
                          )}
                        </div>
                      </Flex.Col>
                    )}

                    <Flex.Col grow={3}>
                      {`Expires `}
                      <DateFormat date={x.licenseValidEndDate} />
                    </Flex.Col>
                    <Flex.Col>{`$${x.productFeeTotal.toFixed(2)}`}</Flex.Col>
                  </Flex.Row>
                </Flex.Col>
              </>
            ))}
            <Divider />
          </Flex.Col>
          <StyledButton
            primary
            type="button"
            onClick={() => {
              history.push(routes.vendor.root, {catalogState: {customer}});
            }}
          >
            Back to Dashboard
          </StyledButton>
        </BasicPage>
      )}
    </>
  );
};

type VoidModal = {
  transaction: PastTransactionDto;
};

const VoidModal: React.FC<VoidModal> = ({transaction}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const voidTransaction = async (values: VoidTransactionDto) => {
    const response = await VendorTransactionService.voidTransaction({
      body: {
        transactionId: transaction.id,
        voidTransactionReason: values.voidTransactionReason,
      },
    });

    if (!response.hasErrors && response.result) {
      notifications.success('Transaction successfully voided.');
      history.push(routes.vendor.root);
    } else {
      notifications.error('There was a problem voiding this transaction.');
      return response;
    }
  };

  const fields = getVoidTransactionFields();

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      trigger={
        <StyledButton size="md" negative>
          <FontAwesomeIcon icon={faBan} />
          {` Void`}
        </StyledButton>
      }
      css={styles}
    >
      <Modal.Header>
        Are you sure you want to void this transaction?
      </Modal.Header>
      <Form
        onSubmit={voidTransaction}
        autoComplete="off"
        render={() => (
          <>
            <Modal.Content>
              <Modal.Description>
                {transaction.hasEarlyRenewedProducts && (
                  <section className="early-renewal-void-warning-container">
                    <Message warning>
                      This transaction includes licenses that were renewed
                      early. If this transaction is voided, the previous license
                      that was renewed early will become active with the
                      original expiration date.
                    </Message>
                  </section>
                )}
                <Typography variant="body1">
                  <Flex.Col className="void-transaction-modal-info">
                    <Flex.Row justifyContent="space-between">
                      <Typography
                        variant="body1"
                        fontWeight="500"
                      >{`LDWF # `}</Typography>
                      <Flex.Box>{transaction.sportsmanId}</Flex.Box>
                    </Flex.Row>
                    <Flex.Row justifyContent="space-between">
                      <Typography
                        variant="body1"
                        fontWeight="500"
                      >{`Name `}</Typography>
                      <Flex.Box>{transaction.customerName}</Flex.Box>
                    </Flex.Row>
                    <Flex.Row justifyContent="space-between">
                      <Typography
                        variant="body1"
                        fontWeight="500"
                      >{`Transaction #`}</Typography>
                      <Flex.Box>{transaction.transactionNumber}</Flex.Box>
                    </Flex.Row>
                    <Flex.Row justifyContent="space-between">
                      <Typography
                        variant="body1"
                        fontWeight="500"
                      >{`Privileges  `}</Typography>
                      <Flex.Col>
                        {transaction.products?.map((product) => (
                          <Flex.Row justifyContent="flex-end">{`(${product.code}) ${product.name}`}</Flex.Row>
                        ))}
                      </Flex.Col>
                    </Flex.Row>
                  </Flex.Col>
                </Typography>

                <FormFields fields={fields} />
              </Modal.Description>
            </Modal.Content>
            <Divider />
            <Modal.Actions className="void-transaction-modal-actions">
              <StyledButton onClick={() => setOpen(false)}>Cancel</StyledButton>
              <StyledButton
                type="submit"
                content="Void"
                labelPosition="left"
                icon="ban"
                negative
              />
            </Modal.Actions>
          </>
        )}
      />
    </Modal>
  );
};

const FormFields: React.FC<{
  fields: RawFieldConfig<VoidTransactionDto>;
}> = ({fields}) => {
  return (
    <>
      <Form.Section title="">
        <Form.Row>
          <Form.Dropdown fieldConfig={fields.voidTransactionReason} />
        </Form.Row>
      </Form.Section>
    </>
  );
};

const styles = css`
  .product-labels {
    display: inline-block;
  }

  .void-transaction-modal-actions {
    margin: 1em !important;
  }

  .early-renewal-void-warning-container {
    padding: 1em;
  }

  .void-transaction-modal-info {
    margin: 1em 50% 0 1em;
  }
`;
