import React from 'react';
import {Divider} from 'semantic-ui-react';
import {resourceRoutes} from '../../internet-sales-portal/routes/config';
import {StyledButton} from '../../styled-components/styled-buttons';

export const RolpRedirectInfo: React.FC = () => {
  return (
    <div className="box-description">
      <h3>Recreational Offshore Landing Permit (ROLP)</h3>
      <label>
        To possess tunas, billfish, swordfish, amberjacks, snappers, groupers,
        hinds, cobia, wahoo, dolphinfish and gray triggerfish in Louisiana
        waters, recreational fishermen (except those under age 18 and those on a
        paid for-hire charter trip) must obtain a Recreational Offshore Landing
        Permit (ROLP), in addition to required licenses. To obtain or renew your
        ROLP, please click the button below.
      </label>
      <Divider hidden />
      <a
        href={resourceRoutes.rolpLogin}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledButton content="Get ROLP" primary />
      </a>
    </div>
  );
};
