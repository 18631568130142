import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import * as Common from '@microsoft/applicationinsights-common';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from 'history';
import {Env} from '../config/env-vars';

const browserHistory = createBrowserHistory({basename: ''});
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    distributedTracingMode: Common.DistributedTracingModes.W3C,
    instrumentationKey: Env.applicationInsightsKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory},
    },
  },
});

if (Env.isProductionBuild) {
  ai.loadAppInsights();

  ai.addTelemetryInitializer((envelope: any) => {
    envelope.tags['env'] = Env.name;
    envelope.tags['build'] = Env.buildNumber;
  });
}

export const trackPageView = (pageView?: Common.IPageViewTelemetry) => {
  if (!Env.isProductionBuild) return;

  return ai.trackPageView(pageView);
};

export const setUser = ({id}: {id?: string}) => {
  if (!Env.isProductionBuild) return;

  if (id) {
    ai.setAuthenticatedUserContext(id);
  } else {
    ai.clearAuthenticatedUserContext();
  }
};
