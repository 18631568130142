import css from '@emotion/css/macro';
import React from 'react';
import {useField} from 'react-final-form';
import {CountryCode} from '../api/generated/enums';
import {Form} from '../forms';
import {AddressCreateDto} from '../forms/address-fields';
import {RawFieldConfig} from '../forms/schema-utils';
import {StyledButton} from '../styled-components/styled-buttons';
import {Flex} from './flex';
import {PreviousAddressModal} from './previous-address-modal';

type addressFormConfig = {
  sectionTitle: string;
  addressConfig: RawFieldConfig<AddressCreateDto>;
  hasCountyField?: boolean;
  dontRenderCountryField?: boolean;
  copyHandler?: () => void;
};

type styledAddressFormConfig = {
  sectionTitle?: string;
  addressConfig: RawFieldConfig<AddressCreateDto>;
  hasCountyField?: boolean;
  dontRenderCountryField?: boolean;
  copyHandler?: () => void;
  showPreviousAddress?: boolean;
  customerId?: number;
};

export const AddressForm = (config: addressFormConfig) => {
  const field = useField(config.addressConfig.countryCode!.fieldName);
  const isInternational =
    field.input.value !== CountryCode['UNITED STATES'] &&
    field.input.value !== '';
  config.addressConfig.internationalPostalCode!.fieldRequired = true;
  return (
    <Form.Section
      title={
        <Flex.Fill>
          <Flex.Row vAlign="center" css={styles}>
            <div>{config.sectionTitle}</div>
            {config.copyHandler ? (
              <StyledButton type="button" primary onClick={config.copyHandler}>
                Copy Address
              </StyledButton>
            ) : undefined}
          </Flex.Row>
        </Flex.Fill>
      }
    >
      <Form.Row proportions={[3, 1]}>
        <Form.Input fieldConfig={config.addressConfig.street1} />
      </Form.Row>
      <Form.Row proportions={[3, 1]}>
        <Form.Input fieldConfig={config.addressConfig.street2} />
      </Form.Row>
      <Form.Row proportions={[1.5, 0.75, 0.75, 1]}>
        <Form.Input fieldConfig={config.addressConfig.city} />
        {isInternational ? (
          <Form.Input
            fieldConfig={config.addressConfig.internationalPostalCode}
          />
        ) : (
          <>
            <Form.Dropdown fieldConfig={config.addressConfig.stateCode} />
            <Form.InputMasked fieldConfig={config.addressConfig.zipCode} />
          </>
        )}
        {!config.dontRenderCountryField && (
          <Form.Dropdown fieldConfig={config.addressConfig.countryCode} />
        )}
      </Form.Row>
      {config.hasCountyField && (
        <Form.Row proportions={[3, 5]}>
          <Form.Dropdown fieldConfig={config.addressConfig.countyCodeId} />
        </Form.Row>
      )}
    </Form.Section>
  );
};

export const StyledAddressForm = (config: styledAddressFormConfig) => {
  config.addressConfig.internationalPostalCode!.fieldRequired = true;
  return (
    <div>
      {config.sectionTitle ? (
        <Form.Section
          title={
            <Flex.Fill css={styles}>
              <Flex.Row vAlign="center" className="title-row">
                <div>{config.sectionTitle}</div>
                {config.copyHandler ? (
                  <StyledButton
                    size="sm"
                    type="button"
                    primary
                    onClick={config.copyHandler}
                  >
                    Copy Address
                  </StyledButton>
                ) : undefined}
                {config.showPreviousAddress && (
                  <PreviousAddressModal customerId={config.customerId} />
                )}
              </Flex.Row>
            </Flex.Fill>
          }
        >
          <AddressFields {...config} />
        </Form.Section>
      ) : (
        <AddressFields {...config} />
      )}
    </div>
  );
};

const AddressFields = (config: styledAddressFormConfig) => {
  const field = useField(config.addressConfig.countryCode!.fieldName);
  return (
    <>
      <Form.Row proportions={[]}>
        <Form.Input fieldConfig={config.addressConfig.street1} />
      </Form.Row>
      <Form.Row proportions={[]}>
        <Form.Input fieldConfig={config.addressConfig.street2} />
      </Form.Row>
      <Form.Row proportions={[]}>
        <Form.Input fieldConfig={config.addressConfig.city} />
        <Form.Dropdown fieldConfig={config.addressConfig.stateCode} />
      </Form.Row>
      <Form.Row proportions={[]}>
        {field.input.value === CountryCode['UNITED STATES'] ||
        field.input.value === '' ? (
          <Form.InputMasked fieldConfig={config.addressConfig.zipCode} />
        ) : (
          <Form.Input
            fieldConfig={config.addressConfig.internationalPostalCode}
          />
        )}
        {!config.dontRenderCountryField && (
          <Form.Dropdown fieldConfig={config.addressConfig.countryCode} />
        )}
      </Form.Row>
      {config.hasCountyField && (
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown fieldConfig={config.addressConfig.countyCodeId} />
        </Form.Row>
      )}
    </>
  );
};

const styles = css`
  .title-row {
    height: 1.2em;

    button {
      margin-left: 12px;
    }
  }
`;
