import React from 'react';
import {CustomerMailingAddressDto} from '../../api/generated';
import {Form} from '../../forms';
import {RawFieldConfig} from '../../forms/schema-utils';

export const MailingAddressConfirmationFormFields: React.FC<{
  fields: RawFieldConfig<CustomerMailingAddressDto>;
}> = ({fields}) => {
  return (
    <>
      <Form.Row proportions={[1]}>
        <Form.Input fieldConfig={fields.street1} />
      </Form.Row>
      <Form.Row proportions={[1]}>
        <Form.Input fieldConfig={fields.street2} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.city} />
        <Form.Dropdown fieldConfig={fields.stateCode} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.InputMasked fieldConfig={fields.zipCode} />
        <Form.Dropdown fieldConfig={fields.countryCode} />
      </Form.Row>
    </>
  );
};
