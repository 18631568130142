import css from '@emotion/css/macro';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {AccountService, ForgotPasswordCommand, UserDto} from '../api/generated';
import logo from '../assets/ldwf-logo-3c-2018-small.png';
import {Form} from '../forms';
import {fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {useProduce} from '../hooks/use-produce';
import {ValidationError} from '../types/index';
import {useNotification} from '../hooks/use-notifications';
import {
  Divider,
  Message,
  Grid,
  Container,
  Header,
  Image,
} from 'semantic-ui-react';

const fields = fieldConfig<ForgotPasswordCommand>({
  username: input({
    fieldLabel: 'Username',
  }),
});

const defaultFormState = getDefaults(fields);

type AuthState = {
  user: UserDto | null;
  errors: ValidationError[];
};

const INITIAL_STATE: AuthState = {
  user: null,
  errors: [],
};

export const ForgotPassword = (props: any) => {
  const [state] = useProduce<AuthState>(INITIAL_STATE);
  const notifications = useNotification();
  const history = useHistory();

  const onSubmit = async (values) => {
    const response = await AccountService.forgotPassword({
      body: values,
    });

    if (response.hasErrors) {
      notifications.error('Forgot Password request has failed.');
      return response;
    }

    notifications.success(
      'Success! Please check your email for a link to reset your password.'
    );

    history.goBack();
  };

  return (
    <Grid css={styles} verticalAlign="middle">
      <Grid.Column>
        <Container text>
          {state.errors.length > 0 && (
            <Message negative>
              {state.errors.map((x) => (
                <p key={x.propertyName}>{x.errorMessage}</p>
              ))}
            </Message>
          )}
          <Form.Container>
            <Image src={logo} className="logo" />
            <Form
              initialValues={defaultFormState}
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Section
                    title={
                      <>
                        Louisiana Department of Wildlife & Fisheries
                        <Header.Subheader>Forgot Password?</Header.Subheader>
                      </>
                    }
                  >
                    <Form.Input fieldConfig={fields.username} />
                  </Form.Section>

                  <Divider />
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Submit
                    </Form.Button>
                  </div>
                </>
              )}
            ></Form>
          </Form.Container>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;

  .logo {
    margin: -100px auto 25px;
  }
`;
