import css from '@emotion/css/macro';
import React, {useEffect, useMemo, useRef} from 'react';
import {useField} from 'react-final-form';
import {Dropdown, FormField} from 'semantic-ui-react';
import {SubmitError} from '../forms/submit-error';
import ReactQuill, {Quill} from 'react-quill';
import {FieldConfig} from '../forms/schema-utils';
import {DropdownOption} from '../api/generated/utils';
import 'react-quill/dist/quill.snow.css';
import {sanitizeFunction} from '../utils/react-quill-helper';
import {useMedia} from 'react-use';
import {MediaSizes} from '../styles/breakpoints';

const CustomReactQuillLink = Quill.import('formats/link');
CustomReactQuillLink.sanitize = sanitizeFunction;
Quill.register(CustomReactQuillLink, true);

export const mobileToolbar = [
  [{header: [1, 2, 3, false]}],
  ['bold', 'italic', 'underline'],
  [{list: 'ordered'}, {list: 'bullet'}],
];

type HtmlInput = {
  fieldConfig?: FieldConfig;
  defaultValue?: string;
  readOnly?: boolean;
  showTokenDropDown?: boolean;
  onChange: any;
  height?: string;
  maxWidth?: string;
  enableMobileToolbar?: boolean;
  tokenDropDownOptions?: DropdownOption[];
};

export const HtmlTextInput: React.FC<HtmlInput> = ({
  fieldConfig,
  defaultValue,
  readOnly = false,
  showTokenDropDown = false,
  onChange,
  height,
  maxWidth,
  enableMobileToolbar,
  tokenDropDownOptions,
}) => {
  fieldConfig = fieldConfig ?? {fieldName: ''};
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);
  const quillRef = useRef<ReactQuill>(null);
  const {input, meta} = useField(fieldConfig.fieldName);
  const {value} = input;

  const moduleOptions = useMemo(() => {
    if (readOnly) {
      return {
        toolbar: false,
        clipboard: {
          matchVisual: false,
        },
      };
    }

    if (isMobile && enableMobileToolbar) {
      return {
        toolbar: mobileToolbar,
        clipboard: {
          matchVisual: false,
        },
      };
    }

    return {
      toolbar: true,
      clipboard: {
        matchVisual: false,
      },
    };
  }, [enableMobileToolbar, isMobile, readOnly]);

  const insertToken = (
    content: string | undefined,
    quillRef: React.MutableRefObject<ReactQuill | null>
  ) => {
    if (content && quillRef && quillRef.current) {
      //These are enums we control and are not user managed. This turns the Title case convention into camel case
      //It then adds braces for formatting the token before inserting into the current position of the user's caret
      const editor = quillRef!.current.getEditor();
      const camelCasedSelection =
        content.substr(0, 1).toLowerCase() + content.substr(1);
      const caretPosition = editor.getSelection(true);

      editor.insertText(
        caretPosition?.index ?? 0,
        `{{${camelCasedSelection}}}`
      );
    }
  };

  useEffect(() => {
    //This will get the current toolbar and attach the insertToken handler via module
    //see https://quilljs.com/docs/modules/toolbar/#handlers
    const toolbar = quillRef?.current?.getEditor()?.getModule('toolbar');
    if (toolbar && !readOnly) {
      toolbar.addHandler('insertToken', insertToken);
    }
  }, [quillRef, readOnly]);

  return (
    <div className="form-field" css={styles(height, maxWidth)}>
      <FormField error={!!meta.submitError}>
        {fieldConfig.fieldLabel && (
          <span className="css-1oiibla-indicatorStyles">
            <label htmlFor={input.name}>
              {fieldConfig.fieldLabel}
              {fieldConfig.fieldRequired && (
                <label className="required-indicator">*</label>
              )}
            </label>
          </span>
        )}
        {showTokenDropDown && !readOnly && (
          <div className="token-dropdown-container">
            <Dropdown
              className={'insert-token-dropdown'}
              scrolling
              button
              pointing
              selectOnBlur={false}
              onChange={(e, {value}) => {
                insertToken(value?.toString(), quillRef);
              }}
              text={'Insert Token'}
              options={tokenDropDownOptions}
              value={''} //Allows us to insert the same token twice without selecting a different one
            />
          </div>
        )}
        <ReactQuill
          readOnly={readOnly}
          defaultValue={defaultValue}
          className={`html-editor`}
          value={value}
          modules={moduleOptions}
          onChange={onChange}
          ref={quillRef}
        />
      </FormField>
      <SubmitError name={input.name} />
    </div>
  );
};

const styles = (height?: string, maxWidth?: string) => css`
  .html-editor {
    z-index: 0;
    ${height ? `height: ${height}` : ''};
    ${maxWidth ? `max-width: ${maxWidth}` : ''};

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ql-toolbar {
      height: 42.125px;
    }

    .ql-container {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    select {
      padding: 0.2rem 1.5rem 0.2rem 0.4rem;
      border-radius: 0;
      box-shadow: none;
      background: inherit;
    }
  }
  .public-DraftEditor-content {
    min-height: 100px;
  }

  .token-dropdown-container {
    margin-bottom: -40px;
    margin-right: 5px;
    text-align: right;
    background-color: transparent;
  }
`;
