export type FilterProps = {
  eventTypeId?: number;
  classTypeId?: number;
  countyCodeId?: number;
  daysIntoTheFuture?: number;
};

export const defaultFilter: FilterProps = {
  eventTypeId: undefined,
  classTypeId: undefined,
  countyCodeId: undefined,
  daysIntoTheFuture: undefined,
};

export const covidWarningMessage = `
  Students or other attendees are not to attend class if they have any symptoms of COVID-19 or have been in contact with someone that has COVID-19 in the last 14 days.
`;

export const eventsRegistrationWarningMessage = `
  In order to register for any events, you must login with your LDWF account.
`;

export enum CertificationTypeCodes {
  HunterEducation = 'HE',
  BoaterEducation = 'BE',
  BowHunterEductation = 'BOW',
}

export const EmailLimits = {
  instructorEmailLimit: 1200,
};
