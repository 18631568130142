import {FORM_ERROR} from 'final-form';
import {ApiResult} from '../api/generated';

import {
  camelCase,
  isPlainObject,
  isLength,
  keys,
  merge,
  reduce,
  set,
  toPath,
} from 'lodash';

const flattenKeys = (obj, path: string[] = []) =>
  !isPlainObject(obj)
    ? {[path.join('.')]: obj}
    : reduce(
        obj,
        (acc, next, key) => merge(acc, flattenKeys(next, [...path, key])),
        {}
      );

export const getNestedKeys = (object) => {
  return keys(flattenKeys(object)).map((x) => x || FORM_ERROR);
};

export const mapErrors = (response: ApiResult<any>, fields: string[] = []) => {
  const fieldMap = new Set(fields.map((x) => normalizePath(x)));

  return response.validationFailures?.reduce((errors, error) => {
    const propertyName = normalizePath(error.propertyName);
    const errorPath = toPath(propertyName);
    const errorMessage = error.errorMessage?.replace(/'/gi, '');

    return fieldMap.has(propertyName)
      ? set(errors, errorPath, errorMessage)
      : set(errors, ['_UNKNOWN_', ...errorPath], errorMessage);
  }, {});
};

const normalizePath = (path) =>
  toPath(path)
    .map((part, index, parts) => {
      const nextIndex = index + 1;
      const hasMore = nextIndex < parts.length;
      const nextIsIndex = hasMore && isIndex(parts[nextIndex]);
      const normalizedPart = isIndex(part) ? `[${part}]` : camelCase(part);
      return `${normalizedPart}${nextIsIndex || !hasMore ? '' : '.'}`;
    })
    .join('');

const isIndex = (value) => isLength(Number(value));

export const getErrorMessages = (response: ApiResult<any>) =>
  response.validationFailures?.map((error) =>
    error.errorMessage?.replace(/'/gi, '')
  );
