export const vendorRoutes = {
  root: `/vendor`,
  licensesAndPermits: `/vendor/licenses-and-permits`,
  purchaseConfirmation: '/vendor/purchase-confirmation',
  salesReceipt: '/vendor/sales-receipt',
  hipSurvey: '/vendor/hip-survey',
  mailingAddressConfirmation:'/vendor/mailing-address-confirmation',
  changePassword: '/vendor/change-password',
  createCustomer: '/vendor/create-customer',
  vendorUser: {
    create: `/vendor/vendor-user/create`,
    detail: `/vendor/vendor-user/:id`,
  },
  issueDuplicates: '/vendor/issue-duplicates',
  transactionDetails: '/vendor/transaction-details',
  customers: {
    profile: `/vendor/customers/:id/profile`,
  },
  corporateReport: `/vendor/corporate-report`,
};
