import {DropdownItemProps} from 'semantic-ui-react';

export const populateDropdownWithNumberRange = (min: number, max: number) => {
  const dropdownItems: DropdownItemProps[] = [];

  for (let i = min; i < max + 1; i++) {
    dropdownItems.push({text: i.toString(), value: i});
  }

  return dropdownItems;
};
