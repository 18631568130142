import React from 'react';
import {Container, Header, List, Modal} from 'semantic-ui-react';
import {css} from '@emotion/core';
import {StyledButton} from '../../styled-components/styled-buttons';
import {DisplayLotteryItemDto, IspCustomersService} from '../../api/generated';
import {useAsync} from 'react-use';
import {notifications} from '../../utils/notification-service';
import {Media} from '../../styles/breakpoints';
import {
  isBlackBearLotteryWithBearLicenseRequirement,
  isGeneralBlackBearLottery,
  isLandOwnerBlackBearLottery,
  isWmaBlackBearLottery,
} from './lottery-helpers';
import {customerRoutes} from '../routes/config';

interface BlackBearLotteryInfoModalProps {
  blackBearLotteryInfoModalOpen: boolean;
  isLeftover: boolean;
  blackBearLottery: DisplayLotteryItemDto;
  openBlackBearLotteryInfoModal: () => void;
  closeBlackBearLotteryInfoModal: () => void;
  handleOnLotteryApply: (
    blackBearLottery: DisplayLotteryItemDto,
    isLeftovers: boolean
  ) => void;
}

export const BlackBearLotteryInfoModal: React.FC<BlackBearLotteryInfoModalProps> = ({
  blackBearLotteryInfoModalOpen,
  isLeftover,
  blackBearLottery,
  openBlackBearLotteryInfoModal,
  closeBlackBearLotteryInfoModal,
  handleOnLotteryApply,
}) => {
  const isLotteryWmaBlackBear = isWmaBlackBearLottery(
    blackBearLottery.preferencePointType?.lotteryCode || ''
  );

  const isLotteryLandOwnerBlackBear = isLandOwnerBlackBearLottery(
    blackBearLottery.preferencePointType?.lotteryCode || ''
  );

  const isLotteryGeneralBlackBear = isGeneralBlackBearLottery(
    blackBearLottery.preferencePointType?.lotteryCode || ''
  );

  const isBlackBearHuntingLicenseRequired = isBlackBearLotteryWithBearLicenseRequirement(
    blackBearLottery.preferencePointType?.lotteryCode || ''
  );

  const fetchCustomerBlackBearLicenseInfo = useAsync(async () => {
    if (blackBearLotteryInfoModalOpen && isBlackBearHuntingLicenseRequired) {
      var response = await IspCustomersService.getIsBlackBearLicenseHolder();
      if (response.hasErrors) {
        notifications.error(
          'Unable to fetch your black bear license information.'
        );
        return false;
      }
      return response.result;
    }
  }, [blackBearLotteryInfoModalOpen, isBlackBearHuntingLicenseRequired]);

  const customerHasBlackBearHuntingLicense =
    fetchCustomerBlackBearLicenseInfo.value;

  const isContinueButtonDisabled =
    isBlackBearHuntingLicenseRequired && !customerHasBlackBearHuntingLicense;

  const createAdditionalInformation = (): JSX.Element => {
    if (isLotteryWmaBlackBear) {
      return (
        <>
          <List size="medium" bulleted>
            <List.Item>
              To participate in the WMA black bear lottery, applications must be
              submitted during the open lottery application period.
            </List.Item>
            <List.Item>
              Possession of a valid black bear hunting license is required
              before applying for the WMA black bear lottery.
            </List.Item>
            <List.Item>
              Submission of more than one WMA black bear lottery application is
              not allowed. Paper applications are not accepted.
            </List.Item>
            <List.Item>
              The successful applicant will receive a single WMA bear harvest
              permit that will authorize bear hunting on either Bayou Macon, Big
              Lake, Buckhorn wildlife management areas during December 7-22,
              2024.
            </List.Item>
            <List.Item>
              All applicants will be notified of their results by the email
              provided. An applicant’s results can also be viewed{' '}
              <strong>
                <a
                  href={customerRoutes.lotteryApplications}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </strong>
              .
            </List.Item>
            <List.Item>
              All hunters must comply with licensing requirements.
            </List.Item>
          </List>
        </>
      );
    } else if (isLotteryLandOwnerBlackBear) {
      return (
        <>
          <List size="medium" bulleted>
            <List.Item>
              To participate in the private landowner black bear lottery,
              applications must be submitted during the open lottery application
              period.
            </List.Item>
            <List.Item>
              Submission of more than one private landowner black bear lottery
              application is not allowed. Paper applications are not accepted.
            </List.Item>
            <List.Item>
              The successful applicants will receive a bear harvest permit that
              will authorize bear hunting to qualifying private landowners,
              their designee, or lessee in Bear Area 4 during December 7-22,
              2024. To qualify for a Private Landowner Permit, applicants must
              provide evidence of qualifying acreage (40+ contiguous acres),
              proof of ownership, and payment of property taxes for property
              within the Bear Area for which they apply. Landowner designees
              must provide evidence deemed sufficient by the department that
              they have full agency and authority to act on behalf of a
              qualifying private landowner. The department shall be notified
              once a permit has been transferred and provided the transferee's
              name, address, and phone number. Transferees must be a bona fide
              Louisiana resident who is properly licensed and hunter safety
              certified. Only the private landowner permits are transferrable.
              Permits being transferred must be arranged prior to the mandatory
              training session. A permit may only be transferred one time.
              Lessees of qualifying private lands must provide a duly executed
              and properly recorded lease agreement evidencing they have the
              right to hunt bear upon the qualifying acreage.
            </List.Item>
            <List.Item>
              Lottery is open to qualifying non-residents. However, they cannot
              hunt if selected. They must transfer their permit to a resident
              hunter.
            </List.Item>
            <List.Item>
              Only the private landowner permits are transferrable. Transferees
              must be a bona fide Louisiana resident who is properly licensed
              and hunter safety certified.
            </List.Item>
            <List.Item>
              All applicants will be notified of their results by the email
              provided. An applicant’s results can also be viewed{' '}
              <strong>
                <a
                  href={customerRoutes.lotteryApplications}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </strong>
              .
            </List.Item>
            <List.Item>
              All hunters must comply with licensing requirements.
            </List.Item>
          </List>
        </>
      );
    } else if (isLotteryGeneralBlackBear) {
      return (
        <>
          <List size="medium" bulleted>
            <List.Item>
              To participate in the general black bear lottery, applications
              must be submitted during the open lottery application period.
            </List.Item>
            <List.Item>
              Possession of a valid black bear hunting license is required prior
              to applying to the general black bear lottery.
            </List.Item>
            <List.Item>
              Submission of more than one general black bear lottery application
              is not allowed. Paper applications are not accepted.
            </List.Item>
            <List.Item>
              The successful applicant will receive a bear harvest permit that
              will authorize bear hunting on private property, with landowner
              permission, within Bear Area 4 during December 7-22, 2024.
              Individuals receiving a General Permit are responsible for
              coordinating with landowners to gain permission and access to
              hunting on their property.
            </List.Item>
            <List.Item>
              All applicants will be notified of their results by the email
              provided. An applicant’s results can also be viewed{' '}
              <strong>
                <a
                  href={customerRoutes.lotteryApplications}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </strong>
              .
            </List.Item>
            <List.Item>
              All hunters must comply with licensing requirements.
            </List.Item>
          </List>
        </>
      );
    } else {
      return <div>NO ADDITIONAL INFORMATION AVAILABLE</div>;
    }
  };

  return (
    <Modal
      css={blackBearLotteryInfoModalStyles}
      onClose={() => closeBlackBearLotteryInfoModal()}
      onOpen={() => openBlackBearLotteryInfoModal()}
      open={blackBearLotteryInfoModalOpen}
      size={'small'}
    >
      <Modal.Header>
        <Header as="h3">{blackBearLottery.name}</Header>
      </Modal.Header>

      <Modal.Content>
        <Modal.Description>
          <Header as="h4">Additional Information</Header>
          <Container>{createAdditionalInformation()}</Container>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton
          content="Back"
          onClick={() => closeBlackBearLotteryInfoModal()}
        />
        <StyledButton
          primary
          type="submit"
          disabled={isContinueButtonDisabled}
          content="Continue"
          onClick={() => handleOnLotteryApply(blackBearLottery, isLeftover)}
        />
      </Modal.Actions>
    </Modal>
  );
};

const blackBearLotteryInfoModalStyles = css`
  h3 {
    color: #1c3644 !important;
  }

  h4 {
    font-weight: 400;
    color: #51504e !important;
  }

  p {
    color: #51504e !important;
  }

  .content {
    padding: 0px 32px 16px 32px;
  }

  .container {
    border: solid 1px #d7d6d4;
    padding: 16px;
    justify-content: center;
  }

  .cards {
    ${Media('MobileMax')} {
      justify-content: center;
    }
  }
`;
