import _ from 'lodash';
import React, {useState} from 'react';
import {useLocation} from 'react-use';
import {
  AccountService,
  BooleanApiResult,
  CustomerDetailDtoApiResult,
  IspCustomersService,
  ResidencyCode,
  VendorCustomerService,
  CustomerAuthWithCustomerNumberDto,
} from '../../../api/generated';
import {SocialSecurityNumberStatus} from '../../../api/generated/enums';
import {notify} from '../../../hooks/use-subscription';
import {splitZipCodeFields} from '../../../utils/address-helpers';
import {verifyCustomer} from '../../../utils/auth-helpers';
import {notifications} from '../../../utils/notification-service';
import {CustomerCreate} from './customer-create-update-base';
import {CustomerUpdate} from './customer-create-update-base';
import {useLoginCookie} from '../../../hooks/use-login-cookie';
import {validateDateOfBirth} from './customer-create-update-utils';

type CustomerCreateType = {
  onCancel: () => void;
  completionHandler?: ({sportsmanId, dateOfBirth}) => void;
};

type CustomerUpdateType = {
  stepper?: boolean;
  customerId: Number;
  onCancel: () => void;
};

const SsnStatus = {
  true: SocialSecurityNumberStatus.EXEMPT,
  false: SocialSecurityNumberStatus['NON-EXEMPT'],
};

const residencyCodeOptions = {
  Yes: ResidencyCode.RESIDENT,
  No: ResidencyCode.NONRESIDENT,
};

export const CustomerCreateComponent: React.FC<CustomerCreateType> = (
  config
) => {
  const {pathname} = useLocation();
  const isVendor = pathname?.includes('vendor') ?? false;
  const cookies = useLoginCookie();

  const onSubmit = async (values) => {
    const valuesToSubmit = _.cloneDeep(values);
    valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
      ? SsnStatus.true
      : SsnStatus.false;

    delete valuesToSubmit.hasBirthCertificate;
    valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

    const dateOfBirthToSubmit = validateDateOfBirth(
      valuesToSubmit.dateOfBirth,
      'Create'
    );

    //Return any validation failures regarding the dateOfBirth field
    if (dateOfBirthToSubmit instanceof Object) {
      return dateOfBirthToSubmit;
    }

    valuesToSubmit.dateOfBirth = dateOfBirthToSubmit;

    splitZipCodeFields(valuesToSubmit);
    let response: CustomerDetailDtoApiResult;
    if (isVendor) {
      response = await VendorCustomerService.create({body: valuesToSubmit});
    } else {
      response = await IspCustomersService.create({body: valuesToSubmit});
    }

    if (response.hasErrors) {
      notifications.error('Customer Creation Failed');
      return response;
    }
    notifications.success('Customer Created');
    var valuesForCustomerLogin: CustomerAuthWithCustomerNumberDto = {
      sportsmanId: response.result!.sportsmanId,
      dateOfBirth: response.result!.dateOfBirth,
    };

    if (config.completionHandler) {
      config.completionHandler({
        sportsmanId: valuesForCustomerLogin.sportsmanId,
        dateOfBirth: valuesForCustomerLogin.dateOfBirth,
      });
    } else {
      await AccountService.customerLoginWithCustomerNumber({
        body: valuesForCustomerLogin,
      });
      verifyCustomer();
      cookies.setCurrentLoginCookie();
    }
  };

  const createConfig = {
    ...config,
    onSubmit: onSubmit,
  };

  return <CustomerCreate {...createConfig} />;
};

export const CustomerUpdateComponent: React.FC<CustomerUpdateType> = (
  config
) => {
  const {pathname} = useLocation();
  const isVendor = pathname?.includes('vendor') ?? false;
  const [didSubmit, setDidSubmit] = useState(false);

  const onSubmit = async (values) => {
    const valuesToSubmit = _.cloneDeep(values);

    const dateOfBirthToSubmit = validateDateOfBirth(
      valuesToSubmit.dateOfBirth,
      'Update'
    );

    //Return any validation failures regarding the dateOfBirth field
    if (dateOfBirthToSubmit instanceof Object) {
      return dateOfBirthToSubmit;
    }

    valuesToSubmit.dateOfBirth = dateOfBirthToSubmit;

    splitZipCodeFields(valuesToSubmit);

    valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
      ? SsnStatus.true
      : SsnStatus.false;
    valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

    let response: BooleanApiResult;
    if (isVendor) {
      response = await VendorCustomerService.update({body: valuesToSubmit});
    } else {
      response = await IspCustomersService.update({body: valuesToSubmit});
    }

    if (response.hasErrors) {
      notifications.error(
        'Update Failed. Ensure all required information has been provided'
      );
      if (!didSubmit) {
        setDidSubmit(true);
      }
      return response;
    }

    notifications.success('Information updated successfully');

    notify('customer-updated', undefined);
    notify('refresh-session', undefined);

    verifyCustomer();
  };
  const updateConfig = {
    ...config,
    onSubmit: onSubmit,
  };

  return <CustomerUpdate {...updateConfig} />;
};
