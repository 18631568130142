import ReachTooltip from '@reach/tooltip';
import React from 'react';
import css from '@emotion/css/macro';

type Tooltip = {
  label?: string;
  className?: string;
};

export const Tooltip: React.FC<Tooltip> = ({label, className, children}) =>
  label ? (
    <ReachTooltip label={label} css={styles} className={className}>
      <span>{children}</span>
    </ReachTooltip>
  ) : (
    <span>{children}</span>
  );

const styles = css`
  margin-top: 0.4rem;
`;
