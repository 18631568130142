import React, {ReactNode} from 'react';
import {Flex} from '../components/flex';
import {NavigationLink} from './navigation-link';
import {Container, Menu, Label} from 'semantic-ui-react';
import {buildPath} from '../routes/config';
import css from '@emotion/css/macro';
import {Route, Switch} from 'react-router-dom';
import {PageTitle} from './page-title';

type navRoute = {
  name: string;
  route: string;
  render: () => ReactNode;
  badgeCount?: number;
};

type navConfigType = {
  header: string;
  backRouteName: string;
  backRoute: string;
  id: number | undefined;
  routes: navRoute[];
};

export function navConfig(config: navConfigType) {
  return config;
}

type navParams = {config: navConfigType};

export const SecondaryNav = ({config}: navParams) => {
  const buildRoute = (route) => {
    return buildPath(route, {
      id: Number(config.id),
    });
  };

  const header = config.header;
  const breadcrumbs = [
    {title: config.backRouteName, url: config.backRoute},
    {title: header},
  ];

  const styles = css`
    .header,
    .page-title {
      margin-top: 10px !important;
      :not(.form-section) {
        margin-left: 15%;
      }
    }

    .secondary-navigation {
      background-color: #fff;
      padding: 6px 0;
      margin: auto;
      margin-bottom: -20px;

      .item {
        height: 48px;
      }

      .ui.container {
        @media only screen and (max-width: 990px) {
          margin-left: 25px !important;
          margin-right: 25px !important;
        }
      }
    }
  `;

  return (
    <Flex.Col css={styles} container>
      {config.backRoute && (
        <Flex.Box>
          <PageTitle title={breadcrumbs} />
        </Flex.Box>
      )}
      <Flex.Box className="secondary-navigation">
        <Container fluid>
          <Menu pointing>
            {config.routes.map((tab) => (
              <NavigationLink key={tab.route} to={buildRoute(tab.route)}>
                {tab.name}{' '}
                {tab.badgeCount !== undefined && tab.badgeCount !== 0 && (
                  <Label color="grey">{tab.badgeCount}</Label>
                )}
              </NavigationLink>
            ))}
          </Menu>
        </Container>
      </Flex.Box>
      <Switch>
        {config.routes.map((tab) => (
          // Query params break the routes path
          <Route key={tab.route} path={tab.route.split('?')[0]}>
            {tab.render}
          </Route>
        ))}
      </Switch>
    </Flex.Col>
  );
};
