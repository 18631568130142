import {useState, useEffect} from 'react';

export const useDelayedExpiration = ({isActive = false, delayInMs = 750}) => {
  const [timeoutExpired, setTimeoutExpired] = useState(false);

  useEffect(() => {
    let id;

    if (isActive) {
      id = setTimeout(() => {
        setTimeoutExpired(true);
      }, delayInMs);
    } else {
      setTimeoutExpired(false);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [delayInMs, isActive]);

  return timeoutExpired;
};
