import css from '@emotion/css/macro';
import {cx} from 'emotion';
import React from 'react';
import {Container} from 'semantic-ui-react';
import {PageTitle} from './components/page-title';
import {Media} from './styles/breakpoints';

export type TitlePart = {
  title: string;
  url?: string;
};

type Props = {
  title: string | TitlePart[];
  className?: string;
};

export const BasicPage: React.FC<Props> = React.forwardRef(
  ({title, children, className}, ref: any) => {
    return (
      <Container
        className={cx(className, 'basic-page')}
        ref={ref}
        css={styles}
        fluid
      >
        <div className="content">
          {title && <PageTitle title={title} fromSecondaryNav={true} />}
          {children}
        </div>
      </Container>
    );
  }
);

const styles = css`
  &.ui.container {
    max-width: 1380px !important;

    ${Media('MobileMax')} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  > .content {
    padding: 25px;

    ${Media('MobileMax')} {
      padding: 1.5rem 1rem 0;
    }
  }
`;
