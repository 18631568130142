import React, {useMemo} from 'react';
import {CustomerInformationDto} from '../../../api/generated';
import {
  checkbox,
  fieldConfig,
  getDefaults,
  input,
} from '../../../forms/schema-utils';
import {Divider} from 'semantic-ui-react';
import {OnChange} from 'react-final-form-listeners';
import {Form} from '../../../forms';

export type UpdateEmailFieldConfigDto = {
  emailAddress: string;
  isEmailVerified: boolean;
};

const useFields = (email: string) =>
  fieldConfig<UpdateEmailFieldConfigDto>({
    emailAddress: input({
      fieldLabel: 'Email Address',
      defaultValue: email,
      fieldRequired: true,
    }),
    isEmailVerified: checkbox({
      //Ensures the * displays next to checkbox
      fieldLabel: ' ',
      fieldRequired: true,
      defaultValue: false,
      inputProps: {
        label: 'I hereby declare this is my correct email address',
      },
    }),
  });

export interface UpdateEmailProps {
  customer: CustomerInformationDto | undefined;
  isEmailVerified: boolean;
  isPageDisabled: boolean;

  setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomerEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const UpdateEmail = ({
  customer,
  isEmailVerified,
  isPageDisabled,
  setIsEmailVerified,
  setCustomerEmail,
}: UpdateEmailProps) => {
  const fields = useFields(customer?.emailAddress ?? '');

  const initialValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  return (
    <>
      {customer && (
        <>
          <div className="box-description">
            <h3>ATTENTION</h3>
            <label>
              <strong>
                All communication related to your lottery application(s) will be
                sent to this address.
              </strong>{' '}
              If this is not your current email address, please use the form
              below to correct it. You may also update this address at any time
              in your customer profile.
            </label>
          </div>
          <Divider hidden />
          <Form
            initialValues={initialValues}
            className="inline"
            //Prevents Form component from throwing error when removed. Does not accept null
            onSubmit={() => {}}
            render={() => (
              <>
                {fields && (
                  <>
                    <Form.Row>
                      <Form.Input
                        fieldConfig={fields.emailAddress}
                        disabled={isPageDisabled}
                      />
                      <OnChange name="emailAddress">
                        {(value: string) => {
                          setCustomerEmail(value);
                          setIsEmailVerified(false);
                        }}
                      </OnChange>
                    </Form.Row>
                    <Form.Row>
                      <Form.Checkbox
                        fieldConfig={fields.isEmailVerified}
                        onClick={() => setIsEmailVerified(!isEmailVerified)}
                        checked={isEmailVerified}
                        disabled={isPageDisabled}
                      />
                    </Form.Row>
                  </>
                )}
              </>
            )}
          />
        </>
      )}
    </>
  );
};
