import React, {useCallback} from 'react';
import {Modal} from 'semantic-ui-react';
import {
  IspCustomersService,
  CustomerPreviousAddressDtoDataTablePageApiResult,
  CustomersService,
} from '../api/generated';
import {Producer, useProduce} from '../hooks/use-produce';
import {
  PagedDataTableConfig,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {DateFormat} from './date';
import {StyledButton} from '../styled-components/styled-buttons';

type PreviousAddressModalState = {
  isOpen: boolean;
  isModalLoading: boolean;
};

type FetchPreviousAddressParams = Parameters<
  typeof CustomersService.getTableOfPreviousAddresses
>['0'];

type AddPreviousAddressModal = {
  customerId?: number;
};

type TableConfig = {
  setModalState: (mutationFn: Producer<PreviousAddressModalState>) => void;
};

const TableConfig = () => {
  return PagedDataTableConfig(IspCustomersService.getTableOfPreviousAddresses, {
    initialPageSize: 10,
    columns: [
      {
        header: 'Address',
        render: (item) => <div>{item.previousAddress?.fullAddress}</div>,
      },
      {
        header: 'Date Changed',
        render: (item) => <DateFormat date={item.dateEdited} />,
      },
      {
        header: 'Address Type',
        render: (item) => <div>{item.addressType ? item.addressType : ''}</div>,
      },
    ],
    defaultSort: {
      column: 'id',
      direction: 'ASC',
    },
  });
};

export const PreviousAddressModal = (config: AddPreviousAddressModal) => {
  const [modalState, setModalState] = useProduce<PreviousAddressModalState>({
    isOpen: false,
    isModalLoading: false,
  });

  const handleCloseModal = () => {
    setModalState((draft) => {
      draft.isOpen = false;
    });
  };

  const fetchPreviousAddresses = useCallback(
    (x: CustomerPreviousAddressDtoDataTablePageApiResult) => {
      const fetchPreviousAddresses = IspCustomersService.getTableOfPreviousAddresses(
        {
          ...x,
          customerId: config.customerId,
        } as FetchPreviousAddressParams
      );

      fetchPreviousAddresses.then(() => {
        setModalState((draft) => {
          draft.isModalLoading = false;
        });
      });
      return fetchPreviousAddresses;
    },
    [config.customerId, setModalState]
  );

  const pagedDataTable = usePagedDataTable(
    fetchPreviousAddresses,
    TableConfig(),
    {renderSearch: () => <></>}
  );

  return (
    <Modal
      open={modalState.isOpen && !modalState.isModalLoading}
      trigger={
        <StyledButton
          size="sm"
          type="button"
          primary
          onClick={() =>
            setModalState((draft) => {
              draft.isOpen = true;
            })
          }
        >
          Prior Addresses
        </StyledButton>
      }
      onClose={handleCloseModal}
      size="small"
    >
      <Modal.Header>Previous Addresses</Modal.Header>
      <Modal.Content>{pagedDataTable}</Modal.Content>
      <Modal.Actions>
        <StyledButton
          primary
          type="button"
          onClick={() => setModalState((draft) => (draft.isOpen = false))}
        >
          Close
        </StyledButton>
      </Modal.Actions>
    </Modal>
  );
};
