import React, {useMemo} from 'react';
import {useAsync} from 'react-use';
import {
  CustomerCatalogService,
  VendorCatalogService,
} from '../../api/generated';
import {getOptionDtoDropdownOptions} from '../../api/generated/utils';
import {AsyncStateContainer} from '../../components/async-state-container';
import {
  INITIAL_STATE,
  ProductCatalogState,
} from '../../shared/product-catalog/product-catalog-utils';
import {notifications} from '../../utils/notification-service';
import {ProductCatalog} from './product-catalog-listing';

enum AdapterType {
  CUSTOMER = 'CUSTOMER',
  VENDOR = 'VENDOR',
}

const getShoppingCart = {
  [AdapterType.CUSTOMER]: CustomerCatalogService.getShoppingCart,
  [AdapterType.VENDOR]: VendorCatalogService.getShoppingCart,
};

const getCatalog = {
  [AdapterType.CUSTOMER]: CustomerCatalogService.get,
  [AdapterType.VENDOR]: VendorCatalogService.get,
};

type GetProductCatalogAsyncProps = {
  adapterType: AdapterType;
  customerId?: number;
};

type GetProductCatalogAsync = ({
  adapterType,
  customerId,
}: GetProductCatalogAsyncProps) => any;

const getProductCatalogAsync: GetProductCatalogAsync = async ({
  adapterType,
  customerId,
}) => {
  const BackendShoppingCart = await getShoppingCart[adapterType]({
    customerId: customerId,
  });
  const CatalogResponse = await getCatalog[adapterType]({
    customerId: customerId,
  });

  const backendCart = BackendShoppingCart?.result?.products ?? [];
  const prevCatalogState = {
    ...CatalogResponse,
    backendCart: backendCart,
  };

  if (CatalogResponse.hasErrors) {
    return prevCatalogState;
  }

  if (BackendShoppingCart.hasErrors) {
    notifications.error('Unable to Restore Shopping Cart');
    return prevCatalogState;
  }

  if (backendCart.length > 0) {
    return {
      ...CatalogResponse,
      total: BackendShoppingCart.result?.total,
      backendCart: backendCart,
    };
  }

  return prevCatalogState;
};

type CatalogAdapterProps = {
  catalogState?: ProductCatalogState;
  customerId?: number;
  render: (
    catalogState: ProductCatalogState
  ) => React.FunctionComponentElement<ProductCatalog>;
};

export const ProductCatalogAdapter: React.FC<CatalogAdapterProps> = ({
  catalogState,
  customerId,
  render,
}) => {
  const fetchCustomerCatalog = useAsync(async () => {
    if (customerId) {
      return await getProductCatalogAsync({
        adapterType: AdapterType.VENDOR,
        customerId,
      });
    } else {
      return await getProductCatalogAsync({adapterType: AdapterType.CUSTOMER});
    }
  }, [customerId]);

  const prevCatalogState = useMemo<ProductCatalogState>(() => {
    if (catalogState) {
      return catalogState;
    }

    if (fetchCustomerCatalog.value) {
      const {
        products,
        activeRevocations,
        displayMenu: displayMenuOptions,
        customerInformation: customer,
        previouslyPurchasedProducts: ownedProducts,
        convenienceFee,
        stateOfficeCreditCardFeePercent,
      } = fetchCustomerCatalog.value.result;

      const prevCatalogState: ProductCatalogState = {
        ...INITIAL_STATE,
        currentDisplayMenu: displayMenuOptions[0]
          ? displayMenuOptions[0].value
          : 0,
        displayMenuOptions: getOptionDtoDropdownOptions(displayMenuOptions),
        activeRevocations,
        ownedProducts,
        convenienceFee,
        stateOfficeCreditCardFeePercent,
        products,
        customer,
      };

      return prevCatalogState;
    } else return INITIAL_STATE;
  }, [catalogState, fetchCustomerCatalog.value]);

  return (
    <>
      <AsyncStateContainer {...fetchCustomerCatalog}>
        {fetchCustomerCatalog.value &&
          prevCatalogState &&
          render(prevCatalogState)}
      </AsyncStateContainer>
    </>
  );
};
