// Constants
import {
  BlackBearLotteryPreferencePointTypeCode,
  LotteryType,
} from './lottery-constants';

export const getSectionTitle = (lotteryType) => {
  let title = '';

  switch (lotteryType) {
    case LotteryType.available:
      title = 'Available Lotteries';

      break;
    case LotteryType.leftover:
      title = 'Leftovers';

      break;
    case LotteryType.unavailable:
      title = 'Other Lotteries';

      break;

    default:
      break;
  }

  return title;
};

export const getPreferencePointsCount = (preferencePoints, afterAward?) => {
  let points =
    preferencePoints === 0
      ? //The customer will get 2 on initial application
        '2'
      : preferencePoints;

  return afterAward && points - 1 > -1 ? points - 1 : points;
};

export const isWmaBlackBearLottery = (
  preferencePointTypeCode: string
): boolean => {
  return (
    BlackBearLotteryPreferencePointTypeCode.wmaBlackBearLottery ===
    preferencePointTypeCode
  );
};

export const isLandOwnerBlackBearLottery = (
  preferencePointTypeCode: string
): boolean => {
  return (
    BlackBearLotteryPreferencePointTypeCode.landOwnerBlackBearLottery ===
    preferencePointTypeCode
  );
};

export const isGeneralBlackBearLottery = (
  preferencePointTypeCode: string
): boolean => {
  return (
    BlackBearLotteryPreferencePointTypeCode.generalBlackBearLottery ===
    preferencePointTypeCode
  );
};

export const isBlackBearLottery = (
  preferencePointTypeCode: string
): boolean => {
  return (
    isWmaBlackBearLottery(preferencePointTypeCode) ||
    isLandOwnerBlackBearLottery(preferencePointTypeCode) ||
    isGeneralBlackBearLottery(preferencePointTypeCode)
  );
};

export const isBlackBearLotteryWithBearLicenseRequirement = (
  preferencePointTypeCode: string
): boolean => {
  return (
    isWmaBlackBearLottery(preferencePointTypeCode) ||
    isGeneralBlackBearLottery(preferencePointTypeCode)
  );
};
