import {buildPath} from '../routes/config';

export const buildIdRoute = (route: string, id?: number) => {
  return buildPath(route, {
    id: id ?? 0,
  });
};

export const buildStringRoute = (route: string, string?: string) => {
  return buildPath(route, {
    string: string ?? '',
  });
};

export function buildStringRouteWithState<T>(
  route: string,
  string: string | undefined,
  state: T
): [string, T] {
  return [buildStringRoute(route, string), state];
}

export function buildIdRouteWithState<T>(
  route: string,
  id: number,
  state: T
): [string, T] {
  return [buildIdRoute(route, id), state];
}
