import axios from 'axios';
import qs from 'qs';
import {serviceOptions} from './index';
import {Env} from '../../config/env-vars';

import {
  requestInterceptors,
  responseInterceptors,
  handleResponseError,
} from '../base-interceptors';

export const instance = axios.create({
  baseURL: Env.apiBaseUrl,
  paramsSerializer: (params) => qs.stringify(params, {allowDots: true}),
});

instance.interceptors.request.use(requestInterceptors, Promise.reject);
instance.interceptors.response.use(responseInterceptors, handleResponseError);

serviceOptions.axios = instance;
