import React, {useState} from 'react';
import {Grid, Container, Loader, List} from 'semantic-ui-react';
import {useAsync} from 'react-use';
import {AccountService, LotteryShoppingCartService} from '../api/generated';
import {notifications} from '../utils/notification-service';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {customerRoutes} from '../internet-sales-portal/routes/config';
import {routes} from '../routes/config';
import {useLoginCookie} from '../hooks/use-login-cookie';

export const OneTimeLoginLandingPageAcceptLottery = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const match = useRouteMatch<{
    token: string;
    lotteryCustomerApplicationId: string;
  }>();
  const cookies = useLoginCookie();

  useAsync(async () => {
    setIsLoading(true);

    const response = await AccountService.customerLoginWithToken({
      body: {secureToken: match.params.token},
    });

    if (response.hasErrors) {
      notifications.error(
        'This link is no longer valid. Please use one of the options below to log in.'
      );
      history.push(routes.customer.dashboard);
      return response;
    }

    cookies.setCurrentLoginCookie();
    const lotteryAwardResponse = await LotteryShoppingCartService.addAwardAcceptanceLink(
      {
        lotteryCustomerApplicationId: parseInt(
          match.params.lotteryCustomerApplicationId
        ),
      }
    );

    setIsLoading(false);
    if (lotteryAwardResponse.hasErrors) {
      lotteryAwardResponse.validationFailures?.forEach((validationFailure) =>
        notifications.error(validationFailure.errorMessage)
      );
      history.push(customerRoutes.lotteryApplications);
      return lotteryAwardResponse;
    }

    notifications.success('Successfully logged in.');
    history.push(customerRoutes.lotteryApplications);
  });

  return (
    <Grid verticalAlign="middle" columns={1}>
      <Grid.Column>
        <List horizontal>
          <Grid.Row>
            <Container text>Logging you in...</Container>
          </Grid.Row>
          <Grid.Row>
            <Loader active={isLoading}></Loader>
          </Grid.Row>
        </List>
      </Grid.Column>
    </Grid>
  );
};
