import React, {useMemo, useState} from 'react';
import {
  BillingDetailsDto,
  CheckoutInformationDto,
  CustomerInformationDto,
  LotteryCheckoutService,
  LotteryCustomerApplicationService,
  LotteryShoppingCartService,
  TransactionCustomerService,
} from '../../../api/generated';
import {ValidationFailures} from '../lottery-applications-confirmation';
import {useHistory} from 'react-router-dom';
import {useAsyncFn, useMedia} from 'react-use';
import {MediaSizes} from '../../../styles/breakpoints';
import {StateCode, CountryCode} from '../../../api/generated/enums';
import {getEnumDropdownOptions} from '../../../api/generated/utils';
import {
  fieldConfig,
  input,
  dropdown,
  masked,
  getDefaults,
  RawFieldConfig,
} from '../../../forms/schema-utils';
import {Env} from '../../../config/env-vars';
import {useImportedScript} from '../../../utils/import-script';
import {notifications} from '../../../utils/notification-service';
import {routes} from '../../../routes/config';
import {StyledButton} from '../../../styled-components/styled-buttons';
import {Table, Message} from 'semantic-ui-react';
import {Form} from '../../../forms';
import {ContinueNoFeeTransaction} from './continue-no-fee-transaction';

declare const PayWithConverge: any;

const useFields = (customer: CustomerInformationDto | null) =>
  fieldConfig<BillingDetailsDto>({
    firstName: input({
      fieldLabel: 'First Name',
      defaultValue: customer && customer.firstName,
      fieldRequired: true,
      inputProps: {
        maxLength: 20,
      },
    }),
    lastName: input({
      fieldLabel: 'Last Name',
      defaultValue: customer && customer.lastName,
      fieldRequired: true,
      inputProps: {
        maxLength: 20,
      },
    }),
    streetAddress: input({
      fieldLabel: 'Street Address',
      defaultValue:
        customer &&
        customer.physicalAddress &&
        customer.physicalAddress.street1,
      fieldRequired: true,
      inputProps: {
        maxLength: 50,
      },
    }),
    city: input({
      fieldLabel: 'City',
      defaultValue:
        customer && customer.physicalAddress && customer.physicalAddress.city,
      fieldRequired: true,
      inputProps: {
        maxLength: 30,
      },
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      defaultValue:
        customer &&
        customer.physicalAddress &&
        customer.physicalAddress.stateCode,
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      fieldRequired: true,
      defaultValue:
        customer &&
        customer.physicalAddress &&
        customer.physicalAddress.zipCode,
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
      },
    }),
  });

export interface CreditCardInformationProps {
  checkoutInfo: CheckoutInformationDto;
  donationAmount: number | undefined;
  isEmailVerified: boolean;
  customerEmail: string;
  setValidationFailures: (
    validationFailures: (
      validationFailures: ValidationFailures
    ) => ValidationFailures
  ) => void;
  setIsPageDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreditCardInformation = ({
  checkoutInfo,
  donationAmount,
  isEmailVerified,
  customerEmail,
  setValidationFailures,
  setIsPageDisabled,
}: CreditCardInformationProps) => {
  const history = useHistory();
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  const fields = useFields(checkoutInfo.customerInformation!);

  const shoppingCart = checkoutInfo.shoppingCart;

  const [elavonTransactionId, setElavonTransactionId] = useState();
  const [retryButtonVisible, setRetryButtonVisible] = useState<boolean>(false);
  const [
    paymentVerificationLoading,
    setPaymentVerificationLoading,
  ] = useState<boolean>(false);
  const [isTokenRequestLoading, setIsTokenRequestLoading] = useState<boolean>(
    false
  );

  useImportedScript(Env.elavonConvergeLightboxUrl);

  const cartItemTotal =
    shoppingCart != null ? shoppingCart?.total - shoppingCart?.fee : 0;

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const [verifyTransactionState, verifyTransaction] = useAsyncFn(
    async (trx) => {
      setIsPageDisabled(true);
      setPaymentVerificationLoading(true);
      setElavonTransactionId(trx.ssl_txn_id);
      const transactionId = elavonTransactionId ?? trx.ssl_txn_id;
      const verifyTransactionResponse = await LotteryCheckoutService.verifyTransaction(
        {
          body: {
            cardToken: '',
            transactionId: transactionId,
            updateAutoRenewalInformation: false,
          },
        }
      );

      setPaymentVerificationLoading(false);
      setIsPageDisabled(false);
      if (verifyTransactionResponse.hasErrors) {
        verifyTransactionResponse.validationFailures?.forEach(
          (validationError) => {
            if (validationError.propertyName === 'cartExpiration') {
              notifications.error('Your cart has expired');
              notifications.error(validationError.errorMessage);
              history.replace(routes.customer.lotteryApplications);
              return;
            }
          }
        );

        setRetryButtonVisible(true);
        setValidationFailures((validationFailures) => {
          return {
            ...validationFailures,
            errors: [...(verifyTransactionResponse.validationFailures ?? [])],
          };
        });
      } else {
        history.replace(routes.customer.lotteryReceipt, {
          transactionState: verifyTransactionResponse.result,
        });
      }
    }
  );

  const onSubmit = async (values: any) => {
    setPaymentVerificationLoading(true);
    setIsPageDisabled(true);
    const response = await LotteryShoppingCartService.isCartExpired({
      shoppingCartId: shoppingCart?.id,
    });

    if (response.result === true) {
      notifications.error('Your cart is expired');
      history.push(routes.customer.lotteryApplications);
      setIsPageDisabled(false);
      return;
    }
    setPaymentVerificationLoading(false);

    if (
      (shoppingCart?.donations?.length || 0) <= 1 &&
      shoppingCart?.products?.length === 0
    ) {
      notifications.error('Please select an option for both donations');
      setValidationFailures((validationFailures) => {
        validationFailures.hasDonationError = true;
        return {
          ...validationFailures,
        };
      });

      setIsPageDisabled(false);
      return;
    }

    if (!isEmailVerified) {
      notifications.error('Please Verify Your Email Address');
      setValidationFailures((validationFailures) => {
        validationFailures.hasEmailVerificationError = true;
        return {
          ...validationFailures,
        };
      });

      setIsPageDisabled(false);
      return;
    }

    const lotteryValidationResponse = await LotteryCustomerApplicationService.verifyLeftoverLotteryCartItems();
    if (lotteryValidationResponse.hasErrors) {
      notifications.error(
        'Invalid item in cart. Please contact support for further assistance'
      );
      setIsPageDisabled(false);
      return lotteryValidationResponse;
    }

    const updateEmailResponse = await TransactionCustomerService.updateEmailAddress(
      {
        emailAddress: customerEmail,
      }
    );

    if (updateEmailResponse.hasErrors) {
      notifications.error(
        'Failed to update email. Please contact support for further assistance.'
      );

      setIsPageDisabled(false);
      return;
    }

    values.zipCode = values?.zipCode?.replace('-', '');
    setIsTokenRequestLoading(true);
    const getTransactionTokenResponse = await LotteryCheckoutService.getTransactionToken(
      {
        body: {
          billingDetails: values,
          donationAmount: donationAmount ?? 0,
          updateAutoRenewalPaymentInfo: false,
        },
      }
    );
    setIsTokenRequestLoading(false);
    setIsPageDisabled(false);

    if (getTransactionTokenResponse.hasErrors) {
      notifications.error(
        'Failed to initiate payment step. Please contact support for further assistance.'
      );
      setValidationFailures((validationFailures) => {
        return {
          ...validationFailures,
          errors: [...(getTransactionTokenResponse.validationFailures ?? [])],
        };
      });
      return;
    }

    const paymentFields = {
      ssl_txn_auth_token: getTransactionTokenResponse.result?.token,
    };

    const callback = {
      onError: function (error: React.ReactNode) {
        setPaymentVerificationLoading(false);
        notifications.error(
          `${error}. Please contact support if you require further assistance.`
        );
      },
      onCancelled: function () {
        setPaymentVerificationLoading(false);
        notifications.info('Transaction Canceled');
      },
      onDeclined: async function (response: {errorName: any; ssl_txn_id: any}) {
        setPaymentVerificationLoading(false);
        if (response.errorName && response.errorName !== '') {
          notifications.error(
            `${response.errorName}. Please contact support if you require further assistance.`
          );
        } else {
          notifications.error(
            'Transaction Declined. Please contact support if you require further assistance.'
          );
        }
        const saveTransactionFailure = await LotteryCheckoutService.saveTransactionFailure(
          {
            transactionId: response.ssl_txn_id,
          }
        );

        if (saveTransactionFailure.hasErrors) {
          notifications.error(
            'Failed to log declined transaction. Please contact support for further assistance.'
          );
        }
      },
      onApproval: async function (response: {ssl_txn_id: any}) {
        setPaymentVerificationLoading(false);
        await verifyTransaction(response);
      },
      onSubmit: function () {
        setPaymentVerificationLoading(true);
      },
    };

    PayWithConverge.open(paymentFields, callback);
    return true;
  };

  return (
    <>
      {
        <Table basic compact unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <strong>Billing Information</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Form
                  initialValues={defaultValues}
                  onSubmit={onSubmit}
                  render={() => (
                    <>
                      <FormFields fields={fields} />
                      {retryButtonVisible && (
                        <Message color="red">
                          Error: Your payment was successful, but we encountered
                          an issue while saving your transaction. Please click
                          'Retry' below to have the system try again. If the
                          issue persists, please contact support.
                        </Message>
                      )}
                      <div className="payment-button">
                        {cartItemTotal > 0 ? (
                          <>
                            {!retryButtonVisible && (
                              <StyledButton
                                type="submit"
                                className="mobile-button-margin"
                                fluid={mobileMax}
                                primary
                                disabled={paymentVerificationLoading}
                                content={
                                  verifyTransactionState.loading
                                    ? 'Processing Payment...'
                                    : isTokenRequestLoading
                                    ? 'Loading...'
                                    : 'Continue'
                                }
                              />
                            )}
                            {retryButtonVisible && (
                              <StyledButton
                                onClick={verifyTransaction}
                                className="mobile-button-margin"
                                fluid={mobileMax}
                                primary
                                disabled={verifyTransactionState.loading}
                                content={
                                  verifyTransactionState.loading
                                    ? 'Retrying...'
                                    : 'Retry'
                                }
                              />
                            )}
                          </>
                        ) : (
                          <ContinueNoFeeTransaction
                            isEmailVerified={isEmailVerified}
                            customerEmail={customerEmail}
                            shoppingCartId={shoppingCart?.id}
                          />
                        )}
                        <StyledButton
                          secondary
                          onClick={async () => {
                            const response = await LotteryShoppingCartService.isCartExpired(
                              {
                                shoppingCartId: shoppingCart?.id,
                              }
                            );

                            if (response.result === true) {
                              notifications.error('Your cart is expired');
                            }
                            history.push(routes.customer.lotteryApplications);
                          }}
                          fluid={mobileMax}
                          to={routes.customer.lotteryApplications}
                          disabled={paymentVerificationLoading}
                          content={'Cancel'}
                        />
                      </div>
                    </>
                  )}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
    </>
  );
};

const FormFields: React.FC<{
  fields: RawFieldConfig<BillingDetailsDto>;
}> = ({fields}) => {
  return (
    <Form.Section title="">
      <Form.Row>
        <Form.Input fieldConfig={fields.firstName} />
        <Form.Input fieldConfig={fields.lastName} />
      </Form.Row>
      <Form.Row>
        <Form.Input fieldConfig={fields.streetAddress} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.city} />
        <Form.Dropdown fieldConfig={fields.stateCode} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.InputMasked fieldConfig={fields.zipCode} />
        <Form.Dropdown fieldConfig={fields.countryCode} />
      </Form.Row>
    </Form.Section>
  );
};
