// Helpers
import {fieldConfig, input} from '../../forms/schema-utils';

type FieldConfigDto = {
  groupCode: string;
};

export const fields = fieldConfig<FieldConfigDto>({
  groupCode: input({
    fieldLabel: 'Group Code',
  }),
});

export enum LotteryType {
  available = 'AVAILABLE',
  leftover = 'LEFTOVER',
  unavailable = 'UNAVAILABLE',
}

export enum BlackBearLotteryPreferencePointTypeCode {
  wmaBlackBearLottery = '0901',
  landOwnerBlackBearLottery = '0902',
  generalBlackBearLottery = '0903',
}
