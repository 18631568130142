import {
  faCalendarCheck,
  faInfoCircle,
  faSearch,
} from '@fortawesome/pro-light-svg-icons';
import {faArrowRight, faFileDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Header, Segment} from 'semantic-ui-react';
import {
  CustomerCourseSystemService,
  CustomerOnlineCourseService,
} from '../../api/generated';
import {AsyncStateContainer} from '../../components/async-state-container';
import {Flex} from '../../components/flex';
import {StyledButton} from '../../styled-components/styled-buttons';
import {
  StyledListingCard,
  StyledListingCardContent,
  StyledListingCardContentRow,
  StyledListingCardGroup,
  StyledListingContainer,
} from '../../styled-components/styled-listing-components';
import {Typography} from '../../styled-components/typography';
import {StyledPageContainer} from '../../styled-page-container';
import {momentCst} from '../../utils/date-time-helpers';
import {notifications} from '../../utils/notification-service';
import {customerRoutes} from '../routes/config';
import {ExportButton} from '../../components/export-button';

export const OnlineCourseListing = () => {
  const history = useHistory();
  const fetchOnlineCourses = useAsync(async () => {
    const courseResponse = await CustomerOnlineCourseService.getOnlineCoursesForLoggedInUser();

    if (courseResponse.hasErrors) {
      courseResponse.validationFailures?.forEach((error) => [
        notifications.error(error.errorMessage),
      ]);
      history.push(customerRoutes.dashboard);
    }

    const loginUrlResponse = await CustomerCourseSystemService.getEnrollmentLoginUrlsForLoggedInUser();

    if (loginUrlResponse.hasErrors) {
      loginUrlResponse.validationFailures?.forEach((error) => [
        notifications.error(error.errorMessage),
      ]);
      history.push(customerRoutes.dashboard);
    }

    return {courseResponse, loginUrlResponse};
  });

  const onlineCourses = fetchOnlineCourses.value?.courseResponse.result;
  const loginUrls = fetchOnlineCourses.value?.loginUrlResponse.result;

  return (
    <StyledPageContainer
      title="Online Courses"
      subtitle="All online courses you are taking or have taken."
    >
      <AsyncStateContainer {...fetchOnlineCourses}>
        {onlineCourses && (
          <>
            {onlineCourses.length > 0 ? (
              <StyledListingContainer>
                <StyledListingCardGroup>
                  {onlineCourses.map((onlineCourse) => {
                    const loginUrl = loginUrls?.find(
                      (x) =>
                        x.enrollmentIdentifier ===
                        onlineCourse.enrollmentIdentifier
                    );
                    return (
                      <StyledListingCard>
                        <Flex.Row className="card-header">
                          <Typography variant="heading3">
                            {onlineCourse.courseName}
                          </Typography>
                        </Flex.Row>
                        <Flex.Row>
                          <Typography variant="subheading1">
                            {`Purchased ${momentCst(
                              onlineCourse.purchasedDate
                            ).format('LL')}`}
                          </Typography>
                        </Flex.Row>
                        <StyledListingCardContent>
                          <Flex.Col>
                            <Flex.Row
                              justifyContent="space-between"
                              vAlign="center"
                            >
                              <Flex.Box>
                                <StyledListingCardContentRow
                                  icon={faInfoCircle}
                                  iconSize="xs"
                                  text={onlineCourse.status}
                                  textSize="small"
                                />

                                {onlineCourse.completedDate && (
                                  <StyledListingCardContentRow
                                    icon={faCalendarCheck}
                                    iconSize="xs"
                                    text={`Completed ${momentCst(
                                      onlineCourse.completedDate
                                    ).format('LL')}`}
                                    textSize="small"
                                  />
                                )}
                              </Flex.Box>
                            </Flex.Row>
                            <Flex.Row
                              className="button-row"
                              justifyContent="space-between"
                            >
                              <Flex.Box>
                                {loginUrl && (
                                  <StyledButton
                                    primary
                                    size="sm"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.location.href =
                                        loginUrl.loginUrl ?? '';
                                    }}
                                  >
                                    Go to course{' '}
                                    <FontAwesomeIcon icon={faArrowRight} />
                                  </StyledButton>
                                )}
                              </Flex.Box>
                              <Flex.Box>
                                {onlineCourse.isExportable && (
                                  <ExportButton
                                    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                                    request={{
                                      courseSystemEnrollmentId: onlineCourse.id,
                                    }}
                                    action={
                                      CustomerOnlineCourseService.getProofOfOnlineCourseCompletionExport
                                    }
                                    fileName={'Proof of Course Completion'}
                                    icon={faFileDownload}
                                    size={'sm'}
                                  />
                                )}
                              </Flex.Box>
                            </Flex.Row>
                          </Flex.Col>
                        </StyledListingCardContent>
                      </StyledListingCard>
                    );
                  })}
                </StyledListingCardGroup>
              </StyledListingContainer>
            ) : (
              <>
                <Flex.Fill className="no-results-found-row">
                  <Segment placeholder className="no-results">
                    <Header icon>
                      <FontAwesomeIcon name="search" icon={faSearch} />
                      <Typography variant="heading1">
                        No online courses
                      </Typography>
                      <Flex.Box>
                        <Typography variant="heading3">
                          <a
                            href="https://www.wlf.louisiana.gov/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Click Here
                          </a>{' '}
                          to find a course.
                        </Typography>
                      </Flex.Box>
                    </Header>
                  </Segment>
                </Flex.Fill>
              </>
            )}
          </>
        )}
      </AsyncStateContainer>
    </StyledPageContainer>
  );
};
