import {SkipNavContent} from '@reach/skip-nav';
import React from 'react';
import {CustomerRoutes} from './internet-sales-portal/routes';

export const CustomerApp = () => {
  return (
    <>
      <SkipNavContent></SkipNavContent>
      <CustomerRoutes />
    </>
  );
};
