import css from '@emotion/css/macro';
import React from 'react';
import {Header} from 'semantic-ui-react';
import {Theme} from '../theme';

type FormSection = {
  title: string | React.ReactNode;
};

export const StyledFormSection: React.FC<FormSection> = props => (
  <div css={styles}>
    <FormSection {...props}></FormSection>
  </div>
);

export const FormSection: React.FC<FormSection> = ({title, children}) => (
  <>
    <Header size="medium" className="form-section">
      <div className="skew-container">
        {title}
        <div className="skew"></div>
      </div>
    </Header>
    {children}
  </>
);

const styles = css`
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0px;
  }

  .skew-container {
    display: inline-block;
  }

  .skew {
    -moz-transform: skew(-15deg, 0deg);
    -webkit-transform: skew(-15deg, 0deg);
    -o-transform: skew(-15deg, 0deg);
    -ms-transform: skew(-15deg, 0deg);
    transform: skew(-15deg, 0deg);
    height: 5px;
    background: ${Theme.palette.primaryColor};
    margin-right: -0.7rem;
  }
`;
