import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useMedia} from 'react-use';
import {Divider, Message} from 'semantic-ui-react';
import {css} from '@emotion/core';
import {adminRoutes} from '../../admin/routes/config';
import {
  CustomerMailingAddressDto,
  ProductShoppingCartItemDto,
} from '../../api/generated';
import {customerRoutes} from '../../internet-sales-portal/routes/config';
import {buildPath} from '../../routes/config';
import {MediaSizes} from '../../styles/breakpoints';
import {
  isHardCardInCart,
  isStateDuckstampInCart,
} from '../product-catalog/product-catalog-utils';
import {getFormattedMailingAddress} from './mailing-address-helpers';

export const CustomerMailingAddressInformation: React.FC<{
  shoppingCart: ProductShoppingCartItemDto[] | undefined;
  mailingAddress: CustomerMailingAddressDto | null;
  showAddressUpdateText: boolean;
  customerId?: number;
}> = ({shoppingCart, mailingAddress, showAddressUpdateText, customerId}) => {
  const {pathname} = useLocation();
  const isVendor = pathname?.includes('vendor') ?? false;
  const isAdmin = pathname?.includes('admin') ?? false;

  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  const cartHasHardCard = isHardCardInCart(shoppingCart);
  const cartHasStateDuckstamp = isStateDuckstampInCart(shoppingCart);

  const mailingNoticeText =
    cartHasHardCard && cartHasStateDuckstamp
      ? 'Your Collector State Duck Stamp(s) and Hard Card(s) will be mailed to the following address within 7-10 business days. If you do not receive your item(s) within 30 days, please call LDWF at (225)-765-2898.'
      : cartHasHardCard
      ? 'Your Hard Card(s) will be mailed to the following address within 7-10 business days. If you do not receive your card(s) within 30 days, please call LDWF at (225)-765-2898.'
      : 'Your Collector State Duck Stamp(s) will be mailed to the following address within 7-10 business days. If you do not receive your stamp(s) within 30 days, please call LDWF at (225)-765-2898.';

  const mailingAddressText =
    cartHasHardCard && cartHasStateDuckstamp
      ? 'Your Collector State Duck Stamp(s) and Hard Card(s) will be delivered to '
      : cartHasHardCard
      ? 'Your Hard Card(s) will be delivered to '
      : 'Your Collector State Duck Stamp(s) will be delivered to ';

  const mailingAddressUpdateText =
    cartHasHardCard && cartHasStateDuckstamp
      ? 'You can update the mailing address for your Collector State Duck Stamp(s) and Hard Card(s) from the '
      : cartHasHardCard
      ? 'You can update the mailing address for your Hard Card(s) from the '
      : 'You can update the mailing address for your Collector State Duck Stamp(s) from the ';

  const fullAddress =
    mailingAddress !== null ? getFormattedMailingAddress(mailingAddress) : '';

  const mailingAddressUpdateLinkText = 'Licenses & Permits page.';

  return mailingAddress && (cartHasHardCard || cartHasStateDuckstamp) ? (
    <div css={styles}>
      <Message className="cell-container">
        {mailingNoticeText}
        <Divider hidden />
        {mailingAddressText}
        {mobileMax ? <div>{fullAddress}</div> : <span>{fullAddress}</span>}
        {showAddressUpdateText && (
          <>
            <Divider hidden className="last-divider" />
            {mailingAddressUpdateText}
            {isAdmin ? (
              <Link
                to={buildPath(adminRoutes.customers.allLicenses, {
                  id: customerId || 0,
                })}
                target="_blank"
              >
                {mailingAddressUpdateLinkText}
              </Link>
            ) : isVendor ? (
              <>{mailingAddressUpdateLinkText}</>
            ) : (
              <Link
                to={buildPath(customerRoutes.customerlicensesAndPermits, {
                  id: customerId || 0,
                })}
              >
                {mailingAddressUpdateLinkText}
              </Link>
            )}
          </>
        )}
      </Message>
    </div>
  ) : null;
};

const styles = css`
  .cell-container {
    font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;

    .last-divider {
      margin-bottom: 0;
    }
  }
`;
