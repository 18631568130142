import React from 'react';
import {CheckboxAdapter, CheckboxAdapterProps} from './adapters/checkbox';
import {DatePickerAdapter} from './adapters/datepicker';
import {DropdownAdapter, DropdownAdapterProps} from './adapters/dropdown';
import {TextAreaAdapter, TextAreaAdapterProps} from './adapters/textarea';
import {FieldFactory} from './field-factory';
import {
  InputAdapter,
  InputAdapterProps,
  InputCurrencyAdapter,
  InputDecimalAdapter,
  InputMaskedAdapter,
  InputMaskedAdapterProps,
  InputFileUrlAdapter,
} from './adapters/input';
import {
  RadioGroupAdapter,
  RadioGroupAdapterProps,
} from './adapters/radio-group';
import TimePickerAdapter from './adapters/timepicker';

export const FieldCheckbox = FieldFactory<CheckboxAdapterProps>({
  renderComponent: props => <CheckboxAdapter {...props} />,
});

export const FieldDropdown = FieldFactory<DropdownAdapterProps>({
  renderComponent: props => <DropdownAdapter {...props} />,
});

export const FieldInput = FieldFactory<InputAdapterProps>({
  renderComponent: props => <InputAdapter {...props} />,
});

export const FieldInputCurrency = FieldFactory<InputAdapterProps>({
  renderComponent: props => <InputCurrencyAdapter {...props} />,
});

export const FieldInputDecimal = FieldFactory<InputAdapterProps>({
  renderComponent: props => <InputDecimalAdapter {...props} />,
});

export const FieldInputFileUrl = FieldFactory<InputAdapterProps>({
  renderComponent: props => <InputFileUrlAdapter {...props} />,
});

export const FieldInputMasked = FieldFactory<InputMaskedAdapterProps>({
  renderComponent: props => <InputMaskedAdapter {...props} />,
});

export const FieldRadioGroup = FieldFactory<RadioGroupAdapterProps>({
  renderComponent: props => <RadioGroupAdapter {...props} />,
});

export const FieldTextArea = FieldFactory<TextAreaAdapterProps>({
  renderComponent: props => <TextAreaAdapter {...props} />,
});

export const FieldDatePicker = FieldFactory<InputAdapterProps>({
  renderComponent: props => <DatePickerAdapter {...props} />,
});

export const FieldTimePicker = FieldFactory<InputAdapterProps>({
  renderComponent: (props) => <TimePickerAdapter {...props} />,
});
