import React from 'react';
import {css} from '@emotion/core';
import {cx} from 'emotion';

export const Flex = () => <div>Namespace Only. Do Not Use</div>;

type FlexProps = {
  className?: string;
  fill?: boolean;
  container?: boolean;
};

export type RowProps = FlexProps & {
  align?: 'top' | 'center' | 'bottom';
  vAlign?: 'top' | 'center' | 'bottom';
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end'
    | 'justify-center';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  style?: object;
};

type ColProps = FlexProps & {
  align?: 'left' | 'center' | 'right';
  grow?: 1 | 2 | 3 | 4 | 5;
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end'
    | 'justify-center';
};

const Row: React.FC<RowProps> = (props) => {
  const className = cx('row', props.className, {
    container: props.container,
    [props.align as string]: !!props.align,
    [props.vAlign as string]: !!props.vAlign,
    [props.justifyContent as string]: !!props.justifyContent,
    [props.flexWrap as string]: !!props.flexWrap,
    fill: !!props.fill,
  });
  const {children} = props;

  return (
    <div css={styles} style={props.style} className={className}>
      {children}
    </div>
  );
};

const Col: React.FC<ColProps> = (props) => {
  const className = cx('column', props.className, {
    grow: !!props.grow,
    [props.align as string]: !!props.align,
    [props.justifyContent as string]: !!props.justifyContent,
    fill: !!props.fill,
  });
  const {children} = props;

  const getStyles = () => {
    return css`
      &.column {
        display: flex;
        flex-direction: column;

        &.center {
          align-items: center;
        }

        &.bottom {
          align-items: flex-end;
        }

        &.grow {
          flex-grow: ${props.grow};
        }

        &.space-between {
          justify-content: space-between;
        }

        &.space-around {
          justify-content: space-around;
        }

        &.space-evenly {
          justify-content: space-evenly;
        }

        &.flex-start {
          justify-content: flex-start;
        }

        &.flex-end {
          justify-content: flex-end;
        }

        &.justify-center {
          justify-content: center;
        }
      }
    `;
  };

  const colStyles = getStyles();

  return (
    <div css={colStyles} className={className}>
      {children}
    </div>
  );
};

const Fill: React.FC<{
  className?: string;
  scroll?: boolean;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  align?: 'top' | 'center' | 'bottom';
  vAlign?: 'top' | 'center' | 'bottom';
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
}> = ({children, className, flexWrap, scroll}) => (
  <div
    className={cx(
      'fill',
      className,
      {scroll},
      {
        [flexWrap as string]: !!flexWrap,
      }
    )}
  >
    {children}
  </div>
);
const Box: React.FC<{className?: string; style?: object}> = ({
  children,
  style,
  className,
}) => (
  <div className={cx('box', className)} style={style}>
    {children}
  </div>
);

Flex.Row = Row;
Flex.Col = Col;
Flex.Fill = Fill;
Flex.Box = Box;

const styles = css`
  &.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  &.row {
    display: flex !important;
    flex-direction: row;

    &.top {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

    &.justify-center {
      justify-content: center;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.space-around {
      justify-content: space-around;
    }

    &.space-evenly {
      justify-content: space-evenly;
    }

    &.flex-start {
      justify-content: flex-start;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    &.nowrap {
      flex-wrap: nowrap;
    }

    &.wrap {
      flex-wrap: wrap;
    }

    &.wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }

  .fill {
    flex: 1 1 0%;
    &.top {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.space-around {
      justify-content: space-around;
    }

    &.space-evenly {
      justify-content: space-evenly;
    }

    &.flex-start {
      justify-content: flex-start;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    &.nowrap {
      flex-wrap: nowrap;
    }

    &.wrap {
      flex-wrap: wrap;
    }

    &.wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }

  .scroll {
    overflow-y: auto;
  }
`;
