import css from '@emotion/css/macro';
import React from 'react';
import {Segment, SegmentProps} from 'semantic-ui-react';
import {Media} from '../styles/breakpoints';

type FormContainer = SegmentProps & {
  children: React.ReactNode;
  className?: string;
};

export const FormContainer: React.FC<FormContainer> = ({
  children,
  className,
  ...props
}) => (
  <Segment css={styles} className={className} {...props}>
    {children}
  </Segment>
);

const styles = css`
  &.ui.segment {
    padding: 2em;

    ${Media('TabletMax')} {
      padding: 1em;
      border-radius: 0;
    }
  }
`;
