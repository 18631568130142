import React from 'react';
import {navConfig, SecondaryNav} from '../../../components/secondary-nav';
import {buildPath, routes} from '../../../routes/config';
import {adminRoutes} from '../../../admin/routes/config';
import {PaymentMethod} from './payment-method/payment-method';
import {ProductListing} from './products/product-listing';
import {useParams} from 'react-router-dom';
import {BasicPage} from '../../../basic-page';
import {useAsync} from 'react-use';
import {AutoRenewService} from '../../../api/generated';

export const AutoRenewManagement: React.FC<{
  isAdmin?: boolean;
}> = ({isAdmin}) => {
  const {id} = useParams<{id: string}>();

  const buildRoute = (route: string, id: string) => {
    return buildPath(route, {
      id: Number(id),
    });
  };

  const fetchIsAutoRenewEnabled = useAsync(async () => {
    const {result} = await AutoRenewService.getIsEnabled();
    return result;
  }, []);

  const isAutoRenewEnabled = fetchIsAutoRenewEnabled.value;

  const productRoute = isAdmin
    ? buildRoute(adminRoutes.customers.manageAutoRenewal.customerProducts, id)
    : routes.customer.autoRenewManagement.customerProducts;

  const paymentMethodRoute = isAdmin
    ? buildRoute(adminRoutes.customers.manageAutoRenewal.paymentMethod, id)
    : routes.customer.autoRenewManagement.paymentMethod;

  const breadcrumbs = isAdmin
    ? [
        {
          title: 'Customer Dashboard',
          url: buildRoute(adminRoutes.customers.dashboard, id),
        },
        {title: 'Auto-Renew Management'},
      ]
    : [
        {title: 'Dashboard', url: routes.customer.dashboard},
        {title: 'Auto-Renew Management'},
      ];

  const tabs = navConfig({
    id: 0,
    header: '',
    backRouteName: '',
    backRoute: '',
    routes: [
      {
        name: 'Products',
        route: productRoute,
        render: () => (
          <ProductListing isAdmin={isAdmin} customerId={Number(id)} />
        ),
      },
      {
        name: 'Payment Method',
        route: paymentMethodRoute,
        render: () => (
          <PaymentMethod isAdmin={isAdmin} customerId={Number(id)} />
        ),
      },
    ],
  });

  return isAutoRenewEnabled ? (
    <BasicPage title={breadcrumbs}>
      <SecondaryNav config={tabs} />
    </BasicPage>
  ) : null;
};
