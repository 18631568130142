import React, {useState} from 'react';
import {useMedia} from 'react-use';
import {Button, Message, Modal} from 'semantic-ui-react';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  AdminCustomerAutoRenewalService,
  AutoRenewService,
} from '../../../../api/generated';
import {MediaSizes} from '../../../../styles/breakpoints';
import {notifications} from '../../../../utils/notification-service';
import {autoRenewPaymentMethodStyles} from './styles';

export const PaymentMethodDeleteConfirmationModal: React.FC<{
  isAdmin?: boolean;
  fetchPaymentMethod: any;
  customerId?: number;
}> = ({isAdmin, fetchPaymentMethod, customerId}) => {
  const [
    paymentMethodDeleteConfirmationModalOpen,
    setPaymentMethodDeleteConfirmationModalOpen,
  ] = React.useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <Modal
      css={autoRenewPaymentMethodStyles}
      onClose={() => setPaymentMethodDeleteConfirmationModalOpen(false)}
      onOpen={() => setPaymentMethodDeleteConfirmationModalOpen(true)}
      open={paymentMethodDeleteConfirmationModalOpen}
      size="large"
      trigger={
        <Button color="red" fluid={mobileMax} className="mobile-button-margin">
          <FontAwesomeIcon icon={faTrash} /> {'Remove'}
        </Button>
      }
    >
      <Modal.Header>Remove Payment Method Confirmation</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to remove the payment method on this account?
        </p>
        <Message
          warning
          content="Warning: This will prevent any products opted into auto-renew from renewing unless another payment method is added."
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          type="button"
          loading={isLoading}
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);

            const result = isAdmin
              ? await AdminCustomerAutoRenewalService.deletePaymentMethod({
                  customerId: customerId,
                })
              : await AutoRenewService.deletePaymentMethod();

            setIsLoading(false);

            if (result.hasErrors) {
              notifications.error(
                'Something went wrong when trying to remove this payment method.'
              );
              return;
            }

            notifications.success('Payment method successfully removed.');
            fetchPaymentMethod.retry();
          }}
          content={'Confirm'}
        />
        <Button
          secondary
          type="button"
          onClick={() => setPaymentMethodDeleteConfirmationModalOpen(false)}
          content={'Cancel'}
        />
      </Modal.Actions>
    </Modal>
  );
};
