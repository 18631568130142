import css from '@emotion/css/macro';
import React from 'react';
import {
  Header,
  Divider,
} from 'semantic-ui-react';
  
import {AccountService} from '../api/generated';
import {Form} from '../forms';
import {BasicPage} from '../basic-page';
import {notifications} from '../utils/notification-service';
import {ChangePasswordForm, ChangePasswordDto, initialValues} from '../components/change-password-form';

export const ChangePassword = () => {
  const onSubmit = async (values: ChangePasswordDto) => {
    const defaultValues = {
      userId: -1,
      oldPassword: '',
      password: '',
      confirmPassword: '',
    };
    const body = {...defaultValues, ...values};

    const response = await AccountService.changePassword({body});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Password Changed');
  };

  return (
    <BasicPage title="" css={styles}>
      <Form.Container className="form-container">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={() => (
            <>
              <ChangePasswordForm
                title={
                  <>
                    Department of Wildlife &amp; Fisheries
                    <Header.Subheader>Set a password</Header.Subheader>
                  </>
                }
                oldPasswordRequired={true}
              />
              <Divider />
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Set Password
                </Form.Button>
              </div>
            </>
          )}
        ></Form>
      </Form.Container>
    </BasicPage>
  );
};

const styles = css`
  .form-container {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
`;
