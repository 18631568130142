export const adminRoutes = {
  root: `/admin`,
  users: {
    root: `/admin/users`,
    create: `/admin/users/create`,
    detail: `/admin/users/:id/*`,
    details: `/admin/users/:id/details`,
    changeHistory: `/admin/users/:id/change-history`,
  },
  roles: {
    root: `/admin/roles`,
    create: `/admin/roles/create`,
    detail: `/admin/roles/:id/*`,
    details: `/admin/roles/:id/details`,
    changeHistory: `/admin/roles/:id/change-history`,
  },
  products: {
    root: `/admin/products`,
    create: `/admin/products/create`,
    detail: `/admin/products/:id/*`,
    details: `/admin/products/:id/details`,
    rules: `/admin/products/:id/rules`,
    itemFees: `/admin/products/:id/item-fees`,
    itemTags: `/admin/products/:id/item-tags`,
    itemImages: `/admin/products/:id/item-images`,
    changeHistory: `/admin/products/:id/change-history`,
  },
  customerProducts: {
    root: `/admin/customer-products`,
  },
  customerProductTransactionTags: {
    root: `/admin/customer-transaction-tags`,
  },
  transactions: {
    detail: `/admin/transactions/:id`,
  },
  vendors: {
    root: `/admin/vendors`,
    create: `/admin/vendors/create`,
    detail: `/admin/vendors/:id/*`,
    dashboard: `/admin/vendors/:id/dashboard`,
    profile: `/admin/vendors/:id/profile`,
    users: {
      root: `/admin/vendors/:id/users`,
      create: `/admin/vendors/:id/users/create`,
      edit: `/admin/vendors/:vendorId/users/:id/edit`,
    },
    notifications: `/admin/vendors/:id/notifications`,
    sales: `/admin/vendors/:id/sales`,
    journals: `/admin/vendors/:id/journals`,
    comments: `/admin/vendors/:id/comments`,
    changeHistory: `/admin/vendors/:id/change-history`,
    licensesAndPermits: `/admin/vendor/licenses-and-permits`,
    issueDuplicates: '/admin/vendor/issue-duplicates',
    purchaseConfirmation: '/admin/vendor/purchase-confirmation',
    salesReceipt: '/admin/vendor/sales-receipt',
    hipSurvey: '/admin/vendor/hip-survey',
    mailingAddressConfirmation: '/admin/vendor/mailing-address-confirmation',
  },
  customers: {
    root: `/admin/customers`,
    create: `/admin/customers/create`,
    detail: `/admin/customers/:id/*`,
    dashboard: `/admin/customers/:id/dashboard`,
    profile: `/admin/customers/:id/profile`,
    certifications: `/admin/customers/:id/certifications`,
    activeLicenses: `/admin/customers/:id/active-licenses`,
    sales: `/admin/customers/:id/sales`,
    revocations: `/admin/customers/:id/revocations`,
    harvests: `/admin/customers/:id/harvests`,
    lotteryApplications: `/admin/customers/:id/lottery-applications`,
    preferencePoints: `/admin/customers/:id/preference-points`,
    productSurveys: `/admin/customers/:id/product-surveys`,
    events: `/admin/customers/:id/events`,
    attachments: `/admin/customers/:id/attachments`,
    certifiedLicenseRecord: `/admin/customers/:id/certified-license-record`,
    comments: `/admin/customers/:id/comments`,
    notificationHistory: `/admin/customers/:id/lottery-notification-history`,
    addresses: `/admin/customers/:id/addresses`,
    changeHistory: `/admin/customers/:id/change-history`,
    licenseDetail:
      '/admin/customers/:id/license-details/:transactionCustomerDetailId',
    allLicenses: '/admin/customers/:id/license-and-permits',
    manageAutoRenewal: {
      customerProducts: '/admin/customers/:id/auto-renewal/products',
      paymentMethod: '/admin/customers/:id/auto-renewal/payment-method',
    },
  },
  certificationImport: {
    root: `/admin/events/certification-import`,
  },
  settings: {
    root: `/admin/settings`,
    detail: `/admin/settings/:id/*`,
    details: `/admin/settings/:id/details`,
    changeHistory: `/admin/settings/:id/change-history`,
  },
  harvestSeasons: {
    root: `/admin/harvest-seasons`,
    create: `/admin/harvest-seasons/create`,
    details: `/admin/harvest-seasons/:id`,
  },
  certificationCodes: {
    root: `/admin/certification-codes`,
    detail: `/admin/certification-codes/:id/*`,
    details: `/admin/certification-codes/:id/details`,
    changeHistory: `/admin/certification-codes/:id/change-history`,
  },
  corporations: {
    root: `/admin/corporations`,
    create: `/admin/corporations/create`,
    detail: `/admin/corporations/:id/*`,
    details: `/admin/corporations/:id/details`,
    changeHistory: `/admin/corporations/:id/change-history`,
  },
  displayMenus: {
    root: `/admin/display-menus`,
    create: `/admin/display-menus/create`,
    detail: `/admin/display-menus/:id/*`,
    details: `/admin/display-menus/:id/details`,
    changeHistory: `/admin/display-menus/:id/change-history`,
  },
  distributionAccounts: {
    root: `/admin/distribution-accounts`,
    create: `/admin/distribution-accounts/create`,
    detail: `/admin/distribution-accounts/:id/*`,
    details: `/admin/distribution-accounts/:id/details`,
    changeHistory: `/admin/distribution-accounts/:id/change-history`,
  },
  customerLicenseEligibilityCodes: {
    root: `/admin/customer-license-eligibility-codes`,
    create: `/admin/customer-license-eligibility-codes/create`,
    detail: `/admin/customer-license-eligibility-codes/:id`,
  },
  revocationTypes: {
    root: `/admin/revocation-types`,
    create: `/admin/revocation-types/create`,
    detail: `/admin/revocation-types/:id/*`,
    details: `/admin/revocation-types/:id/details`,
    changeHistory: `/admin/revocation-types/:id/change-history`,
  },
  locations: {
    root: `/admin/locations`,
    create: `/admin/locations/create`,
    detail: `/admin/locations/:id/*`,
    details: `/admin/locations/:id/details`,
    changeHistory: `/admin/locations/:id/change-history`,
  },
  eventTypes: {
    root: `/admin/event-types`,
    create: `/admin/event-types/create`,
    detail: `/admin/event-types/:id/*`,
    details: `/admin/event-types/:id/details`,
    changeHistory: `/admin/event-types/:id/change-history`,
  },
  courseSystemEnrollments: {
    root: `/admin/course-system-enrollments`,
  },
  documentTypes: {
    root: `/admin/document-types`,
    create: `/admin/document-types/create`,
    detail: `/admin/document-types/:id`,
  },
  lotteries: {
    root: `/admin/lotteries`,
    create: `/admin/lotteries/create`,
    detail: `/admin/lotteries/:id/*`,
    details: `/admin/lotteries/:id/details`,
    hunts: `/admin/lotteries/:id/hunts`,
    applicants: `/admin/lotteries/:id/applicants`,
    changeHistory: `/admin/lotteries/:id/change-history`,
  },
  lotterySpecies: {
    root: `/admin/lottery-species`,
    create: `/admin/lottery-species/create`,
    detail: `/admin/lottery-species/:id/*`,
    details: `/admin/lottery-species/:id/details`,
    changeHistory: `/admin/lottery-species/:id/change-history`,
  },
  lotteryCategories: {
    root: `/admin/lottery-categories`,
    create: `/admin/lottery-categories/create`,
    detail: `/admin/lottery-categories/:id/*`,
    details: `/admin/lottery-categories/:id/details`,
    changeHistory: `/admin/lottery-categories/:id/change-history`,
  },
  fulfillments: {
    root: '/admin/fulfillments',
  },
  fulfillmentMethods: {
    root: '/admin/fulfillment-methods',
    create: '/admin/fulfillment-methods/create',
    details: '/admin/fulfillment-methods/:id',
  },
  transactionFulfillments: {
    root: '/admin/transaction-fulfillments',
  },
  classEventTypes: {
    root: '/admin/class-event-types',
    create: '/admin/class-event-types/create',
    detail: '/admin/class-event-types/:id/*',
    details: '/admin/class-event-types/:id/details',
    changeHistory: '/admin/class-event-types/:id/change-history',
  },
  preferencePointTypes: {
    root: '/admin/preference-point-types',
    create: '/admin/preference-point-types/create',
    detail: '/admin/preference-point-types/:id',
  },
  events: {
    root: '/admin/events',
    create: '/admin/events/create',
    detail: '/admin/events/:id/details',
    changeHistory: '/admin/events/:id/change-history',
    students: `/admin/events/:id/students`,
    instructors: `/admin/events/:id/instructors`,
  },
  eventsSummary: {
    root: '/admin/events-summary',
  },
  eventAreas: {
    root: '/admin/event-areas',
    create: '/admin/event-areas/create',
    detail: '/admin/event-areas/:id/details',
  },
  lotteryFees: {
    root: `/admin/lottery-fees`,
    create: `/admin/lottery-fees/create`,
    detail: `/admin/lottery-fees/:id/*`,
    details: `/admin/lottery-fees/:id/details`,
    changeHistory: `/admin/lottery-fees/:id/change-history`,
  },
  lotteryAwards: {
    root: `/admin/lottery-awards`,
    create: `/admin/lottery-awards/create`,
    detail: `/admin/lottery-awards/:id/*`,
    details: `/admin/lottery-awards/:id/details`,
    changeHistory: `/admin/lottery-awards/:id/change-history`,
  },
  lotteryEmailNotification: {
    root: `/admin/lottery-email-notifications`,
    create: `/admin/lottery-email-notifications/create`,
    readOnly: `/admin/lottery-email-notifications/read-only/:id`,
    detail: `/admin/lottery-email-notifications/:id/*`,
    details: `/admin/lottery-email-notifications/:id/details`,
    changeHistory: `/admin/lottery-email-notifications/:id/change-history`,
  },
  lotterySMSNotification: {
    root: `/admin/lottery-sms-notifications`,
    detail: `/admin/lottery-sms-notifications/:id/*`,
    details: `/admin/lottery-sms-notifications/:id/details`,
    changeHistory: `/admin/lottery-sms-notifications/:id/change-history`,
  },
  huntLocations: {
    root: `/admin/hunt-locations`,
    create: `/admin/hunt-locations/create`,
    detail: `/admin/hunt-locations/:id/*`,
    details: `/admin/hunt-locations/:id/details`,
    changeHistory: `/admin/hunt-locations/:id/change-history`,
  },
  instructors: {
    root: `/admin/instructors`,
  },
  instructorsSummary: {
    root: `/admin/instructors-summary`,
  },
  instructorsCertReport: {
    root: `/admin/instructors-cert-report`,
  },
  eventReports: {
    root: `/admin/event-reports`,
    classEventTypeSummaryListing: `/admin/event-reports/class-event-type-summary-listing`,
    participantsReportListing: `/admin/event-reports/participant-report--listing`,
    instructorExpirationReportListing: `/admin/event-reports/instructor-expiration-listing`,
    importedCertificationsReportListing: `/admin/event-reports/imported-certifications-report-listing`,
    certificationsReportListing: `/admin/event-reports/certifications-report-listing`,
  },
  instructorTypes: {
    root: `/admin/instructor-types`,
    create: `/admin/instructor-types/create`,
    detail: `/admin/instructor-types/:id/*`,
    details: `/admin/instructor-types/:id/details`,
    changeHistory: `/admin/instructor-types/:id/change-history`,
  },
  journals: {
    root: `/admin/journals`,
    create: `/admin/journals/create`,
    detail: `/admin/journals/:id`,
  },
  statements: {
    root: `/admin/statements`,
    detail: `/admin/statements/:id`,
    print: `/admin/statements/:id/print`,
  },
  changePassword: {
    root: `/admin/change-password`,
  },
  reports: {
    root: `/admin/reports`,
    accounting: {
      root: `/admin/reports/accounting`,
      achReturns: `/admin/reports/accounting/ach-returns`,
      hardCard: `/admin/reports/accounting/hard-card`,
      locationClassSalesByTimePeriod: `/admin/reports/accounting/location-class-sales-by-time-period`,
      lotteryTransactions: `/admin/reports/accounting/lottery-transactions`,
      monthlyAch: `/admin/reports/accounting/monthly-ach`,
      nonAch: `/admin/reports/accounting/non-ach`,
      productSalesVolume: `/admin/reports/accounting/product-sales-volume`,
      statewideSalesDetails: `/admin/reports/accounting/statewide-sales-details`,
      weeklyAch: `/admin/reports/accounting/weekly-ach`,
      boatRenewals: `/admin/reports/accounting/boat-renewals`,
      privilegeFee: `/admin/reports/accounting/privilege-fee`,
      nonEftSalesReceipts: `/admin/reports/accounting/non-eft-sales-receipts`,
      autoRenewals: `/admin/reports/accounting/auto-renewals`,
      achBalanace: `/admin/reports/accounting/ach-balance`,
    },
    lotteries: {
      root: `/admin/reports/lotteries`,
      lotteryAnalysis: `/admin/reports/lotteries/lottery-analysis`,
      lotteryApplicants: `/admin/reports/lotteries/lottery-applicants`,
      lotteryField: `/admin/reports/lotteries/lottery-field`,
    },
    harvests: {
      root: `/admin/reports/harvests`,
      seasonalHarvests: `/admin/reports/harvests/seasonal`,
    },
    licenses: {
      root: `/admin/reports/licenses`,
      lifetimeLicenses: `/admin/reports/licenses/lifetime`,
      commercialLicenses: `/admin/reports/licenses/commercial`,
    },
    transferals: {
      root: `/admin/reports/transferals`,
      hipTransferalFile: `/admin/reports/transferals/hipTransferal`,
      duckstampCsv: `/admin/reports/transferals/duckstampCsv`,
    },
    vendors: {
      root: '/admin/reports/vendors',
      vendorSales: '/admin/reports/vendors/vendor-sales',
      mySales: '/admin/reports/vendors/my-sales',
      clerkSales: '/admin/reports/vendors/clerk-sales',
      voidSales: '/admin/reports/vendors/void-sales',
    },
    renewals: {
      root: `/admin/reports/renewals`,
      boatRenewals: {
        root: `/admin/reports/renewals/boat-renewals`,
        details: `/admin/reports/renewals/boat-renewals/details/:id`,
      },
    },
  },
};
