import css from '@emotion/css/macro';
import React from 'react';
import {AccountService, AnyObject} from '../api/generated';
import {Form} from '../forms';
import {fieldConfig, input} from '../forms/schema-utils';
import {useQueryParams} from '../hooks/use-query-params';
import {Header, Grid, Container, Divider} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';
import {routes} from '../routes/config';

const fields = fieldConfig<AnyObject>({
  password: input({
    fieldLabel: 'Password',
    inputProps: {
      type: 'password',
    },
  }),
  confirmPassword: input({
    fieldLabel: 'Confirm Password',
    inputProps: {
      type: 'password',
    },
  }),
});

const initialValues = {
  password: '',
  confirmPassword: '',
};

export const ResetPassword = () => {
  const {username, token} = useQueryParams<{username: string; token: string}>();
  const history = useHistory();

  const onSubmit = async (values) => {
    const response = await AccountService.resetPassword({
      body: {
        username,
        code: token,
        ...values,
      },
    });

    if (response.hasErrors) {
      return response;
    }

    await AccountService.login({
      body: {
        username,
        password: values.password,
      },
    });

    history.replace(routes.admin.root);
  };

  return (
    <Grid css={styles} verticalAlign="middle">
      <Grid.Column>
        <Container text>
          <Form.Container>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Section
                    title={
                      <>
                        Department of Wildlife & Fisheries
                        <Header.Subheader>Set a password</Header.Subheader>
                      </>
                    }
                  >
                    <Form.Input fieldConfig={fields.password} />
                    <Form.Input fieldConfig={fields.confirmPassword} />
                  </Form.Section>

                  <Divider />
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Set Password
                    </Form.Button>
                  </div>
                </>
              )}
            ></Form>
          </Form.Container>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
`;
