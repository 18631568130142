import css from '@emotion/css/macro';
import {faArrowLeft, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {faBellSlash, faReceipt, faUser} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormSpy, useForm} from 'react-final-form';
import {Link, useHistory} from 'react-router-dom';
import RichTextEditor from 'react-rte';
import {useAsync, useAsyncFn} from 'react-use';
import {
  Divider,
  Dropdown,
  Feed,
  FeedEventProps,
  Input,
  Label,
  List,
  Message,
  Popup,
  Segment,
  SemanticShorthandCollection,
} from 'semantic-ui-react';
import {
  AddressDto,
  CustomerLookupDto,
  CustomerLookupDtoApiResult,
  CustomerLookupService,
  CustomerLookupWithAlternateIdQuery,
  CustomerLookupWithCustomerNumberQuery,
  CustomerLookupWithDriversLicenseQuery,
  CustomerLookupWithSsnQuery,
  DashboardService,
  PastTransactionDto,
  ResidencyCode,
  VenderDashboardUserDto,
  VendorCustomerService,
  VendorNotificationDto,
  VendorOptionsService,
  VendorReportsService,
  VendorTransactionService,
  VendorTypeService,
} from '../api/generated';
import {
  CountryCode,
  SocialSecurityNumberStatus,
  StateCode,
} from '../api/generated/enums';
import {
  getEnumDropdownOptions,
  getOptionDtoDropdownOptions,
} from '../api/generated/utils';
import {useUser} from '../auth/use-auth';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DateFormat, DateTimeFormat} from '../components/date';
import {Flex} from '../components/flex';
import {Form} from '../forms';
import {
  checkbox,
  datepicker,
  dropdown,
  fieldConfig,
  input,
  masked,
} from '../forms/schema-utils';
import {RenderEditButton} from '../hooks/use-paged-data-table';
import {CustomerVerificationStepper} from '../internet-sales-portal/customers/customer-verification-stepper/verification-stepper';
import {buildPath, routes} from '../routes/config';
import {StyledButton} from '../styled-components/styled-buttons';
import {
  DashboardCard,
  DashboardCardDescription,
  DashboardCardHeader,
  StyledDashboard,
} from '../styled-components/styled-dashboard';
import {Typography} from '../styled-components/typography';
import {Theme} from '../theme';
import {useTheme} from '../theme/use-theme';
import {ValidationError} from '../types/index';
import {splitZipCodeFields} from '../utils/address-helpers';
import {
  isEmailValid,
  removeIsResidencyVerified,
  unVerifyCustomer,
  verifyCustomer,
} from '../utils/auth-helpers';
import {notifications} from '../utils/notification-service';
import {saveAs} from 'file-saver';
import {RequireAuthorization} from '../auth/require-authorization';
import {isDateFormatValid} from '../utils/date-helpers';
import {useBooleanState} from '../hooks/use-boolean-state';
import {removeUnknownKeyFromSubmitErrorsMutator} from '../internet-sales-portal/customers/customer-verification-stepper/remove-unknown-key-from-submit-errors-mutator';

type FieldConfigOmitKeys = 'isVendorLookup';
type CustomerLookupWithAlternateIdQueryConfig = Omit<
  CustomerLookupWithAlternateIdQuery,
  FieldConfigOmitKeys
>;
type CustomerLookupWithCustomerNumberQueryConfig = Omit<
  CustomerLookupWithCustomerNumberQuery,
  FieldConfigOmitKeys
>;
type CustomerLookupWithDriversLicenseQueryConfig = Omit<
  CustomerLookupWithDriversLicenseQuery,
  FieldConfigOmitKeys
>;
type CustomerLookupWithSsnQueryConfig = Omit<
  CustomerLookupWithSsnQuery,
  FieldConfigOmitKeys
>;

const SsnStatus = {
  true: SocialSecurityNumberStatus.EXEMPT,
  false: SocialSecurityNumberStatus['NON-EXEMPT'],
};

const residencyCodeOptions = {
  Yes: ResidencyCode.RESIDENT,
  No: ResidencyCode.NONRESIDENT,
};

export type VendorLandingState = {
  isHqVendor: boolean;
  isManager: boolean;
  customer?: CustomerLookupDto | null;
  vendorId?: number | null;
};

const initialVendorLandingState: VendorLandingState = {
  isHqVendor: false,
  isManager: false,
  customer: null,
  vendorId: null,
};

const subscriptionDefaults = {values: false};
const mutator = {removeUnknownKeyFromSubmitErrorsMutator};

export const VendorLandingPage = () => {
  const user = useUser();
  const history = useHistory();

  const [didSubmit, setDidSubmit] = useState(false);

  const [
    vendorLandingState,
    setVendorLandingState,
  ] = useState<VendorLandingState>({
    ...initialVendorLandingState,
    vendorId: user.vendorId,
  });

  const [
    customerHasAllRequiredDetails,
    setCustomerHasAllRequiredDetails,
  ] = useState(true);

  const {
    state: isUnknownKeyRemovedFromSubmitError,
    setTrue: setIsUnknownKeyRemovedFromSubmitErrorToTrue,
    setFalse: setIsUnknownKeyRemovedFromSubmitErrorToFalse,
  } = useBooleanState(false);

  const [, setTheme] = useTheme();

  const initializeState = useAsync(async () => {
    const routeCustomer = (history.location.state?.catalogState
      ? history.location.state?.catalogState.customer
      : history.location.state) as CustomerLookupDto;

    const {result} = await VendorTypeService.get({
      vendorId: user.vendorId,
    });

    const landingState = {
      isHqVendor: result,
      isManager: user.claims?.includes('Vendor - Manager') ?? false,
      customer: !!routeCustomer ? routeCustomer : null,
      vendorId: user.vendorId,
    };
    setVendorLandingState(landingState);
    return landingState;
  });

  useEffect(() => {
    verifyCustomer();
    window.scrollTo(0, 0);
    setTheme(
      (draft) =>
        (draft.palette.mainContentBackgroundColor = Theme.palette.grey00)
    );
    const resetBackground = () => {
      setTheme(
        (draft) =>
          (draft.palette.mainContentBackgroundColor =
            Theme.palette.mainContentBackgroundColor)
      );
    };
    return resetBackground;
  }, [setTheme]);

  const navigateToProductCatalog = () => {
    history.push(routes.vendor.licensesAndPermits, vendorLandingState);
  };
  const navigateToIssueDuplicates = () => {
    history.push(routes.vendor.issueDuplicates, vendorLandingState);
  };

  type VendorServiceBody = {
    id: string;
    customerId?: number;
  };

  const fetchVendorDashboard = useAsync(async () => {
    let customerId: number = 0;
    let body: VendorServiceBody = {
      id: `${user.vendorId}`,
    };

    if (vendorLandingState.customer !== null) {
      customerId = vendorLandingState.customer?.id ?? 0;
    }

    const {result, hasErrors} = await DashboardService.getDashboard({
      ...body,
      customerId: customerId,
    });

    if (hasErrors) {
      notifications.error('Unable to get Dashboard');
    }
    return result;
  }, [vendorLandingState.customer, user.vendorId]);

  const fetchDashboard = {
    loading: fetchVendorDashboard.loading || initializeState.loading,
    error: fetchVendorDashboard.error || initializeState.error,
    value: fetchVendorDashboard.value && initializeState.value,
  };

  let pastTransactions = fetchVendorDashboard.value
    ? fetchVendorDashboard.value.pastTransactions
    : [];

  pastTransactions = _.orderBy(
    pastTransactions,
    ['createdTimestamp'],
    ['desc']
  );

  const [
    onCustomerVerificationSubmitState,
    onCustomerVerificationSubmit,
  ] = useAsyncFn(async (values) => {
    setIsUnknownKeyRemovedFromSubmitErrorToFalse();
    const valuesToSubmit = _.cloneDeep(values);

    splitZipCodeFields(valuesToSubmit);

    valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
      ? SsnStatus.true
      : SsnStatus.false;
    valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

    const response = await VendorCustomerService.verify({body: valuesToSubmit});

    if (response.hasErrors) {
      notifications.error(
        'Customer Update Failed. Ensure all required information has been provided'
      );
      if (!didSubmit) {
        setDidSubmit(true);
      }
      return response;
    }

    notifications.success('Customer updated successfully');
    setCustomerHasAllRequiredDetails(true);
  });

  return (
    <BasicPage title="" css={styles}>
      <AsyncStateContainer {...fetchDashboard}>
        {customerHasAllRequiredDetails ? (
          <>
            {fetchVendorDashboard.value && initializeState.value && (
              <>
                {fetchVendorDashboard.value.vendorIsLocked && (
                  <Message negative>
                    <p>
                      Your vendors account has been locked. You will not be able
                      to purchase items for customers.
                    </p>
                  </Message>
                )}
                <StyledDashboard>
                  {/* <ChatWidget
              request={Env.twilioVendorUrl}
              title="Welcome"
              id={user.id}
            /> */}
                  <StyledDashboard flexWrap="wrap">
                    {!vendorLandingState.customer ? (
                      <>
                        <CustomerLookupCard
                          setCatalogRouteState={setVendorLandingState}
                          setCustomerHasAllRequiredDetails={
                            setCustomerHasAllRequiredDetails
                          }
                          catalogRouteState={vendorLandingState}
                        />
                        <SalesCard pastTransactions={pastTransactions} />
                      </>
                    ) : (
                      <CustomerTransactionCard
                        customer={vendorLandingState.customer}
                        setCatalogRouteState={setVendorLandingState}
                        navigateToProductCatalog={navigateToProductCatalog}
                        navigateToIssueDuplicates={navigateToIssueDuplicates}
                        pastTransactions={pastTransactions}
                        vendorIsLocked={
                          fetchVendorDashboard.value.vendorIsLocked
                        }
                      />
                    )}
                    <ManageVendorUsers
                      vendorUsers={fetchVendorDashboard.value.vendorUsers ?? []}
                      isManager={vendorLandingState.isManager}
                    />
                    <ReportCard
                      vendorUsers={fetchVendorDashboard.value.vendorUsers ?? []}
                      isManager={vendorLandingState.isManager}
                      vendorId={vendorLandingState.vendorId}
                    />
                  </StyledDashboard>

                  <WlfNotificationListing
                    notifications={
                      fetchVendorDashboard.value.notifications ?? []
                    }
                  />
                </StyledDashboard>
              </>
            )}
          </>
        ) : (
          <>
            {vendorLandingState.customer && (
              <Form
                initialValues={vendorLandingState.customer}
                subscription={subscriptionDefaults}
                mutators={mutator}
                onSubmit={onCustomerVerificationSubmit}
                render={() => (
                  <CustomerVerificationStepper
                    onSubmitState={onCustomerVerificationSubmitState}
                    isEdit={true}
                    isUnknownKeyRemovedFromSubmitError={
                      isUnknownKeyRemovedFromSubmitError
                    }
                    setIsUnknownKeyRemovedFromSubmitErrorToTrue={
                      setIsUnknownKeyRemovedFromSubmitErrorToTrue
                    }
                  />
                )}
              />
            )}
          </>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

const ldwfLookupKey = 'LDWF #';
const driversLicenseLookupKey = "Driver's License/ID Card #";
const ssnLookupKey = 'Social Security #';
const alternateIdLookupKey = 'Alternate ID #';

const lookupOptions = [
  {
    key: ldwfLookupKey,
    text: ldwfLookupKey,
    value: ldwfLookupKey,
  },
  {
    key: driversLicenseLookupKey,
    text: driversLicenseLookupKey,
    value: driversLicenseLookupKey,
  },
  {
    key: ssnLookupKey,
    text: ssnLookupKey,
    value: ssnLookupKey,
  },
  {
    key: alternateIdLookupKey,
    text: alternateIdLookupKey,
    value: alternateIdLookupKey,
  },
];

const dateOfBirthFieldForLookup = masked({
  fieldLabel: 'Date of Birth',
  inputProps: {
    placeholder: 'MM/DD/YYYY',
    type: 'tel',
    options: {
      numericOnly: true,
      blocks: [2, 2, 4],
      delimiter: '/',
    },
  },
});

const ldwfLookupFieldConfig = fieldConfig<CustomerLookupWithCustomerNumberQueryConfig>(
  {
    sportsmanId: masked({
      fieldLabel: 'LDWF #',
      inputProps: {
        options: {
          numericOnly: true,
        },
      },
    }),
    dateOfBirth: dateOfBirthFieldForLookup,
  }
);

const LdwfLookupFields = () => (
  <>
    <Form.InputMasked fieldConfig={ldwfLookupFieldConfig.sportsmanId} />
    <Form.InputMasked fieldConfig={ldwfLookupFieldConfig.dateOfBirth} />
  </>
);

const driversLicenseLookupFieldConfig = fieldConfig<CustomerLookupWithDriversLicenseQueryConfig>(
  {
    driverLicenseNumber: input({
      fieldLabel: `Driver's License or ID Card #`,
    }),
    driversLicenseStateCode: dropdown({
      fieldLabel: `Driver's License or ID State`,
      inputProps: {
        placeholder: 'Select a State...',
        options: getEnumDropdownOptions(StateCode),
        selection: true,
        search: true,
        defaultValue: StateCode.LOUISIANA,
      },
    }),
    dateOfBirth: dateOfBirthFieldForLookup,
  }
);

const DriversLicenseLookupFields = () => (
  <>
    <Form.Dropdown
      fieldConfig={driversLicenseLookupFieldConfig.driversLicenseStateCode}
    />
    <Form.Input
      fieldConfig={driversLicenseLookupFieldConfig.driverLicenseNumber}
    />
    <Form.InputMasked
      fieldConfig={driversLicenseLookupFieldConfig.dateOfBirth}
    />
  </>
);

const ssnLookupFieldConfig = fieldConfig<CustomerLookupWithSsnQueryConfig>({
  ssnLast4: masked({
    fieldLabel: 'Last 4 digits of SSN',
    inputProps: {
      options: {
        blocks: [4],
        numericOnly: true,
      },
    },
  }),
  lastName: input({
    fieldLabel: 'Last Name',
  }),
  dateOfBirth: dateOfBirthFieldForLookup,
});

const SsnLookupFields = () => (
  <>
    <Form.InputMasked fieldConfig={ssnLookupFieldConfig.ssnLast4} />
    <Form.Input fieldConfig={ssnLookupFieldConfig.lastName} />
    <Form.InputMasked fieldConfig={ssnLookupFieldConfig.dateOfBirth} />
  </>
);

const alternateIdLookupFieldConfig = fieldConfig<CustomerLookupWithAlternateIdQueryConfig>(
  {
    alternateIdentifier: input({
      fieldLabel: 'Alternate ID #',
    }),
    countryOfIssueCode: dropdown({
      fieldLabel: 'Country of Issue',
      inputProps: {
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        search: true,
        clearable: true,
        selection: true,
      },
    }),
    dateOfBirth: dateOfBirthFieldForLookup,
  }
);

const AlternateIdLookupFields = () => (
  <>
    <Form.Input
      fieldConfig={alternateIdLookupFieldConfig.alternateIdentifier}
    />
    <Form.Dropdown
      fieldConfig={alternateIdLookupFieldConfig.countryOfIssueCode}
    />
    <Form.InputMasked fieldConfig={alternateIdLookupFieldConfig.dateOfBirth} />
  </>
);

type CustomerLookupCardProps = {
  catalogRouteState: VendorLandingState;
  setCatalogRouteState: (catalogRouteState: VendorLandingState) => void;
  setCustomerHasAllRequiredDetails: (
    customerHasAllRequiredDetails: boolean
  ) => void;
};

const CustomerLookupCard: React.FC<CustomerLookupCardProps> = ({
  catalogRouteState,
  setCatalogRouteState,
  setCustomerHasAllRequiredDetails,
}) => {
  const [lookupType, setLookupType] = useState<any>(ldwfLookupKey);
  const [lookupErrors, setLookupErrors] = useState<ValidationError[]>();

  useEffect(() => {
    removeIsResidencyVerified();
  });

  const FormFields = () => {
    switch (lookupType) {
      case ldwfLookupKey:
        return <LdwfLookupFields />;
      case driversLicenseLookupKey:
        return <DriversLicenseLookupFields />;
      case ssnLookupKey:
        return <SsnLookupFields />;
      case alternateIdLookupKey:
        return <AlternateIdLookupFields />;
      default:
        return <></>;
    }
  };

  const onSubmit = async (values) => {
    if (!isDateFormatValid(values.dateOfBirth)) {
      const response = {
        hasErrors: true,
        validationFailures: [
          {
            propertyName: 'dateOfBirth',
            errorMessage: "'Date of Birth' not in correct format, MM/DD/YYYY",
          },
        ],
      };
      return response;
    }

    let lookupEndpoint;

    switch (lookupType) {
      case ldwfLookupKey:
        lookupEndpoint = CustomerLookupService.lookupWithCustomerNumber;
        break;
      case driversLicenseLookupKey:
        lookupEndpoint = CustomerLookupService.lookupWithDriversLicense;
        break;
      case ssnLookupKey:
        lookupEndpoint = CustomerLookupService.lookupWithSsn;
        break;
      case alternateIdLookupKey:
        lookupEndpoint = CustomerLookupService.lookupWithAlternateId;
        break;
      default:
        notifications.error('Unknown lookup command.');
        return;
    }

    const response: CustomerLookupDtoApiResult = await lookupEndpoint({
      body: values,
    });

    if (response.hasErrors) {
      setLookupErrors(
        response.validationFailures?.reduce(
          (acc: ValidationError[], cur: ValidationError) => {
            if (cur.propertyName === '') {
              acc.push(cur);
            }
            return acc;
          },
          [] as ValidationError[]
        )
      );
      return response;
    }

    const getHasAllRequiredDetails = (customer?: CustomerLookupDto) => {
      if (!customer) {
        return false;
      }
      const SsnAndNonExempt =
        (customer.socialSecurityNumber === null &&
          customer.socialSecurityNumberStatusCode ===
            SocialSecurityNumberStatus.EXEMPT) ||
        (!!customer.socialSecurityNumber &&
          customer.socialSecurityNumberStatusCode ===
            SocialSecurityNumberStatus['NON-EXEMPT']);

      const hasValidPassportCountryCode =
        (customer.socialSecurityNumberStatusCode ===
          SocialSecurityNumberStatus.EXEMPT &&
          !!customer.passportCountryCode &&
          customer.passportCountryCode !== CountryCode['UNITED STATES']) ||
        (customer.socialSecurityNumberStatusCode ===
          SocialSecurityNumberStatus['NON-EXEMPT'] &&
          !!customer.passportCountryCode) ||
        (customer.socialSecurityNumberStatusCode ===
          SocialSecurityNumberStatus['NON-EXEMPT'] &&
          customer.passportCountryCode === null);

      const hasValidEmail = isEmailValid(customer.emailAddress ?? '');

      return (
        hasValidEmail &&
        hasValidPassportCountryCode &&
        SsnAndNonExempt &&
        !!customer.firstName &&
        !!customer.lastName &&
        !!customer.dateOfBirth &&
        !!customer.emailAddress &&
        !!customer.phoneNumberPrimary &&
        !!customer.genderCode &&
        !!customer.physicalAddress &&
        !!customer.mailingAddress &&
        !!addressHasRequiredDetails(customer.physicalAddress) &&
        !!addressHasRequiredDetails(customer.mailingAddress)
      );
    };

    const addressHasRequiredDetails = (address: AddressDto) => {
      return (
        !!address.street1 &&
        !!address.city &&
        !!address.stateCode &&
        !!address.countryCode
      );
    };

    setCatalogRouteState({
      ...catalogRouteState,
      customer: response.result,
    });

    setCustomerHasAllRequiredDetails(getHasAllRequiredDetails(response.result));
  };

  return (
    <Form
      className="ui form mini"
      onSubmit={onSubmit}
      render={() => (
        <>
          <DashboardCard size="md">
            <DashboardCardHeader>
              <Typography variant="heading2">Customer Lookup</Typography>
              <StyledButton
                floated="right"
                as={Link}
                to={routes.vendor.createCustomer}
              >
                New Customer
              </StyledButton>
            </DashboardCardHeader>
            <DashboardCardDescription scroll className="customer-lookup">
              <LookupTypeSelector
                setLookupType={setLookupType}
                setLookupErrors={setLookupErrors}
                lookupType={lookupType}
              />
              {(lookupErrors || []).length > 0 && (
                <Message negative>
                  {(lookupErrors || []).map((x) => (
                    <p>{x.errorMessage}</p>
                  ))}
                </Message>
              )}
              <FormFields />
              <Flex.Col>
                <StyledButton primary type="submit">
                  Search Accounts
                </StyledButton>
              </Flex.Col>
            </DashboardCardDescription>
          </DashboardCard>
        </>
      )}
    />
  );
};

const VendorSaleInfo: React.FC = () => {
  return (
    <>
      <div>
        <Typography variant="body1">
          All sales are final and transactions older than 12 hours cannot be
          voided.
        </Typography>
      </div>
      <Divider hidden fitted />
      <div>
        <Typography variant="body1">
          Reprints occur on this system for 24 hours. <br />
          After 24 hours the customer can reprint most licenses by visiting the
          WLF website.
        </Typography>
      </div>
      <div>
        <Typography variant="small">
          (Duplicate Licenses can be printed for $2 by selecting Issue
          Duplicates. Duplicate Lifetime Licenses can be printed for $7.50 per
          lifetime license.)
        </Typography>
      </div>
    </>
  );
};

type SalesCardProps = {
  pastTransactions: PastTransactionDto[];
};

const SalesCard: React.FC<SalesCardProps> = ({pastTransactions}) => {
  const [filter, setFilter] = useState<string>();
  const [transactions, setTransactions] = useState<PastTransactionDto[]>();

  const filterSales = useCallback(
    (filter: string | undefined) => {
      if (filter === undefined) {
        setTransactions(pastTransactions);
      } else {
        setTransactions(
          pastTransactions.filter(
            (transaction) =>
              transaction.customerName
                ?.toLowerCase()
                .includes(filter.toLowerCase()) ||
              transaction.transactionNumber
                ?.toLowerCase()
                .includes(filter.toLowerCase()) ||
              transaction.vendorName
                ?.toLowerCase()
                .includes(filter.toLowerCase())
          )
        );
      }
    },
    [pastTransactions]
  );

  useEffect(() => {
    filterSales(filter);
  }, [pastTransactions, filter, filterSales]);

  return (
    <DashboardCard size="md">
      <DashboardCardHeader>
        <Flex.Row justifyContent="space-between" flexWrap="nowrap">
          <Flex.Col>
            <Flex.Row flexWrap="nowrap">
              <Typography variant="heading2">{`Sales `}</Typography>
              <Popup
                wide="very"
                position="top left"
                content={<VendorSaleInfo />}
                trigger={
                  <Typography variant="small">
                    <FontAwesomeIcon
                      size="lg"
                      className="info-icon"
                      icon={faInfoCircle}
                    />
                  </Typography>
                }
              />
            </Flex.Row>
          </Flex.Col>
          <Flex.Col>
            <Input
              className="sales-search"
              placeholder="Search"
              icon="search"
              size="mini"
              onChange={({target}) => {
                const value = target.value === '' ? undefined : target.value;
                setFilter(value);
              }}
            />
          </Flex.Col>
        </Flex.Row>
      </DashboardCardHeader>

      {pastTransactions && pastTransactions?.length > 0 ? (
        <>
          <DashboardCardDescription scroll>
            <SalesTransactionList
              pastTransactions={transactions ?? pastTransactions}
            />
          </DashboardCardDescription>
        </>
      ) : (
        <>
          <DashboardCardDescription>
            {pastTransactions && pastTransactions.length > 0 ? (
              <>
                <Segment
                  className="add-padding"
                  align="center"
                  placeholder
                  basic
                >
                  <List.Item>
                    <Label circular size="massive">
                      <FontAwesomeIcon
                        className="fa-inverse"
                        icon={faReceipt}
                      />
                    </Label>
                  </List.Item>
                  <Typography variant="subheading1">
                    No search results
                  </Typography>
                </Segment>
              </>
            ) : (
              <>
                <Segment
                  className="add-padding"
                  align="center"
                  placeholder
                  basic
                >
                  <List.Item>
                    <Label circular size="massive">
                      <FontAwesomeIcon
                        className="fa-inverse"
                        icon={faReceipt}
                      />
                    </Label>
                  </List.Item>
                  <Typography variant="subheading1">
                    You currently don't have any transactions.
                  </Typography>
                </Segment>
                <VendorSaleInfo />
              </>
            )}
          </DashboardCardDescription>
        </>
      )}
    </DashboardCard>
  );
};

type CustomerLookupTransactionList = {
  pastTransactions: PastTransactionDto[];
  customer: CustomerLookupDto;
};

const CustomerLookupTransactionList: React.FC<CustomerLookupTransactionList> = ({
  pastTransactions,
  customer,
}) => {
  const getTransactionRouteObject = (transaction) => {
    return {
      pathname: routes.vendor.transactionDetails,
      state: {
        transaction,
        customer,
      },
    };
  };

  return (
    <div className="transaction-list">
      {pastTransactions.length > 0 ? (
        <List divided selection verticalAlign="middle">
          {pastTransactions.map((transaction) => {
            return (
              <>
                {(transaction.products?.length ?? 0) > 0 && (
                  <List.Item
                    as={Link}
                    to={getTransactionRouteObject(transaction)}
                  >
                    <List.Icon>
                      <Label circular size="tiny">
                        <FontAwesomeIcon
                          className="fa-inverse"
                          icon={faReceipt}
                        />
                      </Label>
                    </List.Icon>
                    <List.Content>
                      <Flex.Row justifyContent="space-between">
                        <Flex.Col>
                          <Typography
                            variant="subheading1"
                            color={Theme.palette.blue700}
                          >
                            {`#${transaction.transactionNumber}`}
                          </Typography>
                        </Flex.Col>
                        <Flex.Col>
                          <Flex.Row justifyContent="flex-end">
                            <Typography variant="small">
                              <DateFormat date={transaction.createdTimestamp} />
                            </Typography>
                          </Flex.Row>
                          <Flex.Row>
                            <Typography
                              variant="small"
                              color={Theme.palette.blue700}
                            >
                              {transaction.vendorName}
                            </Typography>
                          </Flex.Row>
                        </Flex.Col>
                      </Flex.Row>
                      {transaction.products?.map((product) => (
                        <>
                          {!product.isExpired && (
                            <Flex.Row>
                              <Flex.Box>
                                {product.replacedProductName &&
                                product.replacedProductCode ? (
                                  <Flex.Row vAlign="bottom">
                                    <Label size="tiny" basic horizontal>
                                      {product.name}
                                    </Label>
                                    <Typography variant="body1">
                                      {product.replacedProductName}
                                    </Typography>
                                  </Flex.Row>
                                ) : (
                                  <Typography variant="body1">
                                    {product.name}
                                  </Typography>
                                )}
                              </Flex.Box>
                            </Flex.Row>
                          )}
                        </>
                      ))}
                    </List.Content>
                  </List.Item>
                )}
              </>
            );
          })}
        </List>
      ) : (
        <>
          <Segment className="add-padding" align="center" placeholder basic>
            <List.Item>
              <Label circular size="massive">
                <FontAwesomeIcon className="fa-inverse" icon={faReceipt} />
              </Label>
            </List.Item>
            <Typography variant="subheading1">
              You currently don't have any transactions.
            </Typography>
          </Segment>
          <VendorSaleInfo />
        </>
      )}
    </div>
  );
};

type SalesTransactionList = {
  pastTransactions: PastTransactionDto[];
};

const SalesTransactionList: React.FC<SalesTransactionList> = ({
  pastTransactions,
}) => {
  const history = useHistory();

  const getTransactionRouteObject = (transaction) => {
    return {
      pathname: routes.vendor.transactionDetails,
      state: {
        transaction,
      },
    };
  };

  return (
    <div className="transaction-list">
      {pastTransactions.length > 0 ? (
        <List divided verticalAlign="middle">
          {pastTransactions.map((transaction) => {
            return (
              <List.Item as={Link} to={getTransactionRouteObject(transaction)}>
                <List.Content className="vendor-sale-date" floated="right">
                  <Flex.Col>
                    <Flex.Row justifyContent="flex-end">
                      <Typography variant="small">
                        <DateTimeFormat
                          datetime={transaction.createdTimestamp}
                        />
                      </Typography>
                    </Flex.Row>
                    <Divider hidden fitted />
                    <Flex.Row>
                      <Typography variant="body1">
                        {transaction.vendorName}
                      </Typography>
                    </Flex.Row>
                  </Flex.Col>
                </List.Content>
                <List.Icon>
                  <Label circular size="tiny">
                    <FontAwesomeIcon className="fa-inverse" icon={faReceipt} />
                  </Label>
                </List.Icon>
                <List.Content
                  className="transaction-number"
                  onClick={() =>
                    history.push(routes.vendor.transactionDetails, {
                      transaction,
                    })
                  }
                >
                  <Typography
                    variant="subheading1"
                    color={Theme.palette.blue700}
                  >
                    {`#${transaction.transactionNumber}`}
                  </Typography>
                  <List.Item>
                    <Typography variant="body1">
                      {transaction.customerName}
                    </Typography>
                  </List.Item>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      ) : (
        <>
          <Segment className="add-padding" align="center" placeholder basic>
            <List.Item>
              <Label circular size="massive">
                <FontAwesomeIcon className="fa-inverse" icon={faReceipt} />
              </Label>
            </List.Item>
            <Typography variant="subheading1">
              You currently don't have any transactions.
            </Typography>
          </Segment>
          <VendorSaleInfo />
        </>
      )}
    </div>
  );
};

type CustomerTransactionCard = {
  navigateToProductCatalog: () => void;
  navigateToIssueDuplicates: () => void;
  setCatalogRouteState: (catalogRouteState: VendorLandingState) => void;
  pastTransactions: PastTransactionDto[];
  customer: CustomerLookupDto;
  vendorIsLocked: boolean;
};

const CustomerTransactionCard: React.FC<CustomerTransactionCard> = ({
  navigateToProductCatalog,
  navigateToIssueDuplicates,
  setCatalogRouteState,
  pastTransactions,
  customer,
  vendorIsLocked,
}) => {
  const buildRoute = (route) => {
    return buildPath(route, {
      id: Number(customer.id),
    });
  };

  const clearSearch = () => {
    setCatalogRouteState(initialVendorLandingState);
    unVerifyCustomer();
  };

  return (
    <div className="customer-transactions-container">
      <DashboardCard className="customer-transactions-card" size="md">
        <Flex.Row>
          <Flex.Col grow={2}>
            <List as={Link} horizontal onClick={clearSearch}>
              <List.Item>
                <FontAwesomeIcon size="sm" icon={faArrowLeft} />
              </List.Item>
              <List.Item>
                <Typography as={Link} variant="heading5">
                  Customer Search
                </Typography>
              </List.Item>
            </List>
            <Typography variant="subheading1" color={Theme.palette.grey600}>
              {`Customer ID ${customer.sportsmanId}`}
            </Typography>
            <DashboardCardHeader>
              <Typography variant="heading2">
                {`${customer.firstName} ${customer.lastName}`}
              </Typography>
            </DashboardCardHeader>

            <DashboardCardDescription scroll>
              {customer.physicalAddress && (
                <Typography variant="body1" color={Theme.palette.grey600}>
                  <div>{customer.physicalAddress.street1}</div>
                  {customer.physicalAddress.street2 && (
                    <div>{customer.physicalAddress.street2}</div>
                  )}
                  <div>
                    {`${customer.physicalAddress.city}, ${
                      customer.physicalAddress.stateCode
                    } 
                    ${customer.physicalAddress.zipCode}${
                      customer.physicalAddress.plus4
                        ? '-' + customer.physicalAddress.plus4
                        : ''
                    }`}
                  </div>
                </Typography>
              )}
              <Divider hidden />
            </DashboardCardDescription>
            <Divider hidden />
            <div>
              <StyledButton
                as={Link}
                to={buildRoute(routes.vendor.customers.profile)}
                fitContent
              >
                View Account Details
              </StyledButton>
            </div>
          </Flex.Col>

          <Flex.Col grow={1}>
            <Flex.Box>
              <DashboardCardHeader>
                <Typography variant="heading2">Transactions</Typography>
                <StyledButton
                  primary
                  floated="right"
                  type={'button'}
                  onClick={navigateToProductCatalog}
                  disabled={vendorIsLocked}
                >
                  Purchase
                </StyledButton>
                <StyledButton
                  floated="right"
                  type={'button'}
                  onClick={navigateToIssueDuplicates}
                >
                  Duplicates
                </StyledButton>
              </DashboardCardHeader>
              <DashboardCardDescription className="customer-lookup-transactions">
                <CustomerLookupTransactionList
                  pastTransactions={pastTransactions}
                  customer={customer}
                />
              </DashboardCardDescription>
            </Flex.Box>
          </Flex.Col>
        </Flex.Row>
      </DashboardCard>
    </div>
  );
};

type ManageVendorUsersProps = {
  vendorUsers: VenderDashboardUserDto[];
  isManager: boolean;
};

const ManageVendorUsers: React.FC<ManageVendorUsersProps> = ({
  vendorUsers,
  isManager,
}) => {
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [users, setUsers] = useState<VenderDashboardUserDto[]>(vendorUsers);

  useEffect(() => {
    setUsers(
      showInactive
        ? vendorUsers
        : vendorUsers?.filter(
            (user) => user.status?.toLowerCase() !== 'inactive'
          )
    );
  }, [showInactive, vendorUsers]);

  return (
    <DashboardCard size="md">
      <DashboardCardHeader>
        <Typography variant="heading2">Manage Users</Typography>
        <StyledButton
          floated="right"
          size="sm"
          primary
          as={Link}
          to={routes.vendor.vendorUser.create}
          disabled={!isManager}
          content={'New User'}
        />
        <StyledButton
          floated="right"
          size="sm"
          type={'button'}
          onClick={() => {
            setShowInactive(!showInactive);
          }}
          content={`${showInactive ? 'Hide' : 'Show'} Inactive`}
        />
      </DashboardCardHeader>
      {users.length > 0 ? (
        <>
          <DashboardCardDescription scroll>
            <List celled size="massive">
              {users.map((user) => (
                <List.Item>
                  <Flex.Row justifyContent="space-between">
                    <Flex.Col justifyContent="justify-center">
                      <Flex.Row align="center">
                        <Label circular size="tiny">
                          <FontAwesomeIcon
                            className="fa-inverse"
                            icon={faUser}
                          />
                        </Label>
                        <Typography
                          className="manage-user-name"
                          variant="subheading1"
                          color={Theme.palette.blue700}
                        >
                          {user.firstName} {user.lastName}
                        </Typography>
                      </Flex.Row>
                    </Flex.Col>
                    <Flex.Col justifyContent="flex-end">
                      <Flex.Row align="center">
                        <Typography
                          variant="subheading1"
                          color={Theme.palette.blue700}
                        >
                          {user.status}
                        </Typography>
                        <RenderEditButton
                          item={user}
                          descriptor="vendor user"
                          route={routes.vendor.vendorUser.detail}
                          disabled={!isManager}
                        />
                      </Flex.Row>
                    </Flex.Col>
                  </Flex.Row>
                </List.Item>
              ))}
            </List>
          </DashboardCardDescription>
        </>
      ) : (
        <>
          <DashboardCardDescription>
            <Segment className="add-padding" align="center" placeholder basic>
              <List.Item>
                <Label circular size="massive">
                  <FontAwesomeIcon className="fa-inverse" icon={faReceipt} />
                </Label>
              </List.Item>
              <Typography variant="subheading1">No users to show</Typography>
            </Segment>
          </DashboardCardDescription>
        </>
      )}
    </DashboardCard>
  );
};

type VendorReportFields = {
  beginDate: Date;
  endDate: Date;
  isVendorSales: boolean;
  isMySales: boolean;
  isClerkSales: boolean;
  isVoidSales: boolean;
  clerkId: number;
  statementId: number;
  isStatement: boolean;
};

const useReportFieldConfig = (
  clerkOptions,
  statementOptions,
  defaultClerkId
) => {
  return fieldConfig<VendorReportFields>({
    clerkId: dropdown({
      fieldLabel: 'Clerk',
      inputProps: {
        defaultValue: defaultClerkId,
        options: getOptionDtoDropdownOptions(clerkOptions),
        placeholder: 'Select a Clerk...',
        selection: true,
        search: true,
        clearable: true,
      },
    }),
    beginDate: datepicker({
      fieldLabel: 'Begin Date',
    }),
    endDate: datepicker({
      fieldLabel: 'End Date',
    }),
    isVendorSales: checkbox({
      fieldLabel: 'Vendor Sales',
      inputProps: {
        toggle: true,
      },
    }),
    isMySales: checkbox({
      fieldLabel: 'My Sales',
      inputProps: {
        toggle: true,
      },
    }),
    isClerkSales: checkbox({
      fieldLabel: 'Clerk Sales',
      inputProps: {
        toggle: true,
      },
    }),
    isVoidSales: checkbox({
      fieldLabel: 'Void Sales',
      inputProps: {
        toggle: true,
      },
    }),
    isStatement: checkbox({
      fieldLabel: 'Statement',
      inputProps: {
        toggle: true,
      },
    }),
    statementId: dropdown({
      fieldLabel: 'Date',
      fieldRequired: true,
      inputProps: {
        options: getOptionDtoDropdownOptions(statementOptions),
        placeholder: 'Select a Date...',
        selection: true,
        search: true,
        clearable: true,
      },
    }),
  });
};

const ReportFields = ({isManager, fieldConfig}) => (
  <>
    <Form.Row>
      <Form.DatePicker fieldConfig={fieldConfig.beginDate} />
      <Form.DatePicker fieldConfig={fieldConfig.endDate} />
    </Form.Row>
    <Form.Row proportions={[1, 2]}>
      <Form.Checkbox fieldConfig={fieldConfig.isMySales} />
      <FormSpy>
        {({values}) => {
          return (
            <>
              {isManager && values.isMySales && (
                <Form.Dropdown fieldConfig={fieldConfig.clerkId} />
              )}
            </>
          );
        }}
      </FormSpy>
    </Form.Row>
    {isManager && (
      <>
        <Form.Row>
          <Form.Checkbox fieldConfig={fieldConfig.isVendorSales} />
          <Form.Checkbox fieldConfig={fieldConfig.isClerkSales} />
          <Form.Checkbox fieldConfig={fieldConfig.isVoidSales} />
        </Form.Row>
        <Divider />
        <Form.Row proportions={[1, 2]}>
          <Form.Checkbox fieldConfig={fieldConfig.isStatement} />
          <FormSpy>
            {({values}) => {
              return (
                <>
                  {isManager && values.isStatement && (
                    <Form.Dropdown fieldConfig={fieldConfig.statementId} />
                  )}
                </>
              );
            }}
          </FormSpy>
        </Form.Row>
      </>
    )}
  </>
);

const reportFormSubscriptions = {errors: false};

type ReportCardProps = {
  isManager: boolean;
  vendorId?: number | null;
  vendorUsers: VenderDashboardUserDto[];
};

const ReportCard: React.FC<ReportCardProps> = ({
  isManager,
  vendorId,
  vendorUsers,
}) => {
  const history = useHistory();
  const user = useUser();

  const clerkOptions = vendorUsers.map((clerk) => ({
    label: `${clerk.firstName} ${clerk.lastName}`,
    value: clerk.id,
  }));

  const fetchStatementOptions = useAsync(async () => {
    if (vendorId) {
      const {result} = await VendorOptionsService.getVendorStatementOptions({
        vendorId: vendorId,
      });

      return result?.statements;
    }
  }, [vendorId]);

  const fields = useReportFieldConfig(
    clerkOptions,
    fetchStatementOptions.value,
    user.id
  );

  const [, fetchExport] = useAsyncFn(async (values: VendorReportFields) => {
    if (values.isVendorSales && vendorId) {
      const blob = await VendorTransactionService.getVendorSalesReportPdf(
        {
          body: {
            vendorId: vendorId,
            beginDate: values.beginDate,
            endDate: values.endDate,
          },
        },
        {
          responseType: 'blob',
        }
      );

      saveAs(blob, 'VendorSalesReport');
    }

    if (values.isMySales && vendorId) {
      const blob = await VendorTransactionService.getVendorMySalesReportPdf(
        {
          body: {
            vendorId: vendorId,
            beginDate: values.beginDate,
            endDate: values.endDate,
            clerkId: values.clerkId,
          },
        },
        {
          responseType: 'blob',
        }
      );

      saveAs(blob, 'MySalesReport');
    }

    if (values.isClerkSales && vendorId) {
      const blob = await VendorTransactionService.getVendorClerkSalesReportPdf(
        {
          body: {
            vendorId: vendorId,
            beginDate: values.beginDate,
            endDate: values.endDate,
          },
        },
        {
          responseType: 'blob',
        }
      );

      saveAs(blob, 'ClerkSalesReport');
    }

    if (values.isVoidSales && vendorId) {
      const blob = await VendorTransactionService.getVendorVoidSalesReportPdf(
        {
          body: {
            vendorId: vendorId,
            beginDate: values.beginDate,
            endDate: values.endDate,
          },
        },
        {
          responseType: 'blob',
        }
      );

      saveAs(blob, 'VoidSalesReport');
    }

    if (values.isStatement && vendorId) {
      if (!values.statementId) {
        notifications.error('Statement date must not be empty.');
      } else {
        const blob = await VendorReportsService.exportStatementPdfFile(
          {
            id: values.statementId,
          },
          {
            responseType: 'blob',
          }
        );

        saveAs(blob, `Statement ${values.statementId} Export`);
      }
    }
  });

  return (
    <Form
      subscription={reportFormSubscriptions}
      className="ui form mini"
      onSubmit={fetchExport}
      render={() => (
        <>
          <DashboardCard size="md">
            <DashboardCardHeader>
              <Typography variant="heading2">Reports</Typography>

              <StyledButton primary floated="right" type={'submit'}>
                Download
              </StyledButton>
              <RequireAuthorization
                showErrorPage={false}
                claims={'Vendor - Corporate'}
              >
                <StyledButton
                  floated="right"
                  onClick={() => {
                    history.push(routes.vendor.corporateReport);
                  }}
                >
                  Corporate
                </StyledButton>
              </RequireAuthorization>
            </DashboardCardHeader>
            <DashboardCardDescription>
              <ReportFields isManager={isManager} fieldConfig={fields} />
            </DashboardCardDescription>
          </DashboardCard>
        </>
      )}
    />
  );
};

type WlfNotificationListingProps = {
  notifications: VendorNotificationDto[];
};

const WlfNotificationListing: React.FC<WlfNotificationListingProps> = ({
  notifications,
}) => {
  const notificationFeed = useMemo(() => {
    let events: SemanticShorthandCollection<FeedEventProps> = notifications.map(
      (notification) => {
        return {
          content: (
            <>
              <Segment padded>
                <div>
                  <Typography variant="small">
                    <DateFormat date={notification.createdTimestamp} />
                  </Typography>
                </div>
                <div>
                  <Typography variant="subheading1">
                    {notification.subject}
                  </Typography>
                </div>
                <RichTextEditor
                  className="markdown-editor"
                  value={RichTextEditor.createValueFromString(
                    notification.message ?? '',
                    'markdown'
                  )}
                  readOnly
                />
              </Segment>
              <Divider />
            </>
          ),
        };
      }
    );

    return events;
  }, [notifications]);

  return (
    <DashboardCard className="notification-card" fullHeight>
      {notifications.length > 0 ? (
        <>
          <DashboardCardHeader>
            <Typography variant="heading3">Messages</Typography>
            <br />
            <Typography variant="subheading1">
              from the Louisiana Department of Wildlife and Fisheries
            </Typography>
          </DashboardCardHeader>
          <DashboardCardDescription scroll>
            <Feed events={notificationFeed} />
          </DashboardCardDescription>
        </>
      ) : (
        <>
          <DashboardCardHeader>
            <Typography variant="heading2">Messages</Typography>
            <br />
            <Typography variant="small">
              from the Louisiana Department of Wildlife and Fisheries
            </Typography>
          </DashboardCardHeader>
          <DashboardCardDescription>
            <Segment basic placeholder align="center">
              <List.Item>
                <Label circular size="massive">
                  <FontAwesomeIcon icon={faBellSlash} className="fa-inverse" />
                </Label>
              </List.Item>
              <Typography variant="subheading1">
                You currently have no messages.
              </Typography>
              <Divider hidden />
              <Typography variant="body1">
                Messages from the Louisiana Department of Wildlife and Fisheries
                will appear here.
              </Typography>
            </Segment>
          </DashboardCardDescription>
        </>
      )}
    </DashboardCard>
  );
};

type LookupTypeSelector = {
  setLookupType: any;
  setLookupErrors: any;
  lookupType: any;
};

const LookupTypeSelector: React.FC<LookupTypeSelector> = ({
  setLookupType,
  setLookupErrors,
  lookupType,
}) => {
  const form = useForm();

  return (
    <>
      <Typography variant="body1">
        Choose an option to find a customer record
      </Typography>
      <Dropdown
        className="lookup-selector field"
        onChange={(e, {value}) => {
          setLookupType(value);
          setLookupErrors([]);
          form.reset();
        }}
        value={lookupType}
        options={lookupOptions}
        selection
        fluid
      />
    </>
  );
};

const styles = css`
  .customer-lookup-transactions {
    overflow-y: scroll;
    height: 19.3em;
    margin: 1.2rem 0.7rem 0.7rem 0.7em;
  }

  .vendor-sale-date {
    margin: 0em 1em 0em 1em !important;
  }

  .ui.grid {
    margin-top: 0em !important;
    margin-bottom: -0.8em !important;
  }

  .customer-lookup {
    width: 25em;

    .form-field {
      margin-bottom: 0.5rem !important;
    }
  }

  .card-group .card .card-content {
    background-color: #335c64;
    text-align: center;
  }

  .card-header {
    color: white !important;
  }

  .card-group .card:hover {
    filter: brightness(0.85);
    box-shadow: 5px 8px 10px #808080 !important;
  }

  .card-group .card-base {
    box-shadow: 5px 5px 10px #707070;
  }

  .ui.feed {
    margin: 1em 1em !important;

    .markdown-editor {
      border: none !important;
    }
  }

  .manage-user-name {
    margin-left: 0.5em !important;
  }

  .info-icon {
    margin-left: 6px;
  }

  .transaction-number {
    cursor: pointer;
  }

  .sales-search {
    input {
      border-width: 2px;
      border-color: #cbcfd1;
    }

    &.left.action {
      input {
        margin-left: -1px;
      }

      .ui.button {
        box-shadow: none !important;
        z-index: 0;
      }
    }
  }

  .notification-card {
    padding: 2em 1em 0em 1em !important;
  }

  .customer-transactions-container {
    width: 100%;
  }

  .customer-transactions-card {
    width: auto !important;
  }

  .transaction-list {
    width: 100%;
    text-align: left;
    overflow-y: auto;
  }
`;
