import React from "react";

/**
 * Stores the state in the browser history,
 * making the state reusable across refreshes and navigation
 *
 * @param key The key to store it in history
 * @param defaultTo A default value if nothing exists in history
 */
export const useHistoryState = <T>(
  key: string,
  defaultTo: T | undefined
): [T, (value: T) => void] => {
  const [state, rawSetState] = React.useState(() => {
    const value = window.history.state && window.history.state[key];
    return value || defaultTo;
  });

  const setState = (value: T) => {
    window.history.replaceState(
      { ...window.history.state, [key]: value },
      document.title
    );
    rawSetState(value);
  };

  return [state, setState];
};
