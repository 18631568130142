import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown, Icon, Message, Placeholder, Popup} from 'semantic-ui-react';
import css from '@emotion/css/macro';
import _, {cloneDeep, uniq} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAlignLeft,
  faCalendar,
  faInfoCircle,
  faMapMarkerAlt,
  faMapSigns,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import {useAsync, useAsyncFn} from 'react-use';

// Components
import {AsyncStateContainer} from '../../components/async-state-container';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import {StyledButton} from '../../styled-components/styled-buttons';
import {
  StyledListingCard,
  StyledListingCardContent,
} from '../../styled-components/styled-listing-components';
import {StyledPageContainer} from '../../styled-page-container';
import {Typography} from '../../styled-components/typography';

// Network Services
import {
  CustomerEventsService,
  CustomerOptionsService,
  CustomerCourseSystemService,
  CustomerOnlineCourseService,
  CourseSystemEnrollmentDetailDto,
  AccountService,
} from '../../api/generated';

// Constants
import {
  covidWarningMessage,
  defaultFilter,
  eventsRegistrationWarningMessage,
  FilterProps,
} from './constants';
import {Theme} from '../../theme';

// Helpers
import {buildPath, routes} from '../../routes/config';
import {cstNow, momentCst} from '../../utils/date-time-helpers';
import {
  eventDateTimeSorter,
  getNumberOfSeatsRemainingMessageIsp,
  shortenString,
} from './helpers';
import {getOptionDtoDropdownOptions} from '../../api/generated/utils';
import {isNullOrUndefined} from '../../utils/object-helpers';
import {Media} from '../../styles/breakpoints';
import {startToEndDateString} from '../../utils/date-helpers';
import {useProduce} from '../../hooks/use-produce';
import {notifications} from '../../utils/notification-service';
import {FieldConfig} from '../../forms/schema-utils';
import {customerRoutes} from '../routes/config';
import {
  buildIdRoute,
  buildStringRoute,
  buildStringRouteWithState,
} from '../../utils/route-helpers';
import {ClickableIcon} from '../../components/clickable-icon';
import {cx} from 'emotion';
import {
  completedCourseStatuses,
  CourseRegistrationLocationState,
} from '../online-course/course-registration';
const konamiCodeKeys = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
];

export const IspEventListing = () => {
  const history = useHistory();
  const [konamiKeysPressed, setKonamiKeysPressed] = useState<string[]>([]);
  const [konamiActive, setKonamiActive] = useState(false);
  const konamiDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (konamiDiv.current) {
      konamiDiv.current.focus();
    }
  }, []);

  const submitPasscodeInputConfig: FieldConfig = {
    fieldName: 'passcode',
  };

  const [filter, setFilter] = useProduce<FilterProps>(defaultFilter);

  const fetchCourses = useAsync(async () => {
    return await CustomerCourseSystemService.getCourseSystemCourses();
  }, []);

  const fetchEvents = useAsync(async () => {
    const [
      events,
      isUserLoggedIn,
      eventsForLoggedInUser,
      options,
      onlineCoursesForLoggedInUser,
      isOnlineCourseEnabled,
    ] = await Promise.all([
      CustomerEventsService.getActiveEvents(),
      AccountService.isUserLoggedIn(),
      CustomerEventsService.getEventsForLoggedInUser(),
      CustomerOptionsService.getFilteredEventOptions(),
      CustomerOnlineCourseService.getOnlineCoursesForLoggedInUser(),
      CustomerOnlineCourseService.isOnlineCourseEnabled(),
    ]);

    const eventTypes = uniq(
      (events.result || []).map((x) => x.eventType)
    ).sort();

    return {
      isUserLoggedIn: isUserLoggedIn.result,
      events: events.result,
      eventsForLoggedInUser: eventsForLoggedInUser.result,
      onlineCoursesForLoggedInUser: onlineCoursesForLoggedInUser.result,
      eventTypes: eventTypes,
      options: options.result,
      isOnlineCourseEnabled: isOnlineCourseEnabled.result,
    };
  }, []);

  const [, submitPrivatePasscode] = useAsyncFn(async (values) => {
    const passcode = values.passcode;
    const fetchEvent = await CustomerEventsService.getByPrivatePasscode({
      passcode: passcode,
    });
    const response = fetchEvent.result;

    if (fetchEvent.hasErrors) {
      return fetchEvent;
    }

    notifications.success('Event found, Redirecting...');
    history.push(buildIdRoute(routes.eventDetails, response?.id));
    return response;
  });

  const filteredEvents = useMemo(() => {
    if (!fetchEvents.value) {
      return undefined;
    }

    const isBeforeOrEqual = (
      dateStringToCheck: string,
      dateStringCheckedAgainst: string
    ) => {
      return !momentCst(dateStringToCheck).isAfter(
        momentCst(dateStringCheckedAgainst)
      );
    };

    return fetchEvents.value.events?.filter(
      (x) =>
        x.eventDateTimes &&
        x.eventDateTimes.length > 0 &&
        (isNullOrUndefined(filter.eventTypeId) ||
          x.eventTypeId === filter.eventTypeId) &&
        (isNullOrUndefined(filter.classTypeId) ||
          x.classTypeId === filter.classTypeId) &&
        (isNullOrUndefined(filter.countyCodeId) ||
          x.address?.countyCodeId === filter.countyCodeId) &&
        (isNullOrUndefined(filter.daysIntoTheFuture) ||
          (x.eventDateTimes.length > 0 &&
            isBeforeOrEqual(
              momentCst(
                x.eventDateTimes.sort(eventDateTimeSorter)[0].eventDate
              ).format('YYYY/MM/DD'),
              cstNow().add(filter.daysIntoTheFuture, 'd').format('YYYY/MM/DD')
            )))
    );
  }, [fetchEvents.value, filter]);

  const eventOptions = fetchEvents.value?.options;
  const onlineCourses = fetchCourses.value?.result;
  const isOnlineCourseMaterialEnabled =
    fetchEvents.value?.isOnlineCourseEnabled;

  const [
    sendCustomerToCourseSystemState,
    sendCustomerToCourseSystem,
  ] = useAsyncFn(
    async (customerCourse: CourseSystemEnrollmentDetailDto | undefined) => {
      const loginUrlResponse = await CustomerCourseSystemService.getEnrollmentLoginUrlsForLoggedInUser();

      if (loginUrlResponse.hasErrors) {
        loginUrlResponse.validationFailures?.forEach((err) => {
          notifications.error(err.errorMessage);
        });
        return null;
      }

      const loginUrlForCurrentCourse = loginUrlResponse.result?.find(
        (x) => x.enrollmentIdentifier === customerCourse?.enrollmentIdentifier
      );

      if (!loginUrlForCurrentCourse) {
        notifications.error(
          'Error finding login url.  Please try again later.'
        );
        return null;
      }

      if (loginUrlForCurrentCourse.loginUrl) {
        window.location.href = loginUrlForCurrentCourse.loginUrl;
      }
    }
  );

  return (
    <>
      <StyledPageContainer
        title="Events"
        subtitle="Find an event near you."
        fluid
      >
        <AsyncStateContainer {...fetchEvents}>
          {fetchEvents.value && filteredEvents && (
            <Flex.Box css={styles}>
              <section className="warning-message-section">
                {!fetchEvents.value.isUserLoggedIn && (
                  <Message size="tiny" warning>
                    {eventsRegistrationWarningMessage}
                  </Message>
                )}
                <Message size="tiny" warning>
                  {covidWarningMessage}
                </Message>
              </section>

              <div className="main-flex-container">
                <Flex.Col className="main-content-section">
                  <section className="filters-section">
                    <div>
                      <Typography variant="labelSmall">Event Type</Typography>
                      <Dropdown
                        fluid
                        search
                        selection
                        selectOnBlur={false}
                        clearable
                        options={getOptionDtoDropdownOptions(
                          eventOptions?.eventTypes
                        )}
                        onChange={(e, {value}) => {
                          setFilter((filter) => {
                            filter.eventTypeId = value
                              ? parseInt(`${value}`)
                              : undefined;
                          });
                        }}
                      />
                    </div>

                    <div>
                      <Typography variant="labelSmall">Class Type</Typography>
                      <Dropdown
                        fluid
                        search
                        selection
                        selectOnBlur={false}
                        clearable
                        options={getOptionDtoDropdownOptions(
                          eventOptions?.classTypes
                        )}
                        onChange={(e, {value}) => {
                          setFilter((filter) => {
                            filter.classTypeId = value
                              ? parseInt(`${value}`)
                              : undefined;
                          });
                        }}
                      />
                    </div>

                    <div>
                      <Typography variant="labelSmall">Date</Typography>
                      <Dropdown
                        defaultValue={filter.daysIntoTheFuture}
                        options={[
                          {text: '7 days or less', value: 7},
                          {text: '14 days or less', value: 14},
                        ]}
                        onChange={(e, {value}) =>
                          setFilter((filter) => {
                            filter.daysIntoTheFuture = filter.daysIntoTheFuture = value
                              ? +value
                              : undefined;
                          })
                        }
                        fluid
                        selectOnBlur={false}
                        search
                        selection
                        clearable
                      />
                    </div>

                    <div>
                      <Typography variant="labelSmall">Parish</Typography>
                      <Dropdown
                        fluid
                        search
                        selection
                        selectOnBlur={false}
                        clearable
                        options={getOptionDtoDropdownOptions(
                          eventOptions?.counties
                        )}
                        onChange={(e, {value}) => {
                          setFilter((filter) => {
                            filter.countyCodeId = value
                              ? parseInt(`${value}`)
                              : undefined;
                          });
                        }}
                      />
                    </div>
                  </section>

                  <section className="events-list-section">
                    {filteredEvents.map((event) => {
                      const classDatesSorted =
                        event.eventDateTimes?.sort(eventDateTimeSorter) ?? [];

                      const classDatesSortedReversed = cloneDeep(
                        classDatesSorted
                      ).reverse();

                      const firstClassDate =
                        classDatesSorted.length > 0
                          ? classDatesSorted[0].eventDate
                          : null;

                      const lastClassDate =
                        classDatesSortedReversed.length > 0
                          ? classDatesSortedReversed[0].eventDate
                          : null;

                      let eventLocation = event.location;
                      if (event.location) {
                        eventLocation = shortenString(event.location);
                      }

                      return (
                        <StyledListingCard
                          className={cx(konamiActive ? 'konami-active' : '')}
                          key={event.id}
                          onClick={() =>
                            history.push(
                              buildPath(routes.eventDetails, {
                                id: event.id,
                              }),
                              {returnRoute: routes.events}
                            )
                          }
                          size="sm"
                        >
                          <Typography
                            variant="heading2"
                            color={Theme.palette.blue800}
                          >
                            {event.classEventType}
                          </Typography>

                          <Typography variant="labelLarge">
                            {firstClassDate && lastClassDate
                              ? startToEndDateString({
                                  beginDate: firstClassDate,
                                  endDate: lastClassDate,
                                })
                              : 'No dates found for this event...'}
                          </Typography>

                          <Flex.Col>
                            <StyledListingCardContent>
                              <Flex.Row className="event-info-row">
                                <FontAwesomeIcon
                                  size="xs"
                                  className="icon"
                                  icon={faCalendar}
                                />
                                <Typography variant="small">
                                  {`Registration Ends ${momentCst(
                                    event.displayEndDate
                                  )
                                    .format('LL')
                                    .toString()}`}
                                </Typography>
                              </Flex.Row>

                              <Flex.Row className="event-info-row">
                                <FontAwesomeIcon
                                  size="xs"
                                  className="icon"
                                  icon={faMapMarkerAlt}
                                />
                                <Typography variant="small">
                                  {`${
                                    eventLocation ??
                                    'No location found for this event...'
                                  }`}
                                </Typography>
                              </Flex.Row>

                              <Flex.Row className="event-info-row">
                                <FontAwesomeIcon
                                  size="xs"
                                  className="icon"
                                  icon={faMapSigns}
                                />
                                <Typography variant="small">
                                  {`${
                                    getOptionDtoDropdownOptions(
                                      eventOptions?.counties
                                    ).find((x) => x.text === event.countyCode)
                                      ?.text ??
                                    'No Parish found for this event...'
                                  }`}
                                </Typography>
                              </Flex.Row>

                              <Flex.Row className="event-info-row">
                                <FontAwesomeIcon
                                  size="xs"
                                  className="icon"
                                  icon={faUser}
                                />
                                <Typography variant="small">
                                  {getNumberOfSeatsRemainingMessageIsp(event)}
                                </Typography>
                              </Flex.Row>

                              <Flex.Row className="event-info-row">
                                <FontAwesomeIcon
                                  size="xs"
                                  className="icon"
                                  icon={faAlignLeft}
                                />
                                <Typography variant="small">
                                  <div className="truncated">
                                    {event.description}
                                  </div>
                                </Typography>
                              </Flex.Row>
                            </StyledListingCardContent>

                            <Flex.Row>
                              <StyledButton
                                primary={
                                  event.customersCanOnlyApplyToWaitlist
                                    ? false
                                    : true
                                }
                                secondary={
                                  event.customersCanOnlyApplyToWaitlist
                                    ? true
                                    : false
                                }
                                size={'sm'}
                                onClick={() =>
                                  history.push(
                                    buildPath(routes.eventDetails, {
                                      id: event.id,
                                    }),
                                    {returnRoute: routes.events}
                                  )
                                }
                              >
                                {event.customersCanOnlyApplyToWaitlist
                                  ? 'Join Wait List'
                                  : 'Register'}
                                <Icon name="arrow right" />
                              </StyledButton>
                            </Flex.Row>
                          </Flex.Col>
                        </StyledListingCard>
                      );
                    })}
                  </section>
                </Flex.Col>

                <aside className="side-content-section">
                  {isOnlineCourseMaterialEnabled && (
                    <section className="online-course-section">
                      <Typography
                        variant="heading2"
                        color={Theme.palette.blue800}
                      >
                        Online Courses
                      </Typography>
                      <Flex.Col>
                        {!fetchCourses.loading ? (
                          onlineCourses?.courses ? (
                            onlineCourses.courses.map((course) => {
                              let currentUserCourse:
                                | CourseSystemEnrollmentDetailDto
                                | undefined;
                              if (
                                fetchEvents.value?.onlineCoursesForLoggedInUser
                              ) {
                                currentUserCourse = fetchEvents.value?.onlineCoursesForLoggedInUser.find(
                                  (x) =>
                                    x.courseCode === course.courseCode &&
                                    x.status !==
                                      completedCourseStatuses.Failed &&
                                    x.status !== completedCourseStatuses.Passed
                                );
                              }
                              return (
                                <StyledListingCard size="sm">
                                  <Typography
                                    variant="heading3"
                                    color={Theme.palette.blue800}
                                  >
                                    {course.name}
                                    <Popup
                                      position="top center"
                                      on="click"
                                      content={course.courseTerms}
                                      trigger={
                                        <ClickableIcon
                                          padded
                                          icon={faInfoCircle}
                                        />
                                      }
                                    />
                                  </Typography>

                                  <Flex.Col justifyContent="space-between">
                                    <StyledListingCardContent>
                                      <Typography
                                        variant="small"
                                        color={Theme.palette.grey700}
                                      >
                                        {course.description}
                                      </Typography>
                                    </StyledListingCardContent>

                                    <Flex.Row>
                                      {fetchEvents.value?.isUserLoggedIn ? (
                                        currentUserCourse ? (
                                          <>
                                            <StyledButton
                                              size={'sm'}
                                              onClick={() => {
                                                history.push(
                                                  buildStringRoute(
                                                    customerRoutes.onlineCourseDetail,
                                                    course.courseCode
                                                  )
                                                );
                                              }}
                                            >
                                              Register
                                              <Icon name="arrow right" />
                                            </StyledButton>
                                            <StyledButton
                                              size={'sm'}
                                              loading={
                                                sendCustomerToCourseSystemState.loading
                                              }
                                              onClick={() => {
                                                sendCustomerToCourseSystem(
                                                  currentUserCourse
                                                );
                                              }}
                                              primary
                                            >
                                              Continue
                                            </StyledButton>
                                          </>
                                        ) : (
                                          <StyledButton
                                            primary
                                            size={'sm'}
                                            onClick={() => {
                                              history.push(
                                                buildStringRoute(
                                                  customerRoutes.onlineCourseDetail,
                                                  course.courseCode
                                                )
                                              );
                                            }}
                                          >
                                            Register
                                            <Icon name="arrow right" />
                                          </StyledButton>
                                        )
                                      ) : (
                                        <>
                                          <StyledButton
                                            size={'sm'}
                                            onClick={() => {
                                              history.push(
                                                buildStringRoute(
                                                  customerRoutes.onlineCourseDetail,
                                                  course.courseCode
                                                )
                                              );
                                            }}
                                          >
                                            Register
                                            <Icon name="arrow right" />
                                          </StyledButton>
                                          <StyledButton
                                            size={'sm'}
                                            loading={
                                              sendCustomerToCourseSystemState.loading
                                            }
                                            onClick={() => {
                                              history.push(
                                                ...buildStringRouteWithState<CourseRegistrationLocationState>(
                                                  routes.customer
                                                    .courseRegistration,
                                                  course.courseCode ?? '',
                                                  {
                                                    command: 'continue',
                                                  }
                                                )
                                              );
                                            }}
                                            primary
                                          >
                                            Login To Continue
                                          </StyledButton>
                                        </>
                                      )}
                                    </Flex.Row>
                                  </Flex.Col>
                                </StyledListingCard>
                              );
                            })
                          ) : (
                            <StyledListingCard size="sm">
                              <Typography
                                variant="heading3"
                                color={Theme.palette.blue800}
                              >
                                Online courses not available.
                              </Typography>

                              <Flex.Col justifyContent="space-between">
                                <StyledListingCardContent>
                                  <Typography
                                    variant="small"
                                    color={Theme.palette.grey700}
                                  >
                                    There was a problem getting online course
                                    information. Please try again later.
                                  </Typography>
                                </StyledListingCardContent>
                              </Flex.Col>
                            </StyledListingCard>
                          )
                        ) : (
                          <StyledListingCard size="sm">
                            <Typography
                              variant="heading3"
                              color={Theme.palette.blue800}
                            >
                              Fetching online courses...
                            </Typography>

                            <Flex.Col
                              className="loading-paragraph"
                              justifyContent="space-between"
                            >
                              <Placeholder>
                                <Placeholder.Paragraph>
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                </Placeholder.Paragraph>
                              </Placeholder>
                            </Flex.Col>
                          </StyledListingCard>
                        )}
                      </Flex.Col>
                    </section>
                  )}

                  <section className="private-event-section">
                    <Typography
                      variant="heading2"
                      color={Theme.palette.blue800}
                    >
                      Private Event
                    </Typography>
                    <Flex.Col>
                      <StyledListingCard size="sm">
                        <Typography
                          variant="heading3"
                          color={Theme.palette.blue800}
                        >
                          Class Code
                        </Typography>
                        <Flex.Col>
                          <Form
                            className="class-code-form"
                            onSubmit={submitPrivatePasscode}
                            render={() => (
                              <Flex.Col className="class-code-input-col">
                                <Form.Input
                                  fieldConfig={submitPasscodeInputConfig}
                                  size="mini"
                                  className="passcode-input"
                                />
                                <div className="form-actions">
                                  <StyledButton
                                    size={'sm'}
                                    type="submit"
                                    primary
                                  >
                                    Go
                                    <Icon name="arrow right" />
                                  </StyledButton>
                                </div>
                              </Flex.Col>
                            )}
                          />
                        </Flex.Col>
                      </StyledListingCard>
                    </Flex.Col>
                  </section>
                </aside>
              </div>
            </Flex.Box>
          )}
        </AsyncStateContainer>
      </StyledPageContainer>
      <div
        aria-hidden={true}
        tabIndex={-1}
        className="konami-code"
        ref={konamiDiv}
        onKeyDown={(e) => {
          const keyPressed = e.key;
          if (keyPressed === 'Escape' && !!konamiKeysPressed[0]) {
            setKonamiKeysPressed([]);
            setKonamiActive(false);
            notifications.success('Konami Code Deactivated.');
          }
          setKonamiKeysPressed((currState) => {
            const newState = currState.concat(keyPressed);
            const isActive = _.isEqual(newState, konamiCodeKeys);
            if (isActive) {
              setKonamiActive(true);
              notifications.success('Konami Code Activated...');
            }
            return newState;
          });
        }}
      ></div>
    </>
  );
};

const styles = css`
  .konami-code {
    display: none;
  }

  .truncated {
    width: 250px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ui.card {
    padding: 20px;
    margin: 6px 0;

    .icon {
      min-width: 16px;
    }
  }

  .warning-message-section {
    margin-bottom: 20px;
    max-width: 75%;
  }

  .main-flex-container {
    display: flex;
    flex-direction: row;

    .main-content-section {
      flex: 5;

      .filters-section {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-right: 100px;
        padding-right: 100px;

        .ui.selection.dropdown .menu > .item {
          padding: 0.6rem 1rem !important;
        }

        .ui.dropdown .menu > .item {
          padding: 0.6rem 1rem !important;
        }

        .ui.dropdown {
          min-width: 200px;
          font-size: 12px;

          .text {
            font-size: 12px;
          }
        }

        .ui.multiple.search.dropdown > input.search {
          outline: none;
        }

        .filter-row {
          justify-content: space-between;
        }
      }

      .events-list-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .ui.card {
          margin-right: 20px;
          width: 340px;
        }

        .event-info-row {
          align-items: center;
          height: 15px;
          margin-bottom: 6px;

          svg {
            margin-right: 8px;
          }

          &:first-child {
            margin-top: 10px;
          }
          &:last-child {
            margin-bottom: 23px;
          }
        }
      }
    }

    .side-content-section {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ui.button {
        margin-top: 15px;
      }

      .online-course-section {
        margin-bottom: 32px;
      }

      .private-event-section {
        margin-top: 15px;
        flex: 1;

        .class-code-form {
          display: flex;

          .class-code-input-col {
            flex: 1;
            align-items: flex-start;

            .ui.form .form-field {
              margin: 0;
            }

            .passcode-input {
              margin: 0;
              padding: 0;
              height: 30px;
              width: 262px;
            }

            .form-actions {
              margin: 0;

              .ui.button {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  ${Media('WideMin')} {
    .warning-message-section {
      max-width: 100%;
    }

    .main-flex-container {
      flex-direction: column;

      .filters-section {
        margin-right: 0px;
        padding-right: 0px !important;
      }

      .events-list-section {
        flex-direction: column;
      }
    }

    .main-flex-container .main-content-section .filters-section {
      flex-direction: column;
    }
  }

  .loading-paragraph {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .konami-active {
    animation: skew 20s infinite !important;
    animation-timing-function: linear;
    transform-origin: center center !important;
  }

  @keyframes skew {
    0% {
      transform: skewX(0);
    }
    25% {
      transform: skewX(10deg);
    }
    75% {
      transform: skewX(-10deg);
    }
    100% {
      transform: skewX(0);
    }
  }
`;
