import {AddressDto, OptionsService} from '../api/generated';
import {input, dropdown, masked, RawFieldConfig} from './schema-utils';
import {StateCode, CountryCode} from '../api/generated/enums';
import {
  getEnumDropdownOptions,
  getOptionDtoDropdownOptions,
} from '../api/generated/utils';

type AddressCreateKeys =
  | 'street1'
  | 'street2'
  | 'city'
  | 'stateCode'
  | 'zipCode'
  | 'countryCode'
  | 'countyCodeId'
  | 'internationalPostalCode';

export type AddressCreateDto = Pick<AddressDto, AddressCreateKeys>;

export type AddressProps = {
  physicalAddress: AddressCreateDto;
  mailingAddress: AddressCreateDto;
  address: AddressCreateDto;
};

export type InstructorAddressProps = {
  instructorAddress: AddressCreateDto;
};

export const getAddressDto = async () => {
  const {result} = await OptionsService.getAddressOptions();
  const addressDto: RawFieldConfig<AddressCreateDto> = {
    street1: input({
      fieldLabel: 'Street Address 1',
      fieldRequired: true,
    }),
    street2: input({
      fieldLabel: 'Street Address 2',
    }),
    city: input({
      fieldLabel: 'City',
      fieldRequired: true,
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      defaultValue: StateCode.LOUISIANA,
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      fieldRequired: true,
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    internationalPostalCode: input({
      fieldLabel: 'International Postal Code',
      fieldRequired: true,
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
      },
    }),
    countyCodeId: dropdown({
      fieldLabel: 'Parish',
      fieldRequired: true,
      inputProps: {
        search: true,
        options: getOptionDtoDropdownOptions(result?.counties),
        placeholder: 'Select a Parish...',
        selection: true,
      },
    }),
  };

  return addressDto;
};

export const customerGetAddressDto = async () => {
  const {result} = await OptionsService.getAddressOptions();
  const addressDto: RawFieldConfig<AddressCreateDto> = {
    street1: input({
      fieldLabel: 'Street Address 1',
      fieldRequired: true,
    }),
    street2: input({
      fieldLabel: 'Street Address 2',
    }),
    city: input({
      fieldLabel: 'City',
      fieldRequired: true,
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      defaultValue: StateCode.LOUISIANA,
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      fieldRequired: true,
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    internationalPostalCode: input({
      fieldLabel: 'International Postal Code',
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
      },
    }),
    countyCodeId: dropdown({
      fieldLabel: 'Parish',
      fieldRequired: true,
      inputProps: {
        search: true,
        options: getOptionDtoDropdownOptions(result?.counties),
        placeholder: 'Select a Parish...',
        selection: true,
      },
    }),
  };

  return addressDto;
};

export const getHuntAddressDto = async () => {
  const {result} = await OptionsService.getAddressOptions();
  const addressDto: RawFieldConfig<AddressCreateDto> = {
    street1: input({
      fieldLabel: 'Street Address',
    }),
    street2: input({
      fieldLabel: 'Street Address 2',
    }),
    city: input({
      fieldLabel: 'City',
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      defaultValue: StateCode.LOUISIANA,
      inputProps: {
        disabled: true,
        selection: true,
        search: true,
        options: getEnumDropdownOptions(StateCode),
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    internationalPostalCode: input({
      fieldLabel: 'International Postal Code',
      fieldRequired: true,
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        disabled: true,
        search: true,
        selection: true,
        options: getEnumDropdownOptions(CountryCode),
      },
    }),
    countyCodeId: dropdown({
      fieldLabel: 'Parish',
      inputProps: {
        search: true,
        options: getOptionDtoDropdownOptions(result?.counties),
        placeholder: 'Select a Parish...',
        selection: true,
      },
    }),
  };

  return addressDto;
};
