import React from 'react';
import {Card, Segment} from 'semantic-ui-react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {css} from '@emotion/core';

// Components
import {BasicPage} from '../../basic-page';
import {Typography} from '../../styled-components/typography';

// Network Services
import {BooleanApiResult, CustomerEventsService} from '../../api/generated';

// Helpers
import {buildPath, routes} from '../../routes/config';
import {useUser} from '../../auth/use-auth';
import {notifications} from '../../utils/notification-service';

// Constants
import {customerRoutes} from '../../internet-sales-portal/routes/config';
import {Theme} from '../../theme';
import {Flex} from '../../components/flex';
import {StyledButton} from '../../styled-components/styled-buttons';
import {cx} from 'emotion';
import {useEffect} from 'react';
import {EventRegistrationSubmitAction} from './event-registration-login';

type RegisterCustomerResponse = BooleanApiResult & {
  customerAlreadyRegisteredForClassEventType?: boolean;
  customerAlreadyWaitlistedForClassEventType?: boolean;
};

export const EventRegistration = () => {
  const match = useRouteMatch<{
    id: string;
    submitAction: EventRegistrationSubmitAction;
  }>();
  const eventId = Number(match.params.id);
  const submitAction = match.params.submitAction;
  const history = useHistory();
  const user = useUser();
  const fetchEvent = useAsync(async () => {
    const response = await CustomerEventsService.getById({id: eventId});
    if (response.hasErrors) {
      response.validationFailures?.forEach((x) => {
        notifications.error(x.errorMessage);
      });
    }

    return response.result;
  }, [eventId]);
  const event = fetchEvent.value;

  const buildRoute = (route, id) => {
    return buildPath(route, {
      id: id,
    });
  };

  if (!submitAction) {
    notifications.info('Failure to register. Please try again.');
    history.push(buildRoute(routes.eventDetails, eventId));
  }

  const registerUser = useAsync(async () => {
    const registerResponse: RegisterCustomerResponse = {
      result: false,
      validationFailures: [],
      createdEntities: [],
      hasErrors: false,
    };

    const canUserRegister = await CustomerEventsService.canLoggedInUserRegisterForEvent(
      {
        id: eventId,
      }
    );

    const customerRegisteredForClassEventType =
      canUserRegister.result?.canUserRegisterForEvent
        ?.customerAlreadyRegisteredForClassEventType;
    const customerHasEmailAddress =
      canUserRegister.result?.canUserRegisterForEvent?.customerHasEmailAddress;
    const customerIsNotAlreadyRegistered =
      canUserRegister.result?.canUserRegisterForEvent
        ?.customerIsNotRegisteredForEvent;
    const customerIsNotAlreadyWaitlisted =
      canUserRegister.result?.canUserWaitlistForEvent
        ?.customerIsNotWaitlistedForEvent;
    const customerWaitlistedForClassEventType =
      canUserRegister.result?.canUserWaitlistForEvent
        ?.customerAlreadyWaitlistedForClassEventType;
    const classIsAtCapacitiyWithoutWaitlist =
      !canUserRegister.result?.eventHasWaitlist &&
      canUserRegister.result?.canUserWaitlistForEvent?.eventIsAtCapacity;

    if (!customerHasEmailAddress) {
      registerResponse.validationFailures?.push({
        errorMessage: 'Customer does not have email.',
        propertyName: 'CustomerId',
      });
      history.push(
        buildRoute(customerRoutes.customerEmailSubmission, user.customerId)
      );
    }

    if (!customerIsNotAlreadyRegistered && submitAction !== 'unregister') {
      registerResponse.validationFailures?.push({
        errorMessage: 'Customer is already registered for this event.',
        propertyName: 'CustomerId',
      });
      return registerResponse;
    }

    //TODO: add const for EventRegistrationSubmitAction values
    if (customerRegisteredForClassEventType && submitAction === 'register') {
      registerResponse.customerAlreadyRegisteredForClassEventType = customerRegisteredForClassEventType;
      registerResponse.customerAlreadyWaitlistedForClassEventType = customerWaitlistedForClassEventType;
      return registerResponse;
    }

    if (customerWaitlistedForClassEventType && submitAction === 'register') {
      registerResponse.customerAlreadyRegisteredForClassEventType = customerRegisteredForClassEventType;
      registerResponse.customerAlreadyWaitlistedForClassEventType = customerWaitlistedForClassEventType;
      return registerResponse;
    }

    if (
      !customerIsNotAlreadyWaitlisted &&
      submitAction !== 'remove-from-waitlist'
    ) {
      registerResponse.validationFailures?.push({
        errorMessage: 'Customer is already waitlisted for this event.',
        propertyName: 'CustomerId',
      });
      return registerResponse;
    }

    if (classIsAtCapacitiyWithoutWaitlist && submitAction !== 'unregister') {
      registerResponse.validationFailures?.push({
        errorMessage:
          'Event is at capacity. Please check later to see if more spots become available.',
        propertyName: 'CustomerId',
      });
      return registerResponse;
    }

    if (
      (customerHasEmailAddress && customerIsNotAlreadyRegistered) ||
      submitAction === 'unregister'
    ) {
      let registerOrWaitlistResponse: any = {};

      if (submitAction === 'register') {
        registerOrWaitlistResponse = await CustomerEventsService.registerLoggedInUserToEvent(
          {
            body: {
              eventId: eventId,
            },
          }
        );
      } else if (submitAction === 'waitlist') {
        registerOrWaitlistResponse = await CustomerEventsService.waitlistLoggedInUserToEvent(
          {
            body: {
              eventId: eventId,
            },
          }
        );
      } else if (submitAction === 'unregister') {
        registerOrWaitlistResponse = await CustomerEventsService.unregisterLoggedInUserFromEvent(
          {
            body: {
              eventId: eventId,
            },
          }
        );
      } else if (submitAction === 'remove-from-waitlist') {
        registerOrWaitlistResponse = await CustomerEventsService.removeLoggedInUserFromWaitlist(
          {
            body: {
              eventId: eventId,
            },
          }
        );
      }

      if (registerOrWaitlistResponse.hasErrors) {
        registerResponse.hasErrors = true;
        registerResponse.validationFailures =
          registerOrWaitlistResponse.validationFailures;
        return registerResponse;
      }

      registerResponse.result = true;
      registerResponse.createdEntities =
        registerOrWaitlistResponse.createdEntities;

      return registerResponse;
    }

    return registerResponse;
  }, [eventId, history, submitAction, user.customerId]);

  if (!registerUser.loading) {
    if (registerUser.value) {
      if ((registerUser.value?.validationFailures?.length ?? 0) > 0) {
        return (
          <BasicPage title="">
            <EventRegistrationFailure response={registerUser.value} />
          </BasicPage>
        );
      }
      if (
        submitAction === 'register' &&
        (registerUser.value.customerAlreadyRegisteredForClassEventType ||
          registerUser.value.customerAlreadyWaitlistedForClassEventType)
      ) {
        return (
          <BasicPage title="">
            <SwapEventRegistration
              isRegistered={
                registerUser.value.customerAlreadyRegisteredForClassEventType
              }
              isWaitlisted={
                registerUser.value.customerAlreadyWaitlistedForClassEventType
              }
              classEventType={event?.classEventTypeName}
              eventId={eventId}
              submitAction={submitAction}
            />
          </BasicPage>
        );
      }
      if (registerUser.value) {
        return (
          <EventRegistrationSuccessful
            eventId={eventId}
            submitAction={submitAction}
          />
        );
      }
    }
  }

  return <BasicPage title="Checking eligibility..."></BasicPage>;
};

type SwapEventRegistration = EventRegistrationSuccessful & {
  classEventType?: string;
  isRegistered?: boolean;
  isWaitlisted?: boolean;
};

const SwapEventRegistration: React.FC<SwapEventRegistration> = ({
  eventId,
  submitAction,
  classEventType,
  isRegistered,
  isWaitlisted,
}) => {
  const history = useHistory();
  const [
    swapCustomerEventRegistrationState,
    swapCustomerEventRegistration,
  ] = useAsyncFn(async () => {
    const result = await CustomerEventsService.swapCustomerEventRegistration({
      body: {eventId},
    });
    if (result.hasErrors) {
      result.validationFailures?.forEach((x) => {
        notifications.error(x.errorMessage);
      });
    }
    return result.result;
  });

  const [registerCustomerToEventState, registerCustomerToEvent] = useAsyncFn(
    async () => {
      const result = await CustomerEventsService.registerLoggedInUserToEvent({
        body: {
          eventId: eventId,
        },
      });
      if (result.hasErrors) {
        result.validationFailures?.forEach((x) => {
          notifications.error(x.errorMessage);
        });
      }
      return result.result;
    }
  );

  let message = '';
  if (submitAction === 'register') {
    if (isRegistered) {
      message = `You are already registered for a ${classEventType} event.  Would you like to change your registration to the new event?`;
    } else if (isWaitlisted) {
      message = `You are already waitlisted for a ${classEventType} event.  
      If you register to this event, you will no longer be promoted from waitlisted on the other event(s) you are waitlisted on. Would you like to continue?`;
    }
  }

  return (
    <Flex.Box>
      {!swapCustomerEventRegistrationState.loading &&
      !registerCustomerToEventState.loading &&
      (swapCustomerEventRegistrationState.value === true ||
        registerCustomerToEventState.value !== undefined) ? (
        <>
          <EventRegistrationSuccessful
            eventId={eventId}
            submitAction={submitAction}
          />
        </>
      ) : (
        <div css={styles}>
          <Flex.Row>
            <Segment
              compact
              padded
              className={cx(`warning-message`, `orange-message`)}
            >
              <Card.Content>
                <Flex.Row justifyContent="space-evenly">
                  <Typography variant="heading2" color={Theme.palette.blue800}>
                    <strong>WARNING: {message}</strong>
                  </Typography>
                </Flex.Row>
              </Card.Content>
            </Segment>
          </Flex.Row>
          <Flex.Row justifyContent="space-evenly">
            <Flex.Box>
              <StyledButton
                loading={
                  swapCustomerEventRegistrationState.loading ||
                  registerCustomerToEventState.loading
                }
                onClick={() => {
                  if (isRegistered) {
                    swapCustomerEventRegistration();
                  } else if (isWaitlisted) {
                    registerCustomerToEvent();
                  }
                }}
                primary
                padded
              >
                Yes
              </StyledButton>
              <StyledButton padded onClick={() => history.goBack()}>
                No
              </StyledButton>
            </Flex.Box>
          </Flex.Row>
        </div>
      )}
    </Flex.Box>
  );
};

type EventRegistrationSuccessful = {
  eventId: number;
  submitAction?: EventRegistrationSubmitAction;
  swappedRegistration?: boolean;
};

const EventRegistrationSuccessful: React.FC<EventRegistrationSuccessful> = ({
  eventId,
  submitAction,
  swappedRegistration,
}) => {
  const history = useHistory();

  let successMessage = '';
  if (swappedRegistration) {
    successMessage = `${
      submitAction === 'register' ? 'Registration' : 'Waitlist'
    } successfully transferred.`;
  } else if (submitAction === 'register') {
    successMessage = 'Successfully registered for event';
  } else if (submitAction === 'waitlist') {
    successMessage = 'Successfully added to waitlist for event';
  } else if (submitAction === 'unregister') {
    successMessage = 'Successfully cancelled registration for event';
  } else if (submitAction === 'remove-from-waitlist') {
    successMessage = 'Successfully removed from waitlist for event';
  } else {
    successMessage = 'Request was successful';
  }

  useEffect(() => {
    notifications.success(successMessage);
    history.push(routes.events);
  });

  return <div css={styles}></div>;
};

type EventRegistrationFailure = {
  response: BooleanApiResult;
};

const EventRegistrationFailure: React.FC<EventRegistrationFailure> = ({
  response,
}) => {
  return (
    <>
      <div css={styles}>
        <Segment compact padded className="cancelled-card">
          <Card.Content>
            <div>
              <Typography variant="heading1" color={'#a44442'}>
                Error registering for event:
              </Typography>
            </div>
            {response.validationFailures?.map((x) => {
              return (
                <div>
                  <Typography variant="heading2" color={'#a44442'}>
                    {x.errorMessage}
                  </Typography>
                </div>
              );
            })}
          </Card.Content>
        </Segment>
      </div>
    </>
  );
};

const styles = css`
  .warning-message.orange-message {
    padding: 12px 20px !important;
    box-shadow: none !important;
    line-height: 13px;
    width: 100%;
    margin-bottom: 10px;
    background-color: ${Theme.palette.orange50};
    border: 0px none !important;
    text-align: center;
  }

  .cancelled-card {
    padding: 0.2em 0.5em !important;
    margin: 1em 0em !important;
    box-shadow: none !important;
    border: 1px solid !important;
    color: #e0b4b4;
    background-color: #fff6f6;
    line-height: 13px;
    width: 100%;
    text-align: center;

    .content {
      padding: 0em !important;
    }
  }

  .event-successful-box {
    padding: 0.2em 0em !important;
    margin-bottom: 1.5em !important;
    box-shadow: none !important;
    border: none !important;
    background-color: ${Theme.palette.green00} !important;
    line-height: 13px;
    width: 100%;
    text-align: center;
    .content {
      margin: 0.4rem 1rem !important;
    }
  }

  button.link {
    background: none;
    border: none;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 0rem;
    font-family: arial, sans-serif; // TODO: make this point to the Theme.fonts
    color: #069;
    cursor: pointer;
  }
`;
