import React from 'react';
import moment from 'moment-timezone';
import {applicationTimezone} from '../utils/date-time-helpers';

type DateProps = {
  date: number | Date | string;
  format?: string;
};

type TimeProps = {
  time: string;
  format?: string;
};

type DateTimeProps = {
  datetime?: number | Date | string;
  format?: string;
};

export const DateTimeFormats = {
  Date: 'MM/DD/YYYY',
  Time: 'hh:mm A',
  DateTime: 'MM/DD/YY hh:mm A',
};

export const DateFormat = React.memo<DateProps>(
  ({date, format = DateTimeFormats.Date}) => (
    <>{moment.tz(date, applicationTimezone).format(format)}</>
  )
);

export const TimeFormat = React.memo<TimeProps>(
  ({time, format = DateTimeFormats.Time}) => (
    <>{moment.tz(time, 'LT', applicationTimezone).format(format)}</>
  )
);

export const DateTimeFormat = React.memo<DateTimeProps>(
  ({datetime, format = DateTimeFormats.DateTime}) => (
    <>{moment.tz(datetime, applicationTimezone).format(format)}</>
  )
);
