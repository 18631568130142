import css from '@emotion/css/macro';
import {cx} from 'emotion';
import React, {useMemo} from 'react';
import {Flex, RowProps} from '../components/flex';
import {Media} from '../styles/breakpoints';
import {
  Card,
  StrictCardDescriptionProps,
  StrictCardHeaderProps,
  StrictCardProps,
} from 'semantic-ui-react';

type StyledDashboardProps = RowProps;

export const StyledDashboard: React.FC<StyledDashboardProps> = ({
  children,
  ...rest
}) => {
  const DashboardContent = useMemo(() => {
    let dashboardItems: React.ReactNode[] = [];
    let colIndex = 0;

    if (children) {
      while (children[colIndex]) {
        dashboardItems.push(children[colIndex]);
        colIndex++;
      }
    }

    return (
      <Flex.Row justifyContent="space-evenly" {...rest}>
        {[...dashboardItems]}
      </Flex.Row>
    );
  }, [children, rest]);

  return <div css={dashboardCardStyles}>{children && DashboardContent}</div>;
};

type DashboardCardProps = {
  mobileFluid?: boolean;
  fluid?: boolean;
  backgroundColor?: string;
  className?: string;
  size?: 'sm' | 'md';
  fullHeight?: boolean;
} & StrictCardProps;

export const DashboardCard: React.FC<DashboardCardProps> = ({
  mobileFluid = false,
  fullHeight = false,
  size = 'sm',
  fluid,
  children,
  className,
  backgroundColor,
  ...rest
}) => {
  const styles = css`
    .full-card-container {
      background-color: ${backgroundColor ?? ''} !important;
      height: ${fullHeight ? '50.64em' : mdCardHeightMobile} !important;
    }
  `;

  return (
    <div css={styles}>
      <Card
        fluid={fluid}
        className={cx('full-card-container', className, fullHeight ? '' : size)}
        {...rest}
      >
        {children}
      </Card>
    </div>
  );
};

type DashboardCardHeaderProps = {} & StrictCardHeaderProps;

export const DashboardCardHeader: React.FC<DashboardCardHeaderProps> = ({
  className,
  children,
  ...rest
}) => {
  const styles = css``;

  return (
    <div css={styles}>
      <Card.Header className={cx(className)} {...rest}>
        {children}
      </Card.Header>
    </div>
  );
};

type DashboardCardDescriptionProps = {
  scroll?: boolean;
} & StrictCardDescriptionProps;

export const DashboardCardDescription: React.FC<DashboardCardDescriptionProps> = ({
  scroll,
  className,
  children,
  ...rest
}) => {
  return (
    <div
      className={cx(
        'card-description',
        scroll ? 'card-description-scroll' : ``
      )}
    >
      <Card.Description className={cx(className)} {...rest}>
        {children}
      </Card.Description>
    </div>
  );
};

type DashboardCardActionsProps = {className?: string};

export const DashboardCardActions: React.FC<DashboardCardActionsProps> = ({
  className,
  children,
}) => {
  const styles = css`
    .card-actions {
      position: absolute;
      bottom: 15px !important;
    }
  `;

  return (
    <div css={styles}>
      <div className={cx('card-actions', className)}>{children}</div>
    </div>
  );
};

const cardPadding = '29px 15px';
const cardMargin = '10px';

const cardWidthDesktop = '420px';
const cardWidthMobile = '345px';

const smCardHeightDesktop = '232px';
const mdCardHeightDesktop = '395px';

const smCardHeightMobile = '212px';
const mdCardHeightMobile = '370px';

export const dashboardCardStyles = css`
  .full-card-container {
    box-shadow: 0px 4px 20px #ecebea;
    border-radius: 5px;
    padding: ${cardPadding};
    margin: ${cardMargin};

    ${Media('MobileMax')} {
      min-width: ${cardWidthMobile} !important;
    }

    ${Media('DesktopMin')} {
      min-width: ${cardWidthDesktop} !important;
    }

    .card-description-scroll {
      margin-top: 5px;
      overflow-y: auto;
    }

    ${Media('MobileMax')} {
      &.sm {
        height: ${smCardHeightMobile} !important;
      }

      &.md {
        height: ${mdCardHeightMobile} !important;
      }
    }

    ${Media('DesktopMin')} {
      &.sm {
        height: ${smCardHeightDesktop} !important;
      }

      &.md {
        height: ${mdCardHeightDesktop} !important;
      }
    }

    .header {
      margin-bottom: 10px;
    }
  }

  .ui.card:first-of-type {
    margin-bottom: 10px !important;
  }

  .ui.card:first-of-type {
    margin-top: 10px !important;
  }

  .ui.grid > .row {
    padding-top: 0rem !important;
    padding-bottom: 2rem !important;
  }
`;
