import React, {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {buildPath} from '../../routes/config';
import {customerRoutes as routes} from '../../internet-sales-portal/routes/config';
import {Loader} from 'semantic-ui-react';

export type EventRegistrationSubmitAction =
  | 'register'
  | 'waitlist'
  | 'unregister'
  | 'remove-from-waitlist';

export const EventRegistrationLogin = () => {
  const match = useRouteMatch<{
    id: string;
    submitAction: EventRegistrationSubmitAction;
  }>();
  const eventId = Number(match.params.id);
  const submitAction = match.params.submitAction;
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push(
        buildPath(routes.eventRegistration, {
          id: eventId,
          submitAction: submitAction,
        })
      );
    }, 250);
  });

  return (
    <>
      <Loader inline="centered" active />
    </>
  );
};
