import css from '@emotion/css/macro';
import {faFileExcel, faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {saveAs} from 'file-saver';
import {map} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useAsync, useAsyncFn} from 'react-use';
import {Table} from 'semantic-ui-react';
import {getOptionDtoDropdownOptions} from '../api/generated/utils';
import {AsyncStateContainer} from '../components/async-state-container';
import {DateFormat} from '../components/date';
import {Form} from '../forms';
import {dropdown, fieldConfig, RawFieldConfig} from '../forms/schema-utils';
import {StyledButton} from '../styled-components/styled-buttons';
import {StyledPageContainer} from '../styled-page-container';
import {formatCurrency} from '../utils/currency-helpers';

import {
  ApiResult,
  CorporationReportDto,
  DataTablePage,
  DataTableRequest,
  GetCorporationReportDataTableRequest,
  GetCorporationReportDataTableResponse,
  VendorOptionsService,
  VendorReportsService,
} from '../api/generated';

import {
  DtoColumnSearch,
  PagedDataTableConfig,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {notifications} from '../utils/notification-service';
import _ from 'lodash';

type CorporationReportDtoOmitKeys =
  | 'vendorName'
  | 'accountNumber'
  | 'date'
  | 'amount'
  | 'vendorFeeAmount'
  | 'grossAmount'
  | 'batchId'
  | 'corporationId';

type CorporationReportDtoFieldConfigDto = Omit<
  CorporationReportDto,
  CorporationReportDtoOmitKeys
>;

const useFields = () => {
  return useAsync(async () => {
    const {result} = await VendorOptionsService.getCorporateReportOptions();

    const achDropdownOptions = getOptionDtoDropdownOptions(
      result?.achBatchOptions
    );

    return fieldConfig<CorporationReportDtoFieldConfigDto>({
      achBatchId: dropdown({
        fieldLabel: 'ACH Batch',
        inputProps: {
          options: achDropdownOptions,
          defaultValue: _.first(achDropdownOptions)?.value,
          placeholder: 'Select ACH Batch...',
          selection: true,
          search: true,
        },
      }),
    });
  });
};

const FormFields: React.FC<{
  fields: RawFieldConfig<CorporationReportDtoFieldConfigDto>;
}> = ({fields}) => {
  return (
    <>
      <Form.StyledSection title="Advanced Search">
        <Form.Row proportions={[1, 2]}>
          <Form.Dropdown fieldConfig={fields.achBatchId} />
        </Form.Row>
      </Form.StyledSection>
    </>
  );
};

type GetCorporateReportParams = {
  body: GetCorporationReportDataTableRequest;
};

type FieldDefault = {defaultValue: string} | undefined;

export const CorporateReportListing = () => {
  const [formFields, setFormFields] = useState<{achBatchId?: Number}>();
  const fetchFields = useFields();
  const [dataTableState, setDataTableState] = useState<DataTableRequest>();

  const [columnSearch, setColumnSearch] = useState<
    DtoColumnSearch<CorporationReportDto>[]
  >([] as DtoColumnSearch<CorporationReportDto>[]);

  const defaultBatchId = (fetchFields.value?.achBatchId
    ?.inputProps as FieldDefault)?.defaultValue;

  useEffect(() => {
    defaultBatchId &&
      setColumnSearch([
        {
          propertyName: 'achBatchId',
          searchTerm: defaultBatchId,
          sortDirection: '',
        },
      ]);
  }, [defaultBatchId]);

  const getActiveFilterCount = () => {
    let count = columnSearch.filter((x) => x.searchTerm).length;
    return count;
  };

  const [tableResult, setTableResult] = useState<
    GetCorporationReportDataTableResponse | undefined
  >();

  const getTable = useCallback(async (params: GetCorporateReportParams) => {
    const fetchResult = await VendorReportsService.getCorporateReport({
      ...params,
      body: {
        ...params.body,
      },
    });

    setTableResult(fetchResult.result);

    return {
      hasErrors: fetchResult.hasErrors,
      validationFailures: fetchResult.validationFailures,
      result: fetchResult.result?.dataTablePage,
    };
  }, []);

  const onSubmit = (values) => {
    setFormFields(values);

    const columnSearchDtos: DtoColumnSearch<CorporationReportDto>[] = map(
      values,
      (value, key) => {
        return {
          propertyName: key,
          sortable: key,
          searchTerm: value,
          sortDirection: '',
        };
      }
    ) as DtoColumnSearch<CorporationReportDto>[];
    setColumnSearch(columnSearchDtos);
  };

  const [downloadExportState, downloadExport] = useAsyncFn(async () => {
    try {
      const blob = await VendorReportsService.getCorporateReportExcel(
        {
          body: dataTableState
            ? {
                ...dataTableState,
              }
            : undefined,
        },
        {
          responseType: 'blob',
        }
      );
      saveAs(blob, 'CORPORATE REPORT');
    } catch {
      notifications.error(
        'Something went wrong. Please try again. If the error persists, please contact an admin.'
      );
    }
  }, [dataTableState]);

  const formDefaults = {size: 'small'};

  const onStateChange = useCallback((tableState: DataTableRequest) => {
    setDataTableState(tableState);
  }, []);

  const fetchState = {
    loading: fetchFields.loading,
    error: fetchFields.error,
  };

  const pagedDataTable = usePagedDataTable(getTable, TableConfig, {
    columnParams: columnSearch,
    filterBadge: getActiveFilterCount(),
    filter: () => (
      <AsyncStateContainer {...fetchState}>
        {fetchFields.value && (
          <Form
            initialValues={formFields}
            formProps={formDefaults}
            onSubmit={onSubmit}
            render={() => (
              <>
                {fetchFields.value && <FormFields fields={fetchFields.value} />}
                <div className="form-actions">
                  <StyledButton type="submit" primary>
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </StyledButton>
                  {getActiveFilterCount() > 0 && (
                    <StyledButton onClick={() => setFormFields({})}>
                      Clear Filters
                    </StyledButton>
                  )}
                </div>
              </>
            )}
          />
        )}
      </AsyncStateContainer>
    ),
    actions: (
      <StyledButton
        loading={downloadExportState.loading}
        disabled={downloadExportState.loading}
        onClick={() => {
          downloadExport();
        }}
      >
        <FontAwesomeIcon icon={faFileExcel} /> Export
      </StyledButton>
    ),
    onStateChange: onStateChange,
    footer: () => (
      <Table.Row>
        <Table.Cell textAlign={'right'} colSpan={2}></Table.Cell>
        <Table.Cell textAlign={'right'}>Total</Table.Cell>
        <Table.Cell textAlign={'right'}>
          ${formatCurrency(tableResult?.amountTotal)}
        </Table.Cell>
        <Table.Cell textAlign={'right'}>
          ${formatCurrency(tableResult?.vendorFeeAmountTotal)}
        </Table.Cell>
        <Table.Cell textAlign={'right'}>
          ${formatCurrency(tableResult?.grossAmountTotal)}
        </Table.Cell>
      </Table.Row>
    ),
  });

  return (
    <StyledPageContainer css={styles} title={'Corporate Report'}>
      {pagedDataTable}
    </StyledPageContainer>
  );
};

type GetTable = (
  params: any
) => Promise<ApiResult<DataTablePage<CorporationReportDto>>>;
const GetTableMock = ((() => {}) as unknown) as GetTable;

const TableConfig = PagedDataTableConfig(GetTableMock, {
  columns: [
    {
      header: 'Vendor',
      sortable: 'vendorName',
      column: 'vendorName',
    },
    {
      header: 'Account',
      sortable: 'accountNumber',
      column: 'accountNumber',
    },
    {
      header: 'Date',
      sortable: 'date',
      render: (item) => {
        return <DateFormat date={item.date ?? ''} />;
      },
    },
    {
      header: 'Amount Due',
      sortable: 'amount',
      render: (item) => `$${formatCurrency(item.amount)}`,
      cellProps: {
        textAlign: 'right',
        width: '2',
      },
    },
    {
      header: 'Vendor Commission',
      sortable: 'vendorFeeAmount',
      render: (item) => `$${formatCurrency(item.vendorFeeAmount)}`,
      cellProps: {
        textAlign: 'right',
        width: '2',
      },
    },
    {
      header: 'Gross Sale',
      sortable: 'grossAmount',
      render: (item) => `$${formatCurrency(item.grossAmount)}`,
      cellProps: {
        textAlign: 'right',
        width: '2',
      },
    },
  ],
  defaultSort: {
    column: 'accountNumber',
    direction: 'ASC',
  },
});

const styles = css`
  font-weight: 500 !important;

  .ui.basic.table tfoot td {
    background: #ddd;
  }
`;
