import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import {cx} from 'emotion';
import React from 'react';
import {Button, ButtonProps} from 'semantic-ui-react';
import {useNotification} from '../hooks/use-notifications';

type CopyButton = {
  value: string;
  description?: string;
} & ButtonProps;

export const CopyButton = (props: CopyButton) => {
  let {value, description, className, ...buttonProps} = props;
  const notifications = useNotification();

  if (!description) {
    description = `"${value}"`;
  }

  return (
    <Button
      icon
      type="button"
      onClick={() => {
        copy(value);
        notifications.info(`${description} copied to clipboard`);
      }}
      className={cx('clear no-focus copy-button', className)}
      {...buttonProps}
    >
      <FontAwesomeIcon icon={faCopy} />
    </Button>
  );
};
