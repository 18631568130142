import React from 'react';
import css from '@emotion/css/macro';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import {Label, List} from 'semantic-ui-react';

export type StyledIcon = {
  icon: IconProp;
  iconColor?: string;
  customIconOverride?: React.ReactNode;
};

type FontAwesomeCircleWithIcon = {
  styledIcon: StyledIcon;
  iconSize: SizeProp;
  className?: string;
  onClick?: () => void;
};

export const FontAwesomeCircleWithIcon: React.FC<FontAwesomeCircleWithIcon> = ({
  styledIcon,
  iconSize,
  onClick,
  className,
}) => {
  return (
    <span css={getStyles(styledIcon.iconColor)} className={cx(className)}>
      <List.Item>
        <Label
          onClick={onClick}
          className="circle-label"
          size="tiny"
          circular
          horizontal
        >
          <FontAwesomeIcon
            className="fa-inverse"
            icon={styledIcon.icon}
            size={iconSize}
          />
        </Label>
      </List.Item>
    </span>
  );
};

const getStyles = (color?: string) => css`
  .circle-label {
    background-color: ${color} !important;
  }
`;
