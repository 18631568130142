import css from '@emotion/css/macro';
import {DateFormat} from '../../components/date';
import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {
  Button,
  Card,
  CardGroup,
  Container,
  Dimmer,
  Divider,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Modal,
  Popup,
  Radio,
  Segment,
} from 'semantic-ui-react';
import {StyledPageContainer} from '../../styled-page-container';
import {Media} from '../../styles/breakpoints';
import {
  LotteryApplicationListItemDto,
  DisplayLotteryItemDto,
  LotteryDisplayCategoryDto,
  LotteryShoppingCartService,
  LotteryCustomerApplicationService,
  GroupApplicationDetailsDto,
  CustomerPortalCustomersService,
  LotteryApplicationService,
} from '../../api/generated';
import {buildPath, routes} from '../../routes/config';
import {useHistory} from 'react-router-dom';
import {ShoppingCart} from './lottery-application-cart';
import {notifications} from '../../utils/notification-service';
import {fieldConfig, input} from '../../forms/schema-utils';
import {Form} from '../../forms';
import {StyledButton} from '../../styled-components/styled-buttons';
import {cstNow, momentCst} from '../../utils/date-time-helpers';
import {Theme} from '../../theme';
import {Flex} from '../../components/flex';
import {Typography} from '../../styled-components/typography';
import {PreferencePointsSection} from './lottery-preference-points-section';
import {
  getSectionTitle,
  isBlackBearLottery,
  isGeneralBlackBearLottery,
  isLandOwnerBlackBearLottery,
  isWmaBlackBearLottery,
} from './lottery-helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {PageTitle} from '../../components/page-title';
import {CustomerResidencyValidation} from '../customers/customer-residency-validation';
import {RevocationBannerContent} from '../../components/revocation-banner-content';
import {CatalogType} from '../../shared/product-catalog/product-catalog-utils';
import _ from 'lodash';
import {StyledConfirmButton} from '../../styled-components/styled-confirm-button';
import moment from 'moment';
import {BlackBearLotteryInfoModal} from './black-bear-lottery-info-modal';

enum LotteryType {
  available = 'AVAILABLE',
  leftover = 'LEFTOVER',
  unavailable = 'UNAVAILABLE',
}

type FieldConfigDto = {
  groupCode: string;
};

const fields = fieldConfig<FieldConfigDto>({
  groupCode: input({
    fieldLabel: 'Group Code',
  }),
});

const uneditableHuntStatus = [
  'unsuccessful',
  'declined',
  'removed',
  'disqualified',
  'ineligible',
];

//Font size constants
const basicFontSize = '12px';
const lotteryNameFontSize = '14px';
const lotterySectionFontSize = '16px';

export const LotteryApplications = () => {
  const [selectedSpeciesId, setSpeciesId] = useState(0);
  const [cartRefresh, refreshCart] = useState(false);

  //We want to refresh the lottery categorization whenever the filtered species changes, but also on cart transaction
  //This is so the lotteries are appropriately moved on application completion / addition / removal to and from the cart
  const fetchCustomerShoppingCart = useAsync(async () => {
    if (cartRefresh === null) {
      return;
    }

    const shoppingCart = await LotteryShoppingCartService.getShoppingCart();

    if (shoppingCart.hasErrors) {
      notifications.error('There was an error getting the shopping cart');
      return;
    }
    return shoppingCart.result;
  }, [cartRefresh]);

  const fetchDisplayLotteries = useAsync(async () => {
    if (cartRefresh === null) {
      return;
    }

    if (fetchCustomerShoppingCart.loading) {
      return;
    }

    const lotteries = await LotteryShoppingCartService.getDisplayLotteries({
      speciesId: selectedSpeciesId,
    });

    if (lotteries.hasErrors) {
      notifications.error('There was an error getting lotteries');
      return;
    }
    return lotteries.result;
  }, [selectedSpeciesId, cartRefresh, fetchCustomerShoppingCart.loading]);

  const fetchCustomerRevocations = useAsync(async () => {
    const revocationCount = await CustomerPortalCustomersService.getRevocationCount();

    if (revocationCount.hasErrors) {
      return;
    }
    return revocationCount.result;
  }, []);

  const displayLotteries = fetchDisplayLotteries.value;
  const shoppingCart = fetchCustomerShoppingCart.value;
  const activeRevocations = fetchCustomerRevocations.value;

  let applicationsCurrentlyinCart = Array<Number>();
  if (shoppingCart != null && (shoppingCart.lotteries?.length ?? 0) > 0) {
    shoppingCart.lotteries?.forEach((item) => {
      applicationsCurrentlyinCart.push(item.lotteryCustomerApplicationId ?? 0);
    });
  }

  const SpeciesOptions: React.FC<{
    species?: LotteryDisplayCategoryDto[];
  }> = ({species}) => {
    return (
      <section className="species-options">
        <Flex.Box>
          <StyledButton
            padded
            buttonColor={
              selectedSpeciesId === 0
                ? Theme.palette.blue50
                : Theme.palette.grey00
            }
            onClick={async () => setSpeciesId(0)}
            content={'ALL'}
          />
          {species &&
            species.map((item) => {
              return (
                <StyledButton
                  padded
                  key={item.id}
                  buttonColor={
                    item.id === selectedSpeciesId
                      ? Theme.palette.blue50
                      : Theme.palette.grey00
                  }
                  onClick={async () => setSpeciesId(item.id)}
                  content={item.code}
                />
              );
            })}
        </Flex.Box>
      </section>
    );
  };

  const breadcrumbs = [
    {title: 'Dashboard', url: routes.customer.dashboard},
    {title: 'Lotteries'},
  ];

  const revocationBannerContent = (
    <RevocationBannerContent
      activeRevocations={activeRevocations}
    ></RevocationBannerContent>
  );

  let upcoming: DisplayLotteryItemDto[] = [];
  let ineligible: DisplayLotteryItemDto[] = [];
  let ended: DisplayLotteryItemDto[] = [];

  const allUnavailableLotteries = displayLotteries?.unavailableLotteries?.sort(
    (a, b) =>
      +momentCst(a.applicationStartDate).format('yyyyMMDD') >
      +momentCst(b.applicationStartDate).format('yyyyMMDD')
        ? 1
        : -1
  );

  allUnavailableLotteries?.forEach((lottery) => {
    if (
      +momentCst(lottery.applicationStartDate).format('yyyyMMDD') >
      +momentCst(cstNow().toDate()).format('yyyyMMDD')
    ) {
      upcoming.push(lottery);
    } else if (
      +momentCst(lottery.applicationEndDate).format('yyyyMMDD') <
      +momentCst(cstNow().toDate()).format('yyyyMMDD')
    ) {
      ended.push(lottery);
    } else if (lottery.isIneligible) {
      ineligible.push(lottery);
    }
  });

  let unavailableLotteries: DisplayLotteryItemDto[] = upcoming;
  unavailableLotteries.push.apply(unavailableLotteries, ineligible);
  unavailableLotteries.push.apply(unavailableLotteries, ended);

  const hasLeftoversInCart = shoppingCart?.lotteries?.some(
    ({isLeftover}) => isLeftover === true
  );

  return (
    <>
      <CustomerResidencyValidation
        catalogType={CatalogType.customer}
        isLotteryListing={true}
      />
      {activeRevocations && activeRevocations?.length > 0 ? (
        <Message
          error
          size="huge"
          header="Restricted"
          content={revocationBannerContent}
        />
      ) : (
        <StyledPageContainer
          css={styles}
          title={<PageTitle title={breadcrumbs} />}
          fluid
        >
          <Dimmer.Dimmable>
            <div className="main-content">
              <div className="listing-container">
                {fetchCustomerRevocations.loading ||
                  fetchCustomerShoppingCart.loading ||
                  (fetchDisplayLotteries.loading && (
                    <Dimmer active inverted>
                      <Loader size="large">Loading...</Loader>
                    </Dimmer>
                  ))}
                {displayLotteries && (
                  <>
                    {hasLeftoversInCart && shoppingCart?.expirationTimestamp && (
                      <Message warning>
                        Your cart contains one or more leftover lottery
                        applications. Due to high demand, please complete your
                        transaction by{' '}
                        <b>
                          {moment(shoppingCart.expirationTimestamp).format(
                            'LT'
                          )}
                        </b>{' '}
                        to be guaranteed a spot.
                      </Message>
                    )}
                    <SpeciesOptions species={displayLotteries.species} />

                    <CustomerLotteryList
                      customerLotteryApplications={
                        displayLotteries.lotteryCustomerApplications
                      }
                      cartRefresh={cartRefresh}
                      refreshCart={refreshCart}
                      applicationsCurrentlyinCart={applicationsCurrentlyinCart}
                    />

                    <section className="listing-section available-lotteries">
                      <LotteryList
                        lotteries={displayLotteries.availableLotteries}
                        type={LotteryType.available}
                        cartRefresh={cartRefresh}
                        refreshCart={refreshCart}
                      />
                    </section>

                    <section className="listing-section leftovers">
                      <LotteryList
                        lotteries={displayLotteries.leftoverLotteries}
                        type={LotteryType.leftover}
                      />
                    </section>

                    <section className="listing-section other-lotteries">
                      <LotteryList
                        lotteries={unavailableLotteries}
                        type={LotteryType.unavailable}
                      />
                    </section>
                  </>
                )}
              </div>

              <section className="shopping-cart-container">
                <ShoppingCart
                  cartContents={shoppingCart}
                  cartRefresh={cartRefresh}
                  refreshCart={refreshCart}
                />
              </section>
            </div>
          </Dimmer.Dimmable>
        </StyledPageContainer>
      )}
    </>
  );
};

const CustomerLotteryList: React.FC<{
  customerLotteryApplications?: LotteryApplicationListItemDto[];
  cartRefresh: boolean;
  refreshCart: any;
  applicationsCurrentlyinCart: Array<Number>;
}> = ({
  customerLotteryApplications,
  cartRefresh,
  refreshCart,
  applicationsCurrentlyinCart,
}) => {
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
  const [isAcceptButtonLoading, setIsAcceptButtonLoading] = useState<boolean>(
    false
  );
  const [isDeclineButtonLoading, setIsDeclineButtonLoading] = useState<boolean>(
    false
  );
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);
  const [
    currentApplication,
    setCurrentApplication,
  ] = useState<LotteryApplicationListItemDto>();
  const history = useHistory();

  const applicationStatuses = [
    'removed',
    'ineligible',
    'disqualified',
    'unsuccessful',
  ];

  async function addCartItem() {
    if (!currentApplication) {
      return;
    }

    setIsAddingToCart(true);
    const result = await LotteryShoppingCartService.addAward({
      lotteryCustomerApplicationId: currentApplication.id,
    });

    if (result.hasErrors) {
      notifications.error('There was a problem adding that item to the cart.');
    }
    refreshCart(!cartRefresh);
    setShowAcceptModal(false);
    setIsAddingToCart(false);
  }

  async function declineAwardAfterAcceptance() {
    setIsDeclineButtonLoading(true);
    if (!currentApplication) {
      notifications.error('No Lottery Application was found to decline.');
      setIsDeclineButtonLoading(false);
      return;
    }

    const result = await LotteryCustomerApplicationService.declineAcceptedAward(
      {
        lotteryCustomerApplicationId: currentApplication.id,
      }
    );

    if (result.hasErrors) {
      notifications.error('There was a problem updating the award status');
    } else {
      notifications.success(`Award declined successfully`);
    }

    setShowDeclineModal(false);
    setIsDeclineButtonLoading(false);
    refreshCart(!cartRefresh);
  }

  async function acceptOrDeclineAward(wasAccepted: boolean) {
    setIsAcceptButtonLoading(true);
    if (!currentApplication) {
      setIsAcceptButtonLoading(false);
      return;
    }

    if (
      currentApplication.hasAwardFee &&
      wasAccepted &&
      currentApplication.isPaidOnline
    ) {
      addCartItem();
      setIsAcceptButtonLoading(false);
      return;
    }
    const result = await LotteryCustomerApplicationService.updateAwardAcceptedStatus(
      {
        lotteryCustomerApplicationId: currentApplication.id,
        wasAccepted: wasAccepted,
      }
    );

    if (result.hasErrors || result.result === false) {
      notifications.error('There was a problem updating the award status');
    } else {
      //Don't show this validation message if the award is paid offline
      if (currentApplication.isPaidOnline) {
        notifications.success(
          `Award ${wasAccepted ? 'accepted' : 'declined'} successfully`
        );
      }
    }

    wasAccepted ? setShowAcceptModal(false) : setShowDeclineModal(false);
    setIsAcceptButtonLoading(false);
    refreshCart(!cartRefresh);
  }

  const [changeGroupModalOpen, setChangeGroupModalOpen] = React.useState(false);
  const [selectedLotteryId, setSelectedLotteryId] = React.useState(0);
  const [selectedApplicationId, setSelectedApplicationId] = React.useState(0);
  const [selectedGroupCode, setSelectedGroupCode] = React.useState('');
  const [changeGroupModalContent, setChangeGroupModalContent] = React.useState(
    {} as GroupApplicationDetailsDto
  );

  const EditGroupCodeModal: React.FC<{
    cartRefresh?: boolean;
    refreshCart?: any;
  }> = () => {
    const [isLoading, setIsLoading] = React.useState(false);

    const onSearch = async (values: {groupCode: string}) => {
      if (values.groupCode === null || !values.groupCode) {
        notifications.error('Please enter a group code');
        return;
      }

      const response = await LotteryShoppingCartService.getGroup({
        lotteryId: selectedLotteryId,
        code: values.groupCode,
      });

      if (!response.result) {
        response.validationFailures?.forEach((error) => {
          notifications.error(error.errorMessage);
        });
      } else {
        setSelectedGroupCode(values.groupCode);
        setChangeGroupModalContent(response.result);
      }
    };

    const onSubmit = async () => {
      setIsLoading(true);
      const response = await LotteryApplicationService.updateApplicationGroupCode(
        {
          body: {
            lotteryCustomerApplicationId: selectedApplicationId,
            lotteryId: selectedLotteryId,
            newGroupCode: selectedGroupCode,
          },
        }
      );

      if (response.hasErrors) {
        response.validationFailures?.forEach((x) =>
          notifications.error(x.errorMessage)
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        refreshCart(!cartRefresh);
        setChangeGroupModalOpen(false);
        notifications.success('Group Successfully Changed');
      }
    };

    return (
      <Modal
        onClose={() => setChangeGroupModalOpen(false)}
        onOpen={() => setChangeGroupModalOpen(true)}
        open={changeGroupModalOpen}
        size={'small'}
      >
        <Modal.Header>Join /Change Group</Modal.Header>
        <Modal.Content>
          <Form
            className="inline"
            onSubmit={onSearch}
            render={() => (
              <>
                {fields && (
                  <>
                    <p>Enter a group code to join an existing group</p>
                    <Form.Input
                      fieldConfig={fields.groupCode}
                      defaultValue={selectedGroupCode}
                    />
                    <Button primary content={'Search'} />
                    {_.isEmpty(changeGroupModalContent) && (
                      <Button
                        onClick={() => setChangeGroupModalOpen(false)}
                        content={'Cancel'}
                      />
                    )}
                  </>
                )}
              </>
            )}
          />
          <Modal.Description>
            {!_.isEmpty(changeGroupModalContent) && (
              <>
                <Divider hidden />
                <h2>Group Information</h2>
                <p>
                  <strong>Leader:</strong> {changeGroupModalContent.leader}
                </p>
                <p>
                  <strong>Group Code:</strong> {changeGroupModalContent.code}
                </p>
                <p>
                  <strong>Group Hunt Selections:</strong>
                </p>
                <CardGroup>
                  {changeGroupModalContent.hunts?.map((hunt) => {
                    return (
                      <Card key={hunt.id}>
                        <Card.Content>
                          <Card.Header>{hunt.description}</Card.Header>
                        </Card.Content>
                        <Card.Content>
                          <Card.Description>
                            <p>
                              <Icon name="marker" />
                              {hunt.huntLocationName}
                            </p>
                            <p>
                              <Icon name="clock" />
                              <DateFormat date={hunt.startDate} /> -{' '}
                              <DateFormat date={hunt.endDate} />
                            </p>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    );
                  })}
                </CardGroup>
                <Message color="orange">
                  Warning: Joining a group can not be undone. Ensure this group
                  has the correct information before proceeding
                </Message>
              </>
            )}
          </Modal.Description>
        </Modal.Content>
        {!_.isEmpty(changeGroupModalContent) && (
          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={() => setChangeGroupModalOpen(false)}
            />
            <StyledConfirmButton
              primary
              loading={isLoading}
              color={`${Theme.palette.green700}`}
              buttonHoverColor={`${Theme.palette.green600}`}
              text="Join Group"
              onConfirm={onSubmit}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  };

  return customerLotteryApplications ? (
    <section className="your-lotteries listing-section">
      <Typography variant="heading3" color={Theme.palette.blue800}>
        Your Lotteries
      </Typography>
      <AwardDeclineModal
        showDeclineModal={showDeclineModal}
        setShowDeclineModal={setShowDeclineModal}
        isDeclineButtonLoading={isDeclineButtonLoading}
        acceptOrDeclineAward={
          !currentApplication?.awardAccepted
            ? acceptOrDeclineAward
            : declineAwardAfterAcceptance
        }
      />
      <AwardAcceptModal
        showAcceptModal={showAcceptModal}
        setShowAcceptModal={setShowAcceptModal}
        isAcceptButtonLoading={isAcceptButtonLoading}
        acceptOrDeclineAward={acceptOrDeclineAward}
      />
      {customerLotteryApplications.length === 0 ? (
        <NoResultsSegment />
      ) : (
        customerLotteryApplications.map((application) => {
          const itemInCart = applicationsCurrentlyinCart.includes(
            application.id
          );

          const awardAcceptanceOverdue =
            +momentCst(cstNow().toDate()).format('yyyyMMDD') >
              +momentCst(application.acceptAwardByDate).format('yyyyMMDD') &&
            application.isAward &&
            !application.awardAccepted;

          const showAcceptRewardButton =
            application.isAward &&
            !application.awardAccepted &&
            !application.isLeftover;

          const showAwardPrice =
            !application.isAwardFeePaid && application.hasAwardFee;

          const noAwardFeeMessage = 'None';

          const isUnpaidOfflineApplication =
            !application.isApplicationFeePaid &&
            !application.isPaidOnline &&
            !application.isAward;

          const isUnpaidOfflineAward =
            !application.isPaidOnline &&
            application.isAward &&
            application.hasAwardFee &&
            !application.isAwardFeePaid &&
            application.awardAccepted;

          const showAccepted =
            application.isAward &&
            application.awardAccepted &&
            !isUnpaidOfflineAward;

          const showDeclined =
            !application.isAward &&
            application.awardAccepted === false &&
            !isUnpaidOfflineAward;

          const showWon =
            application.isAward &&
            !application.awardAccepted &&
            application.awardAccepted !== false &&
            !isUnpaidOfflineAward &&
            !awardAcceptanceOverdue;

          const showOverdue =
            application.isAward &&
            !application.awardAccepted &&
            awardAcceptanceOverdue &&
            !isUnpaidOfflineApplication &&
            !application.isLeftover;

          const showInCart =
            !application.isApplicationFeePaid &&
            application.isPaidOnline &&
            !application.isAward;

          const huntsInOrderOfRank = application.hunts?.sort(function (a, b) {
            return a.rank - b.rank;
          });
          return (
            <Segment key={application.id}>
              {application.isCanceled ? (
                <Typography variant="heading4" color={Theme.palette.blue800}>
                  {application.name}
                  <Message className="canceled-message" warning>
                    This lottery has been canceled. Please check your email for
                    further information.
                  </Message>
                </Typography>
              ) : (
                <div className="content-grid">
                  <section className="title-section">
                    <Typography
                      variant="heading4"
                      color={Theme.palette.blue800}
                    >
                      {application.name}
                      {application.quantity > 1
                        ? ` (x${application.quantity})`
                        : ''}

                      {showAccepted ? (
                        <Label
                          color="green"
                          horizontal
                          className="lottery-label"
                        >
                          <Icon name="trophy" />
                          ACCEPTED
                        </Label>
                      ) : null}

                      {/* TODO: investigate isAward returned value */}
                      {showDeclined ? (
                        <Label color="red" horizontal className="lottery-label">
                          DECLINED
                        </Label>
                      ) : null}

                      {showWon ? (
                        <Label horizontal className="lottery-label lottery-won">
                          <Icon name="trophy" />
                          WON
                        </Label>
                      ) : null}

                      {showOverdue ? (
                        <Label color="red" horizontal className="lottery-label">
                          <Icon name="x" />
                          OVERDUE{' '}
                          <Popup
                            trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                            wide="very"
                          >
                            You have been removed from this lottery because the
                            award was not accepted by the deadline of{' '}
                            {momentCst(application.acceptAwardByDate).format(
                              'MMMM Do, YYYY'
                            )}
                          </Popup>
                        </Label>
                      ) : null}

                      {!application.isAward &&
                      application.isApplicationFeePaid &&
                      application?.status?.toLowerCase() === 'submitted' ? (
                        <Label className="lottery-label lottery-submitted">
                          <Icon name="trophy" />
                          {application.status}
                        </Label>
                      ) : (
                        showInCart && (
                          <Label className="lottery-label lottery-submitted">
                            <Icon name="cart" />
                            IN CART
                          </Label>
                        )
                      )}

                      {(isUnpaidOfflineApplication || isUnpaidOfflineAward) &&
                        ['submitted', 'unaccepted'].includes(
                          application?.status?.toLowerCase() ?? ''
                        ) && (
                          <Label className={'lottery-label lottery-submitted'}>
                            PENDING PAYMENT{' '}
                            <Popup
                              trigger={<FontAwesomeIcon icon={faInfoCircle} />}
                              wide="very"
                            >
                              Before you can be entered into the drawing, the
                              application fee must be paid. This lottery's
                              application fee is paid offline. Once paid, your
                              appllication will be set to 'SUBMITTED'
                            </Popup>
                          </Label>
                        )}

                      {applicationStatuses.includes(
                        application?.status?.toLowerCase() ?? ''
                      ) ? (
                        <Label>{application.status}</Label>
                      ) : null}
                    </Typography>
                    <br />
                    <Flex.Row>
                      <PreferencePointsSection application={application} />
                      {application.groupCode &&
                        application.isApplicationFeePaid && (
                          <div className="group-info">
                            <Flex.Row>
                              <Typography
                                variant="small"
                                color={Theme.palette.grey700}
                                fontWeight={'500'}
                              >
                                Group Code: {application.groupCode}
                              </Typography>
                            </Flex.Row>
                            {application.hasGroupMembers && (
                              <Flex.Row>
                                <Typography
                                  variant="small"
                                  color={Theme.palette.grey700}
                                  fontWeight={'500'}
                                >
                                  Leader: {application.groupLeader}
                                </Typography>
                              </Flex.Row>
                            )}
                          </div>
                        )}
                    </Flex.Row>

                    {showAcceptRewardButton &&
                      application.acceptAwardByDate &&
                      !awardAcceptanceOverdue && (
                        <Typography
                          variant="subheading1"
                          fontSize={basicFontSize}
                          fontWeight={'500'}
                          color={Theme.palette.grey700}
                        >
                          Accept Before:{' '}
                          <DateFormat date={application.acceptAwardByDate} />
                        </Typography>
                      )}

                    {awardAcceptanceOverdue && application.acceptAwardByDate && (
                      <Typography
                        variant="subheading1"
                        fontSize={basicFontSize}
                        fontWeight={'500'}
                        color={Theme.palette.grey700}
                      >
                        Deadline was{' '}
                        <DateFormat date={application.acceptAwardByDate} />{' '}
                      </Typography>
                    )}

                    {showAcceptRewardButton &&
                    application.awardAccepted == null ? null : (
                      <Typography
                        variant="small"
                        fontSize={basicFontSize}
                        fontWeight={'500'}
                        color={Theme.palette.grey700}
                      >
                        Draw Date:{' '}
                        <DateFormat date={application.drawDate ?? ''} />
                      </Typography>
                    )}
                  </section>

                  <section className="amount-section">
                    <Flex.Col className="fee-info">
                      <Typography
                        variant="heading4"
                        color={Theme.palette.grey700}
                        className="column-two-row"
                      >
                        {application.isAwardFeePaid
                          ? 'Award Fee Paid'
                          : showAwardPrice
                          ? `Award Fee: ${
                              application.isPaidOnline
                                ? `$${application.awardPrice}`
                                : 'Fee Paid Offline'
                            }`
                          : `Award Fee: ${noAwardFeeMessage}`}
                      </Typography>
                      <Typography
                        variant="heading4"
                        color={Theme.palette.grey700}
                        className="column-two-row"
                      >
                        {!application.isApplicationFeePaid &&
                          (!application.isAward || application.isLeftover) &&
                          `Application Fee: ${
                            application.isPaidOnline
                              ? application.price > 0 &&
                                !application.isApplicationFeeWaived
                                ? `$${application.price.toFixed(2).toString()}`
                                : 'Free'
                              : 'Fee Paid Offline'
                          }`}
                      </Typography>
                      {!application.isAward &&
                        application.isApplicationFeePaid &&
                        application?.status?.toLowerCase() === 'submitted' &&
                        !application.isLeftover &&
                        application.isGroupLottery &&
                        +momentCst(cstNow().toDate()).format('yyyyMMDD') <=
                          +momentCst(application.applicationEndDate).format(
                            'yyyyMMDD'
                          ) && (
                          <StyledButton
                            secondary
                            size="sm"
                            onClick={() => {
                              setSelectedLotteryId(application.lotteryId);
                              setChangeGroupModalOpen(true);
                              setChangeGroupModalContent(
                                {} as GroupApplicationDetailsDto
                              );
                              setSelectedGroupCode('');
                              setSelectedApplicationId(application.id);
                            }}
                            content={
                              application.hasGroupMembers
                                ? 'Change Group'
                                : 'Join Group'
                            }
                          />
                        )}
                    </Flex.Col>
                  </section>

                  <Flex.Row className="details-section" flexWrap="wrap">
                    {huntsInOrderOfRank?.map((x, index) => {
                      return (
                        <section className="hunt" key={index}>
                          <Typography
                            variant="small"
                            color={Theme.palette.blue800}
                            fontFamily={'Hind Madurai'}
                            fontWeight={'600'}
                          >
                            {x.rank ? `${x.rank} - ` : ''}
                            {x.name}
                          </Typography>

                          <Typography
                            variant="small"
                            color={Theme.palette.blue800}
                          >
                            {x.huntLocationName}
                          </Typography>

                          <Typography
                            variant="small"
                            color={Theme.palette.blue800}
                          >
                            <DateFormat date={x.startDate} />
                            {' - '}
                            <DateFormat date={x.endDate} />
                          </Typography>
                        </section>
                      );
                    })}
                  </Flex.Row>
                  <section className="actions-section">
                    <div className="action-buttons">
                      {
                        /* The decline button will display before and after accepting the award*/
                        ['accepted', 'unaccepted'].includes(
                          application?.status?.toLowerCase() ?? ''
                        ) &&
                          cstNow().isBefore(
                            application.hunts?.find((x) => x.isSuccessful)
                              ?.startDate
                          ) && (
                            <StyledButton
                              type="submit"
                              buttonColor={Theme.palette.grey50}
                              buttonTextColor={Theme.palette.grey700}
                              onClick={() => {
                                setCurrentApplication(application);
                                setShowDeclineModal(true);
                              }}
                              content="Decline"
                              size="sm"
                              disabled={
                                applicationsCurrentlyinCart.includes(
                                  application.id
                                ) || isAddingToCart
                              }
                            />
                          )
                      }
                      {showAcceptRewardButton &&
                        application.awardAccepted == null && (
                          <StyledButton
                            className="primary-action"
                            primary
                            type="submit"
                            buttonColor={Theme.palette.green100}
                            buttonTextColor={Theme.palette.green800}
                            buttonHoverColor={Theme.palette.green200}
                            buttonHoverTextColor={Theme.palette.green50}
                            onClick={() => {
                              setCurrentApplication(application);
                              setShowAcceptModal(true);
                            }}
                            disabled={itemInCart || isAddingToCart}
                            content={
                              itemInCart
                                ? 'In Cart'
                                : isAddingToCart
                                ? 'Adding...'
                                : 'Accept'
                            }
                            size="sm"
                          />
                        )}
                    </div>
                  </section>
                  {application.isGroupLeader &&
                    !application.isAward &&
                    !uneditableHuntStatus.includes(
                      application.status?.toLowerCase() ?? ''
                    ) && (
                      <StyledButton
                        secondary
                        onClick={() => {
                          var huntSelectionRoute = buildPath(
                            routes.customer.lotteryHunts,
                            {
                              id: Number(application.lotteryId),
                            }
                          );
                          history.push(huntSelectionRoute);
                        }}
                        content={'Edit Hunt Selection'}
                      />
                    )}
                </div>
              )}
            </Segment>
          );
        })
      )}
      <EditGroupCodeModal refreshCart={refreshCart} cartRefresh={cartRefresh} />
    </section>
  ) : null;
};

const AwardDeclineModal: React.FC<{
  showDeclineModal: boolean;
  setShowDeclineModal: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineButtonLoading: boolean;
  acceptOrDeclineAward: any;
}> = ({
  showDeclineModal,
  setShowDeclineModal,
  isDeclineButtonLoading,
  acceptOrDeclineAward,
}) => {
  return (
    <Modal
      onClose={() => setShowDeclineModal(false)}
      onOpen={() => setShowDeclineModal(true)}
      open={showDeclineModal}
      size={'tiny'}
    >
      <Modal.Header>Decline Award?</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Are you sure you want to decline this lottery award? This action
            can't be undone and your application fee will not be reimbursed.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton
          secondary
          content="Cancel"
          onClick={() => setShowDeclineModal(false)}
        />
        <StyledButton
          primary
          content="Decline Award"
          onClick={() => acceptOrDeclineAward(false)}
          loading={isDeclineButtonLoading}
          disabled={isDeclineButtonLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

const AwardAcceptModal: React.FC<{
  showAcceptModal: boolean;
  setShowAcceptModal: React.Dispatch<React.SetStateAction<boolean>>;
  isAcceptButtonLoading: boolean;
  acceptOrDeclineAward: any;
}> = ({
  showAcceptModal,
  setShowAcceptModal,
  isAcceptButtonLoading,
  acceptOrDeclineAward,
}) => {
  return (
    <Modal
      onClose={() => setShowAcceptModal(false)}
      onOpen={() => setShowAcceptModal(true)}
      open={showAcceptModal}
      size={'tiny'}
    >
      <Modal.Header>Accept Award?</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Are you sure you want to accept this award?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton
          secondary
          content="Cancel"
          onClick={() => setShowAcceptModal(false)}
        />
        <StyledButton
          content="Accept Award"
          primary
          onClick={() => acceptOrDeclineAward(true)}
          loading={isAcceptButtonLoading}
          disabled={isAcceptButtonLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

const LotteryList: React.FC<{
  lotteries?: DisplayLotteryItemDto[];
  type: string;
  cartRefresh?: boolean;
  refreshCart?: any;
}> = ({lotteries, type, cartRefresh, refreshCart}) => {
  const history = useHistory();
  const [selectedLotteryId, setSelectedLotteryId] = React.useState(0);
  const [applyModalOpen, setApplyModalOpen] = React.useState(false);
  const [groupModalOpen, setGroupModalOpen] = React.useState(false);
  const [groupModalContent, setGroupModalContent] = React.useState(
    {} as GroupApplicationDetailsDto
  );
  const [
    blackBearLotteryInfoModalOpen,
    setBlackBearLotteryInfoModalOpen,
  ] = useState<boolean>(false);
  const [
    selectedBlackBearLottery,
    setSelectedBlackBearLottery,
  ] = useState<DisplayLotteryItemDto>();

  const openBlackBearLotteryInfoModal = () => {
    setBlackBearLotteryInfoModalOpen(true);
  };

  const closeBlackBearLotteryInfoModal = () => {
    setBlackBearLotteryInfoModalOpen(false);
  };

  const isLeftover = type === LotteryType.leftover;

  const onLotteryClick = (lottery: DisplayLotteryItemDto) => {
    const isLotteryBlackBear = isBlackBearLottery(
      lottery.preferencePointType?.lotteryCode || ''
    );

    if (isLotteryBlackBear) {
      setSelectedBlackBearLottery(lottery);
      setBlackBearLotteryInfoModalOpen(true);
    } else {
      setSelectedBlackBearLottery(undefined);
      setBlackBearLotteryInfoModalOpen(false);
      onLotteryApply(lottery, isLeftover);
    }
  };

  const onLotteryApply = (
    lottery: DisplayLotteryItemDto,
    isLeftovers: boolean
  ) => {
    if (lottery.isGroupApplication && !isLeftovers) {
      setSelectedLotteryId(lottery.id);
      setApplyModalOpen(true);
    } else {
      const url = buildPath(routes.customer.lotteryHunts, {id: lottery.id});
      window.scrollTo(0, 0);
      history.push(url);
    }
  };

  const ApplyModal: React.FC<{}> = () => {
    const [selectedApplicationType, setSelectedApplicationType] = useState('');

    const onSubmit = async (values: {groupCode: string}) => {
      if (selectedApplicationType === 'JoinGroup') {
        if (!values.groupCode) {
          notifications.error('Please enter a group code');
          return;
        }

        const response = await LotteryShoppingCartService.getGroup({
          lotteryId: selectedLotteryId,
          code: values.groupCode,
        });

        if (!response.result) {
          response.validationFailures?.forEach((error) => {
            notifications.error(error.errorMessage);
          });
        } else {
          setApplyModalOpen(false);
          setGroupModalContent(response.result);
          setGroupModalOpen(true);
        }
      } else {
        history.push(
          buildPath(routes.customer.lotteryHunts, {id: selectedLotteryId})
        );
      }
    };

    return (
      <Modal
        css={applyModalStyles}
        onClose={() => setApplyModalOpen(false)}
        onOpen={() => setApplyModalOpen(true)}
        open={applyModalOpen}
        size={'small'}
      >
        <Modal.Header>
          <Header as="h3">Apply to Lottery</Header>
          <Header as="h4">Choose an application type</Header>
        </Modal.Header>
        <Form
          className="inline"
          onSubmit={onSubmit}
          render={() => (
            <>
              {fields && (
                <>
                  <Modal.Content>
                    <Modal.Description>
                      <Form.Row>
                        <Container>
                          <Radio
                            label="Individual"
                            checked={selectedApplicationType === 'Individual'}
                            onChange={() =>
                              setSelectedApplicationType('Individual')
                            }
                          />
                        </Container>
                      </Form.Row>
                      <Divider hidden />
                      <Form.Row>
                        <Container>
                          <Radio
                            label="Create Group"
                            checked={selectedApplicationType === 'CreateGroup'}
                            onChange={() =>
                              setSelectedApplicationType('CreateGroup')
                            }
                          />
                          <p>
                            Generate a group code you can share with friends
                          </p>
                        </Container>
                      </Form.Row>
                      <Divider hidden />
                      <Form.Row>
                        <Container>
                          <Radio
                            label="Join Group"
                            checked={selectedApplicationType === 'JoinGroup'}
                            onChange={() =>
                              setSelectedApplicationType('JoinGroup')
                            }
                          />
                          <p>Enter a group code to join an existing group</p>
                          <Form.Input
                            disabled={selectedApplicationType !== 'JoinGroup'}
                            fieldRequired={
                              selectedApplicationType === 'JoinGroup'
                            }
                            fieldConfig={fields.groupCode}
                          />
                        </Container>
                      </Form.Row>
                    </Modal.Description>
                  </Modal.Content>
                  <Divider />
                  <Modal.Actions className="apply-modal-actions-container">
                    <Button
                      content="Cancel"
                      onClick={() => setApplyModalOpen(false)}
                    />
                    <StyledButton
                      primary
                      type="submit"
                      className="field-submission-button"
                      disabled={!selectedApplicationType}
                      content="Continue"
                    />
                  </Modal.Actions>
                </>
              )}
            </>
          )}
        />
      </Modal>
    );
  };

  const submitJoinGroup = async () => {
    setGroupModalOpen(false);

    const response = await LotteryShoppingCartService.addGroupApplication({
      lotteryId: selectedLotteryId,
      code: groupModalContent.code,
    });

    if (response.hasErrors) {
      response.validationFailures?.forEach((x) =>
        notifications.error(x.errorMessage)
      );
      return response;
    }

    refreshCart(!cartRefresh);
    notifications.success('Group successfully joined.');
  };

  const ConfirmationModal: React.FC<{
    cartRefresh?: boolean;
    refreshCart?: any;
  }> = () => {
    return (
      <Modal
        onClose={() => setGroupModalOpen(false)}
        onOpen={() => setGroupModalOpen(true)}
        open={groupModalOpen}
        size={'large'}
      >
        <Modal.Header>Group Information</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              <strong>Leader:</strong> {groupModalContent.leader}
            </p>
            <p>
              <strong>Group Code:</strong> {groupModalContent.code}
            </p>
            <Divider />
            <CardGroup>
              {groupModalContent.hunts?.map((hunt) => {
                return (
                  <Card key={hunt.id}>
                    <Card.Content>
                      <Card.Header>{hunt.description}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      <Card.Description>
                        <p>
                          <Icon name="marker" />
                          {hunt.huntLocationName}
                        </p>
                        <p>
                          <Icon name="clock" />
                          <DateFormat date={hunt.startDate} /> -{' '}
                          <DateFormat date={hunt.endDate} />
                        </p>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                );
              })}
            </CardGroup>
            <Divider hidden />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setGroupModalOpen(false)} />
          <Button
            primary
            positive
            content="Join Group"
            onClick={submitJoinGroup}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  return lotteries ? (
    <div className="lottery-list-container">
      <Typography
        variant="heading3"
        color={Theme.palette.blue800}
        fontSize={lotterySectionFontSize}
        fontWeight={'600'}
      >
        {getSectionTitle(type)}
      </Typography>

      {isLeftover && (
        <div className="leftover-disclaimer-container">
          <FontAwesomeIcon
            icon={faInfoCircle}
            size={'sm'}
            color={Theme.palette.blue300}
          />
          <Typography
            variant="heading4"
            color={Theme.palette.grey700}
            fontSize={basicFontSize}
            className={'leftover-info-text'}
          >
            By purchasing, you agree to pay the application fee and award fee. A
            spot will be guaranteed in the available hunts upon completion.
          </Typography>
        </div>
      )}

      {selectedBlackBearLottery && (
        <BlackBearLotteryInfoModal
          blackBearLotteryInfoModalOpen={blackBearLotteryInfoModalOpen}
          isLeftover={isLeftover}
          blackBearLottery={selectedBlackBearLottery}
          openBlackBearLotteryInfoModal={openBlackBearLotteryInfoModal}
          closeBlackBearLotteryInfoModal={closeBlackBearLotteryInfoModal}
          handleOnLotteryApply={onLotteryApply}
        />
      )}

      <ApplyModal />

      <ConfirmationModal cartRefresh={cartRefresh} refreshCart={refreshCart} />
      {lotteries.length === 0 ? (
        <NoResultsSegment />
      ) : (
        lotteries.map((lottery) => {
          const isLotteryWmaBlackBear = isWmaBlackBearLottery(
            lottery.preferencePointType?.lotteryCode || ''
          );

          const isLotteryLandOwnerBlackBear = isLandOwnerBlackBearLottery(
            lottery.preferencePointType?.lotteryCode || ''
          );

          const isLotteryGeneralBlackBear = isGeneralBlackBearLottery(
            lottery.preferencePointType?.lotteryCode || ''
          );
          return (
            <Segment key={lottery.id}>
              <div className="content-grid">
                <section className="title-section">
                  <Typography
                    variant="heading4"
                    color={Theme.palette.blue800}
                    fontSize={lotteryNameFontSize}
                    fontWeight={'600'}
                  >
                    {lottery.name}
                    {isLeftover && lottery.hasDeclined && (
                      <Label color="red" horizontal className="lottery-label">
                        DECLINED
                      </Label>
                    )}
                  </Typography>
                </section>

                <section className="amount-section">
                  <Typography
                    variant="heading4"
                    color={Theme.palette.grey700}
                    className="column-two-row"
                  >
                    {isLeftover ? (
                      <>
                        Total Leftover Fee:{' '}
                        {lottery.leftoverTotalPrice > 0
                          ? lottery.leftoverTotalPrice.toFixed(2).toString()
                          : 'Free'}
                      </>
                    ) : (
                      <>
                        Application Fee:{' '}
                        {lottery.isFeePaidOnline
                          ? lottery.price > 0 && !lottery.isApplicationFeeWaived
                            ? `$${lottery.price.toFixed(2).toString()}`
                            : 'Free'
                          : 'Fee Paid Offline'}
                      </>
                    )}
                  </Typography>
                </section>

                <section className="subtitle-section">
                  <Typography
                    variant="small"
                    color={Theme.palette.grey700}
                    fontSize={basicFontSize}
                  >
                    {isLeftover ? (
                      <p>
                        Available for purchase from:{' '}
                        <DateFormat date={lottery.leftoversStartDate ?? ''} /> -{' '}
                        <DateFormat date={lottery.leftoversEndDate ?? ''} />
                      </p>
                    ) : (
                      <p>
                        Applications Accepted From:{' '}
                        <DateFormat date={lottery.applicationStartDate} /> -{' '}
                        <DateFormat date={lottery.applicationEndDate} />
                      </p>
                    )}
                  </Typography>
                </section>

                <section className="actions-section">
                  {type === LotteryType.unavailable ? (
                    +momentCst(lottery.applicationStartDate).format(
                      'yyyyMMDD'
                    ) > +momentCst(cstNow().toDate()).format('yyyyMMDD') ? (
                      <StyledButton
                        disabled
                        content={'Upcoming'}
                        buttonColor={Theme.palette.grey50}
                        buttonTextColor={Theme.palette.grey700}
                      />
                    ) : +momentCst(lottery.applicationEndDate).format(
                        'yyyyMMDD'
                      ) < +momentCst(cstNow().toDate()).format('yyyyMMDD') ? (
                      <StyledButton
                        disabled
                        content={'Ended'}
                        buttonColor={Theme.palette.grey50}
                        buttonTextColor={Theme.palette.grey700}
                      />
                    ) : (
                      lottery.isIneligible && (
                        <StyledButton
                          disabled
                          content={'Ineligible'}
                          buttonColor={Theme.palette.grey50}
                          buttonTextColor={Theme.palette.grey700}
                        />
                      )
                    )
                  ) : (
                    <StyledButton
                      primary
                      type="submit"
                      buttonColor={Theme.palette.green100}
                      buttonTextColor={Theme.palette.green800}
                      buttonHoverColor={Theme.palette.green200}
                      buttonHoverTextColor={Theme.palette.green50}
                      onClick={() => onLotteryClick(lottery)}
                      content={
                        isLeftover
                          ? lottery.hasHuntSlotsAvailable
                            ? 'Add to Cart'
                            : 'Hunts Full'
                          : 'Apply'
                      }
                      disabled={
                        !lottery.hasHuntSlotsAvailable || lottery.hasDeclined
                      }
                      size="sm"
                    />
                  )}
                </section>
              </div>
              <section className="black-bear-lottery-info-section">
                <Typography
                  variant="small"
                  color={Theme.palette.grey700}
                  fontSize={basicFontSize}
                >
                  {isLotteryWmaBlackBear ? (
                    <>
                      <p>
                        Authorizes bear harvest for Bayou Macon, Big Lake, or
                        Buckhorn WMA.
                      </p>
                      <p>
                        Possession of a valid black bear hunting license is
                        required prior to applying.
                      </p>
                    </>
                  ) : isLotteryLandOwnerBlackBear ? (
                    <>
                      <p>
                        Authorizes bear harvest for qualifying private
                        landowners, their designee, or lessee in Bear Area 4.
                      </p>
                      <p>
                        Lottery open to qualifying non-residents. However,
                        hunters must be a bona fide Louisiana resident who is
                        properly licensed and hunter safety certified.
                      </p>
                    </>
                  ) : isLotteryGeneralBlackBear ? (
                    <>
                      <p>
                        Authorizes bear harvest for private property, with
                        landowner permission, within Bear Area 4.
                      </p>
                      <p>
                        Possession of a valid black bear hunting license is
                        required prior to applying.
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>
              </section>
            </Segment>
          );
        })
      )}
    </div>
  ) : null;
};

const NoResultsSegment = () => {
  return (
    <Segment>
      <Typography variant="heading3" color={Theme.palette.grey400}>
        NO RESULTS
      </Typography>
    </Segment>
  );
};

const applyModalStyles = css`
  h3 {
    color: #1c3644 !important;
  }

  h4 {
    font-weight: 400;
    color: #51504e !important;
  }

  p {
    margin-left: 28px;
    margin-top: 8px;
    color: #51504e !important;
  }

  .header {
    border: none !important;
  }

  .content {
    padding: 0px 32px 16px 32px;
  }

  .container {
    border-radius: 8px;
    border: solid 1px #d7d6d4;
    padding: 16px;
  }

  .checkbox {
    label {
      font-weight: 500;
      color: #1c3644;
    }
  }

  .form-field {
    width: 60%;
    margin: 16px 0px 0px 8px !important;

    label {
      color: #51504e !important;
    }

    input {
      margin-top: 4px !important;
    }
  }

  .apply-modal-actions-container {
    margin-bottom: 8px !important;
  }

  .divider {
    margin: 8px 0px 8px 0px;
  }
`;

const styles = css`
  .canceled-message {
    margin-top: 8px !important;
  }

  .main-content {
    display: flex;
    flex-direction: row;
  }

  .listing-container {
    flex: 1;
  }

  .shopping-cart-container {
    flex: 1;
  }

  .species-options {
    margin-bottom: 17px;

    .ui.button {
      margin-bottom: 10px;

      &:active,
      &:focus {
        background: ${Theme.palette.blue50};
      }
    }
  }

  .listing-section {
    margin-bottom: 27px;
  }

  .black-bear-lottery-info-section {
    margin-top: 8px;
  }

  .unavailable-lotteries-row-grid {
    margin-top: 8px;
  }

  .your-lotteries {
    .ui.segment {
      padding: 1em;
      margin: 4px 0;
    }

    .ui.button {
      margin: 0;
    }

    .content-grid {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr;

      .title-section {
        justify-self: start;
        align-self: flex-start;
        width: 100%;
      }

      .amount-section {
        justify-self: end;
        align-self: flex-start;
      }

      .details-section {
        justify-self: start;
        align-self: start;
        margin-top: 20px;

        .hunt {
          min-width: 220px;
          padding: 5px;
          border: solid ${Theme.palette.grey100} 1px;
          display: grid;
          margin-bottom: 5px;
          margin-right: 5px;
          box-sizing: border-box;
          border-radius: 3px;

          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr 1fr;
        }
      }

      .actions-section {
        height: 100%;
        width: 100%;
        justify-self: end;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        min-width: 115px;
        .action-buttons {
          .primary-action {
            margin-left: 11px;
            margin-top: 75px;
          }
        }
      }
    }
  }

  .lottery-list-container {
    display: flex;
    flex-direction: column;

    .ui.segment {
      padding: 1em;
      margin: 4px 0;
    }

    .ui.button {
      margin: 0;
    }

    .content-grid {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr 1fr;

      .title-section {
        justify-self: start;
        align-self: center;
      }

      .subtitle-section {
        justify-self: start;
        align-self: flex-start;
      }

      .amount-section {
        justify-self: end;
        align-self: center;
      }

      .actions-section {
        justify-self: end;
        align-self: flex-end;
        min-width: 115px;
      }
    }
  }

  .leftover-disclaimer-container {
    flex-direction: row;
  }

  ${Media('WideMin')} {
    .ui.message {
      max-width: 80%;
    }

    .main-content {
      flex-direction: column-reverse;
    }

    .shopping-cart-container {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 27px;
    }

    .your-lotteries {
      .content-grid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;

        .title-section {
          justify-self: start;
          align-self: center;
        }

        .amount-section {
          justify-self: end;
          align-self: center;
        }

        .details-section {
          justify-self: start;
          align-self: start;
          margin-top: 20px;

          .hunt {
            min-width: 180px;
            padding: 5px;
            border: solid ${Theme.palette.grey100} 1px;
            display: grid;

            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
          }
        }

        .actions-section {
          height: 100%;
          width: 100%;
          justify-self: end;
          align-self: flex-end;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          min-width: 115px;
          .action-buttons {
            .primary-action {
              margin-left: 0;
              margin-top: 75px;
            }
          }
        }
      }
    }
  }

  .accept-decline-button-container {
    padding: 0;
    display: inline-block;
  }

  .form-field {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .field-submission-button {
    margin-top: 25px;
  }

  .empty-results {
    color: #b6b4b4;
  }

  .lottery-listing {
    display: inline-block;
    width: 65%;
  }

  .btn-join-group {
    padding-left: 0px;
  }

  .lottery-name-and-badge {
    display: inline-block;
  }

  .lottery-row-badge {
    margin-left: 10px;
  }

  .lottery-row-name {
    float: left;
  }

  .hunts-container {
    margin: 8px 0px 8px 0px;
  }

  .lottery-display-table {
    border: none !important;

    .header {
      .header-text {
        color: white;
      }
      background-color: #495c2b;
      padding: 10px;
    }

    .lottery-fee-paid {
      color: #495c2b;
      text-decoration: underline;
      font-weight: bold;
      font-size: 12px;
    }

    .lottery-fee-not-paid {
      text-align: left;
    }

    .ui.grid {
      margin-top: 8px;
    }
  }

  .leftover-info-text {
    padding-left: 15px;
    padding-top: -15px;
  }

  .lottery-label {
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-size: 11px;
  }

  .lottery-won {
    background-color: ${Theme.palette.green500};
  }

  .lottery-submitted {
    background-color: ${Theme.palette.green300};
  }

  .group-info {
    padding-left: 50px;
  }
`;
