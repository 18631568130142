import 'cleave.js/dist/addons/cleave-phone.us';
import {faUpload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CleaveOptions} from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import {cx} from 'emotion';
import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {AnyObject} from '../../types';
import {
  Button,
  HtmlInputrops,
  Input,
  StrictInputProps,
} from 'semantic-ui-react';

import {
  defaultValueConverter,
  ValueConverter,
  currencyValueConverter,
  decimalValueConverter,
  uppercaseValueConverter,
} from '../value-converters';

type InputAdditionalProps = {
  valueConverter?: ValueConverter;
  allowLowerCase?: boolean;
};

export type InputAdapterProps = StrictInputProps &
  HtmlInputrops &
  InputAdditionalProps;

export const InputAdapter = ({input, meta, ...rest}) => {
  let {
    valueConverter,
    allowLowerCase,
    ...additionalProps
  } = rest as InputAdditionalProps;

  const inputType = input.type?.toLowerCase();
  const skipTransform = inputType === 'password' || inputType === 'number';
  const convertToUpper = !valueConverter && !skipTransform && !allowLowerCase;

  let converter = valueConverter as ValueConverter;

  if (!converter) {
    converter = convertToUpper
      ? uppercaseValueConverter
      : defaultValueConverter;
  }

  const [value, setValue] = useState(converter.format(input.value));

  const className = cx(rest.className, convertToUpper && 'upper-case');

  // if formatting the external value does not match
  // the internal value, update internal value
  useEffect(() => {
    const shouldSetValue = !converter.equals(input.value, value);
    if (shouldSetValue) {
      setValue(converter.format(input.value));
    }
  }, [converter, input.value, value]);

  return (
    <Input
      {...input}
      value={value}
      {...additionalProps}
      className={className}
      onChange={(event, {value}) => {
        setValue(value);
        input.onChange(converter.parseInput(value));
      }}
      onFocus={(event) => {
        input.onFocus(event);
        event.target.select();
      }}
      onBlur={(event) => {
        setValue(converter.format(input.value));
        input.onBlur(event);
      }}
    />
  );
};

export const InputCurrencyAdapter = ({input, meta, ...rest}) => {
  const props: AnyObject = {...rest, valueConverter: currencyValueConverter};

  return (
    <InputAdapter
      input={input}
      meta={meta}
      icon="dollar"
      iconPosition="left"
      {...props}
      className={cx('input-currency', props.className)}
    />
  );
};

export const InputFileUrlAdapter = ({input, meta, ...rest}) => {
  const {onDrop, ...props} = rest;
  const [uploading, setUploading] = useState(false);
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: async (acceptedFiles) => {
      setUploading(true);
      const uri = await onDrop(acceptedFiles[0]);
      input.onChange(uri);
      setUploading(false);
    },
  });

  return (
    <InputAdapter
      input={input}
      meta={meta}
      action={
        <div {...getRootProps({className: 'ui button file-upload'})}>
          <input {...getInputProps()} />
          <Button
            basic
            icon
            className="action-button"
            type="button"
            loading={uploading}
          >
            <FontAwesomeIcon icon={faUpload} />
          </Button>
        </div>
      }
      {...props}
      className={cx('input-file-upload', props.className)}
    />
  );
};

export const InputDecimalAdapter = ({input, meta, ...rest}) => {
  const props = {...rest, valueConverter: decimalValueConverter};
  return <InputAdapter input={input} meta={meta} icon="percent" {...props} />;
};

export type InputMaskedAdapterProps = InputAdapterProps & {
  options?: CleaveOptions;
};

export const InputMaskedAdapter = ({input, meta, ...rest}) => {
  const {className, ...inputProps} = rest;
  return (
    <div className={cx('ui input', className)}>
      <Cleave {...input} {...inputProps} />
    </div>
  );
};
