import css from '@emotion/css';
import {faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-use';
import {
  Button,
  Divider,
  Grid,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import {CommercialLicenseRenewalService} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {ExportButton} from '../../components/export-button';
import {routes} from '../../routes/config';
import {cstNow} from '../../utils/date-time-helpers';

export const CommercialLicenseRenewalPaymentReceipt: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();
  const transactionInfo = location?.state?.state.transactionInformation;

  return (
    <BasicPage title="Payment Receipt" css={styles}>
      <Segment>
        <Message color="green">
          Payment Successful on {transactionInfo.renewalDate}.
        </Message>
        <Grid.Row>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <h3>Payment Details</h3>
            <p>Please print this receipt for your records</p>
          </Grid.Column>
        </Grid.Row>
        <Table basic compact unstackable>
          <Table.Body>
            {transactionInfo &&
              transactionInfo.commercialLicenses &&
              transactionInfo?.commercialLicenses.length > 0 &&
              transactionInfo?.commercialLicenses?.map((license) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <div>
                        {license?.description}
                        <br />
                        <p className="license-subtext">
                          License #: {license?.licenseNumber}
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      ${license?.fees.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            <Table.Row>
              <Table.Cell>
                <div>
                  <b>Convenience Fee:</b>
                </div>
              </Table.Cell>
              <Table.Cell textAlign="right">
                ${transactionInfo?.convenienceFee.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div>
                  <b>Total:</b>
                </div>
              </Table.Cell>
              <Table.Cell textAlign="right">
                ${transactionInfo?.amount.toFixed(2)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Grid.Row className="update-email">
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <Message color="yellow">
              Please print your receipt and carry it with you while fishing. If
              you do not receive your license(s) within 10 business days, please
              contact the Licensing Section at (225) 765-2898 for assistance.
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <ExportButton
          primary
          action={CommercialLicenseRenewalService.transactionReceiptPrint}
          fileName={`TransactionReceipt-${cstNow().format('MM/DD/YY-hh:m')}`}
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
          request={{transactionId: transactionInfo.id}}
          label={' Print Receipt'}
          icon={faFilePdf}
        />
        <Button
          onClick={() => {
            history.replace(routes.salesLanding);
          }}
          content={'Back to Home'}
        />
        <Button
          onClick={() => {
            history.replace(
              routes.commercialLicenseRenewal.commercialLicenseLookup
            );
          }}
          content={'Back to Search'}
        />
      </Segment>
    </BasicPage>
  );
};

const styles = css`
  .license-subtext {
    font-size: 14px;
    color: grey;
  }
`;
