import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import {AnalyticsEvents} from './events';
import {Env} from '../config/env-vars';
import {logger} from '../utils/logger';

type Analytics = {
  identify: (uniqueId?: string | undefined) => void;
  trackEvent: (
    eventName: AnalyticsEvents,
    properties?: {
      [index: string]: any;
    }
  ) => void;
  trackPage: (url: string) => void;
};

const log = logger('analytics');

const {isProductionBuild, googleAnalyticsId, googleTagManagerId} = Env;

const initializeGoogleAnalytics = isProductionBuild && !!googleAnalyticsId;
const initializeGoogleTagManager = isProductionBuild && !!googleTagManagerId;

let analtyics: Analytics;

analtyics = {
  identify: mockInDevelopment('identify', (uniqueId) => {
    ReactGA.set({userId: uniqueId});
  }),
  trackEvent: mockInDevelopment('trackEvent', (eventName, properties) => {
    ReactGA.event({
      category: 'Tracked Event',
      action: eventName,
    });
  }),
  trackPage: mockInDevelopment('trackPage', (url) => {
    ReactGA.pageview(url);
  }),
};

if (initializeGoogleAnalytics) {
  ReactGA.initialize(googleAnalyticsId as string);
}
if (initializeGoogleTagManager) {
  TagManager.initialize({
    gtmId: googleTagManagerId,
  });
}

function mockInDevelopment(method, action) {
  return isProductionBuild
    ? (...args) => {
        try {
          action(...args);
        } catch (error) {
          log.error(`analytics.${method}`, error);
        }
      }
    : (...args) => log.info(`analytics.${method}`, ...args);
}

export {analtyics};
