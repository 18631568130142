import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {CustomerCreate, CustomerUpdate} from './customer-create-update-base';
import {SocialSecurityNumberStatus} from '../../../api/generated/enums';
import {useNotification} from '../../../hooks/use-notifications';
import {notify} from '../../../hooks/use-subscription';
import {splitZipCodeFields} from '../../../utils/address-helpers';
import {customerRoutes as routes} from '../../routes/config';
import {
  AccountService,
  IspCustomersService,
  ResidencyCode,
} from '../../../api/generated';
import {useAsyncFn} from 'react-use';
import {useUser} from '../../../auth/use-auth';
import {
  logoutAsync,
  removeIsResidencyVerified,
} from '../../../utils/auth-helpers';
import {forceCstOffsetAndStartOfDay} from '../../../utils/date-time-helpers';
import {useLoginCookie} from '../../../hooks/use-login-cookie';

const residencyCodeOptions = {
  Yes: ResidencyCode.RESIDENT,
  No: ResidencyCode.NONRESIDENT,
};

const SsnStatus = {
  true: SocialSecurityNumberStatus.EXEMPT,
  false: SocialSecurityNumberStatus['NON-EXEMPT'],
};

export const CustomerCreateForRoute = () => {
  const history = useHistory();
  const notifications = useNotification();
  const cookies = useLoginCookie();

  const onSubmit = async (values) => {
    const valuesToSubmit = _.cloneDeep(values);

    valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
      ? SsnStatus.true
      : SsnStatus.false;

    delete valuesToSubmit.hasBirthCertificate;
    valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

    const dateOfBirthToSubmit = valuesToSubmit.dateOfBirth;
    valuesToSubmit.dateOfBirth = forceCstOffsetAndStartOfDay(
      dateOfBirthToSubmit
    );

    splitZipCodeFields(valuesToSubmit);

    const response = await IspCustomersService.create({body: valuesToSubmit});

    if (response.hasErrors || !response.result) {
      notifications.error('Customer Creation Failed');
      return response;
    }
    notifications.success('Customer Created');

    var valuesForCustomerLogin = {
      sportsmanId: response.result.sportsmanId,
      dateOfBirth: response.result.dateOfBirth,
    };

    await AccountService.customerLoginWithCustomerNumber({
      body: valuesForCustomerLogin,
    });
    cookies.setCurrentLoginCookie();
    history.push(routes.dashboard);
    notify('refresh-session', undefined);

    history.push(routes.dashboard);
  };

  const onCancel = async () => {
    await logoutAsync();
  };

  const config = {
    onSubmit: onSubmit,
    onCancel: onCancel,
  };

  return <CustomerCreate {...config} />;
};

export const CustomerUpdateForRoute = () => {
  const history = useHistory();
  const notifications = useNotification();
  const user = useUser();

  const [, onSubmit] = useAsyncFn(async (values) => {
    const valuesToSubmit = _.cloneDeep(values);

    splitZipCodeFields(valuesToSubmit);

    valuesToSubmit.socialSecurityNumberStatusCode = values.socialSecurityNumberStatusCode
      ? SsnStatus.true
      : SsnStatus.false;

    valuesToSubmit.residencyCode = residencyCodeOptions[values.residencyCode];

    const dateOfBirthToSubmit = valuesToSubmit.dateOfBirth;
    valuesToSubmit.dateOfBirth = forceCstOffsetAndStartOfDay(
      dateOfBirthToSubmit
    );

    const response = await IspCustomersService.update({body: valuesToSubmit});

    if (response.hasErrors) {
      notifications.error('Customer Update Failed');
      return response;
    }

    notifications.success('Customer Updated');
    removeIsResidencyVerified();

    notify('customer-updated', undefined);
    notify('refresh-session', undefined);

    history.push(routes.dashboard);
  });

  const onCancel = async () => {
    history.push(routes.dashboard);
  };

  const config = {
    customerId: user.customerId ?? 0,
    onSubmit: onSubmit,
    onCancel: onCancel,
  };

  return <CustomerUpdate {...config} />;
};
