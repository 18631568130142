import {useAsync} from 'react-use';
import {VendorUserService} from '../../api/generated';
import React, {useState} from 'react';
import {BasicPage} from '../../basic-page';
import {Form} from '../../forms';
import {Divider} from 'semantic-ui-react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {routes} from '../../routes/config';
import {useNotification} from '../../hooks/use-notifications';
import ChangePasswordModal from '../../admin/users/change-password-modal';
import {css} from '@emotion/core';
import {
  useVendorFields,
  VendorCreateFormFields,
  VendorUpdateFormFields,
} from '../vendor-user-create-edit-form';

export const VendorUserCreate = () => {
  const fields = useVendorFields(false);
  const history = useHistory();
  const notifications = useNotification();

  const onSubmit = async (values) => {
    const response = await VendorUserService.create({body: values});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('User Created');
    history.push(routes.vendor.root);
  };

  return (
    <BasicPage title="">
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              {fields.value && (
                <>
                  <VendorCreateFormFields fields={fields.value.fields} />
                  <Divider />
                  <div className="form-actions">
                    <Form.Button
                      type="submit"
                      primary
                      content={'Create User'}
                    />
                    <Form.Button
                      type="button"
                      as={Link}
                      to={routes.vendor.root}
                      content={'Cancel'}
                    />
                  </div>
                </>
              )}
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const VendorUserUpdate = () => {
  const fields = useVendorFields(true);
  const history = useHistory();
  const notifications = useNotification();

  const [
    changePasswordModalOpen,
    setChangePasswordModalOpen,
  ] = useState<boolean>(false);

  const {id} = useParams<{id: string}>();

  const fetchVendorUser = useAsync(async () => {
    const {result} = await VendorUserService.getById({
      id: Number(id),
    });
    return result;
  }, [id]);

  const vendorUser = fetchVendorUser.value;

  const changePassword = async (values) => {
    const defaultValues = {
      id: id,
      password: '',
      confirmPassword: '',
    };
    const body = {...defaultValues, ...values};

    const response = await VendorUserService.changePassword({body});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Password Changed');
    setChangePasswordModalOpen(false);
  };

  const onSubmit = async (values) => {
    const response = await VendorUserService.update({body: values});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('User Updated');
    history.push(routes.vendor.root);
  };

  return (
    <BasicPage css={styles} title="">
      <Form.Container>
        <Form
          initialValues={vendorUser}
          onSubmit={onSubmit}
          render={() => (
            <>
              {fields.value && (
                <>
                  <VendorUpdateFormFields fields={fields.value.fields} />
                  <Form.Button
                    className="change-password-button"
                    type="button"
                    onClick={() => setChangePasswordModalOpen(true)}
                    content={'Change Password'}
                  />
                  <Divider />
                  <div className="form-actions">
                    <Form.Button type="submit" primary content={'Edit User'} />
                    <Form.Button
                      type="button"
                      as={Link}
                      to={routes.vendor.root}
                      content={'Cancel'}
                    />
                  </div>
                </>
              )}
            </>
          )}
        />
      </Form.Container>
      <ChangePasswordModal
        oldPasswordRequired={false}
        modalOpen={changePasswordModalOpen}
        setModalOpen={setChangePasswordModalOpen}
        resetPassword={changePassword}
      />
    </BasicPage>
  );
};

const styles = css`
  .change-password-button {
    margin: 1em;
  }
`;
