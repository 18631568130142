export function combineRegisteredAndWaitlistedEvents<T>(
  registeredEvents: T[] | undefined,
  waitlistedEvents: T[] | undefined
) {
  const registeredEventsExpanded = (registeredEvents || []).map((x) => {
    return {
      ...x,
      isOnWaitlist: false,
    };
  });
  const waitlistedEventsExpanded = (waitlistedEvents || []).map((x) => {
    return {
      ...x,
      isOnWaitlist: true,
    };
  });

  const events = registeredEventsExpanded.concat(waitlistedEventsExpanded);

  return events;
}
