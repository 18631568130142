import React, {useEffect, useState} from 'react';
import {Grid, Container, Loader, List} from 'semantic-ui-react';
import {useAsync, useLocation} from 'react-use';
import {AccountService} from '../api/generated';
import {notifications} from '../utils/notification-service';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {customerRoutes} from '../internet-sales-portal/routes/config';
import {routes} from '../routes/config';
import {ProductCatalogAdapter} from '../shared/product-catalog/product-catalog-adapter';
import {
  CatalogType,
  ProductCatalogState,
} from '../shared/product-catalog/product-catalog-utils';
import {useLoginCookie} from '../hooks/use-login-cookie';

type OneTimeLoginLandingPageProductCatalogProps = {
  catalogType: CatalogType;
  prevCatalogState: ProductCatalogState;
  isLoggedIn: boolean;
  isLoading: boolean;
};

export const OneTimeLoginLandingPageProductCatalog: React.FC<OneTimeLoginLandingPageProductCatalogProps> = ({
  isLoggedIn,
  isLoading,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      notifications.success('Successfully logged in.');
      history.push(customerRoutes.licensesAndPermits);
    }
  });

  return (
    <Grid verticalAlign="middle" columns={1}>
      <Grid.Column>
        <List horizontal>
          <Grid.Row>
            <Container text>Logging you in...</Container>
          </Grid.Row>
          <Grid.Row>
            <Loader active={isLoading}></Loader>
          </Grid.Row>
        </List>
      </Grid.Column>
    </Grid>
  );
};

export function OneTimeLoginLandingPageProductCatalogWrapper() {
  const location = useLocation();
  const history = useHistory();
  const cookies = useLoginCookie();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const catalogState = location.state?.catalogState ?? null;

  const match = useRouteMatch<{token: string}>();
  const {token} = match?.params ?? null;

  useAsync(async () => {
    const response = await AccountService.customerLoginWithToken({
      body: {secureToken: token},
    });

    if (response.hasErrors) {
      notifications.error(
        'This link is no longer valid. Please use one of the options below to log in.'
      );
      history.push(routes.customer.dashboard);
      return response;
    }

    cookies.setCurrentLoginCookie();
    setIsLoading(false);
    setIsLoggedIn(true);
  });

  if (isLoading) {
    return (
      <Grid verticalAlign="middle" columns={1}>
        <Grid.Column>
          <List horizontal>
            <Grid.Row>
              <Container text>Logging you in...</Container>
            </Grid.Row>
            <Grid.Row>
              <Loader active={isLoading}></Loader>
            </Grid.Row>
          </List>
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <ProductCatalogAdapter
      catalogState={catalogState}
      render={(catalogState) => {
        return (
          <OneTimeLoginLandingPageProductCatalog
            catalogType={CatalogType.customer}
            prevCatalogState={catalogState}
            isLoggedIn={isLoggedIn}
            isLoading={isLoading}
          />
        );
      }}
    />
  );
}
