import css from '@emotion/css/macro';
import {isArray, last, take} from 'lodash';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, Header} from 'semantic-ui-react';
import {Flex} from './flex';
import {Theme} from '../theme';
import {Media} from '../styles/breakpoints';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';

type BreadcrumbPart = {
  title: string;
  url?: string;
  fromSecondaryNav?: boolean;
};

export const PageTitle = ({title, fromSecondaryNav = false}) => {
  const parts = (isArray(title) ? title : [{title}]) as BreadcrumbPart[];
  const currentPage = last(parts);
  const breadcrumbs = take(parts, parts.length - 1);
  const header = fromSecondaryNav ? (
    <h3 className={'secondary-page-header'}>{currentPage?.title}</h3>
  ) : (
    <Header className="page-header">{currentPage?.title}</Header>
  );
  return (
    <>
      {header}
      <Flex.Row align="center" css={styles} className="page-title">
        {breadcrumbs.length > 0 && (
          <Flex.Box className="breadcrumbs">
            <Breadcrumb>
              {breadcrumbs.map((crumb, index) => (
                <Fragment key={crumb.url || crumb.title}>
                  {index < breadcrumbs.length - 1 ? (
                    <>
                      <Breadcrumb.Section link as={Link} to={crumb.url}>
                        {crumb.title}
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider> </Breadcrumb.Divider>
                      <Breadcrumb.Divider>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Breadcrumb.Divider>
                      <Breadcrumb.Divider> </Breadcrumb.Divider>
                    </>
                  ) : (
                    <>
                      <Breadcrumb.Section link as={Link} to={crumb.url}>
                        {crumb.title}
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider> </Breadcrumb.Divider>
                      <Breadcrumb.Divider>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Breadcrumb.Divider>
                      <Breadcrumb.Divider> </Breadcrumb.Divider>
                      <Breadcrumb.Section header={header.toString()}>
                        <p className="page-header">{currentPage?.title}</p>
                      </Breadcrumb.Section>
                    </>
                  )}
                </Fragment>
              ))}
              <Flex.Box></Flex.Box>
            </Breadcrumb>
          </Flex.Box>
        )}
        <Flex.Fill></Flex.Fill>
      </Flex.Row>
    </>
  );
};

const styles = css`
  .page-header {
    margin-top: 0;
    font-weight: normal;
  }

  .secondary-page-header {
    margin-left: -15% !important;
  }

  .mobile-divider {
    display: none !important;
    ${Media('MobileMax')} {
      display: inline !important;
    }
  }

  .ui.breadcrumb a {
    color: ${Theme.palette.grey1};
  }

  .breadcrumbs-separator {
    height: 22px;
    width: 1px;
    margin: 0 20px;
    background-color: #dbdce7;

    ${Media('MobileMax')} {
      display: none;
    }
  }

  margin-top: 0px;
  margin-bottom: 20px;
`;
