import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {CustomerLookupService} from '../../api/generated';
import {ChangePassword} from '../../auth/change-password';
import {RequireAuthorization} from '../../auth/require-authorization';
import {CustomerCreateComponent} from '../../internet-sales-portal/customers/customer-create-update/customer-create-update-login-component';
import {HipSurvey} from '../../shared/hip/hip-survey';
import {ProductCatalogAdapter} from '../../shared/product-catalog/product-catalog-adapter';
import {ProductCatalog} from '../../shared/product-catalog/product-catalog-listing';
import {CatalogType} from '../../shared/product-catalog/product-catalog-utils';
import {SalesReceipt} from '../../shared/product-catalog/product-sales-receipt';
import {PurchaseConfirmation} from '../../shared/product-catalog/purchase-confirmation';
import {notifications} from '../../utils/notification-service';
import {IssueDuplicates} from '../issue-duplicates';
import {TransactionDetails} from '../transaction-details';
import {VendorCustomerProfile} from '../vendor-customer-profile';
import {VendorLandingPage} from '../vendor-landing';
import {
  VendorUserCreate,
  VendorUserUpdate,
} from '../vendor-users/vendor-user-create-update';
import {CorporateReportListing} from '../corporate-report-listing';
import {vendorRoutes as routes} from './config';
import {MailingAddressConfirmation} from '../../shared/mailing-address/mailing-address-confirmation';

export const VendorRoutes = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route path={routes.corporateReport} exact>
        <CorporateReportListing />
      </Route>
      <Route path={routes.root} exact>
        <VendorLandingPage />
      </Route>
      <Route
        path={routes.licensesAndPermits}
        exact
        render={({location}) => {
          const customerId = location.state ? location.state.customer?.id : 0;
          const isHqVendor = location.state
            ? location.state.isHqVendor === true
            : false;
          const catalogType = isHqVendor
            ? CatalogType.hqVendor
            : location.state.catalogState &&
              location.state.catalogState.catalogType
            ? location.state.catalogState.catalogType
            : CatalogType.vendor;
          const catalogState = location.state
            ? location.state.catalogState
            : null;

          return (
            <ProductCatalogAdapter
              catalogState={catalogState}
              customerId={customerId}
              render={(catalogState) => (
                <ProductCatalog
                  catalogType={catalogType}
                  prevCatalogState={catalogState}
                />
              )}
            />
          );
        }}
      />
      <Route path={routes.mailingAddressConfirmation} exact>
        <MailingAddressConfirmation />
      </Route>
      <Route path={routes.hipSurvey} exact>
        <HipSurvey />
      </Route>
      <Route
        path={routes.purchaseConfirmation}
        exact
        render={({location}) => {
          const customerId = location.state
            ? location.state.customer?.id
            : null;

          const isHqVendor = location.state
            ? location.state.isHqVendor === true ||
              location.state.catalogType === CatalogType.hqVendor ||
              (location.state.catalogState &&
                location.state.catalogState.catalogType ===
                  CatalogType.hqVendor)
            : false;

          const catalogType = isHqVendor
            ? CatalogType.hqVendor
            : CatalogType.vendor;

          const catalogState = location.state
            ? location.state.catalogState
            : null;

          return (
            <ProductCatalogAdapter
              catalogState={catalogState}
              customerId={customerId}
              render={(catalogState) => {
                location.state.catalogState = {
                  ...catalogState,
                  catalogType,
                };

                return <PurchaseConfirmation />;
              }}
            />
          );
        }}
      />
      <Route path={routes.salesReceipt} exact>
        <SalesReceipt />
      </Route>
      <Route path={routes.changePassword} exact>
        <ChangePassword />
      </Route>
      <Route path={routes.createCustomer} exact>
        <CustomerCreateComponent
          onCancel={() => {
            history.push(routes.root);
          }}
          completionHandler={async (loginValues) => {
            const response = await CustomerLookupService.lookupWithCustomerNumber(
              {
                body: loginValues,
              }
            );

            if (response.hasErrors) {
              notifications.error('Failed to lookup created customer');
            }

            history.push(routes.root, response.result);
          }}
        />
      </Route>
      <Route path={routes.customers.profile}>
        <VendorCustomerProfile />
      </Route>
      <Route path={routes.vendorUser.create} exact>
        <RequireAuthorization showErrorPage={false} claims="Vendor - Manager">
          <VendorUserCreate />
        </RequireAuthorization>
      </Route>
      <Route path={routes.vendorUser.detail} exact>
        <RequireAuthorization showErrorPage={false} claims="Vendor - Manager">
          <VendorUserUpdate />
        </RequireAuthorization>
      </Route>
      <Route path={routes.issueDuplicates} exact>
        <IssueDuplicates />
      </Route>
      <Route path={routes.transactionDetails} exact>
        <TransactionDetails />
      </Route>
    </Switch>
  );
};
