export const noProductsMessageStyles = {
  color: 'lightgray',
  fontStyle: 'italic',
  marginTop: '5px',
};

export const productListItemStyles = {
  marginBottom: '0px',
  fontSize: '18px',
};

export const additionalInformationTextStyles = {
  color: 'gray',
  marginTop: '0px',
  marginBottom: '0px',
};
