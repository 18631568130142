import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {
  AddressDto,
  AnyObject,
  CustomerDetailDto,
  CustomerOptionsService,
  IspCreateCustomerCommand,
  SocialSecurityNumberStatus,
  VerifyCustomerService,
} from '../../api/generated';
import {StyledPageContainer} from '../../styled-page-container';
import {Card, Divider, Grid, Modal, Segment} from 'semantic-ui-react';
import {Form} from '../../forms';
import {Typography} from '../../styled-components/typography';
import {AsyncStateContainer} from '../../components/async-state-container';
import {css} from '@emotion/core';
import {getOptionDtoDropdownOptions} from '../../api/generated/utils';
import {StyledButton} from '../../styled-components/styled-buttons';
import {
  fieldConfig,
  input,
  radiogroup,
  RawFieldConfig,
} from '../../forms/schema-utils';
import {Overwrite} from 'utility-types';
import {AddressProps} from '../../forms/address-fields';
import {useHistory} from 'react-router-dom';
import {customerRoutes as routes} from '../routes/config';
import {Theme} from '../../theme';
import {CountryCode, StateCode} from '../../api/generated/enums';
import {isEmailValid, logoutAsync} from '../../utils/auth-helpers';
import {Media} from '../../styles/breakpoints';
import {momentCst} from '../../utils/date-time-helpers';
import {FormSpy, useForm} from 'react-final-form';
import moment from 'moment';
import {notifications} from '../../utils/notification-service';

type FieldConfigDto = Pick<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  'residencyCode' | 'driversLicenseNumber'
>;

const useFields = () => {
  const fetchFields = useAsync(async () => {
    return fieldConfig<FieldConfigDto>({
      residencyCode: radiogroup({
        fieldLabel: 'Are you a Louisiana Resident?',
        fieldRequired: true,
      }),
      driversLicenseNumber: input({
        fieldLabel: 'Louisiana DL or ID Number',
        fieldRequired: true,
      }),
    });
  }, []);

  return fetchFields;
};

const residentYesNoOptions = {
  Yes: 'Yes',
  No: 'No',
} as AnyObject;

const residencyCodeToYesNo = {
  RESIDENT: 'Yes',
  NONRESIDENT: 'No',
} as AnyObject;

const legalConfirmationFieldConfig = {
  fieldLabel: ' ',
  fieldName: 'legalConfirmation',
  fieldRequired: true,
  inputProps: {
    label: 'I hereby declare that all information provided is true and correct',
  },
};

type CustomerVerification = {
  customerId: Number;
  onSubmit: (values) => void;
  onEdit: ({isStepper}: {isStepper: boolean}) => void;
};

const subscriptionDefault = {values: false};

export const CustomerVerification = (config: CustomerVerification) => {
  const fields = useFields();
  const history = useHistory();
  const fetchCustomer = useAsync(async () => {
    if (config.customerId === null) {
      notifications.error('Error, user is not a customer');
      return;
    }
    const fetch = VerifyCustomerService.getById({
      id: config.customerId,
    } as any);

    return fetch;
  }, [config.customerId]);
  const customer = fetchCustomer.value?.result;

  return (
    <AsyncStateContainer {...fetchCustomer}>
      <>
        {customer && (
          <StyledPageContainer
            title="Customer Details"
            subtitle="Complete or confirm the details below. 
            Call (225) 765-2887 to update information that cannot be edited."
            css={customerVerificationStyles}
          >
            <CustomerInformation customer={customer} onEdit={config.onEdit} />
            <Divider />

            <Form
              initialValues={customer}
              subscription={subscriptionDefault}
              onSubmit={config.onSubmit}
              render={() => (
                <>
                  {fields.value && (
                    <>
                      <div className="form-actions">
                        <StyledButton type="submit" primary padded>
                          Next
                        </StyledButton>
                        <StyledButton
                          onClick={async () => {
                            await logoutAsync();
                            history.push(routes.dashboard);
                          }}
                        >
                          Cancel
                        </StyledButton>
                      </div>
                    </>
                  )}
                </>
              )}
            />
          </StyledPageContainer>
        )}
      </>
    </AsyncStateContainer>
  );
};

export type CustomerInfoType = {
  customer: CustomerDetailDto;
  onEdit: ({isStepper}: {isStepper: boolean}) => void;
};

export type CustomerInfoFieldType = {
  header: string;
  text: any;
  noPadding?: boolean;
};

const CustomerInformation: React.FC<CustomerInfoType> = ({
  customer,
  onEdit,
}) => {
  const customerFullName = `${customer?.firstName} ${
    customer?.middleName ?? ''
  } ${customer?.lastName} ${customer?.suffixCode ?? ''}`;
  const history = useHistory();
  const result = useAsync(
    async () => await CustomerOptionsService.getCustomerOptions(),
    []
  );
  const hairColorCodes = getOptionDtoDropdownOptions(
    result.value?.result?.hairColorCodes
  );
  const customerHairColorCode = hairColorCodes.find(
    (x) => x.value === customer.hairColorCodeId
  );
  const eyeColorCodes = getOptionDtoDropdownOptions(
    result.value?.result?.eyeColorCodes
  );
  const customerEyeColorCode = eyeColorCodes.find(
    (x) => x.value === customer.eyeColorCodeId
  );

  const getKeyOfEnumValue = (value, obj) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  const getHasAllRequiredDetails = (customer: CustomerDetailDto) => {
    const SsnAndNonExempt =
      (customer.socialSecurityNumber === null &&
        customer.socialSecurityNumberStatusCode ===
          SocialSecurityNumberStatus.Exempt) ||
      (!!customer.socialSecurityNumber &&
        customer.socialSecurityNumberStatusCode ===
          SocialSecurityNumberStatus.NonExempt);

    const hasValidPassportCountryCode =
      (customer.socialSecurityNumberStatusCode ===
        SocialSecurityNumberStatus.Exempt &&
        !!customer.passportCountryCode &&
        customer.passportCountryCode !== CountryCode['UNITED STATES']) ||
      (customer.socialSecurityNumberStatusCode ===
        SocialSecurityNumberStatus.NonExempt &&
        !!customer.passportCountryCode) ||
      (customer.socialSecurityNumberStatusCode ===
        SocialSecurityNumberStatus.NonExempt &&
        customer.passportCountryCode === null);

    const hasValidEmail = isEmailValid(customer.emailAddress);

    return (
      hasValidEmail &&
      hasValidPassportCountryCode &&
      SsnAndNonExempt &&
      !!customer.firstName &&
      !!customer.lastName &&
      !!customer.dateOfBirth &&
      !!customer.emailAddress &&
      !!customer.phoneNumberPrimary &&
      !!customer.genderCode &&
      !!customer.physicalAddress &&
      !!customer.mailingAddress &&
      !!addressHasRequiredDetails(customer.physicalAddress) &&
      !!addressHasRequiredDetails(customer.mailingAddress)
    );
  };
  const addressHasRequiredDetails = (address: AddressDto) => {
    return (
      !!address.street1 &&
      !!address.city &&
      !!address.stateCode &&
      !!address.countryCode
    );
  };

  const hasAllRequiredDetails = getHasAllRequiredDetails(customer);

  if (customer && !hasAllRequiredDetails) {
    onEdit({isStepper: true});
  }

  return (
    <>
      <Grid stackable doubling columns="equal" padded="vertically">
        <Grid.Row className="customer-verification-heading">
          <Grid.Column className="customer-found-container">
            <Segment compact padded className="customer-found-box">
              <Card.Content>
                <span className="customer-found-text">
                  <Typography variant="subheading1">Customer Found.</Typography>
                  <Typography variant="body1">
                    <button
                      onClick={async () => {
                        await logoutAsync();
                        history.push(routes.dashboard);
                      }}
                      type="button"
                      className="link"
                    >
                      Not you?
                    </button>
                  </Typography>
                </span>
              </Card.Content>
            </Segment>
            <div>
              <CustomerInformationField
                noPadding
                header="Customer ID"
                text={`${customer.sportsmanId?.padStart(10, '0')}`}
              />
            </div>
            <div>
              <Typography variant="heading2">{customerFullName}</Typography>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="customer-information-area">
          <Grid.Column>
            <CustomerInformationField
              header="Date of Birth"
              text={momentCst(customer.dateOfBirth).format('MM/DD/YYYY')}
            />
            <CustomerInformationField
              header="Gender"
              text={customer.genderCode}
            />
            <CustomerInformationField
              header="Hair Color"
              text={customerHairColorCode?.text}
            />
            <CustomerInformationField
              header="Eye Color"
              text={customerEyeColorCode?.text}
            />
            <CustomerInformationField
              header="Height"
              text={
                customer.heightFeet
                  ? `${customer.heightFeet} ft. ${
                      customer.heightInches ?? 0
                    } in.`
                  : 'N/A'
              }
            />
            <CustomerInformationField
              header="Weight"
              text={customer.weight ? `${customer.weight} lbs` : 'N/A'}
            />

            <StyledButton onClick={() => onEdit({isStepper: false})}>
              Edit
            </StyledButton>
          </Grid.Column>
          <Grid.Column>
            <CustomerInformationField
              header="Social Security #"
              text={customer.socialSecurityNumber}
            />
            <CustomerInformationField
              header="Driver's License or ID Card #"
              text={customer.driversLicenseNumber}
            />
            <CustomerInformationField
              header="Driver's License or ID Card state"
              text={getKeyOfEnumValue(
                customer.driversLicenseStateCode,
                StateCode
              )}
            />
            <CustomerInformationField
              header="Alternate ID #"
              text={customer.passportNumber}
            />
            <CustomerInformationField
              header="Alternate ID country"
              text={getKeyOfEnumValue(
                customer.passportCountryCode,
                CountryCode
              )}
            />
            <CustomerInformationField
              header="Military ID"
              text={customer.militaryIdNumber}
            />
            <CustomerInformationField
              header="Primary Phone"
              text={customer.phoneNumberPrimary}
            />
            <CustomerInformationField
              header="Alternate Phone"
              text={customer.phoneNumberSecondary}
            />
            <CustomerInformationField
              header="Email Address"
              text={customer.emailAddress}
            />
          </Grid.Column>
          <Grid.Column>
            <div className="customer-address-header">
              <Typography variant="heading4">Physical Address</Typography>
            </div>
            <CustomerInformationField
              header="Street Address 1"
              text={customer.physicalAddress?.street1}
            />
            <CustomerInformationField
              header="Street Address 2"
              text={customer.physicalAddress?.street2}
            />
            <CustomerInformationField
              header="City"
              text={customer.physicalAddress?.city}
            />
            <CustomerInformationField
              header="State"
              text={getKeyOfEnumValue(
                customer.physicalAddress?.stateCode,
                StateCode
              )}
            />
            <CustomerInformationField
              header="Zip/Postal Code"
              text={
                customer.physicalAddress &&
                `${customer.physicalAddress?.zipCode}${
                  customer.physicalAddress?.plus4 ? '-' : ''
                }${
                  customer.physicalAddress?.plus4
                    ? customer.physicalAddress?.plus4
                    : ''
                }`
              }
            />
            <CustomerInformationField
              header="Country"
              text={getKeyOfEnumValue(
                customer.physicalAddress?.countryCode,
                CountryCode
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <div className="customer-address-header">
              <Typography variant="heading4">Mailing Address</Typography>
            </div>
            <CustomerInformationField
              header="Street Address 1"
              text={customer.mailingAddress?.street1}
            />
            <CustomerInformationField
              header="Street Address 2"
              text={customer.mailingAddress?.street2}
            />
            <CustomerInformationField
              header="City"
              text={customer.mailingAddress?.city}
            />
            <CustomerInformationField
              header="State"
              text={getKeyOfEnumValue(
                customer.mailingAddress?.stateCode,
                StateCode
              )}
            />
            <CustomerInformationField
              header="Zip/Postal Code"
              text={
                customer.mailingAddress &&
                `${customer.mailingAddress.zipCode}${
                  customer.mailingAddress.plus4 ? '-' : ''
                }${
                  customer.mailingAddress.plus4
                    ? customer.mailingAddress.plus4
                    : ''
                }`
              }
            />
            <CustomerInformationField
              header="Country"
              text={getKeyOfEnumValue(
                customer.mailingAddress?.countryCode,
                CountryCode
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export const CustomerInformationField: React.FC<CustomerInfoFieldType> = ({
  header,
  text,
  noPadding,
}) => {
  return !noPadding ? (
    <Grid.Row className="customer-information-container-padded">
      <Grid.Column className="customer-information-heading">
        <Typography variant="heading4">{header} </Typography>
      </Grid.Column>
      <Grid.Column className="customer-information-text">
        <Typography variant="body2">{`${
          text === null || text === undefined ? 'N/A' : text
        }`}</Typography>
      </Grid.Column>
    </Grid.Row>
  ) : (
    <span className="customer-information-container">
      <Typography variant="heading4">{header} </Typography>
      <Typography variant="body2">{text}</Typography>
    </span>
  );
};

export const VerificationFormFields: React.FC<{
  fields: RawFieldConfig<FieldConfigDto>;
  isEdit?: boolean;
  initialValues?: AnyObject;
}> = ({fields, initialValues}) => {
  const form = useForm();
  return (
    <div className="details-form-row">
      <Form.Row>
        <div className="residency-container">
          <FormSpy>
            {({values}) => {
              const isLouisianaResident =
                values.residencyCode === residentYesNoOptions.Yes;
              const age = moment().diff(values.dateOfBirth, 'years', false);
              const requiresLouisianaDriversLicenseNumber =
                isLouisianaResident &&
                age >= 16 &&
                values.driversLicenseStateCode !== StateCode.LOUISIANA;

              const initialResidencyCodeYesNo =
                residencyCodeToYesNo[initialValues?.residencyCode];
              const selectedResidencyCode = values.residencyCode;

              const isChangingToLouisianaDriversLicense =
                (initialResidencyCodeYesNo === 'No' ||
                  initialValues?.driversLicenseStateCode !==
                    StateCode.LOUISIANA) &&
                selectedResidencyCode === 'Yes';
              const hasChangedDriversLicenseNumber =
                values.driversLicenseNumber !==
                initialValues?.driversLicenseNumber;

              if (
                isChangingToLouisianaDriversLicense &&
                requiresLouisianaDriversLicenseNumber &&
                !hasChangedDriversLicenseNumber
              ) {
                form.change('driversLicenseNumber', '');
              }

              return (
                <>
                  <Form.RadioGroup
                    fieldConfig={fields.residencyCode}
                    enum={residentYesNoOptions}
                  />
                  <WhoQualifiesModal />

                  {requiresLouisianaDriversLicenseNumber && (
                    <Form.Input fieldConfig={fields.driversLicenseNumber} />
                  )}
                </>
              );
            }}
          </FormSpy>
        </div>
        <div className="legal-confirmation-text">
          <Typography variant="body1">
            No person shall procure or attempt to procure a license by fraud,
            deceit, misrepresentation, or any false statement. Licenses are
            affidavits confirming the information is correct. False information
            is subject to investigation and may be grounds for criminal
            prosecution resulting in denial or revocation of licenses and/or
            permits.
          </Typography>
          <Form.Checkbox fieldConfig={legalConfirmationFieldConfig} />
        </div>
      </Form.Row>
    </div>
  );
};

const WhoQualifiesModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      size="tiny"
      trigger={
        <button onClick={() => setOpen(!open)} type="button" className="link">
          Who Qualifies?
        </button>
      }
    >
      <Modal.Header>Who is a Louisiana Resident?</Modal.Header>
      <Modal.Content>
        <p>
          Any person who does not claim resident privileges in another state or
          country and who has actual residence and legal permanent home address
          both are in Louisiana, and have been for at least 6 months before
          applying for the permit. Owning real estate or attending Louisiana
          schools does not in itself make you legal resident.
        </p>
        <p>
          Effective August 1, 2019, a Louisiana Resident who was honorably
          discharged from the armed forces of the United States or a reserve
          component of the armed forces of the United States, including the
          National Guard, for the purposes of purchasing a fishing/hunting
          license will be considered a bona fide resident of Louisiana once
          he/she possess a Louisiana driver's license, or, if not licensed to
          drive is in possession of a special identification card issued by the
          Department of Public Safety and Corrections under the provisions of
          R.S. 40:1321.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton primary onClick={() => setOpen(!open)}>
          Close
        </StyledButton>
      </Modal.Actions>
    </Modal>
  );
};

const customerVerificationStyles = css`
  .customer-verification-heading {
    padding-top: 0rem !important;
  }

  .customer-id-heading {
    padding-bottom: 0.5rem;
    padding-top: 1rem;
  }

  .customer-full-name-heading {
    padding-bottom: 1.5rem;
  }

  .customer-information-container-padded {
    width: 100%;
    padding-bottom: 0.25rem;
    display: inline-flex;
    height: auto;
    flex-wrap: wrap;
    line-height: 1rem;
  }

  .customer-information-heading {
    width: 44% !important;
    margin-right: 1%;
    line-height: 1rem;
  }

  .customer-information-area {
    padding-top: 0.25rem !important;
    padding-bottom: 0 !important;
  }

  .customer-information-text {
    width: 55% !important;
    min-width: 100px;
    margin-bottom: 0.5rem;
    word-wrap: break-word;
  }

  .customer-address-header {
    margin-bottom: 0.8rem;
    color: ${Theme.palette.grey800};
  }

  .customer-found-container {
    padding: 0;
  }

  .customer-found-box {
    padding: 0.2em 0em !important;
    margin-bottom: 1.5em !important;
    box-shadow: none !important;
    border: none !important;
    background-color: ${Theme.palette.green00};
    line-height: 13px;
    .content {
      margin: 0.4rem 1rem !important;
    }
  }

  .customer-found-text {
    display: inline-block;
  }

  .details-form-row {
    .ui.radio.checkbox {
      padding: 0.5rem 2rem 0 0;
    }

    .form-field-required {
      padding-left: 0 !important;
    }
    label :not(.required-indicator) {
      font-weight: 500;
      font-size: 14px;
      color: ${Theme.palette.grey600};
    }
  }

  .legal-confirmation-text {
    width: 70%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    .form-field {
      padding-top: 1rem !important;
    }
  }

  .residency-container {
    ${Media('TabletMin')} {
      width: 30%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .form-actions {
    margin-top: 0 !important;
  }

  button.link {
    background: none;
    border: none;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    font-family: ${Theme.fonts};
    color: #069;
    cursor: pointer;
  }
`;
