import currency from 'currency.js';
import {parseCurrency, formatCurrency} from '../utils/currency-helpers';
import {momentCst} from '../utils/date-time-helpers';
import {getPooledMoment} from '../utils/get-pooled-moment';
export type ValueConverter = {
  parseInput: (input: string) => any;
  format: (value: any) => string;
  equals: (value: any, formattedInput: string) => boolean;
};

export const defaultValueConverter: ValueConverter = {
  format: (x) => x,
  parseInput: (x) => x,
  equals: (value, formattedInput) => value === formattedInput,
};

export const uppercaseValueConverter: ValueConverter = {
  format: (x?: string) => x?.toUpperCase() || '',
  parseInput: (x?: string) => x?.toUpperCase(),
  equals: (value, formattedInput) =>
    value.toUpperCase() === formattedInput.toUpperCase(),
};

export const currencyValueConverter: ValueConverter = {
  format: (value) => formatCurrency(value),
  parseInput: (input) => parseCurrency(input),
  equals: (value, input) => value === parseCurrency(input),
};

export const decimalValueConverter: ValueConverter = {
  format: (value) => currency(value * 100).value.toString(),
  parseInput: (input) => {
    const parsedInput = Number(input);
    if (!Number.isFinite(parsedInput)) {
      return 0;
    }
    const precision = getPrecision(parsedInput) + 2;
    return Number((parsedInput / 100).toPrecision(precision));
  },
  equals: (value, input) => value === decimalValueConverter.parseInput(input),
};

export const dateTimeValueConverter: ValueConverter = {
  format: (value) => momentCst(value || '0').format('MM/DD/YYYY hh:mm:ss A'),
  parseInput: (input) => getPooledMoment(input || '0'),
  equals: (value, input) => momentCst(value) === getPooledMoment(input),
};

export const dateValueConverter: ValueConverter = {
  format: (value) => momentCst(value || '0').format('L'),
  parseInput: (input) => getPooledMoment(input || '0'),
  equals: (value, input) => momentCst(value) === getPooledMoment(input),
};

export const timeValueConverter: ValueConverter = {
  format: (value) => momentCst(value || '0', 'LT').format('hh:mm A'),
  parseInput: (input) => getPooledMoment(input || '0', 'LT').format('HH:mm:ss'),
  equals: (value, input) =>
    momentCst(value, 'LT').format('HH:mm:ss') ===
    getPooledMoment(input, 'LT').format('HH:mm:ss'),
};

function getPrecision(a) {
  if (!isFinite(a)) return 0;
  let e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}
