import {createBreakpoint} from 'react-use';

type Breakpoints = keyof typeof Breakpoints;
export const Breakpoints = {
  MobileMax: 767,
  TabletMin: 768,
  TabletMax: 1023,
  DesktopMin: 1024,
  DesktopMax: 1279,
  WideMin: 1320,
} as const;

export const MediaSizes = {
  MobileMax: `max-width: ${Breakpoints.MobileMax}px`,
  TabletMin: `min-width: ${Breakpoints.TabletMin}px`,
  TabletMax: `max-width: ${Breakpoints.TabletMax}px`,
  DesktopMin: `min-width: ${Breakpoints.DesktopMin}px`,
  DesktopMax: `max-width: ${Breakpoints.DesktopMax}px`,
  WideMin: `max-width: ${Breakpoints.WideMin}px`,
} as const;

export const Media = (size: keyof typeof MediaSizes) =>
  `@media only screen and (${MediaSizes[size]})`;

export const useBreakpoint = () => {
  return createBreakpoint(Breakpoints)() as Breakpoints;
};

type PageMaxWidth = keyof typeof PageMaxWidth;
export const PageMaxWidth = {
  Padded: 1380,
  FullWidth: 1700,
  Mobile: 800,
} as const;
