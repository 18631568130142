import React from 'react';
import {Modal} from 'semantic-ui-react';

import {Form} from '../../forms';
import {ChangePasswordForm, ChangePasswordDto, initialValues} from '../../components/change-password-form';

type ChangePasswordModalProps = {
  oldPasswordRequired: boolean;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  resetPassword: (values: ChangePasswordDto) => void;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  oldPasswordRequired,
  modalOpen,
  setModalOpen,
  resetPassword,
}) => {
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      size="tiny"
    >
      <Modal.Content>
        <Form
          initialValues={initialValues}
          onSubmit={resetPassword}
          render={() => (
            <>
              <ChangePasswordForm
                title="Change Password"
                oldPasswordRequired={oldPasswordRequired}
              />
              <Modal.Actions>
                <Form.Button type="submit" primary>Submit</Form.Button>
                <Form.Button type="button" secondary onClick={closeModal}>Cancel</Form.Button>
              </Modal.Actions>
            </>
          )}
        />
      </Modal.Content>
    </Modal>
  )
};

export default ChangePasswordModal;
