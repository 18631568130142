export const customerRoutes = {
  root: `/`,
  login: '/login',
  verification: `/customer/verification`,
  dashboard: '/dashboard',
  licensesAndPermits: `/licenses-and-permits`,
  licensesAndPermitsSetDisplay: `/licenses-and-permits/:id`,
  customerlicensesAndPermits: '/my-licenses-and-permits',
  purchaseConfirmation: '/purchase-confirmation',
  salesReceipt: '/sales-receipt',
  hipSurvey: '/hip-survey',
  mailingAddressConfirmation: '/mailing-address-confirmation',
  validateTags: `/validate-tags`,
  lotteryApplications: `/lottery-applications`,
  lotteryApplicationsPaymentConfirmation: '/lottery-applications-confirmation',
  lotteryReceipt: '/lottery-receipt',
  lotteryHunts: `/lottery-hunts/:id`,
  courseAndEventRegistration: `/course-and-event-registration`,
  yourAccount: `/your-account`,
  customerCreate: `/customers/create`,
  customerUpdate: `/customers/`,
  eventRegistration: '/events/:id/register/:submitAction',
  eventRegistrationLogin: '/events/:id/register-login/:submitAction',
  customerEmailSubmission: '/customers/:id/email',
  customerEventListing: '/customer/my-events',
  customerLotteryApplications: '/customer/my-applications',
  preferencePoints: '/preference-points',
  onlineCourseDetail: '/online-courses/detail/:string',
  courseRegistration: '/online-courses/:string/register',
  courseRegistrationLogin: '/online-courses/:string/login',
  myOnlineCourses: '/online-courses',
  autoRenewManagement: {
    customerProducts: '/auto-renew-products',
    paymentMethod: '/auto-renew-payment-methods',
  },
};

export const resourceRoutes = {
  disclaimer: `https://www.wlf.louisiana.gov/index.cfm?action=pagebuilder&pageID=site-disclaimer`,
  louisianaConservationist: `https://www.wlf.louisiana.gov/page/la-conservationist/`,
  contact: 'https://www.wlf.louisiana.gov/page/contact-us',
  huntingSeasonInformation:
    'https://www.wlf.louisiana.gov/page/hunting-licenses-permits-tags',
  fishingSeasonInformation:
    'https://www.wlf.louisiana.gov/page/recreational-fishing-licenses-and-permits',
  commercialfishingInformation:
    'https://www.wlf.louisiana.gov/page/commercial-licenses-and-permits',
  vendorHelp: {
    root: `/vendor-help`,
    trainingVideo: `https://louisianaoutdoors.com/vendor-training.mp4`,
    trainingDocument: `https://louisianaoutdoors.com/vendor-training.pdf`,
    autoRenewDocument: `https://louisianaoutdoors.com/ar-documentation.pdf`,
    autoRenewVideo: `https://louisianaoutdoors.com/ar-documentation.mp4`,
  },
  externalBoatRenewalInformation:
    'https://www.wlf.louisiana.gov/page/title-or-register-your-boat',
  rolpLogin: 'https://ldwf.wlf.la.gov/rolp',
  huntingLicenseHelp:
    'https://www.wlf.louisiana.gov/page/hunting-licenses-permits-tags',
  organDonorApplication: 'https://registry.donatelifela.org/RegisterMe/ldwf',
};
