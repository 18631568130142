import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BasicPage} from '../basic-page';
import {
  CustomerApplicationService,
  LotteryCustomerApplicationListItemDtoDataTablePageApiResult,
  LotteryCustomerApplicationListItemDto,
  LotteryCustomerApplicationService,
  CustomerOptionsService,
} from '../api/generated';
import {
  usePagedDataTable,
  PagedDataTableConfig,
  DtoColumnSearch,
} from '../hooks/use-paged-data-table';
import {useAsync} from 'react-use';
import {dropdown, fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {getOptionDtoDropdownOptionsValueAsLabel} from '../api/generated/utils';
import {Form} from '../forms';
import {AsyncStateContainer} from '../components/async-state-container';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Divider, DropdownItemProps, Grid} from 'semantic-ui-react';
import {map} from 'lodash';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';

type ApplicationFetchParams = Parameters<
  typeof CustomerApplicationService.getTable
>['0'];

const yesNoDropdownProps = [
  {value: 'yes', text: 'YES'},
  {value: 'no', text: 'NO'},
] as DropdownItemProps[];

const formDefaults = {size: 'small'};
export const CustomerLotteryApplications = () => {
  const {id} = useParams<{id: string}>();
  const [refresh, setRefresh] = useState(+new Date());
  const [formFields, setFormFields] = useState<{}>();
  const [columnSearch, setColumnSearch] = useState<
    DtoColumnSearch<LotteryCustomerApplicationListItemDto>[]
  >([
    {propertyName: 'customerId', searchTerm: id, sortDirection: ''},
  ] as DtoColumnSearch<LotteryCustomerApplicationListItemDto>[]);

  const getActiveFilterCount = () => {
    return columnSearch.filter((x) => {
      return x.searchTerm !== '' && x.propertyName !== 'customerId';
    }).length;
  };

  const fetch = useCallback(
    (x: LotteryCustomerApplicationListItemDtoDataTablePageApiResult) => {
      if (refresh === null) {
      }
      return LotteryCustomerApplicationService.getTable({
        ...x,
        customerId: Number(id),
      } as ApplicationFetchParams);
    },
    [id, refresh]
  );

  const useFields = () => {
    const fetchFields = useAsync(async () => {
      const fetchOptions = (await CustomerOptionsService.getCustomerOptions())
        .result;

      return fieldConfig<any>({
        customerId: input({
          defaultValue: id,
          inputProps: {
            hidden: true,
          },
        }),
        status: dropdown({
          fieldLabel: 'Status',
          inputProps: {
            options: getOptionDtoDropdownOptionsValueAsLabel(
              fetchOptions?.allLotteryApplicationStatuses
            ),
            search: true,
            placeholder: 'Select a Status...',
            selection: true,
            clearable: true,
          },
        }),
        year: input({
          fieldLabel: 'Year',
        }),
        groupCode: input({
          fieldLabel: 'Group Code',
        }),
        isAwardFeePaid: dropdown({
          fieldLabel: 'Award Fee Paid',
          inputProps: {
            options: yesNoDropdownProps,
            placeholder: 'Select Fee Paid...',
            selection: true,
            clearable: true,
          },
        }),
        awardAccepted: dropdown({
          fieldLabel: 'Award Accepted',
          inputProps: {
            options: yesNoDropdownProps,
            placeholder: 'Select Award Accepted...',
            selection: true,
            clearable: true,
          },
        }),
      });
    }, []);

    return fetchFields;
  };

  const SearchFormFields = ({fields}) => {
    return (
      <Form.StyledSection title="Advanced Search">
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.year} />
          <Form.Dropdown fieldConfig={fields.status} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.groupCode} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown fieldConfig={fields.isAwardFeePaid} />
          <Form.Dropdown fieldConfig={fields.awardAccepted} />
        </Form.Row>
      </Form.StyledSection>
    );
  };

  const fetchFields = useFields();
  const fields = fetchFields.value;

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const onSubmit = (values: React.SetStateAction<{} | undefined>) => {
    setFormFields(values);
    const columnSearchDtos: DtoColumnSearch<LotteryCustomerApplicationListItemDto>[] = map(
      values,
      (value, key) => {
        return {
          propertyName: key,
          sortable: key,
          searchTerm: value,
          sortDirection: '',
        };
      }
    ) as DtoColumnSearch<LotteryCustomerApplicationListItemDto>[];
    setColumnSearch(columnSearchDtos);
    setRefresh(+new Date());
  };

  const pagedDataTable = usePagedDataTable(fetch, ApplicationTableConfig(), {
    columnParams: columnSearch,
    filterBadge: getActiveFilterCount(),
    filter: () => (
      <Form
        key={refresh}
        initialValues={formFields ?? defaultValues}
        formProps={formDefaults}
        onSubmit={onSubmit}
        render={({form}) => (
          <AsyncStateContainer {...fetchFields}>
            {fields && (
              <>
                <SearchFormFields fields={fields} />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </Form.Button>
                  {getActiveFilterCount() > 0 && (
                    <Button secondary onClick={() => setFormFields({})}>
                      Clear Filters
                    </Button>
                  )}
                </div>
                <Divider />
              </>
            )}
          </AsyncStateContainer>
        )}
      />
    ),
  });

  return (
    <BasicPage title={'My Lottery Applications'}>{pagedDataTable}</BasicPage>
  );
};

const ApplicationTableConfig = () => {
  return PagedDataTableConfig(CustomerApplicationService.getTable, {
    columns: [
      {
        header: 'Year',
        sortable: 'year',
        column: 'year',
      },
      {
        header: 'Draw Code',
        sortable: 'drawCode',
        column: 'drawCode',
      },
      {
        header: 'Lottery Name',
        sortable: 'name',
        column: 'name',
      },
      {
        header: 'Status',
        sortable: 'status',
        render: (item) => (
          <>
            {item.isApplicationFeePaid ||
            (!item.isApplicationFeePaid &&
              item.status?.toLowerCase() !== 'submitted')
              ? item.status
              : 'PENDING PAYMENT'}
          </>
        ),
      },
      {
        header: 'Group Code',
        sortable: 'groupCode',
        column: 'groupCode',
      },
      {
        header: 'Group Leader',
        column: 'groupLeader',
      },
      {
        header: 'Hunt Choices',
        render: (item) => {
          return (
            <Grid.Column>
              {item.huntChoices?.map((choice) => {
                return (
                  <Grid.Row title={choice} key={choice}>
                    {' '}
                    {choice.length > 30
                      ? choice.substring(0, 30) + '...'
                      : choice}
                  </Grid.Row>
                );
              })}
            </Grid.Column>
          );
        },
      },
      {
        header: 'Hunts Won',
        column: 'awarded',
      },
      {
        header: 'Points Before',
        column: 'prefPointsBefore',
      },
      {
        header: 'Points After',
        column: 'prefPointsAfter',
      },
      {
        header: 'Award Fee Paid',
        sortable: 'isAwardFeePaid',
        render: (item) => <>{item.isAwardFeePaid ? 'Yes' : 'No'}</>,
      },
      {
        header: 'Application Fee Paid',
        sortable: 'isApplicationFeePaid',
        render: (item) => <>{item.isApplicationFeePaid ? 'Yes' : 'No'}</>,
      },
      {
        header: 'Accepted',
        sortable: 'awardAccepted',
        column: 'awardAccepted',
      },
    ],
    defaultSort: {
      column: 'name',
      direction: 'ASC',
    },
  });
};
