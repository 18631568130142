import React from 'react';
import css from '@emotion/css/macro';
import {checkbox, fieldConfig} from '../../forms/schema-utils';
import {Form} from '../../forms';

type EarlyRenewalConfirmationFieldConfigDto = {
  isEarlyRenewalPurchaseConfirmed: boolean;
};

const useFields = () =>
  fieldConfig<EarlyRenewalConfirmationFieldConfigDto>({
    isEarlyRenewalPurchaseConfirmed: checkbox({
      //Ensures the * displays next to checkbox
      fieldLabel: ' ',
      fieldRequired: true,
      defaultValue: false,
      inputProps: {
        label: 'I hereby declare to renew the product(s) early',
      },
    }),
  });

export const EarlyRenewalConfirmation: React.FC<{
  isEarlyRenewalPurchaseConfirmed: boolean;
  setIsEarlyRenewalPurchasedConfirmed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({
  isEarlyRenewalPurchaseConfirmed,
  setIsEarlyRenewalPurchasedConfirmed,
}) => {
  const fields = useFields();

  return (
    <div css={styles} className={'early-renewal-confirmation-section'}>
      <div className={'box-description'}>
        <h3>ATTENTION</h3>
        <label>
          <strong>
            Your cart contains early renewal product(s) that will cancel your
            existing product(s) upon checkout. If you do not wish to proceed,
            please remove the early renewal product(s) from your cart.
          </strong>
        </label>
      </div>
      <Form
        onSubmit={() => {}}
        render={() => (
          <>
            {fields && (
              <Form.Row>
                <Form.Checkbox
                  fieldConfig={fields.isEarlyRenewalPurchaseConfirmed}
                  checked={isEarlyRenewalPurchaseConfirmed}
                  onClick={() => {
                    setIsEarlyRenewalPurchasedConfirmed(
                      !isEarlyRenewalPurchaseConfirmed
                    );
                  }}
                />
              </Form.Row>
            )}
          </>
        )}
      />
    </div>
  );
};

const styles = css`
  .box-description {
    padding: 1rem;
  }
`;
