import React, {useMemo, useState} from 'react';
import {Divider, Message, Modal} from 'semantic-ui-react';
import {css} from '@emotion/core';
import {Form} from '../../forms';
import {StyledButton} from '../../styled-components/styled-buttons';
import {useMailingAddressConfirmationFields} from './mailing-address-confirmation-fields';
import {MailingAddressConfirmationFormFields} from './mailing-address-confirmation-form-fields';
import {
  CustomerMailingAddressDto,
  TransactionCustomerService,
  TransactionsForCustomerService,
} from '../../api/generated';
import {getDefaults} from '../../forms/schema-utils';
import {useLocation} from 'react-router-dom';
import {notifications} from '../../utils/notification-service';

export type UpdateMailingAddressProductDto = {
  transactionId: number;
  transactionCustomerProductId: number;
  productName: string;
};

interface UpdateMailingAddressModalProps {
  initialValues: CustomerMailingAddressDto | null;
  product: UpdateMailingAddressProductDto;
  isModalOpen: boolean;
  closeModal: () => void;
  refetchLicenseInformation: () => void;
}

const subscriptionDefaults = {submitting: true, pristine: true};

export const UpdateMailingAddressModal: React.FC<UpdateMailingAddressModalProps> = ({
  initialValues,
  product,
  isModalOpen,
  closeModal,
  refetchLicenseInformation,
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const location = useLocation();
  const fields = useMailingAddressConfirmationFields(initialValues);
  const isAdmin = location.pathname.includes('admin');

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const updateMailingAddress = async (values: CustomerMailingAddressDto) => {
    setIsFormSubmitting(true);
    let response;

    if (isAdmin) {
      response = await TransactionsForCustomerService.updateMailingAddressForTransaction(
        {
          body: {
            transactionId: product.transactionId,
            customerMailingAddress: values,
          },
        }
      );
    } else {
      response = await TransactionCustomerService.updateMailingAddressForTransaction(
        {
          body: {
            transactionId: product.transactionId,
            customerMailingAddress: values,
          },
        }
      );
    }

    setIsFormSubmitting(false);

    if (!response.hasErrors && response.result) {
      notifications.success('Mailing Address Updated for Transaction');
      closeModal();
      refetchLicenseInformation();
    } else {
      notifications.error(
        'There was a problem updating the mailing address for this transaction.'
      );

      return response;
    }
  };

  return (
    <Modal size="small" open={isModalOpen}>
      <Modal.Header>Update Mailing Address</Modal.Header>
      <Form
        css={styles}
        subscription={subscriptionDefaults}
        initialValues={defaultValues}
        onSubmit={updateMailingAddress}
        render={() => (
          <>
            <Modal.Content className="modal-content">
              <Message warning>
                {`Updating the mailing address for this ${product.productName} will also update the mailing
              addresses for any Collector State Duck Stamp(s) or Hard Card(s) purchased in the same transaction. Note that updating this address will not
              change your account's mailing address.`}
              </Message>
              <MailingAddressConfirmationFormFields fields={fields} />
            </Modal.Content>
            <Divider />
            <Modal.Actions className="modal-action">
              <StyledButton
                type="button"
                secondary
                content={'Cancel'}
                onClick={closeModal}
                disabled={isFormSubmitting}
              />
              <StyledButton
                type="submit"
                primary
                content="Update Mailing Address"
                disabled={isFormSubmitting}
              />
            </Modal.Actions>
          </>
        )}
      />
    </Modal>
  );
};

const styles = css`
  .modal-content {
    padding: 1rem;
  }

  .modal-action {
    padding: 0 1rem 1rem 1rem;
  }
`;
