import React from 'react';
import {useAsync, useAsyncRetry} from 'react-use';
import {
  AdminCustomerAutoRenewalService,
  AutoRenewService,
  CustomerPortalCustomersService,
  CustomersService,
  TransactionCustomerService,
} from '../../../../api/generated';
import {BasicPage} from '../../../../basic-page';
import {EnrolledProductListing} from './enrolled-products';
import {EligibleProductListing} from './eligible-products';
import {FormContainer} from '../../../../forms/container';
import {Divider, Message} from 'semantic-ui-react';
import {AsyncStateContainer} from '../../../../components/async-state-container';

const customerMyAutoRenewalsDescription =
  'The products listed below are those which you currently own that are eligible for auto-renewal. If you do not see specific products in the list, it is because auto-renewal is not available for them at this time.';
const adminCurrentAutoRenewalsDescription =
  'The products listed below are those which the customer currently owns that are eligible for auto-renew. If you do not see specific products in the list, it is because auto-renewal is not available for them at this time. This can be toggled in Product settings.';

const customerEligibleAutoRenewalsDescription =
  'The products listed below are active products you own that are available for auto-renewal. Enabling auto-renewal for any of these will allow the product to be automatically renewed once it expires. The automatic renewal process will use the payment method saved on this account if the product has a fee.';
const adminEligibleAutoRenewalsDescription =
  'The products listed below are active products owned by the customer that are available for auto-renew. Enabling auto-renewal for any of these will allow the product to be automatically renewed once it expires. The automatic renewal process will use the payment method saved on this account if the product has a fee.';

export const ProductListing: React.FC<{
  isAdmin?: boolean;
  customerId?: number;
}> = ({isAdmin, customerId}) => {
  const fetchPaymentMethod = useAsync(async () => {
    const {result} = isAdmin
      ? await AdminCustomerAutoRenewalService.getPaymentMethod({
          customerId: customerId,
        })
      : await AutoRenewService.getPaymentMethod();
    return result;
  });

  const paymentMethod = fetchPaymentMethod.value;

  const fetchProducts = useAsyncRetry(async () => {
    const {result} = isAdmin
      ? await AdminCustomerAutoRenewalService.getProducts({
          customerId: customerId,
        })
      : await AutoRenewService.getProducts();
    return result;
  });

  const fetchConvenienceFee = useAsync(async () => {
    const {result} = await TransactionCustomerService.getConvenienceFee();
    return result;
  }, []);

  const fetchRevocationCount = useAsync(async () => {
    if (isAdmin) {
      const {result} = await CustomersService.getRevocationCount({customerId});
      return result ?? 0;
    }
    const {result} = await CustomerPortalCustomersService.getRevocationCount();
    return result?.length ?? 0;
  }, [isAdmin, customerId]);

  const enrolledProducts = fetchProducts.value?.enrolledAutoRenewProducts || [];
  const eligibleProducts = fetchProducts.value?.eligibleAutoRenewProducts || [];
  const convenienceFee = fetchConvenienceFee.value;
  const hasRevocations = (fetchRevocationCount?.value ?? 0) > 0;

  const anEnrolledProductHasPrice =
    enrolledProducts?.reduce((acc, val) => acc + val.currentPrice, 0) !== 0;
  const anEligibleProductHasPrice =
    eligibleProducts?.reduce((acc, val) => acc + val.currentPrice, 0) !== 0;
  const doesNotMeetAutoRenewCriteriaForEnrolledProducts =
    !paymentMethod && anEnrolledProductHasPrice;
  const doesNotMeetAutoRenewCriteriaForEligibleProducts =
    !paymentMethod && anEligibleProductHasPrice;

  return (
    <BasicPage title="">
      <AsyncStateContainer {...fetchPaymentMethod}>
        <AsyncStateContainer {...fetchProducts}>
          {doesNotMeetAutoRenewCriteriaForEnrolledProducts && (
            <Message error>
              <b>NO PAYMENT METHOD</b>
              <br />
              There is no payment method set up on this account. A payment
              method must be added in order to enroll a product into auto-renew
              if the product has a fee. If you already have auto-renew enabled
              for products you own that have a fee, they will NOT be
              automatically renewed unless a payment method is added.
            </Message>
          )}
          {hasRevocations && (
            <Message error>
              <b> REVOCATION </b>
              <br /> There are one or more active revocations on this account.
              Until these are resolved, you may not enroll any purchased
              products. Additionally, enrolled products will NOT be
              automatically renewed on expiration.
            </Message>
          )}

          <FormContainer>
            <h2>{isAdmin ? 'Customer Auto-Renewals' : 'My Auto-Renewals'}</h2>
            <p>
              {isAdmin
                ? adminCurrentAutoRenewalsDescription
                : customerMyAutoRenewalsDescription}
            </p>
            <p>
              <strong>
                All licenses that renew on the same day will be processed under
                a single transaction. If the product(s) being renewed require
                payment, then one ${convenienceFee?.toFixed(2)} convenience fee
                will be applied to the transaction.
              </strong>
            </p>
            <EnrolledProductListing
              isAdmin={isAdmin}
              customerId={customerId}
              enrolledProducts={enrolledProducts}
              fetchProducts={fetchProducts}
              isTryAgainButtonDisabled={
                doesNotMeetAutoRenewCriteriaForEnrolledProducts ||
                hasRevocations
              }
              convenienceFee={convenienceFee}
            />
          </FormContainer>
          <Divider hidden />

          {!doesNotMeetAutoRenewCriteriaForEnrolledProducts &&
            doesNotMeetAutoRenewCriteriaForEligibleProducts && (
              <Message warning>
                WARNING: There is no payment method set up on this account. A
                payment method must be added in order to enroll a product into
                auto-renew if the product has a fee.
              </Message>
            )}

          <FormContainer>
            <h2>Eligible Products</h2>
            <p>
              {isAdmin
                ? adminEligibleAutoRenewalsDescription
                : customerEligibleAutoRenewalsDescription}
            </p>
            <EligibleProductListing
              isAdmin={isAdmin}
              customerId={customerId}
              eligibleProducts={eligibleProducts}
              fetchProducts={fetchProducts}
              hasNoPaymentMethod={!paymentMethod}
              convenienceFee={convenienceFee}
            />
          </FormContainer>
        </AsyncStateContainer>
      </AsyncStateContainer>
    </BasicPage>
  );
};
