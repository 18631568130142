import {useEffect} from 'react';
import {useNotification} from '../hooks/use-notifications';

type NotificationService = ReturnType<typeof useNotification>;

export let notifications: NotificationService;

export const NotificationService = () => {
  const _notifications = useNotification();
  useEffect(() => {
    notifications = _notifications;
  }, [_notifications]);
  return null;
};
