import css from '@emotion/css/macro';
import React, {useEffect, useState} from 'react';
import {lighten} from 'polished';
import {useHistory} from 'react-router-dom';
import {Button, Grid, Header, Segment, Table} from 'semantic-ui-react';
import {BasicPage} from '../../basic-page';
import {useProduce} from '../../hooks/use-produce';
import {Theme} from '../../theme';
import {
  addProduct,
  catalogRoutes,
  CatalogType,
  INITIAL_HIPSURVEY,
  ProductCatalogState,
  ProductConstantCode,
  removeProduct,
  Species,
  useProductCatalogState,
} from '../product-catalog/product-catalog-utils';
import {cx} from 'emotion';
import {StyledButton} from '../../styled-components/styled-buttons';
import {useAsync} from 'react-use';
import {
  CustomerCatalogService,
  VendorCatalogService,
} from '../../api/generated';

type HipSurveyOptions = {
  label: string;
  species: Species;
  options?: AmountBaggedOptions;
}[];

type AmountBaggedOptions = {
  label: string;
  value: number;
}[];

type HipAcknowledgement = {
  [Species.isHuntingBirds]: boolean;
  [Species.ducksBagged]: boolean;
  [Species.geeseBagged]: boolean;
  [Species.dovesBagged]: boolean;
  [Species.woodcockHunted]: boolean;
  [Species.cootsAndSnipeHunted]: boolean;
  [Species.railsAndGallinulesHunted]: boolean;
  speciesBagged: SpeciesBaggedAcknowledgement;
};

type SpeciesBaggedAcknowledgement = {
  [Species.ducksBagged]: boolean;
  [Species.geeseBagged]: boolean;
  [Species.dovesBagged]: boolean;
  [Species.woodcockHunted]: boolean;
  [Species.cootsAndSnipeHunted]: boolean;
  [Species.railsAndGallinulesHunted]: boolean;
};

const INITIAL_HIP_ACKNOWLEDGEMENT: HipAcknowledgement = {
  [Species.isHuntingBirds]: false,
  [Species.ducksBagged]: false,
  [Species.geeseBagged]: false,
  [Species.dovesBagged]: false,
  [Species.woodcockHunted]: false,
  [Species.cootsAndSnipeHunted]: false,
  [Species.railsAndGallinulesHunted]: false,
  speciesBagged: {
    [Species.ducksBagged]: false,
    [Species.geeseBagged]: false,
    [Species.dovesBagged]: false,
    [Species.woodcockHunted]: false,
    [Species.cootsAndSnipeHunted]: false,
    [Species.railsAndGallinulesHunted]: false,
  },
};

const hipSurveyOptions: HipSurveyOptions = [
  {
    label: 'Doves',
    species: Species.dovesBagged,
    options: [
      {
        label: '0',
        value: 2,
      },
      {
        label: '1 - 30',
        value: 3,
      },
      {
        label: '31+',
        value: 5,
      },
    ],
  },
  {
    label: 'Ducks',
    species: Species.ducksBagged,
    options: [
      {
        label: '0',
        value: 2,
      },
      {
        label: '1 - 10',
        value: 3,
      },
      {
        label: '11+',
        value: 4,
      },
    ],
  },
  {
    label: 'Geese',
    species: Species.geeseBagged,
    options: [
      {
        label: '0',
        value: 2,
      },
      {
        label: '1 - 10',
        value: 3,
      },
      {
        label: '11+',
        value: 4,
      },
    ],
  },
  {
    label: 'Woodcocks',
    species: Species.woodcockHunted,
    options: [
      {
        label: '0',
        value: 2,
      },
      {
        label: '1 - 30',
        value: 3,
      },
      {
        label: '31+',
        value: 5,
      },
    ],
  },
  {
    label: 'Coots/Snipes',
    species: Species.cootsAndSnipeHunted,
  },
  {
    label: 'Rails/Gallinules',
    species: Species.railsAndGallinulesHunted,
  },
];

export const HipSurvey = () => {
  const history = useHistory();
  const {purchaseConfirmationRoutes} = catalogRoutes;

  const {
    catalogState,
  }: {catalogState: ProductCatalogState} = history.location.state;

  const {
    context: {state, setState},
  } = useProductCatalogState({
    prevCatalogState: catalogState,
  });

  const [
    acknowledgedQuestions,
    setAcknowledgedQuestions,
  ] = useProduce<HipAcknowledgement>(INITIAL_HIP_ACKNOWLEDGEMENT);
  const [hipComplete, setHipComplete] = useState<boolean>(false);

  const {products, catalogType, hipSurvey, customer} = state;

  const isAdmin = catalogState.catalogType === CatalogType.admin;
  const isVendor =
    catalogState.catalogType === CatalogType.vendor ||
    catalogState.catalogType === CatalogType.hqVendor;

  const fetchCustomerShoppingCart = useAsync(async () => {
    const cart =
      isAdmin || isVendor
        ? await VendorCatalogService.getShoppingCart({
            customerId: customer?.id,
          })
        : await CustomerCatalogService.getShoppingCart();
    if (cart.hasErrors) {
      return;
    }
    return cart.result;
  }, [isAdmin, isVendor, customer]);

  const shoppingCartItems = fetchCustomerShoppingCart.value?.products;

  useEffect(() => {
    if (hipComplete && catalogType) {
      history.push(purchaseConfirmationRoutes[catalogType], {
        catalogState: state,
      });
    }
  }, [catalogType, hipComplete, history, purchaseConfirmationRoutes, state]);

  const setHuntingBirds = (response: boolean) => {
    setState((draft) => {
      draft.hipSurvey = {...INITIAL_HIPSURVEY, isHuntingBirds: response};
    });
    setAcknowledgedQuestions((draft) => (draft.isHuntingBirds = true));
  };

  const setAmountBagged = (
    species: Species,
    value: number,
    acknowledgement: boolean = false
  ) => {
    if (acknowledgement) {
      setAcknowledgedQuestions((draft) => {
        const baggedSpecies = {...draft.speciesBagged};
        baggedSpecies[species] = true;
        draft.speciesBagged = baggedSpecies;
      });
    }

    setState((draft) => {
      draft.hipSurvey = {...draft.hipSurvey, [species]: value};
    });
  };

  const setSpeciesBagged = (species: Species, value: boolean) => {
    setAmountBagged(species, !value ? 1 : 2);
    setAcknowledgedQuestions((draft) => (draft[species] = true));
  };

  const didHuntSpecies = (species: Species) => {
    if (Number(hipSurvey[species]) > 1) {
      return true;
    }
    return false;
  };

  const hasBaggedOptions = (
    speciesOptions: AmountBaggedOptions | undefined
  ) => {
    return speciesOptions && speciesOptions.length > 0;
  };

  const addOrRemoveHip = async () => {
    const isHipInCart = shoppingCartItems?.find(
      (x) => x.product?.code === ProductConstantCode.hip
    );
    const hipProduct = products.find((x) => x.code === ProductConstantCode.hip);
    if (!hipProduct) return;

    if (!hipSurvey.isHuntingBirds && isHipInCart) {
      await removeProduct(hipProduct, state, setState, shoppingCartItems);
    } else if (hipSurvey.isHuntingBirds && !isHipInCart) {
      await addProduct(hipProduct, state, setState, shoppingCartItems);
    }
  };

  const [isHipAcknowledged, setIsHipAcknowledged] = useState<boolean>();

  useEffect(() => {
    const checkHipAcknowledgement = () => {
      if (acknowledgedQuestions.isHuntingBirds && !hipSurvey.isHuntingBirds)
        return true;

      for (var key in acknowledgedQuestions) {
        if (key === Species.isHuntingBirds) continue;
        if (!acknowledgedQuestions[key]) {
          return false;
        }
        const keyRef = key;
        const speciesOptions = hipSurveyOptions.find(
          (x) => x.species === keyRef
        )?.options;
        if (
          hipSurvey[key] === 2 &&
          hasBaggedOptions(speciesOptions) &&
          !acknowledgedQuestions.speciesBagged[key]
        ) {
          return false;
        }
      }

      return true;
    };

    setIsHipAcknowledged(checkHipAcknowledgement());
  }, [acknowledgedQuestions, hipSurvey]);

  const onNextPage = async () => {
    if (isHipAcknowledged) {
      await addOrRemoveHip();
      setHipComplete(true);
    }
  };

  return (
    <BasicPage title="HIP Survey">
      <Segment css={styles}>
        <Grid columns={3}>
          {hipSurvey && (
            <Grid.Row>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <h3>Will you be hunting migratory birds this season?</h3>
                <div className="migratory-birds-container">
                  <SegmentedControlButton
                    className={cx('segmented-control', 'migratory-birds')}
                    toggle
                    active={
                      acknowledgedQuestions[Species.isHuntingBirds] &&
                      hipSurvey[Species.isHuntingBirds]
                    }
                    onClick={() => setHuntingBirds(true)}
                  >
                    Yes
                  </SegmentedControlButton>
                  <SegmentedControlButton
                    className={cx('segmented-control', 'migratory-birds')}
                    toggle
                    active={
                      acknowledgedQuestions[Species.isHuntingBirds] &&
                      !hipSurvey[Species.isHuntingBirds]
                    }
                    onClick={() => setHuntingBirds(false)}
                  >
                    No
                  </SegmentedControlButton>
                </div>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <HipInstructions />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row centered>
            <Grid.Column computer={8} tablet={16} mobile={16}></Grid.Column>
          </Grid.Row>
          {acknowledgedQuestions[Species.isHuntingBirds] &&
            hipSurvey.isHuntingBirds && (
              <>
                <Grid.Row>
                  <Grid.Column mobile={16}>
                    <h3>
                      Click each species that you hunted last year, then the
                      amount you bagged.
                    </h3>
                  </Grid.Column>
                </Grid.Row>

                {hipSurveyOptions.map((species, key) => (
                  <Grid.Row className="survey-option" key={key}>
                    <>
                      <Grid.Column computer={2} tablet={16} mobile={16}>
                        <div className="label-container">{species.label}</div>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={16} mobile={16}>
                        <div>
                          <SegmentedControlButton
                            toggle
                            active={
                              acknowledgedQuestions[species.species] &&
                              didHuntSpecies(species.species)
                            }
                            onClick={() =>
                              setSpeciesBagged(species.species, true)
                            }
                          >
                            Yes
                          </SegmentedControlButton>
                          <SegmentedControlButton
                            toggle
                            active={
                              acknowledgedQuestions[species.species] &&
                              !didHuntSpecies(species.species)
                            }
                            onClick={() =>
                              setSpeciesBagged(species.species, false)
                            }
                          >
                            No
                          </SegmentedControlButton>
                        </div>
                      </Grid.Column>
                    </>
                    {didHuntSpecies(species.species) &&
                      hasBaggedOptions(species.options) && (
                        <>
                          <Grid.Column computer={2} tablet={16} mobile={16}>
                            <div className="label-container">How Many?</div>
                          </Grid.Column>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <div>
                              {species.options?.map((option) => (
                                <SegmentedControlButton
                                  toggle
                                  active={
                                    acknowledgedQuestions.speciesBagged[
                                      species.species
                                    ] &&
                                    hipSurvey[species.species] === option.value
                                  }
                                  onClick={() =>
                                    setAmountBagged(
                                      species.species,
                                      option.value,
                                      true
                                    )
                                  }
                                >
                                  {option.label}
                                </SegmentedControlButton>
                              ))}
                            </div>
                          </Grid.Column>
                        </>
                      )}

                    {acknowledgedQuestions[species.species] &&
                      didHuntSpecies(species.species) &&
                      !species.options && (
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                          <div className="label-container">
                            <Header as="h3" icon="check" content="Did hunt" />
                          </div>
                        </Grid.Column>
                      )}
                  </Grid.Row>
                ))}
              </>
            )}

          <Grid.Row>
            <Grid.Column mobile={16}>
              <StyledButton
                primary
                disabled={!isHipAcknowledged}
                onClick={async () => await onNextPage()}
              >
                Next
              </StyledButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </BasicPage>
  );
};

const SegmentedControlButton = (props) => (
  <Button className={'segmented-control'} {...props} />
);

const HipInstructions = () => {
  return (
    <>
      <Table className="hip-instructions-table" basic compact unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <strong>What is HIP?</strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <p>
                The Migratory Bird Harvest Information Program (HIP) is a method
                by which your state wildlife agency and the U.S. Fish and
                Wildlife Service (Service) are developing more reliable
                estimates of the number of all migratory birds harvested
                throughout the country. These estimates give biologists the
                information they need to make sound decisions concerning hunting
                seasons, bag limits, and population management.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

const styles = css`
  .label-container {
    padding-top: 0.5rem;
  }

  .migratory-birds-container {
    padding-top: 1rem;
  }

  .migratory-birds {
    height: 62px !important;
    width: 99px !important;
  }

  .segmented-control {
    background-color: ${lighten(0.01, Theme.palette.grey50)};
    border: 1px solid ${Theme.palette.grey100};
    border-radius: 0;
    margin: 1px 0px 1px 0px;
    height: 40px;
    width: 99px;
    color: ${Theme.palette.grey800} !important;

    &:first-of-type {
      margin-left: 0px;
      border-radius: 3px 0px 0px 3px;
    }
    &:last-of-type {
      border-radius: 0px 3px 3px 0px;
    }
    &.ui.button.toggle.active {
      background-color: ${Theme.palette.green00} !important;
      border: 1px solid ${Theme.palette.green400};

      color: ${Theme.palette.grey800} !important;
    }
  }

  .hip-instructions-table {
    border: 0px !important;

    th {
      padding-left: 0.3rem !important;
      border: 0px !important;
    }

    tr td:first-of-type {
      padding-left: 0.3em !important;
    }

    tr td:last-of-type {
      padding-right: 0.3em !important;
    }
    tbody tr td {
      border: 0px !important;
      padding: 0em !important;

      .ui.grid {
        padding-top: 0.3em;
        padding-bottom: 0.3em;

        .row {
          padding-top: 0em;
          padding-bottom: 0em;
        }
      }
    }
  }

  .survey-option {
    display: inline-block !important;
  }

  .bird-type {
    width: 10em;
    margin-right: 2em;
    margin-bottom: 1em;
  }
`;
