import React, {useEffect, useState} from 'react';
import {
  CurrentDonationDto,
  CurrentDonationsDto,
  ShoppingCartDto,
} from '../../../api/generated';
import {
  DonationProductCode,
  Donations,
} from '../../../shared/payment/donation-selection';
import huntersForTheHungryLogo from '../../../assets/images/hunters-for-the-hungry-logo.png';
import disabledVeteransLogo from '../../../assets/images/disabled-veterans-logo.png';
import {Grid, Table} from 'semantic-ui-react';
import {DateFormat} from '../../../components/date';
import css from '@emotion/css';

const labelWidth = 11;
const valueWidth = 5;
const maxWidth = 16;

export interface ShoppingCartProps {
  shoppingCart: ShoppingCartDto | null;
  selectedDonations: CurrentDonationsDto | undefined;
  fetchSelectedDonations: any;
  isPageDisabled: boolean;
}

export const ShoppingCart = ({
  shoppingCart,
  selectedDonations,
  fetchSelectedDonations,
  isPageDisabled,
}: ShoppingCartProps) => {
  const [
    selectedHuntersForHungryDonation,
    setSelectedHuntersForHungryDonation,
  ] = useState<CurrentDonationDto>();

  const [
    huntersForHungryDonationAmount,
    setHuntersForHungryDonationAmount,
  ] = useState<number>(0);

  const [
    selectedDisabledVetsDonation,
    setSelectedDisabledVetsDonations,
  ] = useState<CurrentDonationDto>();

  const [
    disabledVetsDonationAmount,
    setDisabledVetsDonationAmount,
  ] = useState<number>(0);

  const cartItemTotal =
    shoppingCart != null ? shoppingCart?.total - shoppingCart?.fee : 0;

  useEffect(() => {
    const selectedDisabledVetsDonation = fetchSelectedDonations.value?.currentDonations.find(
      (donation: CurrentDonationDto) =>
        donation.donationProductCode ===
        DonationProductCode.DisabledVeteransDonation
    );

    const selectedHuntersForHungryDonation = fetchSelectedDonations.value?.currentDonations.find(
      (donation: CurrentDonationDto) =>
        donation.donationProductCode ===
        DonationProductCode.HuntersForTheHungryDonation
    );

    setSelectedHuntersForHungryDonation(selectedHuntersForHungryDonation);
    setSelectedDisabledVetsDonations(selectedDisabledVetsDonation);
  }, [
    huntersForHungryDonationAmount,
    disabledVetsDonationAmount,
    fetchSelectedDonations,
  ]);

  return (
    <>
      <Table css={styles} basic compact unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <strong>Shopping Cart</strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <ShoppingCartProductRows shoppingCart={shoppingCart} />
          {selectedDonations &&
            selectedDonations.currentDonations
              ?.filter((donation) => donation.donationAmount > 0)
              .map((donation) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <Grid padded>
                        <Grid.Row>
                          <Grid.Column width={labelWidth}>
                            <div className="item-description-container">
                              <span className="product-name">
                                {donation.donationName}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={valueWidth} className="amount">
                            {`$${donation.donationAmount.toFixed(2)}`}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          {shoppingCart && (
            <>
              {shoppingCart.fee > 0 && (
                <Table.Row>
                  <Table.Cell>
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column width={labelWidth}>
                          <strong>Transaction Fee</strong>
                        </Grid.Column>
                        <Grid.Column width={valueWidth} className="amount">
                          <strong>{`$${shoppingCart.fee.toFixed(2)}`}</strong>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell>
                  <Grid padded>
                    <Grid.Row>
                      <Grid.Column width={maxWidth} className="amount">
                        <h3>{`Total: $${(cartItemTotal > 0
                          ? shoppingCart.total
                          : 0
                        ).toFixed(2)}`}</h3>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            </>
          )}
          <section className="lottery-donations-section">
            <div>
              <Donations
                timedShoppingCartId={shoppingCart?.id}
                donationText={
                  'Would you like to donate to Hunters for the Hungry?'
                }
                donationAmount={
                  selectedHuntersForHungryDonation?.donationAmount
                }
                setDonationAmount={(value) => {
                  setHuntersForHungryDonationAmount(value);
                  fetchSelectedDonations.retry();
                }}
                donationProductCode={
                  DonationProductCode.HuntersForTheHungryDonation
                }
                donationLogo={huntersForTheHungryLogo}
                isExternallyDisabled={isPageDisabled}
              />
              <Donations
                timedShoppingCartId={shoppingCart?.id}
                donationAmount={selectedDisabledVetsDonation?.donationAmount}
                setDonationAmount={(value) => {
                  setDisabledVetsDonationAmount(value);
                  fetchSelectedDonations.retry();
                }}
                donationText={
                  'Would you like to help purchase a Hunting and Fishing License for a disabled veteran by donating to the Disabled Veterans License Fund?'
                }
                donationProductCode={
                  DonationProductCode.DisabledVeteransDonation
                }
                donationLogo={disabledVeteransLogo}
                isExternallyDisabled={isPageDisabled}
              />
            </div>
          </section>
        </Table.Body>
      </Table>
    </>
  );
};

export interface ShoppingCartProductRowsProps {
  shoppingCart: ShoppingCartDto | null;
}

export const ShoppingCartProductRows = ({
  shoppingCart,
}: ShoppingCartProductRowsProps) => {
  return (
    <>
      {shoppingCart &&
        shoppingCart.lotteries?.map((item) => {
          return (
            <>
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Grid padded>
                    <Grid.Row>
                      <Grid.Column width={labelWidth}>
                        <div>{item.lotteryName}</div>
                        {item.isLeftover || item.isAward ? (
                          <div className="cart-item-subtext">
                            {item.hunts?.map((hunt) => {
                              return (
                                <li key={hunt.id}>
                                  <strong>{hunt.name}</strong> (
                                  <DateFormat date={hunt.startDate} /> -
                                  <DateFormat date={hunt.endDate} />)
                                  <br />
                                </li>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="cart-item-subtext">
                            <strong>Draw Date: </strong>
                            <DateFormat date={item.drawDate} />
                          </div>
                        )}
                      </Grid.Column>
                      <Grid.Column width={valueWidth} className="amount">
                        {item.itemTotal > 0 && !item.isApplicationFeeWaived
                          ? `$${item.itemTotal.toFixed(2)}`
                          : 'Free'}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            </>
          );
        })}
      {shoppingCart &&
        shoppingCart.products?.map((item) => {
          return (
            <>
              {(item.donationAmount ?? 0) > 0 && (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <div>Hunters for the Hungry Donation</div>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {`$${item?.donationAmount?.toFixed(2)}`}
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          );
        })}
    </>
  );
};

const styles = css`
  .amount {
    text-align: right;
  }
`;
