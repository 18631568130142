import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  PreferencePointsService,
  CustomerPreferencePointListItemDto,
  CustomerPreferencePointListItemDtoDataTablePageApiResult,
  CustomerOptionsService,
} from '../api/generated';
import {
  usePagedDataTable,
  PagedDataTableConfig,
  DtoColumnSearch,
} from '../hooks/use-paged-data-table';
import {useAsync} from 'react-use';
import {dropdown, fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {Form} from '../forms';
import {AsyncStateContainer} from '../components/async-state-container';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Divider} from 'semantic-ui-react';
import {map} from 'lodash';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {getOptionDtoDropdownOptionsValueAsLabel} from '../api/generated/utils';
import {StyledPageContainer} from '../styled-page-container';
import {DateFormat} from '../components/date';

type PreferencePointFetchParams = Parameters<
  typeof PreferencePointsService.getTable
>['0'];

const formDefaults = {size: 'small'};
export const PreferencePoints = () => {
  const {id} = useParams<{id: string}>();
  const [refresh, setRefresh] = useState(+new Date());
  const [formFields, setFormFields] = useState<{}>();

  const [columnSearch, setColumnSearch] = useState<
    DtoColumnSearch<CustomerPreferencePointListItemDto>[]
  >([
    {propertyName: 'customerId', searchTerm: id, sortDirection: ''},
  ] as DtoColumnSearch<CustomerPreferencePointListItemDto>[]);

  const getActiveFilterCount = () => {
    return columnSearch.filter((x) => {
      return x.searchTerm !== '' && x.propertyName !== 'customerId';
    }).length;
  };

  const fetch = useCallback(
    (x: CustomerPreferencePointListItemDtoDataTablePageApiResult) => {
      if (refresh === null) {
      }
      return PreferencePointsService.getTable({
        ...x,
      } as PreferencePointFetchParams);
    },
    [refresh]
  );

  const useFields = () => {
    const fetchFields = useAsync(async () => {
      const fetchOptions = (await CustomerOptionsService.getCustomerOptions())
        .result;

      return fieldConfig<any>({
        customerId: input({
          defaultValue: id,
          inputProps: {
            hidden: true,
          },
        }),
        year: input({
          fieldLabel: 'Year',
        }),
        preferencePointTypeName: dropdown({
          fieldLabel: 'Type',
          inputProps: {
            placeholder: 'Select a type...',
            options: getOptionDtoDropdownOptionsValueAsLabel(
              fetchOptions?.preferencePointTypes
            ),
            selection: true,
            clearable: true,
          },
        }),
      });
    }, []);

    return fetchFields;
  };

  const SearchFormFields = ({fields}) => {
    return (
      <Form.StyledSection title="Advanced Search">
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.year} />
          <Form.Dropdown fieldConfig={fields.preferencePointTypeName} />
        </Form.Row>
      </Form.StyledSection>
    );
  };

  const fetchFields = useFields();
  const fields = fetchFields.value;

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const onSubmit = (values: React.SetStateAction<{} | undefined>) => {
    setFormFields(values);
    const columnSearchDtos: DtoColumnSearch<CustomerPreferencePointListItemDto>[] = map(
      values,
      (value, key) => {
        return {
          propertyName: key,
          sortable: key,
          searchTerm: value,
          sortDirection: '',
        };
      }
    ) as DtoColumnSearch<CustomerPreferencePointListItemDto>[];
    setColumnSearch(columnSearchDtos);
    setRefresh(+new Date());
  };

  const pagedDataTable = usePagedDataTable(fetch, ApplicationTableConfig(), {
    columnParams: columnSearch,
    filterBadge: getActiveFilterCount(),
    filter: () => (
      <Form
        key={refresh}
        initialValues={formFields ?? defaultValues}
        formProps={formDefaults}
        onSubmit={onSubmit}
        render={({form}) => (
          <AsyncStateContainer {...fetchFields}>
            {fields && (
              <>
                <SearchFormFields fields={fields} />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </Form.Button>
                  {getActiveFilterCount() > 0 && (
                    <Button secondary onClick={() => setFormFields({})}>
                      Clear Filters
                    </Button>
                  )}
                </div>
                <Divider />
              </>
            )}
          </AsyncStateContainer>
        )}
      />
    ),
  });

  return (
    <StyledPageContainer
      title="My Preference Points"
      subtitle="Filter and View your Preference Points"
    >
      {pagedDataTable}
    </StyledPageContainer>
  );
};

const ApplicationTableConfig = () => {
  return PagedDataTableConfig(PreferencePointsService.getTable, {
    columns: [
      {
        header: 'Year',
        sortable: 'year',
        column: 'year',
      },
      {
        header: 'Type',
        sortable: 'preferencePointTypeName',
        column: 'preferencePointTypeName',
      },
      {
        header: 'Balance',
        sortable: 'points',
        column: 'points',
      },
      {
        header: 'Created On',
        sortable: 'createdTimestamp',
        render: (item) => <DateFormat date={item.createdTimestamp} />,
      },
      {
        header: 'Comment',
        sortable: 'comments',
        column: 'comments',
      },
    ],
    defaultSort: [
      {
        column: 'preferencePointTypeName',
        direction: 'ASC',
      },
      {column: 'createdTimestamp', direction: 'DESC'},
    ],
  });
};
