import css from '@emotion/css';
import {cx} from 'emotion';
import _ from 'lodash';
import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {Card, Segment} from 'semantic-ui-react';
import {
  BooleanApiResult,
  CourseSystemEnrollmentDetailDto,
  CustomerCatalogService,
  CustomerCourseSystemService,
  CustomerOnlineCourseService,
} from '../../api/generated';
import {useUser} from '../../auth/use-auth';
import {AsyncStateContainer} from '../../components/async-state-container';
import {Flex} from '../../components/flex';
import {
  LoadingIcon,
  StyledButton,
} from '../../styled-components/styled-buttons';
import {Typography} from '../../styled-components/typography';
import {Theme} from '../../theme';
import {notifications} from '../../utils/notification-service';
import {buildStringRoute} from '../../utils/route-helpers';
import {customerRoutes} from '../routes/config';

type RegistrationState = {
  message: string;
};

const registrationState = {
  message: '',
};

export enum completedCourseStatuses {
  Failed = 'Fail',
  Passed = 'Pass',
}

type AtemptRegistrationReturn = Omit<BooleanApiResult, 'createdEntities'> & {
  customerOwnsCourse: boolean;
  customerCourse?: CourseSystemEnrollmentDetailDto;
  customerRegisteredForCourse: boolean;
  courseWasAddedToCart: boolean;
};

export enum CourseRegistrationLocationStateCommands {
  continue = 'continue',
}

type CourseRegistrationLocationStateCommandsType = keyof typeof CourseRegistrationLocationStateCommands;

export type CourseRegistrationLocationState = {
  command?: CourseRegistrationLocationStateCommandsType;
};

export const CourseRegistration = () => {
  const history = useHistory();
  const user = useUser();
  const match = useRouteMatch<{string: string}>();
  const courseCode = String(match.params.string);
  const locationState: CourseRegistrationLocationState | undefined =
    history.location.state;
  const {command} = locationState || {};

  const [customerAcceptedDuplicate, setCustomerAcceptedDuplicate] = useState(
    false
  );

  const attemptRegistration = useAsync(async () => {
    let response = {} as AtemptRegistrationReturn;
    if (!user.customerId) {
      return null;
    }

    const customerOnlineCourses = await CustomerOnlineCourseService.getOnlineCoursesForLoggedInUser();

    let customerOwnsCourse = false;
    let customerCourse: CourseSystemEnrollmentDetailDto | undefined;

    if (customerOnlineCourses.hasErrors) {
      customerOwnsCourse = false;
      return null;
    } else if ((customerOnlineCourses.result?.length ?? 0) > 0) {
      customerCourse = customerOnlineCourses.result?.find(
        (x) =>
          x.courseCode === courseCode &&
          x.status !== completedCourseStatuses.Failed &&
          x.status !== completedCourseStatuses.Passed
      );

      customerOwnsCourse = !!customerCourse;
    }
    if (customerOwnsCourse && !customerAcceptedDuplicate) {
      response = {
        ...customerOnlineCourses,
        result: !!customerOnlineCourses.result,
        customerOwnsCourse: customerOwnsCourse,
        customerCourse: customerCourse,
        customerRegisteredForCourse: customerAcceptedDuplicate,
        courseWasAddedToCart: false,
      };

      return response;
    }

    if (
      command === CourseRegistrationLocationStateCommands.continue &&
      !customerOwnsCourse
    ) {
      notifications.info(
        'No course enrollment found. Please register for a course in order to continue.'
      );
      history.push(
        buildStringRoute(customerRoutes.onlineCourseDetail, courseCode)
      );
    }

    const addCourseToCart = await CustomerCatalogService.addOnlineCourseToCart({
      body: {
        courseCode,
      },
    });

    response = {
      ...addCourseToCart,
      customerOwnsCourse: customerOwnsCourse,
      customerCourse: customerCourse,
      customerRegisteredForCourse: customerAcceptedDuplicate,
      courseWasAddedToCart: addCourseToCart.result,
    };

    if (addCourseToCart.hasErrors) {
      addCourseToCart.validationFailures?.forEach((error) => {
        notifications.error(error.errorMessage);
      });
      return response;
    }

    return response;
  }, [
    command,
    courseCode,
    customerAcceptedDuplicate,
    history,
    user.customerId,
  ]);

  const registrationResponse = attemptRegistration.value;

  if (attemptRegistration.loading) {
    registrationState.message = 'Loading';
    return (
      <RegistrationComplete registrationState={registrationState} loading />
    );
  } else if (attemptRegistration === null) {
    registrationState.message =
      'Error. Something went wrong.  Please try again later.';
    return <RegistrationComplete registrationState={registrationState} />;
  } else if (registrationResponse?.hasErrors) {
    registrationState.message = _.join(registrationResponse.validationFailures);
    return <RegistrationComplete registrationState={registrationState} />;
  } else if (
    registrationResponse?.customerOwnsCourse &&
    !registrationResponse.customerRegisteredForCourse
  ) {
    registrationState.message =
      'You have already registered for this course.  Would you like to continue your current course?';
    return (
      <ContinueCourse
        command={command}
        setCustomerAcceptedDuplicate={setCustomerAcceptedDuplicate}
        customerCourse={registrationResponse.customerCourse}
        registrationState={registrationState}
      />
    );
  } else if (registrationResponse?.result !== null) {
    history.replace(customerRoutes.licensesAndPermits);
  }
  registrationState.message = 'Loading';
  return <RegistrationComplete registrationState={registrationState} loading />;
};

type RegistrationCompleteType = {
  registrationState: RegistrationState;
  loading?: boolean;
};

const RegistrationComplete: React.FC<RegistrationCompleteType> = ({
  registrationState,
  loading,
}) => {
  return (
    <Flex.Box>
      <div css={styles}>
        <Flex.Row>
          <Segment compact padded className={cx(`segment-non-warning`)}>
            <Card.Content>
              <Flex.Row justifyContent="space-evenly">
                <Typography variant="heading2" color={Theme.palette.blue800}>
                  <strong>
                    {registrationState.message}
                    {loading && (
                      <Flex.Row justifyContent="space-around">
                        <LoadingIcon />
                      </Flex.Row>
                    )}
                  </strong>
                </Typography>
              </Flex.Row>
            </Card.Content>
          </Segment>
        </Flex.Row>
      </div>
    </Flex.Box>
  );
};

type ContinueCourseProps = {
  command?: CourseRegistrationLocationStateCommandsType;
  setCustomerAcceptedDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
  customerCourse?: CourseSystemEnrollmentDetailDto;
  registrationState: RegistrationState;
};

const ContinueCourse: React.FC<ContinueCourseProps> = ({
  command,
  setCustomerAcceptedDuplicate,
  customerCourse,
  registrationState,
}) => {
  const [
    sendCustomerToCourseSystemState,
    sendCustomerToCourseSystem,
  ] = useAsyncFn(async () => {
    const loginUrlResponse = await CustomerCourseSystemService.getEnrollmentLoginUrlsForLoggedInUser();

    if (loginUrlResponse.hasErrors) {
      loginUrlResponse.validationFailures?.forEach((err) => {
        notifications.error(err.errorMessage);
      });
      return null;
    }

    const loginUrlForCurrentCourse = loginUrlResponse.result?.find(
      (x) => x.enrollmentIdentifier === customerCourse?.enrollmentIdentifier
    );

    if (!loginUrlForCurrentCourse) {
      notifications.error('Error finding login url.  Please try again later.');
      return null;
    }
    if (loginUrlForCurrentCourse.loginUrl) {
      window.location.href = loginUrlForCurrentCourse.loginUrl;
    }
    return true;
  });

  if (
    command === CourseRegistrationLocationStateCommands.continue &&
    !sendCustomerToCourseSystemState.loading &&
    !sendCustomerToCourseSystemState.value &&
    !sendCustomerToCourseSystemState.error
  ) {
    sendCustomerToCourseSystem();
  }

  return (
    <AsyncStateContainer {...sendCustomerToCourseSystemState}>
      <Flex.Box>
        <div css={styles}>
          {command === CourseRegistrationLocationStateCommands.continue &&
          sendCustomerToCourseSystemState.value ? (
            <Flex.Row>
              <Segment compact padded className={cx(`segment-non-warning`)}>
                <Card.Content>
                  <Flex.Row justifyContent="space-evenly">
                    <Typography
                      variant="heading2"
                      color={Theme.palette.blue800}
                    >
                      <strong>Redirecting...</strong>
                    </Typography>
                  </Flex.Row>
                </Card.Content>
              </Segment>
            </Flex.Row>
          ) : (
            <>
              <Flex.Row>
                <Segment
                  compact
                  padded
                  className={cx(`warning-message`, `orange-message`)}
                >
                  <Card.Content>
                    <Flex.Row justifyContent="space-evenly">
                      <Typography
                        variant="heading2"
                        color={Theme.palette.blue800}
                      >
                        <strong>WARNING: {registrationState.message}</strong>
                      </Typography>
                    </Flex.Row>
                  </Card.Content>
                </Segment>
              </Flex.Row>
              <Flex.Row justifyContent="space-evenly">
                <Flex.Box>
                  <StyledButton
                    loading={sendCustomerToCourseSystemState.loading}
                    onClick={() => {
                      sendCustomerToCourseSystem();
                    }}
                    primary
                    padded
                  >
                    Continue Course
                  </StyledButton>
                  <StyledButton
                    padded
                    onClick={() => setCustomerAcceptedDuplicate(true)}
                  >
                    Purchase another
                  </StyledButton>
                </Flex.Box>
              </Flex.Row>
            </>
          )}
        </div>
      </Flex.Box>
    </AsyncStateContainer>
  );
};

const styles = css`
  .warning-message.orange-message {
    padding: 12px 20px !important;
    box-shadow: none !important;
    line-height: 13px;
    width: 100%;
    margin-bottom: 10px;
    background-color: ${Theme.palette.orange50};
    border: 0px none !important;
    text-align: center;
  }

  .segment-non-warning {
    padding: 12px 20px !important;
    box-shadow: none !important;
    line-height: 13px;
    width: 100%;
    margin-bottom: 10px;
    border: 0px none !important;
    text-align: center;
  }

  .cancelled-card {
    padding: 0.2em 0.5em !important;
    margin: 1em 0em !important;
    box-shadow: none !important;
    border: 1px solid !important;
    color: #e0b4b4;
    background-color: #fff6f6;
    line-height: 13px;
    width: 100%;
    text-align: center;

    .content {
      padding: 0em !important;
    }
  }

  .event-successful-box {
    padding: 0.2em 0em !important;
    margin-bottom: 1.5em !important;
    box-shadow: none !important;
    border: none !important;
    background-color: ${Theme.palette.green00} !important;
    line-height: 13px;
    width: 100%;
    text-align: center;
    .content {
      margin: 0.4rem 1rem !important;
    }
  }

  button.link {
    background: none;
    border: none;
    outline: none;
    padding-left: 0.5rem;
    padding-right: 0rem;
    font-family: ${Theme.fonts.families.arial},
      ${Theme.fonts.families.sansSerif};
    color: #069;
    cursor: pointer;
  }
`;
