import { CustomerMailingAddressDto } from "../../api/generated";
import { StateCode, CountryCode } from "../../api/generated/enums";
import { getEnumDropdownOptions } from "../../api/generated/utils";
import { fieldConfig, input, dropdown, masked } from "../../forms/schema-utils";

export const useMailingAddressConfirmationFields = (customerMailingAddress: CustomerMailingAddressDto | null) =>
  fieldConfig<CustomerMailingAddressDto>({
    street1: input({
      fieldLabel: 'Street Address 1',
      defaultValue: customerMailingAddress?.street1,
      fieldRequired: true,
      inputProps: {
        maxLength: 100,
      },
    }),
    street2: input({
      fieldLabel: 'Street Address 2',
      defaultValue: customerMailingAddress?.street2,
      inputProps: {
        maxLength: 50,
      },
    }),
    city: input({
      fieldLabel: 'City',
      defaultValue: customerMailingAddress?.city,
      fieldRequired: true,
      inputProps: {
        maxLength: 30,
      },
    }),
    stateCode: dropdown({
      fieldLabel: 'State',
      fieldRequired: true,
      defaultValue: customerMailingAddress?.stateCode,
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(StateCode),
        placeholder: 'Select a State...',
        selection: true,
      },
    }),
    zipCode: masked({
      fieldLabel: 'Zip / Postal Code',
      fieldRequired: true,
      defaultValue: customerMailingAddress?.zipCode,
      inputProps: {
        options: {
          blocks: [5, 4],
          delimiter: '-',
          delimiterLazyShow: true,
          numericOnly: true,
        },
      },
    }),
    countryCode: dropdown({
      fieldLabel: 'Country',
      fieldRequired: true,
      defaultValue: CountryCode['UNITED STATES'],
      inputProps: {
        search: true,
        options: getEnumDropdownOptions(CountryCode),
        placeholder: 'Select a Country...',
        selection: true,
      },
    }),
  });