import {css} from '@emotion/core';
import React, {useEffect, useMemo, useState} from 'react';
import {FormSpy, useForm, useFormState} from 'react-final-form';
import {useLocation, useMedia} from 'react-use';
import {AsyncState} from 'react-use/lib/useAsyncFn';
import {Divider, Icon, Step} from 'semantic-ui-react';
import {
  BooleanApiResult,
  CustomerDetailDto,
  CustomerDetailDtoApiResult,
} from '../../../api/generated';
import {SocialSecurityNumberStatus} from '../../../api/generated/enums';
import {StyledAddressForm} from '../../../components/address-form';
import {AsyncStateContainer} from '../../../components/async-state-container';
import {Flex} from '../../../components/flex';
import {Form} from '../../../forms';
import {RawFieldConfig} from '../../../forms/schema-utils';
import {StyledButton} from '../../../styled-components/styled-buttons';
import {MediaSizes} from '../../../styles/breakpoints';
import {useCopyAddress} from '../../../utils/address-helpers';
import {getLength} from '../../../utils/enum-helpers';
import {notifications} from '../../../utils/notification-service';
import {getKeys} from '../../../utils/object-helpers';
import {
  CharacteristicsFieldConfigDto,
  CharacteristicsFieldConfigDtoKeys,
  ContactInformationFieldConfigDto,
  ContactInformationFieldConfigDtoKeys,
  PersonalInformationFieldConfigDto,
  PersonalInformationFieldConfigDtoKeys,
  useCharacteristicsFields,
  useContactInformationFields,
  usePersonalInformationFields,
} from './verification-fields';

enum VerificationSteps {
  PERSONAL_INFO,
  CONTACT_INFO,
  CHARACTERISTICS,
}

export const CustomerVerificationStepper: React.FC<{
  onSubmitState: AsyncState<BooleanApiResult | CustomerDetailDtoApiResult>;
  isEdit?: boolean;
  isUnknownKeyRemovedFromSubmitError: boolean;
  setIsUnknownKeyRemovedFromSubmitErrorToTrue: () => void;
}> = ({
  onSubmitState,
  isEdit = false,
  isUnknownKeyRemovedFromSubmitError,
  setIsUnknownKeyRemovedFromSubmitErrorToTrue,
}) => {
  const {pathname} = useLocation();
  const isVendor = pathname?.includes('vendor') ?? false;
  const isAdmin = pathname?.includes('admin') ?? false;

  const [activeStep, setActiveStep] = useState(0);
  const [didNotify, setDidNotify] = useState(false);

  const {mutators} = useForm();
  const formState = useFormState<CustomerDetailDto>();

  const isPersonalInfoStep = activeStep === VerificationSteps.PERSONAL_INFO;
  const isContactInfoStep = activeStep === VerificationSteps.CONTACT_INFO;
  const isCharacteristicsStep =
    activeStep === VerificationSteps.CHARACTERISTICS;

  const personalInfoFields = usePersonalInformationFields();
  const contactInfoFields = useContactInformationFields(
    isVendor ? 'vendor' : isAdmin ? 'admin' : 'isp'
  ).value;
  const characteristicsFields = useCharacteristicsFields().value;

  useEffect(() => {
    if (!didNotify) {
      notifications.warning(
        'Fill in any missing or required information before continuing'
      );
      setDidNotify(true);
    }

    if (formState.hasSubmitErrors) {
      if (!isUnknownKeyRemovedFromSubmitError) {
        mutators.removeUnknownKeyFromSubmitErrorsMutator(
          formState.submitErrors
        );
        setIsUnknownKeyRemovedFromSubmitErrorToTrue();
      }
      const submissionErrors = getKeys(formState.submitErrors);
      let lowestPageWithErrors = getLength(VerificationSteps);
      submissionErrors.forEach((err) => {
        if (
          Object.keys(PersonalInformationFieldConfigDtoKeys).includes(err) &&
          lowestPageWithErrors > VerificationSteps.PERSONAL_INFO
        ) {
          lowestPageWithErrors = VerificationSteps.PERSONAL_INFO;
        } else if (
          Object.keys(ContactInformationFieldConfigDtoKeys).includes(err) &&
          lowestPageWithErrors > VerificationSteps.CONTACT_INFO
        ) {
          lowestPageWithErrors = VerificationSteps.CONTACT_INFO;
        } else if (
          Object.keys(CharacteristicsFieldConfigDtoKeys).includes(err) &&
          lowestPageWithErrors > VerificationSteps.CHARACTERISTICS
        ) {
          lowestPageWithErrors = VerificationSteps.CHARACTERISTICS;
        }
      });
      setActiveStep(lowestPageWithErrors);
    }
  }, [
    didNotify,
    mutators,
    isUnknownKeyRemovedFromSubmitError,
    setIsUnknownKeyRemovedFromSubmitErrorToTrue,
    formState.hasSubmitErrors,
    formState.submitErrors,
  ]);

  const prevStep = () => {
    window.scrollTo(0, 0);
    const prevStep = activeStep > 0 ? activeStep - 1 : activeStep;
    setActiveStep(prevStep);
  };

  const nextStep = () => {
    window.scrollTo(0, 0);
    const nextStep = activeStep < 2 ? activeStep + 1 : activeStep;
    setActiveStep(nextStep);
  };

  const isLastStep = activeStep === 2;
  const isFirstStep = activeStep === 0;
  const isMobile = useMedia(`(${MediaSizes.MobileMax})`);

  const asyncState = useMemo(() => {
    const state = {
      loading: onSubmitState.loading,
      delayInMs: 0,
    };
    return state;
  }, [onSubmitState.loading]);

  return (
    <AsyncStateContainer {...asyncState}>
      <div css={styles}>
        <Flex.Row justifyContent={isFirstStep ? 'flex-end' : 'space-between'}>
          {!isFirstStep && (
            <StyledButton padded onClick={prevStep}>
              Back
            </StyledButton>
          )}
          {!isLastStep && (
            <StyledButton type="button" primary padded onClick={nextStep}>
              Next
            </StyledButton>
          )}
        </Flex.Row>

        <Step.Group size="mini" stackable="tablet" widths={3}>
          <Step
            active={isPersonalInfoStep}
            onClick={() => setActiveStep(VerificationSteps.PERSONAL_INFO)}
          >
            <Icon name="user" />
            <Step.Content>
              <Step.Title>Personal Information</Step.Title>
            </Step.Content>
          </Step>
          <Step
            active={isContactInfoStep}
            onClick={() => setActiveStep(VerificationSteps.CONTACT_INFO)}
          >
            <Icon name="volume control phone" />
            <Step.Content>
              <Step.Title>Contact Information</Step.Title>
            </Step.Content>
          </Step>
          <Step
            active={isCharacteristicsStep}
            onClick={() => setActiveStep(VerificationSteps.CHARACTERISTICS)}
          >
            <Icon name="id card" />
            <Step.Content>
              <Step.Title>Characteristics</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>

        <div className="stepper-content">
          {isPersonalInfoStep && (
            <PersonalInfoFormFields
              fields={personalInfoFields}
              isEdit={isEdit}
              initialValues={formState.initialValues}
            />
          )}

          {isContactInfoStep && contactInfoFields && (
            <ContactInfoFormFields fields={contactInfoFields} isEdit={isEdit} />
          )}

          {isCharacteristicsStep && characteristicsFields && (
            <>
              <CharacteristicsFormFields
                fields={characteristicsFields}
                isEdit={isEdit}
              />
              <StyledButton
                type="submit"
                floated="right"
                fluid={isMobile}
                primary
                padded
              >
                Submit
              </StyledButton>
            </>
          )}
        </div>

        {isMobile && !isLastStep && (
          <Flex.Row justifyContent="space-between">
            <StyledButton padded onClick={prevStep}>
              Back
            </StyledButton>
            <StyledButton type="button" primary padded onClick={nextStep}>
              Next
            </StyledButton>
          </Flex.Row>
        )}
      </div>
    </AsyncStateContainer>
  );
};

type PersonalInfoFormFieldType = {
  fields: RawFieldConfig<PersonalInformationFieldConfigDto>;
  isEdit?: boolean;
  initialValues?: Partial<CustomerDetailDto>;
};

const PersonalInfoFormFields: React.FC<PersonalInfoFormFieldType> = ({
  fields,
  isEdit,
  initialValues,
}) => {
  const SSNInputPropsDisabled = {
    disabled: true,
    options: {
      blocks: [3, 2, 4],
      delimiter: '-',
    },
  };

  const SSNInputProps = {
    disabled: false,
    options: {
      blocks: [3, 2, 4],
      delimiter: '-',
    },
  };

  return (
    <>
      <Form.Row proportions={[]}>
        <Form.Input
          readOnly={isEdit && !!initialValues?.firstName}
          fieldConfig={fields.firstName}
        />
        <Form.Input
          readOnly={isEdit && !!initialValues?.middleName}
          fieldConfig={fields.middleName}
        />
        <Form.Input
          readOnly={isEdit && !!initialValues?.lastName}
          fieldConfig={fields.lastName}
        />
        <Form.Dropdown
          disabled={isEdit && !!initialValues?.suffixCode}
          fieldConfig={fields.suffixCode}
        />
      </Form.Row>
      <Form.Row proportions={[1, 1, 1, 1]}>
        <FormSpy>
          {() => {
            return (
              <Form.DatePicker
                fieldConfig={fields.dateOfBirth}
                disabled={isEdit && !!initialValues?.dateOfBirth}
              />
            );
          }}
        </FormSpy>
        <FormSpy>
          {({values}) => {
            if (
              values?.socialSecurityNumberStatusCode === true ||
              initialValues?.socialSecurityNumber
            ) {
              fields.socialSecurityNumber!.inputProps = SSNInputPropsDisabled;
              fields.socialSecurityNumber!.fieldRequired = false;
            } else {
              fields.socialSecurityNumber!.inputProps = SSNInputProps;
              fields.socialSecurityNumber!.fieldRequired = true;
            }
            return (
              <Form.InputMasked fieldConfig={fields.socialSecurityNumber} />
            );
          }}
        </FormSpy>
        <FormSpy>
          {({values}) => {
            if (
              values.socialSecurityNumberStatusCode ===
              SocialSecurityNumberStatus['NON-EXEMPT']
            ) {
              values.socialSecurityNumberStatusCode = false;
            } else if (
              values.socialSecurityNumberStatusCode ===
              SocialSecurityNumberStatus.EXEMPT
            ) {
              values.socialSecurityNumberStatusCode = true;
            }
            return (
              <Form.Checkbox
                disabled={values?.socialSecurityNumber}
                fieldConfig={fields.socialSecurityNumberStatusCode}
              />
            );
          }}
        </FormSpy>
      </Form.Row>
      <Form.Row>
        <Form.Input
          disabled={isEdit && !!initialValues?.driversLicenseNumber}
          fieldConfig={fields.driversLicenseNumber}
        />
        <Form.Dropdown
          disabled={isEdit && !!initialValues?.driversLicenseStateCode}
          fieldConfig={fields.driversLicenseStateCode}
        />
        <Form.Input fieldConfig={fields.passportNumber} />
        <FormSpy>
          {({values}) => {
            if (values?.socialSecurityNumberStatusCode === true) {
              fields.passportCountryCode!.fieldRequired = true;
            } else {
              fields.passportCountryCode!.fieldRequired = false;
            }
            return <Form.Dropdown fieldConfig={fields.passportCountryCode} />;
          }}
        </FormSpy>
      </Form.Row>
    </>
  );
};

type ContactInfoFormFieldType = {
  fields: RawFieldConfig<ContactInformationFieldConfigDto>;
  isEdit?: boolean;
  sportsmanId?: string;
  initialValues?: CustomerDetailDto;
};

const ContactInfoFormFields: React.FC<ContactInfoFormFieldType> = ({
  fields,
  isEdit,
}) => {
  const copyAddress = useCopyAddress('physicalAddress', 'mailingAddress');

  return (
    <>
      <Form.Row>
        <Form.Input fieldConfig={fields.emailAddress} />
        <Form.InputMasked fieldConfig={fields.phoneNumberPrimary} />
        <Form.InputMasked fieldConfig={fields.phoneNumberSecondary} />
      </Form.Row>
      <Divider hidden fitted />
      <Flex.Row justifyContent="space-around" flexWrap="wrap">
        <Flex.Box>
          <StyledAddressForm
            sectionTitle="Physical Address"
            addressConfig={fields.physicalAddress}
            showPreviousAddress={isEdit}
          />
        </Flex.Box>
        <Flex.Box>
          <StyledAddressForm
            sectionTitle="Mailing Address"
            addressConfig={fields.mailingAddress}
            copyHandler={copyAddress}
          />
        </Flex.Box>
      </Flex.Row>
    </>
  );
};

type CharacteristicsFormFieldType = {
  fields: RawFieldConfig<CharacteristicsFieldConfigDto>;
  isEdit?: boolean;
  sportsmanId?: string;
  initialValues?: CustomerDetailDto;
};

const CharacteristicsFormFields: React.FC<CharacteristicsFormFieldType> = ({
  fields,
}) => {
  return (
    <>
      <Form.Row>
        <Form.Dropdown fieldConfig={fields.genderCode} />
        <Form.Dropdown fieldConfig={fields.hairColorCodeId} />
        <Form.Dropdown fieldConfig={fields.heightFeet} />
        <Form.Dropdown fieldConfig={fields.heightInches} />
      </Form.Row>
      <Form.Row proportions={[1, 1, 1, 1]}>
        <Form.Dropdown fieldConfig={fields.ethnicCode} />
        <Form.Dropdown fieldConfig={fields.eyeColorCodeId} />
        <Form.Input fieldConfig={fields.weight} />
      </Form.Row>
    </>
  );
};

const styles = css`
  .stepper-content {
    min-height: 500px;
  }
`;
