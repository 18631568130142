import {Moment} from 'moment';
import {momentCst} from './date-time-helpers';

const momentPool = new Map<string, Moment>();

export function getPooledMoment(
  dayString: string | Date | number,
  format?: string
) {
  const key = `${dayString}|${format || ''}`;
  if (!momentPool.has(key)) {
    momentPool.set(key, momentCst(dayString, format));
  }

  return momentPool.get(key) as Moment;
}
