import React, {useEffect, useState} from 'react';
import {Grid, Message, Segment} from 'semantic-ui-react';
import {
  CheckoutInformationDto,
  LotteryCheckoutService,
} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {useAsyncRetry} from 'react-use';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {routes} from '../../routes/config';
import {notifications} from '../../utils/notification-service';
import {donationsAndPaymentStyles} from '../../shared/payment/donation-selection';
import css from '@emotion/css';
import moment from 'moment';
import {CustomerInformation} from './purchase-confirmation/customer-information';
import {UpdateEmail} from './purchase-confirmation/update-email';
import {CreditCardInformation} from './purchase-confirmation/credit-card-information';
import {ShoppingCart} from './purchase-confirmation/shopping-cart';

type Error = {
  errorMessage?: string;
  propertyName?: string;
};

export type ValidationFailures = {
  hasDonationError: boolean;
  hasEmailVerificationError: boolean;
  errors: Error[];
};

export const LotteryApplicationsPaymentConfirmation = () => {
  const history = useHistory();

  const [
    validationFailures,
    setValidationFailures,
  ] = useState<ValidationFailures>({
    errors: [],
    hasDonationError: false,
    hasEmailVerificationError: false,
  });

  let validationFailureMessages = _.uniq(
    validationFailures.errors.map((error) => error.errorMessage)
  );

  const [donationAmount, setDonationAmount] = useState<number | undefined>();

  const [checkoutInformation, setCheckoutInformation] = useState<
    CheckoutInformationDto | undefined
  >();

  const [customerEmail, setCustomerEmail] = useState<string>('');

  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  const [isPageDisabled, setIsPageDisabled] = useState<boolean>(false);

  const breadcrumbs = [
    {title: 'Dashboard', url: routes.customer.dashboard},
    {title: 'Lotteries', url: routes.customer.lotteryApplications},
    {title: 'Purchase Confirmation'},
  ];

  const fetchSelectedDonations = useAsyncRetry(async () => {
    const {result} = await LotteryCheckoutService.getSelectedDonation({
      isReceiptView: false,
    });
    return result;
  }, []);

  const selectedDonations = fetchSelectedDonations.value;

  useEffect(() => {
    const fetchCheckoutInfo = async () => {
      const {result} = await LotteryCheckoutService.get();
      setCheckoutInformation(result);

      setCustomerEmail(result?.customerInformation?.emailAddress ?? '');

      const currentDonationAmount = selectedDonations?.currentDonations
        ?.map((donation) => donation.donationAmount)
        .reduce((prev, curr) => prev + curr, 0);

      if (
        (!donationAmount || donationAmount <= 0) &&
        currentDonationAmount &&
        currentDonationAmount > 0
      ) {
        setDonationAmount(currentDonationAmount);
      }
    };

    fetchCheckoutInfo();
  }, [donationAmount, history, selectedDonations]);

  useEffect(() => {
    if (
      checkoutInformation?.shoppingCart?.lotteries &&
      checkoutInformation.shoppingCart.lotteries.length <= 0
    ) {
      notifications.warning('Your cart does not have lotteries in it');
      history.replace(routes.customer.lotteryApplications);
    }
  }, [history, checkoutInformation]);

  const hasLeftoversInCart = checkoutInformation?.shoppingCart?.lotteries?.some(
    ({isLeftover}) => isLeftover === true
  );

  return (
    <BasicPage title={breadcrumbs}>
      <Segment css={donationsAndPaymentStyles}>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={12} tablet={16} mobile={16}>
              {hasLeftoversInCart &&
                checkoutInformation?.shoppingCart?.expirationTimestamp && (
                  <Message warning>
                    Your cart contains one or more leftover lottery
                    applications.
                    <br />
                    Due to high demand, please complete your transaction by{' '}
                    <b>
                      {moment(
                        checkoutInformation?.shoppingCart.expirationTimestamp
                      ).format('LT')}
                    </b>{' '}
                    to be guaranteed a spot.
                  </Message>
                )}
              <h3>Submit Your Payment</h3>
              <p>
                Please enter your billing address and credit card information
                below.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={12} tablet={16} mobile={16}>
              {checkoutInformation && checkoutInformation.shoppingCart && (
                <ShoppingCart
                  shoppingCart={checkoutInformation.shoppingCart}
                  selectedDonations={selectedDonations}
                  fetchSelectedDonations={fetchSelectedDonations}
                  isPageDisabled={isPageDisabled}
                />
              )}
            </Grid.Column>
            {checkoutInformation && checkoutInformation.customerInformation && (
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <CustomerInformation
                  customer={checkoutInformation.customerInformation}
                />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row css={styles}>
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className={'update-email'}
            >
              {checkoutInformation?.customerInformation && (
                <UpdateEmail
                  customer={checkoutInformation?.customerInformation}
                  isEmailVerified={isEmailVerified}
                  setIsEmailVerified={setIsEmailVerified}
                  setCustomerEmail={setCustomerEmail}
                  isPageDisabled={isPageDisabled}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {checkoutInformation && (
              <Grid.Column
                computer={12}
                tablet={16}
                mobile={16}
                className={'credit-card-info-section'}
              >
                {validationFailures.errors.length > 0 && (
                  <Message error list={validationFailureMessages} />
                )}
                <CreditCardInformation
                  checkoutInfo={checkoutInformation}
                  setValidationFailures={setValidationFailures}
                  donationAmount={donationAmount}
                  isEmailVerified={isEmailVerified}
                  customerEmail={customerEmail}
                  setIsPageDisabled={setIsPageDisabled}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    </BasicPage>
  );
};

const styles = css`
  .update-email {
    background-color: #fcf8e3;
    border-radius: 5px;
    margin: 15px;
  }

  .box-description {
    padding-top: 1rem;
    padding-left: 1.25rem;
  }

  .field-submission-container {
    display: flex;
    align-items: center;
  }

  .field-submission-button {
    background-color: #e0e1e2;
    margin: auto;
    margin-top: 25px;
    width: 125px;
  }
`;
