import React, {ReactNode} from 'react';
import {Card, Label, Placeholder, SemanticSIZES} from 'semantic-ui-react';
import {css} from '@emotion/core';
import {Theme} from '../theme';
import {sum} from 'lodash';
import {Media} from '../styles/breakpoints';
import {cx} from 'emotion';
import {Typography} from './typography';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Flex} from '../components/flex';
import {TypographyType} from '../theme/constants/fonts';

type StyledListingContainer = {
  proportions?: number[];
  className?: string;
};

export const countPerPage = 12;

export const StyledListingContainer: React.FC<StyledListingContainer> = ({
  proportions,
  className,
  children,
}) => {
  let styles;
  if (proportions !== undefined) {
    styles = getStyle(proportions);
  }
  return (
    <div css={listingStyles}>
      <div className={cx(className, 'styled-listing')} css={styles}>
        {children}
      </div>
    </div>
  );
};

type StyledListingCardContentType = {
  scroll?: Boolean;
  className?: string;
};

export const StyledListingCardContent: React.FC<StyledListingCardContentType> = ({
  scroll,
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={cx(
        'card-description',
        scroll ? 'card-description-scroll' : ``
      )}
    >
      <Card.Description className={cx(className)} {...rest}>
        {children}
      </Card.Description>
    </div>
  );
};

type StyledListingResultCount = {
  resultCount?: number;
  actions?: React.ReactNode;
  className?: string;
};

export const StyledListingResultCount: React.FC<StyledListingResultCount> = ({
  resultCount,
  className,
  actions,
}) => {
  return (
    <Flex.Box className={cx(className, 'result-count-container')}>
      <Flex.Row align="center" flexWrap="wrap">
        <Flex.Box>
          Showing <b>{resultCount}</b> results.
        </Flex.Box>
        <Flex.Fill className="listing-result-actions">{actions}</Flex.Fill>
      </Flex.Row>
    </Flex.Box>
  );
};

type StyledListingCardLabel = {
  color?: 'orange' | 'blue' | 'green';
  className?: string;
  size?: SemanticSIZES;
};

export const StyledListingCardLabel: React.FC<StyledListingCardLabel> = ({
  color,
  className,
  size,
  children,
}) => {
  const styles = css`
    .styled-card-label {
      &.orange {
        background-color: ${Theme.palette.orange100} !important;
        color: ${Theme.palette.blue800}!important;
        font-weight: 500 !important;
      }

      &.blue {
        background-color: ${Theme.palette.blue50} !important;
        color: ${Theme.palette.blue800}!important;
        font-weight: 500 !important;
      }

      &.green {
        background-color: ${Theme.palette.green100} !important;
        color: ${Theme.palette.blue800}!important;
        font-weight: 500 !important;
      }
    }
  `;
  return (
    <div css={styles}>
      <Label size={size} className={cx('styled-card-label', color, className)}>
        {children}
      </Label>
    </div>
  );
};
type StyledListingCard = {
  size?: 'sm' | 'smPlus' | 'md';
  className?: string;
  onClick?: () => void;
};

export const StyledListingCard: React.FC<StyledListingCard> = ({
  size,
  className,
  onClick,
  children,
}) => {
  return (
    <Card
      onClick={onClick}
      size={size}
      className={cx('styled-listing-card', size, className)}
    >
      {children}
    </Card>
  );
};

type StyledListingCardGroup = {
  className?: string;
  removeListItemClass?: boolean;
};

export const StyledListingCardGroup: React.FC<StyledListingCardGroup> = ({
  className,
  removeListItemClass,
  children,
}) => {
  return (
    <StyledListingItem
      removeListItemClass={removeListItemClass}
      className={cx(className, 'styled-listing-card-group')}
    >
      <Card.Group className="card-group-container">{children}</Card.Group>
    </StyledListingItem>
  );
};

type StyledListingCardRow = {
  icon?: IconProp;
};

export const StyledListingCardRow: React.FC<StyledListingCardRow> = ({
  icon,
  children,
}) => {
  return (
    <div className="card-info-row">
      {icon && (
        <FontAwesomeIcon
          fixedWidth
          className="icon-padded"
          size="xs"
          icon={icon}
        />
      )}
      {children}
    </div>
  );
};

type StyledListingFilterItemProps = {
  label?: ReactNode;
  className?: string;
};

export const StyledListingFilterItem: React.FC<StyledListingFilterItemProps> = ({
  label,
  className,
  children,
}) => {
  return (
    <div className="filter-item">
      <Flex.Row className="filter-label-row">
        {label && (
          <Label className={cx('filter-label', className)}>
            <Typography variant="labelLarge" color={Theme.palette.grey600}>
              {label}
            </Typography>
          </Label>
        )}
      </Flex.Row>
      {children}
    </div>
  );
};

type StyledListingFilterContainer = {
  label?: ReactNode;
  className?: string;
  removeListItemClass?: boolean;
  actions?: ReactNode;
};

export const StyledListingFilterContainer: React.FC<StyledListingFilterContainer> = ({
  label,
  className,
  removeListItemClass,
  actions,
  children,
}) => {
  return (
    <div css={filterStyles}>
      <StyledListingItem
        removeListItemClass={removeListItemClass}
        className="styled-listing-filter"
      >
        <Flex.Row justifyContent="space-between">
          {label && (
            <Label className={cx('filter-label', className)}>
              <Typography variant="heading3" color={Theme.palette.blue800}>
                {label}
              </Typography>
            </Label>
          )}
          {actions && <Flex.Box className="actions-box">{actions}</Flex.Box>}
        </Flex.Row>
        {children}
      </StyledListingItem>
    </div>
  );
};

type StyledListingItem = {
  className?: string;
  style?: any;
  removeListItemClass?: boolean;
};

export const StyledListingItem: React.FC<StyledListingItem> = ({
  className,
  children,
  removeListItemClass,
  style,
}) => {
  const fieldClassName = className ?? '';
  return (
    <div
      className={cx(!removeListItemClass && 'list-item', fieldClassName)}
      style={style}
    >
      {children}
    </div>
  );
};

type StyledLoadingCards = {
  count?: number;
};

export const StyledLoadingCards: React.FC<StyledLoadingCards> = ({count}) => {
  if (count === 0 || count === undefined) {
    count = 9;
  }
  return (
    <>
      {(Array.apply(null, Array(count ?? 12)) || []).map((_, i) => (
        <StyledListingCard key={`${i}`} className="event-card" size="sm">
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="short" />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </StyledListingCard>
      ))}
    </>
  );
};

type StyledFilterLoadingCards = {
  count?: number;
};

export const StyledFilterLoadingCards: React.FC<StyledFilterLoadingCards> = ({
  count,
}) => {
  count = count === 0 || count === undefined ? 6 : count;
  return (
    <>
      {(Array.apply(null, Array(count ?? 6)) || []).map((_, i) => (
        <StyledListingFilterItem
          key={`${i}`}
          className="event-card"
          label={<Placeholder.Line />}
        >
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length="short" />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </StyledListingFilterItem>
      ))}
    </>
  );
};

export const StyledListingCardContentRow: React.FC<{
  icon: IconProp;
  text?: string;
  iconSize?: SizeProp;
  textSize?: keyof TypographyType;
}> = ({icon, text, iconSize, textSize}) => {
  return (
    <div className="information-row">
      <Flex.Row align="center">
        <FontAwesomeIcon
          size={iconSize ?? 'xs'}
          className="card-icon"
          icon={icon}
        />
        <Typography variant={textSize ?? 'small'}>{text}</Typography>
      </Flex.Row>
    </div>
  );
};

export const InlineIcon: React.FC<{icon: IconProp; color?: string}> = ({
  icon,
  color,
}) => {
  return (
    <span css={iconStyles}>
      <FontAwesomeIcon color={color} icon={icon} className="inline-icon" />
    </span>
  );
};

const styleCache = {};
const getStyle = (proportions: number[] = []) => {
  const key = `cols-${proportions.join('-')}`;
  if (!styleCache[key]) {
    const denominator = sum(proportions);
    styleCache[key] = css`
      ${Media('TabletMin')} {
        ${(proportions || []).map(
          (x, i) => css`
            .list-item:nth-of-type(${i + 1}) {
              width: ${(x / denominator) * 100}%;
              flex: none !important;
            }
          `
        )};
      }
    `;
  }

  return styleCache[key];
};

const cardPadding = '20px';

const cardWidth = '310px';
const cardMinWidth = '245px';

const smCardHeight = '207px';
const smPlusCardHeight = '225px';
const mdCardHeight = '300px';

const filterStyles = css`
  .actions-box {
    margin-right: 2em;
  }
`;

const listingStyles = css`
  .ui.cards {
    margin: 0px !important;
  }

  .information-row {
    align-items: center;
    height: 15px;
    margin-bottom: 6px;

    svg {
      margin-right: 8px;
    }

    &:first-child {
      margin-top: 10px;
    }
    &:last-child {
      margin-bottom: 23px;
    }
  }

  .card-icon {
    width: 25px;
  }

  .filter-item {
    margin-bottom: 15px;
  }

  .list-item {
    ${Media('MobileMax')} {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .filter-item > .ui.label {
    padding: 0px;
    margin-bottom: 10px;
    background: none;
  }

  .card-group-container {
    width: 100%;
  }

  .filter-label {
    border: none !important;
    background: none !important;
    padding: 0px !important;
  }

  .filter-label-row {
    padding-bottom: 5px !important;
  }

  .listing-result-actions {
    min-width: 195px;
  }

  .styled-listing-filter {
    min-width: 240px;
  }

  .styled-listing {
    word-wrap: break-word;
    margin: 0px 20px !important;
  }

  .styled-listing-card {
    padding: 7.5px;

    ${Media('MobileMax')} {
      min-width: 290px !important;
    }

    min-width: ${cardMinWidth};
    width: ${cardWidth} !important;

    box-shadow: 0 1px 3px 0 ${Theme.palette.grey100},
      0 0 0 1px ${Theme.palette.grey100};
    border-radius: 5px;
    padding: ${cardPadding} !important;

    &.sm {
      height: ${smCardHeight} !important;
    }

    &.smPlus {
      height: ${smPlusCardHeight} !important;
    }

    &.md {
      height: ${mdCardHeight} !important;
    }

    .header {
      margin-bottom: 16px;
    }

    .card-description-scroll {
      overflow-y: auto;
    }
  }

  .icon-padded {
    color: ${Theme.palette.blue300};
    margin-right: 5px;
  }

  .result-count-container {
    width: 100%;
  }
`;

const iconStyles = css`
  .inline-icon {
    margin-right: 5px;
  }
`;
