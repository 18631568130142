const palette = {
  white1: '#FFFFFF',
  white2: '#F5F5F5',

  grey1: '#979C9E', // OLD?
  grey00: '#F4F4F4',
  grey50: '#ECEBEA',
  grey100: '#D7D6D4',
  grey200: '#C1C0BE',
  grey300: '#ACABA9',
  grey400: '#969593',
  grey500: '#81807D',
  grey600: '#6B6A67',
  grey700: '#51504E',
  grey800: '#363634',
  grey900: '#1C1C1B',

  brown1: '#79614D', // OLD?
  brown50: '#EFEAE6',
  brown100: '#D6CAC2',
  brown200: '#BCAB9D',
  brown300: '#A38B79',
  brown400: '#8E7663',
  brown500: '#79614D',
  brown600: '#635141',
  brown700: '#4E4034',
  brown800: '#383028',
  brown900: '#221F1B',

  orange50: '#FAECDC',
  orange100: '#F1CDA2',
  orange200: '#E9AF69',
  orange300: '#E0902F',
  orange400: '#D07C29',
  orange500: '#C06824',
  orange600: '#B0541E',
  orange700: '#A04018',
  orange800: '#7D3212',
  orange900: '#59240D',

  yellow50: '#F8F3DD',
  yellow100: '#EADCA1',
  yellow200: '#DCC565',
  yellow300: '#CEAE29',
  yellow400: '#B79D29',
  yellow500: '#A18B28',
  yellow600: '#8A7A28',
  yellow700: '#73631F',
  yellow800: '#5B4C15',
  yellow900: '#44350C',

  green1: '#455a21', // OLD?
  green2: '#60753b', // OLD?
  green00: '#F4F6E4',
  green50: '#EDF3CE',
  green100: '#D0DA97',
  green200: '#B3C161',
  green300: '#96A82A',
  green400: '#839525',
  green500: '#718220',
  green600: '#5E6F1C',
  green700: '#4B5B17',
  green800: '#394812',
  green900: '#26350D',

  blue00: '#ECF6FD',
  blue50: '#D6E3EB',
  blue100: '#ABCADA',
  blue200: '#80B2C8',
  blue300: '#5599B7',
  blue400: '#4588A5',
  blue500: '#357693',
  blue600: '#2D6179',
  blue700: '#254C5E',
  blue800: '#1C3644',
  blue900: '#142129',

  black1: '#1B1C1D',
  black2: '#444',
  transparent: 'rgba(0,0,0,0)',
};

export default palette;
