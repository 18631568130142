import css from '@emotion/css/macro';
import React from 'react';
import {Header, Divider, Grid, Container, Message} from 'semantic-ui-react';

import {AccountService} from '../api/generated';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';
import {
  ChangePasswordForm,
  ChangePasswordDto,
  initialValues,
} from '../components/change-password-form';
import {useQueryParams} from '../hooks/use-query-params';
import {useHistory} from 'react-router-dom';

export const ChangeExpiredPassword = () => {
  const {id, isVendor} = useQueryParams<{id: string; isVendor: string}>();
  const history = useHistory();
  const userIsVendor = isVendor === '1';

  const onSubmit = async (values: ChangePasswordDto) => {
    const defaultValues = {
      userId: id,
      oldPassword: '',
      password: '',
      confirmPassword: '',
    };
    const body = {...defaultValues, ...values};

    const response = await AccountService.changeExpiredPassword({body});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Password Changed');
    history.goBack();
  };

  return (
    <Grid css={styles} verticalAlign="middle">
      <Grid.Column>
        <Container text>
          <Form.Container className="form-container">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={() => (
                <>
                  {userIsVendor && (
                    <Message
                      className="info"
                      icon="warning circle"
                      header="First time logging in?"
                      content={
                        <>
                          <strong>
                            If this is your first login, your "Old Password" in
                            the form below will be:
                          </strong>
                          <br />
                          {
                            'WLF<Your Username (all lowercase)><Last 4 digits of Vendor ID>!'
                          }
                          <br />
                          <br />
                          <strong>Example: </strong>
                          {'WLFusername1234!'}
                        </>
                      }
                    />
                  )}
                  <ChangePasswordForm
                    title={
                      <>
                        Department of Wildlife &amp; Fisheries
                        <Header.Subheader>
                          Your password has expired. Please set a new password
                        </Header.Subheader>
                      </>
                    }
                    oldPasswordRequired={true}
                  />
                  <Divider />
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Set Password
                    </Form.Button>
                  </div>
                </>
              )}
            ></Form>
          </Form.Container>
        </Container>
      </Grid.Column>
    </Grid>
  );
};

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
`;
