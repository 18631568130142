import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Popup, PopupProps} from 'semantic-ui-react';
import {Tooltip} from '../components/tooltip';
import {Button} from '../forms/button';
import {StyledButton} from './styled-buttons';

type StyledConfirmButton = Omit<Button, 'type' | 'as'> & {
  onConfirm: () => void;
  loading?: boolean;
  text?: string;
  tooltip?: string;
  confirmPosition?: PopupProps['position'];
  loadingText?: string;
  confirmText?: string;
  icon?: IconDefinition;
  confirmProps?: Omit<Button, 'type' | 'as'>;
  color?: string;
  buttonHoverColor?: string;
};

export const StyledConfirmButton: React.FC<StyledConfirmButton> = ({
  onConfirm,
  loading,
  text,
  loadingText = 'Loading...',
  confirmText = 'Yes, I am sure',
  confirmPosition,
  icon,
  tooltip = '',
  confirmProps,
  color,
  buttonHoverColor,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onConfirmClick = (e) => {
    e.stopPropagation();
    onConfirm();
    onClose();
  };

  const TooltipWrapper = tooltip ? Tooltip : React.Fragment;
  const tooltipProps = tooltip ? {label: tooltip} : ({} as any);

  const TriggerButton = () => {
    return (
      <TooltipWrapper {...tooltipProps}>
        <StyledButton
          type="button"
          disabled={loading}
          buttonColor={color}
          buttonHoverColor={buttonHoverColor}
          {...props}
          onClick={toggleOpen}
        >
          {!!icon && (
            <>
              <FontAwesomeIcon icon={icon} />{' '}
            </>
          )}
          <span>{loading ? loadingText : text}</span>
          {children && children}
        </StyledButton>
      </TooltipWrapper>
    );
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      trigger={<TriggerButton />}
      //The popup moving on scroll is an issue that is fixed in semantic-react-2.0
      //For now, hideOnScroll is the most ideal solution to the problem
      //See https://github.com/Semantic-Org/Semantic-UI-React/issues/1126 and https://github.com/Semantic-Org/Semantic-UI-React/pull/3947
      hideOnScroll
      content={
        <StyledButton
          type="button"
          onClick={onConfirmClick}
          disabled={loading}
          {...confirmProps}
        >
          {confirmText}
        </StyledButton>
      }
      position={confirmPosition ?? 'right center'}
      on="click"
    />
  );
};

type DeleteButton = {
  onConfirm: StyledConfirmButton['onConfirm'];
  loading: StyledConfirmButton['loading'];
  text?: string;
};

const ConfirmProps = {negative: true, primary: false};

export const DeleteButton: React.FC<DeleteButton> = (props) => (
  <StyledConfirmButton
    {...props}
    text={props.text ? props.text : 'Delete'}
    loadingText="Loading..."
    confirmText="Yes, I am sure"
    confirmPosition="right center"
    confirmProps={ConfirmProps}
    tertiary
    negative
  />
);
