import _ from 'lodash';
import css from '@emotion/css/macro';
import {useAsync, useAsyncRetry, useMedia} from 'react-use';
import {
  LotteryApplicationService,
  HuntSelectionItemDto,
  HuntSelectionListItemDto,
  HuntSelectionDto,
  LotterySelectionDto,
  LotteryShoppingCartService,
} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {useHistory} from 'react-router-dom';
import {AsyncStateContainer} from '../../components/async-state-container';
import React, {useState, useMemo, useEffect} from 'react';
import {customerRoutes as routes} from '../routes/config';
import {
  Card,
  CardGroup,
  Divider,
  Dropdown,
  DropdownItemProps,
  Icon,
  Segment,
  Modal,
  Button,
  Label,
  Form,
  Select,
  Popup,
  Message,
} from 'semantic-ui-react';
import {useRouteMatch} from 'react-router-dom';
import {Media, MediaSizes} from '../../styles/breakpoints';
import {notifications} from '../../utils/notification-service';
import {StyledButton} from '../../styled-components/styled-buttons';
import {DateFormat} from '../../components/date';
import ReactQuill from 'react-quill';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartBar, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

const moduleOptions = {
  toolbar: false,
  clipboard: {
    matchVisual: false,
  },
};

export const LotteryHunts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const [selectedHunts, setSelectedHunts] = useState(
    Array<HuntSelectionItemDto>()
  );

  const fetchLottery = useAsyncRetry(async () => {
    const {result} = await LotteryApplicationService.getHunts({
      lotteryId: id,
    });
    return result;
  }, [id]);
  const lottery = fetchLottery.value;

  const fetchHuntSelections = useAsync(async () => {
    const {
      result,
    } = await LotteryApplicationService.getApplicationHuntSelections({
      lotteryId: id,
    });
    return result;
  }, [id]);
  const huntSelections = fetchHuntSelections.value || [];

  const isUpdate = huntSelections.length > 0;

  return (
    <AsyncStateContainer {...fetchLottery}>
      {lottery && (lottery.hunts?.length ?? 0) > 0 ? (
        <BasicPage title={lottery.name ?? ''}>
          <Segment css={styles}>
            {lottery.applicationInstructions != null ? (
              <ReactQuill
                readOnly={true}
                value={lottery.applicationInstructions}
                className={`html-editor`}
                modules={moduleOptions}
              />
            ) : (
              <p className="empty-instructions">
                NO APPLICATION INSTRUCTIONS AVAILABLE
              </p>
            )}
          </Segment>
          <LotteryChoiceSegment
            lottery={lottery}
            selectedHunts={selectedHunts}
            setSelectedHunts={setSelectedHunts}
            isUpdate={isUpdate}
            fetchLottery={fetchLottery}
          />
        </BasicPage>
      ) : (
        <BasicPage title={''}>
          <Segment>
            <h3 className="no-results">NO RESULTS</h3>
          </Segment>
        </BasicPage>
      )}
    </AsyncStateContainer>
  );
};

const QuantityInput: React.FC<{
  lottery: LotterySelectionDto;
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>;
}> = ({lottery, setSelectedQuantity}) => {
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  let options = new Array<object>();
  for (let i = 1; i < lottery.allowedApplications + 1; i++) {
    const newOption = {
      key: i.toString(),
      text: i.toString(),
      value: i,
    };
    options.push(newOption);
  }

  const handleChange = (
    e: any,
    data: {value: React.SetStateAction<number>}
  ) => {
    setSelectedQuantity(data.value);
  };

  return (
    <>
      {!lottery.isLeftover && lottery.allowedApplications > 1 && (
        <Form css={styles}>
          <Form.Field>
            <label>
              How many applications would you like to add to your cart?{' '}
              <Popup
                on={mobileMax ? 'click' : 'hover'}
                trigger={
                  <span className="popup-icon">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                }
                wide="very"
              >
                Each application purchased is another entry into this lottery's
                drawing for the hunt(s) selected. A max of{' '}
                {`${lottery.choicesPerApplication} ${
                  lottery.choicesPerApplication > 1
                    ? 'applications are'
                    : 'application is'
                } `}
                allowed for this lottery.
              </Popup>
            </label>
            <Form.Field
              defaultValue={1}
              control={Select}
              width={2}
              options={options}
              onChange={handleChange}
            />
          </Form.Field>
        </Form>
      )}
    </>
  );
};

const LotteryChoiceSegment: React.FC<{
  lottery: LotterySelectionDto;
  selectedHunts: HuntSelectionItemDto[];
  setSelectedHunts: React.Dispatch<
    React.SetStateAction<HuntSelectionItemDto[]>
  >;
  isUpdate: boolean;
  fetchLottery: any;
}> = ({lottery, selectedHunts, setSelectedHunts, isUpdate, fetchLottery}) => {
  const ranks = GetRankDropdownOptions(lottery.choicesPerApplication);
  //We want 1 application to be the default value for this selection. This way, they only change this field if they want more
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const numberOfAllowedSelections = lottery.choicesPerApplication;
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <Segment css={styles}>
      <h3>Hunt Choices</h3>
      <p>
        Please select{' '}
        {
          <strong>
            up to {lottery.isLeftover ? 1 : numberOfAllowedSelections}
          </strong>
        }{' '}
        choice
        {numberOfAllowedSelections > 1 ? 's' : ''} below for
        <strong>
          {lottery.name}{' '}
          <Popup
            on={mobileMax ? 'click' : 'hover'}
            trigger={
              <span className="popup-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            }
            wide="very"
          >
            The hunt choices you select here will be the ones you are eligible
            to win should your name be drawn. Only select hunts that you want to
            participate in.
            {lottery.hasRankedChoices && (
              <>
                <br />
                <br />
                <strong> Ranked Choices: </strong> Rank these hunts in the order
                you would prefer to participate in them, from most preferred to
                least.
              </>
            )}
          </Popup>
        </strong>
      </p>
      <Card.Group>
        {lottery.hunts?.map((hunt: HuntSelectionListItemDto) => (
          <Card key={hunt.id}>
            <LotteryHuntInfo lottery={lottery} hunt={hunt} />
            <LotteryHuntSelection
              lottery={lottery}
              selectedHunts={selectedHunts}
              setSelectedHunts={setSelectedHunts}
              ranks={ranks}
              hunt={hunt}
            />
          </Card>
        ))}
      </Card.Group>
      <Divider hidden />
      <QuantityInput
        lottery={lottery}
        setSelectedQuantity={setSelectedQuantity}
      />
      <Divider />
      <LotteryStandardActions
        lottery={lottery}
        selectedHunts={selectedHunts}
        selectedQuantity={selectedQuantity}
        isUpdate={isUpdate}
        fetchLottery={fetchLottery}
        setSelectedHunts={setSelectedHunts}
      />
    </Segment>
  );

  function GetRankDropdownOptions(numberOfHunts: number) {
    const rankOptionsArray = Array<number>();
    for (let i = 0; i < numberOfHunts; i++) {
      rankOptionsArray.push(i + 1);
    }
    return rankOptionsArray;
  }
};

const LotteryStandardActions: React.FC<{
  lottery: LotterySelectionDto;
  selectedHunts: HuntSelectionItemDto[];
  setSelectedHunts: React.Dispatch<
    React.SetStateAction<HuntSelectionItemDto[]>
  >;
  selectedQuantity: number;
  isUpdate: boolean;
  fetchLottery: any;
}> = ({
  lottery,
  selectedHunts,
  setSelectedHunts,
  selectedQuantity,
  isUpdate,
  fetchLottery,
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);

  const fetchLeftoverApplicationCartTimer = useAsync(async () => {
    const {
      result,
    } = await LotteryShoppingCartService.getLeftoverApplicationCartTimer();
    return result;
  });

  const shoppingCartTimer = fetchLeftoverApplicationCartTimer.value;

  const submitHuntSelection = async () => {
    setModalOpen(false);
    const selections = {
      lotteryId: lottery.id,
      selectedHunts: selectedHunts,
      quantity: selectedQuantity,
      isFeePaidOnline: lottery.isFeePaidOnline,
    } as HuntSelectionDto;

    setLoading(true);
    let apiResponse;
    if (isUpdate && !lottery.isLeftover) {
      apiResponse = await LotteryApplicationService.updateHuntChoices({
        body: selections,
      });
    } else {
      apiResponse = await LotteryApplicationService.createApplication({
        body: selections,
      });
    }
    setLoading(false);

    if (apiResponse.hasErrors) {
      notifications.error('Hunt Selection Failed');
      apiResponse.validationFailures?.forEach((x) => {
        notifications.error(x.errorMessage);
      });

      fetchLottery.retry();
      setSelectedHunts([]);

      return apiResponse;
    }
    notifications.success(
      isUpdate ? 'Hunt Update Successful' : 'Hunt Selection Successful'
    );
    history.push(routes.lotteryApplications);
  };

  const ConfirmationModal: React.FC<{}> = () => {
    let selectedHuntsClone = _.cloneDeep(selectedHunts);
    selectedHuntsClone.sort((a: {rank: number}, b: {rank: number}) =>
      a.rank > b.rank ? 1 : -1
    );

    return (
      <Modal
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        size={'large'}
        trigger={
          <Button
            primary
            disabled={selectedHuntsClone.length === 0}
            type="button"
            content={isUpdate ? 'Update Choices' : 'Continue'}
          />
        }
      >
        <Modal.Header>Confirm Selection</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{`Are you sure you want to select ${
              selectedHuntsClone.length > 1 ? 'these hunts' : 'this hunt'
            }?`}</p>
            {!lottery.isFeePaidOnline && (
              <Message warning>
                This lottery's fees are paid offline. Be sure to read the
                description of the lottery on this page to ensure understanding
                of the offline payment process for this lottery before
                confirming.
              </Message>
            )}
            <Divider />
            <CardGroup>
              {selectedHuntsClone.map(
                (hunt: {huntId: number; rank: number}) => {
                  const matchingHunt = lottery.hunts?.filter(
                    (x: {id: number}) => x.id === hunt.huntId
                  );
                  if (!matchingHunt?.length) {
                    return null;
                  }
                  return (
                    <Card key={matchingHunt[0].id}>
                      {hunt.rank !== 0 && (
                        <Label floating color={'green'}>
                          {hunt.rank}
                        </Label>
                      )}
                      <LotteryHuntInfo
                        lottery={lottery}
                        hunt={matchingHunt[0]}
                      />
                    </Card>
                  );
                }
              )}
            </CardGroup>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {lottery.isLeftover && (
            <Message warning>
              <b>Note:</b> Due to high demand, once you add a leftover lottery
              application to your cart, you will have {shoppingCartTimer}{' '}
              minutes to complete your purchase to be guaranteed a spot.
            </Message>
          )}
          <StyledButton content="Cancel" onClick={() => setModalOpen(false)} />
          <StyledButton
            content="Confirm"
            primary
            loading={loading}
            onClick={submitHuntSelection}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <>
      <ConfirmationModal />
      <Button
        type="button"
        onClick={() => {
          history.push(routes.lotteryApplications);
        }}
        content={'Cancel'}
      />
    </>
  );
};

export const LotteryHuntInfo: React.FC<{
  lottery: LotterySelectionDto;
  hunt: HuntSelectionListItemDto;
}> = ({lottery, hunt}) => {
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <>
      <Card.Content css={styles}>
        <Card.Header>
          {hunt.description}{' '}
          <Popup
            on={mobileMax ? 'click' : 'hover'}
            position="top center"
            wide="very"
            trigger={
              <span className="popup-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            }
          >
            {hunt.additionalInformation == null
              ? 'No additional information available'
              : hunt.additionalInformation}
          </Popup>
          {!lottery.isNewLottery && (
            <div className="lottery-stats">
              <div>
                Last Year's Stats{' '}
                <Popup
                  on={mobileMax ? 'click' : 'hover'}
                  position="top center"
                  wide="very"
                  trigger={
                    <span className="popup-icon">
                      <FontAwesomeIcon icon={faChartBar} />
                    </span>
                  }
                >
                  Quota: {hunt.previousYearQuota} | Applicant Count:{' '}
                  {hunt.previousYearApplicantCount} | Success Rate:{' '}
                  {hunt.previousYearSuccessRate}
                </Popup>
              </div>
            </div>
          )}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Description>
          <p>
            <Icon name="marker" />
            {hunt.locationName}
          </p>
          <p>
            <Icon name="clock" />
            <DateFormat date={hunt.startDate} /> -{' '}
            <DateFormat date={hunt.endDate} />
          </p>
          {lottery.isLeftover ? (
            <strong>
              <Icon name="user" />
              {`${hunt.availableSlots} spot(s) remaining`}
            </strong>
          ) : (
            <p>
              <Icon name="user" />
              {`Quota: ${hunt.quota}`}
            </p>
          )}
          <p>
            <Icon name="group" />
            {`Applicant Count: ${hunt.applicantCount}`}
          </p>
        </Card.Description>
      </Card.Content>
    </>
  );
};

const LotteryHuntSelection: React.FC<{
  lottery: LotterySelectionDto;
  selectedHunts: HuntSelectionItemDto[];
  setSelectedHunts: React.Dispatch<
    React.SetStateAction<HuntSelectionItemDto[]>
  >;
  ranks: number[];
  hunt: HuntSelectionListItemDto;
}> = ({lottery, selectedHunts, setSelectedHunts, ranks, hunt}) => {
  const selectableRanks = useMemo<number[]>(() => {
    return _.reject(ranks, (rank) => {
      return _.some(selectedHunts, (h) => {
        return h.huntId !== hunt.id && h.rank === rank;
      });
    });
  }, [ranks, hunt, selectedHunts]);

  const choiceOptions = selectableRanks.map<DropdownItemProps>((rankOption) => {
    return {
      value: rankOption,
      text: 'Choice ' + rankOption,
    };
  });

  return (
    <Card.Content extra textAlign="center">
      {lottery.hasRankedChoices && !lottery.isLeftover ? (
        <LotteryRankedChoiceDropdown
          lottery={lottery}
          selectedHunts={selectedHunts}
          setSelectedHunts={setSelectedHunts}
          choiceOptions={choiceOptions}
          selectableRanks={selectableRanks}
          hunt={hunt}
        />
      ) : (
        <LotteryUnrankedChoiceButton
          lottery={lottery}
          selectedHunts={selectedHunts}
          setSelectedHunts={setSelectedHunts}
          hunt={hunt}
        />
      )}
    </Card.Content>
  );
};

const LotteryUnrankedChoiceButton: React.FC<{
  lottery: LotterySelectionDto;
  selectedHunts: HuntSelectionItemDto[];
  setSelectedHunts: React.Dispatch<
    React.SetStateAction<HuntSelectionItemDto[]>
  >;
  hunt: HuntSelectionListItemDto;
}> = ({lottery, selectedHunts, setSelectedHunts, hunt}) => {
  let selectedHuntsClone = _.cloneDeep(selectedHunts);
  const huntSelected = isHuntCurrentlySelected(selectedHunts, hunt);
  const choicesAllowed = lottery.isLeftover ? 1 : lottery.choicesPerApplication;
  const madeAllSelections = selectedHunts.length === choicesAllowed;
  const huntHasAvailableSlots = hunt.availableSlots > 0 || !lottery.isLeftover;

  return (
    <StyledButton
      primary={!huntSelected}
      secondary={huntSelected}
      disabled={(madeAllSelections && !huntSelected) || !huntHasAvailableSlots}
      onClick={toggleHuntInclusion}
      content={
        huntHasAvailableSlots ? (huntSelected ? 'Remove' : 'Select') : 'Full'
      }
    />
  );

  function toggleHuntInclusion() {
    if (!huntSelected) {
      const newHunt = {
        huntId: hunt.id,
        //we set this to 0 to satisfy the non-nullable SQL parameter -- 0 means no selection
        rank: 0,
      } as HuntSelectionItemDto;
      selectedHuntsClone.push(newHunt);
    } else {
      selectedHuntsClone = removeUnselectedHuntSelectionItem(
        selectedHuntsClone,
        hunt
      );
    }
    setSelectedHunts(selectedHuntsClone);
  }

  function isHuntCurrentlySelected(
    selectedHunts: Array<HuntSelectionItemDto>,
    hunt: HuntSelectionListItemDto
  ) {
    const indexOfHunt = selectedHunts.findIndex(function (selectedHunt) {
      return selectedHunt.huntId === hunt.id;
    });
    const isHuntAlreadySelected = indexOfHunt !== -1;
    return isHuntAlreadySelected;
  }
};

const LotteryRankedChoiceDropdown: React.FC<{
  lottery: LotterySelectionDto;
  selectedHunts: HuntSelectionItemDto[];
  setSelectedHunts: React.Dispatch<
    React.SetStateAction<HuntSelectionItemDto[]>
  >;
  choiceOptions: DropdownItemProps[];
  selectableRanks: number[];
  hunt: HuntSelectionListItemDto;
}> = ({
  lottery,
  selectedHunts,
  setSelectedHunts,
  choiceOptions,
  selectableRanks,
  hunt,
}) => {
  const mobileMax = useMedia(`(${MediaSizes.MobileMax})`);

  return (
    <Dropdown
      clearable
      selection
      fluid={mobileMax}
      options={choiceOptions}
      selectOnBlur={false}
      placeholder={'Select Choice'}
      onChange={(changeEvent, {value}) => {
        const matchingHunt = lottery.hunts?.filter(
          (x: {id: number}) => x.id === hunt.id
        );
        if (!matchingHunt) {
          return;
        }
        const currentHuntRank = matchingHunt[0].rank;
        const selectableRanksClone = _.cloneDeep(selectableRanks);

        let selectedHuntsClone = _.cloneDeep(selectedHunts);

        //if value is null (this means the select was cleared)
        if (!value || value === '') {
          selectableRanksClone.push(currentHuntRank);
          selectedHuntsClone = removeUnselectedHuntSelectionItem(
            selectedHuntsClone,
            hunt
          );

          matchingHunt[0].rank = 0;
          setSelectedHunts(selectedHuntsClone);

          return;
        }

        //If the user swaps out a value for a new one
        if (
          value !== '' &&
          value !== currentHuntRank.toString() &&
          currentHuntRank !== 0
        ) {
          selectedHuntsClone = removeUnselectedHuntSelectionItem(
            selectedHuntsClone,
            hunt
          );
          selectableRanksClone.push(matchingHunt[0].rank);
        }

        matchingHunt[0].rank = value as number;

        const newHunt = {
          huntId: hunt.id,
          rank: value,
        } as HuntSelectionItemDto;

        selectedHuntsClone.push(newHunt);

        setSelectedHunts(selectedHuntsClone);
      }}
    />
  );
};

function removeUnselectedHuntSelectionItem(
  selectedHunts: Array<HuntSelectionItemDto>,
  hunt: HuntSelectionListItemDto
) {
  const indexOfHuntToRemove = selectedHunts.findIndex(function (selectedHunt) {
    return selectedHunt.huntId === hunt.id;
  });
  const isHuntSelected = indexOfHuntToRemove !== -1;

  if (isHuntSelected) {
    selectedHunts.splice(indexOfHuntToRemove, 1);
  }
  return selectedHunts;
}

const styles = css`
  .lottery-stats {
    color: black;
    font-size: 1rem;
    font-weight: normal;
  }

  .no-results {
    margin-left: 50%;
    margin-top: 24px;
    color: #d1d1d1;
  }

  .empty-instructions {
    color: #b6b4b4;
  }

  ${Media('MobileMax')} {
    .popup-icon:hover {
      cursor: pointer;
    }
  }
`;
