import css from '@emotion/css/macro';
import {useAsync} from 'react-use';
import {Card, Grid, Label} from 'semantic-ui-react';
import {AsyncStateContainer} from '../../components/async-state-container';
import {Typography} from '../../styled-components/typography';
import {StyledPageContainer} from '../../styled-page-container';
import React, {useMemo} from 'react';
import {CustomerEventsService, IspEventListItemDto} from '../../api/generated';
import {StyledButton} from '../../styled-components/styled-buttons';
import {cloneDeep} from 'lodash';
import {
  sortEventsByFirstStartDate,
  startToEndDateString,
} from '../../utils/date-helpers';
import {Flex} from '../../components/flex';
import {useHistory} from 'react-router-dom';
import {buildPath, routes} from '../../routes/config';
import {combineRegisteredAndWaitlistedEvents} from '../../utils/event-helpers';
import {cstNow, momentCst} from '../../utils/date-time-helpers';

export const CustomerEventListing = () => {
  const fetchEvents = useAsync(async () => {
    const events = await CustomerEventsService.getEventsForLoggedInUser();
    return events.result;
  }, []);

  const eventsSorted = useMemo(() => {
    if (!fetchEvents.value) {
      return null;
    }

    const events = combineRegisteredAndWaitlistedEvents<IspEventListItemDto>(
      fetchEvents.value.registeredEvents,
      fetchEvents.value.waitlistedEvents
    ).sort(sortEventsByFirstStartDate);

    const noEventDates = events.filter(
      (x) => (x.eventDateTimes?.length ?? 0) === 0
    );

    const rightNow = cstNow().toDate();

    const datesInThePresent = events.filter(
      (x) =>
        x.eventDateTimes &&
        x.eventDateTimes.length > 0 &&
        +momentCst(x.eventDateTimes[0].eventDate).format('yyyyMMDD') <=
          +momentCst(rightNow).format('yyyyMMDD') &&
        +momentCst(cloneDeep(x.eventDateTimes).reverse()[0].eventDate).format(
          'yyyyMMDD'
        ) >= +momentCst(rightNow).format('yyyyMMDD')
    );

    const datesInTheFuture = events.filter(
      (x) =>
        x.eventDateTimes &&
        x.eventDateTimes.length > 0 &&
        +momentCst(x.eventDateTimes[0].eventDate).format('yyyyMMDD') >
          +momentCst(rightNow).format('yyyyMMDD')
    );

    const datesInThePast = events
      .filter(
        (x) =>
          x.eventDateTimes &&
          x.eventDateTimes.length > 0 &&
          +momentCst(cloneDeep(x.eventDateTimes).reverse()[0].eventDate).format(
            'yyyyMMDD'
          ) < +momentCst(rightNow).format('yyyyMMDD')
      )
      .reverse();

    return {
      noEventDates,
      datesInThePresent,
      datesInTheFuture,
      datesInThePast,
    };
  }, [fetchEvents.value]);

  return (
    <StyledPageContainer
      css={styles}
      title="My Events"
      subtitle="All events that you have taken or have registered to take in the future."
    >
      <AsyncStateContainer {...fetchEvents}>
        {fetchEvents.value && eventsSorted && (
          <>
            {eventsSorted.noEventDates.length > 0 && (
              <div className="event-section">
                <div className="event-section-header">
                  <Typography variant="heading1">
                    Events with no dates associated with it...
                  </Typography>
                </div>
                <div className="event-section-body">
                  <EventsCardGroup
                    eventsToDisplay={eventsSorted.noEventDates}
                  />
                </div>
              </div>
            )}
            {eventsSorted.datesInThePresent.length > 0 && (
              <div className="event-section">
                <div className="event-section-header">
                  <Typography variant="heading1">Today's Events</Typography>
                </div>
                <div className="event-section-body">
                  <EventsCardGroup
                    eventsToDisplay={eventsSorted.datesInThePresent}
                  />
                </div>
              </div>
            )}
            {eventsSorted.datesInTheFuture.length > 0 && (
              <div className="event-section">
                <div className="event-section-header">
                  <Typography variant="heading1">Upcoming Events</Typography>
                </div>
                <div className="event-section-body">
                  <EventsCardGroup
                    eventsToDisplay={eventsSorted.datesInTheFuture}
                  />
                </div>
              </div>
            )}
            {eventsSorted.datesInThePast.length > 0 && (
              <div className="event-section">
                <div className="event-section-header">
                  <Typography variant="heading1">Past Events</Typography>
                </div>
                <div className="event-section-body">
                  <EventsCardGroup
                    eventsToDisplay={eventsSorted.datesInThePast}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </AsyncStateContainer>
    </StyledPageContainer>
  );
};

const eventDateTimeSorter = (a, b) => {
  return momentCst(a.eventDate).unix() - momentCst(b.eventDate).unix();
};

type EventListItemWithIsOnWaitlist = IspEventListItemDto & {
  isOnWaitlist: boolean;
};

type EventsCardGroupProps = {
  eventsToDisplay: EventListItemWithIsOnWaitlist[];
};

const EventsCardGroup: React.FC<EventsCardGroupProps> = ({eventsToDisplay}) => {
  const history = useHistory();

  return (
    <>
      {!eventsToDisplay || eventsToDisplay.length <= 0 ? (
        <div className="no-events-message-container">No events to display</div>
      ) : (
        <Card.Group>
          {eventsToDisplay?.map((event) => {
            let seatsRemaining: string | number | undefined;

            if (event.capacity === undefined || event.capacity === null) {
              seatsRemaining = 'UNLIMITED';
            } else if (
              event.capacity - (event.numberOfStudentsInClass ?? 0) >
              0
            ) {
              seatsRemaining =
                event.capacity - (event.numberOfStudentsInClass ?? 0);
            } else {
              seatsRemaining = 'FULL';
            }

            const classDatesSorted =
              event.eventDateTimes?.sort(eventDateTimeSorter) ?? [];

            const classDatesSortedReversed = cloneDeep(
              classDatesSorted
            ).reverse();

            const firstClassDate =
              classDatesSorted.length > 0
                ? classDatesSorted[0].eventDate
                : null;
            const lastClassDate =
              classDatesSortedReversed.length > 0
                ? classDatesSortedReversed[0].eventDate
                : null;

            const shortenString = (s: string) => {
              if (s === null) {
                return s;
              }

              const descriptionLength = 475;
              const shortDescriptionParts = (s || '')
                .slice(0, descriptionLength)
                .split(' ');

              if (s.length > descriptionLength) {
                shortDescriptionParts.pop();
              }

              let shortDescription = shortDescriptionParts.join(' ');

              if (s.length > descriptionLength) {
                shortDescription = shortDescription.trim() + '...';
              }

              return shortDescription;
            };

            const shortDescription = shortenString(event.description ?? '');

            return (
              <Card fluid>
                <Card.Content
                  header={
                    <Flex.Row justifyContent="space-between" vAlign="center">
                      <Flex.Box>
                        <div>
                          <Typography variant="heading2">
                            {event.eventType}
                          </Typography>
                          {event.isOnWaitlist && (
                            <span className="on-waitlist-container">
                              <Label color="red">
                                You are waitlisted for this event
                              </Label>
                            </span>
                          )}
                        </div>
                        <div>
                          <Typography variant="body1">
                            {event.classType}
                          </Typography>
                        </div>
                      </Flex.Box>
                      <Flex.Box>
                        <StyledButton
                          onClick={() =>
                            history.push(
                              buildPath(routes.eventDetails, {
                                id: event.id,
                              }),
                              {
                                returnRoute:
                                  routes.customer.customerEventListing,
                              }
                            )
                          }
                        >
                          View Details
                        </StyledButton>
                      </Flex.Box>
                    </Flex.Row>
                  }
                />
                <Card.Content>
                  <Grid columns={2}>
                    <Grid.Row verticalAlign={'middle'}>
                      <Grid.Column mobile={5} computer={3}>
                        <strong>Class Date(s)</strong>
                      </Grid.Column>
                      <Grid.Column>
                        {firstClassDate && lastClassDate
                          ? startToEndDateString({
                              beginDate: firstClassDate,
                              endDate: lastClassDate,
                            })
                          : 'No dates found for this event...'}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign={'middle'}>
                      <Grid.Column mobile={5} computer={3}>
                        <strong>Seats Remaining</strong>
                      </Grid.Column>
                      <Grid.Column>{seatsRemaining}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign={'middle'}>
                      <Grid.Column mobile={5} computer={3}>
                        <strong>Parish</strong>
                      </Grid.Column>
                      <Grid.Column>{event.countyCode}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign={'middle'}>
                      <Grid.Column mobile={5} computer={3}>
                        <strong>Location</strong>
                      </Grid.Column>
                      <Grid.Column>
                        <div>{event.location}</div>
                        <div>{event.address?.fullAddress}</div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign={'middle'}>
                      <Grid.Column
                        className="short-description-container"
                        computer={15}
                      >
                        <div>
                          <strong>Description</strong>
                        </div>
                        {shortDescription}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      )}
    </>
  );
};

const styles = css`
  .event-section {
    margin-bottom: 35px;
  }

  .event-section-header {
    margin-bottom: 10px !important;
  }

  .event-section-body {
    padding-left: 25px;
  }

  .on-waitlist-container {
    margin-left: 10px;
  }
`;
