import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {Flex} from '../components/flex';
import {css} from '@emotion/core';

type InfoProps = {
  message: string;
};

export const Info = ({message}: InfoProps) => {
  return (
    <div css={styles}>
      <Flex.Row className="info-container">
        <FontAwesomeIcon icon={faInfoCircle} size="lg" className="info-icon" />
        <p>{message}</p>
      </Flex.Row>
    </div>
  );
};

const styles = css`
  .info-container {
    margin: 1.25rem 0rem 1.25rem 0rem;
  }
  .info-icon {
    margin: 0rem 1rem 0rem 1rem;
  }
`;
