import {adminRoutes} from '../admin/routes/config';
import {
  customerRoutes,
  resourceRoutes,
} from '../internet-sales-portal/routes/config';
import {mobileRoutes} from '../mobile/routes/config';
import {vendorRoutes} from '../vendor-portal/routes/config';

type pathParams = {
  [key: string]: string | number;
};

export const routes = {
  salesLanding: `/`,
  confirmEmail: `/confirm-account`,
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  changeExpiredPassword: '/change-expired-password',
  events: `/events`,
  admin: adminRoutes,
  customer: customerRoutes,
  vendor: vendorRoutes,
  eventDetails: '/events/:id',
  changeHistory: adminRoutes.events.changeHistory,
  oneTimeLoginLandingPageDashboard: '/dashboard/:token',
  oneTimeLoginLandingPageTagging: '/t2t/:token',
  oneTimeLoginLandingPageEmailProductCatalog:
    '/auto-renew-licenses-and-permits/:token',
  oneTimeLoginLandingPageActiveLicenses: '/active-licenses-and-permits/:token',
  oneTimeLoginLandingPageLotteries: '/al/:token/:lotteryCustomerApplicationId',
  oneTimeLoginLandingPageProductCatalogTags:
    '/mobile/licenses-and-permits/:id/:token',
  oneTimeLoginLandingPageProductCatalog: '/mobile/licenses-and-permits/:token',
  oneTimeLoginLandingPageAutoRenewProducts: '/auto-renew-products/:token',
  oneTimeLoginLandingPageAutoRenewPaymentMethods:
    '/auto-renew-payment-methods/:token',
  boatRenewal: {
    boatRenewalLookup: '/boat-registration',
    boatRegistrationConfirmation: '/boat-registration/confirm',
    boatRegistrationPaymentConfirmation:
      '/boat-registration/payment-confirmation',
    boatRegistrationReceipt: '/boat-registration/payment-receipt',
  },
  commercialLicenseRenewal: {
    commercialLicenseLookup: '/commercial-license',
    commercialLicenseListing: '/commercial-license/license-listing',
    commercialLicensePaymentConfirmation:
      '/commercial-license/payment-confirmation',
    commercialLicensePaymentReceipt: '/commercial-license/payment-receipt',
  },
  resources: resourceRoutes,
  mobile: mobileRoutes,
};

export const buildPath = (path: string, params: pathParams) => {
  return Object.keys(params).reduce((url, key) => {
    return url.replace(`:${key}`, `${params[key]}`);
  }, path);
};
