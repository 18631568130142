import palette from './palette';

type ButtonSizes = {
  height: string;
  width?: string;
  borderRadius?: string;
  padding?: string;
  fontSize: string;
  fontWeight: string;
  lineHeight: string;
};

export const buttonSizes: {
  xs: ButtonSizes;
  sm: ButtonSizes;
  md: ButtonSizes;
  lg: ButtonSizes;
} = {
  xs: {
    height: '25px',
    width: '25px',
    borderRadius: '5px',
    padding: '6px',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '14.06px',
  },
  sm: {
    height: '30px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14.06px',
  },
  md: {
    height: '39px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '19.32px',
  },
  lg: {
    height: '42px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '22.08px',
  },
};

type ButtonTypeProps = {
  buttonColor: string;
  buttonTextColor: string;
  buttonHoverColor: string;
  buttonHoverTextColor: string;
};

export const buttonTypes: {
  primary: ButtonTypeProps;
  secondary: ButtonTypeProps;
} = {
  primary: {
    buttonColor: palette.blue500,
    buttonTextColor: palette.white1,
    buttonHoverColor: palette.blue300,
    buttonHoverTextColor: palette.white1,
  },
  secondary: {
    buttonColor: palette.grey50,
    buttonTextColor: palette.grey700,
    buttonHoverColor: palette.grey200,
    buttonHoverTextColor: palette.grey900,
  },
};

export const buttons = {
  buttonSizes,
  buttonTypes,
};
