import React from 'react';
import css from '@emotion/css/macro';
import {useAsync} from 'react-use';

// Components
import {Typography} from '../../styled-components/typography';

// Network Services
import {PreferencePointsService} from '../../api/generated';

// Helpers
import {getPreferencePointsCount} from './lottery-helpers';
import {notifications} from '../../utils/notification-service';

// Constants
import {Theme} from '../../theme';
import {Flex} from '../../components/flex';

type Application = {
  preferencePointType?: {id: number};
  awardAccepted?: boolean;
  isAward?: boolean;
  lotteryId?: number;
  status?: string;
};

export const PreferencePointsSection: React.FC<{
  application?: Application;
}> = ({application}) => {
  const preferencePoints = useAsync(async () => {
    const lotteries = await PreferencePointsService.getBeforeAndAfterByType({
      lotteryId: application?.lotteryId,
      preferencePointTypeId: application?.preferencePointType?.id,
    });

    if (lotteries.hasErrors) {
      notifications.error(
        'There was an error getting customer preference points'
      );
      return;
    }

    return lotteries.result;
  }, [application]);

  if (!application) {
    return null;
  }

  return (
    <section css={styles}>
      {application.awardAccepted === false ||
      !application.isAward ||
      !['unaccepted', 'unsuccessful', 'accepted'].includes(
        application.status?.toLowerCase() ?? ''
      ) ? (
        <Flex.Row>
          <Typography
            variant="small"
            color={Theme.palette.grey700}
            fontWeight={'500'}
          >
            Preference Points:
          </Typography>
          {/* This is due to not being able to add a text space between these two components  */}
          <div className="spacer"></div>
          <Typography variant="small" color={Theme.palette.grey700}>
            {`${
              application.status?.toLowerCase() === 'submitted'
                ? getPreferencePointsCount(preferencePoints.value?.before ?? 0)
                : getPreferencePointsCount(preferencePoints.value?.after ?? 0)
            }`}
          </Typography>
        </Flex.Row>
      ) : (
        <div className="before-and-after">
          <Typography
            variant="small"
            color={Theme.palette.grey700}
            fontWeight={'500'}
          >
            Preference Points
          </Typography>

          <div>
            <Typography variant="small" color={Theme.palette.grey700}>
              {`Before: ${
                preferencePoints.value?.before === 0
                  ? 'N/A'
                  : preferencePoints.value?.before
              } `}
            </Typography>

            <Typography variant="small" color={Theme.palette.grey700}>
              {`After: ${
                preferencePoints.value?.after === 0
                  ? 'N/A'
                  : preferencePoints.value?.after
              } `}
            </Typography>
          </div>
        </div>
      )}
    </section>
  );
};

const styles = css`
  .before-and-after {
    display: flex;
    flex-direction: column;
  }

  .spacer {
    width: 2px;
  }
`;
