import React from 'react';
import {Divider, Modal, ModalProps} from 'semantic-ui-react';
import {Typography} from '../../styled-components/typography';
import {Link} from 'react-router-dom';
import {routes} from '../../routes/config';
import {customerRoutes} from '../../internet-sales-portal/routes/config';
import {css} from '@emotion/core';

type TextToTagModalProps = ModalProps;

export const textToTagRedirect = {
  pathname: customerRoutes.dashboard,
  state: {enrollInTextToTag: true},
};

export const TextToTagModal: React.FC<TextToTagModalProps> = ({
  ...modalProps
}) => {
  return (
    <Modal css={textToTagModalStyles} closeIcon {...modalProps}>
      <Modal.Header icon>E-tag/Text to Tag</Modal.Header>
      <Modal.Content>
        <div>
          <Typography variant="heading2">
            E-tag and Text to Tag are now options for reporting your harvests.
            Using either of these methods to tag the carcass BEFORE moving it
            means you DO NOT have to attach a physical tag.
          </Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="heading3">
            E-tag does not require enrollment and can be used through a mobile
            device that has access to the internet.
          </Typography>
        </div>
        <div className="text-to-tag-modal-content-row">
          <Typography variant="heading3">
            To E-Tag your carcass{' '}
            <Link className="link" to={routes.customer.validateTags}>
              Click Here
            </Link>
            .
          </Typography>
        </div>
        <Divider />
        <div>
          <div>
            <Typography variant="heading3">
              Text to Tag allows tag validation in areas where cell service is
              available but there is limited or no access to the internet. You
              MUST be enrolled in Text to Tag to use this free service.
            </Typography>
          </div>
          <div className="text-to-tag-modal-content-row">
            <Typography variant="heading3">
              If you want to enroll and you already have tags{' '}
              <Link className="link" to={textToTagRedirect}>
                Click Here
              </Link>
              .
            </Typography>
          </div>
          <div className="text-to-tag-modal-content-row">
            <Typography variant="heading3">
              If you need to get your tags, you can enroll at checkout by{' '}
              <Link className="link" to={customerRoutes.licensesAndPermits}>
                Clicking Here
              </Link>
              .
            </Typography>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

const textToTagModalStyles = css`
  .text-to-tag-modal-content-row {
    margin-top: 0.5em;
  }

  .link {
    text-decoration: underline !important;
  }
`;
