import React from 'react';

type Percentage = {
  value: number;
  locale?: 'en-us';
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  style?: 'percent';
};

const Percentage: React.FC<Percentage> = (props) => (
  <>{formatPercentage(props)}</>
);

export const formatPercentage = ({
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  locale = 'en-us',
  style = 'percent',
}: Percentage) => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    style,
  } as Intl.NumberFormatOptions);
  return formatter.format(value);
};

export default Percentage;
