import {css} from '@emotion/core';
import moment from 'moment';
import React, {useMemo, useState} from 'react';
import {FormSpy} from 'react-final-form';
import {useAsync, useAsyncFn, useLocation} from 'react-use';
import {Divider, Modal} from 'semantic-ui-react';
import {Overwrite} from 'utility-types';
import {
  AnyObject,
  BooleanApiResult,
  CustomerDetailDto,
  CustomerDetailDtoApiResult,
  CustomerOptionsService,
  IspCreateCustomerCommand,
  IspCustomersService,
  VendorCustomerService,
  VendorVerifyCustomerService,
} from '../../../api/generated';
import {
  CountryCode,
  EthnicCode,
  SocialSecurityNumberStatus,
  StateCode,
  SuffixCode,
} from '../../../api/generated/enums';
import {
  getEnumDropdownOptions,
  getOptionDtoDropdownOptions,
} from '../../../api/generated/utils';
import {StyledAddressForm} from '../../../components/address-form';
import {AsyncStateContainer} from '../../../components/async-state-container';
import {Flex} from '../../../components/flex';
import {Form} from '../../../forms';
import {
  AddressProps,
  customerGetAddressDto,
} from '../../../forms/address-fields';
import {
  checkbox,
  datepicker,
  dropdown,
  fieldConfig,
  getDefaults,
  input,
  masked,
  radiogroup,
  RawFieldConfig,
} from '../../../forms/schema-utils';
import {dateValueConverter} from '../../../forms/value-converters';
import {StyledButton} from '../../../styled-components/styled-buttons';
import {Typography} from '../../../styled-components/typography';
import {StyledPageContainer} from '../../../styled-page-container';
import {Theme} from '../../../theme';
import {useCopyAddress} from '../../../utils/address-helpers';
import {populateDropdownWithNumberRange} from '../../../utils/dropdown-helpers';
import {CustomerVerificationStepper} from '../customer-verification-stepper/verification-stepper';
import {customerUpdateFormValueMapper} from './customer-create-update-utils';
import {CustomerUpdateResidencyValidation} from './customer-update-residency-validation';
import {useBooleanState} from '../../../hooks/use-boolean-state';
import {removeUnknownKeyFromSubmitErrorsMutator} from '../customer-verification-stepper/remove-unknown-key-from-submit-errors-mutator';

type FieldConfigDto = Omit<
  Overwrite<IspCreateCustomerCommand, AddressProps>,
  | 'id'
  | 'passportCountryCode'
  | 'driversLicenseStateCode'
  | 'statusCodeId'
  | 'legalConfirmation'
  | 'licenseEligibilityCodes'
  | 'fullName'
  | 'pchpClassType'
  | 'hasBirthCertificate'
  | 'isHipCertificationCompleted'
  | 'receiveSmsUpdates'
  | 'textToTagSmsNumber'
  | 'textToTagStatus'
  | 'klaviyoProfileId'
>;
const DlInputPropsDisabled = {
  readOnly: true,
};

const DlInputProps = {};

const SSNInputPropsDisabled = {
  disabled: true,
  options: {
    blocks: [3, 2, 4],
    delimiter: '-',
  },
};

const SSNInputProps = {
  autoComplete: 'off',
  disabled: false,
  options: {
    blocks: [3, 2, 4],
    delimiter: '-',
  },
};

export const legalConfirmationFieldConfig = {
  fieldLabel: ' ',
  fieldName: 'legalConfirmation',
  fieldRequired: true,
  inputProps: {
    label: 'I hereby declare that all information provided is true and correct',
  },
};

export const residentYesNoOptions = {
  Yes: 'Yes',
  No: 'No',
} as AnyObject;

const GenderCode = {
  MALE: 'Male',
  FEMALE: 'Female',
} as const;

type Locations = 'vendor' | 'isp' | 'admin';

const useFields = (location: Locations) => {
  const fetchFields = useAsync(async () => {
    const {result} = await CustomerOptionsService.getCustomerOptions();

    return fieldConfig<FieldConfigDto>({
      sportsmanId: input({
        fieldLabel: 'Customer Id',
        inputProps: {
          readOnly: true,
        },
      }),
      createdTimestamp: datepicker({
        fieldLabel: 'Date Created',
        inputProps: {
          disabled: true,
          placeholder: '--',
        },
      }),
      closedTimestamp: datepicker({
        fieldLabel: 'Date Closed',
        inputProps: {
          disabled: true,
          placeholder: '--',
        },
      }),
      firstName: input({
        fieldLabel: 'First Name',
        fieldRequired: true,
      }),
      middleName: input({
        fieldLabel: 'Middle Name',
      }),
      lastName: input({
        fieldLabel: 'Last Name',
        fieldRequired: true,
      }),
      suffixCode: dropdown({
        fieldLabel: 'Suffix',
        inputProps: {
          options: getEnumDropdownOptions(SuffixCode),
          placeholder: 'Select a Suffix...',
          selection: true,
          clearable: true,
          search: true,
        },
      }),
      dateOfBirth: masked({
        fieldLabel: 'Birth Date',
        fieldRequired: true,
        inputProps: {
          placeholder: 'MM/DD/YYYY',
          options: {
            numericOnly: true,
            blocks: [2, 2, 4],
            delimiter: '/',
          },
        },
      }),
      genderCode: dropdown({
        fieldLabel: 'Gender',
        fieldRequired: true,
        inputProps: {
          options: getEnumDropdownOptions(GenderCode),
          placeholder: 'Select a Gender...',
          selection: true,
          search: true,
        },
      }),
      socialSecurityNumberStatusCode: checkbox({
        defaultValue: false,
        inputProps: {
          label: 'I do not have a Social Security Number',
        },
      }),
      socialSecurityNumber: masked({
        fieldLabel: 'SSN',
        fieldRequired: true,
        inputProps: {
          autoComplete: 'off',
          options: {
            blocks: [3, 2, 4],
            delimiter: '-',
          },
        },
      }),
      driversLicenseNumber: input({
        fieldLabel: 'DL or ID Card Number',
        inputProps: {},
      }),
      passportNumber: input({
        fieldLabel: 'Alternate ID #',
        inputProps: {
          placeholder: 'Non-US Citizens Only',
        },
      }),
      militaryIdNumber: input({
        fieldLabel: 'Military ID',
        inputProps: {},
      }),
      hairColorCodeId: dropdown({
        fieldLabel: 'Hair Color',
        inputProps: {
          options: getOptionDtoDropdownOptions(result?.hairColorCodes),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select a Hair Color...',
        },
      }),
      eyeColorCodeId: dropdown({
        fieldLabel: 'Eye Color',
        inputProps: {
          options: getOptionDtoDropdownOptions(result?.eyeColorCodes),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select an Eye Color...',
        },
      }),
      weight: input({
        fieldLabel: 'Weight',
        inputProps: {
          type: 'number',
        },
      }),
      ethnicCode: dropdown({
        fieldLabel: 'Ethnicity',
        inputProps: {
          search: true,
          options: getEnumDropdownOptions(EthnicCode),
          placeholder: 'Select an Ethnicity...',
          selection: true,
          clearable: true,
        },
      }),
      heightFeet: dropdown({
        fieldLabel: 'Height (ft)',
        inputProps: {
          options: populateDropdownWithNumberRange(3, 7),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select Feet...',
        },
      }),
      heightInches: dropdown({
        fieldLabel: 'Height (in)',
        inputProps: {
          options: populateDropdownWithNumberRange(0, 11),
          selection: true,
          clearable: true,
          search: true,
          placeholder: 'Select Inches...',
        },
      }),
      phoneNumberPrimary: masked({
        fieldLabel: 'Primary Number',
        fieldRequired: true,
        inputProps: {
          options: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          },
        },
      }),
      phoneNumberSecondary: masked({
        fieldLabel: 'Secondary Number',
        inputProps: {
          options: {
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          },
        },
      }),
      emailAddress: input({
        fieldLabel: 'Email',
        fieldRequired: location === 'isp',
      }),
      residencyCode: radiogroup({
        fieldLabel: 'Are you a Louisiana Resident?',
        fieldRequired: true,
      }),
      physicalAddress: await customerGetAddressDto(),
      mailingAddress: await customerGetAddressDto(),
    });
  }, [location]);

  return fetchFields;
};

type CustomerCreateType = {
  onSubmit: (values) => Promise<CustomerDetailDtoApiResult | undefined>;
  onCancel: () => void;
};

const mutator = {removeUnknownKeyFromSubmitErrorsMutator};
const subscriptionDefaults = {values: false};
export const CustomerCreate: React.FC<CustomerCreateType> = (config) => {
  const {pathname} = useLocation();
  const routeLocation = pathname?.includes('vendor')
    ? 'vendor'
    : pathname?.includes('admin')
    ? 'admin'
    : 'isp';
  const fields = useFields(routeLocation);

  const defaultValues = useMemo(() => {
    if (fields.value) {
      const defaults = getDefaults(fields.value);
      defaults.residencyCode = undefined;
      return defaults;
    }
  }, [fields.value]);

  const [onSubmitState, onSubmit] = useAsyncFn(
    async (values) => await config.onSubmit(values),
    [config]
  );

  return (
    <StyledPageContainer
      title="Customer Details"
      subtitle="Create a new Customer"
    >
      <AsyncStateContainer {...fields}>
        <Form
          initialValues={defaultValues}
          subscription={subscriptionDefaults}
          onSubmit={onSubmit}
          render={() => (
            <>
              {fields.value && (
                <>
                  <FormFields fields={fields.value} />
                  <Divider />
                  <div className="form-actions">
                    <StyledButton
                      type="submit"
                      primary
                      loading={onSubmitState.loading}
                    >
                      Create Customer
                    </StyledButton>
                    <StyledButton onClick={config.onCancel}>
                      Cancel
                    </StyledButton>
                  </div>
                </>
              )}
            </>
          )}
        />
      </AsyncStateContainer>
    </StyledPageContainer>
  );
};

type CustomerUpdateType = {
  stepper?: boolean;
  customerId: Number;
  onCancel: (customer?: CustomerDetailDto) => void;
  onSubmit: (
    values
  ) => Promise<BooleanApiResult | CustomerDetailDtoApiResult | undefined>;
  location?: Locations;
};

export const CustomerUpdate: React.FC<CustomerUpdateType> = (config) => {
  const location = useLocation();
  const routeLocation = location.pathname?.includes('vendor')
    ? 'vendor'
    : location.pathname?.includes('admin')
    ? 'admin'
    : 'isp';
  const isVendor = routeLocation === 'vendor';

  const fetchCustomer = useAsync(async () => {
    const {result} = isVendor
      ? await VendorVerifyCustomerService.getById({id: +config.customerId})
      : await IspCustomersService.getById();

    return result;
  }, [config.customerId, isVendor]);

  const customer = fetchCustomer.value;
  const fields = useFields(routeLocation);
  const {
    state: isUnknownKeyRemovedFromSubmitError,
    setTrue: setIsUnknownKeyRemovedFromSubmitErrorToTrue,
    setFalse: setIsUnknownKeyRemovedFromSubmitErrorToFalse,
  } = useBooleanState(false);

  if (customer?.physicalAddress && customer.physicalAddress.plus4) {
    customer.physicalAddress.zipCode = `${customer?.physicalAddress.zipCode}-${customer.physicalAddress.plus4}`;
  }

  if (customer?.mailingAddress && customer.mailingAddress.plus4) {
    customer.mailingAddress.zipCode = `${customer?.mailingAddress.zipCode}-${customer.mailingAddress.plus4}`;
  }

  const fetchState = {
    loading: fetchCustomer.loading || fields.loading,
    error: fetchCustomer.error || fields.error,
  };

  const [onSubmitState, onSubmit] = useAsyncFn(
    async (values) => {
      setIsUnknownKeyRemovedFromSubmitErrorToFalse();
      return await config.onSubmit(values);
    },
    [config, setIsUnknownKeyRemovedFromSubmitErrorToFalse]
  );

  const [
    isResidencyValidationModalOpen,
    setIsResidencyValidationModalOpen,
  ] = useState<boolean>(false);

  const [onValidateUpdateState, onValidateUpdate] = useAsyncFn(
    async (values) => {
      const valuesToSubmit = customerUpdateFormValueMapper(values);

      let response: BooleanApiResult;
      if (isVendor) {
        response = await VendorCustomerService.validateUpdateWithResidencyVerification(
          {body: valuesToSubmit}
        );
      } else {
        response = await IspCustomersService.validateUpdateWithResidencyVerification(
          {body: valuesToSubmit}
        );
      }

      if (response.hasErrors) {
        return response;
      }

      setIsResidencyValidationModalOpen(true);
    },
    [isVendor]
  );

  const closeResidencyValidationModal = () => {
    setIsResidencyValidationModalOpen(false);
  };

  return (
    <StyledPageContainer
      title="Customer Details"
      subtitle="Complete or confirm the details below. Call (225) 765-2887 to update information that cannot be edited."
    >
      <AsyncStateContainer {...fetchState}>
        <Form
          initialValues={customer}
          subscription={subscriptionDefaults}
          mutators={mutator}
          onSubmit={config.stepper ? onSubmit : onValidateUpdate}
          render={() => (
            <>
              {fields.value && (
                <>
                  {config.stepper ? (
                    <CustomerVerificationStepper
                      onSubmitState={onSubmitState}
                      isEdit={true}
                      isUnknownKeyRemovedFromSubmitError={
                        isUnknownKeyRemovedFromSubmitError
                      }
                      setIsUnknownKeyRemovedFromSubmitErrorToTrue={
                        setIsUnknownKeyRemovedFromSubmitErrorToTrue
                      }
                    />
                  ) : (
                    <>
                      <FormFields
                        fields={fields.value}
                        isEdit={true}
                        isVendor={isVendor}
                        sportsmanId={customer?.sportsmanId}
                        initialValues={customer}
                        isResidencyValidationModalOpen={
                          isResidencyValidationModalOpen
                        }
                        closeResidencyValidationModal={
                          closeResidencyValidationModal
                        }
                      />
                      <div className="form-actions">
                        <StyledButton
                          type="submit"
                          primary
                          padded
                          loading={onValidateUpdateState.loading}
                        >
                          Save
                        </StyledButton>
                        <StyledButton onClick={() => config.onCancel(customer)}>
                          Cancel
                        </StyledButton>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        />
      </AsyncStateContainer>
    </StyledPageContainer>
  );
};

type FormFieldType = {
  fields: RawFieldConfig<FieldConfigDto>;
  isEdit?: boolean;
  isVendor?: boolean;
  sportsmanId?: string;
  initialValues?: CustomerDetailDto;
  isResidencyValidationModalOpen?: boolean;
  closeResidencyValidationModal?: () => void;
};

const FormFields: React.FC<FormFieldType> = ({
  fields,
  sportsmanId,
  isEdit,
  isVendor,
  initialValues,
  isResidencyValidationModalOpen,
  closeResidencyValidationModal,
}) => {
  const copyAddress = useCopyAddress('physicalAddress', 'mailingAddress');

  return (
    <div css={styles}>
      <Typography variant="heading3">
        {sportsmanId ? 'Customer ID ' : 'New Customer'}
      </Typography>
      <span>{sportsmanId?.padStart(10, '0')}</span>
      <Form.Section title="Personal Information">
        <Form.Row proportions={[]}>
          <Form.Input
            readOnly={isEdit && !!initialValues?.firstName}
            fieldConfig={fields.firstName}
          />
          <Form.Input
            readOnly={isEdit && !!initialValues?.middleName}
            fieldConfig={fields.middleName}
          />
          <Form.Input
            readOnly={isEdit && !!initialValues?.lastName}
            fieldConfig={fields.lastName}
          />
          <Form.Dropdown
            disabled={isEdit && !!initialValues?.suffixCode}
            fieldConfig={fields.suffixCode}
          />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1, 1]}>
          <FormSpy>
            {() => {
              return isEdit ? (
                <Form.Input
                  fieldConfig={fields.dateOfBirth}
                  disabled={isEdit && !!initialValues?.dateOfBirth}
                  valueConverter={dateValueConverter}
                />
              ) : (
                <Form.InputMasked
                  fieldConfig={fields.dateOfBirth}
                  disabled={isEdit && !!initialValues?.dateOfBirth}
                />
              );
            }}
          </FormSpy>
          <FormSpy>
            {({values}) => {
              if (fields.socialSecurityNumber) {
                if (
                  values?.socialSecurityNumberStatusCode === true ||
                  initialValues?.socialSecurityNumber
                ) {
                  fields.socialSecurityNumber.inputProps = SSNInputPropsDisabled;
                  fields.socialSecurityNumber.fieldRequired = false;
                } else {
                  fields.socialSecurityNumber.inputProps = SSNInputProps;
                  fields.socialSecurityNumber.fieldRequired = true;
                }
              }
              return (
                <Form.InputMasked fieldConfig={fields.socialSecurityNumber} />
              );
            }}
          </FormSpy>
          <FormSpy>
            {({values}) => {
              if (
                values.socialSecurityNumberStatusCode ===
                SocialSecurityNumberStatus['NON-EXEMPT']
              ) {
                values.socialSecurityNumberStatusCode = false;
              } else if (
                values.socialSecurityNumberStatusCode ===
                SocialSecurityNumberStatus.EXEMPT
              ) {
                values.socialSecurityNumberStatusCode = true;
              }
              return (
                <Form.Checkbox
                  disabled={values?.socialSecurityNumber}
                  fieldConfig={fields.socialSecurityNumberStatusCode}
                />
              );
            }}
          </FormSpy>
        </Form.Row>
        <Form.Row proportions={[]}>
          <FormSpy>
            {({values}) => {
              if (fields.driversLicenseNumber) {
                const isLouisianaResident = values.residencyCode === 'Yes';
                const age = moment().diff(values.dateOfBirth, 'years', false);
                const requiresLouisianaDriversLicenseNumber =
                  isLouisianaResident && age >= 16;

                fields.driversLicenseNumber.fieldRequired = requiresLouisianaDriversLicenseNumber;

                fields.driversLicenseNumber.fieldLabel = `${
                  requiresLouisianaDriversLicenseNumber ? 'Louisiana ' : ''
                } DL or ID Card Number`;

                if (
                  values.driversLicenseStateCode === undefined ||
                  values.driversLicenseStateCode === null
                ) {
                  fields.driversLicenseNumber.inputProps = DlInputPropsDisabled;
                } else {
                  fields.driversLicenseNumber.inputProps = DlInputProps;
                }

                return (
                  <>
                    {!requiresLouisianaDriversLicenseNumber && (
                      <Form.Dropdown
                        fieldName="driversLicenseStateCode"
                        fieldLabel="DL or ID Card State"
                        search={true}
                        options={getEnumDropdownOptions(StateCode)}
                        placeholder="Select a State..."
                        selection={true}
                        clearable={true}
                      />
                    )}
                    <Form.Input fieldConfig={fields.driversLicenseNumber} />
                  </>
                );
              }
            }}
          </FormSpy>
          <Form.Input fieldConfig={fields.passportNumber} />
          <FormSpy>
            {({values}) => {
              return (
                <Form.Dropdown
                  fieldRequired={
                    values?.socialSecurityNumberStatusCode === true
                  }
                  fieldName="passportCountryCode"
                  fieldLabel="Country of Origin"
                  search={true}
                  clearable={true}
                  options={getEnumDropdownOptions(CountryCode)}
                  placeholder="Select a Country..."
                  selection={true}
                />
              );
            }}
          </FormSpy>
        </Form.Row>
        {isEdit && (
          <Form.Row proportions={[1, 1, 1, 1]}>
            <Form.Input
              fieldConfig={fields.militaryIdNumber}
              disabled={!initialValues?.militaryIdNumber || isVendor}
            />
          </Form.Row>
        )}
        <Divider />
        <Form.Section title="Contact Information">
          <Form.Row proportions={[1, 1, 1, 1]}>
            <Form.Input fieldConfig={fields.emailAddress} />
            <Form.InputMasked fieldConfig={fields.phoneNumberPrimary} />
            <Form.InputMasked fieldConfig={fields.phoneNumberSecondary} />
          </Form.Row>
        </Form.Section>
        <Divider />
        <Flex.Row justifyContent="space-evenly" flexWrap="wrap">
          <Flex.Box>
            <StyledAddressForm
              sectionTitle="Physical Address"
              addressConfig={fields.physicalAddress}
              showPreviousAddress={isEdit}
            />
          </Flex.Box>
          <Flex.Box>
            <StyledAddressForm
              sectionTitle="Mailing Address"
              addressConfig={fields.mailingAddress}
              copyHandler={copyAddress}
            />
          </Flex.Box>
        </Flex.Row>
        <Divider />
        <Form.Section title="Characteristics">
          <Form.Row>
            <Form.Dropdown fieldConfig={fields.genderCode} />
            <Form.Dropdown fieldConfig={fields.hairColorCodeId} />
            <Form.Dropdown fieldConfig={fields.heightFeet} />
            <Form.Dropdown fieldConfig={fields.heightInches} />
          </Form.Row>
          <Form.Row proportions={[1, 1, 1, 1]}>
            <Form.Dropdown fieldConfig={fields.ethnicCode} />
            <Form.Dropdown fieldConfig={fields.eyeColorCodeId} />
            <Form.Input fieldConfig={fields.weight} />
          </Form.Row>
        </Form.Section>
        {isEdit && (
          <FormSpy>
            {({values}) => {
              return (
                <CustomerUpdateResidencyValidation
                  isOpen={isResidencyValidationModalOpen}
                  closeModal={closeResidencyValidationModal}
                  formValues={values}
                />
              );
            }}
          </FormSpy>
        )}
      </Form.Section>
    </div>
  );
};

export const WhoQualifiesModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      size="tiny"
      trigger={
        <button onClick={() => setOpen(!open)} type="button" className="link">
          Who Qualifies?
        </button>
      }
    >
      <Modal.Header>Who is a Louisiana Resident?</Modal.Header>
      <Modal.Content>
        <p>
          Any person who does not claim resident privileges in another state or
          country and who has actual residence and legal permanent home address
          both are in Louisiana, and have been for at least 6 months before
          applying for the permit. Owning real estate or attending Louisiana
          schools does not in itself make you legal resident.
        </p>
        <p>
          Effective August 1, 2019, a Louisiana Resident who was honorably
          discharged from the armed forces of the United States or a reserve
          component of the armed forces of the United States, including the
          National Guard, for the purposes of purchasing a fishing/hunting
          license will be considered a bona fide resident of Louisiana once
          he/she possess a Louisiana driver's license, or, if not licensed to
          drive is in possession of a special identification card issued by the
          Department of Public Safety and Corrections under the provisions of
          R.S. 40:1321.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton primary onClick={() => setOpen(!open)}>
          Close
        </StyledButton>
      </Modal.Actions>
    </Modal>
  );
};

const styles = css`
  .identifying-information-section {
    display: flex;
    flex-direction: row;

    .louisiana-resident-section {
      flex: 1;

      button.link {
        background: none;
        border: none;
        outline: none;
        padding-left: 1.5rem;
        font-family: arial, sans-serif; // TODO: make this point to the Theme.fonts
        color: #069;
        cursor: pointer;
      }
    }

    .id-info-fields {
      flex: 3;
      display: flex;
      flex-direction: column;

      .no-ssn-checkbox {
        &.ui.checkbox {
          padding-top: 34px;
        }
      }
    }
  }

  .address-section {
    display: flex;
    flex-direction: row;

    .physical-address-section {
      flex: 1;
    }

    .mailing-address-section {
      flex: 1;

      .ui.medium.header {
        margin-bottom: 10px;
      }

      .ui.button {
        margin-left: 1.25rem;
        margin-left: 1.25rem;
        color: ${Theme.palette.grey700};
        font-family: Hind Madurai;
        font-weight: 600;
      }
    }
  }

  .license-section {
    width: 900px;
  }
`;
