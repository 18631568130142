import css from '@emotion/css/macro';
import {
  faCartPlus,
  faLock,
  faMinus,
  faMinusCircle,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {faCaretRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _, {orderBy} from 'lodash';
import {darken} from 'polished';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {FormSpy, useFormState} from 'react-final-form';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn, useAsyncRetry, useMedia} from 'react-use';
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Input,
  List,
  Message,
  Modal,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import {
  BooleanApiResult,
  CatalogProductDto,
  CustomerCatalogOptionsDtoApiResult,
  CustomerCatalogService,
  CustomerInformationDto,
  CustomerPortalCustomersService,
  FulfillmentType,
  PreviouslyPurchasedProductsDto,
  ProductRuleDto,
  ProductRuleTypeCode,
  ProductShoppingCartItemDto,
  ShoppingCartProductDto,
  ValidationError,
  VendorCatalogService,
} from '../../api/generated';
import {DateFormat, DateTimeFormats} from '../../components/date';
import {Flex} from '../../components/flex';
import {RevocationBannerContent} from '../../components/revocation-banner-content';
import {Form} from '../../forms';
import {
  RawFieldConfig,
  datepicker,
  dropdown,
  fieldConfig,
} from '../../forms/schema-utils';
import {Producer} from '../../hooks/use-produce';
import {CustomerResidencyValidation} from '../../internet-sales-portal/customers/customer-residency-validation';
import {resourceRoutes} from '../../internet-sales-portal/routes/config';
import {routes} from '../../routes/config';
import {
  LoadingIcon,
  StyledButton,
} from '../../styled-components/styled-buttons';
import {Typography} from '../../styled-components/typography';
import {StyledPageContainer} from '../../styled-page-container';
import {Media, MediaSizes} from '../../styles/breakpoints';
import {Theme} from '../../theme';
import {getIsResidencyVerified} from '../../utils/auth-helpers';
import {getAvailableDates, getYears} from '../../utils/date-helpers';
import {cstNow, momentCst} from '../../utils/date-time-helpers';
import {notifications} from '../../utils/notification-service';
import {buildIdRoute} from '../../utils/route-helpers';
import {quantityReadonlyProductCodes} from './product-catalog-constants';
import {
  CatalogType,
  INITIAL_STATE,
  ModalInfo,
  ProductCatalogState,
  ProductConstantCode,
  addMilitaryIdNumber,
  addProduct,
  catalogRoutes,
  clearCart,
  getParentProductsInCartByRule,
  getPreviouslyOwnedProduct,
  getProductsByCodes,
  getSelectedDonation,
  getUpsoldProductsNotIncluded,
  hasMaxThisSeason,
  hasMilitaryIdNumber,
  isMailingAddressConfirmationNeeded,
  isHipSurveyNeeded,
  isIncludedByInCart,
  isPrereqInCart,
  isProductOwned,
  productHasQuantity,
  removeProduct,
  removeProductFromCustomerBackendCart,
  removeProductFromVendorBackendCart,
  updateCustomerCart,
  updateVendorCart,
  useProductCatalogState,
} from './product-catalog-utils';
import {WritableDraft} from 'immer/dist/types/types-external';

const huntingLicenseHelpRoute = {
  pathname: resourceRoutes.huntingLicenseHelp,
};

export type ProductCatalogContextState = {
  state: ProductCatalogState;
  setState: (mutationFn: Producer<ProductCatalogState>) => void;
};

export type CatalogRouteState = {
  catalogState: ProductCatalogState;
};

export const revocationTypes = {
  citation: {typeName: 'CITATION', phoneNumber: '(225)-765-2887'},
  unpaidTaxes: {typeName: 'UNPAID TAXES', phoneNumber: '(225)-219-7448'},
  childSupport: {typeName: 'CHILD SUPPORT', phoneNumber: ' 1-800-256-4650'},
};

export const ProductCatalogContext = createContext<ProductCatalogContextState>({
  state: INITIAL_STATE,
  setState: () => {},
});

export type CustomerCatalogResult = CustomerCatalogOptionsDtoApiResult & {
  shoppingCart?: ShoppingCartProductDto[];
};

export type ProductCatalog = {
  catalogType: CatalogType;
  prevCatalogState: ProductCatalogState;
};

export const ProductCatalog: React.FC<ProductCatalog> = ({
  catalogType,
  prevCatalogState,
}) => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  let clonedCatalogState = _.cloneDeep(prevCatalogState);
  if (!!id) {
    clonedCatalogState.currentDisplayMenu = id;
  }

  const {context, productsToDisplay} = useProductCatalogState({
    prevCatalogState: clonedCatalogState,
  });

  const {state, setState} = context;
  const {currentDisplayMenu, displayMenuOptions, customer} = state;

  const isResidencyVerified = getIsResidencyVerified();

  const fetchCustomerRevocations = useAsync(async () => {
    const revocationCount = await CustomerPortalCustomersService.getRevocationCount();

    if (revocationCount.hasErrors) {
      return;
    }
    return revocationCount.result;
  }, []);

  const activeRevocations = fetchCustomerRevocations.value;

  const hasMilitaryId = useAsyncRetry(async () => {
    if (state.catalogType && state.customer) {
      return await hasMilitaryIdNumber[state.catalogType]({
        customerId: state.customer.id,
      });
    }
    return;
  }, [state.catalogType, state.customer]);

  const changeDisplay = (value) => {
    value &&
      setState((draft) => {
        draft.currentDisplayMenu = +value;
      });
  };

  const revocationBannerContent = (
    <RevocationBannerContent
      activeRevocations={activeRevocations}
    ></RevocationBannerContent>
  );

  const isAdmin = state.catalogType === CatalogType.admin;
  const isVendor =
    state.catalogType === CatalogType.vendor ||
    state.catalogType === CatalogType.hqVendor;

  const fetchCustomerShoppingCart = useAsyncRetry(async () => {
    let cart;
    if (state.catalogType) {
      if (isAdmin || isVendor) {
        cart = await VendorCatalogService.getShoppingCart({
          customerId: customer?.id,
        });
        if (customer !== null) {
          updateVendorCart(setState, customer.id);
        }
      } else {
        cart = await CustomerCatalogService.getShoppingCart();
        updateCustomerCart(setState);
      }
    }

    if (cart.hasErrors) {
      return;
    }

    return cart.result;
  }, [isAdmin, isVendor, customer, state.catalogType, setState]);

  const shoppingCart = fetchCustomerShoppingCart.value?.products;

  const donationsInCart = fetchCustomerShoppingCart.value?.donations;

  const total = fetchCustomerShoppingCart.value?.total;

  useEffect(() => {
    setState((draft) => {
      draft.catalogType = catalogType;
    });
  }, [setState, catalogType, displayMenuOptions]);

  return (
    <>
      <Dimmer.Dimmable blurring dimmed={!isResidencyVerified}>
        <CustomerResidencyValidation
          customerId={state.customer?.id}
          catalogType={state.catalogType ?? CatalogType.customer}
        />
        {activeRevocations && activeRevocations?.length > 0 ? (
          <Message
            error
            size="huge"
            header="Restricted"
            content={revocationBannerContent}
          />
        ) : (
          <ProductCatalogContext.Provider value={context}>
            <StyledPageContainer
              fluid
              title="Choose Product(s)"
              subtitle="Navigate the sections below to select the permit or license
                      you want to purchase today."
            >
              <main css={styles}>
                <div className="body-container">
                  <section className="filter-section">
                    {currentDisplayMenu !== null &&
                    displayMenuOptions.length > 0 ? (
                      <>
                        <Dropdown
                          className="filter-dropdown"
                          selection
                          fluid
                          options={displayMenuOptions}
                          value={currentDisplayMenu}
                          onChange={(event, {value}) => changeDisplay(value)}
                        />

                        <div className="filter-list">
                          {displayMenuOptions.map((x, index) => (
                            <span
                              key={index}
                              className={`${
                                state.currentDisplayMenu === x.value
                                  ? 'current-menu'
                                  : ''
                              }`}
                              onClick={() => changeDisplay(x.value)}
                            >
                              {x.text}
                            </span>
                          ))}
                        </div>
                        <Divider hidden />
                        <div className="license-365-info">
                          <p>
                            Need more information on hunting licenses, permits,
                            and tags?
                          </p>
                          <Link
                            replace
                            to={huntingLicenseHelpRoute}
                            target="_blank"
                          >
                            Click Here
                          </Link>
                        </div>
                      </>
                    ) : null}
                  </section>
                  <section className="product-listing-section">
                    <ProductRows
                      productsToDisplay={productsToDisplay}
                      hasMilitaryId={hasMilitaryId.value?.result}
                      fetchCustomerShoppingCart={fetchCustomerShoppingCart}
                      shoppingCart={shoppingCart}
                      donationsInCart={donationsInCart}
                    />
                  </section>
                  <section className="shopping-cart-section">
                    {state.customer && state.catalogType && (
                      <CustomerName
                        customer={state.customer}
                        catalogType={state.catalogType}
                      />
                    )}
                    <ShoppingCart
                      fetchCustomerShoppingCart={fetchCustomerShoppingCart}
                      shoppingCart={shoppingCart}
                      total={total}
                      donationsInCart={donationsInCart}
                    />
                  </section>
                </div>
              </main>
            </StyledPageContainer>
            <EarlyRenewalConfirmationModal
              shoppingCart={shoppingCart}
              fetchCustomerShoppingCart={fetchCustomerShoppingCart}
            />
            <ProductImageQuantityModal
              shoppingCart={shoppingCart}
              fetchCustomerShoppingCart={fetchCustomerShoppingCart}
            />
            <ProductModal
              refetchMilitaryId={() => hasMilitaryId.retry()}
              fetchCustomerShoppingCart={fetchCustomerShoppingCart}
              shoppingCart={shoppingCart}
              donationsInCart={donationsInCart}
            />
          </ProductCatalogContext.Provider>
        )}
      </Dimmer.Dimmable>
    </>
  );
};

const CustomerName: React.FC<{
  customer: CustomerInformationDto;
  catalogType: CatalogType;
}> = ({customer, catalogType}) => {
  const history = useHistory();

  const isCustomer = catalogType === CatalogType.customer;
  const isAdmin = catalogType === CatalogType.admin;
  const isVendor =
    catalogType === CatalogType.vendor || catalogType === CatalogType.hqVendor;

  const adminRoute = buildIdRoute(
    routes.admin.customers.dashboard,
    customer.id
  );

  const vendorRoute = () => {
    history.push(routes.vendor.root, {catalogState: {customer}});
  };

  const fullName = `${customer.firstName} ${customer.lastName}`;
  const CustomerLink = () => (
    <>
      {`LDWF# `}
      {isAdmin && <a href={adminRoute}>{customer.sportsmanId}</a>}
      {isVendor && <span onClick={vendorRoute}>{customer.sportsmanId}</span>}
      {isCustomer && `${customer.sportsmanId}`}
    </>
  );

  return (
    <Typography className="customer-name" variant="heading3">
      <List horizontal divided>
        <List.Item>
          <Flex.Box>
            <CustomerLink />
          </Flex.Box>
        </List.Item>
        <List.Item>
          <Flex.Box>{fullName}</Flex.Box>
        </List.Item>
      </List>
    </Typography>
  );
};

type ProductRows = {
  productsToDisplay: CatalogProductDto[] | undefined;
  hasMilitaryId?: boolean;
  fetchCustomerShoppingCart;
  shoppingCart: ProductShoppingCartItemDto[] | undefined;
  donationsInCart: ProductShoppingCartItemDto[] | undefined;
};

export const ProductRows: React.FC<ProductRows> = ({
  productsToDisplay,
  hasMilitaryId,
  fetchCustomerShoppingCart,
  shoppingCart,
  donationsInCart,
}) => {
  const {state, setState} = useContext(ProductCatalogContext);

  const {ownedProducts, catalogType} = state;

  const isSelected = (product: CatalogProductDto) => {
    if (!shoppingCart) {
      return false;
    }

    if (product.isDuplicateLifetimeProduct) {
      return !!shoppingCart.find(
        (x) =>
          x.replacedTransactionCustomerProductId ===
          product.replacedTransactionCustomerProductId
      );
    }

    return (
      !!shoppingCart.find((x) => x?.product?.code === product.code) ||
      !!donationsInCart?.find((x) => x?.product?.code === product.code)
    );
  };

  const orderedProductsToDisplay = orderBy(
    productsToDisplay,
    [
      (x) => isProductOwned(ownedProducts, x.code),
      (x) => x.orderBy,
      (x) => x.code,
    ],
    ['desc', 'asc', 'asc']
  );

  return (
    <>
      {orderedProductsToDisplay &&
        orderedProductsToDisplay.map((product, index) => {
          const prereqProducts = getParentProductsInCartByRule(
            ProductRuleTypeCode.Allows,
            product,
            state,
            shoppingCart
          );
          const excludedByProducts = getParentProductsInCartByRule(
            ProductRuleTypeCode.Excludes,
            product,
            state,
            shoppingCart
          );

          const isOwned = isProductOwned(ownedProducts, product.code);
          const needsMilitaryId = product.isMilitary && !hasMilitaryId;
          const isNative = product.isNative;
          const isQuantity = productHasQuantity(product);
          const isTagProduct = product.hasTags;
          const isCatalogDonation =
            product.productTypeCodeType === 'CATALOG DONATION';
          const {hasMax, amountThisSeason} = hasMaxThisSeason(
            ownedProducts,
            product
          );
          const isEligibleForEarlyRenewal = product.isEligibleForEarlyRenewal;
          const needsImageSelection =
            product.productImages && product.productImages?.length > 0;

          const hasPrereqs = prereqProducts && prereqProducts.length > 0;
          const hasExclusions =
            excludedByProducts && excludedByProducts.length > 0;

          return (
            <div key={index}>
              {hasPrereqs ||
              hasExclusions ||
              (isOwned && hasMax && !isEligibleForEarlyRenewal) ? (
                <Popup
                  flowing
                  position="top left"
                  content={
                    <>
                      {hasExclusions &&
                        !(isOwned && hasMax && !isEligibleForEarlyRenewal) && (
                          <>
                            <b>Excluded by</b>
                            {excludedByProducts?.map((x) => (
                              <div key={x.code}>{`(${x.code}) ${x.name}`}</div>
                            ))}
                          </>
                        )}
                      {hasPrereqs &&
                        !(isOwned && hasMax && !isEligibleForEarlyRenewal) && (
                          <>
                            <b>Requires at least one</b>
                            {prereqProducts?.map((x) => (
                              <div key={x.code}>{`(${x.code}) ${x.name}`}</div>
                            ))}
                          </>
                        )}
                      {isOwned &&
                        hasMax &&
                        !isEligibleForEarlyRenewal &&
                        catalogType === CatalogType.customer &&
                        !isTagProduct && (
                          <div>You already own this product</div>
                        )}
                      {isOwned &&
                        hasMax &&
                        !isEligibleForEarlyRenewal &&
                        catalogType === CatalogType.customer &&
                        isTagProduct && (
                          <div>
                            You already own this product. Tags only need to be
                            purchased once per season.
                          </div>
                        )}
                      {isOwned &&
                        hasMax &&
                        !isEligibleForEarlyRenewal &&
                        (catalogType === CatalogType.vendor ||
                          catalogType === CatalogType.hqVendor ||
                          catalogType === CatalogType.admin) && (
                          <div>Customer already owns this product</div>
                        )}
                    </>
                  }
                  trigger={
                    <div
                      className={`product-item ${
                        hasPrereqs || hasExclusions ? 'disabled' : ''
                      } ${isSelected(product) || isOwned ? 'active' : ''}`}
                    >
                      <ProductRowContent
                        product={product}
                        hasMax={hasMax}
                        amountThisSeason={amountThisSeason}
                        hasPrereqs={hasPrereqs}
                        hasExclusions={hasExclusions}
                        isSelected={isSelected(product)}
                        isOwned={isOwned}
                        isEligibleForEarlyRenewal={isEligibleForEarlyRenewal}
                      />
                    </div>
                  }
                />
              ) : (
                <div
                  className={`product-item ${
                    hasPrereqs || hasExclusions ? 'disabled' : ''
                  } ${isSelected(product) || isOwned ? 'active' : ''}`}
                  onClick={async () => {
                    if (isSelected(product)) {
                      await removeProduct(
                        product,
                        state,
                        setState,
                        shoppingCart
                      );
                    } else {
                      if (isEligibleForEarlyRenewal) {
                        setState((draft) => {
                          const productToAdd: ShoppingCartProductDto = {
                            ...product,
                            startDates: [],
                            serialNumbers: [],
                            productImageIds: [],
                            quantity: 1,
                          };

                          draft.earlyRenewalConfirmationModalOpen = true;
                          draft.modalInfo.selectedProduct = productToAdd;
                          draft.modalInfo.needsMilitaryId = needsMilitaryId;
                          draft.modalInfo.isSerialized = product.isSerialized;
                          draft.modalInfo.isAmount = isCatalogDonation;
                          draft.modalInfo.isNative = isNative;
                          draft.modalInfo.isQuantity =
                            isQuantity ||
                            (product.isStartDateSelectable ?? false);
                        });
                      } else if (needsImageSelection) {
                        setState((draft) => {
                          const productToAdd: ShoppingCartProductDto = {
                            ...product,
                            startDates: [],
                            serialNumbers: [],
                            productImageIds: [],
                            quantity: 0,
                          };

                          draft.imageQuantityModalOpen = true;
                          draft.modalInfo.selectedProduct = productToAdd;
                          draft.modalInfo.needsMilitaryId = needsMilitaryId;
                          draft.modalInfo.isSerialized = product.isSerialized;
                          draft.modalInfo.isAmount = isCatalogDonation;
                          draft.modalInfo.isNative = isNative;
                          draft.modalInfo.isQuantity = false;
                        });
                      } else if (
                        needsMilitaryId ||
                        isQuantity ||
                        isCatalogDonation ||
                        isNative ||
                        product.isSerialized ||
                        product.isStartDateSelectable
                      ) {
                        setState((draft) => {
                          const productToAdd: ShoppingCartProductDto = {
                            ...product,
                            startDates: [],
                            serialNumbers: [],
                            productImageIds: [],
                            quantity: 1,
                          };

                          draft.modalInfo.isOpen = true;
                          draft.modalInfo.selectedProduct = productToAdd;
                          draft.modalInfo.needsMilitaryId = needsMilitaryId;
                          draft.modalInfo.isSerialized = product.isSerialized;
                          draft.modalInfo.isAmount = isCatalogDonation;
                          draft.modalInfo.isNative = isNative;
                          draft.modalInfo.isQuantity =
                            isQuantity ||
                            (product.isStartDateSelectable ?? false);
                        });
                      } else {
                        await addProduct(
                          product,
                          state,
                          setState,
                          shoppingCart
                        );
                      }
                    }
                    fetchCustomerShoppingCart.retry();
                  }}
                >
                  <ProductRowContent
                    product={product}
                    hasMax={hasMax}
                    amountThisSeason={amountThisSeason}
                    hasPrereqs={hasPrereqs}
                    hasExclusions={hasExclusions}
                    isSelected={isSelected(product)}
                    isOwned={isOwned}
                    isEligibleForEarlyRenewal={isEligibleForEarlyRenewal}
                  />
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};

type ProductRowContent = {
  product: CatalogProductDto;
  hasPrereqs: boolean | undefined;
  hasExclusions: boolean | undefined;
  isSelected: boolean;
  hasMax: boolean;
  amountThisSeason: number;
  isOwned: boolean;
  isEligibleForEarlyRenewal: boolean;
};

const ProductRowContent: React.FC<ProductRowContent> = ({
  product,
  hasPrereqs,
  hasExclusions,
  isSelected,
  hasMax,
  isOwned,
  isEligibleForEarlyRenewal,
}) => {
  return (
    <>
      <section className="product-status-icon-section">
        {!(isOwned && hasMax && !isEligibleForEarlyRenewal) && (
          <FontAwesomeIcon
            className={`product-status-icon ${
              hasPrereqs || hasExclusions ? 'disabled' : ''
            }`}
            icon={
              isSelected
                ? faMinusCircle
                : hasPrereqs || hasExclusions
                ? faLock
                : faCartPlus
            }
          />
        )}
      </section>

      <section className="product-description-section">
        <span className="product-name">{`(${product.code}) ${product.name}`}</span>
        <span className="product-extra-details">{product.tagLine}</span>
      </section>

      <section className="product-cost-section">
        <span>${product.productFeeTotal.toFixed(2)}</span>
      </section>
    </>
  );
};

export const ShoppingCart: React.FC<{
  fetchCustomerShoppingCart;
  shoppingCart: ProductShoppingCartItemDto[] | undefined;
  total: number;
  donationsInCart: ProductShoppingCartItemDto[] | undefined;
}> = ({fetchCustomerShoppingCart, shoppingCart, total, donationsInCart}) => {
  const {state, setState} = useContext(ProductCatalogContext);
  const {catalogType, ownedProducts, products} = state;

  const history = useHistory();
  const {
    mailingAddressConfirmationRoutes,
    hipSurveyRoutes,
    purchaseConfirmationRoutes,
    licensesAndPermitsRoutes,
  } = catalogRoutes;
  const widthMin = useMedia(`(${MediaSizes.WideMin})`);

  const pushRouteAndScrollToTop = (route, catalogType, state, history) => {
    history.push(route[catalogType], {
      catalogState: state,
    });
    window.scrollTo(0, 0);
  };

  const onSubmit = async () => {
    const hipProduct = products.find((x) => x.code === ProductConstantCode.hip);
    const isHipInCart = shoppingCart?.find(
      (x) => x.product?.code === ProductConstantCode.hip
    );

    if (catalogType) {
      const mailingAddressConfirmationNeeded = isMailingAddressConfirmationNeeded(
        shoppingCart
      );

      const hipSurveyNeeded = isHipSurveyNeeded(
        shoppingCart,
        ownedProducts,
        state.customer,
        hipProduct
      );

      if (hipSurveyNeeded || mailingAddressConfirmationNeeded) {
        if (catalogType === CatalogType.vendor && isHipInCart && hipProduct) {
          await removeProduct(hipProduct, state, setState, shoppingCart);
          fetchCustomerShoppingCart.retry();
        }

        if (mailingAddressConfirmationNeeded) {
          pushRouteAndScrollToTop(
            mailingAddressConfirmationRoutes,
            catalogType,
            state,
            history
          );
        } else {
          const route =
            catalogType === CatalogType.vendor
              ? licensesAndPermitsRoutes
              : hipSurveyRoutes;
          pushRouteAndScrollToTop(route, catalogType, state, history);

          if (catalogType === CatalogType.vendor) {
            pushRouteAndScrollToTop(
              purchaseConfirmationRoutes,
              catalogType,
              state,
              history
            );
          }
        }
      } else {
        pushRouteAndScrollToTop(
          licensesAndPermitsRoutes,
          catalogType,
          state,
          history
        );
        pushRouteAndScrollToTop(
          purchaseConfirmationRoutes,
          catalogType,
          state,
          history
        );
      }
    }
  };

  const fetchDonationAmounts = useAsyncRetry(async () => {
    if (state === null) {
      return;
    }
    if (state.catalogType && state.customer && donationsInCart) {
      const {result} = await getSelectedDonation[state.catalogType]({
        isReceiptView: false,
        customerId: state.customer.id,
      });
      return result;
    }
    return;
  }, [state, donationsInCart]);

  const selectedDonations = fetchDonationAmounts.value;

  const ClearCartModal: React.FC<{}> = () => {
    const [
      clearConfirmationModalOpen,
      setClearConfirmationModalOpen,
    ] = useState(false);
    return (
      <Modal
        onClose={() => setClearConfirmationModalOpen(false)}
        onOpen={() => setClearConfirmationModalOpen(true)}
        open={clearConfirmationModalOpen}
        size="tiny"
        trigger={
          <Button
            disabled={
              shoppingCart?.length === 0 &&
              state.catalogType !== CatalogType.admin &&
              state.catalogType !== CatalogType.hqVendor
            }
            secondary
            type="button"
            fluid={widthMin}
            content={'Clear'}
          />
        }
      >
        <Modal.Header>Confirm</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to clear your cart?</p>
        </Modal.Content>
        <Modal.Actions>
          <StyledButton
            primary
            type="button"
            onClick={async () => {
              if (state.catalogType && state.customer) {
                var result = await clearCart[state.catalogType]({
                  customerId: state.customer.id,
                });

                if (result.hasErrors) {
                  notifications.error('There was an issue clearing your cart');
                  return;
                }
                fetchCustomerShoppingCart.retry();
                fetchDonationAmounts.retry();
              }
            }}
            content={'Clear'}
          />
          <StyledButton
            secondary
            type="button"
            onClick={() => setClearConfirmationModalOpen(false)}
            content={'Cancel'}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  const isUpsold = (product: CatalogProductDto) => {
    let isUpsold = false;
    let upsoldProducts = getUpsoldProductsNotIncluded(state, shoppingCart);
    upsoldProducts?.forEach((x) => {
      if (x.product?.code === product.code) {
        isUpsold = true;
      }
    });
    return isUpsold;
  };

  return (
    <Table className="shopping-cart" basic="very" unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <h3 className="shopping-cart-title">Your Cart</h3>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {shoppingCart &&
          shoppingCart
            .filter(
              (item) =>
                item.product &&
                (!item.product.isIncludable ||
                  isUpsold(item.product) ||
                  !isPrereqInCart(item.product, shoppingCart) ||
                  (isPrereqInCart(item.product, shoppingCart) &&
                    !isIncludedByInCart(item.product, shoppingCart)))
            )
            .map((item) => {
              const product = item.product!;

              const isReplacementOrDuplicateLifetime =
                item.product?.code === ProductConstantCode.duplicate ||
                item.product?.code === ProductConstantCode.lifetimeDuplicate;

              let includedProducts: CatalogProductDto[] = [];
              const includedProductRules: ProductRuleDto[] =
                product?.productRules?.filter(
                  (rule) =>
                    rule.productRuleTypeCode === ProductRuleTypeCode.Includes
                ) ?? [];

              if (includedProductRules.length > 0) {
                shoppingCart
                  .map((x) => x.product)
                  .filter((x) => x?.isIncludable)
                  .forEach((productInCart) => {
                    if (!productInCart) {
                      return;
                    }
                    includedProductRules.forEach((rule) => {
                      if (productInCart.code === rule.appliedProductCode) {
                        const customerAge = state?.customer?.age;
                        const productIsHip =
                          product?.code === ProductConstantCode.hip;

                        if (!productIsHip) {
                          includedProducts.push(productInCart);
                          return;
                        }

                        if (
                          customerAge &&
                          customerAge >= productInCart.minimumAge &&
                          customerAge <= productInCart.maximumAge
                        ) {
                          includedProducts.push(productInCart);
                        }
                      }
                    });
                  });
              }

              return (
                <Table.Row key={product.id}>
                  <Table.Cell>
                    <div className="item-description-container">
                      <span className="product-name">
                        {product.name}
                        {item.quantity > 1 ? ` (x${item.quantity})` : ''}
                      </span>
                      {includedProducts?.length > 0 &&
                        includedProducts.map((includedProduct) => {
                          return (
                            <div
                              key={includedProduct.id}
                              className="included-product"
                            >
                              <FontAwesomeIcon size="sm" icon={faCaretRight} />
                              <span className="product-details">
                                {includedProduct.name}
                              </span>
                            </div>
                          );
                        })}

                      {isReplacementOrDuplicateLifetime && (
                        <div className="included-product">
                          <FontAwesomeIcon size="sm" icon={faCaretRight} />
                          <span className="product-details">
                            {
                              item.replacedTransactionCustomerProduct
                                ?.replacedProductName
                            }
                          </span>
                          {product.code ===
                            ProductConstantCode.lifetimeDuplicate && (
                            <span className="product-details">
                              {` - Duplicate`}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    <ProductAncillaryInfo item={item} />
                  </Table.Cell>

                  <Table.Cell collapsing textAlign="right" verticalAlign="top">
                    {item.donationAmount ? (
                      <span className="product-price">
                        {`$${item.donationAmount.toFixed(2)}`}
                      </span>
                    ) : (
                      <span className="product-price">
                        {`$${item.itemTotal.toFixed(2)}`}
                      </span>
                    )}
                  </Table.Cell>

                  <Table.Cell collapsing verticalAlign="top">
                    <button
                      className="remove-item-from-cart-button"
                      onClick={async () => {
                        await removeProduct(
                          product,
                          state,
                          setState,
                          shoppingCart
                        );
                        fetchCustomerShoppingCart.retry();
                      }}
                    >
                      <FontAwesomeIcon
                        className="remove-item-from-cart-icon"
                        title={'Remove item'}
                        icon={faTrash}
                      />
                    </button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        {selectedDonations &&
          selectedDonations.currentDonations
            ?.filter((donation) => !!donation.donationAmount)
            .map((donation) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <div className="item-description-container">
                      <span className="product-name">
                        {donation.donationName}
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="right" verticalAlign="top">
                    <span className="product-price">
                      {`$${donation.donationAmount.toFixed(2)}`}
                    </span>
                  </Table.Cell>
                  <Table.Cell collapsing verticalAlign="top">
                    <button
                      className="remove-item-from-cart-button"
                      onClick={async () => {
                        let result: BooleanApiResult = {
                          validationFailures: [],
                          createdEntities: [],
                          hasErrors: true,
                          result: false,
                        };

                        if (state.catalogType === CatalogType.customer) {
                          result = await removeProductFromCustomerBackendCart(
                            donation.productId,
                            setState
                          );
                        } else if (
                          (state.catalogType === CatalogType.vendor ||
                            state.catalogType === CatalogType.hqVendor ||
                            state.catalogType === CatalogType.admin) &&
                          state.customer
                        ) {
                          result = await removeProductFromVendorBackendCart(
                            donation.productId,
                            state.customer.id,
                            setState
                          );
                        }

                        fetchCustomerShoppingCart.retry();
                        fetchDonationAmounts.retry();

                        if (result.hasErrors) {
                          notifications.error(
                            'There was a problem removing that item from your cart'
                          );
                          return;
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        className="remove-item-from-cart-icon"
                        title={'Remove item'}
                        icon={faTrash}
                      />
                    </button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        {shoppingCart?.length !== 0 && total > 0 && !!state.convenienceFee && (
          <Table.Row className="convenience-fee-row">
            <Table.Cell>
              <span className="convenience-fee-title">Convenience Fee</span>
            </Table.Cell>

            <Table.Cell textAlign="right" collapsing>
              <span className="convenience-fee-price">{`$${state.convenienceFee?.toFixed(
                2
              )}`}</span>
            </Table.Cell>

            <Table.Cell></Table.Cell>
          </Table.Row>
        )}

        <Table.Row className="total-cost-row">
          <Table.Cell>
            <span className="total-cost-title">Total</span>
          </Table.Cell>

          <Table.Cell textAlign="right" collapsing>
            <span className="total-cost-price">{`$${total?.toFixed(2)}`}</span>
          </Table.Cell>

          <Table.Cell />
        </Table.Row>
        <Table.Row>
          <Table.Cell textAlign="left" colSpan="3">
            <Form
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Button
                    type="submit"
                    className="checkout-button"
                    disabled={
                      shoppingCart?.length === 0 &&
                      state.catalogType !== CatalogType.admin &&
                      state.catalogType !== CatalogType.hqVendor
                    }
                    content={'Checkout'}
                    fluid={widthMin}
                  />
                  <ClearCartModal />
                </>
              )}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const ProductAncillaryInfo: React.FC<{
  item: ProductShoppingCartItemDto;
}> = ({item}) => {
  const getImageCodesWithQuantity = () => {
    const imageCodesWithQuantity: Record<string, number> = {};

    if (
      item &&
      item.productImageIds &&
      item.product &&
      item.product.productImages
    ) {
      item.productImageIds.forEach((id) => {
        let image;
        if (item.product && item.product.productImages) {
          image = item.product.productImages.find((img) => img.id === id);
        }

        if (image) {
          imageCodesWithQuantity[image.imageCode] =
            (imageCodesWithQuantity[image.imageCode] ?? 0) + 1;
        }
      });
    }

    return imageCodesWithQuantity;
  };

  const imageCodesWithQuantity = getImageCodesWithQuantity();

  return (
    <>
      {(item?.startDates?.length ?? 0) > 0 ||
      (item?.serialNumbers?.length ?? 0) > 0 ? (
        _.times(item.quantity, (index) => {
          if (item.product?.isStartDateSelectable) {
            return (
              <div className="trips-container" key={item.id}>
                {item.startDates?.[index] ? (
                  <>
                    <small>{`Trip ${index + 1}:  `}</small>
                    <small className="muted">
                      <DateFormat date={item.startDates[index]} />
                      {' - '}
                      <DateFormat
                        date={momentCst(item.startDates[index])
                          .add(
                            (item.product.numberOfDaysAllowed ?? 0) - 1,
                            'days'
                          )
                          .toDate()}
                      />
                    </small>
                  </>
                ) : (
                  <small className="error-text">
                    {`Trip ${index + 1} date is required`}
                  </small>
                )}
              </div>
            );
          } else if (item.product?.isSerialized) {
            return (
              <div className="trips-container" key={item.id}>
                {item.serialNumbers?.[index] ? (
                  <>
                    <small>{`Serial Number ${index + 1}:  `}</small>
                    <small className="muted">{item.serialNumbers[index]}</small>
                  </>
                ) : (
                  <small className="error-text">
                    {`Trip ${index + 1} date is required`}
                  </small>
                )}
              </div>
            );
          } else {
            return <small>There was an issue getting the information.</small>;
          }
        })
      ) : (
        <>
          {(item.product?.productImages?.length || 0) > 1 &&
            Object.entries(imageCodesWithQuantity).map(
              ([imageCode, quantity]) => (
                <div className="trips-container" key={imageCode}>
                  <small className="muted">{imageCode}</small>
                  {quantity > 1 && (
                    <small className="muted">{` (X${quantity})`}</small>
                  )}
                </div>
              )
            )}
          <small className="muted">
            {`Valid through `}
            <DateFormat
              date={
                item.product?.licenseValidEndDate ??
                momentCst(cstNow()).add(1, 'years').calendar()
              }
            />
          </small>
        </>
      )}
    </>
  );
};

const EarlyRenewalConfirmationModal: React.FC<{
  shoppingCart: any;
  fetchCustomerShoppingCart: any;
}> = ({shoppingCart, fetchCustomerShoppingCart}) => {
  const {state, setState} = useContext(ProductCatalogContext);

  const [modalButtonIsLoading, setModalButtonIsLoading] = useState<boolean>(
    false
  );

  const {
    selectedProduct,
    needsMilitaryId,
    isQuantity,
    isAmount,
    isNative,
    isSerialized,
  } = state.modalInfo;

  const {earlyRenewalConfirmationModalOpen} = state;

  const previouslyOwnedProduct = getPreviouslyOwnedProduct(
    state.ownedProducts,
    selectedProduct?.code
  );

  const handleOnContinue = async () => {
    if (needsMilitaryId || isQuantity || isAmount || isNative || isSerialized) {
      setModalButtonIsLoading(true);
      setState((draft) => {
        draft.modalInfo.isOpen = true;
        draft.earlyRenewalConfirmationModalOpen = false;
      });
      setModalButtonIsLoading(false);
    } else {
      await addProduct(
        selectedProduct as CatalogProductDto,
        state,
        setState,
        shoppingCart
      );
      fetchCustomerShoppingCart.retry();

      setState((draft) => {
        draft.earlyRenewalConfirmationModalOpen = false;
      });

      setModalButtonIsLoading(false);
    }
  };

  const handleOnCancel = () => {
    setState((draft) => {
      draft.modalInfo.isOpen = false;
      draft.earlyRenewalConfirmationModalOpen = false;
    });

    setModalButtonIsLoading(false);
  };

  return (
    <Modal size="small" css={styles} open={earlyRenewalConfirmationModalOpen}>
      <Modal.Header>
        {selectedProduct && `(${selectedProduct.code}) ${selectedProduct.name}`}
      </Modal.Header>
      <Modal.Content className="product-modal-content">
        <Message warning>
          Your current license is valid until{' '}
          <strong>
            <DateFormat
              date={previouslyOwnedProduct?.licenseValidEndDate}
              format={DateTimeFormats.DateTime}
            />
          </strong>
          . If you purchase a new {selectedProduct?.name}, your existing license
          will be invalid and replaced with the new license. Click 'Continue' to
          purchase the new license with today's start date.
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton
          loading={modalButtonIsLoading}
          disabled={modalButtonIsLoading}
          type="button"
          primary
          content={'Continue'}
          onClick={async () => {
            handleOnContinue();
          }}
        />
        <StyledButton
          type="button"
          secondary
          content={'Cancel'}
          onClick={() => {
            handleOnCancel();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

const ProductModal = ({
  refetchMilitaryId,
  fetchCustomerShoppingCart,
  shoppingCart,
  donationsInCart,
}) => {
  const {state, setState} = useContext(ProductCatalogContext);
  const {
    needsMilitaryId,
    isOpen,
    isUpsell,
    isQuantity,
    isAmount,
    selectedProduct,
    isNative,
    upsoldRules,
  } = state.modalInfo;

  const isModalOpen = selectedProduct?.isEligibleForEarlyRenewal
    ? isOpen && !state.earlyRenewalConfirmationModalOpen
    : isOpen;

  const upsoldProducts = getProductsByCodes(
    upsoldRules?.map((rule) => rule.appliedProductCode ?? '') ?? [],
    state.products.filter((x) => x.isPurchasable)
  );

  const upsoldProductCodes = upsoldProducts.map((item) => item.code || '');

  const isUpsellModal =
    isUpsell && !isQuantity && !isAmount && !isNative && !needsMilitaryId;

  const [
    quantityProduct,
    setQuantityProduct,
  ] = useState<ShoppingCartProductDto>(
    selectedProduct as ShoppingCartProductDto
  );
  const [militaryIdNumber, setMilitaryIdNumber] = useState<string>('');

  useEffect(() => {
    setQuantityProduct(selectedProduct as ShoppingCartProductDto);
  }, [selectedProduct]);

  useEffect(() => {
    if (!isOpen) {
      setState((draft) => {
        draft.modalInfo.isUpsell = false;
        draft.modalInfo.isAmount = false;
        draft.modalInfo.isQuantity = false;
        draft.modalInfo.isSerialized = false;
        draft.modalInfo.needsMilitaryId = false;
        draft.modalInfo.isNative = false;
        draft.modalInfo.selectedProduct = null;
      });
    }
  }, [isOpen, setState]);

  const [validationFailures, setValidationFailures] = useState<
    ValidationError[]
  >([]);

  let validationFailureMessages = _.uniq(
    validationFailures.map((error) => error.errorMessage)
  );

  const resetModalState = () => {
    setHasSelectedBirthCertificate(null);
    setValidationFailures([]);
  };

  const setDisplayedUpsellProducts = (
    draft: WritableDraft<ProductCatalogState>
  ) => {
    const displayedUpsellProductCodes = draft.displayedUpsellProductCodes;
    const displayedUpsellProductCodesToAdd = upsoldProductCodes.filter(
      (code) => !displayedUpsellProductCodes.includes(code)
    );

    if (displayedUpsellProductCodesToAdd.length >= 1) {
      draft.displayedUpsellProductCodes = [
        ...displayedUpsellProductCodes,
        ...displayedUpsellProductCodesToAdd,
      ];
    }
  };

  const closeModal = () => {
    setState((draft) => {
      draft.modalInfo.isOpen = false;
      draft.modalInfo.isUpsell = false;
      draft.modalInfo.isAmount = false;
      draft.modalInfo.isQuantity = false;
      draft.modalInfo.isSerialized = false;
      draft.modalInfo.needsMilitaryId = false;
      draft.modalInfo.isNative = false;
      draft.modalInfo.selectedProduct = null;
      setDisplayedUpsellProducts(draft);
    });
    resetModalState();
  };

  const [handleMilitaryIdState, handleMilitaryId] = useAsyncFn(async () => {
    if (!state.catalogType || !state.customer) {
      setModalButtonIsLoading(false);
      return;
    }

    if (!militaryIdNumber.trim()) {
      addProductModalErrorMessage('Please enter your military ID number.');
      setModalButtonIsLoading(false);
      return;
    }

    const response = await addMilitaryIdNumber[state.catalogType]({
      militaryIdNumber,
      customerId: state.customer.id,
    });

    if (response.hasErrors) {
      setModalButtonIsLoading(false);
      response.validationFailures?.forEach((error) => {
        addProductModalErrorMessage(error.errorMessage ?? '');
      });
      return;
    }

    setState((draft) => {
      draft.modalInfo.needsMilitaryId = false;
      draft.modalInfo.isOpen = isQuantity || isUpsell;
      draft.modalInfo.selectedProduct = null;
    });
    resetModalState();
    setModalButtonIsLoading(false);

    if (!isQuantity && selectedProduct) {
      await addProduct(selectedProduct, state, setState, shoppingCart);
      fetchCustomerShoppingCart.retry();
    }
  }, [
    isQuantity,
    isUpsell,
    militaryIdNumber,
    selectedProduct,
    setState,
    state,
    fetchCustomerShoppingCart,
    shoppingCart,
  ]);

  const hasBirthCertificate = state.customer?.hasBirthCertificate;

  const [
    hasTripDateOverlapError,
    setHasTripDateOverlapError,
  ] = useState<boolean>(false);

  const [isTripDateInThePast, setIsTripDateInThePast] = useState<boolean>(
    false
  );

  const [
    hasSelectedBirthCertificate,
    setHasSelectedBirthCertificate,
  ] = useState<boolean | null>(null);

  const [modalButtonIsLoading, setModalButtonIsLoading] = useState<boolean>(
    false
  );

  const addProductModalErrorMessage = (errorMessage: string) => {
    setValidationFailures([
      {
        propertyName: '',
        errorMessage: errorMessage,
      },
    ]);
  };

  const handleQuantity = async () => {
    const isCatalogDonation =
      quantityProduct.productTypeCodeType === 'CATALOG DONATION';
    const catalogDonationHasAmount =
      isCatalogDonation &&
      !!quantityProduct.productFeeTotal &&
      quantityProduct.productFeeTotal >= 0.01;

    const success = isCatalogDonation
      ? catalogDonationHasAmount
        ? await addProduct(quantityProduct, state, setState, shoppingCart)
        : false
      : await addProduct(quantityProduct, state, setState, shoppingCart);

    fetchCustomerShoppingCart.retry();

    if (success) {
      setModalButtonIsLoading(false);
      resetModalState();
    } else {
      setModalButtonIsLoading(false);
      if (quantityProduct.isStartDateSelectable) {
        if (hasTripDateOverlapError) {
          addProductModalErrorMessage(
            'One or more of the provided trip dates overlap.'
          );
        } else if (isTripDateInThePast) {
          addProductModalErrorMessage(
            'One or more of the provided trip dates are in the past.'
          );
        } else {
          addProductModalErrorMessage(
            'One or more fields are invalid. Ensure all trip dates are provided and are not in the past.'
          );
        }
      } else if (quantityProduct.isSerialized) {
        addProductModalErrorMessage(
          'Serial Numbers are required and have a maximum length of 25.'
        );
      } else if (isCatalogDonation) {
        addProductModalErrorMessage('Donation amount must not be empty.');
      } else {
        addProductModalErrorMessage(
          'There was an issue purchasing the product.'
        );
      }
    }
  };

  const isRequestLoading =
    handleMilitaryIdState.loading || modalButtonIsLoading;

  return (
    <Modal
      size="small"
      css={styles}
      open={isModalOpen}
      closeIcon={true}
      onClose={() => {
        closeModal();
      }}
    >
      <Modal.Header>
        {selectedProduct && `(${selectedProduct.code}) ${selectedProduct.name}`}
      </Modal.Header>
      <Modal.Content className="product-modal-content">
        {selectedProduct &&
          isQuantity &&
          isProductOwned(state.ownedProducts, selectedProduct.code) &&
          !selectedProduct.isEligibleForEarlyRenewal && (
            <Message warning>
              You have already purchased this product. Please ensure that you
              wish to purchase an additional {selectedProduct.name} before
              continuing.
            </Message>
          )}
        {validationFailures.length > 0 && (
          <Message error list={validationFailureMessages} />
        )}
        {selectedProduct && (
          <>
            {(needsMilitaryId && (
              <MilitaryModalContent setMilitaryIdNumber={setMilitaryIdNumber} />
            )) ||
              (isAmount && (
                <AmountModalContent
                  quantityProduct={quantityProduct}
                  setQuantityProduct={setQuantityProduct}
                />
              )) ||
              (isQuantity && (
                <QuantityModalContent
                  isStartDateSelectable={
                    quantityProduct?.isStartDateSelectable ?? false
                  }
                  isSerialized={quantityProduct?.isSerialized}
                  quantity={quantityProduct?.quantity}
                  setQuantityProduct={setQuantityProduct}
                  shoppingCart={shoppingCart}
                  selectedProduct={selectedProduct}
                  setHasTripDateOverlapError={setHasTripDateOverlapError}
                  setIsTripDateInThePast={setIsTripDateInThePast}
                />
              )) ||
              (isUpsell && (
                <UpsellModalContent
                  fetchCustomerShoppingCart={fetchCustomerShoppingCart}
                  shoppingCart={shoppingCart}
                  donationsInCart={donationsInCart}
                  closeModal={closeModal}
                />
              ))}
            {isNative &&
              !hasBirthCertificate &&
              state.catalogType !== CatalogType.customer && (
                <NativeModalContent
                  setHasBirthCertificate={setHasSelectedBirthCertificate}
                />
              )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {isUpsellModal ? (
          <StyledButton
            type="button"
            primary
            onClick={closeModal}
            content={'Continue'}
          />
        ) : (
          <StyledButton
            loading={isRequestLoading}
            disabled={isRequestLoading}
            type="button"
            primary
            content={'Continue'}
            onClick={async () => {
              setModalButtonIsLoading(true);
              if (
                isNative &&
                !hasBirthCertificate &&
                state.catalogType !== CatalogType.customer
              ) {
                if (hasSelectedBirthCertificate === null) {
                  notifications.error(
                    'Please select if the customer has a valid birth certificate.'
                  );
                  setModalButtonIsLoading(false);
                  return;
                }

                if (hasSelectedBirthCertificate === false) {
                  notifications.error(
                    'Customer must have a valid birth certificate to purchase this item.'
                  );
                  setModalButtonIsLoading(false);
                  return;
                }
              }

              if (needsMilitaryId) {
                await handleMilitaryId();
                await refetchMilitaryId();
              } else if (isQuantity) {
                await handleQuantity();
              } else {
                if (isUpsell) {
                  //If it's an upsell, we don't want continue to try to add the parent product again, so we just close the modal
                  closeModal();
                  setModalButtonIsLoading(false);
                  return;
                }
                //If none of the conditions apply and the BC conditions are satisfied, just add the item to the cart
                await addProduct(
                  selectedProduct as CatalogProductDto,
                  state,
                  setState,
                  shoppingCart
                );
                setModalButtonIsLoading(false);
                fetchCustomerShoppingCart.retry();
              }
            }}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

const UpsellModalContent = ({
  fetchCustomerShoppingCart,
  shoppingCart,
  donationsInCart,
  closeModal,
}) => {
  const {state} = useContext(ProductCatalogContext);
  const {upsoldRules} = state.modalInfo;
  const {ownedProducts} = state;

  const upsoldProducts = getProductsByCodes(
    upsoldRules?.map((rule) => rule.appliedProductCode ?? '') ?? [],
    state.products.filter((x) => x.isPurchasable)
  );

  const upsoldProductsToDisplay = upsoldProducts.filter(
    (product) => !state.displayedUpsellProductCodes.includes(product.code || '')
  );

  const upsoldProductCodes = upsoldProductsToDisplay.map(
    (product) => product.code || ''
  );

  const upsoldTags = upsoldProductsToDisplay.filter((x) => x.hasTags);
  const hasOnlyTagsAsUpsell = upsoldProductsToDisplay.every((x) => x.hasTags);
  const hasTagsAsUpsell = upsoldTags.length > 0;
  const hasStateDuckstampsAsUpsell = upsoldProductsToDisplay.some((x) =>
    x.fulfillmentTypes?.some((y) => y === FulfillmentType.StateDuckstamp)
  );

  const ownsAtLeastOneUpsellTag = upsoldTags
    .map((x) => {
      return isProductOwned(ownedProducts, x.code);
    })
    .includes(true);

  const ownsAllUpsellTags = upsoldTags
    .map((x) => {
      return isProductOwned(ownedProducts, x.code);
    })
    .every((x) => x === true);

  const haveAllUpsoldProductsBeenDisplayed = upsoldProductCodes.every(
    (productCode) => state.displayedUpsellProductCodes.includes(productCode)
  );

  useEffect(() => {
    if (
      (hasOnlyTagsAsUpsell && ownsAllUpsellTags) ||
      (!hasOnlyTagsAsUpsell && haveAllUpsoldProductsBeenDisplayed)
    ) {
      closeModal();
    }
  }, [
    haveAllUpsoldProductsBeenDisplayed,
    hasOnlyTagsAsUpsell,
    ownsAllUpsellTags,
    closeModal,
  ]);

  return (
    <div>
      <strong>Suggested Products</strong>
      {hasStateDuckstampsAsUpsell && (
        <Message warning>
          The Louisiana State Duck Collector stamp is not a license nor a
          requirement and is strictly for collecting purposes only.
        </Message>
      )}
      {hasTagsAsUpsell && ownsAtLeastOneUpsellTag && (
        <Message warning>
          Deer and Turkey Tags only need to be purchased once per season.
        </Message>
      )}
      <section className="product-listing-section upsell-products">
        <ProductRows
          productsToDisplay={upsoldProductsToDisplay}
          fetchCustomerShoppingCart={fetchCustomerShoppingCart}
          shoppingCart={shoppingCart}
          donationsInCart={donationsInCart}
        />
      </section>
    </div>
  );
};

const AmountModalContent: React.FC<{
  quantityProduct: ShoppingCartProductDto;
  setQuantityProduct: React.Dispatch<
    React.SetStateAction<ShoppingCartProductDto>
  >;
}> = ({quantityProduct, setQuantityProduct}) => {
  return (
    <Form
      onSubmit={() => {}}
      render={() => {
        return (
          <AmountFormFields
            quantityProduct={quantityProduct}
            setQuantityProduct={setQuantityProduct}
          />
        );
      }}
    />
  );
};

const AmountFormFields: React.FC<{
  quantityProduct: ShoppingCartProductDto;
  setQuantityProduct: React.Dispatch<
    React.SetStateAction<ShoppingCartProductDto>
  >;
}> = ({quantityProduct, setQuantityProduct}) => {
  const handleChange = (e, data) => {
    const parsedInput = parseFloat(data.value);
    setQuantityProduct({
      ...quantityProduct,
      productFeeTotal: parsedInput,
    });
  };

  return (
    <Form.Section title="">
      <Form.Row>
        <Input
          label="Amount"
          type="number"
          fluid={true}
          min={0}
          onChange={handleChange}
        />
      </Form.Row>
    </Form.Section>
  );
};

type FieldConfigDto = {
  quantity: number;
};

const getQuantityFields = (
  modalInfo: ModalInfo,
  ownedProducts: PreviouslyPurchasedProductsDto[],
  quantity: number,
  isSerialized: boolean
) => {
  const maxPerSale = modalInfo.selectedProduct?.maxPerSale;
  const maxPerSeason = modalInfo.selectedProduct?.amountAllowedPerSeason;

  let quantityMax =
    maxPerSale && maxPerSeason && maxPerSale <= maxPerSeason
      ? maxPerSale
      : maxPerSeason;

  if (modalInfo.selectedProduct && quantityMax) {
    const {amountThisSeason} = hasMaxThisSeason(
      ownedProducts,
      modalInfo.selectedProduct
    );
    quantityMax -= amountThisSeason;
  }

  const years = getYears(1, 1);
  const availableDates = getAvailableDates(1, 1);

  const quantityOptions = _.times(quantityMax ?? 1, (x) => ({
    text: `${x + 1}`,
    value: x + 1,
  }));

  let config: any = {
    quantity: dropdown({
      fieldLabel: 'Quantity',
      fieldRequired: true,
      inputProps: {
        options: quantityOptions,
        defaultValue: 1,
        placeholder: 'Select quantity...',
        selection: true,
        search: true,
      },
    }),
  };

  if (isSerialized) {
    _.times(quantity, (index) => {
      config = {
        ...config,
        [`serialNumber${index + 1}`]: datepicker({
          fieldLabel: `Serial Number ${index + 1}`,
          fieldRequired: true,
          inputProps: {
            isOutsideRange: availableDates,
            years: years,
          },
        }),
      };
    });
  } else {
    _.times(quantity, (index) => {
      config = {
        ...config,
        [`trip${index + 1}`]: datepicker({
          fieldLabel: `Trip ${index + 1}`,
          fieldRequired: true,
          inputProps: {
            isOutsideRange: availableDates,
            years: years,
          },
        }),
      };
    });
  }

  return fieldConfig<FieldConfigDto>(config);
};

const MilitaryModalContent: React.FC<{
  setMilitaryIdNumber: React.Dispatch<React.SetStateAction<string>>;
}> = ({setMilitaryIdNumber}) => {
  return (
    <Form
      onSubmit={() => {}}
      render={() => {
        return <MilitaryFormFields setMilitaryIdNumber={setMilitaryIdNumber} />;
      }}
    />
  );
};

const MilitaryFormFields: React.FC<{
  setMilitaryIdNumber: (militaryIdNumber: string) => void;
}> = ({setMilitaryIdNumber}) => {
  const {values} = useFormState();
  useMemo(() => {
    if (values.militaryId) {
      setMilitaryIdNumber(values.militaryId);
    }
  }, [values, setMilitaryIdNumber]);

  return (
    <Form.Section title="">
      <Form.Row proportions={[2, 2]}>
        <Form.Input
          fieldName="militaryId"
          fieldLabel="Military ID Number"
          fieldRequired
        />
      </Form.Row>
    </Form.Section>
  );
};

enum nativeYesNoOptions {
  Yes = 'Yes',
  No = 'No',
}

const NativeModalContent: React.FC<{
  setHasBirthCertificate: React.Dispatch<React.SetStateAction<boolean | null>>;
}> = ({setHasBirthCertificate}) => {
  return (
    <Form
      onSubmit={() => {}}
      render={() => {
        return (
          <NativeFormFields setHasBirthCertificate={setHasBirthCertificate} />
        );
      }}
    />
  );
};

const NativeFormFields: React.FC<{
  setHasBirthCertificate: React.Dispatch<React.SetStateAction<boolean | null>>;
}> = ({setHasBirthCertificate}) => {
  const {values} = useFormState();
  useMemo(() => {
    if (values.hasBirthCertificate) {
      setHasBirthCertificate(
        values.hasBirthCertificate === nativeYesNoOptions.Yes
      );
    }
  }, [values.hasBirthCertificate, setHasBirthCertificate]);
  return (
    <Form.Section title="">
      <Segment padded compact className="birth-certificate-message">
        <div>
          <Typography variant="heading3">
            In order to purchase this type of license, the customer must have a
            valid Louisiana birth certificate. Birth certificate information
            should match DL/ID information for customers 16 or older.
          </Typography>
        </div>
      </Segment>

      <Typography variant="heading3" className="birth-certificate-question">
        Does the customer have a valid Louisiana birth certificate?
      </Typography>
      <Form.Row>
        <Form.RadioGroup
          fieldName="hasBirthCertificate"
          enum={nativeYesNoOptions}
        />
      </Form.Row>
    </Form.Section>
  );
};

type ProductImageQuantities = Record<number, number>;

const ProductImageQuantityModal: React.FC<{
  shoppingCart: any;
  fetchCustomerShoppingCart: any;
}> = ({shoppingCart, fetchCustomerShoppingCart}) => {
  const {state, setState} = useContext(ProductCatalogContext);

  const [validationFailure, setValidationFailure] = useState<ValidationError>();
  const [
    imageQuantities,
    setImageQuantities,
  ] = useState<ProductImageQuantities>({});
  const [totalImageQuantity, setTotalImageQuantity] = useState<number>(0);
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  const {selectedProduct} = state.modalInfo;
  const {imageQuantityModalOpen, ownedProducts, catalogType} = state;

  const maxPerSale = selectedProduct?.maxPerSale;
  const maxPerSeason = selectedProduct?.amountAllowedPerSeason;

  let quantityMax =
    maxPerSale && maxPerSeason && maxPerSale <= maxPerSeason
      ? maxPerSale
      : maxPerSeason;

  if (selectedProduct && quantityMax) {
    const {amountThisSeason} = hasMaxThisSeason(ownedProducts, selectedProduct);
    quantityMax -= amountThisSeason;
  }

  const fetchProductImages = useAsync(async () => {
    if (catalogType === null) {
      return;
    }

    setIsImageLoading(true);
    let productImages;

    if (catalogType === CatalogType.customer) {
      productImages = await CustomerCatalogService.getProductImages({
        productId: selectedProduct?.id,
      });
    } else {
      productImages = await VendorCatalogService.getProductImages({
        productId: selectedProduct?.id,
      });
    }

    setIsImageLoading(false);
    return productImages;
  }, [catalogType, selectedProduct]);

  const productImages = fetchProductImages.value?.result;

  const calculateTotalQuantity = (quantities: Record<number, number>) => {
    return Object.values(quantities).reduce(
      (total, quantity) => total + quantity,
      0
    );
  };

  const handleDecrease = (imageId: number) => {
    setImageQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[imageId] || 0;
      const newQuantity = Math.max(currentQuantity - 1, 0);
      const updatedQuantities = {
        ...prevQuantities,
        [imageId]: newQuantity,
      };
      setTotalImageQuantity(calculateTotalQuantity(updatedQuantities));
      return updatedQuantities;
    });
  };

  const handleIncrease = (imageId: number) => {
    setImageQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[imageId] || 0;
      const newQuantity = quantityMax
        ? Math.min(currentQuantity + 1, quantityMax)
        : currentQuantity + 1;
      const updatedQuantities = {
        ...prevQuantities,
        [imageId]: newQuantity,
      };
      setTotalImageQuantity(calculateTotalQuantity(updatedQuantities));
      return updatedQuantities;
    });
  };

  const handleOnCancel = () => {
    setState((draft) => {
      draft.imageQuantityModalOpen = false;
    });

    setValidationFailure(undefined);
    setIsAddingToCart(false);
    setImageQuantities({});
    setTotalImageQuantity(0);
  };

  const handleOnContinue = async () => {
    setIsAddingToCart(true);
    setValidationFailure(undefined);

    if (quantityMax && totalImageQuantity > quantityMax) {
      setValidationFailure({
        propertyName: '',
        errorMessage: `Please ensure that the total quantity is less than ${quantityMax}.`,
      });
      setIsAddingToCart(false);

      return;
    }

    if (selectedProduct) {
      const productToAdd = _.cloneDeep(selectedProduct);
      productToAdd.quantity = totalImageQuantity;
      productToAdd.productFeeTotal =
        selectedProduct.productFeeTotal * totalImageQuantity;

      for (const [productImageId, quantity] of Object.entries(
        imageQuantities
      )) {
        const id = parseInt(productImageId, 10);

        for (let i = 0; i < quantity; i++) {
          productToAdd.productImageIds?.push(id);
        }
      }

      await addProduct(productToAdd, state, setState, shoppingCart);

      fetchCustomerShoppingCart.retry();

      setState((draft) => {
        draft.imageQuantityModalOpen = false;
      });
      setValidationFailure(undefined);
      setIsAddingToCart(false);
      setImageQuantities({});
      setTotalImageQuantity(0);
    }
  };

  return (
    selectedProduct && (
      <Modal size="small" css={styles} open={imageQuantityModalOpen}>
        <Modal.Header>
          {`(${selectedProduct.code}) ${selectedProduct.name}`}
        </Modal.Header>
        <Modal.Content
          scrolling
          className="product-image-quantity-modal-content"
        >
          <Message warning>
            Each {selectedProduct?.name} is{' '}
            <strong>${selectedProduct.productFeeTotal.toFixed(2)}. </strong>
            {isProductOwned(state.ownedProducts, selectedProduct.code) &&
              `You have already purchased this product. Please ensure that you
              wish to purchase an additional ${selectedProduct.name} before
              continuing.`}
          </Message>
          {validationFailure && (
            <Message error content={validationFailure.errorMessage} />
          )}
          {isImageLoading ? (
            <LoadingIcon className="loading-icon" />
          ) : (
            productImages && (
              <div className={productImages.length > 1 ? 'image-grid' : ''}>
                {productImages.map((imageObject) => (
                  <div key={imageObject.id} className="image-container">
                    <>
                      <img
                        src={imageObject.signedImageUri}
                        alt={imageObject.imageCode}
                      />
                      <div>
                        <div className="image-code">
                          {imageObject.imageCode}
                        </div>
                        <div className="quantity-container">
                          <label htmlFor={`quantity${imageObject.id}`}>
                            Qty.
                            <span className="required-asterisk">* </span>
                          </label>
                          <div className="input-field-container">
                            <StyledButton
                              size="xs"
                              onClick={() => handleDecrease(imageObject.id)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </StyledButton>
                            <input
                              id={`quantity${imageObject.id}`}
                              type="number"
                              value={imageQuantities[imageObject.id] || 0}
                              min={0}
                              max={quantityMax}
                              className="input-field"
                              disabled
                            />
                            <StyledButton
                              size="xs"
                              onClick={() => handleIncrease(imageObject.id)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </StyledButton>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
              </div>
            )
          )}
        </Modal.Content>
        <Modal.Actions className="product-image-quantity-modal-action">
          <StyledButton
            loading={isAddingToCart}
            disabled={isAddingToCart || totalImageQuantity === 0}
            type="button"
            primary
            content={`Add ${totalImageQuantity} to cart`}
            onClick={async () => {
              handleOnContinue();
            }}
          />
          <StyledButton
            type="button"
            secondary
            disabled={isAddingToCart}
            content={'Cancel'}
            onClick={() => {
              handleOnCancel();
            }}
          />
        </Modal.Actions>
      </Modal>
    )
  );
};

const QuantityModalContent: React.FC<{
  quantity: number;
  isStartDateSelectable: boolean;
  isSerialized: boolean;
  setQuantityProduct: React.Dispatch<
    React.SetStateAction<ShoppingCartProductDto>
  >;
  shoppingCart: ProductShoppingCartItemDto[];
  selectedProduct: ShoppingCartProductDto | null;
  setHasTripDateOverlapError: (hasTripDateOverlapError: boolean) => void;
  setIsTripDateInThePast: (isTripDateInThePast: boolean) => void;
}> = ({
  quantity,
  setQuantityProduct,
  isStartDateSelectable,
  isSerialized,
  shoppingCart,
  selectedProduct,
  setHasTripDateOverlapError,
  setIsTripDateInThePast,
}) => {
  const {
    state: {modalInfo, ownedProducts},
  } = useContext(ProductCatalogContext);

  let fields = useMemo(() => {
    return getQuantityFields(modalInfo, ownedProducts, quantity, isSerialized);
  }, [modalInfo, ownedProducts, quantity, isSerialized]);

  const laNativeHuntFiveDayInCart =
    shoppingCart.find(
      (item: any) =>
        item.product.code === quantityReadonlyProductCodes.nativeHunt
    ) !== undefined;

  const isQuantityFormReadonly =
    (laNativeHuntFiveDayInCart &&
      selectedProduct &&
      (selectedProduct.code === quantityReadonlyProductCodes.nativeBigGame ||
        selectedProduct.code ===
          quantityReadonlyProductCodes.nativePrimitiveFirearms)) ??
    false;

  return (
    <Form
      onSubmit={() => {}}
      render={() =>
        fields && (
          <QuantityFormFields
            hasDates={isStartDateSelectable}
            isSerialized={isSerialized}
            fields={fields}
            quantity={quantity}
            setProductToAdd={setQuantityProduct}
            shoppingCart={shoppingCart}
            isQuantityFormReadonly={isQuantityFormReadonly}
            setHasTripDateOverlapError={setHasTripDateOverlapError}
            setIsTripDateInThePast={setIsTripDateInThePast}
          />
        )
      }
    />
  );
};

const QuantityFormFields: React.FC<{
  hasDates: boolean;
  isSerialized: boolean;
  fields: RawFieldConfig<FieldConfigDto>;
  quantity: number;
  setProductToAdd: (productToAdd: ShoppingCartProductDto) => void;
  shoppingCart: ProductShoppingCartItemDto[];
  isQuantityFormReadonly: boolean;
  setHasTripDateOverlapError: (hasTripDateOverlapError: boolean) => void;
  setIsTripDateInThePast: (isTripDateInThePast: boolean) => void;
}> = ({
  hasDates,
  isSerialized,
  fields,
  quantity,
  setProductToAdd,
  shoppingCart,
  isQuantityFormReadonly,
  setHasTripDateOverlapError,
  setIsTripDateInThePast,
}) => {
  const {
    state: {modalInfo},
  } = useContext(ProductCatalogContext);
  const {values} = useFormState();
  const [tripStartDates, setTripStartDates] = useState<string[]>();

  const isTripDateInThePast = (tripDate: string) => {
    const cstToday = cstNow().startOf('day');
    return cstToday.isAfter(tripDate);
  };

  useMemo(() => {
    let startDates: string[] = [];
    setHasTripDateOverlapError(false);
    setIsTripDateInThePast(false);

    let hasTripDateOverlapErrors = false;

    if (values.quantity && modalInfo.selectedProduct) {
      const productToAdd = _.cloneDeep(modalInfo.selectedProduct);

      if (isQuantityFormReadonly) {
        const laNativeHuntFiveDay = shoppingCart.find(
          (item) =>
            item.product?.code === quantityReadonlyProductCodes.nativeHunt
        );

        if (laNativeHuntFiveDay) {
          productToAdd.quantity = laNativeHuntFiveDay?.quantity;
          values.quantity = laNativeHuntFiveDay?.quantity;

          _.times(values.quantity, (index) => {
            startDates.push(laNativeHuntFiveDay.startDates?.[index] ?? '');
            values[`trip${index + 1}`] =
              laNativeHuntFiveDay.startDates?.[index];
          });
        }
      } else {
        productToAdd.quantity = values.quantity;
        if (productToAdd.isStartDateSelectable) {
          _.times(values.quantity, (index) => {
            const targetIndexTripDate = values[`trip${index + 1}`];

            if (startDates.length === 0) {
              setIsTripDateInThePast(isTripDateInThePast(targetIndexTripDate));

              startDates.push(targetIndexTripDate);
            } else {
              startDates.forEach((element) => {
                let previousRangeStartDate = new Date(element);
                let previousRangeEndDay = new Date(previousRangeStartDate);

                setIsTripDateInThePast(isTripDateInThePast(element));

                previousRangeEndDay.setDate(
                  previousRangeStartDate.getDate() +
                    ((productToAdd.numberOfDaysAllowed ?? 0) - 1)
                );

                let selectedRangeStartDay = new Date(targetIndexTripDate);

                let selectedRangeEndDay = new Date(selectedRangeStartDay);
                selectedRangeEndDay.setDate(
                  selectedRangeStartDay.getDate() +
                    ((productToAdd.numberOfDaysAllowed ?? 0) - 1)
                );

                if (
                  (previousRangeStartDate <= selectedRangeStartDay &&
                    previousRangeEndDay >= selectedRangeStartDay) ||
                  (previousRangeStartDate <= selectedRangeEndDay &&
                    previousRangeEndDay >= selectedRangeEndDay)
                ) {
                  setHasTripDateOverlapError(true);
                  hasTripDateOverlapErrors = true;
                }
              });

              if (!hasTripDateOverlapErrors) {
                startDates.push(targetIndexTripDate);
              }
            }
          });
        }
        if (productToAdd.isSerialized) {
          _.times(values.quantity, (index) => {
            const targetIndexSerialNumber = values[`serialNumber${index + 1}`];
            productToAdd?.serialNumbers?.push(targetIndexSerialNumber);
          });
        }
      }

      productToAdd.startDates = startDates;
      productToAdd.productFeeTotal =
        modalInfo.selectedProduct.productFeeTotal * (values.quantity ?? 1);
      setProductToAdd(productToAdd);
      setTripStartDates(startDates);
    }
  }, [
    values,
    modalInfo,
    setProductToAdd,
    isQuantityFormReadonly,
    shoppingCart,
    setHasTripDateOverlapError,
    setIsTripDateInThePast,
  ]);

  return (
    <Form.Section title="">
      <Form.Row proportions={[2, 2]}>
        <Form.Dropdown
          disabled={isQuantityFormReadonly}
          fieldConfig={fields.quantity}
        />
      </Form.Row>
      {isSerialized && (
        <Form.Row>
          <FormSpy>
            {() => {
              const serialNumberKeys = _.times(quantity, (x) => {
                return {prefix: 'serialNumber', num: x + 1};
              });
              return serialNumberKeys.map((trip) => {
                const index = `${trip.prefix}${trip.num}`;
                fields[index].inputProps.openDirection =
                  trip.num >= 3 ? 'up' : 'down';
                return <Form.Input fieldConfig={fields[index]} key={index} />;
              });
            }}
          </FormSpy>
        </Form.Row>
      )}
      {hasDates && (
        <Form.Row>
          <FormSpy>
            {() => {
              const tripKeys = _.times(quantity, (x) => {
                return {prefix: 'trip', num: x + 1};
              });
              return tripKeys.map((trip) => {
                const index = `${trip.prefix}${trip.num}`;
                fields[index].inputProps.openDirection = 'up';

                //If there is only one trip date selected
                //This is just a form cosmetic display, state holds this value already
                if (
                  quantity === 1 &&
                  !fields[index].inputProps.defaultValue &&
                  tripStartDates
                ) {
                  fields[index].inputProps.defaultValue = tripStartDates[0];
                }

                return (
                  <Form.DatePicker
                    disabled={isQuantityFormReadonly}
                    fieldConfig={fields[index]}
                    key={index}
                  />
                );
              });
            }}
          </FormSpy>
        </Form.Row>
      )}
    </Form.Section>
  );
};

const styles = css`
  .body-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .filter-section {
    flex: 2;
    margin: 10px;

    .license-365-info {
      padding: 10px;
      margin-left: 10px;
    }

    ${Media('MobileMax')} {
      min-width: 80%;
    }

    ${Media('DesktopMin')} {
      border-right: 1px solid ${Theme.palette.grey100};

      .message-link-margin {
        margin: 0 10px 10px 0;
      }

      margin-right: 10px;

      .filter-dropdown {
        display: none;
      }

      .filter-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .current-menu {
          background: ${Theme.palette.blue00};
        }

        span {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding: 10px 20px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: ${Theme.palette.blue700};

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    ${Media('TabletMax')} {
      .license-365-info {
        margin-bottom: 10px;
      }

      .filter-list {
        display: none;
      }
    }
  }

  .product-listing-section {
    flex: 5;
    margin-top: 10px;

    ${Media('MobileMax')} {
      min-width: 80%;
    }

    &.upsell-products {
      padding-right: 0;
    }

    .product-item {
      border: 1px solid ${Theme.palette.grey100};
      box-sizing: border-box;
      border-radius: 3px;
      margin-bottom: 4px;
      padding: 18px 14px;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        &:hover {
          cursor: default;
        }
      }

      &.active {
        background: ${Theme.palette.green00};
        border: 1px solid ${Theme.palette.green400};

        .product-status-icon {
          background: ${Theme.palette.grey100} 65%;
        }
      }

      .product-status-icon-section {
        .product-status-icon {
          padding: 9px;
          height: 40px;
          width: 40px;
          color: ${Theme.palette.green600};
          background: ${Theme.palette.green50};
          border-radius: 50%;

          &.disabled {
            color: ${Theme.palette.grey100};
            background: ${Theme.palette.grey50};
          }
        }
      }

      .product-description-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 5px;

        .product-name {
          padding-bottom: 5px;
          color: ${Theme.palette.blue800};
          font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
            Helvetica, sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
        }

        .product-extra-details {
          color: ${Theme.palette.grey700};
          font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 124%;
        }
      }

      .product-cost-section {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
      }
    }
  }

  .filter-dropdown {
    div {
      max-height: 100vh !important;
    }
  }

  .shopping-cart-section {
    flex: 3;
    margin: 10px;

    ${Media('MobileMax')} {
      margin-top: 3em;
    }

    .shopping-cart-title {
      color: ${Theme.palette.blue800};
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }

    .item-description-container {
      display: flex;
      flex-direction: column;
    }

    .product-name {
      color: ${Theme.palette.blue800};
      font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial, Helvetica,
        sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .product-details {
      color: ${Theme.palette.grey700};
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }

    .product-price {
      color: ${Theme.palette.grey700};
      font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }

    .convenience-fee-row {
      .convenience-fee-title {
        color: ${Theme.palette.blue800};
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }

      .convenience-fee-price {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
      }
    }

    .remove-item-from-cart-button {
      background: transparent;
      border: none;
    }

    .remove-item-from-cart-icon {
      color: red;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
      }
    }

    .total-cost-row {
      .total-cost-title {
        color: ${Theme.palette.blue800};
        font-family: 'Hind Madurai', 'Roboto', 'Helvetica Neue', Arial,
          Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

      .total-cost-price {
        color: ${Theme.palette.grey700};
        font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
      }
    }

    .checkout-button {
      background: ${Theme.palette.blue500};
      color: ${Theme.palette.white1};
      margin-right: 20px;
      margin-bottom: 5px;

      &:hover {
        background: ${darken(0.1, Theme.palette.blue500)};
      }
    }

    .cancel-button {
      background: ${Theme.palette.grey50};
      color: ${Theme.palette.grey700};
      margin-bottom: 5px;

      &:hover {
        background: ${darken(0.1, Theme.palette.grey50)};
      }
    }
  }

  .table-container {
    margin: 2em 0em;
    min-height: 30rem;
  }

  .ui.table.selectable {
    tbody tr {
      height: 5rem;

      .product-description {
        padding-right: 0px !important;
      }

      .product-price {
        padding-left: 0px !important;
      }
    }
  }

  .ui.table tbody tr td:first-of-type {
    padding-left: 0.5em !important;
  }

  .ui.table tbody tr td:last-of-type {
    padding-right: 0.7em !important;
  }

  .product-modal-content {
    .ui.basic.table {
      border: 0px;

      thead th {
        border: 0;
        padding-left: 15px;
      }
    }

    .ui.table.selectable {
      tbody tr {
        height: 5rem;

        .product-description {
          padding-right: 0px !important;
        }

        .product-price {
          padding-left: 0px !important;
        }
      }

      .ui.table tbody tr td:first-of-type {
        padding-left: 0.5em !important;
      }

      .ui.table tbody tr td:last-of-type {
        padding-right: 0.7em !important;
      }
    }

    ${Media('WideMin')} {
      padding: 0 25px;

      .body-container {
        flex-direction: column;
      }

      .filter-section {
        padding: 0 15px;
        margin-bottom: 16px;
        border: none;
        display: flex;
        justify-content: center;

        .filter-dropdown {
          display: block;
          max-width: 200px;
        }
      }
    }
  }

  .customer-name {
    span {
      color: #4183c4;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .birth-certificate-message {
    box-shadow: none !important;
    background-color: ${Theme.palette.warningBackground} !important;
    padding: 10px !important;
    margin: 0px 16px 15px 16px !important;
  }

  .birth-certificate-question {
    margin: 0px 16px 8px 16px;
  }

  .product-image-quantity-modal-content {
    .loading-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .image-grid {
      height: 80%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px 20px;
      justify-content: space-between;
    }

    .image-container {
      flex: 0 0 calc(50% - 20px);
      justify-content: center;
      align-items: center;
      text-align: center;

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 5px;
        height: 190px;

        ${Media('TabletMax')} {
          height: 150px;
        }

        ${Media('MobileMax')} {
          height: 110px;
        }
      }

      &:only-child {
        img {
          width: 50%;
        }
      }
    }

    .image-code {
      height: 40px;
    }

    .quantity-container {
      display: flex;
      margin: 0 0 0 10px;
      justify-content: center;
      flex-wrap: wrap;
    }

    .required-asterisk {
      color: red;
    }

    .input-field-container {
      /* Disables zoom on double tap*/
      touch-action: manipulation;
      margin: 0 5px;

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .input-field {
      width: 40px;
      color: black;
      border: 0;
      text-align: center;
      background-color: transparent;
    }
  }

  .product-image-quantity-modal-action {
    span:first-of-type {
      button:first-of-type {
        width: 150px;
      }
    }
  }
`;
