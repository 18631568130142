import React, {useCallback} from 'react';
import {analtyics} from '../analytics';
import {trackPageView} from '../analytics/appinsights';
import {LocationNotifier} from '../routes/location-notifier';

export default function AnalyticsPageTracker() {
  const onLocationChange = useCallback((location) => {
    trackPageView({name: location});
    analtyics.trackPage(location);
  }, []);
  return <LocationNotifier onChange={onLocationChange} />;
}
