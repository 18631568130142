import css from '@emotion/css/macro';
import {
  faCalendar,
  faCheck,
  faReceipt,
  faStar,
  faTag,
  faTrophy,
} from '@fortawesome/pro-solid-svg-icons';
import {faShip} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {
  Dimmer,
  Divider,
  Grid,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import {
  AutoRenewService,
  CommercialLicenseRenewalService,
  CustomerCatalogService,
  CustomerEventsService,
  CustomerHarvestsService,
  CustomerOnlineCourseService,
  CustomerOptionsService,
  CustomerPortalCustomersService,
  IspEventListItemDto,
  LotteryCustomerApplicationService,
  LotteryShoppingCartService,
  OrganDonorRegistryService,
  ResidencyCode,
  TransactionCustomerService,
} from '../api/generated';
import boatCert from '../assets/images/boat-certified.svg';
import bowCert from '../assets/images/bow-certified.svg';
import gunCert from '../assets/images/hip-certified.svg';
import wildlife from '../assets/images/wildlife-white.svg';
import {useUser} from '../auth/use-auth';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DateFormat} from '../components/date';
import {buildPath, routes} from '../routes/config';
import {ListButton, StyledButton} from '../styled-components/styled-buttons';
import {StyledDivider} from '../styled-components/styled-divider';
import {Typography} from '../styled-components/typography';
import {Theme} from '../theme';
import {useTheme} from '../theme/use-theme';
import {
  StyledDashboard,
  DashboardCard,
  DashboardCardHeader,
  DashboardCardDescription,
} from '../styled-components/styled-dashboard';
import _, {cloneDeep} from 'lodash';
import {logoutAsync} from '../utils/auth-helpers';
import {
  dateSorter,
  sortEventsByFirstStartDate,
  startToEndDateString,
} from '../utils/date-helpers';
import {ExportButton as ExportFileButton} from '../components/export-button';
import {Flex} from '../components/flex';
import {combineRegisteredAndWaitlistedEvents} from '../utils/event-helpers';
import {notifications} from '../utils/notification-service';
import {faBowArrow} from '@fortawesome/pro-duotone-svg-icons';
import {faUsers} from '@fortawesome/pro-solid-svg-icons';
import {faFilePdf, faUser} from '@fortawesome/pro-regular-svg-icons';
import {cstNow, momentCst} from '../utils/date-time-helpers';
import {saveAs} from 'file-saver';
import {Tooltip} from '../components/tooltip';
import {TextToTagModal} from './customers/dashboard/customer-text-to-tag-modal';
import {
  faAddressCard,
  faClock,
  faComputerClassic,
  faFileDownload,
} from '@fortawesome/pro-regular-svg-icons';
import {SmsPreferencesModal} from './customers/dashboard/customer-sms-preferences-modal';
import {Media} from '../styles/breakpoints';
import {StateCode} from '../api/generated/enums';
import {CertificationTypeCodes} from './events/constants';
import {ProductConstantCode} from '../shared/product-catalog/product-catalog-utils';
import moment from 'moment';
import {customerRoutes} from './routes/config';
import {OrganDonorModal} from './customers/dashboard/customer-organ-donor-modal';

type ApplicationDescriptionDto = {
  title: string;
  date: Date;
  icon: any;
};

export const CustomerDashboard = () => {
  const user = useUser();
  const history = useHistory();
  const [, setTheme] = useTheme();
  const [openTextToTagModal, setOpenTextToTagModal] = useState(false);
  const [openSmsPreferencesModal, setOpenSmsPreferencesModal] = useState(false);
  const [openOrganDonorModal, setOpenOrganDonorModal] = useState(false);
  const enrollInTextToTag = history.location.state?.enrollInTextToTag;

  useEffect(() => {
    setTheme(
      (draft) =>
        (draft.palette.mainContentBackgroundColor = Theme.palette.grey00)
    );

    window.scrollTo(0, 0);

    const resetBackground = () => {
      setTheme(
        (draft) =>
          (draft.palette.mainContentBackgroundColor =
            Theme.palette.mainContentBackgroundColor)
      );
    };

    return resetBackground;
  });

  const fetchIsAutoRenewEnabled = useAsync(async () => {
    const {result} = await AutoRenewService.getIsEnabled();
    return result;
  }, []);

  const isAutoRenewEnabled = fetchIsAutoRenewEnabled.value;

  const getRouteStateObject = (route: string, state: any) => {
    return {
      pathname: route,
      state: {...state, fromDashboard: true},
    };
  };

  const handleLogout = async () => {
    await logoutAsync();
    history.push(customerRoutes.dashboard);
  };

  const fetchCustomerInfo = useAsync(async () => {
    const fetchCustomer = async () => {
      const [
        customerCatalogResponse,
        eventsResponse,
        certificationsResponse,
      ] = await Promise.all([
        CustomerCatalogService.get(),
        CustomerEventsService.getEventsForLoggedInUser(),
        CustomerPortalCustomersService.getCertificationsForLoggedInUser(),
      ]);

      if (
        customerCatalogResponse.hasErrors ||
        eventsResponse.hasErrors ||
        certificationsResponse.hasErrors
      ) {
        notifications.error('Failure to get customer information.');
        history.push(routes.customer.root);
      }

      const catalogResult = customerCatalogResponse.result;
      const previouslyPurchasedProducts =
        catalogResult?.previouslyPurchasedProducts ?? [];
      const eventsResult = eventsResponse.result;
      const certificationsResult = certificationsResponse.result;

      const events = combineRegisteredAndWaitlistedEvents<IspEventListItemDto>(
        eventsResult?.registeredEvents,
        eventsResult?.waitlistedEvents
      ).sort(sortEventsByFirstStartDate);

      return {
        customerInfo: catalogResult?.customerInformation,
        isHipCertificationNeeded: catalogResult?.isHipCertificationNeeded,
        hipMinAge: catalogResult?.hipMinAge,
        events: events,
        certifications: certificationsResult,
        previouslyPurchasedProducts: previouslyPurchasedProducts,
      };
    };

    const fetchTags = async () => {
      const customerCatalogResponse = await CustomerHarvestsService.getAllForCustomer(
        {
          customerId: user.customerId,
        } as any
      );

      if (customerCatalogResponse.hasErrors) {
        history.push(routes.customer.root);
      }

      return customerCatalogResponse.result;
    };

    const fetchApplications = async () => {
      const applicationsResponse = await LotteryCustomerApplicationService.getDashboardItems(
        {
          customerId: user.customerId,
        } as any
      );

      if (applicationsResponse.hasErrors) {
        history.push(routes.customer.root);
      }

      return applicationsResponse.result;
    };

    const fetchOnlineCourses = async () => {
      const fetchCoursesForUserResponse = await CustomerOnlineCourseService.getOnlineCoursesForLoggedInUser();

      if (fetchCoursesForUserResponse.hasErrors) {
        fetchCoursesForUserResponse.validationFailures?.forEach((error) => {
          notifications.error(error.errorMessage);
        });
      }

      return fetchCoursesForUserResponse;
    };

    const customerAndProductInfo = await fetchCustomer();
    const tags = await fetchTags();
    const customerApplications = await fetchApplications();
    const customerOnlineCourses = await fetchOnlineCourses();
    return {
      ...customerAndProductInfo,
      tags,
      customerApplications,
      customerOnlineCourses,
    };
  }, [history, user.customerId]);

  const customerInfo = fetchCustomerInfo.value?.customerInfo;

  const previouslyPurchasedProducts =
    fetchCustomerInfo.value?.previouslyPurchasedProducts;
  const tags = _.sortBy(fetchCustomerInfo.value?.tags, [
    'wasHarvested',
    'species',
  ]);
  const showLotterySms =
    fetchCustomerInfo?.value?.customerApplications?.showLotterySms;
  const applications =
    fetchCustomerInfo?.value?.customerApplications?.lotteryApplications;
  const events = fetchCustomerInfo?.value?.events;
  const onlineCourses = fetchCustomerInfo?.value?.customerOnlineCourses;
  const certifications = fetchCustomerInfo?.value?.certifications?.filter(
    (x) => x.stateCode === StateCode.LOUISIANA
  );

  if (enrollInTextToTag && !openTextToTagModal && tags.length > 0) {
    setOpenTextToTagModal(enrollInTextToTag);
  }

  const hunterCertification = certifications?.find(
    (x) =>
      x.certificationCodeTypeCode === CertificationTypeCodes.HunterEducation
  );
  const bowCertification = certifications?.find(
    (x) =>
      x.certificationCodeTypeCode === CertificationTypeCodes.BowHunterEductation
  );
  const boatCertification = certifications?.find(
    (x) =>
      x.certificationCodeTypeCode === CertificationTypeCodes.BoaterEducation
  );

  const [
    downloadHunterCertificationState,
    downloadHunterCertification,
  ] = useAsyncFn(
    async (
      customerCertificationId: number,
      customerCertificationDescription?: string
    ) => {
      await downloadCertification(
        customerCertificationId,
        customerCertificationDescription
      );
    }
  );

  const [downloadBowCertificationState, downloadBowCertification] = useAsyncFn(
    async (
      customerCertificationId: number,
      customerCertificationDescription: string
    ) => {
      await downloadCertification(
        customerCertificationId,
        customerCertificationDescription
      );
    }
  );

  const [
    downloadBoatCertificationState,
    downloadBoatCertification,
  ] = useAsyncFn(
    async (
      customerCertificationId: number,
      customerCertificationDescription: string
    ) => {
      await downloadCertification(
        customerCertificationId,
        customerCertificationDescription
      );
    }
  );

  const [, downloadCertification] = useAsyncFn(
    async (
      customerCertificationId: number,
      customerCertificationDescription?: string
    ) => {
      try {
        let response = await CustomerEventsService.downloadCertificationPdfForLoggedInUser(
          {
            body: {
              customerCertificationId: customerCertificationId,
            },
          },
          {
            responseType: 'blob',
          }
        );

        saveAs(response, `${customerCertificationDescription} CERTIFICATION`);
      } catch {
        notifications.error(
          'Error downloading file. Please contact an admin if this issue persists.'
        );
      }
    }
  );

  const clickGetTags = async () => {
    const {result} = await CustomerOptionsService.getDisplayMenuByName({
      name: 'TAGS',
    });

    const url = buildPath(routes.customer.licensesAndPermitsSetDisplay, {
      id: result?.id ?? 0,
    });

    return url;
  };

  const acceptAward = async (id) => {
    await LotteryShoppingCartService.addAwardAcceptanceLink({
      lotteryCustomerApplicationId: id,
    });
    history.push(customerRoutes.lotteryApplications);
  };

  const navigateToCatalog = () => {
    history.push(routes.customer.licensesAndPermits);
  };

  const fetchIsCommercialLicensingEnabled = useAsync(async () => {
    const {result} = await CommercialLicenseRenewalService.getIsEnabled();
    return result;
  }, []);

  const isCommercialLicensingEnabled = fetchIsCommercialLicensingEnabled.value;

  const isCustomerOfHipAge =
    moment().diff(customerInfo?.dateOfBirth, 'years', false) >=
    (fetchCustomerInfo.value?.hipMinAge ?? 0);

  const {value: isOrganDonorRegistryEnabled} = useAsync(async () => {
    const {result} = await OrganDonorRegistryService.isEnabled();
    return result;
  }, []);

  return (
    <BasicPage title="" css={styles}>
      <AsyncStateContainer {...fetchCustomerInfo}>
        {fetchCustomerInfo.value && (
          <StyledDashboard flexWrap="wrap" className="dashboard">
            {customerInfo && (
              <DashboardCard size="md" className="wildlife-background">
                <Grid columns={2} className="customer-card-content">
                  <Grid.Row>
                    <Grid.Column mobile={12}>
                      <List horizontal>
                        <List.Item>
                          <Typography
                            variant="subheading1"
                            color={Theme.palette.grey600}
                          >
                            {`Customer ID ${customerInfo.sportsmanId}`}
                          </Typography>
                        </List.Item>

                        <List.Item>
                          <Typography
                            variant="subheading1"
                            as={Link}
                            to={''}
                            onClick={handleLogout}
                            color={Theme.palette.blue600}
                          >
                            Not you?
                          </Typography>
                        </List.Item>
                      </List>
                      <DashboardCardHeader>
                        <Typography variant="heading2">
                          {`${customerInfo.firstName} ${
                            customerInfo?.middleName ?? ''
                          } ${customerInfo.lastName} ${
                            customerInfo?.suffixCode ?? ''
                          }`}
                        </Typography>
                        <Modal
                          open={openSmsPreferencesModal}
                          onClose={() => {
                            setOpenSmsPreferencesModal(false);
                          }}
                          size="tiny"
                        >
                          <SmsPreferencesModal
                            customerId={customerInfo?.id}
                            setOpen={setOpenSmsPreferencesModal}
                          />
                        </Modal>
                      </DashboardCardHeader>

                      <DashboardCardDescription scroll>
                        {customerInfo.physicalAddress && (
                          <Typography
                            variant="body1"
                            color={Theme.palette.grey600}
                          >
                            <div>{customerInfo.physicalAddress.street1}</div>
                            {customerInfo.physicalAddress.street2 && (
                              <div>{customerInfo.physicalAddress.street2}</div>
                            )}
                            <div>
                              {`
                                ${customerInfo.physicalAddress.city}, 
                                ${customerInfo.physicalAddress.stateCode} 
                                ${customerInfo.physicalAddress.zipCode}
                                ${
                                  customerInfo.physicalAddress.plus4
                                    ? '-' + customerInfo.physicalAddress.plus4
                                    : ''
                                }`}
                            </div>
                          </Typography>
                        )}
                        <StyledDivider />
                        <Typography
                          variant="body1"
                          color={Theme.palette.grey600}
                        >
                          <div>{customerInfo.phoneNumberPrimary}</div>
                          <div>{customerInfo.emailAddress}</div>
                        </Typography>
                      </DashboardCardDescription>
                      <StyledDivider />
                      <div>
                        <StyledButton
                          onClick={() =>
                            history.push(routes.customer.customerUpdate)
                          }
                        >
                          View Account Details
                        </StyledButton>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={4}>
                      {(hunterCertification ||
                        bowCertification ||
                        boatCertification) && (
                        <Typography
                          variant="subheading1"
                          color={Theme.palette.grey600}
                          className="certification-label"
                        >
                          Certifications
                        </Typography>
                      )}
                      <Segment.Group compact className="add-padding">
                        {hunterCertification && (
                          <Tooltip label="Download Hunter Certificate">
                            <Segment
                              className="add-padding cursor-pointer"
                              basic
                              align="middle"
                              onClick={() => {
                                if (!downloadHunterCertificationState.loading) {
                                  downloadHunterCertification(
                                    hunterCertification.id,
                                    hunterCertification.certificationCodeDescription
                                  );
                                }
                              }}
                            >
                              {downloadHunterCertificationState.loading ? (
                                <>
                                  <Dimmer active>
                                    <Loader />
                                  </Dimmer>

                                  <Image spaced src={gunCert} />
                                </>
                              ) : (
                                <Image spaced src={gunCert} />
                              )}
                            </Segment>
                          </Tooltip>
                        )}
                        {bowCertification && (
                          <Tooltip label="Download Bow Certificate">
                            <Segment
                              className="add-padding cursor-pointer"
                              basic
                              align="middle"
                              onClick={() => {
                                if (!downloadBowCertificationState.loading) {
                                  downloadBowCertification(
                                    bowCertification.id,
                                    bowCertification.certificationCodeDescription ??
                                      ''
                                  );
                                }
                              }}
                            >
                              {downloadBowCertificationState.loading ? (
                                <>
                                  <Dimmer active>
                                    <Loader />
                                  </Dimmer>

                                  <Image spaced src={bowCert} />
                                </>
                              ) : (
                                <Image spaced src={bowCert} />
                              )}
                            </Segment>
                          </Tooltip>
                        )}
                        {boatCertification && (
                          <Tooltip label="Download Boater Certificate">
                            <Segment
                              className="add-padding cursor-pointer"
                              basic
                              align="middle"
                              onClick={() => {
                                if (!downloadBoatCertificationState.loading) {
                                  downloadBoatCertification(
                                    boatCertification.id,
                                    boatCertification.certificationCodeDescription ??
                                      ''
                                  );
                                }
                              }}
                            >
                              {downloadBoatCertificationState.loading ? (
                                <>
                                  <Dimmer active>
                                    <Loader />
                                  </Dimmer>

                                  <Image spaced src={boatCert} />
                                </>
                              ) : (
                                <Image spaced src={boatCert} />
                              )}
                            </Segment>
                          </Tooltip>
                        )}
                      </Segment.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {customerInfo.residencyCode === ResidencyCode.RESIDENT &&
                  isOrganDonorRegistryEnabled && (
                    <div className="customer-card-footer">
                      <StyledButton
                        floated="right"
                        className="organ-donor-apply-button"
                        primary
                        onClick={() => setOpenOrganDonorModal(true)}
                      >
                        <Icon name="heart" />
                        Become a Donor
                      </StyledButton>
                    </div>
                  )}
              </DashboardCard>
            )}
            <OrganDonorModal
              open={openOrganDonorModal}
              setOpen={setOpenOrganDonorModal}
            />
            <DashboardCard size="md">
              <DashboardCardHeader>
                <Typography variant="heading2">Licenses & Permits</Typography>
                <div className="button-container">
                  <StyledButton
                    primary
                    floated="right"
                    onClick={navigateToCatalog}
                    size="md"
                    className="mobile-button"
                  >
                    <span className="mobile-button-content">Purchase</span>
                  </StyledButton>
                  <a
                    href="https://ldwf.wlf.la.gov/rolp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledButton
                      primary
                      floated="right"
                      size="md"
                      className="mobile-button"
                    >
                      <span className="mobile-button-content">Get ROLP</span>
                    </StyledButton>
                  </a>
                </div>
                <Divider hidden />
              </DashboardCardHeader>
              {previouslyPurchasedProducts &&
              previouslyPurchasedProducts.filter(
                (product) => !product.isOnlineCourse
              ).length > 0 ? (
                <>
                  <DashboardCardDescription scroll>
                    <List celled size="massive">
                      {previouslyPurchasedProducts
                        .filter(
                          (product) =>
                            !product.isOnlineCourse &&
                            product.code !== ProductConstantCode.duplicate
                        )
                        .map((product) => {
                          return (
                            <List.Item key={product.id}>
                              <List horizontal className="list-item-inner">
                                <List.Item>
                                  <Label circular size="tiny">
                                    <FontAwesomeIcon
                                      className="fa-inverse"
                                      icon={faReceipt}
                                    />
                                  </Label>
                                </List.Item>
                                <List.Item>
                                  <List>
                                    <List.Item>
                                      <Typography
                                        variant="subheading1"
                                        color={Theme.palette.blue700}
                                      >
                                        {product.name}
                                      </Typography>
                                    </List.Item>
                                    <List.Item>
                                      <Typography variant="small">
                                        Valid through{' '}
                                        <DateFormat
                                          date={product.licenseValidEndDate}
                                        />
                                      </Typography>
                                    </List.Item>
                                  </List>
                                </List.Item>
                                <List.Item className="export-container">
                                  <ExportButton
                                    transactionId={product.transactionId}
                                  />
                                </List.Item>
                              </List>
                            </List.Item>
                          );
                        })}
                    </List>
                  </DashboardCardDescription>
                  <Typography
                    variant="small"
                    as={Link}
                    to={routes.customer.customerlicensesAndPermits}
                    color={Theme.palette.blue400}
                  >
                    View/Manage All
                  </Typography>
                </>
              ) : (
                <>
                  <DashboardCardDescription scroll>
                    <Segment
                      className="add-padding"
                      basic
                      placeholder
                      align="center"
                    >
                      <List.Item>
                        <Label circular size="massive">
                          <FontAwesomeIcon
                            className="fa-inverse"
                            icon={faReceipt}
                          />
                        </Label>
                      </List.Item>
                      <Typography variant="subheading1">
                        You currently don't have any valid licenses.
                      </Typography>
                      <Typography
                        variant="small"
                        as={Link}
                        to={routes.customer.customerlicensesAndPermits}
                        color={Theme.palette.blue400}
                      >
                        View/Manage All
                      </Typography>
                    </Segment>
                    <Typography variant="body1">
                      Recreational annual licenses purchased on or after June 1,
                      2022, will be valid for 365 days from the date of
                      purchase.
                    </Typography>
                  </DashboardCardDescription>
                </>
              )}
              {fetchCustomerInfo.value.isHipCertificationNeeded !== null &&
                fetchCustomerInfo.value.isHipCertificationNeeded &&
                (isCustomerOfHipAge ? (
                  <>
                    <StyledDivider />
                    <StyledButton
                      buttonColor={Theme.palette.errorText}
                      buttonTextColor={Theme.palette.white1}
                      buttonHoverColor={Theme.palette.warning}
                      buttonHoverTextColor={Theme.palette.white1}
                      onClick={async () => {
                        await CustomerCatalogService.addHipCertificationItem();

                        history.push(routes.customer.licensesAndPermits);
                      }}
                    >
                      Purchase HIP Certification
                    </StyledButton>
                  </>
                ) : (
                  <>
                    <StyledDivider />
                    <Message color={'grey'} variant="heading1">
                      <Typography variant="heading4">
                        HIP purchase only needed for those over the age of{' '}
                        {fetchCustomerInfo.value?.hipMinAge}.
                      </Typography>
                    </Message>
                  </>
                ))}
            </DashboardCard>

            <DashboardCard size="md">
              <DashboardCardHeader>
                <Typography variant="heading2">Tags</Typography>
                {tags.length > 0 ? (
                  <>
                    <StyledButton
                      primary
                      floated="right"
                      onClick={() => history.push(routes.customer.validateTags)}
                    >
                      Validate Tags
                    </StyledButton>
                    <ExportFileButton
                      primary
                      floated={'right'}
                      // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                      request={{customerId: user.customerId}}
                      disabled={(tags && tags.length === 0) ?? true}
                      action={CustomerHarvestsService.exportCustomerTagsPdfFile}
                      fileName={'TagsDownload.pdf'}
                      icon={faFilePdf}
                      label="Print Tags"
                    />
                  </>
                ) : (
                  <StyledButton
                    primary
                    floated="right"
                    onClick={async () => {
                      var url = await clickGetTags();
                      history.push(url);
                    }}
                  >
                    Get Tags
                  </StyledButton>
                )}
                <Modal
                  open={openTextToTagModal}
                  onClose={() => {
                    setOpenTextToTagModal(false);
                  }}
                  size="tiny"
                >
                  <TextToTagModal setOpen={setOpenTextToTagModal} />
                </Modal>
                <Divider hidden />
              </DashboardCardHeader>
              {tags && tags.length > 0 ? (
                <>
                  <DashboardCardDescription scroll>
                    <List celled size="massive">
                      {tags?.map((tag) => {
                        return (
                          <List.Item>
                            <List horizontal className="list-item-inner">
                              <List.Item>
                                <Label circular size="tiny">
                                  <FontAwesomeIcon
                                    className={
                                      !tag.wasHarvested ? 'fa-inverse' : ''
                                    }
                                    color={Theme.palette.blue800}
                                    icon={tag.wasHarvested ? faCheck : faTag}
                                  />
                                </Label>
                              </List.Item>

                              <List.Item>
                                <List>
                                  <List.Item
                                    as={Link}
                                    to={getRouteStateObject(
                                      routes.customer.validateTags,
                                      {tag}
                                    )}
                                  >
                                    <Typography
                                      variant="subheading1"
                                      color={Theme.palette.blue700}
                                    >
                                      {`Tag #${tag.tagNumber}`}
                                    </Typography>
                                  </List.Item>
                                  <List.Item>
                                    <Typography variant="small">
                                      {tag.species}
                                    </Typography>
                                  </List.Item>
                                </List>
                              </List.Item>

                              {tag.wasHarvested && (
                                <>
                                  <List.Item className="harvested-info">
                                    <List>
                                      <List.Item>
                                        <Typography
                                          textAlign="right"
                                          variant="small"
                                        >
                                          {`Reported `}
                                          <DateFormat
                                            date={
                                              tag.harvestCreatedTimestamp ?? ''
                                            }
                                          />
                                        </Typography>
                                      </List.Item>
                                      <List.Item>
                                        <Typography
                                          textAlign="right"
                                          variant="small"
                                        >
                                          #{`${tag.confirmationNumber}`}
                                        </Typography>
                                      </List.Item>
                                    </List>
                                  </List.Item>
                                </>
                              )}
                            </List>
                          </List.Item>
                        );
                      })}
                    </List>
                  </DashboardCardDescription>
                  <Divider hidden />
                  {tags && tags.length > 0 && (
                    <StyledButton
                      fluid
                      className="text-to-tag-button"
                      content={'Manage Text to Tag'}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTextToTagModal(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <DashboardCardDescription scroll>
                    <Segment
                      className="add-padding"
                      basic
                      placeholder
                      align="center"
                    >
                      <List.Item>
                        <Label circular size="massive">
                          <FontAwesomeIcon
                            icon={faTag}
                            className="fa-inverse"
                          />
                        </Label>
                      </List.Item>
                      <Typography variant="subheading1">
                        You currently don't have any valid tags.
                      </Typography>
                    </Segment>
                    <Typography variant="body1">
                      Tags, sometimes called Game Checks, are required for
                      hunting Deer or Turkey.
                    </Typography>
                  </DashboardCardDescription>
                </>
              )}
            </DashboardCard>

            <DashboardCard className="applications-container" size="md">
              <DashboardCardHeader>
                <Typography variant="heading2">Lotteries</Typography>

                <StyledButton
                  primary
                  floated="right"
                  content={'Lotteries'}
                  onClick={() =>
                    history.push(routes.customer.lotteryApplications)
                  }
                />
                {showLotterySms && (
                  <StyledButton
                    primary
                    floated="right"
                    onClick={(e) => {
                      setOpenSmsPreferencesModal(true);
                    }}
                    content={'Lottery SMS'}
                  />
                )}
                <Divider hidden />
              </DashboardCardHeader>
              {applications && applications.length > 0 ? (
                <>
                  <DashboardCardDescription scroll>
                    <List celled size="massive">
                      {applications?.map((application, index) => {
                        const description = application.isAward
                          ? application.currentLotteryApplicationStatusName?.toLowerCase() ===
                            'accepted'
                            ? {
                                title: 'Hunt Date',
                                date: application.huntDate,
                                icon: faBowArrow,
                              }
                            : application.acceptanceDate === null
                            ? {
                                title: 'Claim your award',
                                date: application.acceptanceDate,
                                icon: faTrophy,
                              }
                            : {
                                title: 'Claim by',
                                date: application.acceptanceDate,
                                icon: faTrophy,
                              }
                          : ({
                              title: 'Draw Date',
                              date: application.drawDate,
                              icon: application.isGroupApplication
                                ? faUsers
                                : faUser,
                            } as ApplicationDescriptionDto);

                        return (
                          <List.Item key={index}>
                            <List.Content>
                              <List.Item>
                                <List horizontal className="list-item-inner">
                                  <List.Item>
                                    <Label
                                      circular
                                      size="big"
                                      className={
                                        application.isAward
                                          ? 'lbl-award'
                                          : 'lbl-application'
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="fa-inverse"
                                        icon={description.icon}
                                      />
                                    </Label>
                                  </List.Item>
                                  <List.Item>
                                    <List>
                                      <List.Item>
                                        <Typography
                                          variant="subheading1"
                                          color={Theme.palette.blue700}
                                        >
                                          {(application.name?.length ?? 0) > 20
                                            ? application.name?.substring(
                                                0,
                                                20
                                              ) + '...'
                                            : application.name}
                                        </Typography>
                                      </List.Item>
                                      <List.Item>
                                        <Typography variant="small">
                                          {`${description.title} `}
                                          {description.date && (
                                            <DateFormat
                                              date={description.date}
                                            />
                                          )}
                                        </Typography>
                                      </List.Item>
                                      <List.Item>
                                        <Typography variant="small">
                                          Application Status:{' '}
                                          {
                                            application.currentLotteryApplicationStatusName
                                          }
                                        </Typography>
                                      </List.Item>
                                    </List>
                                  </List.Item>
                                  {application.currentLotteryApplicationStatusName?.toLowerCase() ===
                                    'unaccepted' && (
                                    <List.Item className="accept-button-container">
                                      <StyledButton
                                        buttonColor={Theme.palette.green600}
                                        buttonTextColor={Theme.palette.white1}
                                        size={'sm'}
                                        onClick={() => {
                                          acceptAward(application.id);
                                        }}
                                        className="accept-button"
                                      >
                                        <FontAwesomeIcon icon={faTrophy} />{' '}
                                        Accept{' '}
                                      </StyledButton>
                                    </List.Item>
                                  )}
                                </List>
                              </List.Item>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </DashboardCardDescription>
                  <Typography
                    variant="small"
                    as={Link}
                    to={routes.customer.customerLotteryApplications}
                    color={Theme.palette.blue400}
                  >
                    View All
                  </Typography>
                </>
              ) : (
                <>
                  <DashboardCardDescription scroll>
                    <Segment
                      className="add-padding"
                      basic
                      placeholder
                      align="center"
                    >
                      <List.Item>
                        <Label circular size="massive">
                          <FontAwesomeIcon
                            icon={faTrophy}
                            className="fa-inverse"
                          />
                        </Label>
                      </List.Item>
                      <Typography variant="subheading1">
                        You are not currently entered in any active lotteries.
                      </Typography>
                      <Typography
                        variant="small"
                        as={Link}
                        to={routes.customer.customerLotteryApplications}
                        color={Theme.palette.blue400}
                      >
                        View All
                      </Typography>
                    </Segment>
                    <Typography variant="body1">
                      LDWF's wildlife management areas offer limited hunts
                      available through lotteries. Hunts include several species
                      including alligator, deer, dove, turkey and waterfowl.
                    </Typography>
                  </DashboardCardDescription>
                </>
              )}
              <Divider hidden />
              <ListButton
                buttonText="View My Preference Points"
                iconColor={Theme.palette.blue400}
                icon={faStar}
                onClick={() => {
                  history.push(routes.customer.preferencePoints);
                }}
              ></ListButton>
            </DashboardCard>

            <DashboardCard size="md">
              <DashboardCardHeader>
                <Typography variant="heading2">Events & Courses</Typography>
                <StyledButton
                  primary
                  floated="right"
                  onClick={() => history.push(routes.events)}
                >
                  Find Event
                </StyledButton>
                <Divider hidden />
              </DashboardCardHeader>
              {events && events.length > 0 ? (
                <>
                  <DashboardCardDescription scroll>
                    <List celled size="massive">
                      {events.map((event, index) => {
                        const eventDates =
                          event.eventDateTimes?.map((x) => x.eventDate) ?? [];

                        const eventDatesSortedAndFiltered = eventDates
                          .sort(dateSorter)
                          .filter((x) => momentCst(x).unix() > cstNow().unix());

                        const eventDatesSortedAndFilteredReversed = cloneDeep(
                          eventDatesSortedAndFiltered
                        ).reverse();

                        const firstClassDate =
                          eventDatesSortedAndFiltered.length > 0
                            ? eventDatesSortedAndFiltered[0]
                            : null;
                        const lastClassDate =
                          eventDatesSortedAndFilteredReversed.length > 0
                            ? eventDatesSortedAndFilteredReversed[0]
                            : null;

                        return (
                          <List.Item key={index}>
                            <Flex.Row justifyContent="space-between">
                              <Flex.Box>
                                <List
                                  horizontal
                                  className="list-item-inner remove-margin-top"
                                >
                                  <List.Item>
                                    <List>
                                      <List.Item
                                        className="apply-finger-cursor"
                                        onClick={() => {
                                          history.push(
                                            buildPath(routes.eventDetails, {
                                              id: +event.id,
                                            }),
                                            {
                                              returnRoute:
                                                routes.customer.dashboard,
                                            }
                                          );
                                        }}
                                      >
                                        <Typography
                                          variant="subheading1"
                                          color={Theme.palette.blue700}
                                        >
                                          {event.classEventType}
                                        </Typography>
                                      </List.Item>
                                      <List.Item>
                                        <Typography variant="small">
                                          {firstClassDate && lastClassDate
                                            ? startToEndDateString({
                                                beginDate: firstClassDate,
                                                endDate: lastClassDate,
                                              })
                                            : 'No dates found for this event...'}
                                        </Typography>
                                      </List.Item>
                                    </List>
                                  </List.Item>
                                </List>
                              </Flex.Box>

                              {event.isOnWaitlist && (
                                <Flex.Fill>
                                  <Segment
                                    basic
                                    className="on-waitlist-segment"
                                  >
                                    <List.Item>
                                      <Label attached="top right" color="red">
                                        Waitlisted
                                      </Label>
                                    </List.Item>
                                  </Segment>
                                </Flex.Fill>
                              )}

                              {event.eventStatusCode?.toUpperCase() ===
                                'CANCELLED' && (
                                <Flex.Fill>
                                  <Segment
                                    basic
                                    className="on-waitlist-segment"
                                  >
                                    <List.Item>
                                      <Label attached="top right" color="red">
                                        Cancelled
                                      </Label>
                                    </List.Item>
                                  </Segment>
                                </Flex.Fill>
                              )}
                            </Flex.Row>
                          </List.Item>
                        );
                      })}
                    </List>
                  </DashboardCardDescription>
                  <Typography
                    variant="small"
                    as={Link}
                    to={routes.customer.customerEventListing}
                    color={Theme.palette.blue400}
                  >
                    View All
                  </Typography>
                </>
              ) : (
                <NoEventsToShow />
              )}
              {onlineCourses && (
                <>
                  <Divider hidden />
                  <ListButton
                    className="online-course-button"
                    buttonText="View My Online Courses"
                    iconColor={Theme.palette.blue400}
                    icon={faComputerClassic}
                    onClick={() => {
                      history.push(routes.customer.myOnlineCourses);
                    }}
                  ></ListButton>
                </>
              )}
            </DashboardCard>

            <DashboardCard size="md">
              <DashboardCardHeader>
                <Typography variant="heading2">Renewals</Typography>
              </DashboardCardHeader>
              <DashboardCardDescription scroll>
                <ListButton
                  buttonText="Boat Registration"
                  iconColor={Theme.palette.blue400}
                  icon={faShip}
                  onClick={() => {
                    history.push(routes.boatRenewal.boatRenewalLookup);
                  }}
                ></ListButton>
                {isCommercialLicensingEnabled && (
                  <ListButton
                    buttonText="Commercial License"
                    iconColor={Theme.palette.blue400}
                    icon={faAddressCard}
                    onClick={() => {
                      history.push(
                        routes.commercialLicenseRenewal.commercialLicenseLookup
                      );
                    }}
                  ></ListButton>
                )}
                {isAutoRenewEnabled && (
                  <ListButton
                    buttonText="Manage Auto-Renew"
                    iconColor={Theme.palette.blue400}
                    icon={faClock}
                    onClick={() => {
                      history.push(
                        routes.customer.autoRenewManagement.customerProducts
                      );
                    }}
                  ></ListButton>
                )}
              </DashboardCardDescription>
            </DashboardCard>
          </StyledDashboard>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

const NoEventsToShow = () => {
  return (
    <DashboardCardDescription scroll>
      <Segment className="add-padding" basic placeholder align="center">
        <List.Item>
          <Label circular size="massive">
            <FontAwesomeIcon icon={faCalendar} className="fa-inverse" />
          </Label>
        </List.Item>
        <Typography variant="subheading1">
          You currently don't have any events.
        </Typography>
      </Segment>
      <Typography variant="body1">
        LDWF events offer courses in Hunter Education, Boater Education, Bow
        Education, and more.
      </Typography>
    </DashboardCardDescription>
  );
};

const ExportButton = ({transactionId}: {transactionId?: number}) => {
  const [useGetExportState, getExport] = useAsyncFn(async () => {
    const blob = await TransactionCustomerService.exportTransactionCustomerLicensePdfFile(
      {body: {transactionId: transactionId ?? 0, viewInNewTab: false}},
      {responseType: 'blob'}
    );

    saveAs(blob, 'CustomerTransaction');
  });

  return (
    <Tooltip label={'Download Transaction'}>
      <StyledButton
        className="export-button"
        onClick={() => {
          if (!useGetExportState.loading) {
            getExport();
          }
        }}
        loading={useGetExportState.loading}
      >
        <FontAwesomeIcon icon={faFileDownload} fixedWidth />
      </StyledButton>
    </Tooltip>
  );
};

const styles = css`
  .dashboard {
    .ui.segment.add-padding {
      padding: 8px 12px 8px 0 !important;
    }

    .button-container {
      display: inline;
    }

    .online-course-button {
      margin-top: 5px;
    }

    .customer-certifications {
      min-width: 58px;
      min-height: 138px;
    }

    .list-item-inner {
      display: flex !important;
    }

    .harvested-info {
      width: 10.7rem !important;
    }

    .full-card-container {
      .ul.ui.list ul,
      ol.ui.list ol,
      .ui.list .list {
        padding: 0.35rem 0rem !important;
      }

      .ui.celled.list > .item,
      .ui.celled.list > .list {
        padding: 0px !important;
      }

      .ui.celled.list > .item > .list > .item {
        margin: 0px 2px;
      }

      .ui.horizontal.list > .item {
        vertical-align: middle;
      }

      .ui.horizontal.list > .item:first-of-type,
      .ui.horizontal.list > .item:last-child {
        padding: 0px !important;
      }
    }
  }

  ${Media('DesktopMin')} {
    .wildlife-background {
      background-image: url('${wildlife}');
      background-repeat: no-repeat;
      background-position: 107px 216px;
    }
  }

  ${Media('MobileMax')} {
    .wildlife-background {
      background-image: url('${wildlife}');
      background-repeat: no-repeat;
      background-position: 51px 183px;
    }

    .mobile-button {
      width: 27%;
    }

    .mobile-button-content {
      font-size: 12px;
      white-space: nowrap;
    }
  }

  .apply-finger-cursor {
    cursor: pointer;
  }

  .on-waitlist-segment {
    padding: 0px;
  }

  .lbl-award {
    background-color: #839525;
  }

  .lbl-application {
    background-color: #e8e8e8;
  }

  .applications-container {
    padding-bottom: 4px !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .export-button {
    margin: 0 15px 10px 10px;
    float: right;
  }

  ${Media('DesktopMin')} {
    .accept-button-container {
      margin-top: 15px;
      margin-left: auto !important;
    }
  }
  ${Media('MobileMax')} {
    .accept-button-container {
      margin-top: 15px;
      margin-left: auto !important;
      width: 65px;
    }
  }

  ${Media('DesktopMin')} {
    .accept-button {
      float: right;
      margin: 0 10px 10px 25px;
    }
  }

  ${Media('MobileMax')} {
    .accept-button {
      float: right;
      width: 80px;
      font-size: 10px !important;
    }
  }

  .export-container {
    margin-left: auto !important;
  }

  .certification-label {
    margin-left: -35px;
  }

  ${Media('MobileMax')} {
    .certification-label {
      margin-left: -15px;
    }
  }

  .customer-card-content {
    flex: 1;
  }

  .customer-card-footer {
    margin-bottom: -1rem;
  }
`;
