import {useState, useCallback} from 'react';

export const useBooleanState = (initialValue: boolean = false) => {
  const [state, setState] = useState(initialValue);

  const setTrue = useCallback(() => {
    setState(true);
  }, []);

  const setFalse = useCallback(() => {
    setState(false);
  }, []);

  return {
    state,
    setTrue,
    setFalse,
  };
};
