import React, {useMemo} from 'react';
import {Divider} from 'semantic-ui-react';
import {fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';

type UpdateEmailFieldConfigDto = {
  emailAddress: string;
};

const useUpdateEmailFields = (email: string | undefined) =>
  fieldConfig<UpdateEmailFieldConfigDto>({
    emailAddress: input({
      fieldLabel: 'Email Address',
      defaultValue: email,
      fieldRequired: true,
    }),
  });

export const UpdateEmailOnReceiptPage: React.FC<{
  customerEmail: string | undefined;
  updateEmail: any;
  resendEmail: any;
}> = ({customerEmail, updateEmail, resendEmail}) => {
  const fields = useUpdateEmailFields(customerEmail);
  const initialValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const onSubmit = async (values) => {
    const response = await updateEmail(values);

    if (response.hasErrors) {
      return response;
    }

    var printReceiptResponse = await resendEmail();

    if (printReceiptResponse.hasErrors) {
      return printReceiptResponse;
    }

    notifications.success('Email Updated and receipt sent');
  };

  return (
    <>
      {
        <>
          <div className="box-description">
            <h3>Update Email</h3>
            <label>
              If this is not your current email address, please use the form
              below to correct it. You may also update this address at any time
              in your customer profile.
            </label>
          </div>
          <Divider hidden />
          <Form
            initialValues={initialValues}
            className="inline"
            //Prevents Form component from throwing error when removed. Does not accept null
            onSubmit={onSubmit}
            render={() => (
              <>
                {fields && (
                  <Form.Row>
                    <Form.Input fieldConfig={fields.emailAddress} />
                    <div className="field-submission-container">
                      <Form.Button
                        type="submit"
                        primary
                        className="field-submission-button"
                      >
                        Update Email Address
                      </Form.Button>
                    </div>
                  </Form.Row>
                )}
              </>
            )}
          />
        </>
      }
    </>
  );
};
