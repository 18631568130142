import React, {useEffect, useMemo} from 'react';
import {
  AccountService,
  CourseDto,
  CustomerCatalogService,
  CustomerCourseSystemService,
  CustomerOnlineCourseService,
} from '../../api/generated';
import {Divider, Grid} from 'semantic-ui-react';
import {useAsync} from 'react-use';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  AsyncState,
  AsyncStateContainer,
} from '../../components/async-state-container';
import {StyledPageContainer} from '../../styled-page-container';
import {StyledButton} from '../../styled-components/styled-buttons';
import {Typography} from '../../styled-components/typography';
import {useNotification} from '../../hooks/use-notifications';
import {
  buildStringRoute,
  buildStringRouteWithState,
} from '../../utils/route-helpers';
import {routes} from '../../routes/config';
import {CourseRegistrationLocationState} from './course-registration';

export const OnlineCourseDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notifications = useNotification();
  const match = useRouteMatch<{string: string}>();
  const courseCode = match.params.string;

  const fetchCourseSystemCourses = useAsync(async () => {
    const response = await CustomerCourseSystemService.getCourseSystemCourses();
    if (response.hasErrors) {
      response.validationFailures?.forEach((err) => {
        notifications.error(err.errorMessage);
      });
    }

    return response.result;
  });

  const fetchCourseSystemProduct = useAsync(async () => {
    const response = await CustomerCatalogService.getOnlineCourseByCourseCode({
      body: {
        courseCode,
      },
    });

    if (response.hasErrors) {
      response.validationFailures?.forEach((err) => {
        notifications.error(err.errorMessage);
      });
      return response;
    }

    return response;
  });

  const courseSystemCourses = fetchCourseSystemCourses.value;
  const courseSystemCourse = courseSystemCourses?.courses?.find(
    (x) => x.courseCode === courseCode
  );
  const courseSystemCourseProductCost =
    fetchCourseSystemProduct.value?.result?.productFeeTotal;

  const asyncState = useMemo(() => {
    const state: AsyncState = {
      loading:
        fetchCourseSystemCourses.loading || fetchCourseSystemProduct.loading,
      delayInMs: 0,
      error: fetchCourseSystemCourses.error || fetchCourseSystemProduct.error,
    };

    return state;
  }, [
    fetchCourseSystemCourses.error,
    fetchCourseSystemCourses.loading,
    fetchCourseSystemProduct.error,
    fetchCourseSystemProduct.loading,
  ]);

  return (
    <AsyncStateContainer {...asyncState}>
      {courseSystemCourse && (
        <StyledPageContainer title="Course Details" subtitle="">
          <Typography variant="heading2">{`${courseSystemCourse.name} Information`}</Typography>
          <CourseInformation
            course={courseSystemCourse}
            courseCode={courseCode}
            cost={courseSystemCourseProductCost}
          />
        </StyledPageContainer>
      )}
    </AsyncStateContainer>
  );
};

export type CourseInfoType = {
  course: CourseDto;
  courseCode: string;
  cost?: number;
};

export type CourseInfoFieldType = {
  header?: string;
  text?: any;
  noPadding?: boolean;
};

const CourseInformation: React.FC<CourseInfoType> = ({
  course,
  courseCode,
  cost,
}) => {
  const history = useHistory();

  const fetchInformation = useAsync(async () => {
    const onlineCoursesForLoggedInUser = await CustomerOnlineCourseService.getOnlineCoursesForLoggedInUser();
    const isUserLoggedIn = await AccountService.isUserLoggedIn();

    return {
      isUserLoggedIn: isUserLoggedIn.result,
      onlineCoursesForLoggedInUser: onlineCoursesForLoggedInUser.result,
    };
  });

  let currentUserOwnsCourse = false;
  if (fetchInformation.value?.onlineCoursesForLoggedInUser) {
    currentUserOwnsCourse = fetchInformation.value?.onlineCoursesForLoggedInUser.some(
      (x) => x.courseCode === courseCode
    );
  }

  return (
    <>
      <Grid stackable doubling columns="equal">
        <Grid.Row>
          <Grid.Column>
            <CourseInformationField header="About" text={course.description} />
            <CourseInformationField
              header="Course Terms"
              text={course.courseTerms}
            />
            <Divider />
            <CourseInformationField
              header="Price"
              text={cost ? `$${cost.toFixed(2)}` : 'N/A'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <div className="button-container">
        <StyledButton
          padded
          onClick={() => {
            history.push(routes.events);
          }}
        >
          Back
        </StyledButton>
        <StyledButton
          padded
          primary
          onClick={() => {
            history.push(
              buildStringRoute(
                routes.customer.courseRegistrationLogin,
                courseCode
              )
            );
          }}
        >
          Register
        </StyledButton>
        {fetchInformation.value?.isUserLoggedIn && currentUserOwnsCourse && (
          <StyledButton
            onClick={() => {
              history.push(
                ...buildStringRouteWithState<CourseRegistrationLocationState>(
                  routes.customer.courseRegistrationLogin,
                  course.courseCode,
                  {
                    command: 'continue',
                  }
                )
              );
            }}
            primary
          >
            Continue
          </StyledButton>
        )}
      </div>
    </>
  );
};

const CourseInformationField: React.FC<CourseInfoFieldType> = ({
  header,
  text,
  children,
}) => {
  const isNullOrUndefined = (text) => {
    return (
      text === null ||
      text === undefined ||
      text === 'null' ||
      text === 'undefined'
    );
  };

  return isNullOrUndefined(text) && isNullOrUndefined(children) ? (
    <></>
  ) : (
    <Grid.Row>
      <Grid.Column>
        <Typography variant="heading3">
          {`${isNullOrUndefined(header) ? '' : header} `}
        </Typography>
      </Grid.Column>
      <Grid.Column>
        {text ? (
          <Typography variant="body1">{`${
            isNullOrUndefined(text) ? 'N/A' : text
          }`}</Typography>
        ) : (
          <Typography variant="body1">{`${
            isNullOrUndefined(children) ? 'N/A' : children
          }`}</Typography>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};
