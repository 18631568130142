import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import moment from 'moment-timezone';
import {useLoginCookie} from './hooks/use-login-cookie';
import {notify} from './hooks/use-subscription';

export const CookieMonitor = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const cookies = useLoginCookie();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (showModal) {
        return;
      }

      cookies.checkAndUpdateCookie();

      const curr = cookies.currentCookie;
      const prev = cookies.previousCookie;

      if (prev && curr && prev !== curr) {
        const prevValue = moment(prev).toDate();
        const currValue = moment(curr).toDate();

        if (prevValue < currValue) {
          setShowModal(true);
        }
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [cookies, showModal]);

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      open={showModal}
      size="tiny"
    >
      <Modal.Header>Refresh Required</Modal.Header>
      <Modal.Content>
        <p>
          You just logged in to another account in this browser. Please click
          the button below or refresh the page in order to update the
          information to the latest signed-in user.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          type="button"
          onClick={() => {
            notify('refresh-session', undefined);
            cookies.setCurrentLoginCookie();
            setShowModal(false);
          }}
          content={'Refresh'}
        />
      </Modal.Actions>
    </Modal>
  );
};
