import {darken} from 'polished';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Grid, Message} from 'semantic-ui-react';
import {css} from '@emotion/core';
import {
  BooleanApiResult,
  CustomerCatalogService,
  CustomerMailingAddressDto,
  IspCustomersService,
  VendorCatalogService,
} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {Form} from '../../forms';
import {getDefaults} from '../../forms/schema-utils';
import {useHistoryState} from '../../hooks/use-history-state';
import {Theme} from '../../theme';
import {notifications} from '../../utils/notification-service';
import {
  catalogRoutes,
  CatalogType,
  isHardCardInCart,
  isHipSurveyNeeded,
  isStateDuckstampInCart,
  ProductCatalogState,
  ProductConstantCode,
  useProductCatalogState,
} from '../product-catalog/product-catalog-utils';
import {useMailingAddressConfirmationFields} from './mailing-address-confirmation-fields';
import {MailingAddressConfirmationFormFields} from './mailing-address-confirmation-form-fields';

type Error = {propertyName: string; errorMessage: string};

const subscriptionDefaults = {submitting: true, pristine: true};

const isEmptyString = (strValue: string): boolean => {
  return !strValue || strValue.trim().length === 0;
};

const getMockResponse = (errors: Error[]): BooleanApiResult => {
  const validationFailures = errors?.map((error) => ({
    propertyName: error.propertyName,
    errorMessage: error.errorMessage,
  }));

  const hasError = validationFailures?.length > 0;

  const response = {
    hasErrors: hasError,
    validationFailures: validationFailures,
    createdEntities: [],
    result: true,
  };

  if (hasError) {
    notifications.error('Mailing address confirmation failed');
  }

  return response;
};

export const MailingAddressConfirmation: React.FC = () => {
  const history = useHistory();
  const {
    hipSurveyRoutes,
    purchaseConfirmationRoutes,
    licensesAndPermitsRoutes,
  } = catalogRoutes;

  const {
    catalogState,
  }: {catalogState: ProductCatalogState} = history.location.state;

  const {
    context: {state, setState},
  } = useProductCatalogState({
    prevCatalogState: catalogState,
  });

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(
    false
  );

  const [
    addressConfirmationComplete,
    setAddressConfirmationComplete,
  ] = useState<boolean>(false);

  const [
    mailingAddress,
    setMailingAddress,
  ] = useHistoryState<CustomerMailingAddressDto>('mailingAddress', undefined);

  const {
    ownedProducts,
    products,
    catalogType,
    customer,
    mailingAddress: catalogStateMailingAddress,
  } = state;

  const isAdmin = state.catalogType === CatalogType.admin;
  const isVendor = state.catalogType === CatalogType.vendor;
  const isVendorOrHq = isVendor || state.catalogType === CatalogType.hqVendor;

  const fetchCustomerMailingAddress = useAsync(async () => {
    const {result} = await IspCustomersService.getCustomerMailingAddressById({
      customerId: customer?.id,
    });
    return result;
  }, [customer]);

  const fetchCustomerShoppingCart = useAsync(async () => {
    let cart;

    if (state.catalogType) {
      if (isAdmin || isVendorOrHq) {
        cart = await VendorCatalogService.getShoppingCart({
          customerId: customer?.id,
        });
      } else {
        cart = await CustomerCatalogService.getShoppingCart();
      }
    }

    if (cart.hasErrors) {
      return;
    }

    return cart.result;
  }, [isAdmin, isVendorOrHq, customer, state.catalogType]);

  const shoppingCart = fetchCustomerShoppingCart.value?.products;
  const hipProduct = products.find((x) => x.code === ProductConstantCode.hip);
  const cartHasHardCard = isHardCardInCart(shoppingCart);
  const cartHasStateDuckstamp = isStateDuckstampInCart(shoppingCart);
  const messageText =
    cartHasHardCard && cartHasStateDuckstamp
      ? 'You have Collector State Duck Stamp(s) and Hard Card(s) in your cart.'
      : cartHasHardCard
      ? 'You have Hard Card(s) in your cart.'
      : 'You have Collector State Duck Stamp(s) in your cart.';

  const fields = useMailingAddressConfirmationFields(
    mailingAddress ||
      catalogStateMailingAddress ||
      fetchCustomerMailingAddress?.value ||
      null
  );

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  useEffect(() => {
    if (addressConfirmationComplete && catalogType) {
      const hipSurveyNeeded = isHipSurveyNeeded(
        shoppingCart,
        ownedProducts,
        customer,
        hipProduct
      );
      if (hipSurveyNeeded && !isVendor) {
        history.push(hipSurveyRoutes[catalogType], {
          catalogState: state,
        });
      } else {
        history.push(purchaseConfirmationRoutes[catalogType], {
          catalogState: state,
        });
      }
    }
  }, [
    catalogType,
    addressConfirmationComplete,
    history,
    purchaseConfirmationRoutes,
    state,
    customer,
    hipProduct,
    hipSurveyRoutes,
    ownedProducts,
    shoppingCart,
    isVendor,
  ]);

  const onSubmit = (values) => {
    setIsNextButtonDisabled(true);

    let errors: Error[] = [];

    const street1 = values?.street1?.trim();
    const street2 = values?.street2?.trim();
    const city = values?.city?.trim();
    const state = values?.stateCode;
    const zipCode = values?.zipCode?.replace('-', '')?.trim();
    const countryCode = values?.countryCode;

    if (isEmptyString(street1)) {
      errors.push({
        propertyName: 'street1',
        errorMessage: 'Street Address 1 cannot be empty.',
      });
    }
    if (isEmptyString(city)) {
      errors.push({
        propertyName: 'city',
        errorMessage: 'City cannot be empty.',
      });
    }
    if (isEmptyString(state)) {
      errors.push({
        propertyName: 'stateCode',
        errorMessage: 'State cannot be empty.',
      });
    }
    if (isEmptyString(zipCode)) {
      errors.push({
        propertyName: 'zipCode',
        errorMessage: 'Zip / Postal Code cannot be empty.',
      });
    }
    if (isEmptyString(countryCode)) {
      errors.push({
        propertyName: 'countryCode',
        errorMessage: 'Country cannot be empty.',
      });
    }

    const response = getMockResponse(errors);

    if (!response.hasErrors) {
      const mailingAddress: CustomerMailingAddressDto = {
        street1,
        street2,
        city,
        stateCode: state,
        zipCode,
        countryCode,
      };
      setState((draft) => {
        draft.mailingAddress = mailingAddress;
      });
      setMailingAddress(mailingAddress);
      setAddressConfirmationComplete(true);
    }

    setIsNextButtonDisabled(false);
    return response;
  };

  const onCancel = () => {
    if (catalogType) {
      history.push(licensesAndPermitsRoutes[catalogType], {
        catalogState,
      });
    }
  };

  return (
    <BasicPage title="Mailing Address Confirmation" css={styles}>
      <Message>
        <strong>
          {messageText} Please update or confirm the mailing address where you
          want these items delivered. Note that updating this address will not
          change your account's mailing address.
        </strong>
      </Message>

      <Form
        initialValues={defaultValues}
        subscription={subscriptionDefaults}
        onSubmit={onSubmit}
        render={() => (
          <>
            <MailingAddressConfirmationFormFields fields={fields} />
            <Grid.Row className="button-row">
              <Grid.Column mobile={16}>
                <Form.Button
                  type="submit"
                  disabled={isNextButtonDisabled}
                  className="next-button"
                  content={'Next'}
                />
                <Form.Button
                  type="button"
                  onClick={onCancel}
                  content={'Back'}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      />
    </BasicPage>
  );
};

const styles = css`
  .button-row {
    margin-left: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .next-button {
    background: ${Theme.palette.blue500};
    color: ${Theme.palette.white1};

    &:hover {
      background: ${darken(0.1, Theme.palette.blue500)};
      color: ${Theme.palette.white1};
    }
  }
`;
