import {Quill} from 'react-quill';
const Link = Quill.import('formats/link');

export const sanitizeFunction = function (url: string) {
  let protocol = url.slice(0, url.indexOf(':'));
  if (Link.PROTOCOL_WHITELIST.indexOf(protocol) === -1) {
    url = 'https://' + url;
  }

  let anchor = document.createElement('a');
  anchor.href = url;
  protocol = anchor.href.slice(0, anchor.href.indexOf(':'));

  return Link.PROTOCOL_WHITELIST.indexOf(protocol) > -1
    ? url
    : Link.SANITIZED_URL;
};
