import {SkipNavContent} from '@reach/skip-nav';
import React from 'react';
import {VendorRoutes} from './vendor-portal/routes';

export const VendorApp = () => {
  return (
    <>
      <SkipNavContent></SkipNavContent>
      <VendorRoutes />
    </>
  );
};
